import { useEffect, useRef, useState } from 'react';
import './index.scss';

import Arrow from '../../assets/icons/ai_ic_left_arrow.svg?react';

const AidHorizontalScrollList = ({ children, cardWidth = 200, rows = 1 }) => {
  const scrollRef = useRef(null);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  useEffect(() => {
    if (scrollRef.current.scrollWidth > scrollRef.current.offsetWidth)
      setShowRight(true);
  }, []);

  const scrollLeft = () => {
    if (scrollRef.current) {
      const scrollAmt =
        parseInt(scrollRef.current.offsetWidth / cardWidth) * cardWidth;
      scrollRef.current.scrollBy({ left: -scrollAmt, behavior: 'smooth' });
      if (scrollRef.current.scrollLeft <= cardWidth) setShowLeft(false);
      setShowRight(true);
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      const scrollAmt =
        parseInt(scrollRef.current.offsetWidth / cardWidth) * cardWidth;
      scrollRef.current.scrollBy({ left: scrollAmt, behavior: 'smooth' });
      setShowLeft(true);
      if (
        scrollRef.current.scrollLeft +
          scrollRef.current.clientWidth +
          cardWidth >=
        scrollRef.current.scrollWidth
      )
        setShowRight(false);
    }
  };

  return (
    <div className="horizontal-scroll-container">
      <div
        className={`arrow-container slick-arrow slick-prev ${
          showLeft && 'slick-has'
        }`}
        onClick={() => {
          if (showLeft) scrollLeft();
        }}
      >
        <Arrow />
      </div>
      {showLeft && (
        <div
          className="left-gradient"
          style={{ height: scrollRef.current.offsetHeight }}
        ></div>
      )}
      <div
        className="scroll-list"
        ref={scrollRef}
        style={{ gridTemplateRows: `repeat(${rows}, auto)` }}
      >
        {children}
      </div>
      {showRight && (
        <div
          className="right-gradient"
          style={{ height: scrollRef.current.offsetHeight }}
        ></div>
      )}
      <div
        className={`arrow-container slick-arrow slick-next ${
          showRight && 'slick-has'
        }`}
        onClick={() => {
          if (showRight) scrollRight();
        }}
      >
        <Arrow />
      </div>
    </div>
  );
};

export default AidHorizontalScrollList;
