import { action, computed, observable, makeObservable } from 'mobx';

import { importSource } from '../utils/constants';

class DataStore {
  filters = {
    toggleState: '',
    isMasterSaveClickMimSummary: false,
    isMasterSaveClickMimPeople: false,
    isMasterSaveClickNetworkSummary: false,
    isAppliedUserFilterData: false,
  };

  lookUpLists = {
    naicsList: [],
    ownerId: 0,
    teamMemberIds: [],
    teamMembers: [],
    industries: [],
    teamAccounts: [],
    uiTeamIds: [],
    teamIds: [],
    eventList: [],
    titleRankList: [],
    latest_mim_monetary_value_max: null,
    insightHistory: false,
    map: null,
    isPeoplePage: false,
    isMapPage: false,
    showFullMapWidth: false,
    isSummaryPage: false,
    import_source: importSource,
    fileImports: [],
    teamFileImports: [],
    titleRole: [],
    fileImportsWithoutEnrich: [],
    teamFileImportsWithoutEnrich: [],
    addImportsToTeam: [],
    isRelationPathSelected: false,
    userTagFolders: {},
  };

  mappedRelationshipsFilter = {
    filterName: '',
    mappedSourceB2bId: '',
    mappedSourceB2cId: '',
    mappedSourceInferredSalary: [],
    mappedSourceWealthEstimate: [],
    mappedSourceLocationState: '',
    mappedSourceLocationCity: '',
    mappedSourceOrgname: '',
    mappedSourceTitle: '',
    mappedSourceSchool: [],
    mappedRelationshipWeights: [],
    mappedRelationshipTypes: [],
  };

  zipCodeFromProfile = '';

  appliedMasterFilters = {
    event_type: [],
    date_filter: 'week',
    location_state: [],
    event_state: [],
    filter_type: 'all',
  };

  teamFilters = {
    team_ids: [],
    team_id_from_summary: '',
    uiTeamIds: [],
    checkedTeamIds: [],
    me_id_from_summary: '',
    isAllChecked: false,
  };

  networkFilters = {
    irs_tax_insights: { key: '', gte: '', lte: '' },
    gender: null,
    marital_status: null,
    company_type: null,
    fetchData: false,
    filter_type: 'all',
    auto_second_degree: false,
    attributes_checked: [],
    wealth_model_checked: [],
    tags_checked: [],
    shared_tags: [],
    attribute_function: true,
    all_interests: false,
    salary_item_summary: '',
    home_search: { geo: null, address: '' },
    slider_radius: 10,
    county: [],
    company_search: { geo: null, address: '' },
    isFromSummary: false,
    sortedInfoColumnKey: '',
    attributes: [],
    import_source: [],
    file_imports: [],
    team_file_imports: [],
    prospect_profile_name: '',
    default_view: false,
    email_alert: true,
    separate_email_alert: false,
    selectedIdValue: -1,
    mapQuery: {},
    all_homes: false,
    filterFileImports: [],
    filterTeamFileImports: [],
    naics_codes: [],
    norm_home_owner: [],
    // is_basement_included: false,
    is_elevator_included: false,
    garage_cars: [],
    number_of_owned_properties: [],
    is_pool_included: false,
    number_of_stories: [],
    number_of_baths: [],
    number_of_bedrooms: [],
    number_of_partial_baths: [],
    total_number_of_rooms: [],
    lotsize_square_feet: [],
    number_of_units: [],
    effective_year_built: [],
    remaining_share_value_sum: [],
    mapped_relationships_filter: {},
    current_org_years: null,
    wealth_estimate_range: {},
    total_years_of_experience: null,
    home_value_estimate: null,
    home_purchase_date: [],
    home_sale_date: [],
    property_type: [],
    exc_current_experience: [],
    latest_mim_monetary_value: [],
    // latest_mim_monetary_value_range: [],
  };
  table_search = {};
  selectedGroupByParam = [];

  isShowingInsightsView = false;

  groupByValues = {
    age: {},
    industry: [],
    org_name: [],
    location_city: [],
    schools: [],
    location_state: [],
    employee_count: [],
    stock_performance: [],
    title: [],
    title_role: [],
    latest_mim_event_type: [],
    marital_status: '',
    top_team_members: '',
    team_member_id: '',
    gender: '',
    title_rank: [],
    credentials: [],
    inferred_salary_range: [],
    wealth_estimate: [],
    interests: [],
    previous_experience_org_name: [],
    event_org_name: [],
    remaining_share_value_sum: [],
    tags: {},
    funding_round: [],
    number_of_owned_properties: [],
    property_type: [],
    mapped_connection_count: [],
  };

  // keep quicksearch text
  quick_search = '';

  // keep quickserach text of RI pages
  relationship_quick_search = '';

  // to identify the N2 summary chart clicked item
  summaryFilter = null;

  // to identify the click is from company profile
  companyFilter = null;

  // to identify show more clicked from superconnectors
  superConnectorFilter = null;

  // to identify if user checked any of the import sources
  importSourceKeys = {
    isImportSourceChecked: false, // make true when any checkbox of importsource is changed
    isImportSourceSaved: false, // make true when any importsources has data from masrer of importsource is changed
  };

  userTags = [];

  currentUser = {
    isAdmin: false,
    ownerId: 0,
    creditCount: 0,
    isCorporate: false,
  };

  importContacts = {
    activeKeyValue: '',
  };

  overViewTeam = {
    ownerType: 'me',
    ownerTypeId: null,
  };

  constructor() {
    makeObservable(this, {
      teamFilters: observable,
      quick_search: observable,
      relationship_quick_search: observable,
      overViewTeam: observable,
      lookUpLists: observable,
      userTags: observable,
      currentUser: observable,
      filters: observable,
      networkFilters: observable,
      table_search: observable,
      selectedGroupByParam: observable,
      isShowingInsightsView: observable,
      groupByValues: observable,
      isNetworkFilterApplied: computed,
      isNonMasterFilterApplied: computed,
      isTriggerFilterApplied: computed,
      reset: action,
      resetNetworkFilters: action,
      resetGroupByValues: action,
      summaryFilter: observable,
      companyFilter: observable,
      superConnectorFilter: observable,
      appliedMasterFilters: observable,
      mappedRelationshipsFilter: observable,
      zipCodeFromProfile: observable,
      resetAppliedMasterFilter: action,
      importSourceKeys: observable,
    });
  }

  get isNonMasterFilterApplied() {
    if (
      (this.networkFilters.full_name &&
        this.networkFilters.full_name !== '' &&
        this.networkFilters.full_name !== ' ' &&
        this.networkFilters.full_name.length > 0) ||
      (this.teamFilters.checkedTeamIds &&
        this.teamFilters.checkedTeamIds.length > 0)
    )
      return true;
    return false;
  }

  get isTriggerFilterApplied() {
    if (
      (this.networkFilters.latest_mim_event_type &&
        this.networkFilters.latest_mim_event_type.length) ||
      (this.networkFilters.funding_round &&
        this.networkFilters.funding_round.length > 0) ||
      // (this.networkFilters.latest_mim_monetary_value &&
      //   this.networkFilters.latest_mim_monetary_value.key &&
      //   this.networkFilters.latest_mim_monetary_value.value &&
      //   this.networkFilters.latest_mim_monetary_value.value.length > 0) ||
      (this.networkFilters.latest_mim_monetary_value &&
        this.networkFilters.latest_mim_monetary_value.length > 0) ||
      (this.networkFilters.period &&
        this.networkFilters.period !== '' &&
        this.networkFilters.period.value) ||
      (this.networkFilters.m2_date && this.networkFilters.m2_date.length > 0) ||
      (this.networkFilters.event_id &&
        this.networkFilters.event_id !== '' &&
        this.networkFilters.event_id !== ' ') ||
      (this.networkFilters.mimperson_id &&
        this.networkFilters.mimperson_id !== '' &&
        this.networkFilters.mimperson_id !== ' ') ||
      (this.networkFilters.daily_opportunities_id &&
        this.networkFilters.daily_opportunities_date)
    )
      return true;
    return false;
  }

  get isNetworkFilterApplied() {
    if (
      (this.networkFilters.company_type &&
        this.networkFilters.company_type.value !== '') ||
      (this.quick_search &&
        this.quick_search !== '' &&
        this.quick_search !== ' ') ||
      (this.networkFilters.event_id &&
        this.networkFilters.event_id !== '' &&
        this.networkFilters.event_id !== ' ') ||
      (this.networkFilters.mimperson_id &&
        this.networkFilters.mimperson_id !== '' &&
        this.networkFilters.mimperson_id !== ' ') ||
      (this.networkFilters.daily_opportunities_id &&
        this.networkFilters.daily_opportunities_date) ||
      (this.networkFilters.filter_type &&
        this.networkFilters.filter_type !== 'all') ||
      (this.networkFilters.full_name &&
        this.networkFilters.full_name !== '' &&
        this.networkFilters.full_name !== ' ') ||
      (this.networkFilters.home_address_suggest &&
        this.networkFilters.home_address_suggest.length > 0) ||
      (this.networkFilters.exc_home_address_suggest &&
        this.networkFilters.exc_home_address_suggest.length > 0) ||
      // (this.networkFilters.home_address_suggest &&
      //   this.networkFilters.home_address_suggest !== '' &&
      //   this.networkFilters.home_address_suggest !== ' ') ||
      (this.networkFilters.irs_tax_insights &&
        this.networkFilters.irs_tax_insights.key &&
        (this.networkFilters.irs_tax_insights.gte ||
          this.networkFilters.irs_tax_insights.lte)) ||
      (this.networkFilters.location_city &&
        this.networkFilters.location_city.length > 0) ||
      (this.networkFilters.home_search &&
        this.networkFilters.home_search.geo &&
        this.networkFilters.home_search.address) ||
      (this.networkFilters.location_state &&
        this.networkFilters.location_state.length > 0) ||
      (this.networkFilters.home_postal_code &&
        this.networkFilters.home_postal_code.key &&
        this.networkFilters.home_postal_code.value &&
        this.networkFilters.home_postal_code.value.length > 0) ||
      (this.networkFilters.county && this.networkFilters.county.length > 0) ||
      (this.networkFilters.age &&
        this.networkFilters.age.key &&
        this.networkFilters.age.value &&
        this.networkFilters.age.value.length > 0) ||
      (this.networkFilters.gender && this.networkFilters.gender.value) ||
      (this.networkFilters.marital_status &&
        this.networkFilters.marital_status.value) ||
      (this.networkFilters.presence_of_children &&
        this.networkFilters.presence_of_children.value !== null) ||
      (this.networkFilters.wealth_estimate &&
        this.networkFilters.wealth_estimate.length > 0) ||
      (this.networkFilters.interests && this.networkFilters.interests.length) ||
      (this.networkFilters.attribute_function !== undefined &&
        this.networkFilters.attributes_checked &&
        this.networkFilters.attributes_checked.length > 0) ||
      (this.networkFilters.wealth_model_checked &&
        this.networkFilters.wealth_model_checked.length > 0) ||
      (this.networkFilters.tags_checked &&
        this.networkFilters.tags_checked.length > 0) ||
      (this.networkFilters.shared_tags &&
        this.networkFilters.shared_tags.length > 0) ||
      (this.networkFilters.org_name &&
        this.networkFilters.org_name.length > 0) ||
      (this.networkFilters.exc_current_experience &&
        this.networkFilters.exc_current_experience.length > 0) ||
      (this.networkFilters.previous_experience_org_name &&
        this.networkFilters.previous_experience_org_name.length > 0) ||
      (this.networkFilters.industry &&
        this.networkFilters.industry.length > 0) ||
      (this.networkFilters.exc_industry &&
        this.networkFilters.exc_industry.length > 0) ||
      (this.networkFilters.title_rank &&
        this.networkFilters.title_rank.length) ||
      (this.networkFilters.credentials &&
        this.networkFilters.credentials.length) ||
      (this.networkFilters.title && this.networkFilters.title.length > 0) ||
      (this.networkFilters.exc_title_rank &&
        this.networkFilters.exc_title_rank.length > 0) ||
      (this.networkFilters.exc_title &&
        this.networkFilters.exc_title.length > 0) ||
      (this.networkFilters.title_role &&
        this.networkFilters.title_role.length > 0) ||
      (this.networkFilters.schools && this.networkFilters.schools.length > 0) ||
      (this.networkFilters.company_search &&
        this.networkFilters.company_search.geo &&
        this.networkFilters.company_search.address) ||
      ((this.lookUpLists.isPeoplePage || this.lookUpLists.isSummaryPage) &&
        this.networkFilters.stock_performance &&
        this.networkFilters.stock_performance.length > 0) ||
      (this.networkFilters.number_of_mapped_connections &&
        this.networkFilters.number_of_mapped_connections.length > 0) ||
      (this.networkFilters.employee_count &&
        this.networkFilters.employee_count.length > 0) ||
      (this.networkFilters.inferred_salary_range &&
        this.networkFilters.inferred_salary_range.length > 0) ||
      (this.networkFilters.latest_mim_event_type &&
        this.networkFilters.latest_mim_event_type.length) ||
      // (this.networkFilters.latest_mim_monetary_value &&
      //   this.networkFilters.latest_mim_monetary_value.key &&
      //   this.networkFilters.latest_mim_monetary_value.value &&
      //   this.networkFilters.latest_mim_monetary_value.value.length > 0) ||
      (this.networkFilters.latest_mim_monetary_value &&
        this.networkFilters.latest_mim_monetary_value.length > 0) ||
      (this.networkFilters.period &&
        this.networkFilters.period !== '' &&
        this.networkFilters.period.value) ||
      (this.networkFilters.m2_date && this.networkFilters.m2_date.length > 0) ||
      (this.networkFilters.home_purchase_date &&
        this.networkFilters.home_purchase_date.length > 0) ||
      (this.networkFilters.home_sale_date &&
        this.networkFilters.home_sale_date.length > 0) ||
      (this.networkFilters.property_type &&
        this.networkFilters.property_type.length > 0) ||
      (this.networkFilters.relmap_org &&
        this.networkFilters.relmap_org.length > 0) ||
      (this.teamFilters.checkedTeamIds &&
        this.teamFilters.checkedTeamIds.length > 0) ||
      (this.networkFilters.import_source &&
        this.networkFilters.import_source.length > 0 &&
        (this.networkFilters.filter_type === 'all' ||
          this.networkFilters.import_source.length <
            this.lookUpLists.import_source.length)) ||
      (this.networkFilters.file_imports &&
        this.networkFilters.file_imports.length > 0 &&
        (this.networkFilters.filter_type === 'all' ||
          this.networkFilters.file_imports.length <
            this.lookUpLists.fileImportsWithoutEnrich.length)) ||
      (this.networkFilters.filter_type !== 'first_degree' &&
        this.networkFilters.team_file_imports &&
        this.networkFilters.team_file_imports.length > 0 &&
        (this.networkFilters.filter_type === 'all' ||
          this.networkFilters.team_file_imports.length <
            this.lookUpLists.teamFileImportsWithoutEnrich.length)) ||
      (this.groupByValues.property_type &&
        this.groupByValues.property_type.length > 0) ||
      (this.groupByValues.remaining_share_value_sum &&
        this.groupByValues.remaining_share_value_sum.length > 0) ||
      (this.groupByValues.org_name && this.groupByValues.org_name.length > 0) ||
      (this.groupByValues.previous_experience_org_name &&
        this.groupByValues.previous_experience_org_name.length > 0) ||
      (this.networkFilters.current_org_years &&
        this.networkFilters.current_org_years.key &&
        this.networkFilters.current_org_years.value &&
        this.networkFilters.current_org_years.value.length > 0) ||
      (this.networkFilters.wealth_estimate_range &&
        this.networkFilters.wealth_estimate_range.key &&
        this.networkFilters.wealth_estimate_range.value &&
        this.networkFilters.wealth_estimate_range.value.length > 0) ||
      (this.networkFilters.total_years_of_experience &&
        this.networkFilters.total_years_of_experience.key &&
        this.networkFilters.total_years_of_experience.value &&
        this.networkFilters.total_years_of_experience.value.length > 0) ||
      (this.groupByValues.age &&
        Object.keys(this.groupByValues.age).length > 0) ||
      ((this.lookUpLists.isPeoplePage || this.lookUpLists.isSummaryPage) &&
        this.groupByValues.stock_performance &&
        this.groupByValues.stock_performance.length > 0) ||
      (this.groupByValues.employee_count &&
        this.groupByValues.employee_count.length > 0) ||
      (this.groupByValues.location_city &&
        this.groupByValues.location_city.length > 0) ||
      (this.groupByValues.location_state &&
        this.groupByValues.location_state.length > 0) ||
      this.groupByValues.home_postal_code ||
      (this.groupByValues.gender && this.groupByValues.gender !== '') ||
      (this.groupByValues.marital_status &&
        this.groupByValues.marital_status !== '') ||
      (this.groupByValues.industry && this.groupByValues.industry.length > 0) ||
      (this.groupByValues.inferred_salary_range &&
        this.groupByValues.inferred_salary_range.length > 0) ||
      (this.groupByValues.interests && this.groupByValues.interests.length) ||
      (this.groupByValues.latest_mim_event_type &&
        this.groupByValues.latest_mim_event_type.length) ||
      (this.groupByValues.title && this.groupByValues.title.length > 0) ||
      (this.groupByValues.title_rank && this.groupByValues.title_rank.length) ||
      (this.groupByValues.title_role && this.groupByValues.title_role.length) ||
      (this.groupByValues.credentials &&
        this.groupByValues.credentials.length) ||
      (this.groupByValues.schools && this.groupByValues.schools.length > 0) ||
      (this.groupByValues.number_of_owned_properties &&
        this.groupByValues.number_of_owned_properties.length > 0) ||
      (this.groupByValues.funding_round &&
        this.groupByValues.funding_round.length > 0) ||
      (this.groupByValues.tags &&
        Object.keys(this.groupByValues.tags).length > 0) ||
      (this.groupByValues.mapped_connection_count &&
        Object.keys(this.groupByValues.mapped_connection_count).length > 0) ||
      (this.networkFilters.funding_round &&
        this.networkFilters.funding_round.length > 0) ||
      (this.networkFilters.event_org_name &&
        this.networkFilters.event_org_name.length > 0) ||
      this.networkFilters.all_homes ||
      (this.networkFilters.naics_codes &&
        this.networkFilters.naics_codes.length > 0) ||
      (this.networkFilters.mapped_relationships_filter &&
        (this.networkFilters.mapped_relationships_filter.mapped_source_b2b_id ||
          this.networkFilters.mapped_relationships_filter
            .mapped_source_b2c_id)) ||
      (this.networkFilters.home_value_estimate &&
        this.networkFilters.home_value_estimate.key &&
        this.networkFilters.home_value_estimate.value &&
        this.networkFilters.home_value_estimate.value.length > 0) ||
      (this.networkFilters.home_value_estimate &&
        Array.isArray(this.networkFilters.home_value_estimate) &&
        this.networkFilters.home_value_estimate.length > 0) ||
      (this.networkFilters.norm_home_owner &&
        this.networkFilters.norm_home_owner.length > 0) ||
      (this.networkFilters.number_of_stories &&
        this.networkFilters.number_of_stories.length > 0) ||
      (this.networkFilters.number_of_baths &&
        this.networkFilters.number_of_baths.length > 0) ||
      (this.networkFilters.number_of_bedrooms &&
        this.networkFilters.number_of_bedrooms.length > 0) ||
      (this.networkFilters.number_of_partial_baths &&
        this.networkFilters.number_of_partial_baths.length > 0) ||
      (this.networkFilters.total_number_of_rooms &&
        this.networkFilters.total_number_of_rooms.length > 0) ||
      (this.networkFilters.number_of_units &&
        this.networkFilters.number_of_units.length > 0) ||
      (this.networkFilters.effective_year_built &&
        this.networkFilters.effective_year_built.length > 0) ||
      this.networkFilters.is_elevator_included ||
      (this.networkFilters.garage_cars &&
        this.networkFilters.garage_cars.length > 0) ||
      (this.networkFilters.number_of_owned_properties &&
        this.networkFilters.number_of_owned_properties.length > 0) ||
      this.networkFilters.is_pool_included ||
      (this.networkFilters.lotsize_square_feet &&
        this.networkFilters.lotsize_square_feet.length > 0) ||
      (this.networkFilters.remaining_share_value_sum &&
        this.networkFilters.remaining_share_value_sum.length > 0)
    )
      return true;
    return false;
  }

  getDefaultTeamIds() {
    const checkedArrayNew = Array.from(new Set(this.lookUpLists.teamIds));
    this.teamFilters.checkedTeamIds = this.lookUpLists.teamIds;
    const team_ids = checkedArrayNew.filter(Number);
    return team_ids;
  }

  // to reset all filters on logout
  reset() {
    this.filters = {
      toggleState: '',
    };
    this.quick_search = '';

    this.selectedGroupByParam = [];
    this.isShowingInsightsView = false;

    this.resetGroupByValues();

    this.resetNetworkFilters();

    this.superConnectorFilter = null;

    this.teamFilters = {
      team_ids: this.getDefaultTeamIds(),
      uiTeamIds: [...this.lookUpLists.uiTeamIds],
    };

    this.overViewTeam = {
      ownerType: 'me',
      ownerTypeId: null,
    };

    this.userTags = [];
    this.sharedTags = [];

    this.currentUser = {
      user: {},
      isAdmin: false,
      ownerId: 0,
      creditCount: 0,
    };

    this.lookUpLists = {
      naicsList: [],
      ownerId: 0,
      teamMemberIds: [],
      teamMembers: [],
      industries: [],
      teamAccounts: [],
      uiTeamIds: [],
      teamIds: [],
      eventList: [],
      titleRankList: [],
      latest_mim_monetary_value_max: null,
      map: null,
      isPeoplePage: false,
      isMapPage: false,
      showFullMapWidth: false,
      isSummaryPage: false,
      import_source: importSource,
      fileImports: [],
      teamFileImports: [],
      titleRole: [],
      fileImportsWithoutEnrich: [],
      teamFileImportsWithoutEnrich: [],
      addImportsToTeam: [],
      isRelationPathSelected: false,
      storiesList: [],
      userTagFolders: {},
    };

    /** used in export-all in mapped connections in profile */
    this.resetMappedFilters();

    /** used in redirection from tax-profile in profile */
    this.zipCodeFromProfile = '';
  }

  resetMappedFilters() {
    this.mappedRelationshipsFilter = {
      filterName: '',
      mappedSourceB2bId: '',
      mappedSourceB2cId: '',
      mappedSourceInferredSalary: [],
      mappedSourceWealthEstimate: [],
      mappedSourceLocationState: '',
      mappedSourceLocationCity: '',
      mappedSourceOrgname: '',
      mappedSourceTitle: '',
      mappedSourceSchool: [],
      mappedRelationshipWeights: [],
      mappedRelationshipTypes: [],
    };
  }

  resetNetworkFilters() {
    this.table_search = {};
    this.networkFilters = {
      map: null,
      fetchData: false,
      filter_type: 'all',
      auto_second_degree: false,
      location_state: [],
      presence_of_children: null,
      interests: [],
      credentials: [],
      title: [],
      title_rank: [],
      exc_title: [],
      title_role: [],
      exc_title_rank: [],
      inferred_salary_range: [],
      wealth_estimate: [],
      wealth_estimate_range: {},
      number_of_mapped_connections: [],
      employee_count: [],
      stock_performance: [],
      latest_mim_event_type: [],
      m2_date: [],
      home_purchase_date: [],
      home_sale_date: [],
      property_type: [],
      period: null,
      full_name: '',
      home_address_suggest: [],
      exc_home_address_suggest: [],
      org_name: [],
      exc_current_experience: [],
      previous_experience_org_name: [],
      event_org_name: [],
      relmap_org: [],
      industry: [],
      exc_industry: [],
      schools: [],
      import_source: [],
      file_imports: [],
      team_file_imports: [],
      location_city: [],
      home_postal_code: {},
      irs_tax_insights: { key: '', gte: '', lte: '' },
      gender: null,
      marital_status: null,
      company_type: null,
      home_search: { geo: null, address: '' },
      company_search: { geo: null, address: '' },
      county: [],
      funding_round: [],
      latest_mim_monetary_value: [],
      // latest_mim_monetary_value_range: [],
      age: {},
      current_org_years: null,
      total_years_of_experience: null,
      home_value_estimate: null,
      management_change_date_range: [],
      change_type: '',
      selectedIdValue: -1,
      prospect_profile_name: '',
      default_view: false,
      email_alert: true,
      separate_email_alert: false,
      tags_checked: [],
      shared_tags: [],
      attributes_checked: [],
      wealth_model_checked: [],
      attribute_function: true,
      all_interests: false,
      all_homes: false,
      filterFileImports: [],
      filterTeamFileImports: [],
      naics_codes: [],
      norm_home_owner: [],
      // is_basement_included: false,
      is_elevator_included: false,
      garage_cars: [],
      number_of_owned_properties: [],
      is_pool_included: false,
      number_of_stories: [],
      number_of_baths: [],
      number_of_bedrooms: [],
      number_of_partial_baths: [],
      total_number_of_rooms: [],
      lotsize_square_feet: [],
      number_of_units: [],
      effective_year_built: [],
      remaining_share_value_sum: [],
      mapped_relationships_filter: {},
    };
    // this.superConnectorFilter = null;
    // this.teamFilters = {
    //   team_ids: this.getDefaultTeamIds(),
    //   uiTeamIds: [...this.lookUpLists.uiTeamIds],
    //   isAllChecked: true,
    // };
    this.teamFilters = {
      team_ids: [],
      uiTeamIds: [[]],
      checkedTeamIds: [],
      isAllChecked: false,
    };

    this.selectedGroupByParam = [];
    this.isShowingInsightsView = false;

    this.groupByValues = {
      age: {},
      industry: [],
      org_name: [],
      location_city: [],
      schools: [],
      location_state: [],
      employee_count: [],
      stock_performance: [],
      title: [],
      title_role: [],
      latest_mim_event_type: [],
      marital_status: '',
      top_team_members: '',
      team_member_id: '',
      gender: '',
      credentials: [],
      title_rank: [],
      inferred_salary_range: [],
      wealth_estimate: [],
      interests: [],
      previous_experience_org_name: [],
      event_org_name: [],
      remaining_share_value_sum: [],
      tags: {},
      funding_round: [],
      number_of_owned_properties: [],
      property_type: [],
      mapped_connection_count: [],
    };
  }

  resetGroupByValues() {
    // this. selectedGroupByParam = 'select';
    this.isShowingInsightsView = false;

    this.groupByValues = {
      age: {},
      industry: [],
      org_name: [],
      location_city: [],
      schools: [],
      location_state: [],
      employee_count: [],
      stock_performance: [],
      title: [],
      title_role: [],
      latest_mim_event_type: [],
      marital_status: '',
      top_team_members: '',
      team_member_id: '',
      gender: '',
      credentials: [],
      title_rank: [],
      inferred_salary_range: [],
      wealth_estimate: [],
      interests: [],
      previous_experience_org_name: [],
      event_org_name: [],
      remaining_share_value_sum: [],
      tags: {},
      funding_round: [],
      number_of_owned_properties: [],
      property_type: [],
      mapped_connection_count: [],
    };
  }

  resetAppliedMasterFilter() {
    this.appliedMasterFilters = {
      event_type: [],
      date_filter: 'week',
      location_state: [],
      event_state: [],
      filter_type: 'all',
    };
  }
}

export default DataStore;
