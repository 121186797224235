import './index.scss';

import { message } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { inject } from 'mobx-react';

import RedtailIcon from '../../assets/icons/ai_redtail.svg?react';

import CrmForm from '../CrmForm';
import { requestWithAuth, updateUserInfo } from '../../services/api';
import LoaderAnimation from '../AidLoader';
import { AboutText, IntegratedView } from './exportCRMSection';

message.config({
  top: 80,
});

let source;

const RedtailView = inject('dataStore')((props) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const { CancelToken } = axios;
    source = CancelToken.source();
    return () => {
      message.destroy();
      if (source) source.cancel('Operation canceled by the user.');
    };
  }, []);

  const handleSubmit = (values) => {
    // e.preventDefault();
    const { onSuccessIntegration } = props;

    // form.validateFields({ force: true }, (err, values) => {
    const { userName } = values;
    const { password } = values;

    const body = {
      user_name: userName,
      password,
    };

    // if (!err) {
    setIsLoading(true);
    requestWithAuth('redtail-auth', 'POST', null, body, source.token).then(
      (response) => {
        if (response) {
          if (response.code === 200) {
            const paramsRedtail = {
              crm: 'rt',
            };
            updateUserInfo(props.dataStore.currentUser.ownerId, paramsRedtail)
              .then(() => {
                setIsLoading(false);
                onSuccessIntegration();
              })
              .catch((error) => {
                setIsLoading(false);
              });
          } else {
            message.error(response.errorCode, 10);
            setIsLoading(false);
          }
        } else {
          message.error('Please try again', 5);
        }
        setIsLoading(false);
      },
    );
    // }
    // });
  };

  const { submitting, crmValue, removeIntegration } = props;
  // const crmValue = 'rt';
  return (
    <div className="loader-wrapper">
      {isLoading && (
        <LoaderAnimation loaderId="redtail-loader" style={{ top: '100px' }} />
      )}
      <div className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
        {crmValue !== 'rt' && (
          <div className="main-section">
            <div className="left-section">
              <div className="export-data-heading">Export</div>
              <div className="crm-form">
                <CrmForm
                  formLayout="vertical"
                  inputSize="default"
                  showLabels
                  submitting={submitting}
                  onFinish={handleSubmit}
                  keys={{
                    idLabel: 'Username',
                    idKey: 'userName',
                    passwordLabel: 'Password',
                    passwordKey: 'password',
                  }}
                  disable={crmValue !== '' && crmValue !== 'rt'}
                />
              </div>
            </div>
            <div className="right-section">
              <AboutText
                crm_name="Redtail"
                crm_link="http://corporate.redtailtechnology.com/"
                crm_url="redtail.com"
                crm_icon={RedtailIcon}
              />
            </div>
          </div>
        )}
        {crmValue === 'rt' && (
          <div className="main-section">
            <div className="left-section">
              {' '}
              <IntegratedView crm_name="Redtail" onRemove={removeIntegration} />
            </div>
            <div className="right-section">
              {' '}
              <AboutText
                crm_name="Redtail"
                crm_link="http://corporate.redtailtechnology.com/"
                crm_url="redtail.com"
                crm_icon={RedtailIcon}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default RedtailView;
