import './index.scss';

import { useState, useRef, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Divider, Radio, Table, Tooltip } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';

import HelpIcon from '../../assets/icons/help-icon.svg?react';

import { commarize } from '../../utils/general';
import { importSourceList } from '../../utils/constants';
import LoaderIcon from '../../assets/icons/ai_ic_logo_gold.svg?react';
import PeopleViewModal from './PeopleViewModal';

const Opportunities = inject(
  'dataStore',
  'globalDataStore',
)(
  observer((props) => {
    const borderRef = useRef(null);
    const [dataSource, setDataSource] = useState([]);
    const [triggerPeriod, setTriggerPeriod] = useState('week_triggers');
    const [modalVisibility, setModalVisibility] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      if (props.data) {
        setIsLoading(false);
        if (Object.keys(props.data).length > 0) getData(triggerPeriod);
      }
    }, [props.data, props.globalDataStore.multipleFilters.selectedId]);

    useEffect(() => {
      if (borderRef && borderRef.current) {
        borderRef.current.style.left = '0px';
      }
    }, []);

    const getData = (period) => {
      const { data } = props;
      setDataSource([]);

      if (!data.opp) {
        return;
      }
      const dataSet = [];
      Object.keys(data.opp[period]).map((key) => {
        const obj = {};
        obj.filters = key;
        obj.oppFirstDegree = commarize(data.opp[period][key].first_degree);
        obj.oppSecondDegree = commarize(data.opp[period][key].second_degree);
        obj.oppAll = commarize(data.opp[period][key].all);
        dataSet.push(obj);
      });

      if (dataSet && dataSet.length > 0) {
        dataSet.sort((a, b) => {
          const filter1 = a.filters.toLowerCase(),
            filter2 = b.filters.toLowerCase();

          if (filter1 < filter2) {
            return -1;
          }
          if (filter1 > filter2) {
            return 1;
          }
          return 0;
        });
      }

      if (props.globalDataStore.multipleFilters.selectedId !== -1) {
        /** Apply filter if any filter is selected */
        const selectedFilter =
          props.globalDataStore.multipleFilters.filterList.filter((item) => {
            return (
              item.value === props.globalDataStore.multipleFilters.selectedId
            );
          });
        if (selectedFilter && selectedFilter[0] && selectedFilter[0].label) {
          setDataSource([
            ...dataSet.filter(
              (item) => item.filters === selectedFilter[0].label,
            ),
          ]);
        }
      } else {
        setDataSource([...dataSet]);
      }
    };

    // Method to handle the radio button change
    const handlePeriodSelectorChange = (e) => {
      setTriggerPeriod(e.target.value);
      getData(e.target.value);
      if (borderRef && borderRef.current) {
        borderRef.current.style.left =
          e.target.value === 'week_triggers' ? '0px' : '60px';
      }
    };

    const getValues = (list) => {
      if (list && list.length > 0) {
        return list.map((item) => {
          return item.value;
        });
      }
      return [];
    };

    const onOpportunitiesClick = (text, rec, degree) => {
      if (text === '0') return;
      const selectedFilter =
        props.globalDataStore.multipleFilters.filterList.filter(
          (x) => x.label === rec.filters,
        );
      props.dataStore.summaryFilter = props.dataStore.summaryFilter || {};
      props.dataStore.summaryFilter.selectedId = selectedFilter[0].value || -1;
      props.dataStore.summaryFilter.dropdownSelectedId =
        props.dataStore.networkFilters.selectedIdValue;

      /** if ALL is clicked in the table,
       * and the filter has any filter_type other than ALL, then this filter_type will have priority
       * if 1st/2nd is clciked the clicked one has priority than that in the filters */

      if (degree === 'all' && selectedFilter[0].filter_type !== 'all') {
        props.dataStore.summaryFilter.filterType =
          selectedFilter[0].filter_type;
      } else {
        props.dataStore.summaryFilter.filterType = degree;
      }

      if (props.dataStore.summaryFilter.filterType !== 'all') {
        props.dataStore.summaryFilter.file_imports = props.myFileImportsIds;
        props.dataStore.summaryFilter.import_source =
          getValues(importSourceList);

        if (props.dataStore.summaryFilter.filterType !== 'first_degree') {
          /** team files should be passed for sencond/combined */
          props.dataStore.summaryFilter.team_file_imports = props.sharedFileIds;
        }
      }

      props.dataStore.summaryFilter.opportunitiesPeriod =
        triggerPeriod.split('_')[0];
      props.dataStore.summaryFilter.ownerTypeId = 'None';

      setModalVisibility(true);
    };

    const columns = [
      {
        title: (
          <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            <span>Prospect Filters</span>
            <Tooltip
              placement="top"
              color="var(--color-primary)"
              title="All Filters will display here unless a specific filter is selected"
            >
              <HelpIcon
                className="info-icon"
                height="14px"
                width="14px"
                cursor="pointer"
              />
            </Tooltip>
          </div>
        ),
        key: 'prospect_filters',
        children: [
          {
            title: <div></div>,
            dataIndex: 'filters',
            key: 'filters',
            width: 190,
            className: 'column-border',
          },
        ],
      },
      {
        title: 'Opportunities',
        key: 'opp',
        align: 'left',
        children: [
          {
            title: (
              <div className="sub-header">
                <div className="high-indicator" />
                1st
              </div>
            ),
            key: 'oppFirstDegree',
            dataIndex: 'oppFirstDegree',
            align: 'center',
            render: (text, record, index) => {
              return (
                <div
                  className={text && text !== '0' ? 'pointer' : ''}
                  onClick={() => {
                    onOpportunitiesClick(text, record, 'first_degree');
                  }}
                >
                  {text}
                </div>
              );
            },
          },
          {
            title: (
              <div className="sub-header">
                <div className="medium-indicator" />
                2nd
              </div>
            ),
            key: 'oppSecondDegree',
            dataIndex: 'oppSecondDegree',
            align: 'center',
            render: (text, record, index) => {
              return (
                <div
                  className={text && text !== '0' ? 'pointer' : ''}
                  onClick={() => {
                    onOpportunitiesClick(text, record, 'second_degree');
                  }}
                >
                  {text}
                </div>
              );
            },
          },
          {
            title: <div className="sub-header">All</div>,
            key: 'oppAll',
            dataIndex: 'oppAll',
            align: 'center',
            render: (text, record, index) => {
              return (
                <div
                  className={text && text !== '0' ? 'pointer' : ''}
                  onClick={() => {
                    onOpportunitiesClick(text, record, 'all');
                  }}
                >
                  {text}
                </div>
              );
            },
          },
        ],
      },
    ];

    return (
      <div className="loader-wrapper">
        {isLoading && (
          <LoaderIcon className="aid-sidecar-ext-logo-spin-icon-only" />
        )}
        <div
          className={
            isLoading
              ? 'dashboard-item opportunities aid-div-disable'
              : 'dashboard-item opportunities aid-div-enable'
          }
        >
          <div className="opportunities-header">
            <div style={{ display: 'flex' }}>
              <div className="dashboard-item-title">
                Opportunities
                <span className="second-title"> by Filters</span>
              </div>

              <div className="opportunities-filter">
                <Radio.Group
                  onChange={handlePeriodSelectorChange}
                  defaultValue="week_triggers"
                >
                  {/* <Radio.Button className="radio-style" value="day_triggers">
                    Recent
                  </Radio.Button> */}
                  <Radio.Button className="radio-style" value="week_triggers">
                    Week
                  </Radio.Button>
                  <Radio.Button className="radio-style" value="month_triggers">
                    Month
                  </Radio.Button>
                </Radio.Group>
                <div className="bottom-line" ref={borderRef} />
              </div>
            </div>

            <div>
              <CloseCircleFilled
                onClick={props.onCloseButtonClick}
                style={{ color: 'var(--color-secondary)' }}
              />
            </div>
          </div>
          <Divider className="dash-board-divider" />
          {props.sourceImportId && props.sourceImportId.length > 0 ? (
            <div className="dashboard-item-empty">
              {' '}
              <p>View currently not available for CSV Imports</p>
            </div>
          ) : !dataSource || dataSource.length === 0 ? (
            <div className="dashboard-item-empty">
              {' '}
              <p>No results</p>
            </div>
          ) : (
            <div id="opp-table" className="opportunities-content">
              <Table
                pagination={false}
                size="small"
                columns={columns}
                dataSource={dataSource}
                scroll={{ y: 170 }}
              />
            </div>
          )}
        </div>
        {modalVisibility && (
          <PeopleViewModal
            onCancel={() => setModalVisibility(false)}
            showCompanyProfile={props.showCompanyProfile}
          />
        )}
      </div>
    );
  }),
);

export default Opportunities;
