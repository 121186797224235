import './index.css';

import { inject, observer } from 'mobx-react';

import { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Select from 'react-select';
import axios from 'axios';
import { requestWithAuth } from '../../services/api';

let source;
class MultiDataSearch extends Component {
  state = {
    options: [],
    showSearch: false,
  };

  handleSearchKeys = (id) => {
    switch (id) {
      case 'relmap_org':
        return 'ai_org';
      case 'org_name':
        return 'ai_org';
      case 'previous_experience_org_name':
        return 'ai_org';
      default:
        return id;
    }
  };

  handleInputChange = (value) => {
    const options = [];
    const { id } = this.props;
    if (value.length > 0) {
      this.setState({ showSearch: true });
      if (source) source.cancel('Operation canceled by the user.');
      const { CancelToken } = axios;
      source = CancelToken.source();
      const params = {};
      params.lookup_type = 'n2';
      params.search_key = this.handleSearchKeys(id);
      params.search_value = value;
      if (
        id !== 'relmap_org' &&
        this.props.dataStore.networkFilters.filter_type
      ) {
        params.filter_type = this.props.dataStore.networkFilters.filter_type;
      }
      requestWithAuth('auto-suggest-new', 'GET', params, null, source.token)
        .then((response) => {
          if (response && response.result) {
            response.result.map((data) => {
              if (data) {
                switch (id) {
                  // case 'relmap_org':
                  //   options.push({ value: data.ai_org_id, label: data.name.toTitleCase(), labelValue: data.name });
                  //   break;
                  case 'relmap_org':
                  case 'org_name':
                  case 'previous_experience_org_name':
                    options.push({
                      value: data.ai_org_id,
                      label: data.name
                        ? data.name.toString().toTitleCase()
                        : '',
                      labelValue: data.name,
                    });
                    break;
                  default:
                    options.push({
                      value: data,
                      label: data.toString().toTitleCase(),
                    });
                    break;
                }
              }
              return '';
            });
          }
          this.setState({ options });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      this.setState({ showSearch: false });
    }
  };

  resetMapZoom = () => {
    if (this.props.dataStore.lookUpLists.map) {
      this.props.dataStore.lookUpLists.map.flyTo({
        zoom: 3,
        center: [-90.039891, 42.058919],
        speed: 2,
      });
    }
  };

  handleChange = (selected) => {
    const { id, from } = this.props;
    if (
      selected &&
      selected.length > 0 &&
      document.getElementById(`${id}_${from}_search_icon`) !== null
    )
      document.getElementById(`${id}_${from}_search_icon`).style.display =
        'none';
    else if (document.getElementById(`${id}_${from}_search_icon`) !== null)
      document.getElementById(`${id}_${from}_search_icon`).style.display =
        'block';
    if (from === 'masterFilter') {
      this.props.globalDataStore.masterFiltersOnSelect[id] = selected;

      if (id === 'relmap_org') {
        if (
          this.props.globalDataStore.masterFiltersOnSelect.relmap_org &&
          this.props.globalDataStore.masterFiltersOnSelect.relmap_org.length > 0
        ) {
          this.props.globalDataStore.masterFiltersOnSelect.filter_type = 'all';
        }
      }
    } else if (from === 'profile') {
      if (this.props.onSelect) this.props.onSelect(selected, id);
    } else {
      this.props.dataStore.networkFilters[id] = selected;

      if (id === 'relmap_org') {
        if (
          this.props.dataStore.lookUpLists.isRelationPathSelected &&
          (!this.props.dataStore.networkFilters.relmap_org ||
            this.props.dataStore.networkFilters.relmap_org.length <= 0)
        ) {
          this.props.dataStore.networkFilters.filter_type = 'second_degree';
        } else {
          this.props.dataStore.networkFilters.filter_type = 'all';
        }
      }
      if (
        this.props.dataStore.lookUpLists.map &&
        this.props.dataStore.lookUpLists.map.getZoom() > 3
      )
        this.resetMapZoom();
      else this.props.dataStore.networkFilters.fetchData = true;
      if (this.props.onSelect) this.props.onSelect();
    }
  };

  debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this;
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  handleScrollToBottom = () => {
    if (document.getElementById('user-filter-parent'))
      document.getElementById('user-filter-parent').scrollBy({
        top: 250,
        behavior: 'smooth',
      });
  };

  render() {
    const {
      id,
      isMulti,
      isDisabled = false,
      placeholder,
      isClearable = true,
      from,
      val,
    } = this.props;
    const selectedOptionNetwork = this.props.dataStore.networkFilters[id];
    const selectedOptionMaster =
      this.props.globalDataStore.masterFiltersOnSelect[id];
    const value =
      from === 'masterFilter'
        ? selectedOptionMaster
        : from === 'profile'
          ? val
          : selectedOptionNetwork;
    const { options } = this.state;
    return (
      <>
        {(from === 'masterFilter' || (value && value.length > 0)) &&
          id !== 'relmap_org' && (
            <div className={'placeholder-active'}>{placeholder}</div>
          )}
        <Select
          placeholder={placeholder}
          value={value}
          isMulti={isMulti}
          isDisabled={isDisabled}
          className={'multi-auto-suggest multi-auto-suggest-new'}
          classNamePrefix="ai"
          isSearchable
          isClearable={isClearable}
          hideSelectedOptions={true} // FIX: issue with 2 compnies with same id on selected gets both cleared
          cropWithEllipsis
          closeMenuOnSelect={false}
          closeMenuOnScroll
          filterOption={(candidate, data) => {
            if (data) return true;
          }}
          // onMenuScrollToBottom={() => {
          //   this.handleScrollToBottom();
          // }}
          onFocus={this.inFocus}
          onChange={this.handleChange}
          onInputChange={this.debounce(this.handleInputChange, 500)}
          options={options}
          onBlur={() => {
            this.setState({
              options: [],
            });
          }}
          noOptionsMessage={() => null}
          components={{
            DropdownIndicator: () => (
              <>
                {' '}
                {this.state.showSearch && (
                  <SearchOutlined className="search-icon" />
                )}
              </>
            ),
            IndicatorSeparator: () => null,
          }}
        />
      </>
    );
  }
}

export default inject('dataStore')(
  inject('globalDataStore')(observer(MultiDataSearch)),
);
