import './index.css';

import { Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import useDeepEffect from '../../hooks/useDeepEffect';
import { splitNumberWithCommas } from '../../utils/general';

const InputGroup = Input.Group;
const { Option } = Select;

const NumberRangePicker = inject('dataStore')(
  observer((props) => {
    const [selectedOption, setSelectedOption] = useState('between');
    const [valueOne, setValueOne] = useState(null);
    const [valueTwo, setValueTwo] = useState(null);

    useEffect(() => {
      document.addEventListener('wheel', disableIncrementOnScroll);

      return () =>
        document.removeEventListener('wheel', disableIncrementOnScroll);
    }, []);

    const disableIncrementOnScroll = () => {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    };

    /** https://medium.com/better-programming/how-to-use-the-react-hook-usedeepeffect-815818c0ad9d */
    useDeepEffect(() => {
      const { number } = props;
      if (number && number.key && number.value && number.value.length > 0) {
        setSelectedOption(number.key);
        if (valueOne !== number.value[0])
          setValueOne(splitNumberWithCommas(number.value[0]) || '');
        setValueTwo(
          splitNumberWithCommas(
            number.key === 'between' ? number.value[1] : number.value[0],
          ) || '',
        );
      } else {
        if (number && number.key && number.key === 'exact') {
          setSelectedOption('exact');
        } else {
          setSelectedOption('between');
        }
        setValueOne(null);
        setValueTwo(null);
      }
    }, [props.number]);

    useEffect(() => {
      const { id } = props;
      if (valueOne && valueTwo) {
        if (valueOne === '0' && valueTwo === '0') {
          props.dataStore.networkFilters[id] = null;
          setValueOne(null);
          setValueTwo(null);
        } else {
          onRangeSelected();
        }
      } else if (valueOne === '' && valueTwo === '') {
        onRangeSelected();
      }
    }, [valueOne, valueTwo]);

    // function to make ui changes based on exact and between dropdown choosing
    const onDropDownSelect = (value) => {
      setSelectedOption(value === '1' ? 'between' : 'exact');
      if (valueOne || valueTwo) {
        if (value !== '1') {
          setValueTwo(valueOne);
        } else {
          onRangeSelected();
        }
      }
      if (props.onSelect) props.onSelect();
    };

    const onRangeChange = (event) => {
      const { id } = event.target;
      let { value } = event.target;

      value = value.replaceAll(',', '');
      if (!value.match(/^\d*$/)) return;

      if (
        (props.id === 'current_org_years' ||
          props.id === 'total_years_of_experience') &&
        value > 100
      )
        value = 100;

      const formattedVal = splitNumberWithCommas(value) || '';
      if (id === 'minValue') {
        setValueOne(formattedVal);
      } else if (id === 'maxValue') {
        setValueTwo(formattedVal);
      } else if (id === 'exactValue') {
        setValueOne(formattedVal);
        setValueTwo(formattedVal);
      }
      if (props.onSelect) props.onSelect();
    };

    const onRangeSelected = () => {
      const { onValueSelected } = props;
      if (!valueOne && !valueTwo) {
        onValueSelected(props.id, {});
      } else if (parseInt(valueOne) >= 0 && parseInt(valueTwo) >= 0) {
        const numberSelected = {
          key: selectedOption,
          value: [valueOne, valueTwo],
        };
        onValueSelected(props.id, numberSelected);
      }
    };

    return (
      <InputGroup>
        <div className="range-picker-parent-new">
          <Select
            bordered={false}
            className="range-picker"
            popupClassName="range-picker-dropdown"
            style={{ minWidth: 100, height: 40 }}
            value={selectedOption === 'between' ? '1' : '2'}
            onChange={onDropDownSelect}
          >
            <Option value="1">Between</Option>
            <Option value="2">Exact</Option>
          </Select>
          {selectedOption === 'between' ? (
            <div
              className={
                props.id === 'current_org_years' ||
                props.id === 'total_years_of_experience'
                  ? 'flex'
                  : 'flex large-content'
              }
            >
              <Input
                type="text"
                id="minValue"
                placeholder="##"
                value={valueOne}
                onChange={onRangeChange}
                style={{ width: '42%', textAlign: 'center' }}
                disabled={props.disabled || false}
              />
              <Input
                style={{
                  pointerEvents: 'none',
                  backgroundColor: '#fff',
                  width: '16%',
                  padding: 0,
                  textAlign: 'center',
                }}
                placeholder="~"
                disabled
              />
              <Input
                type="text"
                id="maxValue"
                placeholder="##"
                value={valueTwo}
                className="last-input"
                onChange={onRangeChange}
                style={{ width: '42%', textAlign: 'center' }}
                disabled={props.disabled || false}
              />
            </div>
          ) : (
            <Input
              type="text"
              id="exactValue"
              placeholder={props.placeholder}
              value={valueOne}
              className="last-input"
              onChange={onRangeChange}
            />
          )}
        </div>
      </InputGroup>
    );
  }),
);

export default NumberRangePicker;
