import './index.scss';
import { inject, observer } from 'mobx-react';
import { useEffect, useState, useRef } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Modal } from 'antd';

import ExportIcon from '../../assets/icons/ai_export.svg?react';

import TagsNew from '../TagsNew';
import SelectClubs from '../SelectClubs';
import { showConfirm } from '../../utils/popup';
import { requestWithAuth } from '../../services/api';
import { splitNumberWithCommas } from '../../utils/general';
import {
  handleCrmIconsAfterIntegration,
  handleCrmLabels,
  handleCrmSync,
} from '../../utils/crmMethods';
import Tags from '../Tags';

const { confirm } = Modal;

const RowExtra = ({
  rec,
  updatePerson,
  handleExportCsvClick,
  handleExportFromRowClick,
  selectedProfileIds = [],
  refreshData,
  ...props
}) => {
  const flags = useFlags();
  const navigate = useNavigate();
  const modalRef = useRef(null);

  const [clubCode, setClubCode] = useState();
  const [crmOk, setCrmOk] = useState(false);
  const [showExportPopUp, setShowExportPopUp] = useState(false);
  const [nameOfImport, setNameOfImport] = useState('');

  useEffect(() => {
    if (crmOk) {
      handleCrmConfirm(clubCode);
    }
  }, [crmOk]);

  const onCrmTokenExpiry = (url) => {
    navigate('/connect/export', {
      state: {
        crmSource: url === 'redtail-auth/0' ? 'rt' : 'cm',
        response: url === 'redtail-auth/0' ? 'rt' : 'cm',
      },
    });
  };

  const fetchUserTypeData = () => {
    requestWithAuth('user-type', 'GET', null, null, null).then((response) => {
      if (response && response.results && response.results[0]) {
        props.dataStore.currentUser.creditCount = response.results[0].credits;
      }
    });
  };

  function handleCrmConfirm(clubCode) {
    // setIsLoading(true);

    const body = {};
    const params = {};
    const person = [];

    if (selectedProfileIds && selectedProfileIds.length > 0) {
      /** Multiple rows click */
      selectedProfileIds.map((profile) => {
        if (profile.b2b_id) person.push(profile.b2b_id);
        if (profile.b2c_id) person.push(profile.b2c_id);
      });
    } else {
      /**Row hover click */
      if (rec.b2b_id) person.push(rec.b2b_id);
      if (rec.b2c_id) person.push(rec.b2c_id);
    }

    body.n2_person = person;

    if (props.dataStore.currentUser.org_name)
      body.org_name = props.dataStore.currentUser.org_name;

    if (clubCode) body.club_code = clubCode.value;

    // handleCrmSync - is a method in utilis/crmsync.js
    // if any crm is integrated - call the method, else redirect to crm import page.
    // when handleCrmSync returns , it enteres the then part of promise and makes the progress bar invisible,
    // also unchecks the selected checkboxes.
    if (props.dataStore.currentUser.crm) {
      handleCrmSync(
        props.dataStore.currentUser.ownerId,
        props.dataStore.currentUser.crm,
        body,
        onCrmTokenExpiry,
        'progress_people_list',
        props.dataStore.networkFilters,
        params,
      ).then(() => {
        // setSelectedRowKeys([]);
        // setSelectedProfileIds([]);
        // changeSelectedProfileIds([]);
        // setN2PersonId([]);
        // setFromRow(false);
        setCrmOk(false);
        // id1 = null;
        // id2 = null;
        // clearCrmCredentials();
        if (!props.dataStore.currentUser.isAdmin) fetchUserTypeData();
        // setIsLoading(false);
      });
    } else {
      navigate('/connect/export');
    }
  }

  const exportPopUp = () => {
    let totalContactSelected = 1;
    if (selectedProfileIds && selectedProfileIds.length > 0) {
      totalContactSelected = selectedProfileIds.length;
    }
    const additionalCredits =
      totalContactSelected - props.dataStore.currentUser.creditCount;
    const content = (
      <>
        You selected {splitNumberWithCommas(totalContactSelected)}
        {totalContactSelected === 1 ? ' profile ' : ' profiles '} to export
        <br />
        {totalContactSelected <= props.dataStore.currentUser.creditCount
          ? 'Please Confirm'
          : `You require an additional ${splitNumberWithCommas(
              additionalCredits,
            )} ${additionalCredits > 1 ? ' credits ' : ' credit '}to proceed`}
      </>
    );
    showConfirm({
      className: 'small-popup center-align',
      title: 'Confirm Export',
      closable: true,
      content: content,
      okText:
        totalContactSelected <= props.dataStore.currentUser.creditCount
          ? 'Yes'
          : 'Buy More',
      onOk: () => {
        if (totalContactSelected <= props.dataStore.currentUser.creditCount) {
          setCrmOk(true);
        } else {
          /**Redirect to buy credits */
          navigate('/account/credits');
        }
      },
      onCancel: () => {
        // onConfirmPopUpCancel();
        setCrmOk(false);

        return false;
      },
    });
  };

  const clubModel = () => {
    modalRef.current = confirm({
      icon: null,
      okButtonProps: {
        disabled: true,
      },

      onOk() {
        exportPopUp(clubCode);
        modalRef.current.destroy();
      },
      onCancel() {
        // onConfirmPopUpCancel();
        setClubCode();
        if (modalRef && modalRef.current) modalRef.current.destroy();
      },
      className: 'club-popUp',
      title: 'Select Club/Location',
      content: (
        <SelectClubs
          setClubVal={(val) => setClubCode(val)}
          modalRef={modalRef}
        />
      ),
    });
  };

  function handleCrmClick() {
    if (!props.dataStore.currentUser.crm) {
      navigate('/connect/export');
      return;
    }
    if (
      props.dataStore.currentUser.crm === 'sf' &&
      props.dataStore.currentUser.org_name === 'INV'
    )
      clubModel();
    else exportPopUp();
  }

  //function handleTagsClick(b2bId, b2cId) {}

  if (flags && flags.tagFolders) {
    return (
      <div className="row-popup">
        <div className="row-popup-content">
          <div
            className="row-popup-icon"
            onClick={(e) => {
              e.stopPropagation();
              handleCrmClick(rec.b2b_id, rec.b2c_id);
            }}
            style={{
              height: '100%',
              marginTop: '-8px',
            }}
          >
            <img
              src={handleCrmIconsAfterIntegration(
                props.dataStore.currentUser.crm,
              )}
              alt="Crm"
              style={{
                width: '16px',
                height: '17px',
              }}
            />
            <div className="tooltip-over">
              <img
                src={handleCrmIconsAfterIntegration(
                  props.dataStore.currentUser.crm,
                )}
                alt="Crm"
                style={{
                  width: '15.5px',
                  marginLeft: '2px',
                }}
              />
              <div className="crm-label">
                {handleCrmLabels(props.dataStore.currentUser.crm)}
              </div>
            </div>
          </div>

          <div
            className="row-popup-icon"
            onClick={(e) => {
              e.stopPropagation();

              if (selectedProfileIds && selectedProfileIds.length > 0) {
                /** Multiple rows click */
                handleExportFromRowClick(true);
              } else {
                /**Row hover click */
                const ids = [];
                if (rec.b2b_id) ids.push(rec.b2b_id);
                if (rec.b2c_id) ids.push(rec.b2c_id);

                handleExportFromRowClick(ids);
              }
            }}
          >
            <ExportIcon />
            <div className="tooltip-over">
              <ExportIcon /> Export
            </div>
          </div>

          <div
            onClick={(e) => {
              e.stopPropagation();
              //handleTagsClick(rec.b2b_id, rec.b2c_id);
            }}
          >
            <TagsNew
              from="selected-rows"
              selectedProfileIds={
                selectedProfileIds.length
                  ? selectedProfileIds
                  : [
                      {
                        b2b_id: rec.b2b_id,
                        b2c_id: rec.b2c_id || '',
                        full_name: rec.full_name,
                      },
                    ]
              }
              updatePerson={updatePerson}
              refreshData={refreshData}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="popupDiv"
      style={{ display: 'none', position: 'relative', left: 80 }}
    >
      <div className="popup">
        <div className="popupInner">
          <span
            onClick={(e) => e.stopPropagation()}
            style={{
              // width: '50%',
              height: '100%',
              padding: '14px 8px 10px 10px',
            }}
          >
            <Tags
              fromRow
              selectedProfileIds={[
                {
                  b2b_id: rec.b2b_id,
                  b2c_id: rec.b2c_id || '',
                },
              ]}
              updatePerson={updatePerson}
            />{' '}
          </span>
          <Tooltip
            placement="bottomRight"
            title={handleCrmLabels(props.dataStore.currentUser.crm)}
            mouseLeaveDelay={0}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                handleExportCsvClick(rec.b2b_id, rec.b2c_id);
              }}
              style={{
                // width: '50%',
                height: '100%',
                padding: '10px 10px 10px 8px',
                float: 'right',
              }}
            >
              <img
                src={handleCrmIconsAfterIntegration(
                  props.dataStore.currentUser.crm,
                )}
                alt="Crm"
                style={{
                  height: '25px',
                  float: 'right',
                }}
              />{' '}
            </div>
          </Tooltip>
        </div>
        <div className="popArrow" />
      </div>
    </div>
  );
};

export default inject('dataStore')(observer(RowExtra));
