import './index.scss';

import { Tooltip } from 'antd';

import iconLinkedIn from '../../assets/icons/ai_linked_in.svg';

const getLinkedInArray = (linkedInArray) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        maxWidth: '100px',
      }}
    >
      {linkedInArray &&
        linkedInArray.map((linkedIn) => {
          const link = `//${linkedIn}`;

          return (
            <span className="linkedin_link" key={linkedIn}>
              <Tooltip
                placement="bottomRight"
                color="var(--color-primary)"
                title={link}
              >
                <a
                  style={{
                    wordBreak: 'break-word',
                    display: 'block',
                    color: 'var(--color-dark)',
                  }}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    href={linkedIn}
                    src={iconLinkedIn}
                    alt={link}
                    className="linkedin-icon"
                  />
                </a>{' '}
              </Tooltip>
            </span>
          );
        })}
    </div>
  );
};

const HouseHoldCard = ({
  b2b_id,
  b2c_id,
  age,
  image,
  full_name,
  linkedIn,
  title,
  company,
  ai_org_id,
  showProfile,
  showCompanyProfile,
}) => {
  const renderHeading = () => {
    return (
      <div className="heading-title">
        <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
          <div>
            {
              image && <img src={image} />
              // : <Avatar
              //   fill='var(--color-low)'
              //   width='21px'
              //   height='25px'
              // />
            }
          </div>
          <div
            className="title-name"
            onClick={() => {
              showProfile(b2b_id, b2c_id);
            }}
          >
            {full_name && full_name.toString().toTitleCase()}
          </div>
        </div>
        {linkedIn && linkedIn.length > 0 && getLinkedInArray(linkedIn)}
      </div>
    );
  };

  return (
    <div className="household-card">
      {renderHeading()}
      <div className="card-data">
        {age !== null && age !== undefined && age > 0 && (
          <div className="household-age">
            Age <br />
            <label className="value">{age}</label>
          </div>
        )}
        <div>
          {title && <div className="household-title">{title}</div>}
          {company && (
            <div
              className={
                ai_org_id
                  ? 'house-hold-company company-link'
                  : 'house-hold-company'
              }
              title={company.toString().toTitleCase()}
              onClick={() => {
                if (!company || !ai_org_id) return;
                showCompanyProfile(ai_org_id, company);
              }}
            >
              {company}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const HouseholdMembers = ({
  houseHoldMemberslist = [],
  showProfile,
  showCompanyProfile,
}) => {
  return (
    <div className="household-members">
      <div
        className=" profile-box-header profile-titles"
        style={{ borderBottom: '1px solid var(--color-horizontal-line)' }}
      >
        Household Members
      </div>
      <div className="content-section">
        {houseHoldMemberslist.map((listItem) => {
          return (
            <HouseHoldCard
              key={`${listItem.b2b_id}-${listItem.b2c_id}`}
              full_name={listItem.full_name}
              age={listItem.age}
              image={listItem.image_url}
              title={listItem.title}
              company={listItem.org_name}
              linkedIn={listItem.norm_linkedin_urls}
              b2b_id={listItem.b2b_id}
              b2c_id={listItem.b2c_id}
              ai_org_id={listItem.ai_org_id}
              showProfile={showProfile}
              showCompanyProfile={showCompanyProfile}
            />
          );
        })}
      </div>
    </div>
  );
};

export default HouseholdMembers;
