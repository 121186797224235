import './index.scss';

import { useEffect, useState } from 'react';
import { Button, message } from 'antd';

import RemoveIcon from '../../assets/icons/ai_remove.svg?react';
import UserPlus from '../../assets/icons/user-plus-solid.svg?react';

import { requestWithAuth } from '../../services/api';

function AddOrRemoveButton(props) {
  const { full_name, b2b_id, b2c_id, showLoading, contact_status } = props;

  const [buttonLabel, setButtonLabel] = useState('Add to My Network');
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    const getButtonLabel = () => {
      if (contact_status === 'adding') {
        setButtonLabel('Adding to My Network');
        setDisable(true);
      } else if (contact_status === 'removing') {
        setButtonLabel('Removing from My Network');
        setDisable(true);
      } else if (contact_status === 'removable') {
        setButtonLabel('Remove from My Network');
        setDisable(false);
      } else if (contact_status === 'addable') {
        setButtonLabel('Add to My Network');
        setDisable(false);
      }
    };
    getButtonLabel();
  }, [contact_status]);

  const addOrRemoveNetwork = () => {
    showLoading(true);
    const body = {};
    let url = '';
    if (contact_status === 'removable') {
      // remove
      url = 'remove-network-contacts';
      body.b2b_id = b2b_id || '';
      body.b2c_id = b2c_id || '';
    } else if (contact_status === 'addable') {
      // add
      url = 'aidentified-contacts';
      const personId = [];
      personId.push(b2b_id ? b2b_id : b2c_id);
      body.n2_person = personId;
    } else {
      return;
    }

    requestWithAuth(url, 'POST', null, body).then((response) => {
      if (response && response.code && response.code === 200) {
        let messageString = '';
        if (contact_status === 'removable') {
          messageString = `We're removing ${full_name} from your network. We'll notify you when this process is done.`;
          setButtonLabel('Removing from My Network');
        }
        if (contact_status === 'addable') {
          messageString = `We're adding ${full_name} to your network. We'll notify you when this process is done.`;
          setButtonLabel('Adding to My Network');
        }

        message.success(messageString, 10);

        setDisable(true);
      } else if (response && response.errorCode) {
        message.error(response.errorCode, 5);
      }
      showLoading(false);
    });
  };

  if (contact_status === 'not_addable') {
    return null;
  }

  return (
    <Button
      disabled={disable}
      className={
        contact_status === 'addable' || contact_status === 'adding'
          ? 'add-button'
          : 'remove-button'
      }
      onClick={addOrRemoveNetwork}
      icon={
        contact_status === 'addable' || contact_status === 'adding' ? (
          <UserPlus />
        ) : (
          <RemoveIcon />
        )
      }
    >
      {buttonLabel}
    </Button>
  );
}
export default AddOrRemoveButton;
