import './index.css';

import { useState } from 'react';

import { Navigate } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import auth0 from 'auth0-js';

import Logo from '../../assets/icons/ai_ic_logo_gold.svg?react';
import ButtonLogo from '../../assets/icons/ai_logomark_relationships_blue.svg?react';
import LoaderAnimation from '../../components/AidLoader';

const { VITE_AUTH0_DOMAIN, VITE_AUTH0_CLIENT_ID, VITE_AUTH0_CONNECTION } =
  import.meta.env;
// import { withRouter, Redirect } from 'react-router-dom'

const ForgotPasswordAuth0 = (props) => {
  const webAuth = new auth0.WebAuth({
    domain: VITE_AUTH0_DOMAIN,
    clientID: VITE_AUTH0_CLIENT_ID,
  });
  const [isRedirect, setIsRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailId, setEmailId] = useState('');

  const handleSubmit = (values) => {
    const { email } = values;
    if (!email) {
      alert('Please enter your email.');
      return;
    }
    setEmailId(email);
    setIsLoading(true);
    webAuth.changePassword(
      {
        connection: VITE_AUTH0_CONNECTION,
        email: email,
      },
      function (err, resp) {
        if (err) {
          console.log(err.message);
          handleRedirect();
        } else {
          console.log(resp);
          handleRedirect();
        }
      },
    );
  };

  const handleRedirect = () => {
    setIsLoading(false);
    setIsRedirect(true);
  };

  if (isRedirect) {
    return (
      <Navigate
        to={{
          pathname: '/user/forgot-password-sent',
          state: { emailId },
        }}
      />
    );
  }

  return (
    <div className="form-container">
      <div className="form-content form-container-bottom">
        <div className="main loader-wrapper">
          {isLoading && <LoaderAnimation loaderId="forgot-pw-loader" />}
          <div className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
            <div className="logo-section">
              <img
                src="https://s.aidimg.com/cda/login/aidentified_logo_white.svg"
                alt="logo"
                width={170}
              />
              <Logo height={30} width={30} />
            </div>
            <div className="title-text">
              Forgot your password? <br /> Enter your email to be sent password
              reset instructions
            </div>
            <div>
              <Form
                className="form-tag-tweak"
                hideRequiredMark
                layout="vertical"
                onFinish={handleSubmit}
                autoComplete="__away"
                name="basic"
              >
                <div id="validation-status" />
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: `Please enter your email` },
                    {
                      required: false,
                      type: 'email',
                      message: 'Please enter an email in the correct format',
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item className="form-item" style={{ paddingTop: '20px' }}>
                  <Button
                    // type="primary"
                    htmlType="submit"
                    className="forgot-submit-button"
                    icon={<ButtonLogo width={20} height={20} />}
                  >
                    Send password reset email
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default withRouter(ForgotPassword);
export default ForgotPasswordAuth0;
