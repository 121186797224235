import '@gouch/to-title-case';
import './index.scss';

import { CheckCircleFilled } from '@ant-design/icons';
// import { formatEventName } from '../../utils/general';
// import { interests } from '../../utils/constants';

const TalkingPoints = (props) => {
  // object to store the keys to be dispalyed
  const displayListObject = {
    schools: 'You both studied at:',
    org_previous: 'You both worked at:',
    // titles: 'You have shared similar titles:',
    // industry: 'You have experience in similar industries:',
    // events: 'You both participated in similar Triggers:',
    // location_city: 'You both live in the same city:',
    // interests: 'You both share common modeled interests:',
    mutual_contacts: 'You both know:',
    // location_state: 'You both live in the same state:',
    // work_city: 'You both worked in the same city:',
    // weather_talking_point: 'Weather Update:',
  };

  // mutual contacts with click - onclick open the mutual contact profile
  const getMutualContacts = (mutual) => {
    return mutual.map((contact, index) => {
      return (
        <span>
          <span
            className="link-connectivity"
            onClick={() => {
              props.openProfileTab(contact.b2b_id, contact.b2c_id);
            }}
          >
            {contact.full_name}
          </span>
          {index !== mutual.length - 1 && mutual.length > 1 ? ', ' : ''}
        </span>
      );
    });
  };

  // const listItems = Object.entries(props.connectivityList).filter(
  //   (value) =>
  //     value[0] !== 'connectivity_strength' &&
  //     value[0] !== 'connectivity_score' &&
  //     value[0] !== 'wealth_estimate' &&
  //     value[1] &&
  //     value[1].length > 0,
  // );

  const listItems = Object.entries(props.connectivityList).filter(
    (value) =>
      (value[0] === 'mutual_contacts' ||
        value[0] === 'org_previous' ||
        value[0] === 'schools') &&
      value[1] &&
      value[1].length > 0,
  );

  // to seperate each array items with comma and "and" text
  const arrayToList = (array, arrayName) => {
    // const eventsArray = [];
    // if (arrayName === 'events') {
    //   array.map((event) => {
    //     if (event) {
    //       eventsArray.push(formatEventName(event));
    //     }
    //   });
    //   array = eventsArray;
    // } else if (arrayName === 'interests') {
    //   const interestLabelArray = interests.filter((intrest) =>
    //     array.includes(intrest.value),
    //   );
    //   const interestArray = interestLabelArray.map((value) => value.label);
    //   array = interestArray;
    // }

    return array
      .join(', ')
      .replace(/, ((?:.(?!, ))+)$/, ' and $1')
      .toTitleCase();
  };

  return (
    <>
      {listItems.length > 0 && (
        <div className="talking-points no-print">
          <div className="head-label">Talking Points</div>
          <div className="talking-point-data">
            {listItems.map((item, index) => {
              return (
                <div>
                  <CheckCircleFilled
                    style={{
                      color: 'var(--color-golden-end)',
                      fontSize: 12,
                      paddingRight: '9px',
                    }}
                  />

                  <span className="text-data">
                    {displayListObject[item[0]]}{' '}
                    {item[0] === 'mutual_contacts' ? (
                      <span>{getMutualContacts(item[1])}</span>
                    ) : (
                      // : item[0] === 'weather_talking_point' ? (
                      //   <span className="weather-points">{item[1]}</span>
                      // )
                      <span className="value-points">
                        {Array.isArray(item[1])
                          ? arrayToList(item[1], item[0])
                          : item[1]
                            ? item[1].toString().toTitleCase()
                            : ''}
                      </span>
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default TalkingPoints;
