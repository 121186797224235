import { useEffect, useRef } from 'react';

import isEqual from 'lodash/isEqual';

export default function useDeepEffect(effectFunc, deps) {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isFirstEffect = isFirst.current;
    const isSame = prevDeps.current.every((obj, index) => {
      return isEqual(obj, deps[index]);
    });
    if (isFirstEffect || !isSame) {
      effectFunc();
    }
    isFirst.current = false;
    prevDeps.current = deps;
  }, deps);
}
