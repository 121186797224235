/* eslint-disable */
// FIXME: please fix eslint errors
import './index.scss';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Select } from 'antd';
import { inject, observer } from 'mobx-react';
import cloneDeep from 'lodash/cloneDeep';
import { showConfirm } from '../../utils/popup';

const { Option } = Select;

const MultiFilterSelect = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const {
      value = props.dataStore.networkFilters.selectedIdValue,
      from = '',
      updateAfterSelect,
      variant = 'borderless',
    } = props;
    const navigate = useNavigate();

    useEffect(() => {
      if (
        props.globalDataStore.multipleFilters.filterList &&
        props.globalDataStore.multipleFilters.filterList.length > 0
      ) {
        props.globalDataStore.multipleFilters.filterList.sort((a, b) => {
          let filter1 = a.label.toLowerCase(),
            filter2 = b.label.toLowerCase();

          if (filter1 < filter2) {
            return -1;
          }
          if (filter1 > filter2) {
            return 1;
          }
          return 0;
        });
      }
    }, [props.globalDataStore.multipleFilters.filterList]);

    const saveToMasterFilterModal = (isUpdate) => {
      let content = '';
      if (!props.dataStore.isNonMasterFilterApplied) {
        showMasterFilterModal(isUpdate);
        return;
      }

      let nonMasterArray = [];

      if (
        props.dataStore.networkFilters.full_name &&
        props.dataStore.networkFilters.full_name !== '' &&
        props.dataStore.networkFilters.full_name !== ' ' &&
        props.dataStore.networkFilters.full_name.length > 0
      )
        nonMasterArray.push('Name');

      // if (
      //   props.dataStore.networkFilters.naics_codes &&
      //   props.dataStore.networkFilters.naics_codes.length > 0
      // ) {
      //   nonMasterArray.push('NAICS');
      // }

      if (
        props.dataStore.teamFilters.checkedTeamIds &&
        props.dataStore.teamFilters.checkedTeamIds.length > 0
      ) {
        nonMasterArray.push('Team');
      }

      if (nonMasterArray.length === 0) {
        showMasterFilterModal(isUpdate);
        return;
      }

      var appliedFields = nonMasterArray.join(', ');
      if (nonMasterArray.length > 0 && !nonMasterArray.includes('Team'))
        content = `${appliedFields} ${
          nonMasterArray.length > 1 ? 'fields' : 'field'
        } currently can't be saved to filter. Please deselect and save again.`;
      else if (nonMasterArray.includes('Team') && nonMasterArray.length === 1)
        content =
          "Team attributes currently can't be saved to filter. Please select all and save again.";
      else
        content = `${appliedFields} ${
          nonMasterArray.length > 1 ? 'fields ' : 'field '
        } 
        currently can't be saved to filter. Please "select all" for Teams/deselect other 
        mentioned attributes and save again.`;

      showConfirm({
        className: 'small-popup',
        closable: true,
        content: content,
        okText: 'Continue Anyway',
        onOk: () => {
          showMasterFilterModal(isUpdate);
        },
        onCancel: () => {
          return false;
        },
      });
    };
    const showMasterFilterModal = (isUpdate) => {
      props.globalDataStore.masterFiltersOnSelect = cloneDeep(
        props.dataStore.networkFilters,
      );
      if (
        props.globalDataStore.masterFiltersOnSelect.mapQuery &&
        props.globalDataStore.masterFiltersOnSelect.mapQuery.cities
      ) {
        props.globalDataStore.masterFiltersOnSelect.mapQuery =
          null; /**no mapquery needed for state aggrigation */
      }
      props.globalDataStore.masterFiltersOnSelect.is_update = !!isUpdate;
      if (!isUpdate) {
        props.globalDataStore.masterFiltersOnSelect.prospect_profile_name = '';
        props.globalDataStore.masterFiltersOnSelect.profile_id = 0;
        props.globalDataStore.masterFiltersOnSelect.default_view = 0;
      } else {
        props.globalDataStore.masterFiltersOnSelect.profile_id =
          props.dataStore.networkFilters.selectedIdValue;
      }

      props.globalDataStore.masterFilters = cloneDeep(
        props.globalDataStore.masterFiltersOnSelect,
      );

      props.modalDataStore.filterModal.isVisible = true;
      if (props.onUpdateOrCreate) {
        props.onUpdateOrCreate();
      }
    };

    const resetMapZoom = () => {
      if (props.dataStore.lookUpLists.map) {
        props.dataStore.lookUpLists.map.flyTo({
          zoom: 3,
          center: [-90.039891, 42.058919],
          speed: 2,
        });
      }
    };

    const onFilterItemSelected = (value) => {
      switch (value) {
        case -1: {
          /** No Filter Selected */
          if (from === 'prospect-finder') {
            props.dataStore.resetNetworkFilters();
            props.dataStore.importSourceKeys.isImportSourceSaved = false;
            props.dataStore.quick_search = '';
            localStorage.removeItem('tempNetworkFilters');
            if (
              props.dataStore.lookUpLists.map &&
              props.dataStore.lookUpLists.map.getZoom() > 3
            )
              resetMapZoom();
            else props.dataStore.networkFilters.fetchData = true;
          } else {
            props.globalDataStore.resetMasterFilter();
            props.dataStore.resetNetworkFilters();
            props.dataStore.resetAppliedMasterFilter();
            props.globalDataStore.multipleFilters.isFilterApplied = false;
            props.dataStore.networkFilters.selectedIdValue = value;
            props.globalDataStore.multipleFilters.selectedId = value;
            updateAfterSelect();
          }

          break;
        }
        case -2: {
          /** Manage my filters */
          navigate('/savedsearches');
          break;
        }
        case -3: {
          /** Save as new prospect filter */
          props.globalDataStore.reset();
          saveToMasterFilterModal();
          break;
        }
        case -4: {
          /** Update prospect filter */
          props.globalDataStore.reset();
          saveToMasterFilterModal(true);
          break;
        }
        default: {
          /** Any valid filter */
          if (from === 'prospect-finder') {
            props.dataStore.resetNetworkFilters();
            props.globalDataStore.multipleFilters.filterList.map((filter) => {
              if (filter.value === value) {
                props.dataStore.networkFilters.selectedIdValue = value;
                if (
                  props.dataStore.lookUpLists.map &&
                  props.dataStore.lookUpLists.map.getZoom() > 3
                ) {
                  resetMapZoom();
                }
                updateAfterSelect(
                  filter.filters,
                  filter.label,
                  filter.is_active,
                  filter.email_alert,
                  filter.separate_email_alert,
                  filter.filter_type,
                );
              }
            });
          } else {
            props.globalDataStore.resetMasterFilter();
            props.dataStore.resetNetworkFilters();
            props.dataStore.resetAppliedMasterFilter();
            props.globalDataStore.multipleFilters.isFilterApplied = true;
            props.dataStore.networkFilters.selectedIdValue = value;
            props.globalDataStore.multipleFilters.selectedId = value;
            updateAfterSelect();
          }

          break;
        }
      }
    };

    return (
      <Select
        placeholder={value}
        getPopupContainer={(trigger) => trigger.parentNode}
        defaultValue={
          from === 'prospect-finder'
            ? props.dataStore.networkFilters.selectedIdValue
            : props.globalDataStore.multipleFilters.selectedId
        }
        value={value || -1}
        className="multi-filter-select"
        dropdownMatchSelectWidth={false}
        variant={variant}
        onChange={onFilterItemSelected}
      >
        {/* show this if any change detected */}
        {from === 'prospect-finder' &&
          props.dataStore.isNetworkFilterApplied && (
            <Option key={-3} value={-3} className="first-item">
              <span style={{ color: 'var(--color-link)' }}>
                + Save as new Search
              </span>
            </Option>
          )}
        {/* show this if any filter selected + any change detected */}
        {from === 'prospect-finder' &&
          props.dataStore.networkFilters.selectedIdValue > 0 &&
          props.dataStore.isNetworkFilterApplied && (
            <Option key={-4} value={-4} className="first-item">
              <span style={{ color: 'var(--color-link)' }}>
                Update Saved Search
              </span>
            </Option>
          )}
        {
          <Option key={-1} value={-1}>
            {from === 'prospect-finder'
              ? 'No Saved Search Selected'
              : 'None Selected'}
          </Option>
        }
        {props.globalDataStore.multipleFilters.filterList.map((filter) => {
          return (
            <Option key={filter.value} value={filter.value}>
              {filter.label}
            </Option>
          );
        })}
        {
          <Option key={-2} value={-2}>
            Manage My Filters
          </Option>
        }
      </Select>
    );
  }),
);

export default MultiFilterSelect;
