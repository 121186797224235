import './MapBoxMap.css';

import { createRef, PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import MapBoxGL from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
// import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import { autorun } from 'mobx';
import axios from 'axios';
import geohash from 'ngeohash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import HelpIcon from '../../assets/icons/help-icon.svg?react';

import withRouter from '../../hooks/withRouter';
import { requestWithAuth } from '../../services/api';
import {
  commarize,
  splitNumberWithCommas,
  getSubscription,
} from '../../utils/general';
import { getBodyParams } from '../../utils/getBodyParams';
import ExportComponent from '../../components/ExportComponent';
import WarningMessage from '../../components/WarningMessage';
import LoaderAnimation from '../../components/AidLoader';

const { VITE_MAP_BOX_API_KEY } = import.meta.env;
const dataPopup = new MapBoxGL.Popup({ offset: 10 });
let isCallingWithBounds = false;
let source;
let peopleCountClick = false;

class RelMap extends PureComponent {
  mapContainerRef = createRef();

  map;

  constructor(props) {
    super(props);
    this.markers = [];
    this.props.dataStore.lookUpLists.isMapPage = true;
    this.state = {
      isSingleData: false,
      isLoading: false,
      mimpersonId: '',
      count: 0,
      mapWidth: '92vw',
    };
  }

  getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180); // deg2rad below
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c; // Distance in km
    if (this.map && this.map.getZoom() <= 8) d /= 3;
    else d /= 2;
    return d;
  };

  getStateAggsQuery = () => {
    const query = {
      cities: {
        terms: { field: 'home_state', size: 100 },
        aggs: {
          centroid: {
            geo_centroid: { field: 'geo' },
          },
        },
      },
    };

    if (this.props.dataStore.networkFilters.filter_type !== 'all')
      query.cities.aggs.b2b_id_count = { cardinality: { field: 'b2b_id' } };

    return query;
  };

  getGeoAggsQuery = () => {
    const { map } = this;
    if (!map) return;
    const geoQuery = {
      location_aggs: {
        filter: {
          geo_distance: {
            geo: `${map.getCenter().lat}, ${map.getCenter().lng}`,
            distance: `${this.getDistanceFromLatLonInKm(
              map.getBounds().getNorthEast().lat,
              map.getBounds().getNorthEast().lng,
              map.getBounds().getCenter().lat,
              map.getBounds().getCenter().lng,
            )}km`,
            distance_type: 'plane',
          },
        },
        aggregations: {
          hash_bucket: {
            geohash_grid: {
              field: 'geo',
              precision: 12,
              size: 500,
            },
          },
        },
      },
    };

    return geoQuery;
  };

  getGeoAggsQueryWithBounds = () => {
    const { map } = this;
    if (!map) return;
    const geoQuery = {
      location_aggs: {
        filter: {
          geo_bounding_box: {
            geo: {
              top_right: {
                lat: map.getBounds().getNorthEast().lat,
                lon: map.getBounds().getNorthEast().lng,
              },
              bottom_left: {
                lat: map.getBounds().getSouthWest().lat,
                lon: map.getBounds().getSouthWest().lng,
              },
              bottom_right: {
                lat: map.getBounds().getSouthEast().lat,
                lon: map.getBounds().getSouthEast().lng,
              },
              top_left: {
                lat: map.getBounds().getNorthWest().lat,
                lon: map.getBounds().getNorthWest().lng,
              },
            },
          },
        },
        aggregations: {
          hash_bucket: {
            geohash_grid: {
              field: 'geo',
              precision: 12,
              size: 500,
            },
          },
        },
      },
    };

    return geoQuery;
  };

  getGeoPolygonQuery = (data) => {
    const { map } = this;
    if (!map) return;
    if (
      data &&
      data.features &&
      data.features[0] &&
      data.features[0].geometry &&
      data.features[0].geometry.coordinates
    )
      return {
        location_aggs: {
          filter: {
            geo_shape: {
              geo: {
                shape: {
                  type: 'Polygon',
                  coordinates: data.features[0].geometry.coordinates,
                },
                relation: 'intersects',
              },
            },
          },
          aggregations: {
            hash_bucket: {
              geohash_grid: {
                field: 'geo',
                precision: 12,
                size: 500,
              },
              aggs: {
                id_count: {
                  cardinality: {
                    field: 'id',
                  },
                },
              },
            },
          },
        },
      };

    return;
  };

  getBody = (withBounds, areaData) => {
    let aggs;
    if (this.map && this.map.getZoom() > 3 && withBounds)
      aggs = this.getGeoAggsQueryWithBounds();
    else if (areaData) aggs = this.getGeoPolygonQuery(areaData);
    else if (this.map && this.map.getZoom() > 3) aggs = this.getGeoAggsQuery();
    else aggs = this.getStateAggsQuery();
    if (!this.props.dataStore.networkFilters.hasSavedMapQuery)
      this.props.dataStore.networkFilters.mapQuery = aggs;
    this.props.dataStore.networkFilters.hasSavedMapQuery = false;

    return getBodyParams(
      this.props.dataStore.networkFilters,
      this.props.dataStore.lookUpLists,
      this.props.dataStore.superConnectorFilter,
      this.props.dataStore.teamFilters,
      this.props.dataStore.currentUser,
      this.props.dataStore.mappedRelationshipsFilter,
      this.props.modalDataStore.profileModal,
      this.props.dataStore.groupByValues,
      true,
    );
  };

  /* query with state aggregation,
        called for first time and for zoom level <= 3 */
  fetchStateAggregateData = (isFirstTime) => {
    const body = this.getBody() | {};

    if (!body.map_query) return;

    if (source) source.cancel('Operation canceled by the user.');
    const { CancelToken } = axios;
    source = CancelToken.source();

    const params = {};

    // if anything is searched, pass that
    if (this.props.dataStore.quick_search)
      params.quick_search = this.props.dataStore.quick_search;
    if (this.props.dataStore.networkFilters.filter_type) {
      params.filter_type = this.props.dataStore.networkFilters.filter_type;
    }

    requestWithAuth(
      'map-view-counts-new',
      'POST',
      params,
      body,
      source.token,
    ).then((response) => {
      if (!response) return;
      if (isFirstTime) {
        this.setState({
          data: response,
        });
      } else {
        this.updateMap(response);
      }
    });
    this.props.dataStore.networkFilters.fetchData = false;
  };

  // Query with geoBounds , sorting and geohashGridAggregation
  fetchGeoAggregationData = (withBounds, areaData) => {
    const body = this.getBody(withBounds, areaData);

    if (!body.map_query) return;

    if (source) source.cancel('Operation canceled by the user.');
    const { CancelToken } = axios;
    source = CancelToken.source();

    const params = {};

    // if anything is searched, pass that
    if (this.props.dataStore.quick_search)
      params.quick_search = this.props.dataStore.quick_search;
    if (this.props.dataStore.networkFilters.filter_type) {
      params.filter_type = this.props.dataStore.networkFilters.filter_type;
    }

    requestWithAuth(
      'map-view-counts-new',
      'POST',
      params,
      body,
      source.token,
    ).then((response) => {
      if (!response) return;
      this.updateMap(response);
    });
    this.props.dataStore.networkFilters.fetchData = false;
  };

  showProfile = (id, b2bId, b2cId) => {
    const { profileModal } = this.props.modalDataStore;
    profileModal.b2bId = b2bId;
    profileModal.b2cId = b2cId;
    profileModal.mimpersonId = id || '';
    profileModal.eventsId = '';
    profileModal.isVisible = true;

    this.setState({
      isLoading: false,
    });
    // if (document.getElementById('mapBox') != null)
    //   document.getElementById('mapBox').style.display = 'none';
  };

  // prettier-ignore
  fetchPersonData = (popup, data, isHover) => {
    // popup.setHTML('<div id=cover> <div id=cover-spin></div> </div>');

    const { hashKey, locArray, count } = data;

    const query = this.getBody(true);
    delete query.aggregations;

    if (locArray && locArray.length >= 3) {
      query.geo_query = {
        geo_polygon: {
          geo: {
            points: [
              hashKey,
              `${locArray[0]}, ${locArray[1]}`,
              `${locArray[0]}, ${locArray[3]}`,
              `${locArray[2]}, ${locArray[3]}`,
              `${locArray[2]}, ${locArray[1]}`,
            ],
          },
        },
      };
    }

    if (count > 1 || isHover) {
      popup.setHTML('<div id=cover> <div id=cover-spin></div> </div>');
      // query._source = ['_id', 'b2b_id', 'b2c_id', 'full_name', 'age', 'title', 'org_name'];
    } else {
      this.setState({
        isLoading: true
      });
    }

    query.size = count;

    const params = {};

    // if anything is searched, pass that
    if (this.props.dataStore.quick_search)
      params.quick_search = this.props.dataStore.quick_search;
    if (this.props.dataStore.networkFilters.filter_type) {
      params.filter_type = this.props.dataStore.networkFilters.filter_type;
    }

    // prettier-ignore
    requestWithAuth(
     'map-view-person-list-new',
     'POST',
      params,
      query,
      source.token
    ).then((response) => {
      if (!response) return;
      if (count > 1 || isHover) {
        this.showMlutiData(popup, response, count);
      } else {
        // this.showSingleData(popup, response);
        if (
          response &&
          response.hits &&
          response.hits.hits &&
          response.hits.hits[0] &&
          response.hits.hits[0]._source
        ) {
          const rec = response.hits.hits[0]._source;
          this.showProfile(null, rec.b2b_id, rec.b2c_id);
        }
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  };

  // prettier-ignore
  showMlutiData = (popup, response, count) => {

    // fetching arrays from response
    // prettier-ignore
    const divScroll = document.createElement('div');
    divScroll.className = 'scroll-content';

    response.hits.hits.map((item) => {
      let PersonDetails = 'name';
      PersonDetails = PersonDetails.replace(
        'name',
        item._source.full_name === '' || item._source.full_name === undefined
          ? ''
          : item._source.full_name,
      )

      const age =
        item._source.age === '' ||
          item._source.age === undefined ||
          item._source.age === 0
          ? ''
          : `, ${item._source.age}`;

      let orgDetails = 'titleOrgName';
      // prettier-ignore
      orgDetails = orgDetails
        .replace(
          'title',
          item._source.title === '' || item._source.title === undefined
            ? ''
            : item._source.title,
        )
        .replace(
          'OrgName',
          item._source.org_name === '' || item._source.org_name === undefined
            ? ''
            : `${item._source.title === ''
              ? `${item._source.org_name}`
              : `, ${item._source.org_name}`
            }`,
        );

      if (PersonDetails !== '') {
        PersonDetails = PersonDetails.replace(/'/g, '&#8217;').toString();
      }

      if (orgDetails !== '') {
        orgDetails = orgDetails.replace(/'/g, '&#8217;').toString();
      }

      const personClass = PersonDetails === '' ? 'notVisible' : 'onlyVisible';
      const orgClass = orgDetails === '' ? 'notVisible' : 'onlyVisible';

      // showdetail method is called while clicking
      // prettier-ignore

      const innerContent = '<div id=detailPopOver class=detail-main> '
        + ' <div class=detail-div>'
        + ' <div>'
        + ' <div class=' + personClass + ' > <b>'
        + PersonDetails
        + '</b>'
        + '<span>'
        + age
        + '</span>'
        + '</div>'
        + ' <div class=' + orgClass + ' > '
        + orgDetails
        + ' </div>'

        + ' </div>'
        + '<div class="arrow-detail">'
        + '<svg viewBox="0 0 492 492">'
        + ' <path d="M368.94,228.73,172,31.79a24.26,24.26,0,0,0-34.24,0l-14.5,14.5a24.24,24.24,0,0,0,0,34.24L288.63,245.91,123.07,411.47a24.28,24.28,0,0,0,0,34.25l14.51,14.49a24.25,24.25,0,0,0,34.23,0L368.94,263.09a24.43,24.43,0,0,0,0-34.36Z"/>'
        + ' </svg>'
        + '</div>'

        + ' </div>'
        + '</div>';

      const div2 = document.createElement('div');
      // div2.setHTML(innerContent)
      div2.innerHTML = innerContent;
      div2.onclick = () => { this.showProfile(item._id, item._source.b2b_id, item._source.b2c_id) };
      divScroll.append(div2);
    });

    const divHeading = document.createElement('div');
    divHeading.className = 'detail-heading';
    divHeading.innerHTML = `Found ${count} Relmap Connection(s)`;
    // divHeading.setHTML(`Found ${count} Relmap Connection(s)`)

    const div1 = document.createElement('div');
    div1.className = 'detailFirst';
    if (count > 1)
      div1.append(divHeading);
    div1.append(divScroll)


    const divElement = document.createElement('div');
    divElement.append(div1);
    popup.setDOMContent(divElement);

  };

  addMarkersToMap = (location, data) => {
    const { map } = this;
    if (!map) return;
    const el = document.createElement('div');
    el.className = 'marker';
    el.style.width = '15px';
    el.style.height = '15px';

    if (data && data.count && data.count > 1)
      el.style.backgroundColor = 'var(--color-border-blue)';
    else el.style.backgroundColor = 'var(--color-dark)';

    const marker = new MapBoxGL.Marker(el);

    marker.setLngLat(location).addTo(map);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;

    el.addEventListener('click', function (e) {
      const zoom = map.getZoom();

      if (zoom <= 3 && _this.markers) {
        // remove the existing markers before adding new markers
        _this.markers.map((marker) => marker.remove());
      }

      if (zoom >= 6) {
        // zoom In to show more data
        map.flyTo({
          zoom: 16,
          center: marker.getLngLat(),
          speed: 2,
        });
        // map.zoomTo((16), { center: marker.getLngLat() });
      } else {
        // zoom In to show more data
        map.zoomTo(zoom + 3, { center: marker.getLngLat() });
      }
    });

    const hoverPopup = new MapBoxGL.Popup({
      offset: 10,
      closeButton: false,
      className: 'hover-popup',
    });

    el.addEventListener('mouseenter', () => {
      if (
        map.getZoom() >= 16 &&
        data &&
        getSubscription(this.props.dataStore.currentUser)
      ) {
        if (dataPopup) {
          dataPopup._onClose();
        }
        const isHover = !!(data.count && data.count === 1);
        marker.setPopup(dataPopup);
        marker.togglePopup();
        _this.fetchPersonData(dataPopup, data, isHover);
      } else if (data) {
        hoverPopup.setText(commarize(data.count));
        marker.setPopup(hoverPopup);
        marker.togglePopup();
      }
    });

    el.addEventListener('mouseleave', () => {
      if (marker && hoverPopup) {
        hoverPopup._onClose();
      }
    });

    this.markers.push(marker);
  };

  componentDidMount() {
    // if (document.getElementById('searchResult') !== null)
    //   document.getElementById('searchResult').innerHTML = '';
    if (!getSubscription(this.props.dataStore.currentUser)) return;
    const { CancelToken } = axios;
    source = CancelToken.source();

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this3 = this;
    MapBoxGL.accessToken = VITE_MAP_BOX_API_KEY;
    // MapBoxGL.workerClass = MapboxWorker;
    const map = new MapBoxGL.Map({
      container: this.mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v9?optimize=true',
      // width: 'calc(100vw - 448px)',
      height: 'calc(100vh - 234px)',
      zoom: 3,
      minZoom: 3,
      maxZoom: 18.5,
      center: [-90.039891, 42.058919],
      renderWorldCopies: false,
    });
    map.addControl(new MapBoxGL.NavigationControl());
    this.map = map;

    const draw = new MapboxDraw({
      controls: {
        polygon: true,
        trash: true,
        point: false,
        line_string: false,
        combine_features: false,
        uncombine_features: false,
      },
    });

    if (this.props.dataStore.networkFilters.mapQuery)
      this.props.dataStore.networkFilters.hasSavedMapQuery = true;

    map.addControl(draw, 'top-left');
    // method called when we zoom and api is called only in the end of zoom
    map.on('zoomend', function () {
      if (draw) draw.deleteAll();
      /* for zoom level <= 3, show state aggregate data
                else show detail data */
      if (map.getZoom() <= 3) _this3.fetchStateAggregateData();
      else _this3.fetchGeoAggregationData(false);

      // if (map.getZoom() >= 16) {
      //     map.setStyle('"mapbox://styles/mapbox/light-v10');
      // }
    });
    // method called when we drag and api is called only in the end of drag
    map.on('dragend', function () {
      if (draw) draw.deleteAll();
      if (document.getElementById('date') !== null)
        document.getElementById('date').style.display = 'none';
      /* for zoom level <= 3, show state aggregate data
                so no need to call api on drag at this level */
      if (map.getZoom() > 3) {
        _this3.fetchGeoAggregationData(false);
      }
    });

    if (draw) {
      map.on('draw.create', updateArea);
      map.on('draw.delete', (e) => {
        draw.deleteAll();
        if (map.getZoom() <= 3) _this3.fetchStateAggregateData();
        else _this3.fetchGeoAggregationData(false);
      });
    }

    function updateArea(e) {
      let data = draw.getAll();
      if (data.features.length === 2) {
        /** delete previous polygon */
        const id = data?.features[0]?.id;
        data = draw.delete([id]).getAll();
      }
      if (data.features.length > 0) _this3.fetchGeoAggregationData(false, data);
    }

    this.autoUpdateDisposer = autorun(() => {
      if (
        this.props.dataStore.networkFilters.fetchData &&
        this.map &&
        getSubscription(this.props.dataStore.currentUser)
      ) {
        this.fetchGeoAggregationData(false);
      }
      if (this.props.dataStore.lookUpLists.showFullMapWidth !== undefined) {
        this.setState({
          mapWidth: this.props.dataStore.lookUpLists.showFullMapWidth
            ? '92vw'
            : 'calc(100vw - 448px)',
        });
      }
    });

    this.fetchStateAggregateData(true);

    this.setState({
      data: {},
    });
  }

  setProgress = (value) => {
    this.setState({
      isLoading: value,
    });
  };

  updateMap(data) {
    const { map } = this;
    if (!map) return;
    if (this.markers) {
      // remove the existing markers before adding new markers
      this.markers.map((marker) => marker.remove());
    }

    if (data) {
      if (
        data.aggregations &&
        data.aggregations.cities &&
        data.aggregations.b2b_id_count &&
        data.aggregations.b2b_id_count.value
      ) {
        // document.getElementById(
        //   'searchResult',
        // ).innerHTML = `${splitNumberWithCommas(
        //   data.aggregations.b2b_id_count.value,
        // )} Result(s)`;

        this.setState({ count: data.aggregations.b2b_id_count.value });
      } else if (
        data.aggregations &&
        data.aggregations.location_aggs &&
        data.aggregations.location_aggs.doc_count
      ) {
        // document.getElementById(
        //   'searchResult',
        // ).innerHTML = `${splitNumberWithCommas(
        //   data.aggregations.location_aggs.doc_count,
        // )} Result(s)`;

        this.setState({ count: data.aggregations.location_aggs.doc_count });
      } else if (data.hits && data.hits.total) {
        // document.getElementById(
        //   'searchResult',
        // ).innerHTML = `${splitNumberWithCommas(data.hits.total)} Result(s)`;

        this.setState({ count: data.hits.total });
      } else {
        this.setState({ count: 0 });
        // document.getElementById('searchResult').innerHTML = '';
      }

      if (data.aggregations) {
        this.setState({
          data,
        });
        /* result will be on aggregation-buckets,
                incase of query with state aggregation */
        if (data.aggregations.cities) {
          const cities = data.aggregations.cities.buckets;
          cities.map((city) => {
            if (city.centroid.location !== undefined && city.key !== 'FM') {
              const { lat } = city.centroid.location;
              const { lon } = city.centroid.location;
              const data = {
                count: city.doc_count,
              };
              this.addMarkersToMap([lon, lat], data);
            }
            return '';
          });
        }
        if (
          data.aggregations.location_aggs &&
          data.aggregations.location_aggs.hash_bucket
        ) {
          const geoAggs = data.aggregations.location_aggs.hash_bucket.buckets;
          if (geoAggs && geoAggs.length > 0) {
            isCallingWithBounds = false;
            geoAggs.map((geoPoint) => {
              if (geoPoint.key !== undefined) {
                const location = geohash.decode_bbox(geoPoint.key);
                const lat = location[2].toFixed(6);
                const lon = location[3].toFixed(6);
                const data = {
                  count: geoPoint.doc_count,
                  hashKey: geoPoint.key,
                  locArray: location,
                };
                this.addMarkersToMap([lon, lat], data);
              }
              return '';
            });
          } else if (!isCallingWithBounds) {
            isCallingWithBounds = !isCallingWithBounds;
            this.fetchGeoAggregationData(true);
          }
        }
      }
    }
  }

  componentWillUnmount() {
    if (source) source.cancel('Operation canceled by the user.');
    this.props.dataStore.lookUpLists.isMapPage = false;
    if (this.map) this.map.remove();
    this.map = null;
    this.props.dataStore.lookUpLists.map = null;
    if (!peopleCountClick) {
      this.props.dataStore.networkFilters.mapQuery = {};
      this.props.dataStore.networkFilters.hasSavedMapQuery = true;
    }
    if (this.autoUpdateDisposer) this.autoUpdateDisposer();
  }

  componentDidUpdate() {
    if (!document.getElementById('mapBox')) {
      // return if mapBox id is not found
      return;
    }
    if (this.map) this.props.dataStore.lookUpLists.map = this.map;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this3 = this;

    try {
      if (this.state) {
        const { data } = this.state;
        _this3.updateMap(data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  redirectToPeopleView = () => {
    peopleCountClick = true;
    this.props.navigate('/prospect-finder/people');
  };

  renderTopSection() {
    const showViewList = () => {
      return (
        <>
          <div>
            <span
              className="count-label"
              onClick={() => {
                if (
                  this.props.dataStore.networkFilters.mapQuery &&
                  this.props.dataStore.networkFilters.mapQuery.cities
                )
                  this.props.navigate('/prospect-finder/people');
                else this.redirectToPeopleView();
              }}
            >
              View List
            </span>
          </div>
        </>
      );
    };
    if (this.state.count > 0)
      return (
        <>
          {this.map && this.map.getZoom() > 3 ? (
            <ExportComponent
              dataCount={this.state.count}
              types="n2"
              pagination={{ defaultPageSize: 0, current: 0 }}
              fromMapPage
              navigateToPeopleView={this.redirectToPeopleView}
              setProgress={this.setProgress}
            />
          ) : (
            <>
              <div>{splitNumberWithCommas(this.state.count)} results</div>
              {showViewList()}
            </>
          )}
        </>
      );

    return showViewList();
  }

  render() {
    if (!getSubscription(this.props.dataStore.currentUser))
      return (
        <WarningMessage
          iconName="upgrade"
          content="To view executives, upgrade today!"
          buttonText="Subscribe"
        />
      );
    return (
      <>
        <div
          className="map-count"
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {this.renderTopSection()}
        </div>
        <div className="container loader-wrapper">
          {this.state.isLoading && <LoaderAnimation loaderId="map-loader" />}
          <div
            className={
              this.state.isLoading ? 'aid-div-disable' : 'aid-div-enable'
            }
          >
            <div className="search" />
            <div className="main-content">
              <div>
                <HelpIcon
                  id="map-draw-help"
                  height="14px"
                  width="14px"
                  className="draw-help"
                />
              </div>
              <div
                id="mapBox"
                style={{
                  width: this.state.mapWidth,
                }}
                ref={this.mapContainerRef}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withLDConsumer()(
  observer(withRouter(inject('dataStore', 'modalDataStore')(RelMap))),
);
