import { Button, Form, Input, message } from 'antd';
import { requestWithAuth } from '../../services/api';

function Comment(props) {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const handleSubmit = (values) => {
    if (values.comments.trim() === '') {
      message.error('Please input your feedback!');
      return;
    }
    const {
      b2b_id,
      b2c_id,
      first_name,
      last_name,
      org_name,
      title,
      norm_linkedin_urls,
    } = props.person;

    const body = {
      b2b_id: b2b_id,
      b2c_id: b2c_id,
      first_name: first_name || '',
      last_name: last_name || '',
      org_name: org_name || '',
      title: title || '',
      linkedin_urls: norm_linkedin_urls || [],
      comment: values.comments.trim(),
    };

    requestWithAuth('profile-comments', 'POST', null, body, null, null).then(
      (res) => {
        if (res) {
          form.resetFields();
          message.success('Feedback submitted');
          props.onCommentSend('user_comment_details', {
            user_comment: values.comments.trim(),
            created: new Date(),
            admin_status: null,
            admin_status_time: null,
          });
          // props.onCommentSend(values.comments.trim());
        }
      },
    );
  };

  return (
    <div className="profile-comment">
      <div className="profile-box-header profile-titles">Share Feedback</div>
      <div style={{ paddingLeft: '31px', width: '80%' }}>
        <p>
          If you have feedback regarding something that is incorrect in the
          profile or general suggestions, please let us know:
        </p>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            name="comments"
            rules={[
              {
                required: true,
                message: 'Please input your feedback!',
              },
            ]}
          >
            <TextArea className="text-area-content" id="comment-content" />
          </Form.Item>

          <Button className="send-btn" htmlType="submit">
            Send
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default Comment;
