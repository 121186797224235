import './index.css';

import { Checkbox, Tooltip, Switch } from 'antd';
import { Component } from 'react';
import isEqual from 'lodash/isEqual';

let defaultCheck = [];

class TeamMultiCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValues: [],
      checkAll: [],
      checkedList: [],
    };
  }

  // on change for checkbox.group
  onCheckChanged = (i, checkedList) => {
    const checked = this.state.checkedList;
    const defaultChecked = this.state.checkAll;
    // changing the checkall state according to the length of list
    if (this.props.checkboxList[i].users.length !== checkedList.length)
      defaultChecked[i] = false;
    else defaultChecked[i] = true;

    this.setState({
      checkAll: defaultChecked,
    });
    // if (checked[i].length === 1 && checkedList.length === 0) return;

    checked[i] = checkedList;
    this.setState({
      checkedList: checked,
    });

    let checkedArray = checked.toString().split(',').map(Number);
    checkedArray = checkedArray.filter((val) => val !== 0);

    // removing duplicate values from array
    const checkedArrayNew = Array.from(new Set(checkedArray));
    // passing checked values via props through onChecked
    this.setState({ selectedValues: checkedArrayNew });
    this.setState((state, props) => {
      state.selectedValues = checkedArrayNew;
      props.onChecked(
        state.selectedValues,
        props.id,
        checkedArray,
        defaultChecked,
      );
    });
  };

  // on change for checkbox
  onCheckAllChange = (i, checkedLists, checkedFlag) => {
    const checked = this.state.checkedList;
    const checkedState = this.state.checkAll;
    checked[i] = checkedLists;
    this.setState({
      checkedList: checked,
    });

    // changing the checked state according to the checkall
    if (!checkedFlag) {
      checked[i] = [];
      checkedState[i] = false;
    } else {
      const { checkboxList } = this.props;
      checked[i] = checkboxList[i].users.map((user) => {
        return user.value;
      });
      checkedState[i] = true;
    }

    this.setState({
      checkedList: checked,
      checkAll: checkedState,
    });
    let checkedArray = this.state.checkedList.toString().split(',').map(Number);
    checkedArray = checkedArray.filter((val) => val !== 0);
    const checkedArrayNew = Array.from(new Set(checkedArray));

    this.setState({ selectedValues: checkedArrayNew });
    this.setState((state, props) => {
      state.selectedValues = checkedArrayNew;
      props.onChecked(
        state.selectedValues,
        props.id,
        checkedArray,
        checkedState,
      );
    });
  };

  handleChecked = (nextProps) => {
    this.setState({
      selectedValues: nextProps.defaultList,
      checkedList: nextProps.defaultList,
    });

    for (let i = 0; i < nextProps.defaultList.length; i++) {
      if (
        nextProps.defaultList[i].length === nextProps.defaultTeamLengthArray[i]
      ) {
        defaultCheck[i] = true;
      } else {
        defaultCheck[i] = false;
      }
    }

    this.setState({ checkAll: defaultCheck });
    defaultCheck = [];
  };

  componentDidMount() {
    this.handleChecked(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps, this.props)) this.handleChecked(nextProps);
  }

  compare(a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  render() {
    const { checkboxList } = this.props;
    checkboxList.sort(this.compare);
    const checked = this.state.checkedList;
    const checkedValue = this.state.checkAll;

    return checkboxList.map((team, i) => {
      return (
        <div className="multi-checkbox-container">
          <div className="titleProspectCheckBox">
            <div className="site-checkbox-all-wrapper">
              <Switch
                checked={checkedValue[i]}
                size="small"
                onChange={(checkedFlag) =>
                  this.onCheckAllChange(i, checked[i], checkedFlag)
                }
              />
            </div>
            <Tooltip title={team.name}>
              <span className="titleProspectHover">{team.name}</span>
            </Tooltip>
          </div>

          {/* value field sets the checkboxes as checked */}
          <Checkbox.Group
            options={team.users}
            value={checked[i]}
            onChange={(checkedList) => this.onCheckChanged(i, checkedList)}
            className={checkedValue[i] ? 'checkbox-color-change' : ''}
          />
        </div>
      );
    });
  }
}
export default TeamMultiCheckbox;
