import './index.scss';
import { DragDropContainer } from 'react-drag-drop-container';
import { dashboardBoxItems } from '../../utils/constants';

const DragDropListItem = (props) => {
  const { id, onClick, targetKey } = props;

  return (
    <div>
      <DragDropContainer
        targetKey={targetKey}
        dragData={{ label: id }}
        disappearDraggedElement={false}
        zIndex={2000}
      >
        <div id={id} className="drag-drop-list-item" onClick={onClick}>
          {dashboardBoxItems[id]}
        </div>
      </DragDropContainer>
    </div>
  );
};

export default DragDropListItem;
