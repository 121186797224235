import './index.scss';

import { Button, message } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';

import iconGoogleSearch from '../../assets/icons/ai_google.svg';
import MyProfile from '../../assets/icons/ai_profile.svg?react';

import { requestWithAuth } from '../../services/api';
import { setCookie } from '../../utils/cookies';
import LoaderAnimation from '../AidLoader';
import MoreInfoSection from './moreInfoSection';

message.config({
  top: 80,
});
let source;

const GoogleContactsImport = () => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const { CancelToken } = axios;
    source = CancelToken.source();
    return () => {
      message.destroy();
      if (source) source.cancel('Operation canceled by the user.');
    };
  }, []);

  const validationSubmit = () => {
    setIsLoading(true);
    requestWithAuth('google-auth', 'POST', null, null, source.token).then(
      (response) => {
        if (response) {
          if (response.code === 200) {
            if (response.auth_url && response.auth_url !== '') {
              localStorage.setItem('import_source', 'google');
              const hostArray = window.location.host.split('.');
              if (hostArray && hostArray.length > 0 && hostArray[0]) {
                setCookie('slug', hostArray[0], 1);
              }
              window.open(response.auth_url, '_self');
            } else {
              message.error('Please try again', 5);
            }
          } else {
            message.error(response.message, 10);
            setIsLoading(false);
          }
        } else {
          message.error('Please try again', 5);
          setIsLoading(false);
        }
      },
    );
  };

  return (
    <div className="loader-wrapper">
      {isLoading && <LoaderAnimation loaderId="google-import-loader" />}
      <div
        className={
          isLoading
            ? 'google-contacts aid-div-disable'
            : 'google-contacts aid-div-enable'
        }
      >
        <div className="inner-container">
          <div>
            <label className="connect-to">Import</label>
            <div className="profile-icon">
              <div style={{ padding: 20 }}>
                <MyProfile />
              </div>
              <Button
                type="primary"
                className="import-button"
                onClick={validationSubmit}
              >
                Import Contacts
              </Button>
            </div>
          </div>
          <div>
            <div className="google-section">
              <img
                className="google-icon"
                src={iconGoogleSearch}
                alt="google"
              />
              <label className="google-label">Google Contacts</label>
            </div>
            <div style={{ padding: '20px 0' }}>
              <p className="paragraph-data">
                When you enter your Google credentials, you are granting
                Aidentified access to your contacts. Contacts are imported into
                the Aidentified platform and matched with our profiles. Through
                this matching, additional household and professional attributes
                are provided as well as relationship paths to prospects. We do
                not sync information back to Google and we do not store your
                credentials.
              </p>
              <MoreInfoSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleContactsImport;
