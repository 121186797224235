import './CrmCallback.css';

import { Button } from 'antd';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import qs from 'qs';

import withRouter from '../../hooks/withRouter';
import { requestWithAuth } from '../../services/api';
import { eraseCookie, getCookie } from '../../utils/cookies';
import LoaderAnimation from '../../components/AidLoader';

class CrmCallback extends Component {
  constructor(props) {
    super(props);
    const param = this.props.location.search;
    let slug = getCookie('slug');
    const hostArray = window.location.host.split('.');
    if (
      hostArray &&
      hostArray.length > 0 &&
      hostArray[0] &&
      !hostArray[0].startsWith('localhost')
    ) {
      slug = slug || hostArray[0];

      if (hostArray[0] !== slug) {
        window.location.assign(
          `https://${slug}.aidentified.com/crm/callback/${param}`,
        );
      }
    }
    eraseCookie('slug');
  }

  componentDidMount() {
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    if (
      query &&
      !(Object.keys(query).length === 0 && query.constructor === Object) &&
      query.code
    ) {
      const body = {
        code: query.code,
      };

      const crmSource = localStorage.getItem('crm_source');
      let url = '';
      switch (crmSource) {
        case 'sf':
          url = 'salesforce-auth';
          break;
        case 'ct':
          url = 'contactually-token';
          break;
        case 'pd':
          url = 'pipedrive-auth';
          break;
        case 'hs':
          url = 'hubspot-auth';
          break;
      }

      if (url) {
        requestWithAuth(url, 'POST', null, body, null).then((text) => {
          setTimeout(() => {
            this.props.navigate('/connect/export', {
              state: {
                crmSource,
                response: text,
              },
            });
          });
        });
      }
    }
  }

  render() {
    return (
      <div className="exception">
        <div className="imgBlock loader-wrapper">
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              display: 'block',
            }}
          >
            {' '}
            <LoaderAnimation style={{ top: '-20px' }} loaderId="crm-loader" />
          </div>
        </div>
        <div className="content">
          <h1>Processing</h1>
          <div className="actions">
            <a href="/connect/export">
              <Button type="primary">Back to settings</Button>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(inject('dataStore')(observer(CrmCallback)));
