import './index.scss';
import { Table } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';

const EventDescription = (props) => {
  const flags = useFlags();

  const data = [
    {
      id: 1,
      trigger: 'News Mention',
      description: 'Monitor News Mentions of your prospects and clients.',
      relevance:
        'A News Mention provides an opportunity to reach out or reconnect with a prospect or client.',
    },
    {
      id: 2,
      trigger: 'Investment Received',
      description: 'Company received an investment.',
      relevance:
        'Senior and middle management likely to have shares and may benefit from the investment depending on the size and conditions.',
    },
    {
      id: 3,
      trigger: 'Company Achieves Unicorn Status',
      description: 'Private company reaching $1billion valuation.',
      relevance:
        'Company will be most likely either be acquired, receive significant funding or IPO. Start to follow management teams and executives.',
    },
    {
      id: 4,
      trigger: 'Company Acquired',
      description: 'Company was sold.',
      relevance:
        'Senior and middle management likely to have shares and benefits from acquisition.',
    },
    {
      id: 5,
      trigger: 'IPO Filing',
      description:
        'Used by companies planning on going public to register their securities with the U.S Securities and Exchange Commission (SEC).',
      relevance:
        'Senior and middle management likely to have shares and benefit from IPO. IPO is approximately 3 to 6 months after filing.',
    },
    {
      id: 6,
      trigger: 'IPO',
      description:
        'An initial public offering (IPO) refers to the process of offering shares of a private corporation to the public in a new stock issuance.',
      relevance:
        'Shares are now tradable on an exchange. Depending on if a person has a lockup period, he/she can sell shares.',
    },
    {
      id: 7,
      trigger: 'SPAC',
      description:
        'Special Purpose Acquisition Companies (SPACs) also known as “blank check companies” allow private companies to go public through acquisition, rather than through a formal IPO (Initial Public Offering).',
      relevance:
        'SPAC executives generally receive 10-15 percent of the deal once they acquire the private company which has to be within 2 years of the SPAC’s IPO.',
    },
    {
      id: 8,
      trigger: (
        <>
          Insider Stock Sale, <br />
          Acquisition, <br />
          Purchase
        </>
      ),
      description:
        'When a company insider buys or sells stock on the open market, the law states that the company/insider must make the trade details public.',
      relevance:
        'When a transaction occurs, remaining share value is displayed.',
    },
    {
      id: 9,
      trigger: 'Job Title Change',
      description: 'A person changes position within the same company.',
      relevance:
        'Opportunity to initiate a business discussion based on congratulating the person on the promotion.',
    },
    {
      id: 10,
      trigger: 'Company Change',
      description: 'A person changes employer.',
      relevance:
        'Opportunity to initiate a business discussion based on congratulating the person on the job change.',
    },
    {
      id: 10,
      trigger:
        flags && flags.dntfd8665AttomHomeListings ? (
          <>
            Property Sale, <br /> Property Purchase, <br /> Property Listing
          </>
        ) : (
          <>
            Property Sale, <br /> Property Purchase
          </>
        ),
      description: 'Home purchases and sales.',
      relevance:
        'A home purchase or sale can be a trigger for new insurance products, retail purchases, and prospect/client location changes.',
    },
  ];

  const columns = [
    {
      key: 'trigger',
      title: 'Trigger',
      dataIndex: 'trigger',
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
    },
    {
      key: 'relevance',
      title: 'Relevance',
      dataIndex: 'relevance',
    },
  ];

  return (
    <div className="description-table">
      <Table dataSource={data} columns={columns} pagination={false} />
    </div>
  );
};

export default EventDescription;
