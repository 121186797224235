import './index.scss';
import { Modal } from 'antd';

import CloseIcon from '../../assets/icons/ai_ic_cloce_circled.svg?react';

const AiModal = ({ wrapClassName, children, footer = null, onCancel }) => {
  return (
    <Modal
      open
      closable
      onCancel={onCancel}
      closeIcon={<CloseIcon height={20} style={{ color: '#808184' }} />}
      wrapClassName={`ai-modal-wrapper ${wrapClassName}`}
      classNames={{
        mask: 'ai-modal-mask',
      }}
      footer={footer}
    >
      <div className="content">{children}</div>
    </Modal>
  );
};

export default AiModal;
