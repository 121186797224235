import '@gouch/to-title-case';
import './index.scss';

import { message, Modal, Tooltip } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { CloseCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';

import LeftArrow from '../../assets/icons/ai_ic_left_arrow.svg?react';
import IntegrateCrm from '../../assets/icons/ai_plus_circle.svg?react';
import ExportPdfImage from '../../assets/icons/ai_ic_pdf.svg?react';
import NotesIcon from '../../assets/icons/ai_ic_notes.svg?react';
// import SuperconnectorIcon from '../../assets/icons/ai_ic_star.svg?react';
// import NetworthIcon from '../../assets/icons/ai_ic_diamond.svg?react';
import ConnectivityIcon from '../../assets/icons/ai_meter_signal.svg?react';
import TagIcon from '../../assets/icons/ai_tag.svg?react';
import ProfileIcon from '../../assets/icons/ai_ic_profile_placeholder.svg?react';

import { getSourceName } from '../../utils/general';
import {
  handleCrmIconsAfterIntegration,
  handleCrmLabels,
} from '../../utils/crmMethods';
import { showConfirm } from '../../utils/popup';
import SelectClubs from '../SelectClubs';
import TagsList from '../TagsFolder/TagsList';
import TagsNew from '../TagsNew';
import AiTooltip from '../AiTooltip';
import { requestWithAuth } from '../../services/api';
import Notes from './Notes';
import TagSection from './TagSection';
import AddOrRemoveButton from './AddOrRemoveButton';

message.config({
  top: 80,
});
const ProfileHeader = inject(
  'dataStore',
  'modalDataStore',
)(
  observer(
    ({
      person,
      showLoading,
      crmOk,
      connectivity,
      updatePerson,
      onBackArrowClick,
      ...props
    }) => {
      const navigate = useNavigate();
      const { crm = '' } = props.dataStore.currentUser;
      const flags = useFlags();
      const [showNotes, setShowNotes] = useState(false);
      const [crmPopupOk, setCrmPopupOk] = useState(false);
      const [clubCode, setClubCode] = useState();
      const { confirm } = Modal;
      const modalRef = useRef(null);
      useEffect(() => {
        return () => {
          message.destroy();
        };
      }, []);

      useEffect(() => {
        if (crmPopupOk) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          props.dataStore.currentUser.creditCount >= 1 ||
          props.dataStore.currentUser.isAdmin
            ? crmOk(clubCode)
            : handleSubscribeRedirect();
        }
      }, [crmPopupOk]);

      // const onGoogleSearchClick = (name, company) => {
      //   if (name) {
      //     const searchValue = `${name} , ${company}`;
      //     window.open(`https://www.google.com/search?q=${searchValue}`);
      //   }
      // };

      const exportPopUp = () => {
        return showConfirm({
          className: 'small-popup',
          closable: true,
          onOk: () => {
            setCrmPopupOk(true);
          },
          title: 'Confirm Export',
          content: (
            <div style={{ textAlign: 'center' }}>
              You selected 1 profile to export
              <br />
              {props.dataStore.currentUser.creditCount >= 1 ||
              props.dataStore.currentUser.isAdmin
                ? 'Please confirm'
                : 'You require an additional 1 credit to proceed'}
            </div>
          ),
          okText:
            props.dataStore.currentUser.creditCount >= 1 ||
            props.dataStore.currentUser.isAdmin
              ? 'Yes'
              : 'Buy More',
        });
      };

      const clubModel = () => {
        modalRef.current = confirm({
          icon: null,
          okButtonProps: {
            disabled: true,
          },
          onOk() {
            exportPopUp();
            modalRef.current.destroy();
          },
          onCancel() {
            setClubCode();
            if (modalRef && modalRef.current) modalRef.current.destroy();
          },
          className: 'club-popUp',
          title: 'Select Club/Location',
          content: (
            <SelectClubs
              setClubVal={(val) => setClubCode(val)}
              modalRef={modalRef}
            />
          ),
        });
      };

      const handleCrmClick = () => {
        if (!crm) {
          navigate('/connect/export');
          return;
        }
        if (crm === 'sf' && props.dataStore.currentUser.org_name === 'INV')
          clubModel();
        else exportPopUp();
      };

      const handleSubscribeRedirect = () => {
        navigate('/account/credits');
      };

      const renderPrevious = () => {
        const { trackModal } = props.modalDataStore;
        if (trackModal.length > 1) {
          const lastItem = trackModal[trackModal.length - 2];
          if (lastItem) {
            if (lastItem.modal === 'profile')
              return 'previous prospect profile';
            else if (lastItem.modal === 'company')
              return 'previous company profile';
          }
        } else if (window.location && window.location.pathname) {
          const currentlocation = window.location.pathname;
          if (currentlocation.includes('/connect')) return 'Import/Export';
          else {
            const pathArray = currentlocation.split('/');
            const finalPath = currentlocation.includes('/prospect-finder')
              ? pathArray[pathArray.length - 2]
              : pathArray[pathArray.length - 1];
            return (
              <span
                style={{ textTransform: 'capitalize', display: 'contents' }}
              >
                {finalPath.replace(/-/g, ' ')}
              </span>
            );
          }
        }
        return 'Prospect Finder';
      };

      const handleRemoveTagsFromProfiles = (checkedTagIds = []) => {
        const profTags = person.profile_history_details.tags_details.map(
          (item) => item.tag,
        );
        const tagstoRemove = profTags.filter(
          (element) => !checkedTagIds.includes(element),
        );
        const body = {};
        const params = {};

        body.b2b_b2c_list = [
          {
            b2b_id: person.b2b_id || '',
            b2c_id: person.b2c_id || '',
          },
        ];

        body.tag_ids = tagstoRemove;
        requestWithAuth(
          'profile-tags/delete-multiple',
          'POST',
          params,
          body,
          null,
        )
          .then((response) => {
            if (response && response.code === 200) {
              message.success('Tag detached', 5);
              updatePerson(
                'tags_details',
                person.profile_history_details.tags_details.filter((element) =>
                  checkedTagIds.includes(element.tag),
                ),
              );
            }
          })
          .catch((err) => {
            message.error('Please try again', 5);
            console.log(err);
          });
      };

      return (
        <div className="profile-header">
          <div
            onClick={onBackArrowClick}
            className="back-profile-icon no-print"
          >
            <LeftArrow /> Back to {renderPrevious()}
          </div>
          <div
            className={
              person && person.date_of_death
                ? 'main-header-bg-deceased'
                : 'main-header-bg'
            }
          >
            <div
              className={
                person && person.date_of_death
                  ? 'main-header-deceased'
                  : 'main-header'
              }

              // style={{ alignItems: 'center', padding: '0 0 0 40px' }}
            >
              <div
              // style={{ padding: '30px 12px 20px 12px' }}
              >
                <div className="cname">
                  {person.full_name}
                  {person && person.date_of_death && (
                    <>
                      {' '}
                      (Deceased -{' '}
                      {dayjs(person.date_of_death).format('MM/DD/YYYY')})
                    </>
                  )}

                  {(!person.b2b || !person.b2c) && (
                    <Tooltip
                      placement="right"
                      color="linear-gradient(180deg, #FFFFFF 0%, #F4FAFF 100%)"
                      overlayInnerStyle={{
                        color: 'var(--color-black)',
                        padding: '8px 14px',
                        boxShadow: '0 3px 6px 0 rgba(0,0,0,0.3)',
                        fontSize: '12px',
                      }}
                      title="This is a profile that contains fewer attributes, which might provide a less full and accurate picture of this prospect."
                    >
                      <ProfileIcon height={60} width={60} />
                    </Tooltip>
                  )}
                </div>
                <div className="flex">
                  {connectivity && (
                    <div className="connectivity">
                      <ConnectivityIcon
                        className={
                          connectivity === 'high'
                            ? 'connectivity-icon-high'
                            : connectivity === 'medium'
                              ? 'connectivity-icon-medium'
                              : 'connectivity-icon-low'
                        }
                      />
                      <span className="connectivity-text">
                        {connectivity.replace('medium', 'med').toTitleCase()}
                      </span>
                      <span
                        style={{
                          fontWeight: 400,
                          fontSize: '14px',
                          height: '20px',
                        }}
                      >
                        Relationship Strength
                      </span>
                      <AiTooltip
                        // className="connectivity-info"
                        placement="bottomLeft"
                        iconColor="var(--color-white)"
                        marginBottom="0"
                        title={
                          'Relationship Strength is based on quality of mapped relationships.'
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="profile-header-right-section">
                <div
                // style={{
                //   display: 'flex',
                //   alignItems: 'flex-end',
                //   flexDirection: 'column',
                //   rowGap: '20px',
                // }}
                >
                  {/* <div className="badge-containers" style={{ height: 52 }}> */}
                  {/* TODO: Hide until data availability
                   <div className="badge-border">
                    <div className="badge">
                      <NetworthIcon />
                      <span>HIGH NET WORTH</span>
                    </div>
                  </div>
                  <div className="badge-border">
                    <div className="badge">
                      <SuperconnectorIcon />
                      <span>SUPER CONNECTOR</span>
                    </div>
                  </div> */}
                  {/* </div> */}
                  <div
                    className="notes-icon no-print"
                    onClick={() => setShowNotes(true)}
                  >
                    {person &&
                    person.profile_history_details &&
                    person.profile_history_details.note_details &&
                    person.profile_history_details.note_details.note ? (
                      <NotesIcon />
                    ) : (
                      <NotesIcon />
                    )}
                    <span>Notes</span>
                  </div>
                </div>
                <div className="add-remove-network">
                  <AddOrRemoveButton
                    degree={person.degree}
                    full_name={person.full_name}
                    contact_status={person.contact_status}
                    b2b_id={person.b2b_id}
                    b2c_id={person.b2c_id}
                    showLoading={showLoading}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sub-header">
            {/* <div
              onClick={() =>
                onGoogleSearchClick(person.full_name, person.org_name)
              }
            >
              <Google width="15px" height="15px" fill="var(--color-white)" />
              Profile Search
            </div> */}

            <div onClick={handleCrmClick} className="no-print">
              {crm ? (
                <img
                  src={handleCrmIconsAfterIntegration(crm)}
                  alt={handleCrmLabels(crm)}
                />
              ) : (
                <IntegrateCrm className="fill-primary" />
              )}
              {handleCrmLabels(crm)}
            </div>
            <div className="pdf-dropdown no-print">
              <div onClick={props.reactPrintFn}>
                <ExportPdfImage
                  style={{
                    paddingLeft: '10px',
                    width: '24px',
                  }}
                />
                PDF
              </div>
            </div>
            <div className="dummy-div" />

            <div>
              {flags && flags.tagFolders ? (
                <div className="flex profile-tags">
                  <div>
                    <TagIcon color="var(--color-primary)" /> Tags
                  </div>

                  <TagsNew
                    from="profile"
                    profileTagsDetails={
                      person.profile_history_details &&
                      person.profile_history_details.tags_details
                        ? person.profile_history_details.tags_details
                        : []
                    }
                    selectedProfileIds={[
                      {
                        b2b_id: person.b2b_id,
                        b2c_id: person.b2c_id || '',
                      },
                    ]}
                    updatePerson={updatePerson}
                  >
                    {person.profile_history_details &&
                      person.profile_history_details.tags_details && (
                        <TagsList
                          tags={person.profile_history_details.tags_details.map(
                            (item) => {
                              return { id: item.tag, tag: item.tag_name };
                            },
                          )}
                          count={5}
                          checkedTags={person.profile_history_details.tags_details.map(
                            (item) => item.tag,
                          )}
                          handleTagsCheck={(checked) => {
                            // handleRemoveTagsFromProfiles([...checked]);
                          }}
                        />
                      )}
                  </TagsNew>
                </div>
              ) : (
                <TagSection
                  b2bId={person.b2b_id}
                  b2cId={person.b2c_id}
                  tagData={
                    person.profile_history_details &&
                    person.profile_history_details.tags_details
                      ? person.profile_history_details.tags_details
                      : []
                  }
                  updatePerson={updatePerson}
                />
              )}
            </div>

            {person.import_source && person.import_source.length > 0 && (
              <div className="connection-source-section">
                <AiTooltip
                  placement="bottom"
                  title={
                    <div className="sources-tooltip">
                      {person.import_source.map(getSourceName).join(', ')}
                    </div>
                  }
                >
                  <div className="connection-sources">
                    Connection Source:{' '}
                    {person.import_source
                      .map(getSourceName)
                      .join(', ')
                      .substring(0, 30)}
                  </div>
                </AiTooltip>
              </div>
            )}
          </div>
          {showNotes && (
            <Modal
              title={null}
              footer={null}
              closeIcon={<CloseCircleFilled />}
              wrapClassName="notes-popup"
              centered
              open
              closable={true}
              onCancel={() => {
                setShowNotes(false);
              }}
            >
              <Notes
                person={person}
                updatePerson={updatePerson}
                onClose={() => {
                  setShowNotes(false);
                }}
              />
            </Modal>
          )}
        </div>
      );
    },
  ),
);

export default ProfileHeader;
