import './index.scss';

import React, { useEffect, useState } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { inject, observer } from 'mobx-react';
import { Menu, MenuProps } from 'antd';

import { AccountPropsInterface } from '../../types/AccountTypes';
import CreditsIcon from '../../assets/icons/ai_credits.svg?react';
import NotificationsIcon from '../../assets/icons/ai_notifications.svg?react';
import PasswordIcon from '../../assets/icons/ai_password.svg?react';
import SignoutIcon from '../../assets/icons/aid_signout.svg?react';

const Account: React.FC<AccountPropsInterface> = (props) => {
  const { pathname } = useLocation();
  const flags = useFlags();
  let slug = 'app';

  const [activeKey, setActiveKey] = useState<string>('profile');

  const hostArray = window.location.host.split('.');
  if (hostArray && hostArray.length > 0 && hostArray[0]) {
    slug = hostArray[0];
  }

  useEffect(() => {
    if (pathname) {
      const path = pathname.split('/')[2];
      if (path) setActiveKey(path);
    }
  }, [pathname]);

  const fullName = props.dataStore?.currentUser.full_name || '';

  const items: MenuProps['items'] = [
    {
      key: 'profile',
      label: (
        <Link to="/account/profile">
          <div className="item-div-header">
            <span className="settings-header">Settings</span>
            <span className="profile-name">{fullName}</span>
            {/* <EditIcon style={{ height: '16px' }} /> */}
          </div>
        </Link>
      ),
    },
    {
      key: 'credits',
      label: (
        <Link to="/account/credits">
          <div className="item-div">
            <CreditsIcon />
            <span>Credits</span>
          </div>
        </Link>
      ),
    },
    {
      key: 'notifications',
      label: (
        <Link to="/account/notifications">
          <div className="item-div">
            <NotificationsIcon />
            <span>Notifications</span>
          </div>
        </Link>
      ),
    },
    {
      key: 'security',
      label: (
        <Link to="/account/security">
          <div className="item-div">
            <PasswordIcon />
            <span>Security</span>
          </div>
        </Link>
      ),
    },
    {
      key: 'signOut',
      label: (
        <Link to="/user/logout">
          <div className="item-div">
            <SignoutIcon style={{ height: '25px' }} />
            <span>Sign Out</span>
          </div>
        </Link>
      ),
    },
  ];

  if (flags && flags.dntfd8933NewProfileView) {
    return (
      <div className="account-container-new">
        <div className="menu-container">
          <Menu
            selectedKeys={[activeKey]}
            mode="inline"
            items={items}
            className="settings-menu"
          />
          <div className="menu-background"></div>
        </div>
        <div className="settings-content">
          <Outlet />
        </div>
      </div>
    );
  }

  return (
    <div className="accountContainer">
      <div className="accountComponent">
        <div id="right" className="right-col">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default inject('dataStore')(observer(Account));
