import './index.scss';
import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Divider } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';

const TeamMembers = inject('dataStore')(
  observer((props) => {
    const [teamMembers, setTeamMembers] = useState(
      props.dataStore.lookUpLists.teamMembers,
    );

    useEffect(() => {
      if (
        typeof props.teamId === 'number' &&
        props.dataStore.lookUpLists.team
      ) {
        const filteredTeam = props.dataStore.lookUpLists.team.filter((data) => {
          return data.id === props.teamId;
        })[0];
        if (filteredTeam) {
          setTeamMembers([...filteredTeam.users]);
        }
      } else setTeamMembers(props.dataStore.lookUpLists.teamMembers);
    }, [props.teamId]);

    return (
      <div className="dashboard-item team-members">
        <div className="team-members-header">
          <div className="dashboard-item-title">Team Members</div>
          <div>
            <CloseCircleFilled
              onClick={props.onCloseButtonClick}
              style={{ color: 'var(--color-secondary)' }}
            />
          </div>
        </div>
        {!teamMembers || teamMembers.length < 1 ? (
          <div className="dashboard-item-empty">
            {' '}
            <p>Contact your Account Manager to create a team.</p>
          </div>
        ) : (
          <div className="team-members-content">
            {teamMembers.map((item, index) => {
              return (
                <>
                  {index !== 0 && <Divider className="team-divider" />}
                  <div>
                    <div className="name-section">
                      <div>{`${item.first_name} ${item.last_name}`}</div>
                      {item.email && (
                        <a
                          className=""
                          href={`mailto:${item.email}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.email}
                        </a>
                      )}
                    </div>
                    <div className="org-section">
                      {item.title || ''}
                      {item.title && item.org_name && ', '}
                      {item.org_name || ''}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        )}
      </div>
    );
  }),
);

export default TeamMembers;
