import './index.scss';
import { Button, Popconfirm, message } from 'antd';
import EmailIcon from '../../assets/icons/ai_email.svg?react';
import PhoneIcon from '../../assets/icons/ai_phone.svg?react';

message.config({
  top: 80,
});

const renderEmailButton = (emails) => {
  const EmailButton = () => {
    return (
      <div style={{ paddingRight: '10px' }}>
        <Button icon={<EmailIcon />} className="contact-button">
          Email this Person
        </Button>
      </div>
    );
  };
  if (emails.length === 1) {
    return (
      <a href={`mailto: ${emails[0]}`}>
        <EmailButton />
      </a>
    );
  }

  return (
    <Popconfirm
      placement="topRight"
      overlayClassName="opportunities-contact-modal"
      title={emails.map((email) => {
        return (
          <a href={`mailto: ${email}`}>
            <div>{email}</div>
          </a>
        );
      })}
    >
      {/* <EmailButton /> */}
      <div style={{ paddingRight: '10px' }}>
        <Button icon={<EmailIcon />} className="contact-button">
          Email this Person
        </Button>
      </div>
    </Popconfirm>
  );
};

const renderPhoneButton = (phones) => {
  return (
    <Popconfirm
      placement="topRight"
      overlayClassName="opportunities-contact-modal"
      title={phones.map((phone) => {
        return <div>{phone}</div>;
      })}
    >
      <Button icon={<PhoneIcon />} className="contact-button">
        Make a Call
      </Button>
    </Popconfirm>
  );
};

const ContactPerson = (props) => {
  const { data } = props;
  const { full_name, emails, phones } = data;
  return (
    <div className="oppurtunities-right-component">
      <div className="oppurtunities-right-header">
        {'Ask '}
        {full_name}
      </div>
      <div
        style={{
          display: 'flex',
        }}
      >
        {emails && emails.length > 0 && renderEmailButton(emails)}
        {phones && phones.length > 0 && renderPhoneButton(phones)}
      </div>
    </div>
  );
};

export default ContactPerson;
