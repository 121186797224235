import './index.scss';
import { useState, useRef, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { message } from 'antd';

import TagsIcon from '../../assets/icons/ai_tag.svg?react';
import MaximizeIcon from '../../assets/icons/ai-maximize.svg?react';

import { requestWithAuth } from '../../services/api';
import TagsModal from './TagsModal';

const TagsNew = inject('dataStore')(
  observer((props) => {
    const {
      profileTagsDetails = [],
      from,
      // selectAllRows,
      selectedProfileIds,
    } = props;
    const [showTagsPopUp, setShowTagsPopUp] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [profileTagsData, setProfileTagsData] = useState([]);
    const tagRef = useRef(null);

    useEffect(() => {
      if (profileTagsDetails && profileTagsDetails.length > 0) {
        const tagData = profileTagsDetails.map((item) => item.tag);
        setProfileTagsData(tagData);
      }
    }, [profileTagsDetails]);

    const fetchUserTags = () => {
      requestWithAuth('tags', 'GET', null, null, null)
        .then((response) => {
          if (response && response.length) {
            props.dataStore.userTags = [...response];
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    };

    const handleCreate = (tagName) => {
      setIsLoading(true);
      requestWithAuth(
        'tags',
        'POST',
        null,
        {
          tag: tagName,
        },
        null,
      )
        .then((response) => {
          if (response && response.code === 200) {
            fetchUserTags();
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          message.error('Please try again', 5);
          console.log(err);
          setIsLoading(false);
        });
    };

    const renderTagButton = () => {
      /** Tag Button in Rows, opportunities, superconnectors where we had favorite option */
      switch (from) {
        case 'multiprofiles': {
          /** Tag/Untag from export component */
          return (
            <div
              onClick={() => {
                setShowTagsPopUp(true);
              }}
              className="tag-untag"
            >
              <TagsIcon />
              Tag/Untag
              {selectedProfileIds?.length > 0
                ? `(${selectedProfileIds.length})`
                : ' All'}
            </div>
          );
        }
        case 'selected-rows': {
          /** Tag/Untag from single row */
          return (
            <div className="row-popup-icon">
              <TagsIcon />
              <div
                className="tooltip-over"
                onClick={() => {
                  //if (!props.profileTags) fetchProfileTags();
                  setShowTagsPopUp(true);
                }}
              >
                <TagsIcon /> Tag/Untag
              </div>
            </div>
          );
        }
        case 'row-profile-tag': {
          /** Tags of a profile from table row (Tags column) */
          return (
            <div
              onClick={(e) => {
                console.log('12333');
                e.stopPropagation();
                setShowTagsPopUp(true);
              }}
            >
              <div style={{ pointerEvents: 'none' }}>{props.children}</div>
            </div>
          );
        }
        case 'profile': {
          /** Tags in profile header(arrow icon) */
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowTagsPopUp(true);
              }}
            >
              <div style={{ pointerEvents: 'none' }}>{props.children}</div>
              <MaximizeIcon
                width={12}
                height={12}
                // onClick={() => {
                //   setShowTagsPopUp(true);
                // }}
              />
            </div>
          );
        }
        default:
          break;
      }
    };

    return (
      <>
        {renderTagButton()}
        {showTagsPopUp && (
          <TagsModal
            //isLoading={isLoading}
            tagSetup={
              from === 'multiprofiles' || from === 'selected-rows'
                ? 'showTagSetup'
                : 'showTagsForProfile'
            }
            handleCreate={handleCreate}
            profileTags={profileTagsData}
            changeProfileTagsData={(tag) => setProfileTagsData(tag)}
            onClose={() => {
              setShowTagsPopUp(false);
            }}
            // selectAllRows={selectAllRows}
            {...props}
          />
        )}
      </>
    );
  }),
);

export default TagsNew;
