import './SignUpCallBack.css';

import { Button, Checkbox, Form, Input, Select, message } from 'antd';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useNavigate } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';

import { requestWithAuth } from '../../services/api';
import Statelist from '../../utils/Statelist';
import { getCookie } from '../../utils/cookies';
import { setAxiosTokenInterceptor } from '../../components/Auth0AxiosInterceptor';
import Progress from '../../components/Progress';
import LoaderAnimation from '../../components/AidLoader';

const { Option } = Select;
message.config({
  top: 80,
});

/**
    1. /vip/register route redirect to auth0 with the query string that turns the login page into a signup page.
    2. Auth0 returns to /vip/signup/callback.
    3. call the `maxio-referrer` API to find out what to do here. It is now returning a JSON payload like:
    {
      "public_signup_url": "https://",
      "is_northwestern": boolean,
      "is_old_signup": boolean",
    }
    3. If use_old_signup is true, show the user the SignUp form from the App like normal.
    4. If use_old_signup is True and is_northwestern_mutual is True, display the existing form + switch the T&C link out to to the northwestern mutual one.
    5. If it is false, redirect the user to the public_signup_url. 
 */

const SignUpCallBack = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [isAccepted, setIsAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOldSignUp, setIsOldSignUp] = useState(null);
  const [isNorthWestern, setIsNorthWestern] = useState(false);
  const { getIdTokenClaims, logout } = useAuth0();

  useEffect(() => {
    requestWithAuth('maxio-referrer', 'GET', null, null, null)
      .then((response) => {
        if (!response) return;
        if (response.is_registered) navigate('/user/login/callback');
        if (response.user_type === 'lite') {
          setIsNorthWestern(response.is_northwestern_mutual);
          setIsOldSignUp(true);
          return;
        }
        if (response.message) {
          // message.error(response.message, 10);
          // logout({
          //   returnTo: `${getDomain()}/vip/register`,
          // });
          navigate(`/exception/${response?.statusCode}`, {
            state: {
              errorMessage: response?.message,
              from: 'maxio',
              redirectUrl: '/vip/register',
            },
          });
        }
        if (!response.use_old_signup && response.public_signup_url)
          window.location.replace(response.public_signup_url);

        setIsNorthWestern(response.is_northwestern_mutual);
        setIsOldSignUp(response.use_old_signup);
      })
      .catch((e) => {
        navigate('/exception', {
          state: {
            from: 'maxio',
          },
        });
        // logout({
        //   returnTo: `${getDomain()}/vip/register`,
        // });
        // navigate('/vip/register');
      });
  }, []);

  const getStateOptions = () => {
    const stateOptions = Statelist.map((state) => {
      return (
        <Option value={state.value} key={state.value}>
          {state.text}
        </Option>
      );
    });
    stateOptions.unshift(<option value="">Select</option>);
    return stateOptions;
  };

  function registerUserAuth0(params) {
    setIsLoading(true);
    requestWithAuth('user-registration', 'POST', null, { ...params }, null)
      .then((response) => {
        setIsLoading(false);
        if (response && response.code === 200) {
          navigate('/user/login/callback');
        } else if (response && response.code === 400 && response.errorCode) {
          message.error(response.errorCode, 10);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        if (error && error.json && error.json.msg) {
          message.error(error.json.msg, 10);
        } else {
          message.error(
            'There was a network error sending your message, please try submitting again',
            10,
          );
        }
      });
  }

  function registerUser(values) {
    const params = {};
    params.email = values.email;
    params.data = {};
    params.data.title = values.title;
    // params.data.company = values.company;
    // params.data.industry = values.industry;
    params.data.city = values.city;
    params.data.state = values.state;
    //params.data.referrer_code = values.referrercode;
    params.data.full_name = `${values.firstname} ${values.lastname}`;

    const hostArray = window.location.host.split('.');
    let domain = 'app';
    if (hostArray && hostArray.length > 0) {
      [domain] = hostArray;
    }
    params.data.slug = domain.startsWith('localhost')
      ? 'app'
      : getCookie('slug');

    const urlParams = location.search;
    const query = qs.parse(urlParams, {
      ignoreQueryPrefix: true,
    });
    if (
      query &&
      !(Object.keys(query).length === 0 && query.constructor === Object) &&
      (query.utm_source ||
        query.utm_medium ||
        query.utm_campaign ||
        query.utm_term ||
        query.utm_content)
    ) {
      if (query.utm_source) params.data.utm_source = query.utm_source;
      if (query.utm_medium) params.data.utm_medium = query.utm_medium;
      if (query.utm_campaign) params.data.utm_campaign = query.utm_campaign;
      if (query.utm_term) params.data.utm_term = query.utm_term;
      if (query.utm_content) params.data.utm_content = query.utm_content;
    }
    getIdTokenClaims().then((token) => {
      if (token && token.sub) params.data.user_auth0_id = token.sub;
      if (token && token.email) params.data.email = token.email;
      if (token) setAxiosTokenInterceptor(token.__raw);
      registerUserAuth0(params);
    });
  }

  const handleSubmit = (values) => {
    /** validate referrercode
     * proceed if valid
     * register on Auth0, then on success register on django
     */
    setIsLoading(true);
    registerUser(values);
  };
  if (!isOldSignUp) {
    /*show progress*/
    return <LoaderAnimation loaderId="signup-callback-new-loader" />;
  }

  return (
    <div className="loader-wrapper">
      {isLoading && <LoaderAnimation loaderId="signup-callback-loader" />}
      <div
        className={
          isLoading
            ? 'vip-content aid-div-disable'
            : 'vip-content aid-div-enable'
        }
      >
        <div className="vip-form">
          <div className="form-title">
            Access today to discover your next opportunity!
          </div>
          <div
            style={{
              color: 'var(--color-primary)',
              fontSize: '21px',
              marginBottom: '10px',
            }}
          >
            Step 2:
          </div>
          <Form
            // {...formItemLayout}
            layout="vertical"
            form={form}
            name="register"
            onFinish={handleSubmit}
            initialValues={{
              firstname: '',
              lastname: '',
              title: '',
              // company: '',
              // industry: '',
              city: '',
              state: '',
              email: '',
              password: '',
              acceptTerms: false,
            }}
            scrollToFirstError
          >
            <Form.Item
              name="firstname"
              label="First Name"
              rules={[
                {
                  min: 2,
                  message: 'Too Short!',
                },
                {
                  max: 70,
                  message: 'Too Long!',
                },
                {
                  required: true,
                  message: 'Required',
                },
              ]}
            >
              <Input placeholder="e.g. Alex" type="text" />
            </Form.Item>
            <Form.Item
              name="lastname"
              label="Last Name"
              rules={[
                {
                  min: 2,
                  message: 'Too Short!',
                },
                {
                  max: 70,
                  message: 'Too Long!',
                },
                {
                  required: true,
                  message: 'Required',
                },
              ]}
            >
              <Input placeholder="e.g. Smith" type="text" />
            </Form.Item>
            <Form.Item
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                  message: 'Required',
                },
              ]}
            >
              <Input placeholder="e.g. Your Job Title" type="text" />
            </Form.Item>
            <Form.Item
              name="city"
              label="City"
              rules={[
                {
                  required: true,
                  message: 'Required',
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                  message: 'Required',
                },
              ]}
            >
              <Select
                placeholder="Select"
                // onChange={this.onGenderChange}
                // allowClear
              >
                {getStateOptions()}
              </Select>
            </Form.Item>

            <Form.Item
              name="acceptTerms"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error('Should accept agreement')),
                },
              ]}
              // {...tailFormItemLayout}
            >
              <Checkbox
                onChange={(e) => setIsAccepted(e.target.checked)}
                style={{ margin: '15px 0px' }}
              >
                I agree to the{' '}
                <a
                  href={
                    isNorthWestern
                      ? 'https://www.aidentified.com/nm-terms-conditions'
                      : 'https://www.aidentified.com/terms-conditions'
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="form-label"
                >
                  Terms &amp; Conditions
                </a>{' '}
                of this platform
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={
                  isAccepted ? 'submit-button' : 'submit-button-default'
                }
                disabled={!isAccepted}
              >
                Sign-Up
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default SignUpCallBack;
