import './CrmCallback.css';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import axios from 'axios';

import DownloadCSVIcon from '../../assets/icons/download_csv.svg?react';

import { requestWithAuth } from '../../services/api';
import { showConfirm } from '../../utils/popup';
import LoaderAnimation from '../../components/AidLoader';

let source;
const ExportDownloadEmailRedirect = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { CancelToken } = axios;
    source = CancelToken.source();

    return () => {
      if (source) source.cancel('Operation canceled by the user.');
    };
  }, []);

  const handleSubscribeRedirect = () => {
    navigate('/account/credits');
  };

  const handleCSVDownload = () => {
    const fileId = parseInt(params.fileId);
    const { fileName } = params;
    setIsLoading(true);
    const body = { export_id: fileId };
    requestWithAuth('download-csv-url', 'POST', null, body, source.token).then(
      (response) => {
        setIsLoading(false);
        if (!response) return;
        if (response.code === 200) {
          const link = document.createElement('a');
          link.href = response.url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          navigate('/connect/status');
        } else if (response.code === 400) {
          if (response.charge_credits && response.user_credits) {
            showConfirm({
              className: 'small-popup',
              onOk: handleSubscribeRedirect,
              title: 'Confirm',
              content: (
                <>
                  This export requires {response.charge_credits} credits, which
                  exceeds your current balance of {response.user_credits}{' '}
                  {response.user_credits === 1 ? 'credit' : 'credits'}. Please
                  contact Customer Support or purchase additional credits{' '}
                  <a href="/account/credits">here.</a>{' '}
                </>
              ),
              closable: true,
              okText: 'Buy More',
            });
          }
        }
      },
    );
  };

  const handleClose = () => {
    navigate('/connect/status');
  };

  return (
    <div className="loader-wrapper">
      <LoaderAnimation loaderId="exp-dwnld-redirect-loader" />
      <div className="export-download-email-redirect">
        <div
          className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}
          style={{ paddingTop: '75px' }}
        >
          <Card className="export-download-card">
            {/* <img
            src={CloseIcon}
            style={{
              width: '15px',
              cursor: 'pointer',
              verticalAlign: 'unset',
            }}
            onClick={handleClose}
          /> */}

            <CloseOutlined
              style={{ fontSize: '20px', color: 'var(--color-column)' }}
              onClick={handleClose}
            />

            <Card
              bordered={false}
              style={{
                textAlign: 'center',
                paddingBottom: '50px',
              }}
            >
              <DownloadCSVIcon
                fill="var(--color-secondary)"
                height="45px"
                width="45px"
              />
              <p className="info-list" style={{ margin: '20px 0px' }}>
                Your CSV File is Ready to Download
              </p>
              <Button
                type="primary"
                onClick={handleCSVDownload}
                style={{ fontWeight: 500 }}
                className="export-button"
              >
                Download CSV
              </Button>
            </Card>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default ExportDownloadEmailRedirect;
