import { CopyrightOutlined } from '@ant-design/icons';

const Footer = () => {
  return (
    <footer>
      <div className="footer-text">
        Copyright
        <CopyrightOutlined
          style={{ paddingLeft: '2px', paddingRight: '2px' }}
        />
        {new Date().getFullYear()} Aidentified, Inc.
      </div>
    </footer>
  );
};

export default Footer;
