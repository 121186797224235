import './index.scss';

import { Card, Tooltip } from 'antd';
import CountUp from 'react-countup';
import { inject, observer } from 'mobx-react';
import { splitNumberWithCommas } from '../../utils/general';
import LoaderAnimation from '../AidLoader';

const SingleCard = (props) => {
  const { count, label, labelValue, labelKey, onCardClick } = props;

  return (
    <Card
      style={{
        height: '132px',
        //   overflowY: 'auto',
        minWidth: '236px',
        position: 'relative',
        borderRadius: '4px',
        padding: '0 24px',
      }}
      wrapperClassName="events-card"
      size="small"
      className="card-class"
      onClick={() => {
        onCardClick(labelKey, labelValue.value, labelValue.orgId);
      }}
    >
      <p className="count-label">
        <CountUp end={count} duration={2.75} separator="," />
      </p>
      <Tooltip
        placement="bottom"
        title={`${splitNumberWithCommas(count)}${label}${
          labelValue.value ? labelValue.value.toString().toTitleCase() : ''
        }`}
        color="var(--color-primary)"
      >
        <p className="label-style">
          {splitNumberWithCommas(count)}
          {label}
          {labelValue.value ? labelValue.value.toString().toTitleCase() : ''}
        </p>
      </Tooltip>
    </Card>
  );
};

const CardSection = inject('dataStore')(
  observer((props) => {
    const labelData = {
      // inferred_salary: ' Employees earn between ',
      title: ' Employees have title ',
      'current_experience.ai_org_id': ' Employees left and joined ',
      location_city: '  Employees live in ',
      'previous_experience.ai_org_id': ' Employees hired from ',
      schools: '  Employees studied at ',
      filter_type: ' 1st & 2nd Degree Relationships ',
    };

    return (
      <div style={{ position: 'relative' }}>
        {(!props.list || props.list.includes(undefined)) && (
          <LoaderAnimation size="medium" loaderId="cards-section-loader" />
        )}
        <div
          className={
            !props.list || props.list.includes(undefined)
              ? 'cards-section aid-div-disable'
              : 'cards-section aid-div-enable'
          }
        >
          {props.list &&
            props.list.map((data) => {
              if (data && Object.keys(data).length > 0) {
                const { key, count, value, orgId } = data;
                return (
                  <SingleCard
                    key={key}
                    count={count}
                    label={labelData[key]}
                    labelValue={{ value, orgId }}
                    labelKey={key}
                    onCardClick={props.seeInPeopleView}
                  />
                );
              }
            })}
        </div>
      </div>
    );
  }),
);

export default CardSection;
