import './index.scss';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { Menu, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import axios, { CancelTokenSource } from 'axios';

import { AccountPropsInterface } from '../../types/AccountTypes';
import { requestWithAuth } from '../../services/api';
import LoaderAnimation from '../../components/AidLoader';
import CreditsView from './CreditsView';
import CreditUsage from './CreditUsage';
import CreditViewNew from './CreditsViewNew';

message.config({
  top: 80,
});

let source: CancelTokenSource;

type CreditPurchaseBody = { create: number; quantity: number };

const CreditsPage: React.FC<AccountPropsInterface> = inject('dataStore')(
  observer((props: AccountPropsInterface) => {
    const [activeKey, setActiveKey] = useState(
      typeof props.dataStore.currentUser.nexis_admin === 'boolean'
        ? 'Credit Usage'
        : 'Credits',
    );
    const [creditsCompleted, setCreditsCompleted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const flags = useFlags();

    useEffect(() => {
      const { CancelToken } = axios;
      source = CancelToken.source();

      return () => {
        message.destroy();
        if (source) source.cancel('Operation canceled by the user.');
      };
    }, []);

    const fetchUserTypeData = () => {
      setIsLoading(true);
      requestWithAuth(
        'user-type',
        'GET',
        null,
        null,
        source.token,
        'progress-credits-view',
      ).then((response) => {
        if (response && response.results && response.results[0]) {
          // setCreditBalance(response.results[0].credits);
          props.dataStore.currentUser.creditCount = response.results[0].credits;
          setActiveKey('Credits');
          setCreditsCompleted(true);
        }
        setIsLoading(false);
      });
    };

    const onBuyCredits = (body: CreditPurchaseBody) => {
      setCreditsCompleted(false);
      requestWithAuth(
        'maxio-buy-credits',
        'POST',
        null,
        body,
        source.token,
        'progress-payment',
      ).then((response) => {
        // setIsCreditsNeeded(false);
        // setCreditsQuantity('');
        if (response && response.code === 307) {
          window.location.replace(response.url);
        } else if (response && response.code === 200) {
          fetchUserTypeData();
        } else if (response && response.errorCode !== undefined) {
          message.error(response.errorCode, 10);
        }
      });
    };

    const handleMenuClick = (item: { key: string }) => {
      if (!item) return;
      setActiveKey(item.key);
      setCreditsCompleted(false);
    };

    const getCurrentStatusView = () => {
      switch (activeKey) {
        case 'Credits': {
          return flags && flags.dntfd8933NewProfileView ? (
            <CreditViewNew
              billingInfo={props.dataStore.currentUser.billingPortalUrl}
              onBuyCredits={onBuyCredits}
              creditsCompleted={creditsCompleted}
            />
          ) : (
            <CreditsView
              billingInfo={props.dataStore.currentUser.billingPortalUrl}
              onBuyCredits={onBuyCredits}
              creditsCompleted={creditsCompleted}
            />
          );
        }
        case 'Credit Usage':
          return <CreditUsage />;
        default:
          return <></>;
      }
    };

    // if (!props.dataStore.currentUser.billingPortalUrl) {
    //   return <WarningMessage content="You don't have a billing info added" />;
    // }

    return (
      <div
        className={
          flags && flags.dntfd8933NewProfileView
            ? 'credits-container'
            : 'plans-panel-conatiner'
        }
      >
        <Menu
          className="menu"
          onClick={handleMenuClick}
          selectedKeys={[activeKey]}
          mode={
            flags && flags.dntfd8933NewProfileView ? 'horizontal' : 'inline'
          }
        >
          {typeof props.dataStore.currentUser.nexis_admin !== 'boolean' && (
            <Menu.Item key="Credits">
              {' '}
              {flags && flags.dntfd8933NewProfileView
                ? 'Credit Balance'
                : 'Credits'}{' '}
            </Menu.Item>
          )}
          <Menu.Item key="Credit Usage">
            {' '}
            {flags && flags.dntfd8933NewProfileView
              ? 'Credit Log'
              : 'Credit Usage'}{' '}
          </Menu.Item>
        </Menu>
        <div className="loader-wrapper">
          {isLoading && <LoaderAnimation loaderId="credits-loader" />}
          <div
            className={
              isLoading
                ? 'panel-tabs aid-div-disable'
                : 'panel-tabs aid-div-enable'
            }
          >
            {activeKey ? getCurrentStatusView() : ''}
          </div>
        </div>
      </div>
    );
  }),
);

export default CreditsPage;
