import './index.scss';
import React from 'react';

import { caesarCipher } from '../../utils/caesarCipher';
import { produceMaskedContent } from '../../utils/entitlements';

interface BlurredContentParams {
  blurred: boolean;
  obfuscateContent?: boolean;
  blurNode?: boolean;
}

export const BlurredContentClass = 'ai-blurred-content';

const BlurredContent = (
  params: React.PropsWithChildren<BlurredContentParams>,
) => {
  const { children, blurred, blurNode, obfuscateContent } = {
    obfuscateContent: true,
    blurNode: false,
    ...params,
  };

  const blurChildren = (child: React.ReactNode): React.ReactNode => {
    if (typeof child == 'number') {
      child = produceMaskedContent(child as number);
    }

    if (typeof child === 'string') {
      return (
        <span className={blurred ? BlurredContentClass : ''}>
          {blurred && obfuscateContent ? caesarCipher(child.toString()) : child}
        </span>
      );
    }

    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...(blurNode &&
          blurred && {
            className: `${child.props.className} ${BlurredContentClass}`,
          }),
        ...(child.props.children && {
          children: React.Children.map(child.props.children, blurChildren),
        }),
      });
    }

    return child;
  };

  return <>{React.Children.map(children, blurChildren)}</>;
};

export default BlurredContent;
