import moment from 'moment';
import iconAidentified from '../assets/icons/Ai_Blue_Logo-02.svg';
import iconGoogleSearch from '../assets/icons/ai_google.svg';
import iconIcloud from '../assets/icons/ai_icloud.svg';
import iconLinkedIn from '../assets/icons/ai_linked_in.svg';
import iconOutLook from '../assets/icons/ai_outlook.svg';
import filterStates from './Statelist';
import {
  companyViablityRanges,
  wealthScoreRanges,
  importSourceList,
  lotSizeRanges,
  remainingShareValues,
  IrsItems,
  filterLabelNames,
  garageLookup,
  Numbers1To10,
  Properties2To5,
  propertyValues,
} from './constants';

export const getRecentlyViewedText = (utcdatetime) => {
  const duration = moment.duration(moment().diff(utcdatetime));
  let lastViewed;
  if (parseInt(duration.asMinutes()) < 1) {
    lastViewed = 'Just now';
  } else if (parseInt(duration.asMinutes()) < 60) {
    const minutes = parseInt(duration.asMinutes());
    lastViewed = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (parseInt(duration.asHours()) < 24) {
    const hours = parseInt(duration.asHours());
    lastViewed = `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (parseInt(duration.asDays()) < 7) {
    const days = parseInt(duration.asDays());
    lastViewed = `${days} day${days > 1 ? 's' : ''} ago`;
  }
  return lastViewed;
};

export const getDomain = (slug) => {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname.startsWith('staging.')
  ) {
    return `${window.location.protocol}//${window.location.host}`;
  }
  let domain = 'app';
  if (slug) {
    domain = slug;
  } else {
    const hostArray = window.location.host.split('.');
    if (hostArray && hostArray.length > 0 && hostArray[0])
      domain = hostArray[0];
  }
  return `https://${domain}.aidentified.com`;
};

// This function returns number with comma separated.
export const splitNumberWithCommas = (x) => {
  if (!x) return 0;
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

// Format the given number to defined set
export const formatValue = (value) => {
  try {
    let result = '';
    if (!value || value <= 0) return `$${0}`;
    /*
          Case between 1-999,999
          1-999,999 : Number rounded to nearest integer, formatted as US currency
          Examples:
          1: $1,
          1;122.95: $1,123,
          99789.12: $99,789
          1 23455.5: $123,456
        */
    if (value > 0 && value <= 999999)
      return `$${splitNumberWithCommas(Math.round(value))}`;
    /*
          Case of Million
          Case between 1,000,000 - 1,000,000,000: Return number abbreviated as
          "M" rounded to 3 significant digits

          Examples:
          1000000: $1.00 M
          3444900: $3.44 M
          5445000: $5.45 M
          9999999: $9.99 M
          999999999: $999 M
        */
    if (value > 999999 && value <= 999999999) {
      const valConvert = value / 1000000;
      /*  If the number starts with 999 then will take the starting 4 digits
       * it is directly given to the result
       */
      if (value.toString().substring(0, 3) === '999') {
        result = valConvert.toString().substring(0, 4);
        if (result === '999.') {
          result = parseInt(result, 10);
        }
      } else {
        result = Number.parseFloat(valConvert.toString()).toPrecision(3);
      }
      return `$${result}M`;
    }

    /*
          Case of Billion
          1,000,000,000 - 99,999,999,999: Return number abbreviated as
          "B" rounded to 2 significant digits
          1000000000: $1.0 B
          1100000000: $1.1 B
          5450000000: $5.5 B
          98765432198: $99 B
        */
    if (value > 999999999 && value <= 99999999999) {
      const valConvert = value / 1000000000;
      /*  If the number starts with 99 then will take the starting 3 digits
       *  it is directly given to the result
       */
      if (value.toString().substring(0, 2) === '99') {
        result = valConvert.toString().substring(0, 3);
        if (result === '99.') {
          result = parseInt(result, 10);
        }
      } else {
        result = Number.parseFloat(valConvert.toString()).toPrecision(2);
      }
      return `$${result}B`;
    }

    /*
          Case of 100 Billion
          100,000,000,000 or more: Round to either nearest whole billion or whole trillion, no decimals
          100 000 000 000 0
          100000000000: $100 B
          125000000000: $125 B
          987500000000: $988 B
          1,900,000,000,000: $2 T
        */
    if (value > 99999999999 && value <= 999999999999) {
      if (value.toString().substring(0, 3) === '999') {
        return `$${999}B`;
      }
      const valConvert = value / 100000000000;
      result = Number.parseFloat(valConvert.toString()).toPrecision(3);
      return `$${(result * 100).toPrecision(3)}B`;
    }

    // Case of Trillion
    const valConvert = value / 1000000000000;
    if (valConvert >= 9 && valConvert < 10) {
      result = 9;
    } else if (valConvert >= 99 && valConvert < 100) {
      result = 99;
    } else if (valConvert >= 999 && valConvert < 1000) {
      result = 999;
    } else {
      result = Math.round(valConvert);
    }
    return `$${result}T`;

    // if (value.toString().length <= 13) {
    //   if (value.toString().substring(0, 1) === '9') {
    //     result = 9;
    //   } else {
    //     result = Number.parseFloat(valConvert.toString()).toPrecision(1);
    //   }
    // } else if (value.toString().substring(0, 2) === '99') {
    //   result = valConvert.toString().substring(0, 3);
    //   if (result === '99.') {
    //     result = parseInt(result, 10);
    //   }
    // } else {
    //   result = Number.parseFloat(valConvert.toString()).toPrecision(2);
    // }

    // return `$${result}T`;
  } catch (err) {
    return `$${0}`;
  }
};

export const commarize = (count, fixedPoint = 2) => {
  // Alter numbers larger than 1k
  if (count >= 1e3) {
    const units = ['K', 'M', 'B', 'T'];

    // Divide to get SI Unit engineering style numbers (1e3,1e6,1e9, etc)
    const unit = Math.floor((count.toFixed(0).length - 1) / 3) * 3;
    // Calculate the remainder
    const num = (count / `1e${unit}`).toFixed(fixedPoint);
    const unitname = units[Math.floor(unit / 3) - 1];

    // output number remainder + unitname
    return num + unitname;
  }

  // return formatted original number
  return count.toLocaleString();
};

export const getLatestMimDateRange = (dateRange) => {
  if (dateRange)
    switch (dateRange.toLowerCase()) {
      case 'week': {
        const today = new Date();
        const last_date = new Date(today.setDate(today.getDate()));
        const first_date = new Date(
          today.setDate(today.getDate() - 7),
        ).toUTCString();

        const startDateRange = moment(first_date).format('YYYY-MM-DD');
        const endDateRange = moment(last_date).format('YYYY-MM-DD');
        const start = moment(startDateRange).valueOf();
        const end = moment(endDateRange).valueOf();
        const latest_mim_date = {
          gte: start,
          lte: end,
        };
        return latest_mim_date;
      }
      case 'month': {
        const today = new Date();
        const last_date = new Date(today.setDate(today.getDate()));
        const first_date = new Date(
          today.setDate(today.getDate() - 30),
        ).toUTCString();

        const startDateRange = moment(first_date).format('YYYY-MM-DD');
        const endDateRange = moment(last_date).format('YYYY-MM-DD');
        const start = moment(startDateRange).valueOf();
        const end = moment(endDateRange).valueOf();
        const latest_mim_date = {
          gte: start,
          lte: end,
        };
        return latest_mim_date;
      }
      case 'ytd': {
        const today = new Date();
        const last_date = new Date(today.setDate(today.getDate()));
        const startDateRange = `${new Date().getFullYear()}-01-01`;
        const endDateRange = moment(last_date).format('YYYY-MM-DD');
        const start = moment(startDateRange).valueOf();
        const end = moment(endDateRange).valueOf();
        const latest_mim_date = {
          gte: start,
          lte: end,
        };
        return latest_mim_date;
      }
      case '2020': {
        const startDateRange = '2020-01-01';
        const endDateRange = '2020-12-31';
        const start = moment(startDateRange).valueOf();
        const end = moment(endDateRange).valueOf();
        const latest_mim_date = {
          gte: start,
          lte: end,
        };
        return latest_mim_date;
      }
      case 'all': {
        const startDateRange = '2000-01-01';
        const start = moment(startDateRange).valueOf();
        const today = new Date();
        const last_date = new Date(today.setDate(today.getDate()));
        const endDateRange = moment(last_date).format('YYYY-MM-DD');
        const end = moment(endDateRange).valueOf();
        const latest_mim_date = {
          gte: start,
          lte: end,
        };
        return latest_mim_date;
      }
      default:
        return '';
    }
  return '';
};

export function RGBToHex(color) {
  let rgb = color.toString();
  // Choose correct separator
  const sep = rgb.indexOf(',') > -1 ? ',' : ' ';
  // Turn "rgb(r,g,b)" into [r,g,b]
  rgb = rgb.substr(4).split(')')[0].split(sep);
  let r = (+rgb[0]).toString(16);
  let g = (+rgb[1]).toString(16);
  let b = (+rgb[2]).toString(16);

  if (r.length === 1) r = `0${r}`;
  if (g.length === 1) g = `0${g}`;
  if (b.length === 1) b = `0${b}`;
  return `#${r}${g}${b}`;
}

/* method called  to map the selected values and map all fields in the dropdown
to get its corresponding value and label */
export function getMultipleValueSelected(filterArray, filterKey) {
  const eventsArray = [];
  let newEventsArray;
  if (filterArray && filterArray.length > 0) {
    filterArray.map((events) => {
      if (filterKey && filterKey.length > 0 && Array.isArray(filterKey)) {
        filterKey.map((eventValue) => {
          if (eventValue === events.value) {
            if (events.label === 'Elementary')
              eventsArray.push({
                value: events.value,
                label: events.label,
                text: events.text,
              });
            else eventsArray.push({ value: events.value, label: events.label });
          }
          return '';
        });
      }
      return '';
    });
  }
  // eslint-disable-next-line prefer-const
  newEventsArray = eventsArray;
  return newEventsArray;
}

/* method called  to map the selected values to display in the corresponding field */
export function getSelectedValues(filterKey, key) {
  const filterArray = [];
  let newFilterValue;
  if (filterKey && filterKey.length > 0 && Array.isArray(filterKey)) {
    filterKey.map((filters) => {
      if (key === 'ai_org_id')
        filterArray.push({ value: filters.ai_org_id, label: filters.name });
      else if (key === 'org_contains' || key === 'exc_org_contains') {
        filterArray.push({ value: filters, label: filters, __isNew__: true });
      } else filterArray.push({ value: filters, label: filters });
      return '';
    });
  }
  // eslint-disable-next-line prefer-const
  newFilterValue = filterArray;
  return newFilterValue;
}

export function getTimeofDayGreeting(momentInstance) {
  if (!moment.isMoment(momentInstance)) {
    return 'Hello';
  }

  const hour = momentInstance.hour();

  if (hour >= 5 && hour < 12) {
    return 'Good morning';
  } else if (hour >= 12 && hour < 18) {
    return 'Good afternoon';
  } else {
    return 'Good evening';
  }
}

/* method called to get the selected date range */
export function getDateFilterLabel(dateFilter, noOfYears) {
  let dateRange;
  if (dateFilter)
    switch (dateFilter.toLowerCase()) {
      case 'week':
        dateRange = 'One Week';
        break;
      case 'month':
        dateRange = 'One Month';
        break;
      case 'year':
        dateRange = noOfYears === 1 ? '2020' : 'YTD';
        break;
      case 'all':
      default:
        dateRange = 'All';
        break;
    }
  return dateRange;
}

export function getDateFilterValue(dateFilter) {
  let dateRange;
  if (dateFilter)
    switch (dateFilter.toLowerCase()) {
      case 'one week':
        dateRange = 'week';
        break;
      case 'one month':
        dateRange = 'month';
        break;
      case 'ytd':
        dateRange = 'ytd';
        break;
      case '2020':
        dateRange = '2020';
        break;
      case 'all':
      default:
        dateRange = 'all';
        break;
    }
  return dateRange;
}

export function getDateFilter(dateFilter) {
  let filter = dateFilter;
  if (dateFilter)
    switch (dateFilter.toLowerCase()) {
      case 'ytd':
      case '2020':
        filter = 'year';
        break;
      default:
        filter = dateFilter;
        break;
    }
  return filter;
}

export function getNewPropertyValueRange(min, max) {
  if (min === max) {
    /**Scenario of old filter with Exact value */
    return propertyValues.filter(
      (range) =>
        (range.valueObject.lte === null || min <= range.valueObject.lte) &&
        max >= range.valueObject.gte,
    );
  }
  return propertyValues.filter(
    (range) =>
      (range.valueObject.lte === null || min < range.valueObject.lte) &&
      (max === null || max > range.valueObject.gte),
  );
}

export function getMimEvent(event) {
  const mimEvent = [];
  switch (event) {
    case 'ipo':
      mimEvent.push({
        label: 'IPO',
        value: 'ipo',
      });
      break;
    case 'ipo filing':
      mimEvent.push({
        label: 'IPO Filing',
        value: 'ipo filing',
      });
      break;
    case 'ipo spac':
      mimEvent.push({
        label: 'SPAC',
        value: 'ipo spac',
      });
      break;
    // case 'ipo lockup expiring soon':
    // case 'ipo lock-up expiring soon':
    //   mimEvent.push({
    //     label: 'IPO Lock-up Expiring Soon',
    //     value: 'ipo lockup expiring soon',
    //   });
    //   break;
    // case 'ipo lockup expired':
    // case 'ipo lock-up expired':
    //   mimEvent.push({
    //     label: 'IPO Lock-up Expired',
    //     value: 'ipo lockup expired',
    //   });
    //   break;
    case 'insider stock sale':
      mimEvent.push({
        label: 'Insider Stock Sale',
        value: 'insider stock sale',
      });
      break;
    case 'insider stock acquisition':
      mimEvent.push({
        label: 'Insider Stock Acquisition',
        value: 'insider stock acquisition',
      });
      break;
    case 'insider stock purchase':
      mimEvent.push({
        label: 'Insider Stock Purchase',
        value: 'insider stock purchase',
      });
      break;
    case 'company was acquired':
    case 'company acquired':
      mimEvent.push({
        label: 'Company Acquired',
        value: 'company was acquired',
      });
      break;
    case 'investment received':
      mimEvent.push({
        label: 'Investment Received',
        value: 'investment received',
      });
      break;
    case 'unicorn status achieved':
    case 'company achieves unicorn status':
      mimEvent.push({
        label: 'Company Achieves Unicorn Status',
        value: 'unicorn status achieved',
      });
      break;
    case 'news':
      mimEvent.push({
        label: 'News Mention',
        value: 'news',
      });
      break;
    case 'company change':
      mimEvent.push({
        label: 'Company Change',
        value: 'company change',
      });
      break;
    case 'job title change':
      mimEvent.push({
        label: 'Job Title Change',
        value: 'job title change',
      });
      break;
    case 'property sale':
      mimEvent.push({
        label: 'Property Sale',
        value: 'property sale',
      });
      break;
    case 'property purchase':
      mimEvent.push({
        label: 'Property Purchase',
        value: 'property purchase',
      });
      break;
    case 'property listing':
      mimEvent.push({
        label: 'Property Listing',
        value: 'property listing',
      });
      break;
    case 'deceased':
      mimEvent.push({
        label: 'Potential Wealth Transfer',
        value: 'deceased',
      });
      break;
    default:
      break;
  }
  return mimEvent;
}

export function getWealthScoreLabel(score) {
  if (score > 1000000000) {
    return '>$1B';
  }
  if (score <= 1000000000 && score > 500000000) {
    return '$500M+ to $1B';
  }
  if (score <= 500000000 && score > 100000000) {
    return '$100M+ to $500M';
  }
  if (score <= 100000000 && score > 50000000) {
    return '$50M+ to $100M';
  }
  if (score <= 50000000 && score > 20000000) {
    return '$20M+ to $50M';
  }
  if (score <= 20000000 && score > 10000000) {
    return '$10M+ to $20M';
  }
  if (score <= 10000000 && score > 5000000) {
    return '$5M+ to $10M';
  }
  if (score <= 5000000 && score > 2000000) {
    return '$2M+ to $5M';
  }
  if (score <= 2000000 && score > 1000000) {
    return '$1M+ to $2M';
  }
  if (score <= 1000000 && score > 500000) {
    return '$500K+ to $1M';
  }
  if (score <= 500000 && score > 100000) {
    return '$100K+ to $500K';
  }
  if (score <= 100000 && score >= 0) {
    return '<$100K';
  }
}

export function setAppTheme() {
  const themeObject = localStorage.getItem('aidentified.theme')
    ? JSON.parse(localStorage.getItem('aidentified.theme'))
    : null;

  let dark = '';
  let primary = '';
  let secondary = '';
  let logoUrl = '';
  let favIcon = '';
  let slug = '';

  // Aidentified;
  if (themeObject) {
    dark = themeObject.dark;
    primary = themeObject.primary;
    secondary = themeObject.secondary;
    logoUrl = themeObject.logoUrl;
    favIcon = themeObject.favIcon ? themeObject.favIcon : '';
    slug = themeObject.slug ? themeObject.slug : '';
  } else {
    // defaullt theme
    dark = 'rgb(0, 65, 109)';
    primary = 'rgb(66, 128, 199)';
    secondary = 'rgb(145, 175, 202)';
    logoUrl =
      'https://s.aidimg.com/cda/themes/app/aidentified-logo-blue-white.svg';
    favIcon = 'https://s.aidimg.com/cda/themes/app/favicon.ico';
    slug = 'app';
  }

  /** for new design changes
   * need to remove after theme colors are set */

  dark = 'rgb(0, 49, 82)'; // #003152
  primary = 'rgb(0, 65, 109)'; // #00416D
  secondary = 'rgb(177, 195, 210)'; // #B1C3D2
  // const darkShade = 'rgb(132, 150, 166)'; // #8496A6
  const lightShade = 'rgb(201, 215, 225)'; // #C9D7E1

  // removing unwanted white spaces
  dark = dark.replace(/ /g, '');
  primary = primary.replace(/ /g, '');
  secondary = secondary.replace(/ /g, '');

  // generate light version of primarycolor
  let rgb = primary.toString();
  // Choose correct separator
  const sep = rgb.indexOf(',') > -1 ? ',' : ' ';
  // Turn "rgb(r,g,b)" into [r,g,b]
  rgb = rgb.substr(4).split(')')[0].split(sep);
  let lightColor = `rgba(${rgb.toString()},0.07)`; // similar to #E8EFF4

  // remove this after theme colors are fixed
  lightColor = 'rgb(232, 239, 244)';

  const primaryLight = `rgba(${rgb.toString()},0.5)`;

  // dynamically add style to include dynamic theme
  const bodyStyles = document.documentElement.style;
  bodyStyles.setProperty('--color-dark', dark);
  bodyStyles.setProperty('--color-primary', primary);
  bodyStyles.setProperty('--color-secondary', secondary);
  // bodyStyles.setProperty('--color-dark-shade', darkShade);
  bodyStyles.setProperty('--color-light-shade', lightShade);
  bodyStyles.setProperty('--color-light', lightColor);
  bodyStyles.setProperty('--color-primary-light', primaryLight);
  bodyStyles.setProperty('--url-logo', logoUrl);

  // dynamically change favIcon
  if (favIcon) {
    const headID = document.head || document.getElementsByTagName('head')[0];

    const link = document.createElement('link');
    link.rel = ' shortcut icon';
    link.type = 'image/x-icon';
    link.href = favIcon;

    headID.appendChild(link);
  }

  // document title is taken from slug is added in UserLayout.jsx and BasicLayout.jsx file
}

export function getSourceImage(name) {
  switch (name) {
    case 'linkedin':
      return iconLinkedIn;
    case 'icloud':
      return iconIcloud;
    case 'google':
      return iconGoogleSearch;
    case 'outlook':
      return iconOutLook;
    case 'aidentified_auto':
    case 'aidentified':
    default:
      return iconAidentified;
  }
}

export function getSourceName(name) {
  if (!name) return '-';
  switch (name.toLowerCase()) {
    case 'linkedin':
      return 'LinkedIn';
    case 'icloud':
      return 'iCloud';
    case 'google':
      return 'Google';
    case 'outlook':
      return 'Outlook';
    case 'csv':
      return 'CSV';
    case 'aidentified_auto':
      return 'Aidentified Mapped Relationships';
    case 'aidentified':
      return 'Added in Aidentified App';
    case 'data enrichment':
      return 'Data Enrichment';
    case 'status':
      return 'Status';
    default:
      return name.toTitleCase();
  }
}

export function isLowerCase(str) {
  return str && str === str.toLowerCase();
}

export function isEqual(str, str1) {
  return str && str1 && str.toLowerCase() === str1.toLowerCase();
}

export function titleCase(str) {
  if (!str) return str;
  if (str.includes('.')) {
    str = str.toLowerCase().split('.');
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join('.');
  }
  str = str.toLowerCase().split(' ');
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}

export const formatEventName = (val) => {
  if (!val) return '';
  let value = val.toString().toTitleCase();
  value = value.replace('ipo', 'IPO');
  value = value.replace('Ipo', 'IPO');
  if (val === 'company was acquired') {
    value = 'Company Acquired';
  } else if (val === 'ipo lockup expiring soon') {
    value = 'IPO Lock-up Expiring Soon';
  } else if (val === 'ipo lockup expired') {
    value = 'IPO Lock-up Expired';
  } else if (val === 'ipo spac') {
    value = 'SPAC';
  } else if (val === 'news') {
    value = 'News Mention';
  } else if (val === 'deceased') {
    value = 'Potential Wealth Transfer';
  }

  return value;
};

export const formatEventSource = (source) => {
  if (!source) return;
  switch (source) {
    case 'sec':
    case 'SEC':
      return 'SEC';
    default:
      return source.toString().toTitleCase();
  }
};

function getRangeLabel(range, showFormatting) {
  if (range.gte === range.lte)
    return showFormatting ? splitNumberWithCommas(range.gte) : range.gte;
  return showFormatting
    ? `${splitNumberWithCommas(range.gte)} - ${splitNumberWithCommas(
        range.lte,
      )}`
    : `${range.gte} - ${range.lte}`;
}

function getZipRangeLabel(range) {
  if (
    range.home_postal_code &&
    (range.home_postal_code.gte || range.home_postal_code.lte)
  ) {
    let gte = range.home_postal_code.gte;
    let lte = range.home_postal_code.lte;

    if (range.home_zip4 && range.home_zip4.gte)
      gte = `${gte}-${range.home_zip4.gte}`;

    if (range.home_zip4 && range.home_zip4.lte)
      lte = `${lte}-${range.home_zip4.lte}`;
    if (gte === lte) return gte;
    return `${gte} - ${lte}`;
  }
  return '';
}

function getFormattedRangeLabel(range) {
  if (!range.gte && !range.lte) return '';
  if (!range.gte) return `0 - ${splitNumberWithCommas(range.lte)}`;
  if (!range.lte) return `${splitNumberWithCommas(range.gte)}+`;
  if (range.gte === range.lte) return splitNumberWithCommas(range.gte) || '';
  return `${splitNumberWithCommas(range.gte)}-${splitNumberWithCommas(
    range.lte,
  )}`;
}

export function flattenTree(data) {
  return data.reduce((result, node) => {
    const flattenedNode = {
      label: node.title,
      value: node.value,
      // key: node.key,
    };

    result.push(flattenedNode);

    if (node.children && node.children.length > 0) {
      const flattenedChildren = flattenTree(node.children);
      result.push(...flattenedChildren);
    }

    return result;
  }, []);
}

export function getFormatedFilterValues(
  value,
  titleRankList,
  fileImports,
  teamFileImports,
  userTags,
  naicsList,
) {
  const connectionLabel = {
    first_degree: 'First Degree',
    second_degree: 'Second Degree',
    combined: 'First & Second Degree',
    all: 'All',
  };
  const arrList = [];
  switch (value[0]) {
    case 'number_of_stories':
      return value[1].join(' story, ') + ' story';
    case 'number_of_mapped_connections': {
      const mappedCountValues = value[1].map((val) => {
        const gte = val.range['number_of_mapped_connections'].gte;
        const lte = val.range['number_of_mapped_connections'].lte;
        if (lte !== null) return `${gte} - ${lte}`;
        return `${gte}+`;
      });
      return '# of Mapped Relationships : ' + mappedCountValues.join(', ');
    }
    case 'effective_year_built':
      return (
        'Effective Year Built : ' +
        value[1][0].range['effective_year_built'].gte +
        ',' +
        value[1][0].range['effective_year_built'].lte
      );
    case 'total_number_of_rooms':
    case 'number_of_owned_properties':
      value[1].map((obj) => {
        Properties2To5.filter((item) => {
          if (item.value == obj.range[value[0]].gte) {
            arrList.push(item.label);
          }
        });
      });
      return filterLabelNames[value[0]] + ' : ' + arrList.join(', ');
    case 'number_of_bedrooms':
    case 'number_of_baths':
    case 'number_of_partial_baths':
    case 'number_of_units':
      value[1].map((obj) => {
        Numbers1To10.filter((item) => {
          if (item.value == obj.range[value[0]].gte) {
            arrList.push(item.label);
          }
        });
      });
      return filterLabelNames[value[0]] + ' : ' + arrList.join(', ');
    case 'lotsize_square_feet':
      value[1].map((obj) => {
        lotSizeRanges.filter((item) => {
          if (item.rangeObject.lte == obj.range.lotsize_square_feet.lte)
            arrList.push(item.label);
        });
      });
      return 'Lot Square Feet : ' + arrList.join(',');
    case 'garage_cars':
      value[1].map((obj) => {
        arrList.push(
          garageLookup.filter(
            (item) => item.value == obj.range.garage_cars.gte,
          )[0].label,
        );
      });
      return arrList.join(', ');
    case 'exc_title':
    case 'title':
    case 'schools':
    case 'exc_home_address_suggest':
    case 'home_address_suggest':
      if (
        value[1] &&
        value[1].exact &&
        value[1].prefix &&
        value[1].exact.concat(value[1].prefix)
      )
        return value[1].exact.concat(value[1].prefix).join(', ').toTitleCase();
      else return '';
    case 'gender':
      if (value[1] === 'F') return 'Female';
      if (value[1] === 'M') return 'Male';
      break;
    case 'marital_status':
      if (value[1] === 'S') return 'Single';
      if (value[1] === 'M') return 'Married';
      return;
    case 'company_type':
      return value[1].toString().toTitleCase();
    case 'period':
      return value[1].toTitleCase();
    case 'm2_date': {
      const m2 = value[1] || [];
      if (m2 && m2.length > 1)
        return (
          'Time Frame - ' +
          `${moment(m2[0]).format('DD/MM/YYYY')} - ${moment(m2[1]).format(
            'DD/MM/YYYY',
          )}`
        );
      return '';
    }
    case 'home_sale_date': {
      const m2 = value[1] || [];
      if (m2[0] !== m2[1]) {
        return (
          'Home sale date - ' +
          `${moment(m2[0]).format('MM/DD/YYYY')} - ${moment(m2[1]).format(
            'MM/DD/YYYY',
          )}`
        );
      } else {
        return 'Home sale date - ' + `${moment(m2[0]).format('MM/DD/YYYY')} `;
      }
    }
    case 'home_purchase_date': {
      const m2 = value[1] || [];
      if (m2[0] !== m2[1]) {
        return (
          'Home purchase date - ' +
          `${moment(m2[0]).format('MM/DD/YYYY')} - ${moment(m2[1]).format(
            'MM/DD/YYYY',
          )}`
        );
      } else {
        return (
          'Home purchase date - ' + `${moment(m2[0]).format('MM/DD/YYYY')} `
        );
      }
    }
    case 'location_state': {
      const selected = [];
      value[1].map((val) => {
        filterStates.map((state) => {
          if (state.value === val) selected.push(state.label);
        });
      });
      return selected.join(', ');
    }
    case 'filter_type':
      if (value[1] === 'all') return;
      return connectionLabel[value[1]];
    case 'norm_home_owner':
    case 'relmap_org':
      if (value[1] && value[1].length > 0) {
        value[1].map((rel) => {
          arrList.push(rel);
        });
      }
      return arrList.join(', ');
    case 'credentials':
      return value[1].join(', ');
    case 'org_contains':
      return 'Company Contains - ' + value[1].join(', ').toTitleCase();
    case 'exc_org_contains':
      return 'Exclude Company Contains - ' + value[1].join(', ').toTitleCase();
    case 'naics_codes':
      return filterLabelNames[value[0]];
    case 'org_name':
    case 'industry':
    case 'exc_industry':
    case 'funding_round':
    case 'location_city':
    case 'employee_count':
    case 'event_org_name':
    case 'inferred_salary_range':
    case 'county':
    case 'property_type':
      return value[1].join(', ').toTitleCase();
    case 'mapped_source_filter_name':
      return `${value[1]}'s Mapped Connections`;
    case 'latest_mim_event_type': {
      if (value[0] === 'latest_mim_event_type') {
        const eventsArray = [];
        if (value[1] && value[1].length > 0) {
          value[1].map((eventValue) => {
            eventsArray.push(formatEventName(eventValue));
          });
          return eventsArray.join(', ');
        }
      } else return value[1].join(', ');
      return '';
    }
    case 'range_list':
      return getRangeLabel(value[1][0].range.age);
    case 'home_postal_code':
      return getZipRangeLabel(value[1]);
    case 'home_value_estimate': {
      const rangeLabels = [];
      value[1].map((val) => {
        const ranges = getNewPropertyValueRange(
          val.range.home_value_estimate.gte,
          val.range.home_value_estimate.lte,
        );
        ranges.map((obj) => {
          if (obj && obj.label && !rangeLabels.includes(obj.label))
            rangeLabels.push(obj.label);
        });
      });
      if (rangeLabels.length > 0) {
        return rangeLabels.join(', ');
      }
      return null;
    }
    case 'latest_mim_monetary_value': {
      const resultArray = [];
      if (value && value.length > 1) {
        value[1].map((obj) => {
          let rangeLabel = '';
          if (
            obj.range &&
            obj.range['events.event_monetary_value'] &&
            (obj.range['events.event_monetary_value'].gte ||
              obj.range['events.event_monetary_value'].lte)
          ) {
            if (wealthScoreRanges && wealthScoreRanges.length > 0) {
              wealthScoreRanges.map((wealthScore) => {
                Object.keys(obj.range).map(function (key) {
                  if (
                    obj.range[key].gte === wealthScore.min &&
                    (obj.range[key].lte === wealthScore.max ||
                      !obj.range[key].lte)
                  ) {
                    rangeLabel = wealthScore.label;
                  }
                });
              });

              if (rangeLabel) resultArray.push(rangeLabel);
              else {
                resultArray.push(
                  getRangeLabel(
                    value[1][0].range['events.event_monetary_value'],
                    true,
                  ),
                );
              }
            }
          }
        });
      }
      return resultArray.join(', ').replaceAll('+', '');
    }
    case 'total_years_of_experience':
    case 'current_org_years':
      return getRangeLabel(value[1][0].range[value[0]]);
    case 'dateRange':
      return getDateFilterLabel(value[1].dateFilter, value[1].noOfYears);
    case 'home_search':
    case 'company_search':
      return value[1].geo_distance.address;
    case 'stock_performance': {
      const confidenceLevel = [];
      if (value && value.length > 1) {
        companyViablityRanges.map((confidence) => {
          value[1].map((level) => {
            if (
              confidence &&
              level &&
              Array.isArray(level) &&
              confidence.value === level.join(', ')
            ) {
              confidenceLevel.push(confidence.label);
            }
          });
        });
      }
      return confidenceLevel.join(', ');
    }
    case 'exc_title_rank':
    case 'title_rank': {
      const ranklists = [];
      if (value && value.length > 1 && value[1] && value[1].length > 0) {
        value[1].map((rank) => {
          if (titleRankList && titleRankList.length > 0) {
            titleRankList.map((ranklist) => {
              if (rank === ranklist.value) ranklists.push(ranklist.label);
            });
          }
        });
      }
      return ranklists.join(', ').replaceAll('+', '');
    }

    case 'title_role': {
      const functionList = [];

      if (
        value &&
        value.length > 1 &&
        value[1] &&
        value[1].exact &&
        value[1].exact.length > 0
      ) {
        value[1].exact.map((title) => {
          functionList.push(title.replace('_', ' ').toTitleCase());
        });
      }

      return functionList.join(', ');
    }
    case 'tag_ids':
      if (userTags && userTags.length > 0 && value[1]) {
        const tagsArray = userTags.filter((obj) => value[1].includes(obj.id));
        return tagsArray.map(({ tag }) => tag).join(', ');
      }
      return;

    case 'import_source': {
      let importArray = [];
      if (value[1] && value[1].length > 0)
        importArray = getMultipleValueSelected(importSourceList, value[1]).map(
          (obj) => {
            if (obj.label) return obj.label.toTitleCase();
          },
        );
      return importArray.join(', ');
    }
    case 'file_imports': {
      let importFileArray = [];
      if (
        value[1] &&
        value[1].length > 0 &&
        fileImports &&
        fileImports.length > 0
      ) {
        importFileArray = getMultipleValueSelected(fileImports, value[1]).map(
          (obj) => {
            if (obj.label) return obj.label.toTitleCase();
          },
        );
      }
      return importFileArray.join(', ');
    }
    case 'team_file_imports': {
      let importTeamFileArray = [];
      if (
        value[1] &&
        value[1].length > 0 &&
        teamFileImports &&
        teamFileImports.length > 0
      )
        importTeamFileArray = getMultipleValueSelected(
          teamFileImports,
          value[1],
        ).map((obj) => {
          if (obj.label) return obj.label.toTitleCase();
        });
      return importTeamFileArray.join(', ');
    }
    case 'remaining_share_value_sum':
    case 'wealth_estimate': {
      const resultArray = [];
      if (value && value.length > 1) {
        value[1].map((obj) => {
          if (obj.range && Object.keys(obj.range)[0] === 'wealth_estimate') {
            if (wealthScoreRanges && wealthScoreRanges.length > 0) {
              wealthScoreRanges.map((wealthScore) => {
                if (obj.range) {
                  Object.keys(obj.range).map(function (key) {
                    if (
                      (obj.range[key].gte === wealthScore.min ||
                        obj.range[key].gte === wealthScore.min - 1) &&
                      (obj.range[key].lte === wealthScore.max ||
                        !obj.range[key].lte)
                    ) {
                      resultArray.push(wealthScore.label);
                    }
                  });
                }
              });
            }
            if (resultArray.length === 0 && obj.range.wealth_estimate) {
              resultArray.push(getRangeLabel(obj.range['wealth_estimate']));
            }
          } else if (
            obj.range &&
            Object.keys(obj.range)[0] === 'remaining_share_value_sum'
          ) {
            if (remainingShareValues && remainingShareValues.length > 0) {
              remainingShareValues.map((share) => {
                if (obj.range) {
                  Object.keys(obj.range).map(function (key) {
                    if (
                      obj.range[key].gte === share.min ||
                      obj.range[key].gte === share.min - 1
                    ) {
                      resultArray.push(share.label);
                    }
                  });
                }
              });
            }
          }
        });
      }
      return resultArray.join(', ').replaceAll('+', '');
    }

    case 'previous_experience.ai_org_id':
    case 'exc_current_experience.ai_org_id': {
      const previousOrgArray = [];
      if (value[1] && value[1].length > 0) {
        value[1].map((value) => {
          if (value.name) previousOrgArray.push(value.name);
        });
        if (previousOrgArray.length > 0) {
          if (value[0] === 'previous_experience.ai_org_id')
            return `Previous Companies: ${previousOrgArray.join(', ')}`;
          if (value[0] === 'exc_current_experience.ai_org_id')
            return `Exclude companies: ${previousOrgArray.join(', ')}`;
        }
      }
      break;
    }
    case 'geo_tax_wages_salaries_tips_etc':
    case 'geo_tax_household_income':
    case 'geo_tax_wealth_income':
    case 'geo_tax_retirement_income':
    case 'geo_tax_business_income_or_loss':
    case 'geo_tax_schedule_e_income':
    case 'geo_tax_non_wage_income':
    case 'geo_tax_disposable_income':
    case 'geo_tax_investable_assets':
    case 'geo_tax_capital_gain_or_loss':
    case 'geo_tax_ira_deduction':
    case 'geo_tax_total_gifts_to_charity':
    case 'geo_tax_real_estate_taxes':
    case 'geo_tax_home_mortgage_interest_and_points_reported_to_you':
      if (value[1] && value[1].range && value[1].range[value[0]]) {
        const selectedIrs = IrsItems.filter((irs) => {
          return irs.value === value[0];
        });
        if (selectedIrs && selectedIrs.length > 0)
          return `${selectedIrs[0].label} ${getFormattedRangeLabel(
            value[1].range[value[0]],
          )}`;
      }
      break;
    default:
      break;
  }
}

export function formatPropertyAttributes(fieldValue, id) {
  if (id === 'lotsize_square_feet') {
    const squareFeetArray = [];
    fieldValue.map((obj) => {
      if (obj && obj.value) {
        lotSizeRanges.map((rangeListObj) => {
          if (rangeListObj.value === obj.value)
            squareFeetArray.push({
              range: { lotsize_square_feet: { ...rangeListObj.rangeObject } },
            });
        });
      }
    });
    return squareFeetArray;
  }
  const resultArray = [];
  fieldValue.map((obj) => {
    if (obj && obj.value && obj.rangeObject) {
      resultArray.push({
        range: { [id]: { ...obj.rangeObject } },
      });
    }
  });
  return resultArray;
}

export const getEmployeeConfidenceLevel = (performance) => {
  let performanceLevel = '';
  if (performance > 1.3) {
    performanceLevel = 'Very High';
  } else if (performance < 1.3 && performance >= 1.1) {
    performanceLevel = 'High';
  } else if (performance < 1.1 && performance >= 0.9) {
    performanceLevel = 'Neutral';
  } else if (performance < 0.9 && performance >= 0.7) {
    performanceLevel = 'Low';
  } else if (performance < 0.7 && performance >= 0) {
    performanceLevel = 'Very Low';
  }
  return performanceLevel;
};

export const formatEventDescription = (description, eventType = '') => {
  if (description && typeof description === 'string') {
    if (eventType === 'job title change') {
      description = description.replace('from N/A', '');
    }

    description = description.replace('N/A', 'an undisclosed amount');

    const dateMatch = description.match(/(\d{4}-\d{2}-\d{2})/);
    if (dateMatch) {
      const formattedDate = moment(dateMatch[0]).format('MM-DD-YYYY');
      description = description.replace(dateMatch[0], formattedDate);
    }
    return description;
  } else {
    return;
  }
};

export const getDateRangeFromPeriod = (period) => {
  let count = 0,
    startDate;
  switch (period) {
    case 'day':
      for (let i = 0; i < 8; i++) {
        const today = new Date();
        const currentDate = new Date(today.setDate(today.getDate() - i));
        const day = currentDate.toString().split(' ')[0];
        if (day !== 'Sun' && day !== 'Sat') {
          startDate = currentDate;
          count++;
        }
        if (count === 3) break;
      }
      break;
    case 'week':
      for (let i = 0; i < 8; i++) {
        const today = new Date();
        const currentDate = new Date(today.setDate(today.getDate() - i));
        const day = currentDate.toString().split(' ')[0];
        if (day !== 'Sun' && day !== 'Sat') {
          startDate = currentDate;
          count++;
        }
        if (count === 5) break;
      }
      break;
    case 'month': {
      const today = new Date();
      startDate = new Date(today.setDate(today.getDate() - 30));
      break;
    }
    case 'year':
      startDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1),
      );
      break;
    default:
      break;
  }

  const today = new Date();
  const lastDate = new Date(today.setDate(today.getDate()));
  const startDateRange = moment(startDate).format('YYYY-MM-DD');
  const endDateRange = moment(lastDate).format('YYYY-MM-DD');
  return [startDateRange, endDateRange];
};

export const extractYear = (dateString) => {
  return new Date(dateString).getFullYear();
};

export const formatInvestor = (investor) => {
  if (investor && investor.includes('#||#')) {
    return investor.replaceAll('#||#', ', ');
  }
  return investor;
};

export function formatPhoneNumber(phoneNumberString) {
  if (!phoneNumberString) return '';
  const cleaned = phoneNumberString.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]})\u00a0${match[2]}\u2011${match[3]}`;
  }
  return phoneNumberString;
}

export function getTeamFileLookup(
  filterType,
  addImportsToTeam,
  teamFileImportsWithoutEnrich,
  teamFileImports,
) {
  let teamLookupToChoose = [];
  if (filterType === 'all') {
    teamLookupToChoose = teamFileImports;
  } else if (filterType !== 'first_degree') {
    teamLookupToChoose = teamFileImportsWithoutEnrich;
  } else if (filterType === 'first_degree') {
    teamLookupToChoose = addImportsToTeam;
  } else {
    teamLookupToChoose = [];
  }
  // switch (filterType) {
  //   case 'first_degree':
  //     teamLookupToChoose = addImportsToTeam;
  //     break;
  //   case 'second_degree':
  //     teamLookupToChoose = teamFileImportsWithoutEnrich;
  //     break;
  //   case 'combined':
  //     teamLookupToChoose = [teamFileImportsWithoutEnrich, addImportsToTeam];
  //     break;
  //   default:
  //     break;
  // }

  return teamLookupToChoose;
}

export function getSubscription(currentUser) {
  return (
    currentUser.type !== 'lite' &&
    currentUser.type !== 'inactive' &&
    ((currentUser.activePlan && currentUser.activePlan !== 'free') ||
      currentUser.isAdmin ||
      currentUser.isCorporate)
  );
}

export function arrayHasValues(arry = []) {
  return arry && arry.length > 0;
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
  });
}
