import React, { useEffect, useState } from 'react';
import './index.scss';
import { Button, Select, message, Progress } from 'antd';
import { inject, observer } from 'mobx-react';
import axios, { CancelTokenSource } from 'axios';
import { PlusOutlined } from '@ant-design/icons';

import { CreditsViewPropsInterface } from '../../types/AccountTypes';
import { requestWithAuth } from '../../services/api';
import { splitNumberWithCommas } from '../../utils/general';
import { planCredits } from '../../utils/constants';
import LoaderAnimation from '../../components/AidLoader';
import HelpModal from '../../components/HelpModal';
import InfoIcon from '../../assets/icons/ai_info.svg?react';

message.config({
  top: 80,
});

const { Option } = Select;

const CreditViewNew: React.FC<CreditsViewPropsInterface> = inject('dataStore')(
  observer((props) => {
    // const [isCreditsNeeded, setIsCreditsNeeded] = useState(false);
    // const [creditsAddButton, setCreditsAddButton] = useState(false)
    const [creditsQuantity, setCreditsQuantity] = useState<string>('');
    const [creditBalance, setCreditBalance] = useState<number>(0);
    // const [taxPercent, setTaxPercent] = useState(0);
    const [totalCreditAmount, setTotalCreditAmount] = useState<number>(0);
    // const [taxAmount, setTaxAmount] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showCreditsHelpModal, setShowCreditsHelpModal] =
      useState<boolean>(false);

    let source: CancelTokenSource;

    useEffect(() => {
      const { CancelToken } = axios;
      source = CancelToken.source();
      fetchUserTypeData();

      return () => {
        message.destroy();
        if (source) source.cancel('Operation canceled by the user.');
      };
    }, []);

    useEffect(() => {
      // after completing buying credits
      if (props.creditsCompleted) {
        setIsLoading(false);
        // setIsCreditsNeeded(false);
        setCreditBalance(props.dataStore?.currentUser.creditCount ?? 0);
      }
    }, [props.creditsCompleted]);

    const fetchUserTypeData = () => {
      setIsLoading(true);
      requestWithAuth(
        'user-type',
        'GET',
        null,
        null,
        source.token,
        'progress-credits-view',
      ).then((response) => {
        if (response && response.results && response.results[0]) {
          setCreditBalance(response.results[0].credits);
          props.dataStore.currentUser.creditCount = response.results[0].credits;
        }
        setIsLoading(false);
      });
    };

    const buyMoreCredits = () => {
      if (!creditsQuantity) return;

      //   if (!props.billingInfo) {
      //     // if no billing info added show payment page
      //     const { showPaymentOption } = props;
      //     showPaymentOption(totalCreditAmount, creditsQuantity);
      //     return;
      //   }
      setIsLoading(true);
      const body = {
        create: parseInt(creditsQuantity),
        quantity: parseInt(creditsQuantity),
      };

      if (props.onBuyCredits) props.onBuyCredits(body);
    };

    const handleChange = (value: string) => {
      setCreditsQuantity(value);
      const selectedPlan = planCredits.find(
        (plan) => plan.credit === parseInt(value),
      );
      if (selectedPlan) {
        setTotalCreditAmount(selectedPlan.amount);
        // let taxAmount = 0;
        // taxAmount = ((plan.amount * taxPercent) / 100).toFixed(2);
        // setTaxAmount(taxAmount);
      }
    };

    const numberWithCommas = (x: number) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const percentage = (): number => {
      const totalCredits = props.dataStore?.currentUser.totalCredits || 1;
      return (creditBalance / totalCredits) * 100;
    };

    const renderProgress = (percent: number) => (
      <div className="progress-text">
        <span className="credit-digit" style={{ color: '#3C3C3C' }}>
          {splitNumberWithCommas(creditBalance)}
        </span>
        <span className="credit-text">of</span>
        <span
          className="credit-digit"
          style={{ color: 'var(--color-header-blue-variant)' }}
        >
          {splitNumberWithCommas(
            props.dataStore?.currentUser.totalCredits ?? 0,
          )}
        </span>
        <span className="credit-text">remaining / yearly credits</span>
        <InfoIcon
          className="credits-info"
          onClick={() => setShowCreditsHelpModal(true)}
        />
      </div>
    );

    return (
      <div className="loader-wrapper">
        {isLoading && <LoaderAnimation loaderId="credits-view-loader" />}
        <div
          className={
            isLoading
              ? 'creditsView aid-div-disable'
              : 'creditsView aid-div-enable'
          }
        >
          <div className="credit-container-new">
            <div className="credits-view-header">
              {/* <CreditsIcon className="credit-icon" /> */}
              Credits
            </div>
            <div className="credit-content">
              <div className="progress-container">
                <Progress
                  format={renderProgress}
                  percent={percentage()}
                  percentPosition={{
                    align: 'center',
                    type: 'outer',
                  }}
                  size={[200, 15]}
                  strokeColor="#91AFCA"
                />
              </div>
              <div className="add-more-credit-new">
                <div
                  className="flex"
                  style={{ justifyContent: 'space-between' }}
                >
                  Need more bulk credits?{' '}
                  <span className="quick-add">
                    Quick add here <PlusOutlined />
                  </span>
                </div>
                <div className="credits-select-title">
                  Select credits to buy
                </div>
                <div className="flex">
                  <div className="buy-credits-view">
                    {/* <div className="dropdown"> */}
                    <Select
                      placeholder="Select Credits"
                      onChange={handleChange}
                      className="credits-select"
                      allowClear
                      popupClassName="credits-select-options"
                    >
                      {planCredits.map((plan, index) => (
                        <Option key={index} value={plan.credit.toString()}>
                          {splitNumberWithCommas(plan.credit)}{' '}
                          {`($${splitNumberWithCommas(plan.amount)})`}
                        </Option>
                      ))}
                    </Select>
                    {/* </div> */}
                    {props.billingInfo && (
                      <div className="creditTaxDivision" id="creditTaxDivision">
                        {/* <div className="creditTaxValue">
                          <div>Tax {taxPercent}%</div>
                          <div className="creditTaxPercent">
                            {' '}
                            {`$${numberWithCommas(taxAmount)}`}{' '}
                          </div>
                        </div> */}
                        <div className="taxTotalAmount">
                          <div className="creditSubTotal">Sub Total</div>
                          <div className="creditTotalAmount">
                            {`$${numberWithCommas(
                              parseFloat(totalCreditAmount.toFixed(2)),
                            )}`}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="buy-more-button">
                    <Button
                      onClick={buyMoreCredits}
                      disabled={!creditsQuantity}
                      className={
                        creditsQuantity
                          ? 'buy-more-credit-enabled'
                          : 'buy-more-credit'
                      }
                    >
                      Buy
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showCreditsHelpModal && (
          <HelpModal
            type="credits"
            onClose={() => {
              setShowCreditsHelpModal(false);
            }}
          />
        )}
      </div>
    );
  }),
);

export default CreditViewNew;
