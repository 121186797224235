import { message } from 'antd';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useNavigate } from 'react-router';
import { requestWithAuth } from '../../services/api';
import { getCookie } from '../../utils/cookies';
import Progress from '../../components/Progress';
import LoaderAnimation from '../../components/AidLoader';

message.config({
  top: 80,
});

const MaxioCallBack = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const body = {};
    const hostArray = window.location.host.split('.');
    let domain = 'app';
    if (hostArray && hostArray.length > 0) {
      [domain] = hostArray;
    }
    body.slug = domain.startsWith('localhost') ? 'app' : getCookie('slug');

    const urlParams = location.search;
    const query = qs.parse(urlParams, {
      ignoreQueryPrefix: true,
    });
    if (
      query &&
      !(Object.keys(query).length === 0 && query.constructor === Object) &&
      query.maxio_customer_id
    )
      body.maxio_customer_id = query.maxio_customer_id;

    requestWithAuth('maxio-subscription', 'POST', null, body, null).then(
      (response) => {
        if (!response) return;
        if (response && (response.code === 200 || response.code === 307)) {
          navigate('/user/login/callback');
        } else {
          // if (response.message) message.error(response.message);
          // navigate('/vip/register');
          navigate(`/exception/${response?.statusCode}`, {
            state: {
              errorMessage: response?.message,
              from: 'maxio',
            },
          });
        }
      },
    );
  }, [location]);

  return (
    <div className="loader-wrapper">
      <LoaderAnimation loaderId="maxio-loader" />;
    </div>
  );
};
export default MaxioCallBack;
