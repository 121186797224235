import './index.scss';

import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import namecase from 'namecase';
import { Tooltip, message } from 'antd';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router';
import ExportTable from '../ExportTable';
import { requestWithAuth } from '../../services/api';
import LinkedinIcon from '../../assets/icons/ai_linked_in.svg';
import Filter from '../RISubComponents/Filter';
import RowExtra from '../RowExtra';
import LoaderAnimation from '../AidLoader';

message.config({
  top: 80,
});
let source;
const sortInfo = {
  column: '',
  order: '',
};

const TableSection = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const [tableData, setTableData] = useState([]);
    const [tableCount, setTableCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isRowCrmValue, setisRowCrmValue] = useState('');
    const [isCrmCredentials, setisCrmCredentials] = useState(null);
    const [hasLinkedinImports, setHasLinkedinImports] = useState(false);
    const [listPagination, setListPagination] = useState({
      totalPageSize: 0,
      currentPage: 1,
      size: 20,
    });
    const [selectedProfileIds, setSelectedProfileIds] = useState([]);
    const [exportFromRow, setExportFromRow] = useState(null);
    const navigate = useNavigate();
    const flags = useFlags();

    useEffect(() => {
      const { CancelToken } = axios;
      source = CancelToken.source();

      requestWithAuth('user-type', 'GET', null, null, null).then((response) => {
        if (response && response.results && response.results[0]) {
          const { credits, crm, org_name } = response.results[0];
          props.dataStore.currentUser.creditCount = credits;
          setisRowCrmValue(crm);
        }
      });
      // fetchTableData();
      return () => {
        message.destroy();
        if (source) source.cancel('Operation canceled by the user.');
      };
    }, []);

    useEffect(() => {
      // fetchTableData();
      if (listPagination.totalPageSize === 0) return;
      fetchTableData();
    }, [listPagination.size, listPagination.currentPage]);

    useEffect(() => {
      // fetchTableData();
      fetchTableData();
    }, [props.orgId]);

    useEffect(() => {
      fetchTableData();
    }, [props.globalDataStore.multipleFilters.selectedIdList]);

    const connectPage = () => {
      navigate(`/connect/import`, {
        state: {
          fromSource: 'LinkedIn',
        },
      });
    };

    const handleExportCsvClick = (
      selectedRowKeys,
      from,
      recordb2b,
      recordb2c,
    ) => {
      if (!isRowCrmValue) {
        navigate('/connect/export');
        return;
      }

      setisCrmCredentials({
        id1: recordb2b || '',
        id2: recordb2c || '',
      });
    };
    function showPersonProfile(b2bId, b2cId) {
      // profile clicked
      if (!b2bId && !b2cId) {
        message.warning(
          'This profile cannot be opened right now. Please try again later.',
          10,
        );
        return;
      }

      props.modalDataStore.resetCompanyProfileModal();
      props.modalDataStore.resetProfileModal();
      const { profileModal } = props.modalDataStore;
      profileModal.from = 'n2';
      profileModal.b2bId = b2bId;
      profileModal.b2cId = b2cId;
      profileModal.mimpersonId = '';
      profileModal.eventsId = '';
      profileModal.isVisible = true;

      props.modalDataStore.trackModal.push({
        modal: 'profile',
        data: {
          from: 'n2',
          b2bId,
          b2cId,
        },
      });
    }

    const handleDeleteData = (b2bId, b2cId) => {
      const tableDataResult = tableData.filter((data) => {
        if (!b2bId.includes(data.b2b_id) && !b2cId.includes(data.b2c_id))
          // return !b2bId.includes(data.b2b_id) && !b2cId.includes(data.b2c_id)
          return data;
        setTableCount(tableCount - 1);
      });
      setTableData(tableDataResult);
    };

    const columns = [
      {
        title: 'Name',
        width: 150,
        key: 'full_name',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        render: (_val, rec, index) => {
          if (rec.management_changes && rec.management_changes.length > 0) {
            this.rowKeyArray.push(`${rec.b2b_id}${rec.b2c_id}`);
          }

          return (
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  width: '85%',
                  wordBreak: 'break-word',
                  color: 'var(--color-primary)',
                }}
                // onClick={() => {
                //   props.showProfile(rec.b2b_id, rec.b2c_id);
                // }}
              >
                {namecase(rec.full_name)}
              </div>
              <RowExtra
                rec={rec}
                handleExportCsvClick={handleExportCsvClick}
                selectedProfileIds={selectedProfileIds}
                handleExportFromRowClick={(n2Ids) => setExportFromRow(n2Ids)}
              />
            </div>
          );
        },
      },
      {
        title: 'Title',
        width: 180,
        key: 'title',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                textTransform: 'capitalize',
              }}
            >
              {val.title || ''}
            </div>
          );
        },
      },

      {
        title: 'Degree',
        width: 110,
        align: 'center',
        render: (rec) => {
          if (rec.degree && rec.degree === 1) {
            return (
              <span>
                1<sup>st</sup>
              </span>
            );
          }
          if (rec.degree && rec.degree === 2) {
            return (
              <span>
                2<sup>nd</sup>
              </span>
            );
          }
          return '';
        },
      },
      {
        title: 'Mutual',
        width: 120,
        align: 'center',
        className: 'mutual-rel-col',
        render: (rec) => {
          return (
            <>
              {rec.mutual_contacts_details &&
                rec.mutual_contacts_details.length > 0 && (
                  <div>
                    <div>{rec.mutual_contacts_details.length}</div>
                    <div
                      className="popupDiv-mutual"
                      style={{ display: 'none', position: 'relative' }}
                    >
                      <div className="popup-mutual">
                        <div className="popupInner-mutual">
                          <Tooltip>
                            <div>
                              {rec.mutual_contacts_details.map((obj) => {
                                return (
                                  <div
                                    className="link"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      showPersonProfile(obj.b2b_id, obj.b2c_id);
                                    }}
                                  >
                                    {obj.name}
                                  </div>
                                );
                              })}
                            </div>
                          </Tooltip>
                        </div>

                        <div className="popArrow-mutual" />
                      </div>
                    </div>
                  </div>
                )}
            </>
          );
        },
      },
      {
        title: 'City',
        key: 'location_city',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        width: 150,
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                textTransform: 'capitalize',
              }}
            >
              {val.location_city ? val.location_city.toLowerCase() : ''}
            </div>
          );
        },
      },
      {
        title: 'State',
        width: 100,
        key: 'location_state',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              {val.location_state}
            </div>
          );
        },
      },
    ];

    const handleTableChange = (pagination, filtersArg, sorter) => {
      if (sorter && sorter.order) {
        sortInfo.column = sorter.columnKey;
        sortInfo.order = sorter.order === 'ascend' ? 'asc' : 'desc';
      } else {
        sortInfo.column = '';
        sortInfo.order = '';
      }
      if (
        pagination.pageSize === listPagination.size &&
        pagination.current === listPagination.currentPage
      ) {
        fetchTableData();
      }
      setListPagination({
        ...pagination,
        size: pagination.pageSize,
        currentPage: pagination.current,
      });
    };

    const fetchTableData = () => {
      if (source) source.cancel('Operation canceled by the user.');
      const { CancelToken } = axios;
      source = CancelToken.source();

      if (!props.orgId) return;
      setIsLoading(true);

      let ids = [];
      if (
        props.globalDataStore.multipleFilters.selectedIdList &&
        props.globalDataStore.multipleFilters.selectedIdList.length === 1 &&
        props.globalDataStore.multipleFilters.selectedIdList[0].value === -2
      ) {
        ids = [];
      } else if (
        props.globalDataStore.multipleFilters.selectedIdList &&
        props.globalDataStore.multipleFilters.selectedIdList.length > 0
      ) {
        ids = props.globalDataStore.multipleFilters.selectedIdList.map(
          (obj) => obj.value,
        );
        const index = ids.indexOf(-1);
        if (index > -1) {
          ids.splice(index, 1);
        }
      }

      //const body = {};
      const body = {
        filter_ids: [],
        collapse_field: 'b2b_id',
      };
      body.filter_ids = ids;
      const params = {
        page_size: listPagination.size,
        from: (listPagination.currentPage - 1) * listPagination.size,
      };

      params.filter_type = 'company_connection';
      params.org_reorder = 1;
      const search = {};
      search['current_experience.ai_org_id'] = [
        { ai_org_id: props.orgId, name: props.orgName },
      ];

      if (sortInfo.column && sortInfo.order) {
        params.sort_key = sortInfo.column;
        params.sort_order = sortInfo.order;
      }

      if (search) body.search = search;
      requestWithAuth(
        'network-list-new',
        'POST',
        params,
        body,
        source.token,
        'progress_people_list',
      ).then((response) => {
        if (!response) {
          // props.setRelationshipsCount(0, null, null);
          return;
        }
        setIsLoading(false);
        const paginationLimit = response.pagination_limit || 10000;
        setListPagination((prevState) => ({
          ...prevState,
          totalPageSize:
            response.count > paginationLimit ? paginationLimit : response.count,
        }));
        setTableCount(response.count);
        if (response.has_linkedin_imports) setHasLinkedinImports(true);
        // props.setRelationshipsCount(0, 'filter_type', [
        //   { unique_count: response.count, key: 'combined' },
        // ]);
        setTableData(response.results);
      });
    };

    const paginationProps = {
      ...listPagination,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ['20', '50', '100', '200'],
      total: listPagination.totalPageSize,
      pageSize: listPagination.size,
      current: listPagination.currentPage,
    };

    const setProgress = (value) => {
      setIsLoading(value);
    };

    return (
      <div className="loader-wrapper">
        {isLoading && (
          <LoaderAnimation loaderId="table-section-loader" size="medium" />
        )}
        <div
          className={
            isLoading
              ? 'table-section aid-div-disable'
              : 'table-section aid-div-enable'
          }
        >
          {!hasLinkedinImports && tableCount === 0 && !isLoading ? (
            <>
              <div
                style={{
                  color: 'var(--color-primary)',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  paddingBottom: '10px',
                }}
              >
                1st & 2nd Degree Relationships
              </div>
              <div className="linkedin-section" onClick={connectPage}>
                <img
                  className="linkedin-icon"
                  src={LinkedinIcon}
                  alt="linkedin"
                />
                <div style={{ paddingTop: '10px' }}>
                  Import contacts to view more relationship paths
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <label className="label-first-company">
                    1st & 2nd Degree Relationships{' '}
                    <span className="count-label-company">{tableCount}</span>
                  </label>
                  {tableCount !== 0 && (
                    <div
                      style={{
                        cursor: 'pointer',
                        color: 'var(--color-link)',
                        paddingTop: '10px',
                        paddingLeft: '2px',
                      }}
                      onClick={() =>
                        props.onAllClick('filter_type_table', 'combined', null)
                      }
                    >
                      View all in Prospect Finder
                    </div>
                  )}
                </div>
                <div
                  style={{
                    fontSize: '16px',
                    color: '--color-dark-shade',
                  }}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: 'var(--color-primary)',
                    }}
                  >
                    Prospect Filter
                  </span>
                  <div style={{ marginBottom: '15px', width: '300px' }}>
                    <Filter noFilters={true} />
                  </div>
                </div>
              </div>
              <ExportTable
                dataCount={tableCount}
                columns={columns}
                changeSelectedProfileIds={(ids) => setSelectedProfileIds(ids)}
                expandedRowShowing={false}
                pagination={paginationProps}
                pageSize={paginationProps.pageSize}
                onChange={handleTableChange}
                data={tableData}
                crmRowCredentials={isCrmCredentials}
                setProgress={setProgress}
                clearCrmCredentials={() => {
                  setisCrmCredentials(null);
                }}
                deleteData={handleDeleteData}
                company
                rowKey={(rec) => `${rec.b2b_id}${rec.b2c_id}`}
                exportFromRow={exportFromRow}
                clearExport={() => {
                  setExportFromRow(null);
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  }),
);

export default TableSection;
