import React from 'react';
import './index.scss';

const CreditDescription: React.FC = () => {
  return (
    <div className="credit-description-container">
      <div className="credit-description-header">
        Credits in the Aidentified App
      </div>
      <div className="credit-description-content">
        <div className="left-container">
          Aidentified credits serve as in-app currency, enabling users to unlock
          valuable profile attributes and features.
          <div className="section">
            <div className="section-header">How a credit is used</div>
            <div className="section-details">
              Each time a profile is viewed in Prospect Finder or exported, it
              is considered unlocked. Once unlocked, that profile remains
              available for unlimited viewing and can be accessed via exports
              and integrations at any time for all active subscriptions.
            </div>
          </div>
          <div className="credit-box">
            <div className="credit-circle">1 </div>
            <span className="credit-header">credit deducted</span>
            <div className="credit-details">
              It takes 1 credit to unlock a profile and includes the following
              <ul>
                <li>Viewing and access to the profile</li>
                <li>
                  Prospect’s contact information (available phones and emails)
                </li>
                <li>
                  Ability to export and sync the profile with your available
                  integrations
                </li>
              </ul>
            </div>
          </div>
          <div className="section-data">
            For users who have the Professional plan, certain Premium attributes
            require an additional credit to unlock. Once unlocked, these
            attributes also remain accessible for all active subscriptions.
            Premium attributes are included in the Premium plan.
          </div>
          <div className="credit-box">
            <div className="credit-circle">1 </div>
            <span className="credit-header">credit deducted</span>
            <div className="credit-details">
              <div className="credit-title">
                It takes 1 additional credit to unlock Premium attributes
              </div>
              <ul>
                <li>Property value and ownership information</li>
                <li>Property listings information and statuses</li>
                <li>Wealth and Income information</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="right-title">Credit Allotment by Plan</div>
          <div className="plan-section">
            Yearly Plan Credits
            <div className="plan-section-details">
              Allotted at the start of a yearly subscription term and
              replenished yearly upon renewal.
            </div>
          </div>
          <div className="plan-lists">
            <ul>
              <li>Professional: 2,000 credits</li>
              <li>Premium: 5,000 credits</li>
            </ul>
          </div>
          <div className="note">
            Unused credits expire at the end of the subscription term, do not
            carry over, and are not transferrable.
          </div>
          <div className="plan-section">
            Bulk Credits
            <div className="plan-section-details">
              Available for purchase anytime, these credits function identically
              to Yearly Plan Credits but never expire.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditDescription;
