import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { setCookie } from '../../utils/cookies';
import { getDomain } from '../../utils/general';
import LoaderAnimation from '../../components/AidLoader';

const VipContent = (props) => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const hostArray = window.location.host.split('.');
    if (hostArray && hostArray.length > 0 && hostArray[0]) {
      setCookie('slug', hostArray[0], 1);
    }
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
      appState: {
        returnTo: `${getDomain()}/vip/signup/callback`,
      },
    });
  }, []);

  return (
    <div className="exception">
      <LoaderAnimation loaderId="vip-loader" />
    </div>
  );
};

export default VipContent;
