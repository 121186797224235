import './index.scss';

import { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { useLocation } from 'react-router';
import { message, Modal } from 'antd';
import { FileDrop } from 'react-file-drop';
import { PlayCircleFilled } from '@ant-design/icons';
import qs from 'qs';

import CsvIconFile from '../../assets/icons/ai_csv_import_icon.svg';
import InfoIcon from '../../assets/icons/info.svg?react';
import GoogleIcon from '../../assets/icons/ai_google.svg';
import ICloudIcon from '../../assets/icons/ai_icloud.svg';
import LinkedinIcon from '../../assets/icons/ai_linked_in.svg';
import OutlookIcon from '../../assets/icons/ai_outlook.svg';

import CsvImportNew from '../../components/ConnectImport/csvImportNew';
import GoogleContactsImport from '../../components/ConnectImport/googleContactsImport';
import ICloudImport from '../../components/ConnectImport/iCloudImport';
import LinkedInImport from '../../components/ConnectImport/linkedInImport';
import OutlookContactsImport from '../../components/ConnectImport/outlookContactsImport';

const Import = inject('dataStore')(
  observer((props) => {
    const location = useLocation();
    const [aciveConnect, setActiveConnect] = useState('');
    const [fileName, setFileName] = useState('');
    const [inputFile, setInputFile] = useState(null);
    const [showCsvImport, setShowCsvImport] = useState(false);
    const inputRef = useRef();
    useEffect(() => {
      props.dataStore.importContacts.activeKeyValue = aciveConnect;
    }, [aciveConnect]);

    useEffect(() => {
      /**
       * handles click from linkedin re-import push notification
       */
      const query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      if (query && query.type && query.type == 'linkedin re-import') {
        setActiveConnect('linkedIn');
        window.history.replaceState(null, null, window.location.pathname);
      }

      /**
       * handles click from RI->overview top portion tabs click
       */
      if (location && location.state) {
        switch (location.state.fromSource) {
          case 'iCloud':
            setActiveConnect('iCloud');
            break;
          case 'Google':
            setActiveConnect('google');
            break;
          case 'LinkedIn':
            setActiveConnect('linkedIn');
            break;
          case 'Outlook':
            setActiveConnect('outlook');
            break;
          case 'csv':
            setActiveConnect('csv');
            break;
          default:
            setActiveConnect('');
            break;
        }
        window.history.replaceState(null, null, window.location.pathname);
      }
    }, []);

    const ImportItem = ({ code, title, icon }) => {
      return (
        <div
          className="container-item import-item-left"
          onClick={() => {
            setActiveConnect(code);
          }}
        >
          <img className="item-icon" src={icon} alt={title} />
          <div className="title-name">{title}</div>
        </div>
      );
    };

    /**
     *
     * @param {* The file selected/dropped} inputFile
     * Checks if it's zip/csv, if so then proceed,
     * else throw an error and return
     * @returns
     */
    const checkFileType = (inputFile) => {
      if (inputFile) {
        if (
          inputFile.type === 'application/x-zip-compressed' ||
          inputFile.type === 'application/zip' ||
          inputFile.type === 'application/vnd.ms-excel' ||
          inputFile.type === 'text/csv'
        ) {
          setFileName(inputFile.name);
        } else {
          message.error('Incorrect file format', 5);
          return;
        }
        setInputFile(inputFile);
        setActiveConnect('csv');
        setShowCsvImport(true);
      }
    };

    /**
     * Method invoked when file is selected by click.
     * Will be an array and thus getting the first file
     * and passing to the method @checkFileType for classify file types and then parsing
     * @param {* slecetd files array} data
     */
    const onFileSelected = (data) => {
      checkFileType(data.target.files[0]);
    };

    /**
     * Method invoked when file is dropped.
     * Will be an array and thus getting the first file
     * and passing to the method @checkFileType for classify file types and then parsing
     * @param {* dropped files array} files
     */
    const handleDrop = (files) => {
      checkFileType(files[0]);
    };

    const onFileCancel = () => {
      setInputFile(null);
      setFileName('');
      setShowCsvImport(false);
      if (inputRef && inputRef.current) inputRef.current.value = null;
    };

    const onSuccess = () => {
      setActiveConnect('');
    };

    const renderConnectComponent = () => {
      switch (aciveConnect) {
        case 'linkedIn':
          return <LinkedInImport />;
        case 'google':
          return <GoogleContactsImport />;
        case 'iCloud':
          return <ICloudImport onSuccess={onSuccess} />;
        case 'outlook':
          return <OutlookContactsImport />;
        default: {
          return (
            <div id="import" className="container import-container">
              <div id="import-options">
                <div className="connect-title"> Relationships </div>
                <div className="inner-container">
                  <ImportItem
                    code="linkedIn"
                    title="LinkedIn"
                    icon={LinkedinIcon}
                  />
                  <ImportItem code="google" title="Google" icon={GoogleIcon} />

                  <ImportItem code="iCloud" title="iCloud" icon={ICloudIcon} />

                  <ImportItem
                    code="outlook"
                    title="Outlook"
                    icon={OutlookIcon}
                  />
                </div>
              </div>
              <div>
                <div
                  className="connect-title"
                  style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}
                >
                  Clients/Prospects
                  <InfoIcon className="info-icon" id="csv-import-help-icon" />
                  <div
                    id="imports-help-watch"
                    style={{
                      fontSize: '12px',
                      fontWeight: 200,
                      color: 'var(--color-link)',
                      cursor: 'pointer',
                    }}
                  >
                    <PlayCircleFilled className="play-icon" />
                    CSV Help
                  </div>
                </div>
                <div className="csv-file-drop">
                  <FileDrop onDrop={handleDrop}>
                    <div
                      className={
                        'container-item container-item-csv drop-section'
                      }
                      // onClick={() => {
                      //   setActiveConnect("csv");
                      // }}
                    >
                      <img src={CsvIconFile} alt="CSV" />
                      <div style={{ color: 'var(--color-grey-dark)' }}>
                        Drop File here
                      </div>
                      <input
                        ref={inputRef}
                        autoComplete="__away"
                        className="upload-link"
                        type="file"
                        name="file"
                        onChange={onFileSelected}
                        accept=" .csv, .zip "
                      />
                    </div>
                  </FileDrop>
                </div>
              </div>
            </div>
          );
        }
      }
    };

    return (
      <div id="import" className="import-export-container">
        <div className="import-export-left">
          <div className="title">Import</div>
          <div className="description">
            Check back regurarly to update connections from your external
            sources. This will considerably improve your searches and get you
            better matches!
          </div>
          {aciveConnect && (
            <div
              className="back-to"
              onClick={() => {
                setActiveConnect('');
              }}
            >
              {' < Back to Import options'}
            </div>
          )}
        </div>
        <div className="import-right">{renderConnectComponent()}</div>
        {showCsvImport && (
          <Modal
            open={showCsvImport}
            width="100%"
            onCancel={onFileCancel}
            wrapClassName="csv-modal-class"
            footer={null}
            center={true}
          >
            <CsvImportNew
              inputFile={inputFile}
              fileName={fileName}
              onSuccess={onSuccess}
              onCancel={onFileCancel}
            />
          </Modal>
        )}

        {/* <div id="portal-root"></div> */}
      </div>
    );
  }),
);

export default Import;
