import './index.scss';

import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import { message, Spin } from 'antd';
import moment from 'moment';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import withRouter from '../../hooks/withRouter';
import { requestWithAuth } from '../../services/api';
import CompanyInfo from './companyInfo';
import CardSection from './cardSection';
import CompanyEvents from './companyEvents';
import EmployeeStockTransactions from './employeeStockTransactions';
import PieChartSection from './pieChartSection';
import TableSection from './tableSection';
import ChartSection from './chartSection';
import CompanyProfileHeader from './CompanyProfileHeader';
import CompanyProfileHeaderNew from './CompanyProfileHeaderNew';

message.config({
  top: 80,
});
let source;

const CompanyProfile = inject('dataStore')(
  inject('globalDataStore')(
    inject('modalDataStore')(
      observer(({ columns, data, ...props }) => {
        const [eventData, setEventData] = useState([]);
        const [transactionData, setTransactionData] = useState([]);
        const [companyData, setCompanyData] = useState([]);
        const [salaryRange, setSalaryRange] = useState([]);
        const [titleConcentration, setTitleConcentration] = useState([]);
        const [titleGroupConcentration, setTitleGroupConcentration] = useState(
          [],
        );
        const [employeesStudy, setEmployeesStudy] = useState([]);
        const [homeCities, setHomeCities] = useState([]);
        const [homeStates, setHomeStates] = useState([]);
        const [employeesGo, setEmployeesGo] = useState([]);
        const [newHires, setNewHires] = useState([]);
        const [positionsFilled, setPositionsFilled] = useState([]);
        const [interests, setInterests] = useState([]);
        const [zip, setZip] = useState([]);
        const [wealthSegment, setWealthSegment] = useState([]);
        const [cardData, setCardData] = useState(new Array(6));
        const [isLoading, setIsLoading] = useState(false);
        const [cityReload, setCityReload] = useState(false);
        const [statesReload, setStatesReload] = useState(false);
        const [salaryReload, setSalaryReload] = useState(false);
        const [titleReload, setTitleReload] = useState(false);
        const [titleGroupReload, setTitleGroupReload] = useState(false);
        const [employeeStudyReload, setEmployeeStudyReload] = useState(false);
        const [employeeGoReload, setEmployeeGoReload] = useState(false);
        const [newHireReload, setNewHireReload] = useState(false);
        const [positionReload, setPositionReload] = useState(false);
        const [interestsReload, setInterestsReload] = useState(false);
        const [zipReload, setZipReload] = useState(false);
        const [wealthSegmentReload, setWealthSegmentReload] = useState(false);
        const [cityLoading, setCityLoading] = useState(false);
        const [statesLoading, setStatesLoading] = useState(false);
        const [salaryLoading, setSalaryLoading] = useState(false);
        const [titleLoading, setTitleLoading] = useState(false);
        const [titleGroupLoading, setTitleGroupLoading] = useState(false);
        const [employeeStudyLoading, setEmployeeStudyLoading] = useState(false);
        const [employeeGoLoading, setEmployeeGoLoading] = useState(false);
        const [newHireLoading, setNewHireLoading] = useState(false);
        const [positionLoading, setPositionLoading] = useState(false);
        const [interestsLoading, setInterestsLoading] = useState(false);
        const [zipLoading, setZipLoading] = useState(false);
        const [wealthSegmentLoading, setWealthSegmentLoading] = useState(false);
        const flags = useFlags();

        useEffect(() => {
          /** close modal on browser back click, keeping the search criterias in advancedFilter.
           * so add same path in history to avoid url change.
           * browser back click event is handled in ModalView component  */
          window.history.pushState(null, null, window.location.pathname);

          if (props.modalDataStore.trackModal.length === 0) {
            props.modalDataStore.trackModal.push({
              modal: 'company',
              data: {
                id: props.modalDataStore.companyProfileModal.id,
                orgName: props.modalDataStore.companyProfileModal.orgName,
              },
            });
          }

          fetchData();

          return () => {
            message.destroy();
            if (source) source.cancel('Operation canceled by the user.');

            const selectedIdList =
              props.globalDataStore.multipleFilters.filterList.filter(
                (filter) => {
                  return filter.is_active;
                },
              );

            if (selectedIdList.length === 0) {
              props.globalDataStore.multipleFilters.selectedIdList = [
                { value: -1, label: 'All Prospect Filters' },
                ...props.globalDataStore.multipleFilters.allFilterIds,
              ];
            } else {
              props.globalDataStore.multipleFilters.selectedIdList =
                selectedIdList;
            }
          };
        }, []);

        const setEachCardData = (position, key, result) => {
          if (result && result.length > 0 && result[0].unique_count > 0)
            cardData[position] = {
              key,
              count: result[0].unique_count,
              value: result[0].key,
              orgId: result[0].ai_org_id || null,
            };
          else cardData[position] = {};
          if (!cardData.includes(undefined)) {
            setCardData([...cardData]);
          }
        };

        const fetchData = () => {
          const { CancelToken } = axios;
          source = CancelToken.source();

          fetchCompanyData();
          fetchGraphData('inferred_salary');
          fetchGraphData('title');
          fetchGraphData('title_rank');
          fetchGraphData('location_city');
          fetchGraphData('location_state');
          fetchGraphData('current_experience.ai_org_id');
          fetchGraphData('previous_experience.ai_org_id');
          fetchGraphData('schools');
          fetchGraphData('industry');
          fetchGraphData('wealth_estimate');
          fetchGraphData('home_postal_code');
          fetchGraphData('interests');
          fetchRelationshipData();
          fetchEventData();
          fetchTransactionData();
        };

        const fetchRelationshipData = () => {
          const body = {
            collapse_field: 'b2b_id',
          };

          const params = {
            page_size: 10,
            from: 0,
            filter_type: 'company_connection',
            org_reorder: 1,
          };

          const search = {};
          search['current_experience.ai_org_id'] = [
            {
              ai_org_id: props.modalDataStore.companyProfileModal.id,
              name: props.modalDataStore.companyProfileModal.orgName,
            },
          ];

          if (search) body.search = search;
          requestWithAuth(
            'network-list-new',
            'POST',
            params,
            body,
            null,
            null,
          ).then((response) => {
            if (response && response.results) {
              setEachCardData(0, 'filter_type', [
                { unique_count: response.count, key: 'combined' },
              ]);
            } else {
              setEachCardData(0, null, null);
            }
          });
        };

        const fetchEventData = () => {
          if (!props.modalDataStore.companyProfileModal.orgName) return;
          requestWithAuth(
            'company-events-ds2',
            'POST',
            { impact_type: 'multiple' },
            {
              search: {
                event_org_name: [
                  props.modalDataStore.companyProfileModal.orgName,
                ],
              },
            },
            source.token,
          ).then((response) => {
            if (!response) return;
            setEventData(response);
          });
        };
        const fetchTransactionData = () => {
          if (!props.modalDataStore.companyProfileModal.orgName) return;
          requestWithAuth(
            'company-events-ds2',
            'POST',
            { impact_type: 'single' },
            {
              search: {
                event_org_name: [
                  props.modalDataStore.companyProfileModal.orgName,
                ],
              },
            },
            source.token,
          ).then((response) => {
            if (!response) return;
            setTransactionData(response);
          });
        };

        const fetchCompanyData = () => {
          setIsLoading(true);
          const params = {
            ai_org_id: props.modalDataStore.companyProfileModal.id,
          };
          requestWithAuth(
            'company-profile',
            'GET',
            params,
            null,
            source.token,
          ).then((response) => {
            setIsLoading(false);
            if (!response) return;
            setCompanyData(response);
          });
        };

        function fetchGraphData(key) {
          if (key === 'inferred_salary') setSalaryLoading(true);
          if (key === 'title_rank') setTitleGroupLoading(true);
          if (key === 'title') setTitleLoading(true);
          if (key === 'location_city') setCityLoading(true);
          if (key === 'location_state') setStatesLoading(true);
          if (key === 'previous_experience.ai_org_id') setNewHireLoading(true);
          if (key === 'schools') setEmployeeStudyLoading(true);
          if (key === 'current_experience.ai_org_id')
            setEmployeeGoLoading(true);
          if (key === 'industry') setPositionLoading(true);
          if (key === 'wealth_estimate') setWealthSegmentLoading(true);
          if (key === 'home_postal_code') setZipLoading(true);
          if (key === 'interests') setInterestsLoading(true);

          const params = {
            page_size: 100,
            from: 0,
            filter_type: 'all',
            key,
          };
          const body = {};

          if (key === 'current_experience.ai_org_id') {
            body.search = {
              'previous_experience.ai_org_id': [
                {
                  ai_org_id: props.modalDataStore.companyProfileModal.id,
                  name: props.modalDataStore.companyProfileModal.orgName,
                },
              ],
            };
          } else {
            body.search = {
              'current_experience.ai_org_id': [
                {
                  ai_org_id: props.modalDataStore.companyProfileModal.id,
                  name: props.modalDataStore.companyProfileModal.orgName,
                },
              ],
            };
          }

          if (key === 'industry') {
            const dateArray = [];
            dateArray.push(moment().subtract(1, 'years').format('YYYY-MM-DD'));
            dateArray.push(moment().format('YYYY-MM-DD'));
            body.management_changes = {
              management_change_date_range: dateArray,
            };
          }
          requestWithAuth(
            'people-grouping-new',
            'POST',
            params,
            body,
            source.token,
          ).then((response) => {
            switch (key) {
              case 'inferred_salary':
                setSalaryLoading(false);
                if (response && response.results) {
                  setSalaryRange(response.results);
                } else {
                  setSalaryReload(true);
                }
                break;
              case 'title':
                setTitleLoading(false);
                if (response && response.results) {
                  setTitleConcentration(response.results);
                  setEachCardData(5, key, response.results);
                } else {
                  setEachCardData(5, null, null);
                  setTitleReload(true);
                }
                break;
              case 'title_rank':
                setTitleGroupLoading(false);
                if (response && response.results) {
                  setTitleGroupConcentration(response.results);
                } else setTitleGroupReload(true);
                break;
              case 'schools':
                setEmployeeStudyLoading(false);
                if (response && response.results) {
                  setEmployeesStudy(response.results);
                  setEachCardData(2, key, response.results);
                } else {
                  setEachCardData(2, null, null);
                  setEmployeeStudyReload(true);
                }
                break;
              case 'location_city':
                setCityLoading(false);
                if (response && response.results) {
                  setHomeCities(response.results);
                  setEachCardData(1, key, response.results);
                } else {
                  setEachCardData(1, null, null);
                  setCityReload(true);
                }
                break;
              case 'location_state':
                setStatesLoading(false);
                if (response && response.results) {
                  setHomeStates(response.results);
                } else setStatesReload(true);
                break;
              case 'current_experience.ai_org_id':
                setEmployeeGoLoading(false);
                if (response && response.results) {
                  const company = response.results.filter((org) => {
                    return (
                      org.ai_org_id !==
                      props.modalDataStore.companyProfileModal.id
                    );
                  });
                  setEmployeesGo(company);
                  setEachCardData(4, 'current_experience.ai_org_id', company);
                } else {
                  setEachCardData(4, null, null);
                  setEmployeeGoReload(true);
                }
                break;
              case 'previous_experience.ai_org_id': {
                setNewHireLoading(false);
                if (response && response.results) {
                  const company = response.results.filter((org) => {
                    return (
                      org.ai_org_id !==
                      props.modalDataStore.companyProfileModal.id
                    );
                  });
                  setNewHires(company);
                  setEachCardData(3, key, company);
                } else {
                  setEachCardData(3, null, null);
                  setNewHireReload(true);
                }
                break;
              }
              case 'industry':
                setPositionLoading(false);
                if (response && response.results) {
                  setPositionsFilled(response.results);
                } else setPositionReload(true);
                break;
              case 'wealth_estimate':
                setWealthSegmentLoading(false);
                if (response && response.results) {
                  setWealthSegment(response.results);
                } else setWealthSegmentReload(true);
                break;
              case 'home_postal_code':
                setZipLoading(false);
                if (response && response.results) {
                  setZip(response.results);
                } else setZipReload(true);
                break;
              case 'interests':
                setInterestsLoading(false);
                if (response && response.results) {
                  setInterests(response.results);
                } else setInterestsReload(true);
                break;
              default:
                break;
            }
          });
        }

        function prepareListGraphData(response) {
          const chartData = [];
          if (response && response.length > 0) {
            response.map((item) => {
              chartData.push({
                label: item.key ? item.key.toString().toTitleCase() : '',
                count: item.unique_count ? item.unique_count : item.doc_count,
                // eslint-disable-next-line no-prototype-builtins
                key: item.hasOwnProperty('ai_org_id')
                  ? item.ai_org_id
                  : item.key,
              });
            });
          }
          return chartData;
        }

        function prepareGraphData(dataArray, colorChange) {
          const labels = [];
          const keyLabels = [];
          dataArray.map((value) => {
            labels.push(value.key ? value.key.toString().toTitleCase() : '');
            // eslint-disable-next-line no-prototype-builtins
            if (value.hasOwnProperty('ai_org_id'))
              keyLabels.push(value.ai_org_id);
          });
          return {
            labels,
            keyLabels,
            // keyLabels: dataArray.map((value) => value.key),
            // toolTipLabels: dataArray.map((value) =>
            //   value.key ? value.key.toUpperCase() : '',
            // ),

            datasets: [
              {
                barThickness: 6,
                // backgroundColor: 'rgb(184,217,146)',
                // backgroundColor: 'rgb(184,217,146)',
                backgroundColor: getComputedStyle(document.body)
                  .getPropertyValue(
                    colorChange ? `${colorChange}` : '--color-golden-end',
                  )
                  .replace(')', ', 0.65)')
                  .replace('rgb', 'rgba'),
                hoverBackgroundColor: getComputedStyle(
                  document.body,
                ).getPropertyValue('--color-primary'),
                data: dataArray.map((value) => value.unique_count),
              },
            ],
          };
        }

        function preparePieChartData(dataArray) {
          const labelArray = [];
          const valueArray = [];
          dataArray.map((value) => {
            labelArray.push(value.key);
            valueArray.push(value.unique_count);
          });
          return {
            data: valueArray,
            labels: labelArray,
          };
        }

        function showPersonProfile(b2bId, b2cId) {
          // profile clicked
          if (!b2bId && !b2cId) {
            message.warning(
              'This profile cannot be opened right now. Please try again later.',
              10,
            );
            return;
          }

          props.modalDataStore.resetCompanyProfileModal();
          props.modalDataStore.resetProfileModal();
          const { profileModal } = props.modalDataStore;
          profileModal.b2bId = b2bId;
          profileModal.b2cId = b2cId;
          profileModal.mimpersonId = '';
          profileModal.eventsId = '';
          profileModal.isVisible = true;

          props.modalDataStore.trackModal.push({
            modal: 'profile',
            data: {
              from: 'n2',
              b2bId,
              b2cId,
            },
          });
        }

        const seeInPeopleView = (key, data, id) => {
          props.dataStore.companyFilter = {};
          switch (key) {
            case 'people':
              props.dataStore.companyFilter.org_name = {
                label: props.modalDataStore.companyProfileModal.orgName,
                value: props.modalDataStore.companyProfileModal.id,
              };
              break;
            case 'current_experience.ai_org_id':
              props.dataStore.companyFilter.previous_experience_org_name = {
                label: props.modalDataStore.companyProfileModal.orgName,
                value: props.modalDataStore.companyProfileModal.id,
              };
              props.dataStore.companyFilter.org_name = {
                label: data,
                value: id,
              };
              break;
            case 'previous_experience.ai_org_id':
              props.dataStore.companyFilter.previous_experience_org_name = {
                label: data,
                value: id,
              };
              props.dataStore.companyFilter.org_name = {
                label: props.modalDataStore.companyProfileModal.orgName,
                value: props.modalDataStore.companyProfileModal.id,
              };
              break;
            case 'filter_type_table': {
              let filterSelected = [];
              if (
                props.globalDataStore.multipleFilters.selectedIdList &&
                props.globalDataStore.multipleFilters.selectedIdList.length ===
                  1 &&
                props.globalDataStore.multipleFilters.selectedIdList[0]
                  .value !== -1 &&
                props.globalDataStore.multipleFilters.selectedIdList[0]
                  .value !== -2
              ) {
                filterSelected =
                  props.globalDataStore.multipleFilters.filterList.filter(
                    (filter) => {
                      return (
                        filter.value ===
                        props.globalDataStore.multipleFilters.selectedIdList[0]
                          .value
                      );
                    },
                  );
                props.dataStore.companyFilter.filterSelected =
                  filterSelected[0];
              }
              props.dataStore.companyFilter.filter_type = data;
              props.dataStore.companyFilter.org_name = {
                label: props.modalDataStore.companyProfileModal.orgName,
                value: props.modalDataStore.companyProfileModal.id,
              };
              break;
            }
            default:
              props.dataStore.companyFilter[key] = data;
              props.dataStore.companyFilter.org_name = {
                label: props.modalDataStore.companyProfileModal.orgName,
                value: props.modalDataStore.companyProfileModal.id,
              };
              break;
          }
          if (props.dataStore.lookUpLists.isPeoplePage) {
            props.modalDataStore.resetAll();
          } else {
            props.navigate('/prospect-finder/people');
          }
        };

        const getTechStackData = () => {
          const techStack = companyData.tech_stack_metrics.map(
            (techStach) => techStach.stack,
          );
          return techStack.join(', ');
        };

        return (
          <div className="company-modal" style={{ paddingTop: 0 }}>
            {/* <Spin size="large" spinning={isLoading}> */}
            <div
              className="scroll-container"
              style={{
                height: 'calc(100vh - 101px)',
              }}
            >
              <CompanyProfileHeaderNew
                data={companyData}
                onBackArrowClick={props.onBackArrowClick}
              />
              <div className="first-section">
                <CompanyInfo
                  data={companyData}
                  isLoading={isLoading}
                  seeInPeopleView={seeInPeopleView}
                />
                <CardSection
                  list={cardData}
                  seeInPeopleView={seeInPeopleView}
                />
              </div>
              <div>
                <TableSection
                  orgId={props.modalDataStore.companyProfileModal.id}
                  orgName={props.modalDataStore.companyProfileModal.orgName}
                  showProfile={showPersonProfile}
                  setRelationshipsCount={setEachCardData}
                  onAllClick={seeInPeopleView}
                />
              </div>

              <div className="graph-separation">
                <hr />
                <div>LOCATION</div>
                <hr />
              </div>
              <div
                className="chart-section"
                style={{ gridTemplateColumns: 'repeat(3,1fr)' }}
              >
                <ChartSection
                  data={prepareListGraphData(homeCities)}
                  titleLabel="Home Cities"
                  boxName="city"
                  id="location_city"
                  click={seeInPeopleView}
                  isLoading={cityLoading}
                  reload={cityReload}
                  apiClick={fetchGraphData}
                  loaderId="chart-city-loader"
                />
                <ChartSection
                  data={prepareGraphData(homeStates)}
                  titleLabel="Home States"
                  id="location_state"
                  click={seeInPeopleView}
                  isLoading={statesLoading}
                  reload={statesReload}
                  apiClick={fetchGraphData}
                  loaderId="chart-state-loader"
                />
                <ChartSection
                  data={prepareGraphData(zip)}
                  titleLabel="Zip"
                  id="home_postal_code"
                  click={seeInPeopleView}
                  isLoading={zipLoading}
                  reload={zipReload}
                  apiClick={fetchGraphData}
                  loaderId="chart-zip-loader"
                />
              </div>
              <div className="graph-separation">
                <hr />
                <div>POSITIONS</div>
                <hr />
              </div>
              <div
                className="chart-section"
                style={{ gridTemplateColumns: 'repeat(3,1fr)' }}
              >
                <ChartSection
                  data={prepareListGraphData(newHires)}
                  titleLabel="New Hires From These Companies"
                  id="previous_experience.ai_org_id"
                  click={seeInPeopleView}
                  boxName="company"
                  isLoading={newHireLoading}
                  reload={newHireReload}
                  apiClick={fetchGraphData}
                  loaderId="chart-new-hires-loader"
                />
                <ChartSection
                  data={prepareListGraphData(employeesGo)}
                  titleLabel="Where Employees Go"
                  id="current_experience.ai_org_id"
                  click={seeInPeopleView}
                  boxName="company"
                  isLoading={employeeGoLoading}
                  reload={employeeGoReload}
                  apiClick={fetchGraphData}
                  loaderId="chart-current-exp-loader"
                />
                <ChartSection
                  data={prepareListGraphData(positionsFilled)}
                  titleLabel="Positions Filled During Past Year"
                  id="industry"
                  click={seeInPeopleView}
                  boxName="title"
                  isLoading={positionLoading}
                  reload={positionReload}
                  apiClick={fetchGraphData}
                  loaderId="chart-industry-loader"
                />
              </div>
              <div className="chart-section">
                <ChartSection
                  data={prepareGraphData(
                    titleGroupConcentration,
                    '--color-primary',
                  )}
                  titleLabel="Title Group Concentration"
                  id="title_rank"
                  click={seeInPeopleView}
                  isLoading={titleGroupLoading}
                  reload={titleGroupReload}
                  apiClick={fetchGraphData}
                  loaderId="chart-title-rank-loader"
                />
                <ChartSection
                  data={prepareGraphData(titleConcentration, '--color-primary')}
                  titleLabel="Title Concentration"
                  id="title"
                  click={seeInPeopleView}
                  isLoading={titleLoading}
                  reload={titleReload}
                  apiClick={fetchGraphData}
                  loaderId="chart-title-loader"
                />
              </div>
              <div className="graph-separation">
                <hr style={{ width: '38%' }} />
                <div>EDUCATION AND INTERESTS</div>
                <hr style={{ width: '38%' }} />
              </div>
              <div className="chart-section">
                <ChartSection
                  data={prepareGraphData(employeesStudy)}
                  titleLabel="Where Employees Studied"
                  id="schools"
                  click={seeInPeopleView}
                  isLoading={employeeStudyLoading}
                  reload={employeeStudyReload}
                  apiClick={fetchGraphData}
                  loaderId="chart-school-loader"
                />
                <ChartSection
                  data={prepareGraphData(interests)}
                  titleLabel="Modeled Interests"
                  id="interests"
                  click={seeInPeopleView}
                  isLoading={interestsLoading}
                  reload={interestsReload}
                  apiClick={fetchGraphData}
                  loaderId="chart-interests-loader"
                />
              </div>
              <div className="graph-separation">
                <hr />
                <div>WEALTH</div>
                <hr />
              </div>
              <div className="chart-section">
                <PieChartSection
                  data={preparePieChartData(salaryRange)}
                  id="inferred_salary_range"
                  titleLabel="Inferred Salary Ranges"
                  click={seeInPeopleView}
                  isLoading={salaryLoading}
                  reload={salaryReload}
                  apiClick={fetchGraphData}
                  loaderId="chart-salary-loader"
                />
                <ChartSection
                  data={prepareGraphData(wealthSegment)}
                  titleLabel="Wealth Segment"
                  id="wealth_estimate"
                  click={seeInPeopleView}
                  isLoading={wealthSegmentLoading}
                  reload={wealthSegmentReload}
                  apiClick={fetchGraphData}
                  loaderId="chart-wealth-loader"
                />
              </div>

              {companyData &&
                companyData.tech_stack_metrics &&
                companyData.tech_stack_metrics.length > 0 && (
                  <div className="company-tech-stack-section">
                    <div className="tech-stack-container">
                      <div className="tech-stack-title">Technology Stack</div>
                      <div className="tech-stack-content">
                        {getTechStackData()}
                      </div>
                    </div>
                  </div>
                )}

              <div className="company-card-table-section">
                {eventData && eventData.length > 0 && (
                  <CompanyEvents
                    list={eventData}
                    eventFilters={props.dataStore.lookUpLists.eventList.slice(
                      1,
                    )}
                  />
                )}
                {transactionData && transactionData.length > 0 && (
                  <EmployeeStockTransactions
                    list={transactionData}
                    showProfile={showPersonProfile}
                    eventFilters={props.dataStore.lookUpLists.eventList.slice(
                      1,
                    )}
                  />
                )}
              </div>
            </div>
            {/* </Spin> */}
          </div>
        );
      }),
    ),
  ),
);

export default withLDConsumer()(withRouter(CompanyProfile));
