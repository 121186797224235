import './index.scss';

import { Button, message } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';

import connectIcon from '../../assets/icons/ai_export_connect.svg?react';
import iconHubspot from '../../assets/icons/ai_hubspot.svg?react';
import image14 from '../../assets/images/ai_hubspot14.jpg';
import image15 from '../../assets/images/ai_hubspot15.jpg';
import image16 from '../../assets/images/ai_hubspot16.jpg';

import { requestWithAuth } from '../../services/api';
import { setCookie } from '../../utils/cookies';
import LoaderAnimation from '../AidLoader';
import { AboutText, IntegratedView } from './exportCRMSection';

message.config({
  top: 80,
});

let source;

const HubSpotView = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const { CancelToken } = axios;
    source = CancelToken.source();
    return () => {
      message.destroy();
      if (source) source.cancel('Operation canceled by the user.');
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    requestWithAuth('hubspot-register', 'GET', null, null, source.token)
      .then((response) => {
        if (!response) return;
        if (response !== undefined) {
          localStorage.setItem('crm_source', 'hs');
          if (response.authorization_url) {
            const hostArray = window.location.host.split('.');
            if (hostArray && hostArray.length > 0 && hostArray[0]) {
              setCookie('slug', hostArray[0], 1);
            }
            window.open(response.authorization_url, '_self');
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        message.error('HubSpot integration failed!', 5);
      });
  };

  const { crmValue, removeIntegration } = props;
  // const crmValue = 'hs';

  return (
    <div className="loader-wrapper">
      {isLoading && <LoaderAnimation loaderId="hubspot-loader" />}
      <div className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
        {crmValue !== 'hs' && (
          <>
            <div className="main-section">
              <div className="left-section">
                <div id="contentHubspot" style={{ position: 'relative' }}>
                  <div className="export-data-heading">Export</div>
                  <div className="export-icon">
                    <img src={connectIcon} alt="connect" />
                  </div>

                  <div
                    // id="resultView"
                    className="crm-result-view"
                    style={{ display: 'block', marginBottom: 0 }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        type="primary"
                        className="button-crm-export"
                        onClick={handleSubmit}
                      >
                        Connect to HubSpot
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="right-section">
                <AboutText
                  crm_icon={iconHubspot}
                  crm_name="HubSpot"
                  crm_url="hubspot.com"
                  crm_link="https://www.hubspot.com/"
                />
              </div>
            </div>
            {/* <div className="sales-list-section">
            <label className="help-label">Please follow these steps:</label>
            <ol className="salesforce-steps">
              <li>
                Create a HubSpot Developer Account.
              </li>
              <hr className="horizontal-line" />
              <li>
                Set up an App:
                <ol className="salesforce-inner-steps">
                  <li>
                    <div className="inner-item">
                      <img src={image1} className="slider-image png-image" alt="image1" />
                      <div className="sentence-style">
                        Once your account is ready you can now create an App in your hubspot account. After login, will be redirected to the developer home.
                      </div>
                    </div>
                  </li>
                  <hr className="horizontal-line" />
                  <li>
                    <div className="inner-item">
                      <img src={image2} className="slider-image png-image" alt="image2" />
                      <div className="sentence-style">
                        Click {' '}
                        <span className="inner-item-bold">
                          Create an App,
                        </span>
                        In the App form fill in the details.<br />
                        Note: Application name is mandatory.
                      </div>
                    </div>
                  </li>
                  <hr className="horizontal-line" />
                  <li>
                    <div className="inner-item">
                      <img src={image3} className="slider-image png-image" alt="image3" />
                      <div className="sentence-style">
                        Need to mention the redirect url and scope also, <br />
                        <span className="inner-item-bold">
                          Settings -{'>'} Auth <br />
                          Redirect url:{' '}
                        </span>
                        <span style={{ color: 'var(--color-link)' }}>
                          https://{'{slug}'}.aidentified.com/crm/callback <br />
                        </span>
                        Eg: In case of app:{' '}
                        <span style={{ color: 'var(--color-link)' }}>
                          https://app.aidentified.com/crm/callback <br />
                        </span>
                        <span className="inner-item-bold">
                          Scope:{' '}
                        </span>
                        Select {' '}
                        <span className="inner-item-bold">
                          Contacts
                        </span>
                      </div>
                    </div>
                  </li>
                  <hr className="horizontal-line" />
                  <li>
                    <div className="inner-item">
                      <img src={image4} className="slider-image png-image" alt="image4" />
                      <div className="sentence-style">
                        After entering the details, {' '}
                        <span className="inner-item-bold">
                          Save {' '}
                        </span>
                        the changes.
                      </div>
                    </div>
                  </li>
                  <hr className="horizontal-line" />
                  <li>
                    <div className="inner-item">
                      <img src={image5} className="slider-image png-image" alt="image5" />
                      <div className="sentence-style">
                        After saving, a popup with success message will be displayed.  You’ll be given a {' '}
                        <span className="inner-item-bold">
                          Client ID
                        </span>{' '}
                        and a{' '}
                        <span className="inner-item-bold">Client Secret </span>{' '}
                        for your app.<br />
                        <span>
                          To view the credentials:{' '}
                          <span className="inner-item-bold">Settings -{'>'} Auth</span>
                        </span>  <br />
                        *** You will get the credentials after creating an app.
                      </div>
                    </div>
                  </li>
                </ol>
              </li>
              <hr className="horizontal-line" />
              <li>
                Next step is to {' '}
                <span className="inner-item-bold">create a test account.</span>
                <ol className="salesforce-inner-steps">
                  <li>
                    <div className="inner-item">
                      <img src={image6} className="slider-image png-image" alt="image6" />
                      <div className="sentence-style">
                        Click on {' '}
                        <span className="inner-item-bold">Create app test account, {' '}</span>
                        a popup will appear.
                      </div>
                    </div>
                  </li>
                  <hr className="horizontal-line" />
                  <li>
                    <div className="inner-item">
                      <img src={image7} className="slider-image png-image" alt="image7" />
                      <div className="sentence-style">
                        Fill in the details (test account name)
                      </div>
                    </div>
                  </li>
                  <hr className="horizontal-line" />
                  <li>
                    <div className="inner-item">
                      <img src={image8} className="slider-image png-image" alt="image8" />
                      <div className="sentence-style">
                        After creating it will be redirected to the dashboard and display the test account details.<br />
                        ***Note: Your app will not appear as connected app in the settings, unless you complete the register and authentication (Integrate hubspot from our application). You must generate the refresh token and initial access token to have the app appear as connected.
                      </div>
                    </div>
                  </li>
                </ol>
              </li>
              <hr className="horizontal-line" />
              <li>
                Create Custom Fields<br /><br />
                <span className="hubspot-inner-item">
                  Required Custom fields:<br />
                  <span className="hubspot-inner-item-filedname">1.	PHONE EXTRA</span><br />
                  <span className="hubspot-inner-item-filedtype">•	Type : Multi-line Extra</span><br />
                  <span className="hubspot-inner-item-filedname">2.	EMAIL EXTRA</span><br />
                  <span className="hubspot-inner-item-filedtype">•	Type: Multi-line Extra</span><br />
                </span>
                <ol className="salesforce-inner-steps">
                  <li>
                    <div className="inner-item">
                      <img src={image9} className="slider-image png-image" alt="image9" />
                      <div className="sentence-style">
                        After successfully integrating hubspot from application, you will be able to see the test account name. Switch to the test account.
                      </div>
                    </div>
                  </li>
                  <hr className="horizontal-line" />
                  <li>
                    <div className="inner-item">
                      <img src={image10} className="slider-image png-image" alt="image10" />
                      <div className="sentence-style">
                        After switching, will be redirected to the dashboard. Click on the gear icon on the top right section of the header.
                      </div>
                    </div>
                  </li>
                  <hr className="horizontal-line" />
                  <li>
                    <div className="inner-item">
                      <img src={image11} className="slider-image png-image" alt="image11" />
                      <div className="sentence-style">
                        As a result, will be redirected to the settings page, Select {' '}
                        <span className="inner-item-bold">Properties {' '}</span>
                        on the left panel.
                      </div>
                    </div>
                  </li>
                  <hr className="horizontal-line" />
                  <li>
                    <div className="inner-item">
                      <img src={image12} className="slider-image png-image" alt="image12" />
                      <div className="sentence-style">
                        There is an option to create new Property (Custom Field). For that you need to provide{' '}
                        <span className="inner-item-bold">Group {' '}</span>
                        and {' '}
                        <span className="inner-item-bold">Label. </span><br />
                        <span className="inner-item-bold">Group: {' '}</span>
                        Select Contact Information. <br />
                        <span className="inner-item-bold">Label: {' '}</span>
                        Name of the custom field.
                      </div>
                    </div>
                  </li>
                  <hr className="horizontal-line" />
                  <li>
                    <div className="inner-item">
                      <img src={image13} className="slider-image png-image" alt="image13" />
                      <div className="sentence-style">
                        By clicking next, need to enter the type of the field.<br />
                        <span className="inner-item-bold">Type: {' '}</span>
                        Type of field
                      </div>
                    </div>
                  </li>
                </ol>
              </li>
            </ol>
          </div> */}
            <div className="sales-list-section">
              <label className="help-label">Please follow these steps:</label>
              <ol className="salesforce-steps">
                <li>
                  <span className="item-bold">
                    Required Custom fields for Aidentified integration
                  </span>
                  <br />
                  <div className="hubspot-inner-item">
                    <p>
                      These fields must be created by a Hubspot user with
                      permissions to create contact properties, likely a Hubspot
                      Admin. However, they only need to be created once and be
                      made available to all users.
                    </p>
                    <span className="hubspot-inner-item-filedname">
                      1. Phone Extra{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedtype">
                      Field Type : Multi-line text{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedname">
                      2. Email Extra{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedtype">
                      Field Type: Multi-line text{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedname">
                      3. Source{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedtype">
                      Field Type: Single-line text{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedname">
                      4. Latest Individual Event Type{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedtype">
                      Field Type: Single-line text{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedname">
                      5. Latest Individual Event Value{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedtype">
                      Field Type: Single-line text{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedname">
                      6. Latest Individual Event Date{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedtype">
                      Field Type: Date picker{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedname">
                      7. Latest Individual Event Description{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedtype">
                      Field Type: Single-line text{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedname">
                      8. Latest Individual Event URL{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedtype">
                      Field Type: Single-line text{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedname">
                      9. Latest Corporate Event Type{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedtype">
                      Field Type: Single-line text{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedname">
                      10. Latest Corporate Event Value{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedtype">
                      Field Type: Single-line text{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedname">
                      11. Latest Corporate Event Date{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedtype">
                      Field Type: Date picker{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedname">
                      12. Latest Corporate Event Description{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedtype">
                      Field Type: Single-line text{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedname">
                      13. Latest Corporate Event URL{' '}
                    </span>
                    <br />
                    <span className="hubspot-inner-item-filedtype">
                      Field Type: Single-line text{' '}
                    </span>
                    <br />
                  </div>
                  {/* <span>
                  <Player
                    playsInline
                    src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                  />
                </span> */}
                </li>
                <hr className="horizontal-line" />
                <li>
                  <span className="item-bold">Creating a Custom field</span>
                  <ol className="salesforce-inner-steps">
                    <li>
                      <div className="inner-item">
                        <img
                          src={image14}
                          className="slider-image png-image"
                          alt="image14"
                        />
                        <div className="sentence-style">
                          Click on the gear icon in the top right section of the
                          header, followed by{' '}
                          <span className="inner-item-bold">Properties </span>
                          in the left pane, then{' '}
                          <span className="inner-item-bold">
                            Create Property.
                          </span>
                        </div>
                      </div>
                    </li>
                    <hr className="horizontal-line" />
                    <li>
                      <div className="inner-item">
                        <img
                          src={image15}
                          className="slider-image png-image"
                          alt="image15"
                        />
                        <div className="sentence-style">
                          You must create the 3 properties referenced above
                          individually. Below is a sample for creating{' '}
                          <span className="inner-item-bold">Phone Extra.</span>
                          <br />
                          <span className="inner-item-bold">Object Type: </span>
                          Contact
                          <br />
                          <span className="inner-item-bold">Group: </span>
                          Contact Information
                          <br />
                          <span className="inner-item-bold">Label: </span>
                          Phone Extra
                          <br />
                          Click <span className="inner-item-bold">Next.</span>
                        </div>
                      </div>
                    </li>
                    <hr className="horizontal-line" />
                    <li>
                      <div className="inner-item">
                        <img
                          src={image16}
                          className="slider-image png-image"
                          alt="image16"
                        />
                        <div className="sentence-style">
                          <span className="inner-item-bold">Field Type: </span>
                          Multi-line text
                          <br />
                          (check boxes selections are based on your preferences
                          to include new objects in searches and forms)
                          <br />
                          Click <span className="inner-item-bold">Create </span>
                          to complete.
                        </div>
                      </div>
                    </li>
                  </ol>
                </li>
                <hr className="horizontal-line" />
                <li>
                  <span className="item-bold">
                    Above steps must be completed for all 3 new properties
                  </span>
                  <br />
                  <span className="hubspot-inner-item-filedname">
                    1. <span className="item-bold">Email Extra </span>is
                    identical to Phone Extra, except{' '}
                    <span className="item-bold">Label = EMAIL EXTRA </span>{' '}
                  </span>
                  <br />
                  <span className="hubspot-inner-item-filedname">
                    2. <span className="item-bold">Source </span>is slightly
                    different,{' '}
                    <span className="item-bold">Label = SOURCE </span>
                    and{' '}
                    <span className="item-bold">
                      Field Type= Single -line text
                    </span>{' '}
                  </span>
                  <br />
                </li>
              </ol>
            </div>
          </>
        )}
        {crmValue === 'hs' && (
          <div className="main-section">
            <div className="left-section">
              {' '}
              <IntegratedView crm_name="HubSpot" onRemove={removeIntegration} />
            </div>
            <div className="right-section">
              {' '}
              <AboutText
                crm_icon={iconHubspot}
                crm_name="HubSpot"
                crm_url="hubspot.com"
                crm_link="https://www.hubspot.com/"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HubSpotView;
