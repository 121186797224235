import './index.scss';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Select from 'react-select';
import { inject, observer } from 'mobx-react';
import { Button } from 'antd';

import CreditIcon from '../../assets/icons/ai_credit.svg?react';
import ExportPdfImage from '../../assets/icons/ai_pdf_export.svg?react';

import AdvancedSelect from '../AdvancedSelect';
import MultiFilterSelect from '../MultiFilterSelect';
import { splitNumberWithCommas, getSourceName } from '../../utils/general';
import PeopleViewModal from './PeopleViewModal';

const importBoxes = [
  {
    id: 1,
    name: 'linkedin',
  },
  {
    id: 2,
    name: 'icloud',
  },
  {
    id: 3,
    name: 'google',
  },
  {
    id: 4,
    name: 'outlook',
  },
];

const DashboardHeader = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const [selectedCsvImport, setSelectedCsvImport] = useState({
      label: <div>{'None Selected'}</div>,
      value: 'all_selected',
    });
    const [selectedRelationshipPath, setSelectedRelationshipPath] = useState({
      label: 'All',
      value: { name: 'All', id: 'all' },
    });
    const [modalVisibility, setModalVisibility] = useState(false);
    const navigate = useNavigate();
    const {
      showEditView,
      data,
      dataSource,
      csvBoxes,
      showPersonProfile,
      filterData,
      showCompanyProfile,
      dashboardDropdownSelected,
    } = props;

    useEffect(() => {
      if (
        dashboardDropdownSelected &&
        dashboardDropdownSelected.relationship_path &&
        dashboardDropdownSelected.relationship_path.id
      ) {
        setSelectedRelationshipPath({
          label: dashboardDropdownSelected.relationship_path.name,
          value: dashboardDropdownSelected.relationship_path.id,
        });
      } else {
        setSelectedRelationshipPath({
          label: 'All',
          value: { name: 'All', id: 'all' },
        });
      }
      if (
        csvBoxes &&
        Object.keys(csvBoxes).length > 0 &&
        dashboardDropdownSelected &&
        dashboardDropdownSelected.sources &&
        Object.keys(dashboardDropdownSelected.sources).length > 0
      ) {
        const [type, id] = Object.entries(dashboardDropdownSelected.sources)[0];
        const selecteditem = getCsvImportOptions().filter((option) => {
          return (
            option &&
            option.value &&
            type === option.value.type &&
            option.value.id &&
            id.length === option.value.id.length &&
            id.every((x) => option.value.id.includes(x))
          );
        });
        if (selecteditem && selecteditem.length > 0)
          setSelectedCsvImport(selecteditem);
        else
          setSelectedCsvImport({
            label: <div>{'None Selected'}</div>,
            value: 'all_selected',
          });
      } else if (
        !dashboardDropdownSelected.sources ||
        Object.keys(dashboardDropdownSelected.sources).length <= 0
      ) {
        setSelectedCsvImport({
          label: <div>{'None Selected'}</div>,
          value: 'all_selected',
        });
      }
    }, [dashboardDropdownSelected, csvBoxes]);

    const getImportSourceData = (name) => {
      switch (name) {
        case 'linkedin':
          return dataSource.linkedin;
        case 'icloud':
          return dataSource.icloud;
        case 'google':
          return dataSource.google;
        case 'outlook':
          return dataSource.outlook;
        default:
          return {};
      }
    };

    const getOwnerType = () => {
      if (
        !['linkedin', 'icloud', 'google', 'outlook'].includes(
          selectedRelationshipPath.id,
        )
      )
        return selectedRelationshipPath.id;

      return 'first';
    };

    const onCreditLabelClick = () => {
      navigate('/account/credits');
    };

    const onRedirect = (e, source) => {
      e.stopPropagation();
      if (
        source === 'icloud' ||
        source === 'google' ||
        source === 'outlook' ||
        source === 'linkedin'
      ) {
        navigate(`/connect/import`, {
          state: {
            fromSource: getSourceName(source),
          },
        });
      } else if (source === 'connect') {
        navigate(`/connect`);
      }
    };

    const onCountClick = (e, type, source, shared) => {
      e.preventDefault();
      props.dataStore.summaryFilter = {};
      props.dataStore.summaryFilter.ownerTypeId = getOwnerType();
      if (type === 'file_imports') {
        if (shared) {
          props.dataStore.summaryFilter.team_file_imports = data.id;
          props.dataStore.summaryFilter.file_imports = [];
          props.dataStore.summaryFilter.import_source = [];
        } else {
          props.dataStore.summaryFilter.file_imports = data.id;
          props.dataStore.summaryFilter.team_file_imports = [];
          props.dataStore.summaryFilter.import_source = [];
        }
      } else {
        props.dataStore.summaryFilter.import_source = [source.toLowerCase()];
        props.dataStore.summaryFilter.file_imports = [];
        props.dataStore.summaryFilter.team_file_imports = [];
      }
      props.dataStore.summaryFilter.quickSearchRI =
        props.dataStore.relationship_quick_search;
      props.dataStore.summaryFilter.selectedId =
        props.dataStore.networkFilters.selectedIdValue;
      setModalVisibility(true);
    };

    const onRelationshipPathChange = (value) => {
      filterData(value);
    };

    const onCsvImportChange = (selectedData) => {
      const boxItem = {};
      if (selectedData.value && selectedData.value.type === 'file_imports') {
        boxItem.file_imports = selectedData.value.id;
      } else if (
        selectedData.value &&
        selectedData.value.type === 'team_file_imports'
      ) {
        boxItem.team_file_imports = selectedData.value.id;
      }
      const idsArray = [...Array.from(selectedData.value.id)];
      filterData(idsArray, boxItem);
    };

    const getRelationshipPathOptions = () => {
      const options = [
        { label: 'All', value: { name: 'All', id: 'all' } },
        { label: '1st', value: { name: '1st', id: 'first' } },
        { label: '2nd', value: { name: '2nd', id: 'second' } },
        { label: '1st & 2nd', value: { name: '1st & 2nd', id: 'combined' } },
      ];

      {
        importBoxes.map((importBox) => {
          const importBoxData = getImportSourceData(importBox.name);
          let id = [];
          if (importBoxData && importBoxData.id && importBoxData.id.length > 0)
            id = importBoxData.id;
          options.push({
            value: {
              name: importBox.name,
              type: 'import_source',
              id: id,
            },

            label: (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  height: '40px',
                }}
              >
                {importBoxData &&
                importBoxData.id &&
                importBoxData.id.length > 0 &&
                importBoxData.enriched_contacts &&
                importBoxData.enriched_contacts > 0 ? (
                  <div>{getSourceName(importBox.name)}</div>
                ) : (
                  <div
                    className="connect-link"
                    onClick={(e) => onRedirect(e, importBox.name)}
                  >
                    {getSourceName(importBox.name)}
                  </div>
                )}
                {importBoxData &&
                importBoxData.enriched_contacts &&
                importBoxData.enriched_contacts > 0 ? (
                  <div
                    style={{ fontWeight: '700', cursor: 'pointer' }}
                    onClick={(e) =>
                      onCountClick(e, 'import', importBox.name, false)
                    }
                  >
                    {importBoxData.enriched_contacts}
                  </div>
                ) : (
                  <div
                    style={{
                      fontWeight: '700',
                    }}
                    className="connect-link"
                    onClick={(e) => onRedirect(e, importBox.name)}
                  >
                    Import/Export
                  </div>
                )}
              </div>
            ),
          });
        });
      }
      {
        props.dataStore.lookUpLists.teamAccounts.map((team) => {
          if (team.name !== 'Me')
            options.push({
              label: team.name,
              value: { name: team.name, id: team.id },
            });
        });
      }
      return options;
    };

    const getCsvImportOptions = () => {
      const options = [];
      options.push({
        label: <div>{'None Selected'}</div>,
        value: 'all_selected',
      });

      const fileImports = [];
      fileImports.push({
        value: 'CSV imports',
        label: 'CSV imports',
        isDisabled: true,
      });
      const sharedFileImports = [];
      sharedFileImports.push({
        value: 'Shared File Imports',
        label: 'Shared File Imports',
        isDisabled: true,
      });
      if (csvBoxes)
        Object.entries(csvBoxes).map((csvBox) => {
          if (
            csvBox[1].enriched_contacts !== 0 &&
            csvBox[1].shared &&
            !props.dataStore.currentUser.hideSharedCsvFiles
          )
            sharedFileImports.push({
              value: {
                name: csvBox[0],
                type: 'team_file_imports',
                id: csvBox[1].id,
              },
              label: (
                <div>
                  <div className="file-name">{csvBox[0]}</div>
                  <div style={{ fontSize: '11px' }}>
                    by{' '}
                    <span
                      className="connect-link underline"
                      onClick={(e) =>
                        showPersonProfile(
                          e,
                          csvBox[1].owner_b2b_id,
                          csvBox[1].owner_b2c_id,
                        )
                      }
                    >
                      {csvBox[1].owner_name}
                    </span>
                  </div>
                </div>
              ),
            });
          else if (csvBox[1].enriched_contacts !== 0 && !csvBox[1].shared)
            fileImports.push({
              value: {
                name: csvBox[0],
                type: 'file_imports',
                id: csvBox[1].id,
              },
              label: (
                <div>
                  <div className="file-name">{csvBox[0]}</div>
                  <div style={{ fontSize: '11px' }}>
                    by{' '}
                    <span
                      className="connect-link"
                      onClick={(e) =>
                        showPersonProfile(
                          e,
                          csvBox[1].owner_b2b_id,
                          csvBox[1].owner_b2c_id,
                        )
                      }
                    >
                      {csvBox[1].owner_name}
                    </span>
                  </div>
                </div>
              ),
            });
        });
      if (fileImports.length === 1) {
        fileImports.push({
          label:
            selectedRelationshipPath.value === 'all' ? (
              <div className="file-name">
                Please
                <span
                  style={{ color: 'var(--color-link)', cursor: 'pointer' }}
                  onClick={(e) => onRedirect(e, 'connect')}
                >
                  {' '}
                  Import/Export
                </span>{' '}
                and start importing connections.
              </div>
            ) : (
              <div className="file-name">
                Please select &ldquo;All&rdquo; in Relationship Paths filter to
                view CSV files
              </div>
            ),
          value: { name: 'File Imports', type: 'file_imports' },
          // isDisabled: true,
        });
      }
      if (sharedFileImports.length === 1) return [...options, ...fileImports];
      else return [...options, ...fileImports, ...sharedFileImports];
    };

    return (
      <>
        <div className="header-wrapper">
          <div className="heading">
            <div className="credit-section">
              <CreditIcon />
              <label className="credit-label" onClick={onCreditLabelClick}>
                {splitNumberWithCommas(props.dataStore.currentUser.creditCount)}
                {props.dataStore.currentUser.creditCount > 1
                  ? ' Credits'
                  : ' Credit'}
              </label>
            </div>
            {!showEditView && (
              <div className="edit-section">
                <Button
                  id="edit-view"
                  className="edit-label"
                  onClick={props.editView}
                >
                  Edit View
                </Button>
              </div>
            )}
          </div>
          <div className="dropdown">
            <div className="team-class">
              <div
                style={{
                  width: 'max-content',
                  color: 'var(--color-dark-shade)',
                }}
              >
                Relationship Paths :
              </div>
              {/* <Select
                                getPopupContainer={(trigger) => trigger.parentNode}
                                defaultValue={
                                    ownerTypeId || 'Me'
                                }
                                value={ownerTypeId}
                                style={{ width: 180, marginLeft: 5 }}
                                onChange={handleTeamChange}
                            >
                                <Option value={'None'}>None</Option>
                                {props.dataStore.lookUpLists.teamAccounts.map((team) => {
                                    return <Option value={team.id}>{team.name}</Option>;
                            })}

                            </Select> */}

              <Select
                className="insights-ri-paths"
                classNamePrefix="select"
                name="color"
                isSearchable={false}
                options={getRelationshipPathOptions()}
                defaultValue={getRelationshipPathOptions()[0]}
                // defaultValue={dashboardDropdownSelected.relationship_path? {
                //     label: dashboardDropdownSelected.relationship_path.name,
                //     value: dashboardDropdownSelected.relationship_path.id
                // } : {
                //     label: 'All',
                //     value: { name: 'All', id: 'all', }
                // }}
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={selectedRelationshipPath}
                // value={{
                //     label: dashboardDropdownSelected.relationship_path.name,
                //     value: dashboardDropdownSelected.relationship_path.id
                // }
                // }
                isOptionSelected={(option, selected) => {
                  if (option.value && option.value.name)
                    return option.value.name === selected[0].value.name;
                }}
                onChange={(value) => onRelationshipPathChange(value.value)}
              />
            </div>
            <div className="multiple-filter-dropdown">
              <div
                className="labelDropDown"
                style={{
                  width: 'max-content',
                  color: 'var(--color-dark-shade)',
                }}
              >
                Saved Searches :
              </div>
              <div className="ri-multiple-filter">
                <MultiFilterSelect
                  updateAfterSelect={() => props.filterData('fromFilter')}
                  value={dashboardDropdownSelected.filter}
                />
              </div>
            </div>
            <div className="ri-path-dropdown">
              <div
                className="labelDropDown"
                style={{
                  width: 'max-content',
                  marginRight: '15px',
                  color: 'var(--color-dark-shade)',
                  lineHeight: '40px',
                }}
              >
                CSV Imports :
              </div>
              <div>
                <AdvancedSelect
                  selectedValue={selectedCsvImport}
                  options={getCsvImportOptions()}
                  handleOnChange={onCsvImportChange}
                />
              </div>
            </div>
            <div className="pdf-dropdown">
              <div
                className="pdf"
                onClick={() => {
                  window.print();
                }}
              >
                <ExportPdfImage />
                <div style={{ paddingLeft: '10px' }}>PDF</div>
              </div>
            </div>
          </div>
        </div>
        {modalVisibility && (
          <PeopleViewModal
            onCancel={() => setModalVisibility(false)}
            showCompanyProfile={showCompanyProfile}
          />
        )}
      </>
    );
  }),
);

export default DashboardHeader;
