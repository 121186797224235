import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { usePostHog } from 'posthog-js/react';
import { inject, observer } from 'mobx-react';

import { setAuthority } from '../../utils/authority';
import { getDomain } from '../../utils/general';
import { updateUserInfo } from '../../services/api';
import LoaderAnimation from '../../components/AidLoader';

interface DataStore {
  currentUser: {
    ownerId: string;
    userTabOrder: string[];
    userSelectedColumns: string[];
  };
  reset: () => void;
}

interface GlobalDataStore {
  reset: () => void;
  resetMultipleFilters: () => void;
}

interface SignOutProps {
  dataStore: DataStore;
  globalDataStore: GlobalDataStore;
}

const LogOut = (props: SignOutProps) => {
  const { logout } = useAuth0();
  const posthog = usePostHog();
  const { pathname } = useLocation();

  const saveUserSelections = () => {
    if (pathname === '/prospect-finder/people') {
      updateUserInfo(props.dataStore.currentUser.ownerId, {
        prospect_tab_order: props.dataStore.currentUser.userTabOrder,
        prospect_header_order:
          props.dataStore.currentUser.userSelectedColumns.filter(
            (item) =>
              item !== 'relationship_status' && item !== 'relationship_type',
          ),
      });
    }
  };

  const handleSignOut = () => {
    saveUserSelections();
    localStorage.removeItem('tempNetworkFilters');
    localStorage.removeItem('crm_source');
    localStorage.removeItem('redirectionPath');
    localStorage.removeItem('aidentified.pfFinderHelp');
    localStorage.removeItem('aidentified.theme');
    setAuthority('guest');
    props.dataStore.reset();
    props.globalDataStore.reset();
    props.globalDataStore.resetMultipleFilters();
    posthog.reset();
    logout({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      returnTo: `${getDomain()}/user/login`,
    });
  };

  useEffect(() => {
    handleSignOut();
  }, []);

  return (
    <div className="loader-wrapper">
      <LoaderAnimation loaderId="login-loader" />;
    </div>
  );
};

export default inject('globalDataStore', 'dataStore')(observer(LogOut));
