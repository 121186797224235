import './index.css';

import { Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import useDeepEffect from '../../hooks/useDeepEffect';

const InputGroup = Input.Group;
const { Option } = Select;

const PostalCodePicker = inject('dataStore')(
  observer((props) => {
    const [selectedOption, setSelectedOption] = useState('between');
    const [valueOne, setValueOne] = useState(null);
    const [valueTwo, setValueTwo] = useState(null);
    /** https://medium.com/better-programming/how-to-use-the-react-hook-usedeepeffect-815818c0ad9d */
    useDeepEffect(() => {
      const { zipCode } = props;
      if (zipCode && zipCode.key && zipCode.value && zipCode.value.length > 0) {
        setSelectedOption(zipCode.key);
        if (valueOne !== zipCode.value[0]) setValueOne(zipCode.value[0]);
        setValueTwo(
          zipCode.key === 'between' ? zipCode.value[1] : zipCode.value[0],
        );
      } else {
        if (zipCode && zipCode.key && zipCode.key === 'exact') {
          setSelectedOption('exact');
        } else {
          setSelectedOption('between');
        }
        setValueOne(null);
        setValueTwo(null);
      }
    }, [props.zipCode]);

    useEffect(() => {
      if (valueOne && valueTwo) {
        // if (valueOne === '0' && valueTwo === '0' && valueOne.length <= 5 && valueTwo.length <= 5) {
        //   props.dataStore.networkFilters.home_postal_code = null;
        //   setValueOne(null);
        //   setValueTwo(null);
        // }
        if (
          (valueOne === '0' &&
            valueTwo === '0' &&
            selectedOption !== 'exact') ||
          (valueOne === '00000' && valueTwo === '00000')
        ) {
          props.dataStore.networkFilters.home_postal_code = null;
          setValueOne(null);
          setValueTwo(null);
        } else if (selectedOption !== 'exact' || valueOne !== '00000') {
          onRangeSelected();
        }
      } else if (valueOne === '' && valueTwo === '') {
        onRangeSelected();
      }
      // }
    }, [valueOne, valueTwo]);

    // function to make ui changes based on exact and between dropdown choosing
    const onDropDownSelect = (value) => {
      setSelectedOption(value === '1' ? 'between' : 'exact');
      if (valueOne || valueTwo) {
        if (value !== '1') setValueTwo(valueOne);
        else onRangeSelected();
      }
      if (props.onSelect) props.onSelect();
    };

    const onRangeChange = (event) => {
      const { id } = event.target;
      let { value } = event.target;

      if (!value.match(/^\d{0,5}-?(\d{0,4})?$/)) return;

      if (value.length > 5 && !value.includes('-')) {
        value = value.slice(0, 5) + '-' + value.slice(5);
      }

      if (id === 'minRange') {
        setValueOne(value);
      } else if (id === 'maxRange') {
        setValueTwo(value);
      } else if (id === 'exactRange') {
        setValueOne(value);
        setValueTwo(value);
      }
      if (props.onSelect) props.onSelect();
    };

    const onRangeSelected = () => {
      const { onValueSelected } = props;
      if (!valueOne && !valueTwo) {
        onValueSelected(props.id, {});
      } else if (valueOne.length >= 0 && valueTwo.length >= 0) {
        const zipCode = {
          key: selectedOption,
          value: [valueOne, valueTwo],
        };
        onValueSelected(props.id, zipCode);
      }
    };

    return (
      <InputGroup>
        <div className="range-picker-parent-new zip-range">
          <Select
            bordered={false}
            popupClassName="range-picker-dropdown"
            value={selectedOption === 'between' ? '1' : '2'}
            onChange={onDropDownSelect}
          >
            <Option value="1">Between</Option>
            <Option value="2">Exact</Option>
          </Select>
          {selectedOption === 'between' ? (
            <div className="flex range-picker-zip">
              <Input
                type="text"
                maxLength="10"
                id="minRange"
                placeholder="#####-####"
                value={valueOne}
                onChange={onRangeChange}
                style={{
                  textAlign: 'center',
                  width: '46%',
                }}
              />
              <Input
                style={{
                  pointerEvents: 'none',
                  backgroundColor: '#fff',
                  width: '8%',
                  padding: 0,
                  textAlign: 'center',
                  fontSize: '10px',
                }}
                placeholder="~"
                disabled
              />
              <Input
                type="text"
                maxLength="10"
                id="maxRange"
                placeholder="#####-####"
                value={valueTwo}
                onChange={onRangeChange}
                style={{
                  textAlign: 'center',
                  width: '46%',
                }}
                className="last-input"
              />
            </div>
          ) : (
            <div className="range-picker-exact-zip">
              <Input
                type="text"
                maxLength="10"
                id="exactRange"
                placeholder="Zip Code"
                value={valueOne}
                className="last-input"
                onChange={onRangeChange}
              />
            </div>
          )}
        </div>
      </InputGroup>
    );
  }),
);

export default PostalCodePicker;
