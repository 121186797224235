import './index.scss';

import { useEffect, useState, useRef } from 'react';
import { Table, message, Select, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Resizable } from 'react-resizable';
import ReactDragListView from 'react-drag-listview';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { requestWithAuth } from '../../services/api';
import { showConfirm } from '../../utils/popup';
import { handleCrmSync } from '../../utils/crmMethods';
import { splitNumberWithCommas } from '../../utils/general';
import { networkListColumns } from '../../utils/constants';
import ExportComponent from '../ExportComponent';
import SelectClubs from '../SelectClubs';

message.config({
  top: 80,
});

let id1 = null;
let id2 = null;
let sortInfo = null;

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></span>
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: true,
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const ExportTableNew = inject(
  'dataStore',
  'modalDataStore',
)(
  observer(
    ({
      dataCount,
      data = [],
      columns,
      pagination,
      pageSize,
      rowKey,
      rowKeyArray,
      type,
      onChange,
      expandedRowShowing,
      exportAll,
      expandable,
      crmRowCredentials,
      clearCrmCredentials,
      showTableTop = true,
      searchFilter,
      company,
      editable,
      fromProfile,
      refreshData,
      changeSelectedProfileIds,
      exportFromRow,
      clearExport,
      setProgress,
      ...props
    }) => {
      const navigate = useNavigate();
      const flags = useFlags();

      const [selectedRowKeys, setSelectedRowKeys] = useState([]);
      const [n2PersonId, setN2PersonId] = useState([]);
      const [exportConfirmPopUp, setExportConfirmPopUp] = useState(false);
      const [b2bId, setB2bId] = useState([]);
      const [b2cId, setB2cId] = useState([]);
      const [selectedProfileIds, setSelectedProfileIds] = useState([]);
      // const [selectAllRows, setSelectAllRows] = useState(false);
      const [updatedColumns, setUpdatedColumns] = useState(
        editable ? [] : columns,
      );
      const [crmOk, setCrmOk] = useState(false);
      const [clubCode, setClubCode] = useState();
      const [networkColumns, setNetworkColumns] = useState(networkListColumns);
      const { confirm } = Modal;
      const modalRef = useRef(null);
      /** expandedRowKeys is not getting updated as it is not a state variable in the parentComponent
       * so making it observable here
       */
      const [expandedRowKeys, setExpandedRowKeys] = useState(rowKeyArray);
      const [fromRow, setFromRow] = useState(false);

      // useEffect(() => {
      // if new column needs to be added on basis of feature flag - add the codes here
      //   if (
      //     flags.addcolumnFlag
      //   ) {
      //     let newList = networkListColumns;
      //     setNetworkColumns([...newList]);
      //   }
      // }, []);

      useEffect(() => {
        setExpandedRowKeys(rowKeyArray);
      }, [rowKeyArray]);

      useEffect(() => {
        if (editable && columns && columns.length > 0) {
          if (
            !props.dataStore.currentUser.userSelectedColumns ||
            props.dataStore.currentUser.userSelectedColumns.length <= 0 ||
            (props.dataStore.currentUser.userSelectedColumns.length === 2 &&
              props.dataStore.currentUser.userSelectedColumns.includes(
                'relationship_status',
              ) &&
              props.dataStore.currentUser.userSelectedColumns.includes(
                'relationship_type',
              ))
          ) {
            props.dataStore.currentUser.userSelectedColumns = columns.map(
              (col) => col.key,
            );
          }
          const userSelectedColumns = [
            ...props.dataStore.currentUser.userSelectedColumns,
          ];
          if (
            props.dataStore.networkFilters.mapped_relationships_filter
              .mapped_source_filter_name &&
            !userSelectedColumns.includes('relationship_status')
          ) {
            const degreeIndex = userSelectedColumns.indexOf('degree');
            const index =
              degreeIndex > -1 ? degreeIndex : userSelectedColumns.length - 1;
            if (userSelectedColumns.indexOf('relationship_type') == -1)
              userSelectedColumns.splice(index + 1, 0, 'relationship_type');
            if (userSelectedColumns.indexOf('relationship_status') == -1)
              userSelectedColumns.splice(index + 1, 0, 'relationship_status');
          }
          const selected = userSelectedColumns
            .map((key) => {
              const filterVal = columns.find((col) => col.key === key);
              if (filterVal) {
                return filterVal;
              }
              return null;
            })
            .filter((item) => item !== null);

          setUpdatedColumns(selected);
        }
      }, [editable, columns]);

      /** For handling CRM clicked within row */
      useEffect(() => {
        if (crmRowCredentials) {
          id1 = crmRowCredentials.id1 || null;
          id2 = crmRowCredentials.id2 || null;

          onCrmClick(true);
        }
      }, [crmRowCredentials]);

      useEffect(() => {
        if (crmOk) {
          handleCrmOkClick(fromRow, clubCode);
        }
      }, [crmOk]);

      /** Reset selected row informaion on api calls from parent component */
      useEffect(() => {
        setSelectedRowKeys([]);
        setN2PersonId([]);
        setSelectedProfileIds([]);
        if (changeSelectedProfileIds) changeSelectedProfileIds([]);
      }, [props.refreshSelectedRows]);

      const paginationProps = {
        showSizeChanger: true,
        showQuickJumper: true,
        // changed page size options to 50,100 and 200
        pageSizeOptions: ['20', '50', '100', '200'],
        /** by default defaultPageSize is 10,
         * to change that to current size or 200
         */
        defaultPageSize: pageSize,
        // set selected page size of user even after signout
        ...pagination,
      };

      const handleTableChange = (pagination, filters, sorter) => {
        sortInfo = sorter;
        if (onChange) {
          onChange(pagination, filters, sorter);
        }
      };

      const handleRowClick = (data) => {
        if (
          props.modalDataStore.trackModal &&
          props.modalDataStore.trackModal.length > 0
        ) {
          // if modal is open from another modal reset existing
          props.modalDataStore.resetCompanyProfileModal();
          props.modalDataStore.resetProfileModal();
        }
        const { profileModal } = props.modalDataStore;
        profileModal.b2bId = data.b2b_id;
        profileModal.b2cId = data.b2c_id;
        profileModal.mimpersonId = data.mim_person_id || data.id || '';
        profileModal.eventsId = data.events_id || data.mim_event_id || '';
        profileModal.degree = data.degree;
        profileModal.isVisible = true;

        if (
          props.modalDataStore.trackModal &&
          props.modalDataStore.trackModal.length > 0
        ) {
          if (
            props.modalDataStore.trackModal[
              props.modalDataStore.trackModal.length - 1
            ].modal === 'profile'
          ) {
            /* When <ExportTable> is called from profile page */
            profileModal.fetchProfileData = true;
          }
          // if modal is open from another modal add new one to trackModal
          props.modalDataStore.trackModal.push({
            modal: 'profile',
            data: {
              from: 'n2',
              b2bId: data.b2b_id,
              b2cId: data.b2c_id,
            },
          });
        }
      };

      const rowSelection = {
        selectedRowKeys,

        // onSelectAll: (selected, selectedRows, changeRows) => {
        //   if (selected) setSelectAllRows(true);
        //   else setSelectAllRows(false);
        // },
        // onSelect: (record, selected, selectedRows, nativeEvent) => {
        //   if (!selected && selectAllRows) setSelectAllRows(false);
        //   if (selectedRows.length == pageSize) setSelectAllRows(true);
        // },
        onChange: (selectedRowKeys, selectedRows) => {
          const n2Ids = [];
          const b2bIds = [];
          const b2cIds = [];
          const selectedB2bB2c = [];
          // setTimeout(() => {

          // if (selectedRows.length !== pageSize) setSelectAllRows(false);
          // if (
          //   selectedRows.length == pageSize ||
          //   selectedRows.length == dataCount
          // )
          //setSelectAllRows(true);

          selectedRows.map((obj) => {
            if (obj.b2b_id) {
              n2Ids.push(obj.b2b_id);
              b2bIds.push(obj.b2b_id);
            }
            if (obj.b2c_id) {
              n2Ids.push(obj.b2c_id);
              b2cIds.push(obj.b2c_id);
            }
            // if (obj.b2b_id || obj.b2c_id) {
            //   selectedB2bB2c.push(
            //     (({ b2b_id, b2c_id, full_name, tags }) => ({
            //       b2b_id,
            //       b2c_id,
            //       full_name,
            //       tags,
            //     }))(obj),
            //   );
            // }
            if (obj.b2b_id || obj.b2c_id) {
              const newObj = {
                full_name: obj.full_name,
                tags: obj.tags,
              };

              if (obj.b2b_id) {
                newObj.b2b_id = obj.b2b_id;
              }

              if (obj.b2c_id) {
                newObj.b2c_id = obj.b2c_id;
              }

              selectedB2bB2c.push(newObj);
            }
          });
          setSelectedRowKeys(selectedRowKeys);
          setN2PersonId(n2Ids);
          setB2bId(b2bIds);
          setB2cId(b2cIds);
          setSelectedProfileIds(selectedB2bB2c);
          changeSelectedProfileIds(selectedB2bB2c);
          // }, 5);
        },
      };

      const onCrmTokenExpiry = (url) => {
        navigate('/connect/export', {
          state: {
            crmSource: url === 'redtail-auth/0' ? 'rt' : 'cm',
            response: url === 'redtail-auth/0' ? 'rt' : 'cm',
          },
        });
      };

      const fetchUserTypeData = () => {
        requestWithAuth('user-type', 'GET', null, null, null).then(
          (response) => {
            if (response && response.results && response.results[0]) {
              props.dataStore.currentUser.creditCount =
                response.results[0].credits;
            }
          },
        );
      };

      const handleSubscribeRedirect = () => {
        navigate('/account/credits');
      };

      const handleExportCsv = (
        nameOfImport,
        saveLaterHeader,
        exportHeadersList,
        linkedInCB,
        emailCB,
        phoneCB,
      ) => {
        setProgress(true);
        const params = {};
        params.export_name = nameOfImport;
        if (props.dataStore.currentUser.isAdmin) {
          if (linkedInCB) {
            params.profile_refresh = 1;
          } else {
            params.profile_refresh = 0;
          }

          if (emailCB) {
            params.email_validation = 1;
          } else {
            params.email_validation = 0;
          }

          if (phoneCB) {
            if (flags.phoneValidation) params.phone_validation = 1;
          } else {
            params.phone_validation = 0;
          }
        }
        const body = {};
        // if (
        //   flags &&
        //   flags.exportHeaderConfirmation &&
        //   exportHeadersList.indexOf('Degree') > -1 &&
        //   exportHeadersList.indexOf('Relationship Paths') < 0
        // )
        //   exportHeadersList.push('Relationship Paths');

        if (!exportHeadersList.includes('First Name'))
          exportHeadersList.push('First Name');

        if (!exportHeadersList.includes('Last Name'))
          exportHeadersList.push('Last Name');
        exportHeadersList = exportHeadersList.filter(
          (item) =>
            ![
              'selectHouse',
              'selectProfessional',
              'selectTriggers',
              'selectCompany',
              'selectTax',
              'selectAttributes',
            ].includes(item),
        );

        if (
          saveLaterHeader &&
          exportHeadersList &&
          exportHeadersList.length > 0
        ) {
          const body = {
            export_headers: exportHeadersList,
          };
          requestWithAuth('user-export-headers', 'POST', null, body, null);
        }

        if (Array.isArray(n2PersonId) && n2PersonId.length)
          body.n2_person = n2PersonId;
        else if (
          exportFromRow &&
          Array.isArray(exportFromRow) &&
          exportFromRow.length
        )
          body.n2_person = exportFromRow;
        else body.n2_person = [];

        if (exportHeadersList && exportHeadersList.length > 0) {
          body.export_headers = exportHeadersList;

          if (
            props.dataStore.networkFilters.shared_tags &&
            props.dataStore.networkFilters.shared_tags.length > 0
          )
            body.tag_ids = props.dataStore.networkFilters.shared_tags;

          const index = exportHeadersList.indexOf('Household Member');
          if (index > -1) params.household_match = 1;
        }
        requestWithAuth(
          'export-csv',
          'POST',
          params,
          body,
          null,
          'progress_people_list',
        ).then((response) => {
          if (
            response &&
            response.errorCode === 'File with given name already exists'
          ) {
            setExportConfirmPopUp(true);
            message.error(response.errorCode, 10);
            setProgress(false);
            return;
          }
          setSelectedRowKeys([]);
          setN2PersonId([]);
          setB2bId([]);
          setB2cId([]);
          setSelectedProfileIds([]);
          changeSelectedProfileIds([]);
          setProgress(false);

          if (response && response.code === 200) {
            setExportConfirmPopUp(false);
            message.success(
              'Thank you - Your request is being processed. You will receive an email with instructions to download when the file is ready.',
              10,
            );
          }
          if (!props.dataStore.currentUser.isAdmin) fetchUserTypeData();
        });
      };

      const onCrmClick = (isFromRow) => {
        if (!props.dataStore.currentUser.crm) {
          navigate('/connect/export');
          return;
        }

        if (
          props.dataStore.currentUser.crm === 'sf' &&
          props.dataStore.currentUser.org_name === 'INV'
        )
          clubModel(isFromRow, selectedRowKeys);
        else exportPopUp(isFromRow, selectedRowKeys);
      };
      const clubModel = (isFromRow, selectedRowKeys) => {
        modalRef.current = confirm({
          icon: null,
          okButtonProps: {
            disabled: true,
          },

          onOk() {
            exportPopUp(isFromRow, selectedRowKeys);
            modalRef.current.destroy();
          },
          onCancel() {
            onConfirmPopUpCancel();
            setClubCode();
            if (modalRef && modalRef.current) modalRef.current.destroy();
          },
          className: 'club-popUp',
          title: 'Select Club/Location',
          content: (
            <SelectClubs
              setClubVal={(val) => setClubCode(val)}
              modalRef={modalRef}
            />
          ),
        });
      };
      const exportPopUp = (isFromRow) => {
        let totalContactSelected = 1;
        if (selectedRowKeys && selectedRowKeys.length > 0) {
          totalContactSelected = selectedRowKeys.length;
        }
        const additionalCredits =
          totalContactSelected - props.dataStore.currentUser.creditCount;
        const content = (
          <>
            You selected{' '}
            {isFromRow ? 1 : splitNumberWithCommas(selectedRowKeys.length)}
            {isFromRow || selectedRowKeys.length <= 1
              ? ' profile '
              : ' profiles '}{' '}
            to export
            <br />
            {totalContactSelected <= props.dataStore.currentUser.creditCount
              ? 'Please Confirm'
              : `You require an additional ${splitNumberWithCommas(
                  additionalCredits,
                )} ${
                  additionalCredits > 1 ? ' credits ' : ' credit '
                }to proceed`}
          </>
        );
        showConfirm({
          className: 'small-popup center-align',
          title: 'Confirm Export',
          closable: true,
          content: content,
          okText:
            totalContactSelected <= props.dataStore.currentUser.creditCount
              ? 'Yes'
              : 'Buy More',
          onOk: () => {
            if (
              totalContactSelected <= props.dataStore.currentUser.creditCount
            ) {
              setFromRow(isFromRow);
              setCrmOk(true);
            } else handleSubscribeRedirect();
          },
          onCancel: () => {
            onConfirmPopUpCancel();

            return false;
          },
        });
      };

      const handleCrmOkClick = (isFromRow, clubCode) => {
        setProgress(true);

        const body = {};
        const params = {};
        let person = [];

        // if (selectAllRows) {
        //   if (
        //     !props.modalDataStore.profileModal ||
        //     !props.modalDataStore.profileModal.mappedRelationshipsFilter
        //       .filterName
        //   ) {
        //     if (props.dataStore.quick_search)
        //       params.quick_search = props.dataStore.quick_search;
        //     if (props.dataStore.networkFilters.filter_type) {
        //       params.filter_type = props.dataStore.networkFilters.filter_type;
        //     }
        //   }
        //   body = getBodyParams(
        //     props.dataStore.networkFilters,
        //     props.dataStore.lookUpLists,
        //     props.dataStore.superConnectorFilter,
        //     props.dataStore.teamFilters,
        //     props.dataStore.currentUser,
        //     props.dataStore.mappedRelationshipsFilter,
        //     props.modalDataStore.profileModal,
        //     props.dataStore.groupByValues,
        //   );
        // } else {
        if (Array.isArray(n2PersonId) && n2PersonId.length && !isFromRow) {
          /** Click from button with selected rows  */
          person = n2PersonId;
        } else {
          /** Click from Row */

          if (id1) person.push(id1);
          if (id2) person.push(id2);
        }
        body.n2_person = person;
        // }
        if (props.dataStore.currentUser.org_name)
          body.org_name = props.dataStore.currentUser.org_name;

        if (clubCode) body.club_code = clubCode.value;

        // handleCrmSync - is a method in utilis/crmsync.js
        // if any crm is integrated - call the method, else redirect to crm import page.
        // when handleCrmSync returns , it enteres the then part of promise and makes the progress bar invisible,
        // also unchecks the selected checkboxes.
        if (props.dataStore.currentUser.crm) {
          handleCrmSync(
            props.dataStore.currentUser.ownerId,
            props.dataStore.currentUser.crm,
            body,
            onCrmTokenExpiry,
            'progress_people_list',
            props.dataStore.networkFilters,
            params,
          ).then(() => {
            setSelectedRowKeys([]);
            setSelectedProfileIds([]);
            changeSelectedProfileIds([]);
            setN2PersonId([]);
            setFromRow(false);
            setCrmOk(false);
            id1 = null;
            id2 = null;
            clearCrmCredentials();
            if (!props.dataStore.currentUser.isAdmin) fetchUserTypeData();
            setProgress(false);
          });
        } else {
          navigate('/connect/export');
        }
      };

      const onCloseExport = () => {
        setExportConfirmPopUp(false);
        setN2PersonId([]);
        setSelectedRowKeys([]);
        setSelectedProfileIds([]);
        changeSelectedProfileIds([]);
        //setSelectAllRows(false);
        setFromRow(false);
        setCrmOk(false);
        clearExport();
      };

      const onConfirmPopUpCancel = (e) => {
        setSelectedRowKeys([]);
        setN2PersonId([]);
        setB2bId([]);
        setB2cId([]);
        setSelectedProfileIds([]);
        changeSelectedProfileIds([]);
        id1 = null;
        id2 = null;
        clearCrmCredentials();
        setFromRow(false);
        setCrmOk(false);
      };

      const handleDeleteClick = (from) => {
        if (from === 'cancel') {
          setSelectedRowKeys([]);
          setSelectedProfileIds([]);
          changeSelectedProfileIds([]);
          setN2PersonId([]);
          setB2bId([]);
          setB2cId([]);
          return;
        }
        setProgress(true);
        const body = {};
        if (b2bId) body.b2b_ids = b2bId;
        if (b2cId) body.b2c_ids = b2cId;
        requestWithAuth('delete-vault-profiles', 'POST', null, body, null).then(
          (response) => {
            const selectedRowLength = selectedRowKeys.length;
            setSelectedRowKeys([]);
            setSelectedProfileIds([]);
            changeSelectedProfileIds([]);
            setN2PersonId([]);
            setProgress(false);
            if (response && response.code && response.code === 200) {
              // props.dataStore.networkFilters.fetchData = true;
              if (props.deleteData)
                props.deleteData(b2bId, b2cId, selectedRowLength);
              setB2bId([]);
              setB2cId([]);
            }
          },
        );
      };

      const handleResize =
        (index) =>
        (e, { size }) => {
          e.stopPropagation();
          const newColumns = [...updatedColumns];
          if (size.width < 130) {
            newColumns[index] = {
              ...newColumns[index],
              width: 130,
            };
          } else if (size.width > 500) {
            newColumns[index] = {
              ...newColumns[index],
              width: 500,
            };
          } else {
            newColumns[index] = {
              ...newColumns[index],
              width: size.width,
            };
          }
          setUpdatedColumns([...newColumns]);
          // props.dataStore.currentUser.userSelectedColumns = newColumns;
        };

      const updateColumnSelection = () => {
        const selected = props.dataStore.currentUser.userSelectedColumns;
        if (selected && selected.length >= 0) {
          const selectedColumns =
            props.dataStore.currentUser.userSelectedColumns.map((key) => {
              return columns.filter((col) => col.key === key)[0];
            });
          /** Relation ship columns will be in columns only if redirected from mapped connection
           * but it will be always there in userSelectedColumns as it is all time selected
           * So in Case of not redirected, selectedColumns will have undefined values due to this mismatch
           */
          setUpdatedColumns(selectedColumns.filter((col) => !!col));
        }
      };

      const onColumnDeselect = (selected) => {
        if (
          selected === 'relationship_status' ||
          selected === 'relationship_type'
        )
          return;
        if (
          props.dataStore.currentUser.userSelectedColumns.length === 3 &&
          props.dataStore.currentUser.userSelectedColumns.includes(
            'relationship_status',
          ) &&
          props.dataStore.currentUser.userSelectedColumns.includes(
            'relationship_type',
          )
        ) {
          props.dataStore.currentUser.userSelectedColumns = networkColumns.map(
            (item) => item.value,
          );
        } else if (
          props.dataStore.currentUser.userSelectedColumns.length === 1
        ) {
          props.dataStore.currentUser.userSelectedColumns = networkColumns.map(
            (item) => item.value,
          );
        } else {
          const index =
            props.dataStore.currentUser.userSelectedColumns.indexOf(selected);

          if (index > -1)
            props.dataStore.currentUser.userSelectedColumns = [
              ...props.dataStore.currentUser.userSelectedColumns.slice(
                0,
                index,
              ),
              ...props.dataStore.currentUser.userSelectedColumns.slice(
                index + 1,
              ),
            ];
        }

        updateColumnSelection();
      };

      const onColumnSelect = (selected) => {
        let indexStateCity;
        indexStateCity = null;
        /**to set up the initial column order of zip code */
        if (selected === 'home_postal_code') {
          indexStateCity =
            props.dataStore.currentUser.userSelectedColumns.indexOf(
              'location_state',
            ) > -1
              ? props.dataStore.currentUser.userSelectedColumns.indexOf(
                  'location_state',
                )
              : props.dataStore.currentUser.userSelectedColumns.indexOf(
                    'location_city',
                  ) > -1
                ? props.dataStore.currentUser.userSelectedColumns.indexOf(
                    'location_city',
                  )
                : null;

          if (indexStateCity > -1)
            props.dataStore.currentUser.userSelectedColumns = [
              ...props.dataStore.currentUser.userSelectedColumns.slice(
                0,
                indexStateCity + 1,
              ),
              'home_postal_code',
              ...props.dataStore.currentUser.userSelectedColumns.slice(
                indexStateCity + 1,
              ),
            ];
        } else
          props.dataStore.currentUser.userSelectedColumns = [
            ...props.dataStore.currentUser.userSelectedColumns,
            selected,
          ];
        if (selected === 'note' || selected === 'tags')
          props.dataStore.networkFilters.fetchData = true;
        //if (selected === 'relationship_type') fetchLinkData();
        // let userColumns= JSON.parse(JSON.stringify(props.dataStore.currentUser.userSelectedColumns))
        // let degreeIndex=userColumns.indexOf('degree')
        // if((selected==='relationship_type'||selected==='relationship_status')&&degreeIndex>-1)
        //   {

        //     userColumns.splice(degreeIndex+1,0,selected)
        //   }
        //     else
        //         userColumns.push(selected)
        //       // if(userColumns.indexOf('relationship_status')==-1)
        //       //    userColumns.splice(index+1,0,'relationship_status')

        //     props.dataStore.currentUser.userSelectedColumns=userColumns;
        // //props.dataStore.currentUser.userSelectedColumns=['full_name', 'title', 'location_state', 'wealth_estimate', 'org_name', 'inferred_salary', 'location_city', 'personal_emails', 'work_emails', 'degree', 'tags', 'event_date', 'event_type', 'event_description']

        updateColumnSelection();
      };

      const revisedColumns = updatedColumns.map((col, index) => ({
        ...col,
        title: <span className="dragHandler">{col?.title}</span>,
        onHeaderCell: (column) => ({
          width: column.width,
          onResize: handleResize(index),
        }),
      }));

      const dragProps = {
        onDragEnd(fromIndex, toIndex) {
          /** ignore checkbox column at index 0 */
          if (!fromIndex) return;
          const columns = [...updatedColumns];
          const item = columns.splice(fromIndex - 1, 1)[0];
          columns.splice(toIndex - 1, 0, item);
          props.dataStore.currentUser.userSelectedColumns = columns
            .map((col) => col?.key)
            .filter((item) => item !== undefined);
          setUpdatedColumns([...columns]);
        },
        lineClassName: 'drop-line',
        nodeSelector: 'th',
        handleSelector: '.dragHandler',
        ignoreSelector: 'react-resizable-handle',
      };

      const getOptions = () => {
        if (
          props.dataStore.networkFilters.mapped_relationships_filter
            .mapped_source_filter_name
        )
          return networkColumns;

        return networkColumns.filter(
          (item) =>
            item.value !== 'relationship_status' &&
            item.value !== 'relationship_type',
        );
      };

      const getSelectedValue = () => {
        const userColumns = networkColumns.filter((elem) => {
          return updatedColumns.some((ele) => {
            return ele?.key === elem.value;
          });
        });
        /* to make  relweights, relLinks selected in column selector for all cases -find a better way if more such columns comes!*/
        if (
          props.dataStore.networkFilters.mapped_relationships_filter
            .mapped_source_filter_name
        ) {
          if (!updatedColumns.some((ele) => ele?.key === 'relationship_type'))
            userColumns.push({
              label: 'Relationship Type',
              value: 'relationship_type',
            });
          if (!updatedColumns.some((ele) => ele?.key === 'relationship_status'))
            userColumns.push({
              label: 'Relationship Strength',
              value: 'relationship_status',
            });
        }

        return userColumns;
      };

      return (
        <div className="export-table-new">
          {showTableTop && (
            <div
              style={{
                display: 'flex',
                backgroundColor: 'var(--color-white)',
              }}
            >
              {editable && (
                <span id="people-table-column-edit">
                  <Select
                    bordered={false}
                    tagRender={() => {
                      return;
                    }}
                    popupClassName="column-selector-dropdown"
                    className="column-selector"
                    suffixIcon={
                      <EditOutlined
                        id="people-table-column-edit-icon"
                        style={{
                          fontSize: '20px',
                          color: 'var(--color-primary)',
                          // marginTop: '5px'
                        }}
                      />
                    }
                    mode="multiple"
                    onSelect={onColumnSelect}
                    onDeselect={onColumnDeselect}
                    options={getOptions()}
                    value={getSelectedValue()}
                    // getPopupContainer={() => document.getElementById('netwotkList')}
                  />
                </span>
              )}
              <ExportComponent
                dataCount={dataCount}
                rowSelection={rowSelection}
                types={type}
                pagination={paginationProps}
                company={company}
                fromProfile={fromProfile}
                showExportPopUp={exportConfirmPopUp}
                cancelExport={onCloseExport}
                cancelConfirmPopUp={onConfirmPopUpCancel}
                deleteApi={handleDeleteClick}
                handleExportCsv={handleExportCsv}
                // selectAllRows={selectAllRows}
                onCrmClick={onCrmClick}
                selectedProfileIds={selectedProfileIds}
                refreshData={refreshData}
                sortInfo={sortInfo}
                exportFromRow={exportFromRow}
                clearExport={clearExport}
                setProgress={setProgress}
              />
            </div>
          )}
          <ReactDragListView.DragColumn {...dragProps}>
            <Table
              rowKey={rowKey}
              expandable={{
                ...expandable,
                expandIconColumnIndex: -1,
                expandedRowKeys,
                defaultExpandAllRows: true,
                expandedRowClassName: () => 'expanded-row',
              }}
              components={
                editable
                  ? {
                      header: {
                        cell: ResizableTitle,
                      },
                    }
                  : {}
              }
              bordered
              columns={editable ? revisedColumns : columns}
              dataSource={data}
              showSorterTooltip={false}
              pagination={paginationProps}
              onChange={handleTableChange}
              rowSelection={showTableTop ? rowSelection : null}
              rowClassName={'export-table-row'}
              scroll={
                props.scroll || { x: 'max-content', y: 'calc(100vh - 434px)' }
              }
              onRow={(record) => ({
                onClick: () => {
                  handleRowClick(record);
                },
              })}
            />
          </ReactDragListView.DragColumn>
        </div>
      );
    },
  ),
);

export default ExportTableNew;
