import './index.scss';

import { Button, message } from 'antd';
import { useEffect, useState } from 'react';

import axios from 'axios';
import MyProfile from '../../assets/icons/ai_profile.svg?react';
import OutlookIcon from '../../assets/icons/ai_outlook.svg';
import { requestWithAuth } from '../../services/api';
import { setCookie } from '../../utils/cookies';
import image1 from '../../assets/images/ai_outlook_img_01.jpg';
import image2 from '../../assets/images/ai_outlook_img_02.jpg';
import image3 from '../../assets/images/ai_outlook_img_03.jpg';
import LoaderAnimation from '../AidLoader';
import MoreInfoSection from './moreInfoSection';

message.config({
  top: 80,
});
let source;
const column_headers = [
  'first_name',
  'last_name',
  'email_1 (each in separate column)',
  'phone_1 (each in separate column)',
  'school (must map to our school names)',
  'linkedin_url',
  'twitter_url',
  'facebook_url',
  'home_street_address',
  'home_city',
  'home_state',
  'home_zip',
  'title',
  'company_name',
  'company_url',
  'company_street_address',
  'company_city',
  'company_state',
  'company_zip',
];

const OutlookContactsImport = () => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const { CancelToken } = axios;
    source = CancelToken.source();
    return () => {
      message.destroy();
      if (source) source.cancel('Operation canceled by the user.');
    };
  }, []);

  const validationSubmit = () => {
    setIsLoading(true);
    requestWithAuth('outlook-auth', 'POST', null, null, source.token).then(
      (response) => {
        if (response) {
          if (response.code === 200) {
            if (response.auth_url && response.auth_url !== '') {
              localStorage.setItem('import_source', 'outlook');
              const hostArray = window.location.host.split('.');
              if (hostArray && hostArray.length > 0 && hostArray[0]) {
                setCookie('slug', hostArray[0], 1);
              }
              window.open(response.auth_url, '_self');
            } else {
              message.error('Please try again', 5);
            }
          } else {
            message.error(response.message, 10);
            setIsLoading(false);
          }
        } else {
          message.error('Please try again', 5);
          setIsLoading(false);
        }
      },
    );
  };

  return (
    <div className="loader-wrapper">
      {isLoading && <LoaderAnimation loaderId="outlook-import-loader" />}
      <div
        className={
          isLoading
            ? 'outlook-contacts aid-div-disable'
            : 'outlook-contacts aid-div-enable'
        }
      >
        <div className="inner-container">
          <div>
            <label className="connect-to">Import</label>
            <div className="profile-icon">
              <div style={{ padding: 20 }}>
                <MyProfile />
              </div>
              <Button
                type="primary"
                className="import-button"
                onClick={validationSubmit}
              >
                Import Contacts
              </Button>
            </div>
          </div>
          <div>
            <div className="outlook-section">
              <img className="outlook-icon" src={OutlookIcon} alt="outlook" />
              <label className="outlook-label">Outlook.com Contacts</label>
            </div>
            <div style={{ padding: '20px 0' }}>
              <p className="paragraph-data">
                When you enter your Outlook credentials, you are granting
                Aidentified access to your contacts. Contacts are imported into
                the Aidentified platform and matched with our profiles. Through
                this matching, additional household and professional attributes
                are provided as well as relationship paths to prospects. We do
                not sync information back to Outlook and we do not store your
                credentials.{' '}
              </p>
            </div>
          </div>
        </div>

        <>
          <div style={{ marginTop: '20px' }}>
            <label className="help-label">Integrating to Outlook.com</label>
            <ol className="outlook-steps">
              <li>
                <div className="each-step">
                  <div>
                    To import your Outlook contacts directly into Aidentified,
                    click
                    <b> “Outlook.com” </b> under the
                    <b> Import/Export </b>section of Aidentified. After clicking
                    the blue <b> &ldquo;Import Contacts&rdquo; </b> button,
                    you&apos;ll sign into the Microsoft account.
                  </div>
                  <div>
                    <img src={image1} className="slider-image" alt="image1" />
                  </div>
                </div>
              </li>
              <hr className="horizontal-line" />
              <li>
                <div className="each-step">
                  <div>
                    After seeing a “Processing” screen for a few seconds, your
                    contacts should be imported.
                  </div>
                  <div>
                    <img
                      src={image2}
                      style={{ height: 'unset' }}
                      className="slider-image"
                      alt="image2"
                    />
                  </div>
                </div>
              </li>
            </ol>
          </div>
          <div className="export-outlook">
            <label className="help-label">Exporting Outlook to CSV</label>
            <div className="export-paragraph">
              You can import your Outlook contacts indirectly as well by
              exporting them from Outlook in the form of a CSV file, then
              importing that CSV into Aidentified.
            </div>
            <ol className="outlook-steps">
              <li>
                <div className="export-text">
                  When logged into Outlook, click on the
                  <b>“contacts” icon</b> on the left hand side, then click
                  <b>“Manage”</b> and <b>“Export contacts”</b>on the top right.
                </div>
                <div className="image-container">
                  <img src={image3} className="slider-image" alt="image3" />
                </div>
              </li>
              <hr className="horizontal-line" />
              <li>
                <div className="export-text">
                  After exporting and saving the file from Outlook, go to the{' '}
                  <b> &ldquo;Import/Export&rdquo; tab </b> in Aidentified and
                  click <b> &ldquo;CSV&rdquo; </b> under import. Scroll below to
                  see the field mapping to follow, and rename your column
                  headers to match the following where applicable.
                </div>
                <div className="csv-header-section">
                  <label className="format-section">
                    Recommended CSV column header format for import
                  </label>
                  <br />
                  <label className="format-section1">
                    To make sure your file is compliant with our app
                    <span style={{ fontWeight: 'bold' }}>
                      {' '}
                      edit your CSV column headers
                    </span>{' '}
                    to match:
                  </label>
                  <div className="list-div">
                    <div>
                      <ul>
                        {column_headers.map((header, index) => {
                          return (
                            <>
                              {index < 9 && (
                                <>
                                  <li className="list-item">{header}</li>
                                </>
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </div>
                    <div>
                      <ul>
                        {column_headers.map((header, index) => {
                          return (
                            <>
                              {index >= 9 && (
                                <>
                                  <li className="list-item">{header}</li>
                                </>
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <hr className="horizontal-line" />
              <li>
                <div className="export-text">
                  Click the blue <b>“Start import button”</b>. Make sure the
                  Aidentified fields match the Imported fields according to the
                  recommended header formats (see image above). Click the blue
                  <b> “Done” button </b> after matching fields and your contacts
                  will start importing.
                </div>
              </li>
              <hr className="horizontal-line" />
              <li>
                <div className="export-text">
                  Once the file is ready and saved as a CSV. Click in the golden
                  circle and select your file. Name your import and select the
                  box
                  <b>
                    {' '}
                    “Add imported contacts as 1st degree connections and
                    enrich”{' '}
                  </b>
                  if you’d like to have these contacts be imported as 1st degree
                  connections. You also have the option to display these 1st
                  degree connections to other teammates here as well.
                </div>
              </li>
            </ol>
          </div>
          <div style={{ marginTop: '20px' }} className="outlook-more-info">
            <MoreInfoSection />
          </div>
        </>
      </div>
    </div>
  );
};

export default OutlookContactsImport;
