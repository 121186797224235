import { useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'antd';
import axios from 'axios';
import Select from 'react-select';
import './index.scss';
import { requestWithAuth } from '../../services/api';

const SelectClubs = (props) => {
  const {
    // onOk,
    // onCancel,
    // // Include other props you need for the confirm dialog
    // className,
    // closable,
    // title,
    // content,
    setClubVal,
    modalRef,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let source;

  useEffect(() => {
    fetchOptions('');
  }, []);

  const handleChange = (selectedOption) => {
    // Handle selected option
    if (selectedOption)
      modalRef.current.update({
        okButtonProps: { disabled: false },
      });
    else
      modalRef.current.update({
        okButtonProps: { disabled: true },
      });

    setInputValue(selectedOption);
    setClubVal(selectedOption);
  };
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleInputChange = debounce((newValue) => {
    fetchOptions(newValue);
  }, 500);

  const fetchOptions = (input) => {
    const option = [];

    setIsLoading(true);
    // if (input.length > 0) {
    if (source) source.cancel('Operation canceled by the user.');
    const { CancelToken } = axios;
    source = CancelToken.source();
    const params = {};
    params.key = 'invited_clubs';
    params.search_key = input;
    requestWithAuth('field-lookup', 'GET', params, null, source.token).then(
      (response) => {
        if (response && response.result) {
          response.result.map((data) => {
            option.push({
              value: data.club_code,
              label: data.club_name
                ? data.club_name.toString().toTitleCase()
                : '',
            });
          });

          setOptions(option);
          setIsLoading(false);
          //return response.result;
        }
      },
    );
    // }
  };
  return (
    <div style={{ textAlign: 'center' }}>
      <Select
        className="club-select"
        value={inputValue}
        isClearable
        //isDisabled={isLoading}
        //isLoading={isLoading}
        onChange={handleChange}
        isSearchable
        closeMenuOnSelect
        cropWithEllipsis
        placeholder="Type to search..."
        options={options}
        onInputChange={handleInputChange}
        onBlur={() => {
          setOptions([]);
        }}
      />
    </div>
  );
};
export default SelectClubs;
