import { useState } from 'react';
import { Radio, Table, Card, Empty } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { inject, observer } from 'mobx-react';

import CardsIcon from '../../assets/icons/ai_cards.svg?react';
import ListIcon from '../../assets/icons/ai_list.svg?react';

import filterStates from '../../utils/Statelist';
import {
  formatValue,
  formatEventName,
  formatEventSource,
  formatEventDescription,
  formatInvestor,
} from '../../utils/general';

const EventsCard = (props) => {
  const item = props.listItem;

  const title = (
    <>
      <div>{item.event_type && formatEventName(item.event_type)}</div>
      {showSearch(item)}
    </>
  );
  return (
    <Card
      style={{
        height: '185px',
        width: '387px',
        position: 'relative',
        borderRadius: '6px',
      }}
      size="small"
      title={title}
      onClick={() => onCardClick(item)}
    >
      <div className="description-text">
        {item.event_description && (
          <>
            {formatEventDescription(item.event_description, item.event_type)}
            {!item.event_type.includes('property sale') &&
              !item.event_type.includes('property listing') && (
                <>
                  {item.event_type === 'news'
                    ? item.event_domain
                      ? ` (Source: ${item.event_domain})`
                      : ''
                    : item.event_source || item.event_data_source_name
                      ? ` (Source: ${formatEventSource(
                          item.event_source || item.event_data_source_name,
                        )})`
                      : ''}
                </>
              )}
          </>
        )}
        {item.event_type === 'news' && item.event_context && (
          <div dangerouslySetInnerHTML={{ __html: item.event_context }} />
        )}
        <div>
          {item.event_funding_round && (
            <>
              {' '}
              <span style={{ color: 'var(--color-grey-dark)' }}>
                Investment Round{': '}
              </span>
              {item.event_funding_round}
            </>
          )}
        </div>
        <div>
          {item.event_investor_name && (
            <>
              {' '}
              <span style={{ color: 'var(--color-grey-dark)' }}>
                Investors{': '}
              </span>
              {formatInvestor(item.event_investor_name)}
            </>
          )}
        </div>
      </div>
      <div className="card-bottom">
        {item.event_monetary_value !== undefined &&
          item.event_monetary_value !== null &&
          item.event_type !== 'news' && (
            <div
              style={{
                fontSize: '21px',
                color: 'var(--color-dark)',
                paddingRight: '9px',
              }}
            >
              {formatValue(item.event_monetary_value)}
            </div>
          )}
        {item.event_date && (
          <div style={{ color: 'var(--color-column)', paddingTop: '9px' }}>
            {moment(item.event_date).format('MM/DD/YYYY')}
            {item.event_state && item.event_type !== 'news' && (
              <>, {item.event_state}</>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};

const showSearch = (item, displayType) => {
  /** Show search icon for non-insider or insider with filing url
   * show link for news with filing url
   * Don't show icon or link for Company/title change
   */
  if (
    !item.event_type ||
    item.event_type === 'company change' ||
    item.event_type === 'job title change' ||
    item.event_type === 'property sale' ||
    item.event_type === 'property purchase' ||
    item.event_type === 'property listing'
  )
    return <></>;

  if (item.event_type && item.event_type === 'news' && item.event_filing_url) {
    return (
      <div
        style={{
          textAlign: displayType === 'list' ? 'left' : 'right',
          width: '100%',
          fontWeight: 400,
        }}
      >
        <a href={item.event_filing_url} target="_blank" rel="noreferrer">
          View entire article
        </a>
      </div>
    );
  }

  if (
    item.event_type &&
    (!item.event_type.includes('insider') || item.event_filing_url)
  ) {
    return (
      <SearchOutlined
        className={displayType === 'list' ? 'search-icon' : 'search-icon-card'}
      />
    );
  }
};

const onCardClick = (item) => {
  if (
    !item.event_type ||
    item.event_type === 'company change' ||
    item.event_type === 'job title change' ||
    item.event_type === 'news' ||
    item.event_type === 'property sale' ||
    item.event_type === 'property purchase' ||
    item.event_type === 'property listing'
  )
    return;

  if (
    item.event_filing_url &&
    (item.event_type.includes('insider') ||
      item.event_type === 'investment received')
  ) {
    window.open(item.event_filing_url);
  } else if (!item.event_type.includes('insider')) {
    const searchValue = `${item.event_org_name} , ${item.event_type}`;
    window.open(`https://www.google.com/search?q=${searchValue}`);
  }
};

const Triggers = inject('dataStore')(
  observer((props) => {
    const [displayType, setDisplayType] = useState('cards');
    //   const [showMore, setShowMore] = useState(false);
    const [tableList, setTableList] = useState(props.list || []);
    const rowKeyArray = [];

    const columns = [
      {
        title: 'Trigger',
        dataIndex: 'event_type',
        key: 'event_type',
        width: 140,

        render: (val, rec) => {
          if (val === undefined || val === null) return '';

          /* Setting id as unique key array */
          rowKeyArray.push(rec.event_id);

          return (
            <div
              className="event-type-header"
              style={{ display: val === 'news' ? 'block' : 'flex' }}
              onClick={() => onCardClick(rec)}
            >
              {formatEventName(val)}
              {showSearch(rec, displayType)}
            </div>
          );
        },

        filters: props.dataStore.lookUpLists.eventList.slice(1), // exclude All from eventList
        filterMultiple: true,
        onFilter: (value, record) => record.event_type === value,
        sorter: (a, b) => {
          a = a.event_type || '';
          b = b.event_type || '';
          return a.localeCompare(b);
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        width: 200,
        dataIndex: 'event_description',
        className: 'column-right',
        key: 'event_description',
        render: (val, rec) => {
          if (rec.event_id === undefined || rec.event_id === null) return '';
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-profile-labels)',
              }}
            >
              {formatEventDescription(val, rec.event_type)}
              {rec.event_type === 'news' && rec.event_context && (
                <div>{rec.event_context}</div>
              )}
              {/* {rec.event_source &&
                ` (Source: ${formatEventSource(rec.event_source)})`} */}
            </div>
          );
        },
      },
      {
        title: 'Source',
        width: 120,
        dataIndex: 'event_source',
        // className: 'column-right',
        // key: 'event_description',
        render: (val, rec) => {
          if (rec.event_id === undefined || rec.event_id === null || !val)
            return '';
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-profile-labels)',
              }}
            >
              {rec.event_type === 'news'
                ? rec.event_domain || ''
                : val
                  ? formatEventSource(val)
                  : ''}
            </div>
          );
        },
      },
      {
        title: 'Investment Round',
        width: 150,
        dataIndex: 'event_funding_round',
        // className: 'column-right',
        // key: 'event_description',
        render: (val, rec) => {
          if (!val) return '';
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-profile-labels)',
              }}
            >
              {val}
            </div>
          );
        },
      },
      {
        title: 'Value',
        width: 130,
        dataIndex: 'event_monetary_value',
        className: 'column-right',
        key: 'event_monetary_value',
        sorter: (a, b) => a.event_monetary_value - b.event_monetary_value,
        sortDirections: ['descend', 'ascend'],
        render: (val, rec) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-dark)',
              }}
            >
              {val === null || rec.event_type === 'news'
                ? ''
                : formatValue(val)}
            </div>
          );
        },
      },

      {
        title: 'Date',
        width: 110,
        dataIndex: 'event_date',
        key: 'event_date',
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-dark)',
              }}
            >
              {moment(val).format('MM/DD/YYYY')}
            </div>
          );
        },
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
          a = a.event_date || '';
          b = b.event_date || '';
          return a.localeCompare(b);
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Trigger State',
        width: 150,
        dataIndex: 'event_state',
        key: 'event_state',
        sortDirections: ['ascend', 'descend'],
        onFilter: (value, record) =>
          value && record.event_state
            ? record.event_state.indexOf(value) === 0
            : true,
        sorter: (a, b) => {
          a = a.event_state || '';
          b = b.event_state || '';
          return a.localeCompare(b);
        },
        render: (val, rec) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-dark)',
              }}
            >
              {rec.event_type === 'news' ? '' : val}
            </div>
          );
        },
        filters: filterStates,
      },
    ];
    const cardColumns = [
      {
        title: <div style={{ paddingLeft: 20 }}>{'Sort by:  Trigger'}</div>,
        dataIndex: 'event_type',
        key: 'event_type',
        width: 190,
        filters: props.dataStore.lookUpLists.eventList.slice(1), // exclude All from eventList
        filterMultiple: true,
        onFilter: (value, record) => record.event_type === value,
        sorter: (a, b) => {
          a = a.event_type || '';
          b = b.event_type || '';
          return a.localeCompare(b);
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Value',
        dataIndex: 'event_monetary_value',
        className: 'column-right',
        width: 100,
        key: 'event_monetary_value',
        sorter: (a, b) => a.event_monetary_value - b.event_monetary_value,
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Date',
        dataIndex: 'event_date',
        key: 'event_date',
        width: 120,
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
          a = a.event_date || '';
          b = b.event_date || '';
          return a.localeCompare(b);
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Trigger State',
        width: 150,
        dataIndex: 'event_state',
        key: 'event_state',
        sortDirections: ['ascend', 'descend'],
        onFilter: (value, record) =>
          value && record.event_state
            ? record.event_state.indexOf(value) === 0
            : true,
        sorter: (a, b) => {
          a = a.event_state || '';
          b = b.event_state || '';
          return a.localeCompare(b);
        },
        filters: filterStates,
      },
    ];

    const displayArray = [
      {
        label: 'Cards',
        value: 'cards',
        icon: <CardsIcon className="toggle-icon" />,
      },
      {
        label: 'List',
        value: 'list',
        icon: <ListIcon className="toggle-icon" />,
      },
    ];

    return (
      <div className="triggers-section">
        <div className="triggers-header profile-box-header">
          <div className="profile-titles">
            Triggers {tableList.length > 0 && <>({tableList.length})</>}
          </div>
          <div className="display-buttons">
            <Radio.Group
              onChange={(e) => {
                setDisplayType(e.target.value);
              }}
              value={displayType}
            >
              {displayArray.map((display) => {
                return (
                  <Radio.Button value={display.value}>
                    {display.icon} {display.label}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </div>
        </div>
        <div>
          {displayType === 'list' ? (
            <Table
              rowKey={(obj) => obj.event_id}
              // style={{ marginTop: '20px' }}
              dataSource={props.list}
              columns={columns}
              pagination={false}
              scroll={{ x: 'max-content', y: 350 }}
              summary={(currentData) => {
                if (JSON.stringify(tableList) !== JSON.stringify(currentData))
                  setTableList(currentData);
              }}
              showSorterTooltip={false}
            />
          ) : (
            <div className="event-cards-section">
              <Table
                rowKey={(obj) => obj.event_id}
                // style={{ marginTop: '20px' }}
                dataSource={props.list}
                columns={cardColumns}
                pagination={false}
                scroll={{ x: 'max-content', y: 350 }}
                showSorterTooltip={false}
                summary={(currentData) => {
                  if (JSON.stringify(tableList) !== JSON.stringify(currentData))
                    setTableList(currentData);
                }}
              />

              {
                // showMore?
                tableList && tableList.length > 0 ? (
                  <div className="events-card-body">
                    {tableList.map((listItem) => {
                      return <EventsCard listItem={listItem} />;
                    })}
                  </div>
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
                //   : tableList.slice(0, 3).map((listItem) => {
                //       return <EventsCard listItem={listItem} />;
                //     })
              }

              {/* <Table
              rowKey={(obj) => obj.event_id}
              style={{ marginTop: '20px' }}
              dataSource={list}
              columns={columns}
              pagination={false}
              scroll={{ x: 'max-content', y: 350 }}
            /> */}
            </div>
          )}
        </div>
        {/* {tableList.length > 3 && (
        <>
          {showMore ? (
            <div
              className="add-more-section"
              onClick={() => {
                setShowMore(false);
              }}
            >
              {' '}
              Collapse Content
            </div>
          ) : (
            <div
              className="add-more-section"
              onClick={() => {
                setShowMore(true);
              }}
            >
              Show {tableList.length - 3} more
            </div>
          )}
        </>
      )} */}
      </div>
    );
  }),
);

export default Triggers;
