import './index.scss';

import { Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';

import BuildingIcon from '../../assets/icons/ico_neighbourhood.svg?react';
import InfoIcon from '../../assets/icons/info.svg?react';

import { formatValue } from '../../utils/general';
import { IrsItems } from '../../utils/constants';

function TaxProfileItem({ label, value }) {
  if (!value) return <></>;

  return (
    <div className="tax-profile-item">
      <div className="tax-profile-value"> {formatValue(value)}</div>
      <div className="tax-profile-label">{label}</div>
    </div>
  );
}
const TaxProfile = inject('modalDataStore')(
  observer((props) => {
    const {
      home_postal_code,
      home_city,
      home_state,
      home_zip4,
      geo_tax_wages_salaries_tips_etc,
      geo_tax_household_income,
      geo_tax_wealth_income,
      geo_tax_retirement_income,
      geo_tax_business_income_or_loss,
      geo_tax_schedule_e_income,
      geo_tax_non_wage_income,
      geo_tax_disposable_income,
      geo_tax_investable_assets,
      geo_tax_capital_gain_or_loss,
      geo_tax_ira_deduction,
      geo_tax_total_gifts_to_charity,
      geo_tax_real_estate_taxes,
      geo_tax_home_mortgage_interest_and_points_reported_to_you,
    } = props.person;

    if (
      !geo_tax_wages_salaries_tips_etc &&
      !geo_tax_household_income &&
      !geo_tax_wealth_income &&
      !geo_tax_retirement_income &&
      !geo_tax_business_income_or_loss &&
      !geo_tax_schedule_e_income &&
      !geo_tax_non_wage_income &&
      !geo_tax_disposable_income &&
      !geo_tax_investable_assets &&
      !geo_tax_capital_gain_or_loss &&
      !geo_tax_ira_deduction &&
      !geo_tax_total_gifts_to_charity &&
      !geo_tax_real_estate_taxes &&
      !geo_tax_home_mortgage_interest_and_points_reported_to_you
    )
      return <></>;

    return (
      <div className="tax-profile">
        <div className="tax-profile-left">
          <div className="profile-titles">
            Tax Profile
            <Tooltip
              placement="top"
              color="var(--color-primary)"
              title="This section utilizes data collected from individual tax filings consolidated at a Zip+4 level.  Zip+4 usually comprises ten to twenty homes or locations.  If a profile does not have a Zip+4 identifier, Aidentified provides a summary at the ZIP level."
            >
              <InfoIcon className="info-icon" />
            </Tooltip>
          </div>
          <div className="location">
            {home_postal_code && (
              <div>
                {home_postal_code}
                {home_zip4 && <span> -{' ' + home_zip4}</span>}
              </div>
            )}
            {(home_city || home_state) && (
              <div>
                {home_city ? home_city.toTitleCase() : ''}
                {home_city && home_state ? ', ' : ''}
                {home_state || ''}
              </div>
            )}
            {home_postal_code && (
              <div
                className="link"
                onClick={() =>
                  props.handleViewAllClick(home_postal_code, home_zip4)
                }
              >
                View All People
              </div>
            )}
            <BuildingIcon />
          </div>
          <div
            style={{
              color: 'var(--color-dark)',
              display: 'flex',
              alignItems: 'center',
              marginTop: '5px',
            }}
          >
            Definitions
            <InfoIcon
              className="info-icon"
              onClick={() => {
                props.modalDataStore.taxDescriptionModalVisible = true;
              }}
            />
          </div>
        </div>

        <div className="grid-container">
          {IrsItems.map(({ label, value }) => {
            return <TaxProfileItem label={label} value={props.person[value]} />;
          })}
        </div>
      </div>
    );
  }),
);

export default TaxProfile;
