import './index.scss';
import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Tree, Tag, Checkbox, Tooltip } from 'antd';

import PlusIcon from '../../assets/icons/ai_plus.svg?react';
import FolderIcon from '../../assets/icons/ai_folder_plus.svg?react';
import SuppressFolderIcon from '../../assets/icons/ai_folder_suppressed_plus.svg?react';
import OpenFolderIcon from '../../assets/icons/ai_folder_minus.svg?react';
import OpenSuppressFolderIcon from '../../assets/icons/ai_folder_suppressed_minus.svg?react';
import IconClose from '../../assets/icons/ai_ic_close.svg?react';
import EditIcon from '../../assets/icons/ai_edit.svg?react';
import DownArrow from '../../assets/icons/ai_ic_down.svg?react';
import SuppressedFolderPNGIcon from '../../assets/icons/ai_icn-folder-plus.png';
import SuppressedPNGIcon from '../../assets/icons/ai_ic_suppress.png';
import CreateTagElement from './CreateTagElement';

const FolderView = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    //TODO: Need to refactor so that we're not reassigning props within the component
    let {
      // eslint-disable-next-line prefer-const
      onEditClick,
      checkedTags,
      // eslint-disable-next-line prefer-const
      handleTagsCheck,
      // eslint-disable-next-line prefer-const
      folderTitle = null,
      // eslint-disable-next-line prefer-const
      fetchTagFolders,
      // eslint-disable-next-line prefer-const
      isProfileModule,
    } = props;
    const [folderData, setFolderData] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [expandPanel, setExpandPanel] = useState(false);

    useEffect(() => {
      if (props.folders && props.folders.length > 0) {
        const transformedData = transformData(props.folders);
        setFolderData(transformedData);
      }
    }, [props.folders, checkedTags]);

    useEffect(() => {
      if (folderData && folderData.length > 0) {
        // if (checkedTags && checkedTags.length > 0) { /**issue when unselect a folder hence commenting this check */
        setSelectedKeys(getFilteredLeafNodeKeys(folderData, checkedTags));
        // }
      }
    }, [checkedTags, folderData]);

    const togglePanel = () => {
      setExpandPanel(!expandPanel);
    };

    const getFilteredLeafNodeKeys = (data, tagIds) => {
      const keys = [];

      const filterChildren = (children) => {
        children.forEach((child) => {
          if (child.children) {
            filterChildren(child.children);
          }
          if (tagIds && tagIds.includes(child.tagId)) {
            keys.push(child.key);
          }
        });
      };

      filterChildren(data);
      return keys;
    };

    const generateUniqueKey = (parentKey, index) => {
      return parentKey ? `${parentKey}-${index}` : `${index}`;
    };

    const hasAllMembersSelected = (memebers) => {
      const tagIds = memebers.map((item) => item.tag.id);
      return tagIds.every((id) => checkedTags?.includes(id));
    };

    const onCheckAll = (isChecked, children) => {
      const tagIds = children.map((item) => item.tag.id);
      if (isChecked) {
        const selectedTags = [...new Set([...checkedTags, ...tagIds])];
        checkedTags = [...selectedTags];
      } else {
        checkedTags = checkedTags.filter((item) => !tagIds.includes(item));
      }
      handleTagsCheck(checkedTags);
    };

    const renderTagcontent = (tag, key) => {
      return (
        <span className="flex">
          {tag.tag}
          {checkedTags?.includes(tag.id) ? (
            <IconClose style={{ color: '#3C3C3C', height: '10px' }} />
          ) : (
            <PlusIcon style={{ color: '#3C3C3C', height: '10px' }} />
          )}
        </span>
      );
    };

    const transformData = (data) => {
      return data.map((folder, folderIndex) => {
        const folderKey = generateUniqueKey('0', folderIndex);

        return {
          title: (
            <div onClick={() => onEditClick(folder.id)}>
              {folder.tag_folder_name}
            </div>
          ),
          folderId: folder.id,
          key: folderKey,
          selectable: false,
          isLeaf: false,
          is_suppression_tag: folder.is_suppression_tag,
          children: folder.tag_folder_members.map((member, memberIndex) => {
            const memberKey = generateUniqueKey(folderKey, memberIndex);
            return {
              title: () => (
                <>
                  {folder.tag_folder_members &&
                    folder.tag_folder_members.length > 0 &&
                    memberIndex === 0 && (
                      <Checkbox
                        checked={hasAllMembersSelected(
                          folder.tag_folder_members,
                        )}
                        onChange={(e) =>
                          onCheckAll(
                            e.target.checked,
                            folder.tag_folder_members,
                          )
                        }
                      ></Checkbox>
                    )}
                  <span>
                    <Tooltip
                      title={
                        member.tag.tag.toLowerCase() === 'do not contact'
                          ? 'System Default Suppression Tag'
                          : checkedTags?.includes(member.tag.id)
                            ? isProfileModule
                              ? 'Detach tag'
                              : 'Turn tag off'
                            : isProfileModule
                              ? 'Attach tag'
                              : 'Turn tag on'
                      }
                      overlayClassName="tag-tooltip"
                      arrow={false}
                      placement="bottomRight"
                    >
                      <Tag.CheckableTag key={member.tag.id}>
                        {renderTagcontent(member.tag)}
                      </Tag.CheckableTag>
                    </Tooltip>
                  </span>
                </>
              ),
              key: memberKey,
              memberId: member.id,
              tagId: member.tag.id,
              icon: <></>,
              selectable: true,
              isLeaf: true,
              // blockNode: false
            };
          }),
        };
      });
    };

    // const filterTreeData = (data, tagIds) => {
    //   return data.reduce((acc, item) => {
    //     // Filter children nodes
    //     const filteredChildren = item.children
    //       ? filterTreeData(item.children, tagIds)
    //       : [];

    //     // Check if current node should be included
    //     const shouldIncludeNode = item.tagId && tagIds.includes(item.tagId);

    //     if (filteredChildren.length > 0 || shouldIncludeNode) {
    //       acc.push({
    //         ...item,
    //         children: filteredChildren,
    //       });
    //     }

    //     return acc;
    //   }, []);
    // };

    const onTreeNodeSelected = (
      selectedKeys,
      { selected, selectedNodes, node, event },
    ) => {
      setSelectedKeys(selectedKeys);
      if (selected) checkedTags = [...checkedTags, node.tagId];
      else {
        checkedTags = checkedTags.filter((id) => id !== node.tagId);
      }
      handleTagsCheck(checkedTags);
    };

    return (
      <>
        <div className="folder-view-wrapper">
          <div className="sub-title-bar">
            <div className="title">
              {folderTitle ? (
                folderTitle
              ) : (
                <>Folders ({props.folderLength || props.folders.length}) </>
              )}
              <EditIcon onClick={() => onEditClick(true)} />
            </div>
            <div
              className="see-all-text"
              onClick={togglePanel}
              style={{ cursor: 'pointer' }}
            >
              <DownArrow
                className="down-arrow"
                style={{
                  transform: expandPanel ? ' rotate(180deg)' : 'rotate(0deg)',
                  marginBottom: ' -1px',
                }}
              />
              {expandPanel ? 'See less' : 'See all'}
            </div>
          </div>
          <CreateTagElement isFolder onCreation={fetchTagFolders} />
          <div className="collapse-container">
            <Tree
              switcherIcon={(props) =>
                props.is_suppression_tag ? (
                  props.expanded ? (
                    // <OpenSuppressFolderIcon />
                    <span style={{ display: 'contents' }}>
                      <OpenFolderIcon className="open-folder-icon-with-suppress" />
                      <img
                        src={SuppressedPNGIcon}
                        id="suppress-icon-in-open-folder"
                      />
                    </span>
                  ) : (
                    //<SuppressFolderIcon />
                    <img
                      src={SuppressedFolderPNGIcon}
                      id="suppress-folder-icon"
                    />
                  )
                ) : props.expanded ? (
                  <OpenFolderIcon />
                ) : (
                  <FolderIcon />
                )
              }
              showIcon
              blockNode={false}
              multiple
              selectedKeys={selectedKeys}
              // defaultExpandAll
              // defaultSelectedKeys={['0-0-0']}
              // switcherIcon={<DownOutlined />}
              treeData={expandPanel ? folderData : folderData.slice(0, 3)}
              rootClassName="folder-tree"
              onSelect={onTreeNodeSelected}
            />
          </div>
        </div>
      </>
    );
  }),
);

export default FolderView;
