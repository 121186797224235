import './index.scss';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button, message } from 'antd';

import AiModal from '../AiModal';
import TagsSearch from '../TagsFolder/TagsSearch';
import { requestWithAuth } from '../../services/api';
import { getBodyParams } from '../../utils/getBodyParams';
import TagDetachIcon from '../../assets/icons/ai_ic_detach_tag.svg?react';
import TagAttachIcon from '../../assets/icons/ai_ic_attach_tag.svg?react';
import TagDetachIconHover from '../../assets/icons/ai_ic_detach_tag_hover.svg?react';
import LeftArrow from '../../assets/icons/ai_ic_down_arrow.svg?react';
import TagManagement from './TagManagement';

const TagsModal = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const {
      selectedProfileIds,
      profileTags,
      profileTagsDetails,
      tagSetup = 'showTagSetup',
      // selectAllRows,
      refreshData,
      updatePerson,
      dataCount,
    } = props;

    const [tagData, setTagData] = useState(
      props.dataStore.lookUpLists.userTagFolders,
    );
    const [isLoading, setIsLoading] = useState(false);
    const [profileTagsData, setProfileTagsData] = useState();
    // let [initProfileTags, setInitProfileTags] = useState([]);

    /** tagProfiles=showTagSetup will show the Tag/Untag option, means click from row/exportcomponent
     *  tagProfiles = attach / detach => again from from row/exportcomponent on basis of which option chosen Tag/Untag
     *  tagProfiles=showTagsForProfile => shows the list of tags and folders => click from profile => direct view
     */
    const [tagProfiles, setTagProfiles] = useState(tagSetup);
    const [unTagHoverIcon, setUnTagHovericon] = useState(false);
    const [showTagsEdit, setShowTagsEdit] = useState(false);
    const [showFolderEdit, setShowFolderEdit] = useState(false);

    useEffect(() => {
      if (profileTags && profileTags.length > 0)
        setProfileTagsData(profileTags);
    }, [profileTags]);

    useEffect(() => {
      setTagData({ ...props.dataStore.lookUpLists.userTagFolders });
      if (tagProfiles === 'detach') {
        let proTags = [];
        if (props.dataStore.lookUpLists.userTagFolders?.tag_folders.length) {
          props.dataStore.lookUpLists.userTagFolders.tag_folders.map(
            (tag_folder) =>
              tag_folder.tag_folder_members.map((tag) =>
                proTags.push(tag.tag.id),
              ),
          );
        }
        proTags = [
          ...proTags,
          ...props.dataStore.lookUpLists.userTagFolders.orphan_tags.map(
            (tag) => tag.id,
          ),
        ];
        setProfileTagsData([...proTags]);
      }
    }, [props.dataStore.lookUpLists.userTagFolders]);

    // useEffect(() => {
    //   setIsLoading(true);
    //   const params = {
    //     b2b_id: selectedProfileIds[0].b2b_id,
    //   };
    //   if (selectedProfileIds[0].b2c_id) {
    //     params.b2c_id = selectedProfileIds[0].b2c_id;
    //   }
    //   requestWithAuth('profile-tags', 'GET', params, null, null)
    //     .then((response) => {
    //       if (response && response.length) {
    //         let tags = response.map((profiletag) => profiletag.tag);
    //         setProfileTagsData(tags);
    //         setInitProfileTags(tags);
    //       }
    //       setIsLoading(false);
    //     })
    //     .catch((e) => {
    //       setIsLoading(false);
    //     });
    // }, [selectedProfileIds]);

    // const fetchProfileTags = () => {
    //   setIsLoading(true);
    //   const params = {
    //     b2b_id: selectedProfileIds[0].b2b_id,
    //   };
    //   if (selectedProfileIds[0].b2c_id) {
    //     params.b2c_id = selectedProfileIds[0].b2c_id;
    //   }
    //   requestWithAuth('profile-tags', 'GET', params, null, null)
    //     .then((response) => {
    //       if (response && response.length) {
    //         setProfileTagsData([...response]);
    //       }
    //       setIsLoading(false);
    //     })
    //     .catch((e) => {
    //       setIsLoading(false);
    //     });
    // };
    const handleAddTagsToProfiles = (tagIds) => {
      if (tagIds.length === 0) {
        message.error('Please select tags to add', 5);
        return;
      }
      let body = {};
      const params = {};

      if (selectedProfileIds.length === 0) {
        /** No profile selected - means, whole serached data
         * ie, click from export component
         */
        if (
          !props.modalDataStore.profileModal ||
          !props.modalDataStore.profileModal.mappedRelationshipsFilter
            .filterName
        ) {
          if (props.dataStore.quick_search)
            params.quick_search = props.dataStore.quick_search;
          if (props.dataStore.networkFilters.filter_type) {
            params.filter_type = props.dataStore.networkFilters.filter_type;
          }
        }
        body = getBodyParams(
          props.dataStore.networkFilters,
          props.dataStore.lookUpLists,
          props.dataStore.superConnectorFilter,
          props.dataStore.teamFilters,
          props.dataStore.currentUser,
          props.dataStore.mappedRelationshipsFilter,
          props.modalDataStore.profileModal,
          props.dataStore.groupByValues,
        );
      } else {
        body.b2b_b2c_list = selectedProfileIds;
      }

      body.tag_ids = tagIds;

      requestWithAuth('profile-tags', 'POST', params, body, null)
        .then((response) => {
          if (response && response.code === 200 && response.message == 'ok')
            message.success('Tags attached successfully');
          if (
            refreshData &&
            props.dataStore.currentUser.userSelectedColumns.includes('tags')
          )
            refreshData();
        })
        .catch((err) => {
          message.error('Please try again', 5);
          console.log(err);
        });
      // onClose();
    };

    const handleRemoveTagsFromProfiles = (tagIds) => {
      if (tagIds.length === 0) {
        message.error('Please unselect profiles and tags to remove', 10);
        return;
      }
      let body = {};
      const params = {};
      if (selectedProfileIds.length === 0) {
        /** No profile selected - means, whole serached data */
        if (
          !props.modalDataStore.profileModal ||
          !props.modalDataStore.profileModal.mappedRelationshipsFilter
            .filterName
        ) {
          if (props.dataStore.quick_search)
            params.quick_search = props.dataStore.quick_search;
          if (props.dataStore.networkFilters.filter_type) {
            params.filter_type = props.dataStore.networkFilters.filter_type;
          }
        }
        body = getBodyParams(
          props.dataStore.networkFilters,
          props.dataStore.lookUpLists,
          props.dataStore.superConnectorFilter,
          props.dataStore.teamFilters,
          props.dataStore.currentUser,
          props.dataStore.mappedRelationshipsFilter,
          props.modalDataStore.profileModal,
          props.dataStore.groupByValues,
        );
      } else {
        body.b2b_b2c_list = selectedProfileIds;
      }
      body.tag_ids = tagIds;
      requestWithAuth(
        'profile-tags/delete-multiple',
        'POST',
        params,
        body,
        null,
      )
        .then((response) => {
          if (response && response.code === 200 && response.message == 'ok')
            message.success('Tags detached successfully');
          if (
            refreshData &&
            props.dataStore.currentUser.userSelectedColumns.includes('tags')
          )
            refreshData();
        })
        .catch((err) => {
          message.error('Please try again', 5);
          console.log(err);
        });
    };

    const fetchUserTags = () => {
      requestWithAuth('tags', 'GET', null, null, null).then((response) => {
        if (response && response.length) {
          props.dataStore.userTags = response;
        }
      });
    };

    const fetchTagFolders = () => {
      requestWithAuth('tag-folder', 'GET', null, null, null).then(
        (response) => {
          if (response) {
            props.dataStore.lookUpLists.userTagFolders = response;
          }
        },
      );
    };
    const handleCancel = () => {
      if (showTagsEdit) {
        setShowTagsEdit(false);
      } else if (showFolderEdit) {
        setShowFolderEdit(false);
      } else {
        props.onClose();
      }
    };
    const onTagSearch = (tags) => {
      if (showTagsEdit) {
        setShowTagsEdit(false);
      }
      if (showFolderEdit) {
        setShowFolderEdit(false);
      }
      setTagData(tags);
    };

    const handleEditTags = (value) => {
      setShowTagsEdit(value);
    };

    const handleEditFolder = (value) => {
      setShowFolderEdit(value);
    };

    // const isArrEqual = () => {
    //   return initProfileTags.sort().join() === profileTagsData.sort().join();
    // };
    const handleTagsInProfiles = () => {
      if (tagProfiles === 'showTagsForProfile') {
        /** Click from profile header */
        const initProfileTagsSet = new Set([...profileTags]);
        const profileTagsDataSet = new Set([...profileTagsData]);
        const tagstoRemove = initProfileTagsSet.difference(profileTagsDataSet);
        const tagstoAdd = profileTagsDataSet.difference(initProfileTagsSet);
        let updatedtags = [...profileTagsDetails];
        if (tagstoRemove && tagstoRemove.size > 0) {
          const removedTags = Array.from(tagstoRemove);
          handleRemoveTagsFromProfiles(removedTags);

          if (updatePerson) {
            updatedtags = updatedtags.filter(
              (item) => !removedTags.includes(item.tag),
            );
            // updatePerson('tags_details', updatedtags);
          }
        }
        if (tagstoAdd && tagstoAdd.size > 0) {
          const addTags = Array.from(tagstoAdd);
          handleAddTagsToProfiles(addTags);

          if (updatePerson) {
            const updatedFolderTags =
              props.dataStore.lookUpLists.userTagFolders.tag_folders[0].tag_folder_members.map(
                (item) => {
                  if (addTags.includes(item.tag.id))
                    return {
                      tag: item.tag.id,
                      tag_name: item.tag.tag,
                      pid: -1,
                    };
                },
              );

            updatedtags = [
              ...updatedtags,
              ...updatedFolderTags,
              ...props.dataStore.lookUpLists.userTagFolders.orphan_tags.map(
                (item) => {
                  if (addTags.includes(item.id))
                    return { tag: item.id, tag_name: item.tag, pid: -1 };
                },
              ),
            ].filter((item) => !!item);
          }
        }

        if (updatePerson && updatedtags && updatedtags.length > 0) {
          updatePerson('tags_details', updatedtags);
        }
      } else if (tagProfiles === 'attach' && profileTagsData.length >= 0) {
        handleAddTagsToProfiles([...profileTagsData]);
      } else if (profileTagsData.length >= 0) {
        let proTags = [];
        if (props.dataStore.lookUpLists.userTagFolders?.tag_folders.length) {
          props.dataStore.lookUpLists.userTagFolders.tag_folders.map(
            (tag_folder) =>
              tag_folder.tag_folder_members.map((tag) =>
                proTags.push(tag.tag.id),
              ),
          );
        }

        // if(proTags.length>5)
        //   proTags.splice(0,5)
        proTags = [
          ...proTags,
          ...props.dataStore.lookUpLists.userTagFolders.orphan_tags.map(
            (tag) => tag.id,
          ),
        ];
        const tagstoRemove = proTags.filter(
          (tagId) => !profileTagsData.includes(tagId),
        );
        //console.log(tagstoRemove, 'tagstoRemove');
        handleRemoveTagsFromProfiles([...tagstoRemove]);
      }
    };

    // const findProfileTag = (tagId) => {
    //   if (props.dataStore.lookUpLists.userTagFolders.tag_folders) {
    //     for (const tagFolder of props.dataStore.lookUpLists.userTagFolders
    //       .tag_folders) {
    //       for (const tag_folder_member of tagFolder.tag_folder_members) {
    //         if (tag_folder_member.tag.id == tagId)
    //           return (
    //             tagFolder.tag_folder_name + ' > ' + tag_folder_member.tag.tag
    //           );
    //       }
    //     }
    //   }
    //   if (props.dataStore.lookUpLists.userTagFolders.orphan_tags) {
    //     for (const tag of props.dataStore.lookUpLists.userTagFolders
    //       .orphan_tags) {
    //       if (tag.id == tagId) {
    //         return tag.tag;
    //       }
    //     }
    //   }
    //   if (props.dataStore.lookUpLists.userTagFolders.shared_tags) {
    //     for (const tag of props.dataStore.lookUpLists.userTagFolders
    //       .shared_tags) {
    //       if (tag.id == tagId) return tag.tag;
    //     }
    //   }
    // };

    const renderTagsContent = () => {
      if (tagProfiles === 'showTagSetup')
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '20px',
              fontSize: '16px',
            }}
          >
            <div className="tag-untag-text">
              Select a tagging function to apply to multiple profiles
            </div>
            <div className="tags-selection-button-parent">
              <div
                className="tag-option tags-selection-button"
                onClick={() => {
                  setTagProfiles('attach');
                }}
              >
                <div style={{ paddingBottom: 30, fontWeight: 600 }}>Tag</div>
                <TagAttachIcon width={60} />
              </div>
              <div style={{ width: 3, background: '#E8EFF5' }}></div>
              <div
                className="untag-option tags-selection-button"
                onClick={() => {
                  setTagProfiles('detach');
                  let proTags = [];
                  if (
                    props.dataStore.lookUpLists.userTagFolders.tag_folders &&
                    props.dataStore.lookUpLists.userTagFolders.tag_folders
                      .length > 0
                  ) {
                    props.dataStore.lookUpLists.userTagFolders.tag_folders.map(
                      (tag_folder) =>
                        tag_folder.tag_folder_members.map((tag) =>
                          proTags.push(tag.tag.id),
                        ),
                    );
                  }
                  proTags = [
                    ...proTags,
                    ...props.dataStore.lookUpLists.userTagFolders.orphan_tags.map(
                      (tag) => tag.id,
                    ),
                  ];
                  setProfileTagsData([...proTags]);
                }}
                onMouseOver={() => setUnTagHovericon(true)}
                onMouseLeave={() => {
                  setUnTagHovericon(false);
                }}
              >
                <div style={{ paddingBottom: 21, fontWeight: 600 }}>Untag</div>
                {unTagHoverIcon ? (
                  <TagDetachIconHover width={72} />
                ) : (
                  <TagDetachIcon width={72} />
                )}
              </div>
            </div>
          </div>
        );

      return (
        <div className="tags-modal-content">
          <TagsSearch onSearch={onTagSearch} />

          <div className="tags-modal-container">
            <div>
              <div className="tag-management">
                {/* <div className="title">Tag Management</div>
            {tagData &&
              tagData.tag_folders &&
              tagData.tag_folders.length > 0 && (
                <div className="description">
                  Use folders to easily organize prospects and add an entire
                  folder to a suppression list.
                </div>
              )} */}
                <TagManagement
                  tagOrUntag={tagProfiles}
                  userTagFolders={tagData}
                  profileTagsData={profileTagsData}
                  fetchUserTags={fetchUserTags}
                  fetchTagFolders={fetchTagFolders}
                  handleEditTags={handleEditTags}
                  handleEditFolder={handleEditFolder}
                  showTagsEdit={showTagsEdit}
                  showFolderEdit={showFolderEdit}
                  tagSetup={tagSetup}
                  tagProfiles={tagProfiles}
                  selectedProfileIds={selectedProfileIds}
                  dataCount={dataCount}
                  changeProfileTagsData={(tag) => {
                    setProfileTagsData(tag);
                  }}
                />
              </div>
            </div>
            {/* <div className="separator" />
              <Suppression /> */}
          </div>
        </div>
      );
    };

    return (
      <AiModal
        onCancel={props.onClose}
        wrapClassName={
          tagProfiles === 'showTagSetup'
            ? 'tags-setup'
            : 'tags-modal tags-modal-for-profiles'
        }
        footer={[
          <Button key="cancel" className="btn-cancel" onClick={handleCancel}>
            <>
              {(showTagsEdit || showFolderEdit) && (
                <LeftArrow
                  style={{
                    transform: 'rotate(90deg)',
                    height: '13px',
                    marginRight: '10px',
                  }}
                />
              )}
              {/* {showTagsEdit
                ? 'Back to all folders & tags'
                : showFolderEdit
                  ? 'Back to all folders'
                  : 'Cancel'} */}
              {showTagsEdit || showFolderEdit ? 'Back' : 'Cancel'}
            </>
          </Button>,
          <Button
            key="ok"
            type="primary"
            className="btn-ok"
            onClick={() => {
              props.onClose();
              handleTagsInProfiles();
            }}
          >
            Done
          </Button>,
        ]}
      >
        {renderTagsContent()}
      </AiModal>
    );
  }),
);

export default TagsModal;
