import './index.scss';

import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { message, Radio, Tag } from 'antd';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import qs from 'qs';
import cloneDeep from 'lodash/cloneDeep';

import Filter from '../../components/RISubComponents/Filter';
import LoaderAnimation from '../../components/AidLoader';
import NoOpportunityData from '../../components/RISubComponents/NoOpportunityData';
import OpportunityByType from '../../components/RISubComponents/OpportunityByType';
import OpportunityByDate from '../../components/RISubComponents/OpportunityByDate';
import WarningMessage from '../../components/WarningMessage';
import { getSubscription, splitNumberWithCommas } from '../../utils/general';
import { copyUserFilter } from '../../utils/copyUserFilter';
import { requestWithAuth } from '../../services/api';

let triggerTypes = [];
// let oldFilter = [];

message.config({
  top: 80,
});
let source;
let query;

const OpportunitiesNew = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const [user, setUser] = useState({ name: '', crm: '' });
    // const [opportunitiesList, setOpportunitiesList] = useState([]);
    // const [opportunitiesGroup, setOpportunitiesGroup] = useState({});
    const [opportunitiesData, setOpportunitiesData] = useState([]);
    const [hasOpportunities, setHasOpportunities] = useState(false);
    const [showNoData, setShowNoData] = useState(false);
    const [opportunitiesCount, setOpportunitiesCount] = useState({});
    const [loading, setLoading] = useState({});
    const [dailyOpportunitiesDate, setDailyOpportunitiesDate] = useState(null);
    const [groupByValue, setGroupByValue] = useState('triggerType');
    const [hasTypeData, setHasTypeData] = useState(false);
    const location = useLocation();
    const borderRef = useRef(null);

    useEffect(() => {
      props.dataStore.lookUpLists.isOpportunitiesPage = true;
      const { CancelToken } = axios;
      source = CancelToken.source();

      const { crm, org_name, full_name } = props.dataStore.currentUser;
      setUser({
        name: full_name || '',
        crm: crm || '',
        org_name: org_name || '',
      });

      query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      if (query)
        window.history.replaceState(null, null, window.location.pathname);

      if (borderRef && borderRef.current) {
        borderRef.current.style.left = '87px';
      }

      if (getSubscription(props.dataStore.currentUser)) {
        if (
          props.globalDataStore.multipleFilters.selectedIdList &&
          props.globalDataStore.multipleFilters.selectedIdList.length > 0
        ) {
          // oldFilter = [];
          triggerTypes = props.dataStore.lookUpLists.eventList
            .filter((item) => item.value !== 'all')
            .map((item) => item.value);

          fetchDataWithType(0);
        }
      }

      return () => {
        // oldFilter = [];
        props.dataStore.lookUpLists.isOpportunitiesPage = false;
        message.destroy();
        if (source) source.cancel('Operation canceled by the user.');
      };
    }, []);

    useEffect(() => {
      if (
        props.globalDataStore.multipleFilters.selectedIdList &&
        props.globalDataStore.multipleFilters.selectedIdList.length > 0
      ) {
        if (
          triggerTypes.length === 0 &&
          props.dataStore.lookUpLists.eventList &&
          props.dataStore.lookUpLists.eventList.length > 0
        ) {
          triggerTypes = props.dataStore.lookUpLists.eventList
            .filter((item) => item.value !== 'all')
            .map((item) => item.value);
        }
        if (source) source.cancel('Operation canceled by the user.');
        const { CancelToken } = axios;
        source = CancelToken.source();
        fetchDataWithType(0);
      }
    }, [props.globalDataStore.multipleFilters.selectedIdList, groupByValue]);

    // useEffect(() => {
    //   console.log('groupByValue=', groupByValue);
    //   // console.log('isSameFilters=', isSameFilters());
    //   if (
    //     props.globalDataStore.multipleFilters.opporunitySelectedIdList &&
    //     props.globalDataStore.multipleFilters.opporunitySelectedIdList.length >
    //       0
    //     // && !isSameFilters()
    //   ) {
    //     console.log('333');
    //     oldFilter = [
    //       ...props.globalDataStore.multipleFilters.opporunitySelectedIdList.map(
    //         (item) => item.value,
    //       ),
    //     ];
    //     if (source) source.cancel('Operation canceled by the user.');
    //     const { CancelToken } = axios;
    //     source = CancelToken.source();

    //     fetchDataWithType(0);
    //   }
    // }, [groupByValue]);

    useEffect(() => {
      if (groupByValue === 'triggerDate') {
        if (
          opportunitiesData &&
          opportunitiesData.date &&
          opportunitiesData.date.length > 0
        )
          setHasOpportunities(true);
        else setHasOpportunities(false);
      } else if (groupByValue === 'triggerType') {
        if (
          opportunitiesData &&
          Object.values(opportunitiesData).some((array) => array.length > 0)
        )
          setHasOpportunities(true);
      } else {
        setHasOpportunities(false);
      }
    }, [opportunitiesData]);

    // const isSameFilters = () => {
    //   console.log('oldFilter=', oldFilter);
    //   return (
    //     props.globalDataStore.multipleFilters.opporunitySelectedIdList
    //       .length === oldFilter.length &&
    //     props.globalDataStore.multipleFilters.opporunitySelectedIdList.every(
    //       (item) => oldFilter.includes(item.value),
    //     )
    //   );
    // };

    const onGroupByValueChange = (e) => {
      setGroupByValue(e.target.value);
      if (borderRef && borderRef.current) {
        /** bottom border of checked radio button is showing
         * use a div and its postion is mannually adjusted
         *  on radiobutton change
         */
        borderRef.current.style.left =
          e.target.value === 'triggerType' ? '87px' : '232px';

        if (e.target.value === 'triggerType') setHasTypeData(false);
      }
    };

    function toggleProgress(toggle, type = 'date') {
      setLoading((prev) => ({ ...prev, [type]: toggle }));
    }

    function showProfile(b2bId, b2cId) {
      // profile clicked
      if (!b2bId && !b2cId) {
        message.warning(
          'This profile cannot be opened right now. Please try again later.',
          10,
        );
        return;
      }
      const { profileModal } = props.modalDataStore;
      profileModal.b2bId = b2bId;
      profileModal.b2cId = b2cId;
      profileModal.mimpersonId = '';
      profileModal.eventsId = '';
      profileModal.isVisible = true;
    }

    function showCompanyProfile(id, orgName) {
      if (!id) return;
      // click on any company name
      props.modalDataStore.resetProfileModal();
      props.modalDataStore.resetCompanyProfileModal();
      const { companyProfileModal } = props.modalDataStore;
      companyProfileModal.id = id;
      companyProfileModal.orgName = orgName;
      companyProfileModal.isVisible = true;
    }

    async function fetchDataWithType(fromPage) {
      if (groupByValue === 'triggerDate') {
        fetchData(fromPage);
      } else {
        const fetchPromises = triggerTypes.map(async (type) => {
          return fetchData(fromPage, type);
        });

        try {
          /**checking all async fetches resolve - meaning all fetches return no data ,Promise.all resolves only when all individual Promises resolves,then calling fetch function with extra query param */
          await Promise.all(fetchPromises)
            .then((res) =>
              triggerTypes.map(async (type) => {
                return fetchData(fromPage, type, 'opportunities_estimate');
              }),
            )
            .then(() => {
              setHasTypeData(false);
            });
        } catch (error) {
          console.error('Error during fetches', error);
        }
      }
    }

    async function fetchData(
      fromPage,
      event = 'date',
      opportunitiesEstimate = null,
    ) {
      return new Promise((resolve, reject) => {
        /** returning promise to check for resolve condition */
        let ids = [];
        if (
          props.globalDataStore.multipleFilters.selectedIdList &&
          props.globalDataStore.multipleFilters.selectedIdList.length > 0
        ) {
          // ids = [...props.globalDataStore.multipleFilters.opporunitySelectedIdList];
          ids = props.globalDataStore.multipleFilters.selectedIdList
            .filter((obj) => obj.value && obj.value > 0)
            .map((obj) => obj.value);

          if (!ids || ids.length <= 0) {
            setLoading((prev) => ({ ...prev, [event]: false }));
            return;
          }
        }
        setLoading((prev) => ({ ...prev, [event]: true }));
        let list = [];
        const params = {
          page_size: 20,
          //trigger_check:radioTriggerOptions
        };

        if (
          query &&
          !(Object.keys(query).length === 0 && query.constructor === Object) &&
          (query.daily_opportunities_id || query.daily_opportunities_date)
        ) {
          /**Redirection from email */
          if (query.daily_opportunities_id)
            params.daily_opportunities_id = query.daily_opportunities_id;

          if (query.daily_opportunities_date) {
            params.daily_opportunities_date = query.daily_opportunities_date;
            setDailyOpportunitiesDate(query.daily_opportunities_date);
          }
        }

        if (fromPage && fromPage > 0) {
          params.from = fromPage;
          list = opportunitiesData[event];
        }
        if (opportunitiesEstimate) {
          params[opportunitiesEstimate] = 1;
        }

        if (groupByValue === 'triggerType' && event && event !== 'date') {
          params.event_type = event;
        }

        const body = {
          filter_ids: [],
          collapse_field: 'b2b_id',
        };

        body.filter_ids = ids;
        requestWithAuth(
          'opportunities-ds2',
          'POST',
          params,
          body,
          source.token,
          'progress_icon',
        ).then((response) => {
          if (response === null) {
            /** handling error here to set nodata to avoid the blank screen */
            setOpportunitiesCount((prev) => ({ ...prev, [event]: 0 }));
            setOpportunitiesData((prev) => ({ ...prev, [event]: [] }));
            setLoading((prev) => ({ ...prev, [event]: false }));
            /**resolve  when nodata in typeTrigger */
            if (opportunitiesEstimate === null && event !== 'date') resolve();
            return;
          }
          if (!response) {
            // if (event !== 'date' && opportunitiesEstimate === null) {
            //   resolve();
            // }
            return;
          }
          if (
            response?.count == 0 &&
            event !== 'date' &&
            opportunitiesEstimate === null
          )
            resolve();

          if (response && response.results && response.count) {
            setOpportunitiesCount((prev) => ({
              ...prev,
              [event]: response.count,
            }));
            list.push(...response.results);
            setOpportunitiesData((prev) => ({ ...prev, [event]: [...list] }));
            setLoading((prev) => ({ ...prev, [event]: false }));
            setShowNoData(false);
            if (opportunitiesEstimate === null) setHasTypeData(true);
            return;
          }

          if (response && response.message) {
            message.error(response.message, 10);
          } else if (response && response.detail) {
            message.error(response.detail, 10);
          }
          setOpportunitiesCount((prev) => ({ ...prev, [event]: 0 }));
          setOpportunitiesData((prev) => ({ ...prev, [event]: [] }));
          setLoading((prev) => ({ ...prev, [event]: false }));
          setShowNoData(true);
          // props.globalDataStore.multipleFilters.fromFilters = false;
        });
      });
    }

    function addReminder(
      isReminder,
      value,
      b2bId,
      b2cId,
      index,
      type = 'date',
    ) {
      setLoading((prev) => ({ ...prev, [type]: true }));
      const body = {};
      body.source_type = 'OP';
      if (isReminder) body.remind_after = value;
      else if (
        props.globalDataStore.multipleFilters.selectedIdList.some(
          (opt) => opt.value === -2,
        )
      )
        body.is_hidden = 0;
      else body.is_hidden = 1;

      if (b2bId) body.b2b_id = b2bId;
      if (b2cId) body.b2c_id = b2cId;

      requestWithAuth(
        'opportunities-reminder',
        'POST',
        null,
        body,
        source.token,
        'progress_icon',
      ).then((response) => {
        if (response && response.code === 200) {
          if (opportunitiesData[type] && opportunitiesData[type].length > index)
            opportunitiesData[type].splice(index, 1);
          setOpportunitiesCount((prev) => ({
            ...prev,
            [type]: opportunitiesCount[type] - 1,
          }));
          setLoading((prev) => ({ ...prev, [type]: false }));
          return;
        }
        if (response && response.message) {
          message.error(response.message, 10);
        } else if (response && response.detail) {
          message.error(response.detail, 10);
        }
        setLoading((prev) => ({ ...prev, [type]: false }));
      });
    }

    const onDateGroupingReminderClick = (index) => {
      const list = [...opportunitiesData.date];
      list[index].remind_days = 0;
      setOpportunitiesData((prev) => ({ ...prev, date: list }));
    };

    const openUserFilter = (id) => {
      const filterObj = props.globalDataStore.multipleFilters.filterList.filter(
        (item) => {
          return item.value === id;
        },
      );
      if (!filterObj || filterObj.length <= 0) return;
      const filter = filterObj[0];
      props.globalDataStore.reset();
      props.globalDataStore.multipleFilters.isFilterApplied = true;

      if (filter.value)
        props.globalDataStore.masterFiltersOnSelect.profile_id = filter.value;
      let dataStoreFilters = {};
      if (filter && filter.filters) {
        dataStoreFilters = copyUserFilter(
          filter.filters,
          props.globalDataStore.masterFiltersOnSelect,
          props.dataStore.lookUpLists,
          filter.label,
          filter.is_active,
          filter.email_alert,
          filter.separate_email_alert,
          false,
        );
      }

      props.globalDataStore.masterFiltersOnSelect.filter_type =
        filter.filter_type;
      props.globalDataStore.masterFiltersOnSelect = cloneDeep(dataStoreFilters);
      props.globalDataStore.masterFilters = cloneDeep(
        props.globalDataStore.masterFiltersOnSelect,
      );
      props.modalDataStore.filterModal.isVisible = true;
    };

    const isLoading =
      groupByValue === 'triggerDate'
        ? loading.date
        : Object.values(loading)
            .slice(1)
            .some((item) => item === true);

    const hasData =
      groupByValue === 'triggerDate'
        ? opportunitiesData.date && opportunitiesData.date.length > 0
        : Object.values(opportunitiesData)
            .slice(1)
            .some((item) => item.length > 0);

    if (!getSubscription(props.dataStore.currentUser)) {
      return (
        <WarningMessage
          iconName="upgrade"
          content="To view executives, upgrade today!"
          buttonText="Subscribe"
        />
      );
    }

    return (
      <div className="opportunities-new-container">
        {/* <div
          id="progress_icon"
          style={{
            display: `${isLoading ? 'block' : 'none'}`,
          }}
        >
          <Spin
            style={{ position: 'fixed', marginTop: '150px' }}
            size="large"
          />
        </div> */}
        <>
          <div>
            {hasOpportunities && (
              <div className="caption">
                <>Hi {user.name?.split(' ')[0]} -&nbsp;</>
                {opportunitiesCount.date > 0 && (
                  <span>
                    {`here are your opportunities (${splitNumberWithCommas(
                      opportunitiesCount.date,
                    )})`}
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="filter-section">
            <div className="groupby-radio">
              <div className="groupby-text">View by</div>
              <Radio.Group
                onChange={onGroupByValueChange}
                defaultValue="triggerType"
              >
                <Radio.Button className="radio-style" value="triggerType">
                  Wealth Trigger Type
                </Radio.Button>
                <Radio.Button className="radio-style" value="triggerDate">
                  Wealth Trigger Date
                </Radio.Button>
              </Radio.Group>
              <div className="bottom-line" ref={borderRef} />
            </div>
            {((groupByValue === 'triggerType' && hasTypeData) ||
              groupByValue === 'triggerDate') && (
              <div className="filter-container">
                {/* <div className="info">
                Only Saved Search(es) with triggers will be available in the
                drop-down
              </div> */}
                <div>
                  {/* {props.globalDataStore.multipleFilters
                  .opporunitySelectedIdList &&
                  props.globalDataStore.multipleFilters.opporunitySelectedIdList
                    .length > 0 && (
                    <div style={{ width: '300px' }}>
                      <div style={{ color: 'var(--color-dark-shade)' }}>
                        Saved Searches:
                      </div>

                      <TriggerFilter from="opportunities" />
                    </div>
                  )} */}
                  {props.globalDataStore.multipleFilters.selectedIdList &&
                    props.globalDataStore.multipleFilters.selectedIdList
                      .length > 0 && (
                      <div style={{ width: '300px' }}>
                        <div style={{ color: 'var(--color-dark-shade)' }}>
                          Saved Searches:
                        </div>
                        <Filter />
                      </div>
                    )}

                  {dailyOpportunitiesDate && (
                    /** filter from email-redirection */
                    <Tag
                      closable
                      className="email-redirection-indicator"
                      onClose={(e) => {
                        setDailyOpportunitiesDate(null);
                        query = null;
                        fetchDataWithType();
                      }}
                    >
                      Daily Opportunities: {dailyOpportunitiesDate}
                    </Tag>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="loader-wrapper">
            {isLoading && <LoaderAnimation loaderId="opp-new-loader" />}
            <div className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
              {!isLoading && !hasData ? (
                <NoOpportunityData page="Opportunities" />
              ) : (
                <>
                  {groupByValue === 'triggerType' ? (
                    <OpportunityByType
                      data={opportunitiesData}
                      fetchData={fetchData}
                      // onReminderClick = {onTypeGroupingReminderClick}
                      showProfile={showProfile}
                      showCompanyProfile={showCompanyProfile}
                      addReminder={addReminder}
                      openUserFilter={openUserFilter}
                      opportunitiesCount={opportunitiesCount}
                      loading={loading}
                      toggleProgress={toggleProgress}
                      user={user}
                    />
                  ) : (
                    <OpportunityByDate
                      data={opportunitiesData.date || []}
                      isLoading={loading.date}
                      fetchData={fetchData}
                      onReminderClick={onDateGroupingReminderClick}
                      showProfile={showProfile}
                      showCompanyProfile={showCompanyProfile}
                      openUserFilter={openUserFilter}
                      addReminder={addReminder}
                      opportunitiesCount={opportunitiesCount.date}
                      toggleProgress={toggleProgress}
                      user={user}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </>
      </div>
    );
  }),
);

export default OpportunitiesNew;
