import './index.css';

import { Component } from 'react';
import Select from 'react-select';
import { DownOutlined } from '@ant-design/icons';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import BetaImage from '../../assets/icons/ai_beta_flag_b.svg';

class DataSelect extends Component {
  state = {
    selectedOption: null,
  };

  handleChange = (selected) => {
    this.setState({ selectedOption: selected });
    const { onItemSelected, id } = this.props;
    onItemSelected(selected, id);
  };

  componentDidMount() {
    this.selectOption(this.props);
  }

  handleScrollToBottom = () => {
    if (document.getElementById('user-filter-parent'))
      document.getElementById('user-filter-parent').scrollBy({
        top: 250,
        behavior: 'smooth',
      });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.selectOption(nextProps);
  }

  selectOption = (nextProps) => {
    this.setState({
      selectedOption: nextProps.value,
    });
  };

  renderPlaceholder = () => {
    const { placeholder, isBeta } = this.props;
    return (
      <div className="place-holder">
        <div>{placeholder}</div>
        {isBeta === true && (
          <img
            style={{
              width: '40px',
              marginLeft: '5px',
            }}
            src={BetaImage}
            alt="beta"
          />
        )}
      </div>
    );
  };

  render() {
    const {
      styleChange,
      isSearchable = true,
      dropDownList,
      isMulti,
      placeholder,
      isClearable = true,
      closeMenuOnSelect = false,
      from = '',
      disabled = false,
      dropdownicon = <DownOutlined className="dropdown-icon" />,
      ...restProps
    } = this.props;
    const { selectedOption } = this.state;

    return (
      <div className={'dropdown dropdown-select'}>
        {((selectedOption && Object.keys(selectedOption).length > 0) ||
          from === 'master') &&
          from !== 'auto-filter' && (
            <div className="placeholder-active">{placeholder}</div>
          )}

        <Select
          placeholder={
            from === 'master' ? <>Select</> : this.renderPlaceholder()
          }
          value={selectedOption}
          isMulti={isMulti}
          className={styleChange ? 'multi-auto-suggest' : 'select-dropdown'}
          classNamePrefix="ai"
          isSearchable={isSearchable}
          isClearable={isClearable}
          closeMenuOnSelect={closeMenuOnSelect}
          //   onMenuScrollToBottom={() => {
          //     this.handleScrollToBottom();
          //   }}
          hideSelectedOptions={false}
          options={dropDownList}
          cropWithEllipsis
          onFocus={this.inFocus}
          onChange={this.handleChange}
          components={{
            DropdownIndicator: () => <>{dropdownicon}</>,
            IndicatorSeparator: () => null,
          }}
          isDisabled={disabled}
          {...restProps}
        />
      </div>
    );
  }
}

export default withLDConsumer()(DataSelect);
