import './index.scss';
import { Table } from 'antd';
const TaxDescription = (props) => {
  const data = [
    {
      id: 1,
      item: 'Wage',
      description:
        'The term “wages” is defined in section 3401(a) for Federal income tax withholding purposes as all remuneration for services performed by an employee for his employer, with certain specific exceptions.',
    },
    {
      id: 2,
      item: 'Household Income',
      description:
        'Includes both taxable and nontaxable income of any persons living in the household. This refers to individuals, including children, who lived in the home for any period during the year and who may or may not be related to you. ',
    },
    {
      id: 3,
      item: 'Wealth Income ',
      description:
        'This includes the total value of income generated from personal assets, including cash, bank deposits, real estate, assets in insurance and pension plans, ownership of unincorporated businesses, financial securities, and personal trusts.            ',
    },
    {
      id: 4,
      item: 'Retirement Income',
      description:
        'Includes social security benefits as well as any benefits from annuities, retirement or profit-sharing plans, insurance contracts, IRAs, etc. Retirement income may be fully or partially taxable.',
    },
    {
      id: 5,
      item: 'Business Income',
      description:
        'Includes income received from the sale of products or services.',
    },
    {
      id: 6,
      item: 'Schedule E Income',
      description:
        'Includes income or loss from rental real estate, royalties, partnerships, S corporations, estates, trusts, and residual interests in real estate mortgage investment conduits (REMICs).',
    },
    {
      id: 7,
      item: 'Non Wage Income',
      description:
        'Non-wage income is any income you get that is not from a job and not reported on a W-2 (or a Schedule C, if Self-employed). Examples of non-wage income would be disability income, retirement plan/pension income, social security, unemployment, paid family leave, child support, alimony, investment income, and interest from a bank account.',
    },
    {
      id: 8,
      item: 'Disposable Income',
      description:
        "For an individual, gross income is your total pay, which is the amount of money you've earned before taxes and other items are deducted. From your gross income, subtract the income taxes you owe. The amount left represents your disposable income.",
    },
    {
      id: 9,
      item: 'Total Investable Assets',
      description:
        'Equals sum of total non-retirement assets (Interest-bearing assets: deposits, CDs, money-market funds, bonds, bond funds, etc. and equity-related assets:  stocks, equity funds, ETFs, etc.) and retirement assets (IRAs, 401Ks and defined-contribution plans).',
    },
    {
      id: 10,
      item: 'Capital Gains ',
      description:
        'A capital gain occurs when you sell something for more than you spent to acquire it. This includes investments and also applies to personal property, such as a car.',
    },
    {
      id: 11,
      item: 'IRA Deduction',
      description:
        'The IRS categorizes the IRA (Individual Retirement Account) deduction as an above-the-line deduction, meaning you can take it regardless of whether you itemize or claim the standard deduction. This deduction reduces your taxable income for the year, which ultimately reduces the amount of income tax you pay.',
    },
    {
      id: 12,
      item: 'Total Gifts to Charity',
      description:
        'A charitable contribution is a donation or gift to, or for the use of, a qualified organization. It is voluntary and is made without getting, or expecting to get, anything of equal value.',
    },
    {
      id: 13,
      item: 'Real Estate Taxes',
      description:
        'Deductible real estate taxes are generally any state or local taxes on real property levied for the general public welfare.',
    },
    {
      id: 14,
      item: 'Home Mortgage Interests and Points Reported',
      description:
        'The term points is used to describe certain charges paid to obtain a home mortgage. Points may also be called loan origination fees, maximum loan charges, loan discount, or discount points. Points are prepaid interest and may be deductible as home mortgage interest.',
    },
  ];
  const columns = [
    {
      key: 'item',
      title: 'Item',
      dataIndex: 'item',
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
    },
  ];

  return (
    <div className="description-table">
      <Table dataSource={data} columns={columns} pagination={false} />
    </div>
  );
};

export default TaxDescription;
