import './index.scss';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import CountUp from 'react-countup';
import { inject, observer } from 'mobx-react';

import ArrowUpIcon from '../../assets/icons/ai_up_arrow.svg?react';

import { requestWithAuth } from '../../services/api';
import filterStates from '../../utils/Statelist';
import AiTooltip from '../AiTooltip';
import LoaderAnimation from '../AidLoader';

const DiscoverBackground = inject('dataStore')(
  observer((props) => {
    const navigate = useNavigate();

    const [opportunityCount, setOpportunityCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      fetchOpportunityCount();
    }, []);

    const fetchOpportunityCount = () => {
      setIsLoading(true);
      const params = {
        key: 'user_opportunities_estimate',
      };
      requestWithAuth(
        'network-summary-new',
        'POST',
        params,
        null,
        null,
        null,
      ).then((response) => {
        if (response && response.opportunities_estimate) {
          setOpportunityCount(response.opportunities_estimate);
          setIsLoading(false);
        }
        setIsLoading(false);
      });
    };

    const getStateData = (stateCode, getNameOnly) => {
      if (filterStates && filterStates.length && stateCode) {
        if (getNameOnly)
          return filterStates.find((obj) => obj.value === stateCode).text || '';
        else return filterStates.find((obj) => obj.value === stateCode) || {};
      }
    };

    const handleCountClick = () => {
      if (!opportunityCount) return;
      if (props.dataStore.currentUser && props.dataStore.currentUser.state) {
        props.dataStore.networkFilters.location_state = [
          getStateData(props.dataStore.currentUser.state, false),
        ];
      }
      props.dataStore.networkFilters.period = {
        label: 'Month',
        value: 'month',
      };
      props.dataStore.networkFilters.latest_mim_event_type = [
        {
          value: 'company was acquired',
          label: 'Company Acquired',
          text: 'Company Acquired',
        },
        {
          value: 'insider stock acquisition',
          label: 'Insider Stock Acquisition',
          text: 'Insider Stock Acquisition',
        },
        {
          value: 'insider stock sale',
          label: 'Insider Stock Sale',
          text: 'Insider Stock Sale',
        },
      ];

      props.dataStore.networkFilters.fetchData = true;
    };

    return (
      <div className="loader-wrapper">
        {isLoading && <LoaderAnimation loaderId="discover-bg-loader" />}
        <div
          className={
            isLoading
              ? 'new-opportunities-container aid-div-disable'
              : 'new-opportunities-container aid-div-enable'
          }
        >
          <div className="select-saved-indicator">
            <ArrowUpIcon className="arrow" />
            <div
              style={{
                fontSize: 14,
                fontWeight: 600,
                padding: 5,
                fontFamily: 'Poppins-SemiBold',
              }}
            >
              Select a Saved Search from the drop-down
            </div>
            <div style={{ fontSize: 12 }}>To see a list of prospects</div>
          </div>
          <div className="opportunities-indicator">
            <div className="glow-background"></div>
            <div className="opportunities-estimate">
              <div> OPPORTUNITIES </div>
              <div>
                with the most direct wealth triggers
                {props.dataStore.currentUser.state && (
                  <span>
                    {' '}
                    in{' '}
                    {getStateData(props.dataStore.currentUser.state, true) ||
                      ''}
                  </span>
                )}
                <span>
                  <AiTooltip
                    title="Most direct wealth triggers are a combination of company acquired, shares received/sold."
                    size="16px"
                  />
                </span>
              </div>
              <div className="count" onClick={handleCountClick}>
                <CountUp separator="," end={opportunityCount} duration={2.75} />
              </div>
              <div
                style={{ cursor: 'pointer', fontSize: '12px' }}
                onClick={() => {
                  navigate('/account/profile');
                }}
              >
                Your can update your default state in Profile Settings
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    );
  }),
);

export default DiscoverBackground;
