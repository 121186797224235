import '@gouch/to-title-case';

import { CheckCircleFilled } from '@ant-design/icons';
import { formatEventName } from '../../utils/general';
import { interests } from '../../utils/constants';

const Connectivity = (props) => {
  // mutual contacts with click - onclick open the mutual contact profile
  const getMutualContacts = (mutual) => {
    return mutual.map((contact, index) => {
      return (
        <span>
          <span
            className="link-connectivity"
            onClick={() => {
              props.openProfileTab(contact.b2b_id, contact.b2c_id);
            }}
          >
            {contact.full_name}
          </span>
          {index !== mutual.length - 1 && mutual.length > 1 ? ', ' : ''}
        </span>
      );
    });
  };

  // to seperate each array items with comma and "and" text
  const arrayToList = (array, arrayName) => {
    const eventsArray = [];
    if (arrayName === 'events') {
      array.map((event) => {
        if (event) {
          eventsArray.push(formatEventName(event));
        }
      });
      array = eventsArray;
    } else if (arrayName === 'interests') {
      const interestLabelArray = interests.filter((intrest) =>
        array.includes(intrest.value),
      );
      const interestArray = interestLabelArray.map((value) => value.label);
      array = interestArray;
    }

    return array
      .join(', ')
      .replace(/, ((?:.(?!, ))+)$/, ' and $1')
      .toTitleCase();
  };

  // object to store the keys to be dispalyed
  const displayListObject = {
    schools: 'You both studied at',
    org_previous: 'You both worked at',
    titles: 'You have shared similar titles:',
    industry: 'You have experience in similar industries:',
    events: 'You both participated in similar Triggers:',
    location_city: 'You both live in the same city:',
    interests: 'You both share common modeled interests:',
    mutual_contacts: 'You both know:',
    location_state: 'You both live in the same state:',
    work_city: 'You both worked in the same city:',
    weather_talking_point: 'Weather Update:',
  };

  return (
    <>
      <ul style={{ listStyleType: 'none', marginBottom: '0', paddingLeft: 0 }}>
        {props.list.map((item, index) => {
          return (
            <li>
              <span style={{ paddingRight: '10px' }}>
                <CheckCircleFilled
                  style={{ color: 'var(--color-golden-end)' }}
                />
              </span>

              <span className="text-data">
                {displayListObject[item[0]]}{' '}
                {item[0] === 'mutual_contacts' ? (
                  <span>{getMutualContacts(item[1])}</span>
                ) : item[0] === 'weather_talking_point' ? (
                  <span>{item[1]}</span>
                ) : (
                  <span>
                    {Array.isArray(item[1])
                      ? arrayToList(item[1], item[0])
                      : item[1]
                        ? item[1].toString().toTitleCase()
                        : ''}
                  </span>
                )}
                <br />
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Connectivity;
