import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';

import DeleteIcon from '../../assets/icons/ai_delete_filter.svg?react';

import { interests, attributes } from '../../utils/constants';
import { getFormatedFilterValues } from '../../utils/general';
import { showConfirm } from '../../utils/popup';

let titleRankList = [];

const FilterCard = inject('globalDataStore')(
  observer((props) => {
    const {
      from,
      data,
      listItem,
      makeDefaultApi,
      onEdit,
      onDelete,
      clearDefault,
    } = props;
    const {
      label,
      is_active,
      filters,
      value,
      email_alert,
      separate_email_alert,
      filter_type,
      created_at,
    } = listItem;

    const navigate = useNavigate();
    const flags = useFlags();

    const getUserAttributes = () => {
      if (flags && flags.neustarId)
        return attributes.filter(
          (obj) => obj.label !== 'Household' && obj.label !== 'Professional',
        );
      return attributes.filter(
        (obj) => obj.label !== 'Neustar ID' && obj.label !== 'Professional',
      );
    };

    const superConnectorClick = (label, id) => {
      props.globalDataStore.multipleFilters.fromFilters = true;
      props.globalDataStore.multipleFilters.selectedIdList = [
        { value: id, label },
      ];
      navigate('/relationship-intelligence/super-connectors');
    };

    const opportunitiesClick = (label, id) => {
      props.globalDataStore.multipleFilters.fromFilters = true;
      props.globalDataStore.multipleFilters.selectedIdList = [
        { value: id, label },
      ];
      navigate('/relationship-intelligence/opportunities');
    };

    const prospectFinderClick = (label, id) => {
      props.globalDataStore.multipleFilters.fromFilters = true;
      props.globalDataStore.multipleFilters.selectedIdList = [
        { value: id, label },
      ];
      navigate('/prospect-finder/people');
    };

    const handleDefaultClick = () => {
      if (isActive) {
        clearDefault();
        return;
      } else {
        makeDefaultApi(id, label);
        return;
      }
    };

    const handleDeleteClick = (id) => {
      showConfirm({
        className: 'small-popup',
        closable: true,
        onOk: () => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          data?.length > 1
            ? onDelete(id)
            : onEdit(
                filters,
                id,
                label,
                isActive,
                emailAlert,
                separateEmailAlert,
                filterType,
              );
        },
        title: 'Confirm Delete',
        okText:
          props.data && props.data.length > 1 ? 'Yes' : 'Edit this filter',
        cancelText: props.data && props.data.length > 1 ? 'No' : 'Cancel',
        content:
          props.data && props.data.length > 1 ? (
            <p>Are you sure you want to Delete?</p>
          ) : (
            <p style={{ padding: '24px' }}>
              A minimum of 1 Prospect Filter is required. Please create a new
              Prospect Filter prior to deleting this one or change the current
              attributes to meet your new requirements.
            </p>
          ),
      });
      return;
    };

    const userAttributes = getUserAttributes();
    const isActive = is_active;
    const id = value;
    const emailAlert = email_alert;
    const separateEmailAlert = separate_email_alert;
    const filterType = filter_type;
    const filterObj =
      filter_type && filter_type !== 'all'
        ? { ...filters, filter_type }
        : { ...filters };

    if (
      filterObj.org_name ||
      filterObj['current_experience.ai_org_id'] ||
      filterObj['exc_current_experience.ai_org_id']
    ) {
      const orgNames = [];
      if (filterObj.org_name) {
        orgNames.push(...filterObj.org_name);
      }
      if (filterObj['current_experience.ai_org_id']) {
        filterObj['current_experience.ai_org_id'].map((org) => {
          orgNames.push(org.name);
        });
      }
      if (filterObj['exc_current_experience.ai_org_id']) {
        filterObj['exc_current_experience.ai_org_id'].map((org) => {
          orgNames.push(org.name);
        });
      }
      delete filterObj['current_experience.ai_org_id'];
      delete filterObj['exc_current_experience.ai_org_id'];
      filterObj.org_name = orgNames;
    }

    /** if period and m2_date is present, show period only*/
    if (filterObj.period && filterObj.m2_date) {
      delete filterObj['m2_date'];
    }

    if (filterObj.home_postal_code && filterObj.home_postal_code.range) {
      const postalCode = {
        home_postal_code: filterObj.home_postal_code.range.home_postal_code,
        home_zip4:
          filterObj.home_zip4 && filterObj.home_zip4.range
            ? filterObj.home_zip4.range.home_zip4
            : null,
      };
      delete filterObj['home_postal_code'];
      delete filterObj['home_zip4'];
      filterObj.home_postal_code = postalCode;
    }

    if (filterObj === undefined || filterObj === null) return '';
    const filterValue = [];
    if (filterObj.is_pool_included) filterValue.push('Pool included');
    if (filterObj.is_elevator_included) filterValue.push('Elevator included');
    if (filterObj.is_age_included) filterValue.push('Age included');
    if (filterObj.is_norm_home_owner_included)
      filterValue.push('Home Owner included');
    if (filterObj.is_home_value_estimate_included)
      filterValue.push('Property Value included');
    if (filterObj.is_inferred_salary_included)
      filterValue.push('Inferred Income included');
    if (filterObj.is_geo_tax_investable_assets_included)
      filterValue.push('Total Investable Assets included');
    if (filterObj.is_b2b_id_included)
      filterValue.push('Career Information included');
    if (filterObj.is_remaining_share_value_sum_included)
      filterValue.push('Remaining Share Balance Segment(s) included');
    if (filterObj.has_wealth_triggers)
      filterValue.push('Wealth Triggers included');

    const listitems = Object.entries(filterObj).filter((value) => {
      return (
        value[0] &&
        value[1] &&
        ![
          'team_members',
          'date_filter',
          'latest_mim_date',
          'no_of_years',
          'slider_radius',
          'mapped_source_b2b_id',
          'mapped_source_b2c_id',
          'relmap_org_ui',
        ].includes(value[0])
      );
    });
    listitems.map((value) => {
      /** show only 3 items, and show +(n-3) more items */
      if (filterValue.length >= 4) return;
      if (value && value.length > 1 && value[0] && value[1] !== true) {
        filterValue.push(
          getFormatedFilterValues(
            value,
            titleRankList,
            props.dataStore.lookUpLists.fileImports,
            props.dataStore.lookUpLists.teamFileImports,
            props.dataStore.userTags,
            props.dataStore.lookUpLists.naicsList,
          ),
        );
      }
    });
    if (userAttributes && userAttributes.length > 0) {
      userAttributes.map((attribute) => {
        if (filters[attribute.value]) filterValue.push(attribute.label);
      });
    }
    if (filterObj.presence_of_children === true)
      filterValue.push('Presence of Children');
    else if (filterObj.presence_of_children === false)
      filterValue.push('Presence of Children : No');

    if (filterObj.all_homes) filterValue.push('All Home Search');
    /**
     * interest is available as splited like golf:true
     * so need to consolidate all interests
     * And the interest array will be added to @filterValue
     * only if @filterValue length is less than 4
     */
    const interestArray = [];
    if (interests && interests.length > 0) {
      interests.map((interest) => {
        if (filters[interest.value]) interestArray.push(interest.label);
      });
      if (interestArray.length > 0 && filterValue.length < 4)
        filterValue.push(interestArray.join(', '));
    }

    // const isTriggerApplied =
    //   (filterObj.latest_mim_event_type &&
    //     filterObj.latest_mim_event_type.length) ||
    //   (filterObj.funding_round && filterObj.funding_round.length > 0) ||
    //   (filterObj.latest_mim_monetary_value &&
    //     filterObj.latest_mim_monetary_value.key &&
    //     filterObj.latest_mim_monetary_value.value &&
    //     filterObj.latest_mim_monetary_value.value.length > 0) ||
    //   (filterObj.m2_date && filterObj.m2_date.length > 0) ||
    //   (filterObj.period && filterObj.period.value);

    return (
      <>
        <Card
          size="small"
          className="filter-card-class "
          title={
            <>
              <div className="card-title-parent">
                <div className="card-title-name-container">
                  {isActive && (
                    <span className="default-text"> Default Filter </span>
                  )}
                  <div className="card-title"> {label || ''} </div>
                </div>
                <div>
                  <DeleteIcon
                    className="delete-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteClick(id);
                    }}
                  />
                </div>
              </div>
            </>
          }
          style={{
            border: isActive
              ? '1px solid var(--color-primary)'
              : '1px solid var(--color-light-shade)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            onEdit(
              filters,
              id,
              label,
              isActive,
              emailAlert,
              separateEmailAlert,
              filterType,
            );
          }}
        >
          <div className="card-filters card-filters-list">
            <div className="card-body-text">{filterValue.join(', ')}</div>
            {from === 'List' && created_at && (
              <div className="date-text-list">
                {moment(created_at).format('MM/DD/YYYY')}
              </div>
            )}
          </div>
          <div className="make-default">
            <Button
              style={{
                borderColor: 'var(--color-link)',
                color: 'var(--color-link)',
                padding: '0px 8px',
                height: '27px',
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleDefaultClick();
              }}
            >
              {isActive ? 'Undo Default' : 'Make Default'}
            </Button>
          </div>
          <div className="footer-section">
            {created_at && (
              <div className="date-text">
                {moment(created_at).format('MM/DD/YYYY')}
              </div>
            )}
            <div className="footer-redirect-section">
              {from === 'List' && (
                <label style={{ color: 'var(--color-profile-labels)' }}>
                  View:{' '}
                </label>
              )}
              <label
                onClick={(e) => {
                  e.stopPropagation();
                  // if (!isTriggerApplied) return;
                  opportunitiesClick(label, id);
                }}
                style={{
                  // cursor: isTriggerApplied ? 'pointer' : 'not-allowed',
                  // color: isTriggerApplied
                  //   ? 'var(--color-link)'
                  //   : 'var(--color-low)',
                  cursor: 'pointer',
                  color: 'var(--color-link)',
                  padding: '0px 10px',
                  fontSize: '11px',
                }}
              >
                Opportunities
              </label>
              {'  '}
              <label
                onClick={(e) => {
                  e.stopPropagation();
                  superConnectorClick(label, id);
                }}
                style={{
                  cursor: 'pointer',
                  color: 'var(--color-link)',
                }}
              >
                Super Connectors
              </label>
              {'  '}
              <label
                onClick={(e) => {
                  e.stopPropagation();
                  prospectFinderClick(label, id);
                }}
                style={{
                  cursor: 'pointer',
                  color: 'var(--color-link)',
                  padding: '0px 10px',
                }}
              >
                Prospect Finder
              </label>
            </div>
          </div>
        </Card>
      </>
    );
  }),
);

const FilterListing = inject('dataStore')(
  observer((props) => {
    const { data, from } = props;
    titleRankList = props.dataStore.lookUpLists.titleRankList;
    return (
      <>
        <div
          className={
            from === 'List'
              ? 'filter-cards-section filter-list-section'
              : 'filter-cards-section'
          }
        >
          {data &&
            data.length > 0 &&
            data.map((listItem) => {
              return <FilterCard {...props} listItem={listItem} />;
            })}
        </div>
      </>
    );
  }),
);

export default FilterListing;
