import './index.scss';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { Modal, Popover, Radio, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import { formatEventDescription, commarize } from '../../utils/general';

import ConnectivityIcon from '../../assets/icons/ai_meter_signal.svg?react';
import Bell from '../../assets/icons/ai_bell.svg?react';
import Close from '../../assets/icons/ai_ic_cloce_circled.svg?react';
import {
  handleCrmIconsAfterIntegration,
  handleCrmLabels,
  handleCrmSync,
} from '../../utils/crmMethods';
import { showConfirm } from '../../utils/popup';
import Tags from '../Tags';
import SelectClubs from '../SelectClubs';

const OpportunityCard = inject('dataStore')(
  observer((props) => {
    const [leadIds, setLeadIds] = useState({});
    const [crmOk, setCrmOk] = useState(false);
    const [clubCode, setClubCode] = useState();

    const {
      data,
      // event_name,
      // event_index,
      showProfile,
      user,
      toggleProgress,
      openUserFilter,
      showCompanyProfile,
      addReminder,
    } = props;
    const navigate = useNavigate();
    const { confirm } = Modal;
    const modalRef = useRef(null);
    const [isRemindMeShowing, setIsRemindMeShowing] = useState(false);

    useEffect(() => {
      if (crmOk) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        props.dataStore.currentUser.creditCount >= 1 ||
        props.dataStore.currentUser.isAdmin
          ? handleCrmPopupOk(clubCode)
          : handleSubscribeRedirect();
      }
    }, [crmOk]);

    // Method to handle show popup
    const onCrmClick = (b2b_id, b2c_id) => {
      if (!user.crm) {
        navigate('/connect/export');
        return;
      }
      setLeadIds({ b2b_id, b2c_id });

      if (user.crm === 'sf' && props.dataStore.currentUser.org_name === 'INV')
        clubModel();
      else exportPopUp();
    };

    // Method to handle yes click of export csv
    const handleCrmPopupOk = (clubCode) => {
      toggleProgress(true);
      const body = {};
      const person = [];
      if (leadIds && leadIds.b2b_id) person.push(leadIds.b2b_id);
      if (leadIds && leadIds.b2c_id) person.push(leadIds.b2c_id);
      body.n2_person = person;

      if (props.dataStore.currentUser.org_name)
        body.org_name = props.dataStore.currentUser.org_name;

      if (clubCode) body.club_code = clubCode.value;

      handleCrmSync(
        props.dataStore.currentUser.ownerId,
        user.crm,
        body,
        onCrmTokenExpiry,
        'progress_opportunities',
        props.dataStore.networkFilters,
      ).then(() => {
        // if (!props.dataStore.currentUser.isAdmin) fetchUserTypeData();
        setLeadIds({});
        toggleProgress(false);
      });
    };

    const onCrmTokenExpiry = (url) => {
      navigate('/connect/export', {
        state: {
          crmSource: url === 'redtail-auth/0' ? 'rt' : 'cm',
          response: url === 'redtail-auth/0' ? 'rt' : 'cm',
        },
      });
    };

    const handleSubscribeRedirect = () => {
      navigate('/account/credits');
    };

    const clubModel = () => {
      modalRef.current = confirm({
        icon: null,
        okButtonProps: {
          disabled: true,
        },
        onOk() {
          exportPopUp();
          modalRef.current.destroy();
        },
        onCancel() {
          setClubCode();
          if (modalRef && modalRef.current) modalRef.current.destroy();
        },
        className: 'club-popUp',
        title: 'Select Club/Location',
        content: (
          <SelectClubs
            setClubVal={(val) => setClubCode(val)}
            modalRef={modalRef}
          />
        ),
      });
    };

    const exportPopUp = () => {
      return showConfirm({
        className: 'small-popup',
        closable: true,
        onOk: () => {
          setCrmOk(true);
        },
        title: 'Confirm Export',
        content: (
          <div style={{ textAlign: 'center' }}>
            You selected 1 profile to export
            <br />
            {props.dataStore.currentUser.creditCount >= 1 ||
            props.dataStore.currentUser.isAdmin
              ? 'Please confirm'
              : 'You require an additional 1 credit to proceed'}
          </div>
        ),
        okText:
          props.dataStore.currentUser.creditCount >= 1 ||
          props.dataStore.currentUser.isAdmin
            ? 'Yes'
            : 'Buy More',
      });
    };

    const getGender = (gender) => {
      const g = gender.toLowerCase();
      if (g === 'm') return 'Male';
      else if (g === 'f') return 'Female';

      return g.toTitleCase();
    };

    const getEventDescription = (event) => {
      const description = event.event_description;
      switch (event.event_type) {
        case 'insider stock sale':
          return `Raised $${commarize(
            parseFloat(event.event_monetary_value || 0),
          )}`;
        default:
          return formatEventDescription(description, event.event_type);
      }
    };

    return (
      <div className="opportunity-card-wrapper">
        {data && data.degree && data.degree > 0 ? (
          <div className="degree">{data.degree === 1 ? '1st' : '2nd'}</div>
        ) : (
          <></>
        )}
        <div className="title-bar">
          <div className="title-bar-left">
            <span
              className="title-bar-item full-name"
              onClick={() => {
                showProfile(data.b2b_id, data.b2c_id);
              }}
            >
              {data.full_name || ''}
            </span>

            <span className="title-bar-item">
              <Tags
                height={16}
                fromRow
                selectedProfileIds={[
                  {
                    b2b_id: data.b2b_id,
                    b2c_id: data.b2c_id || '',
                  },
                ]}
              />
            </span>
            <Tooltip placement="bottom" title={handleCrmLabels(user.crm)}>
              <span className="title-bar-item">
                <img
                  src={handleCrmIconsAfterIntegration(user.crm)}
                  alt={handleCrmLabels(user.crm)}
                  width={16}
                  height={16}
                  onClick={(e) => {
                    e.stopPropagation();
                    onCrmClick(data.b2b_id, data.b2c_id);
                  }}
                  className="cursor-pointer"
                />
              </span>
            </Tooltip>
          </div>
          <div className="title-bar-right">
            {data.connectivity && (
              <ConnectivityIcon
                className={
                  data.connectivity === 'high'
                    ? 'connectivity-icon-high'
                    : data.connectivity === 'medium'
                      ? 'connectivity-icon-medium'
                      : 'connectivity-icon-low'
                }
              />
            )}
            {data.mutual_count && data.mutual_count > 0 ? (
              <span>
                {data.mutual_count === 1
                  ? `1 Introducer`
                  : `${data.mutual_count}${' '}Introducers`}
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="card-body">
          <div>
            {(data.title || data.org_name) && (
              <div className="card-content" style={{ marginBottom: '14px' }}>
                {data.title && (
                  <>
                    <div className="card-labels">Title</div>
                  </>
                )}
                <div>
                  {data.title ? data.title.toString().toTitleCase() : ''}{' '}
                  {data.org_name && (
                    <>
                      {data.title && <>@&nbsp;</>}
                      <span
                        className={
                          !data || !data.ai_org_id ? '' : 'cursor-pointer'
                        }
                        onClick={() => {
                          if (!data || !data.ai_org_id) return;
                          showCompanyProfile(data.ai_org_id, data.org_name);
                        }}
                      >
                        {data.org_name.toString().toTitleCase()}
                      </span>{' '}
                    </>
                  )}
                </div>
              </div>
            )}
            {data &&
              (data.gender ||
                data.gender ||
                data.home_city ||
                data.home_state) && (
                <div style={{ marginBottom: '14px' }}>
                  <div className="card-content">
                    {data && data.gender && (
                      <>
                        <span className="card-labels">Sex</span>
                        <span>{getGender(data.gender)}</span>
                      </>
                    )}
                    {data && data.age && (
                      <>
                        <span className="card-labels">Age</span>
                        <span>{data.age}</span>
                      </>
                    )}
                  </div>
                  <div className="card-content">
                    <span className="card-labels"></span>
                    {data.home_city
                      ? data.home_city.toString().toLowerCase().toTitleCase()
                      : ''}
                    {data.home_city && data.home_state && <>, </>}
                    {data.home_state || ''}
                  </div>
                </div>
              )}
          </div>
          <div className="org-logo">
            {data && data.logo_url && (
              <img src={data.logo_url} alt={data.org_name || ''} />
            )}
          </div>
        </div>
        <div className="description">{getEventDescription(data.events)}</div>
        <div className="card-footer">
          <Tooltip placement="bottomRight" title={'Set Reminder'}>
            <div
              className="reminder-button"
              onClick={() => {
                setIsRemindMeShowing(true);
              }}
            >
              <Bell fill="var(--color-primary)" />
              <span className="reminder-text">Remind Me</span>
            </div>
          </Tooltip>
          <div
            className={
              isRemindMeShowing
                ? 'reminder-swipe active-reminder'
                : 'reminder-swipe'
            }
          >
            <Radio.Group
              onChange={(e) => {
                addReminder(true, e.target.value, data.b2b_id, data.b2c_id);
                setIsRemindMeShowing(false);
              }}
              value=""
              buttonStyle="solid"
            >
              <Radio.Button value={1}>
                <span>Tomorrow</span>
              </Radio.Button>
              <Radio.Button value={3}>
                <span> 3 days</span>
              </Radio.Button>
              <Radio.Button value={7}>
                <span> 1 week</span>
              </Radio.Button>
              <Radio.Button value={14}>
                <span>2 weeks</span>
              </Radio.Button>
            </Radio.Group>
            <Close
              height={20}
              style={{ color: '#959AA7' }}
              onClick={() => {
                setIsRemindMeShowing(false);
              }}
            />
          </div>

          <div
            className="footer-right"
            style={{
              // display: isRemindMeShowing ? 'none' : 'flex',
              opacity: isRemindMeShowing ? 0 : 1,
            }}
          >
            {data && data.events && data.events.event_date && (
              <div className="date">
                {' '}
                Trigger date{' '}
                {moment(data.events.event_date).format('MM/DD/YYYY')}
              </div>
            )}
            {data &&
              data.filter_list &&
              data.filter_list.length > 0 &&
              data.filter_list[0].id !== -1 && (
                <>
                  <div className="flex-align-with-gap cursor-pointer">
                    <div className="filter-box">
                      {data.filter_list[0].name || ''}
                    </div>

                    {data.filter_list.length > 1 && (
                      <Popover
                        placement="bottom"
                        title="Other triggered Saved Search(s)"
                        rootClassName="opportunity-filter-list"
                        // style={{
                        //   maxWidth: '250px',
                        //   overflowWrap: 'break-word',
                        // }}
                        content={
                          <div>
                            {data.filter_list.map((filter, index) => {
                              return (
                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    openUserFilter(filter.id);
                                  }}
                                >
                                  {index === 0 ? (
                                    <></>
                                  ) : (
                                    <div className="filter-box">
                                      {filter.name}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        }
                      >
                        <span style={{ color: 'var(--color-primary)' }}>
                          {' '}
                          &nbsp; +{data.filter_list.length - 1}
                        </span>
                      </Popover>
                    )}
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    );
  }),
);

export default OpportunityCard;
