import './index.scss';
import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Input } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';

import PlusIcon from '../../assets/icons/ai_plus.svg?react';
import { requestWithAuth } from '../../services/api';

const CreateTagElement = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const { isFolder, onCreation, setProgress } = props;
    const [showTagInput, setShowTagInput] = useState(false);
    const [existingTagError, setExistingTagError] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [showWarning, setShowWarning] = useState(false);

    const onInputChange = (value) => {
      if (isFolder) createFolder(value);
      else createTag(value);
    };

    const folderErrorTimeout = () => {
      setTimeout(() => {
        setShowWarning(false);
        // setInputValue(initialValue);
      }, 3000);
    };

    function myStopFunction() {
      clearTimeout(folderErrorTimeout);
    }

    const createFolder = (folderName) => {
      if (setProgress) setProgress(true);
      if (!folderName || folderName === 'Folder name already exists') {
        if (setProgress) setProgress(false);
        return;
      }

      const body = {};
      body.tag_folder_name = folderName;
      requestWithAuth('tag-folder', 'POST', null, body, null).then(
        (response) => {
          if (response && response.code === 200) {
            onCreation();
            setShowTagInput(false);
          } else if (
            response &&
            response.response &&
            response.response.data &&
            response.response.data.detail &&
            response.response.data.detail === 'Tag Folder name already exists'
          ) {
            setExistingTagError(true);
            setTimeout(() => {
              setExistingTagError(false);
              setShowTagInput(false);
            }, 3000);
          }
          setInputValue('');
          if (setProgress) setProgress(false);
          return;
        },
      );
    };

    const createTag = (tagName) => {
      if (!tagName || tagName === 'Tag name already exists') return;
      const body = {};
      body.tag = tagName;
      requestWithAuth('tags', 'POST', null, body, null).then((response) => {
        if (response && response.code === 200 && response.tag) {
          const tagObj = { ...response.tag, owner_id: response.tag.owner };
          delete tagObj.owner;
          onCreation(tagObj);
          setShowTagInput(false);
        } else if (
          response &&
          response.response &&
          response.response.data &&
          response.response.data.detail &&
          response.response.data.detail === 'Tag name already exists'
        ) {
          setExistingTagError(true);
          setTimeout(() => {
            setExistingTagError(false);
            setShowTagInput(false);
          }, 3000);
        }
        setInputValue('');
        return;
      });
    };

    return (
      <>
        <div className="create-tag-element">
          {showTagInput ? (
            <Input
              prefix={existingTagError ? <CloseCircleFilled /> : <></>}
              value={
                existingTagError
                  ? `${isFolder ? 'Folder' : 'Tag'} name already exists`
                  : inputValue
              }
              autoFocus
              placeholder={`Enter ${isFolder ? 'folder' : 'tag'} name`}
              onChange={(e) => setInputValue(e.target.value)}
              className={
                existingTagError ? 'input-error new-tag-input' : 'new-tag-input'
              }
              onBlur={(e) => {
                if (e.target.value && !existingTagError) {
                  setShowWarning(true);
                  folderErrorTimeout();
                }
              }}
              onPressEnter={(event) => {
                // event.stopPropagation();
                // event.preventDefault();
                // const folderInput = document.getElementById(`folder-${id}`);
                // if (folderInput) folderInput.blur();
                myStopFunction();
                setShowWarning(false);
                onInputChange(event.target.value);
                // setShowTagInput(false)
              }}
            />
          ) : (
            <div className="add-new" onClick={() => setShowTagInput(true)}>
              <PlusIcon />
              <span>{`Add new ${isFolder ? 'folder' : 'tag'}`}</span>
            </div>
          )}
          {showWarning && (
            <div className="input-warning">Press Enter to update name!</div>
          )}
        </div>
      </>
    );
  }),
);

export default CreateTagElement;
