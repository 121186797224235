import './index.scss';

import { message } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { inject } from 'mobx-react';
import { requestWithAuth, updateUserInfo } from '../../services/api';

import LoftyIcon from '../../assets/icons/ai_lofty.svg';
import CrmForm from '../CrmForm';
import LoaderAnimation from '../AidLoader';
import { AboutText, IntegratedView } from './exportCRMSection';

message.config({
  top: 80,
});

let source;

const ChimeView = inject('dataStore')((props) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const { CancelToken } = axios;
    source = CancelToken.source();
    return () => {
      message.destroy();
      if (source) source.cancel('Operation canceled by the user.');
    };
  }, []);

  const handleSubmit = (values) => {
    // e.preventDefault();
    const { onSuccessIntegration } = props;

    // form.validateFields({ force: true }, (err, values) => {
    const { userName } = values;
    const { password } = values;

    const body = {
      user_name: userName,
      password,
    };

    // if (!err) {
    setIsLoading(true);
    requestWithAuth('chime-token', 'POST', null, body, source.token).then(
      (response) => {
        if (!response) return;
        if (response !== undefined) {
          if (response.code === 200) {
            requestWithAuth('chime-auth', 'GET', null, null, source.token).then(
              (resp) => {
                if (resp !== undefined) {
                  if (resp.code === 200) {
                    const paramsChime = {
                      crm: 'cm',
                    };
                    updateUserInfo(
                      props.dataStore.currentUser.ownerId,
                      paramsChime,
                    )
                      .then(() => {
                        setIsLoading(false);
                        onSuccessIntegration();
                      })
                      .catch((error) => {
                        console.log(error);
                        setIsLoading(false);
                      });
                  } else {
                    message.error(resp.errorCode, 10);
                    setIsLoading(false);
                  }
                } else {
                  message.error('Please try again', 5);
                  setIsLoading(false);
                }
              },
            );
          } else {
            message.error(response.errorCode, 10);
            setIsLoading(false);
          }
        } else {
          message.error('Please try again', 5);
          setIsLoading(false);
        }
      },
    );
    // }
    // });
  };

  const { submitting, crmValue, removeIntegration } = props;

  return (
    <div className="loader-wrapper">
      {isLoading && (
        <LoaderAnimation
          loaderId="chime-view-loader"
          style={{ top: '100px' }}
        />
      )}
      <div className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
        {crmValue !== 'cm' && (
          <div className="main-section">
            <div className="left-section">
              <div className="export-data-heading">Export</div>
              <div className="crm-form">
                <CrmForm
                  formLayout="vertical"
                  inputSize="default"
                  showLabels
                  submitting={submitting}
                  onFinish={handleSubmit}
                  keys={{
                    idLabel: 'Username',
                    idKey: 'userName',
                    passwordLabel: 'Password',
                    passwordKey: 'password',
                  }}
                  disable={crmValue !== '' && crmValue !== 'cm'}
                />
              </div>
            </div>
            <div className="right-section">
              <AboutText
                crm_name="Lofty"
                crm_link="http://lofty.com"
                crm_url="Lofty.com"
                crm_icon={LoftyIcon}
              />
            </div>
          </div>
        )}

        {crmValue === 'cm' && (
          <div className="main-section">
            <div className="left-section">
              <IntegratedView crm_name="Lofty" onRemove={removeIntegration} />
            </div>
            <div className="right-section">
              {' '}
              <AboutText
                crm_name="Lofty"
                crm_link="http://lofty.com"
                crm_url="Lofty.com"
                crm_icon={LoftyIcon}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default ChimeView;
