import './index.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, message } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import DeleteIcon from '../../assets/icons/ai_delete_filter.svg?react';

import { requestWithAuth } from '../../services/api';
import { showConfirm } from '../../utils/popup';
import { splitNumberWithCommas } from '../../utils/general';
import WarningMessage from '../../components/WarningMessage';
import LoaderAnimation from '../../components/AidLoader';

let credit;

message.config({
  top: 80,
});

const Notifications = inject(
  'globalDataStore',
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const [notificationData, setNotificationData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    credit = props.dataStore.currentUser.creditCount;

    const downloadCSVFile = (id, name) => {
      setIsLoading(true);
      const body = { export_id: parseInt(id) };
      requestWithAuth('download-csv-url', 'POST', null, body, null).then(
        (response) => {
          setIsLoading(false);
          if (!response) return;
          if (response.code === 200) {
            const link = document.createElement('a');
            link.href = response.url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
            link.remove();
          } else if (response.code === 400) {
            if (response.charge_credits && response.user_credits) {
              showConfirm({
                className: 'small-popup',
                onOk: handleSubscribeRedirect,
                title: 'Confirm',
                content: (
                  <>
                    This export requires {response.charge_credits} credits,
                    which exceeds your current balance of{' '}
                    {response.user_credits}{' '}
                    {response.user_credits === 1 ? 'credit' : 'credits'}. Please
                    contact Customer Support or purchase additional credits{' '}
                    <a href="/account/credits">here.</a>{' '}
                  </>
                ),
                closable: true,
                okText: 'Buy More',
              });
            }
          }
          fetchNotification();
        },
      );
    };

    const creditCheck = (id, name, credit_charged) => {
      /*** if credit charged is greater than 0 and user is not admin then show the pop up***/
      if (
        credit_charged &&
        credit_charged !== 0 &&
        props.dataStore.currentUser.type !== 'admin'
      ) {
        showCreditPopUp(id, name, credit_charged);
      } else downloadCSVFile(id, name);
    };

    const showCreditPopUp = (id, name, creditCharged) => {
      let confirmText;
      if (creditCharged && credit >= creditCharged) {
        confirmText = (
          <div>
            To download this file, {splitNumberWithCommas(creditCharged)}{' '}
            {creditCharged === 1 ? ' credit ' : ' credits '} will be deducted
            from your account. <br />
            Current Credit Balance: {credit}
          </div>
        );
        showConfirm({
          className: 'small-popup',
          onOk: () => downloadCSVFile(id, name),
          title: 'Confirm',
          content: confirmText,
          closable: true,
          okText: 'Proceed',
        });
      } else {
        confirmText = (
          <div>
            To download this file, {splitNumberWithCommas(creditCharged)}
            {creditCharged === 1 ? ' credit is ' : ' credits are '}
            required. <br />
            Current Credit Balance: {credit}
          </div>
        );
        showConfirm({
          className: 'small-popup',
          onOk: handleSubscribeRedirect,
          title: 'Confirm',
          content: confirmText,
          closable: true,
          okText: 'Buy More',
        });
      }
    };

    const handleSubscribeRedirect = () => {
      navigate('/account/credits');
    };

    useEffect(() => {
      if (
        props.dataStore.currentUser.type !== 'lite' &&
        props.dataStore.currentUser.type !== 'inactive'
      )
        fetchNotification();

      return () => {
        props.globalDataStore.notificationCount = 0;
        message.destroy();
      };
    }, []);

    const fetchNotification = () => {
      requestWithAuth(
        'notifications',
        'GET',
        null,
        null,
        null,
        'loading-notifications',
      ).then((response) => {
        setIsLoading(false);
        if (!response || !response.results) return;
        if (response.unseen_count !== undefined)
          props.globalDataStore.notificationCount = response.unseen_count;

        setNotificationData(response.results);
      });
    };

    const getTimeDifference = (date) => {
      const duration = moment.duration(moment().diff(date));
      const seconds = parseInt(duration.asSeconds());
      if (seconds < 60) return `${seconds}s`;
      const minutes = parseInt(duration.asMinutes());
      if (minutes < 60) return `${minutes}mins`;

      const hours = parseInt(duration.asHours());
      if (hours <= 24) return `${hours}h`;
      else {
        const days = parseInt(duration.asDays());
        if (days <= 7) return `${days}d`;
        else {
          const weeks = parseInt(duration.asWeeks());
          if (weeks <= 4) return `${weeks}w`;
          else {
            const months = parseInt(duration.asMonths());
            if (months <= 4) return `${months}m`;
            else {
              const years = parseInt(duration.asYears());
              if (years <= 4) return `${years}y`;
            }
          }
        }
      }
    };

    const onDeleteNotification = (id) => {
      if (!id) return;

      setIsLoading(true);
      const body = {
        id_list: [id],
      };
      requestWithAuth(
        'notification-delete',
        'POST',
        null,
        body,
        null,
        'loading-notification',
      ).then((response) => {
        setIsLoading(false);
        if (response && response.code === 200 && response.message === 'ok') {
          message.success('Notification Deleted', 5);
          setNotificationData(
            notificationData.filter((data) => data.id !== id),
          );
        } else if (response && response.errorCode) {
          message.error(response.errorCode, 5);
        } else {
          message.info('Please try after some time', 5);
        }
      });
    };

    const getFormattedDescription = (item) => {
      if (!item) return '';
      let desc = item.description;
      const { b2b_id, b2c_id } = item;

      switch (item.notification_type) {
        case 'opportunities': {
          const date = item.created;
          const duration = moment.duration(moment().diff(date));
          const days = parseInt(duration.asDays());
          if (days >= 1) {
            desc = desc.replaceAll(
              'today',
              `on ${moment(date).format('MM/DD/YYYY')}`,
            );
          }
          if (desc.includes('--')) {
            desc = desc.replaceAll('--', ':');
          }
          return (
            <>
              {desc} <a>{' - '}View Opportunities</a>
            </>
          );
        }
        case 'admin': {
          //replacing quoted text to italics
          const replaced = desc.replace(
            /'(.*?)'/g,
            (match, p1) => `<i>"${p1}"</i>&nbsp;`,
          );
          const path = `/prospect-finder/people?b2b_id=${b2b_id || ''}&b2c_id=${
            b2c_id || ''
          }`;
          return (
            <div>
              <span dangerouslySetInnerHTML={{ __html: replaced }} />
              {' -'}&nbsp;
              <a href={path}>View Profile</a>
            </div>
          );
        }
        case 'import':
        case 'export':
        default:
          return desc
            .toString()
            .replaceAll('csv', 'CSV')
            .replaceAll('linkedin', 'LinkedIn')
            .replaceAll('.', '');
      }
    };

    const onClickItemRedirection = (item) => {
      if (!item) return '';
      switch (item.notification_type) {
        case 'opportunities': {
          let filterName = '';
          if (item.description.includes('--')) {
            filterName = item.description.split('--')[1] || '';
          }
          navigate(
            `/prospect-finder/people?daily_opportunities_id=${
              item.event_id
            }&daily_opportunities_date=${moment(item.created).format(
              'YYYY-MM-DD',
            )}&filter_name=${filterName}`,
          );
          break;
        }
        case 'ri_count':
          navigate('/relationship-intelligence/dashboard');
          break;
        case 'import':
        case 'export':
          if (item.b2b_id || item.b2c_id) {
            /** if import and b2bid or b2cid, case is add or remove to network, redirection to profile*/
            navigate(
              `/prospect-finder/people?b2b_id=${item.b2b_id || ''}&b2c_id=${
                item.b2c_id || ''
              }`,
            );
            break;
          }
          navigate(`/connect/status`, {
            state: {
              expandStatus: true,
            },
          });
          break;
        case 'linkedin re-import':
          navigate(`/connect/import`, {
            state: {
              fromSource: 'LinkedIn',
            },
          });
          break;
        case 'admin':
          navigate(
            `/prospect-finder/people?b2b_id=${item.b2b_id || ''}&b2c_id=${
              item.b2c_id || ''
            }`,
          );
          break;
        default:
          break;
      }
    };

    if (props.dataStore.currentUser.type === 'lite') {
      return (
        <WarningMessage
          iconName="upgrade"
          content="To view executives, upgrade today!"
          buttonText="Subscribe"
        />
      );
    }
    if (props.dataStore.currentUser.type === 'inactive') {
      return (
        <WarningMessage
          iconName="upgrade"
          content="To view executives, upgrade today!"
          buttonText="Subscribe"
        />
      );
    }

    return (
      <div className="notification-container">
        {props.globalDataStore.notificationCount > 0 && (
          <div className="notification-title">
            {`${props.globalDataStore.notificationCount} new notification${
              props.globalDataStore.notificationCount > 1 ? 's' : ''
            }:`}
            {/* <div className="mark-all-read">Mark all read</div> */}
          </div>
        )}
        <div className="loader-wrapper">
          {isLoading && (
            <div id="" data-testid="loading-notification">
              <LoaderAnimation loaderId="notifications-loader" />
            </div>
          )}
          <div className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
            <List
              dataSource={notificationData}
              // bordered
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  onClick={() => onClickItemRedirection(item)}
                >
                  <List.Item.Meta
                    avatar={item.is_seen ? '' : <div className="bullet" />}
                    title={getFormattedDescription(item)}
                    // description={item.email}
                  />
                  <div className="notification-right-section">
                    {item.notification_type &&
                      item.notification_type === 'export' && (
                        <div
                          className="download-link"
                          onClick={(e) => {
                            e.stopPropagation();
                            creditCheck(
                              item.event_id,
                              item.name,
                              item.charge_credits,
                            );
                          }}
                        >
                          Download CSV File
                        </div>
                      )}
                    {item.notification_type &&
                      item.notification_type === 'import' &&
                      (item.b2b_id || item.b2c_id) && (
                        <div className="download-link">View profile</div>
                      )}
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        onDeleteNotification(item.id);
                      }}
                    >
                      <DeleteIcon className="delete-icon" />
                    </div>
                    <div>{getTimeDifference(item.created)}</div>
                  </div>
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>
    );
  }),
);

export default Notifications;
