import './index.css';
import Select from 'react-select';
import { inject, observer } from 'mobx-react';

const AdvancedSelect = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const { options, handleOnChange, selectedValue } = props;
    const formatOptionLabel = ({ value, label }, { context }) => {
      if (context === 'value') {
        if (value === 'all_selected') return <div>{label}</div>;
        else return <div>{value.name}</div>;
      } else if (context === 'menu') {
        if (
          value.type &&
          ['team_file_imports', 'file_imports'].includes(value.type)
        )
          return (
            <div
              style={{
                maxHeight: '76px',
                padding: '8px',
                borderBottom: '1px solid var(--color-low)',
              }}
            >
              {label}
            </div>
          );
        else
          return (
            <div
              style={{
                maxHeight: '35px',
                padding: '8px',
              }}
            >
              {label}
            </div>
          );
      }
    };

    return (
      <div>
        <Select
          className="csv-imports-dropdown"
          classNamePrefix="select"
          isSearchable={false}
          name="color"
          options={options}
          isOptionSelected={(option, selected) => {
            if (option && option.value && option.value.name)
              return option.value.name === selected[0].value.name;
          }}
          isOptionDisabled={(option) => option.isDisabled}
          onChange={(value) => handleOnChange(value)}
          formatOptionLabel={formatOptionLabel}
          value={selectedValue}
          defaultValue={options[0]}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
    );
  }),
);

export default AdvancedSelect;
