import './index.scss';
import '../../antdStyles.scss';

import { Button, Modal } from 'antd';

function PopUp(props) {
  const {
    title = 'Confirm Export', // to be changed
    visible = true,
    onOk,
    onCancel,
    exportCsvText,
    exportCsvTextProceed,
    textButton,
    width,
  } = props;
  return (
    <Modal
      title={title}
      maskStyle={{ opacity: '0.05' }}
      maskTransitionName="none"
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      className="modal-export"
      width={width}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onOk}>
          {textButton}
        </Button>,
      ]}
    >
      <div className="export-modal-content">
        <div className="export-message">
          <span>{exportCsvText}</span>
        </div>
        <div className="export-message">
          <span>{exportCsvTextProceed}</span>
        </div>
      </div>
    </Modal>
  );
}
export default PopUp;
