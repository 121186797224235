import { DropTarget } from 'react-drag-drop-container';
import { useFlags } from 'launchdarkly-react-client-sdk';

import WarningMessage from '../WarningMessage';
import DraggableGridItem from './DraggableGridItem';

const DraggableGridBox = (props) => {
  const { targetKey, render, selectedItems, handleDrop, swap, emptyWarning } =
    props;

  const flags = useFlags();
  const newDashboard = flags && flags.dasboard2024;

  const wideComponents = ['NetworkDNA', 'WeeklyEngagement'];

  if (newDashboard) {
    wideComponents.unshift('TriggersOverview', 'WealthTriggerOpportunities');
  }

  return (
    <div>
      <DropTarget
        onHit={(e) => handleDrop(e.dragData.label)}
        targetKey={targetKey}
      >
        <DropTarget targetKey="DraggableGridItem">
          {!emptyWarning ? (
            <div className="draggable-grid-box">
              {selectedItems.map((item, index) => {
                return (
                  <div
                    id={`${item}-dashboard-item`}
                    style={{
                      gridColumnStart: wideComponents.includes(item) ? 1 : '',
                      gridColumnEnd: wideComponents.includes(item) ? 3 : '',
                    }}
                  >
                    <DraggableGridItem
                      index={index}
                      swap={swap}
                      render={render}
                      item={item}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="empty-view">
              <WarningMessage content="Select / Drag and Drop Items in the Edit View to Explore Dashboard." />
            </div>
          )}
        </DropTarget>
      </DropTarget>
    </div>
  );
};

export default DraggableGridBox;
