import './index.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseCircleFilled } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { requestWithAuth } from '../../services/api';

const compareLabels = (a, b) => {
  const labelA = a.label.toUpperCase(); // ignore upper and lowercase
  const labelB = b.label.toUpperCase(); // ignore upper and lowercase
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  // labels must be equal
  return 0;
};

const GlobalNotificationBar = inject(
  'globalDataStore',
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const [notificationData, setNotificationData] = useState(null);
    const [showNotification, setShowNotification] = useState(true);

    const navigate = useNavigate();
    const flags = useFlags();

    useEffect(() => {
      /** call api on first render */
      fetchNotification();

      /** creating an interval to send API requests in every 2 mins.
       * clearing the interval on unmount
       */
      const intervalID = setInterval(() => {
        if (
          props.dataStore.currentUser.type !== 'lite' &&
          props.dataStore.currentUser.type !== 'inactive'
        )
          fetchNotification();
      }, 120000);

      return () => {
        clearInterval(intervalID);
      };
    }, []);

    const fetchFileImports = () => {
      requestWithAuth(
        'field-lookup',
        'GET',
        { key: 'file_imports' },
        null,
        null,
      )
        .then((response) => {
          if (response && response.result && response.result.length) {
            const filesWithoutEnrich = [];
            props.dataStore.lookUpLists.fileImports = response.result.map(
              (file) => {
                if (file && !file.data_enrichment_only) {
                  filesWithoutEnrich.push({
                    label: file.import_name,
                    value: file.id,
                    isEnrich: file.data_enrichment_only,
                  });
                }
                return {
                  label: file.import_name,
                  value: file.id,
                  isEnrich: file.data_enrichment_only,
                };
              },
            );
            props.dataStore.lookUpLists.fileImportsWithoutEnrich =
              filesWithoutEnrich;
          }

          props.dataStore.lookUpLists.fileImports.sort(compareLabels);
          props.dataStore.lookUpLists.fileImportsWithoutEnrich.sort(
            compareLabels,
          );

          if (props.dataStore.networkFilters.filter_type !== 'all') {
            if (!props.dataStore.importSourceKeys.isImportSourceSaved) {
              props.dataStore.networkFilters.file_imports =
                props.dataStore.lookUpLists.fileImportsWithoutEnrich.map(
                  (item) => {
                    if (item.value) return item.value;
                  },
                );
            } else {
              const files = [];
              props.dataStore.lookUpLists.fileImportsWithoutEnrich.map(
                (file) => {
                  if (
                    file &&
                    file.value &&
                    props.dataStore.networkFilters.file_imports.includes(
                      file.value,
                    )
                  )
                    files.push(file.value);
                },
              );
              props.dataStore.networkFilters.file_imports = files;
            }
            props.dataStore.networkFilters.fetchData = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const fetchSharedFileImports = () => {
      requestWithAuth(
        'field-lookup',
        'GET',
        { key: 'team_file_imports' },
        null,
        null,
      )
        .then((response) => {
          if (response && response.result && response.result.length) {
            const filesWithoutEnrich = [];
            const addImportsToTeam = [];
            props.dataStore.lookUpLists.teamFileImports = response.result.map(
              (file) => {
                if (file && file.add_import_profiles_to_team) {
                  addImportsToTeam.push({
                    label: file.import_name,
                    value: file.id,
                    isEnrich: file.data_enrichment_only,
                    owner: file.owner_name,
                    ownerId: file.owner_id,
                    b2bId: file.owner_b2b_id,
                    b2cId: file.owner_b2c_id,
                  });
                }
                if (file && !file.data_enrichment_only) {
                  filesWithoutEnrich.push({
                    label: file.import_name,
                    value: file.id,
                    isEnrich: file.data_enrichment_only,
                    owner: file.owner_name,
                    ownerId: file.owner_id,
                    b2bId: file.owner_b2b_id,
                    b2cId: file.owner_b2c_id,
                  });
                }
                return {
                  label: file.import_name,
                  value: file.id,
                  isEnrich: file.data_enrichment_only,
                  owner: file.owner_name,
                  ownerId: file.owner_id,
                  b2bId: file.owner_b2b_id,
                  b2cId: file.owner_b2c_id,
                };
              },
            );
            props.dataStore.lookUpLists.addImportsToTeam = addImportsToTeam;
            props.dataStore.lookUpLists.teamFileImportsWithoutEnrich =
              filesWithoutEnrich;

            props.dataStore.lookUpLists.addImportsToTeam.sort(compareLabels);
            props.dataStore.lookUpLists.teamFileImports.sort(compareLabels);
            props.dataStore.lookUpLists.teamFileImportsWithoutEnrich.sort(
              compareLabels,
            );
          }
          if (
            (!props.dataStore.networkFilters.team_file_imports ||
              props.dataStore.networkFilters.team_file_imports.length <= 0) &&
            props.dataStore.networkFilters.filter_type !== 'all'
          ) {
            const lookupToChoose =
              props.dataStore.networkFilters.filter_type === 'first_degree'
                ? props.dataStore.lookUpLists.addImportsToTeam
                : props.dataStore.lookUpLists.teamFileImportsWithoutEnrich;

            if (!props.dataStore.importSourceKeys.isImportSourceSaved) {
              props.dataStore.networkFilters.team_file_imports =
                lookupToChoose.map((item) => {
                  if (item.value) return item.value;
                });
            } else {
              const teamFiles = [];

              lookupToChoose.map((file) => {
                if (
                  file &&
                  file.value &&
                  props.dataStore.networkFilters.team_file_imports.includes(
                    file.value,
                  )
                )
                  teamFiles.push(file.value);
              });
              props.dataStore.networkFilters.team_file_imports = teamFiles;
            }

            props.dataStore.networkFilters.fetchData = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const fetchNotification = () => {
      requestWithAuth(
        'notifications',
        'GET',
        { page_size: 1 },
        null,
        null,
        'loading-notifications',
      ).then((response) => {
        if (
          !response ||
          !response.results ||
          response.results.length <= 0 ||
          response.unseen_count <= 0
        )
          return;
        setShowNotification(true);
        const results = response.results[0];
        setNotificationData(results.is_seen === false ? { ...results } : {});
        if (
          results.is_seen === false &&
          results.notification_type &&
          results.notification_type === 'import' &&
          results.source === 'csv'
        ) {
          /** On a successfull csv import, we will get notified.
           * Call the fileimports api to update the lookups on a successfull csv import
           */
          fetchFileImports();
          fetchSharedFileImports();
        }
      });
    };

    const onClickItemRedirection = (item) => {
      if (!item) return '';
      switch (item.notification_type) {
        case 'opportunities': {
          let filterName = '';
          if (item.description.includes('--')) {
            filterName = item.description.split('--')[1] || '';
          }
          navigate(
            `/prospect-finder/people?daily_opportunities_id=${
              item.event_id
            }&daily_opportunities_date=${moment(item.created).format(
              'YYYY-MM-DD',
            )}&filter_name=${filterName}`,
          );
          navigate(0);
          break;
        }
        case 'ri_count':
          navigate('/relationship-intelligence/dashboard');
          break;
        case 'import':
        case 'export':
          if (item.b2b_id || item.b2c_id) {
            /** if import and b2bid or b2cid, case is add or remove to network, redirection to profile*/
            navigate(
              `/prospect-finder/people?b2b_id=${item.b2b_id || ''}&b2c_id=${
                item.b2c_id || ''
              }`,
            );
            navigate(0);
            break;
          }
          navigate(`/connect/status`, {
            state: {
              expandStatus: true,
            },
          });
          break;
        case 'linkedin re-import':
          navigate(`/connect/import`, {
            state: {
              fromSource: 'LinkedIn',
            },
          });
          break;
        case 'admin':
          if (item.b2b_id || item.b2c_id) {
            navigate(
              `/prospect-finder/people?b2b_id=${item.b2b_id || ''}&b2c_id=${
                item.b2c_id || ''
              }`,
            );
            navigate(0);
            break;
          }
          navigate(`/notifications`);
          break;
        default:
          break;
      }
    };

    const getFormattedDescription = (item) => {
      if (!item || !item.description) return '';
      let desc = item.description;
      const { b2b_id, b2c_id } = item;

      switch (item.notification_type) {
        case 'opportunities': {
          const date = item.created;
          const duration = moment.duration(moment().diff(date));
          const days = parseInt(duration.asDays());
          if (days >= 1) {
            desc = desc.replaceAll(
              'today',
              `on ${moment(date).format('MM/DD/YYYY')}`,
            );
          }
          if (desc.includes('--')) {
            desc = desc.replaceAll('--', ':');
          }
          return desc;
        }
        default:
          return desc
            .toString()
            .replaceAll('csv', 'CSV')
            .replaceAll('linkedin', 'LinkedIn')
            .replaceAll('.', '');
      }
    };

    if (
      !notificationData ||
      Object.keys(notificationData).length <= 0 ||
      !showNotification
    )
      return <></>;

    return (
      <>
        <div
          className="global-notification-container"
          style={flags && flags.dntfd8933NewProfileView ? { left: 0 } : {}}
        >
          <div
            className="global-notification"
            style={
              flags && flags.dntfd8933NewProfileView
                ? { width: '100vw' }
                : { width: 'calc(100vw - 80px)' }
            }
          >
            <div className="description">
              {getFormattedDescription(notificationData)}
              <span
                className="link"
                onClick={() => onClickItemRedirection(notificationData)}
              >
                See more details here
              </span>
            </div>
            <div onClick={() => setShowNotification(false)}>
              <CloseCircleFilled />
              <span>Dismiss</span>
            </div>
          </div>
        </div>
        {/* dummy div */}
        <div style={{ height: '35px', visibility: 'hidden' }} />
      </>
    );
  }),
);

export default GlobalNotificationBar;
