/** File to be removed when dntfd8933NewProfileView flag is removed,
 * Hence not converting to tsx
 */
import './BaseViewAccount.css';

import { Button, Form, Input, Select, message } from 'antd';
import { inject, observer } from 'mobx-react';

import { useState } from 'react';
import Statelist from '../../utils/Statelist';
import { requestWithAuth } from '../../services/api';
import LoaderAnimation from '../../components/AidLoader';

const { Option } = Select;

const BaseView = inject('dataStore')(
  observer((props) => {
    const user = props.dataStore.currentUser;
    const [isLoading, setIsLoading] = useState(false);

    const getStateOptions = () => {
      const stateOptions = Statelist.map((state) => {
        return (
          <Option key={state.value} value={state.value}>
            {state.text}
          </Option>
        );
      });
      stateOptions.unshift(<Option value="">Select</Option>);
      return stateOptions;
    };

    const handleSubmit = (values) => {
      const body = {
        data: values,
      };
      // console.log(values);
      setIsLoading(true);
      requestWithAuth(
        `user-type/${props.dataStore.currentUser.ownerId}`,
        'PUT',
        null,
        body,
        null,
        'cover-spin',
      ).then((res) => {
        if (res) {
          props.dataStore.currentUser.full_name = values.full_name;
          props.dataStore.currentUser.company = values.company;
          props.dataStore.currentUser.state = values.state;
          props.dataStore.currentUser.title = values.title;
          message.success('Profile Updated');
        }
        setIsLoading(false);
      });
    };

    if (!props.dataStore.currentUser || !props.dataStore.currentUser.email)
      return (
        <div
          className="baseView loader-wrapper"
          style={{ height: '60vh', padding: '10px' }}
        >
          {isLoading && (
            <div id="cover-spin">
              <LoaderAnimation loaderId="invalid-user-base-loader" />
            </div>
          )}
        </div>
      );

    return (
      <div className="baseViewparent">
        <div className="settingsview_title">Profile Settings</div>
        <div className="loader-wrapper">
          {isLoading && (
            <div id="cover-spin">
              <LoaderAnimation loaderId="base-view-loader" />
            </div>
          )}
          <div
            className={
              isLoading ? 'baseView aid-div-disable' : 'baseView aid-div-enable'
            }
          >
            <div className="left">
              <Form
                layout="vertical"
                onFinish={handleSubmit}
                hideRequiredMark
                initialValues={{
                  full_name: user && user.full_name ? user.full_name : '',
                  title: user && user.title ? user.title : '',
                  company: user && user.company ? user.company : '',
                  state: user && user.state ? user.state : '',
                }}
              >
                <div>
                  <Form.Item
                    label="Name"
                    name="full_name"
                    rules={[
                      { required: true, message: 'Name cannot be empty' },
                    ]}
                  >
                    <Input id="full_name" className="formItem" type="text" />
                  </Form.Item>
                  <Form.Item label="Title" name="title">
                    <Input id="title" className="formItem" type="text" />
                  </Form.Item>
                  <Form.Item label="Company" name="company">
                    <Input id="company" className="formItem" type="text" />
                  </Form.Item>
                  <Form.Item label="State" name="state">
                    <Select id="state" className="formItem">
                      {getStateOptions()}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Email">
                    <Input
                      className="formItem"
                      value={user && user.email ? user.email : ''}
                      disabled
                    />
                  </Form.Item>
                  <Button htmlType="submit" className="formItem" type="primary">
                    Update Information
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }),
);

export default BaseView;
