/* Data store used to keep the values from master filter     */

import { action, computed, makeObservable, observable } from 'mobx';

class GlobalDataStore {
  constructor() {
    makeObservable(this, {
      masterFilters: observable,
      masterFiltersOnSelect: observable,
      reset: action,
      isMasterFilterApplied: computed,
      isPrimaryAttributesApplied: computed,
      isHouseholdAttributesApplied: computed,
      isProfessionalAttributesApplied: computed,
      isIrsTaxInsightsApplied: computed,
      isComapnyAttributesApplied: computed,
      isMoneyinmotionAttributesApplied: computed,
      isPropertyApplied: computed,
      isImportSourceAttributesApplied: computed,
      isTagsApplied: computed,
      isAttributesApplied: computed,
      isWealthModelApplied: computed,
      isRelationshipsApplied: computed,
      multipleFilters: observable,
      resetMasterFilter: action,
      resetMultipleFilters: action,
      resetPrimaryAttributes: action,
      resetHouseholdAttributes: action,
      resetProfessionalAttributes: action,
      resetIrsTaxInsightsAttributes: action,
      resetCompanyAttributes: action,
      resetProperty: action,
      resetMoneyinmotionAttributes: action,
      resetTagsAttributes: action,
      resetAttributes: action,
      resetWealthModel: action,
      resetImportSourceAttributes: action,
      notificationCount: observable,
    });
  }

  multipleFilters = {
    filterList: [],
    isFilterApplied: false,
    selectedId: -1,
    selectedIdList: [],
    allFilterIds: [],
    filterType: 'all',
    fromFilters: false,
  };

  notificationCount = 0;

  orderingParam = '-created_at';

  /* used to keep the saved value */
  masterFilters = {
    fetchData: true,
    filter_type: 'all',
    mapQuery: {},
    location_state: [],
    presence_of_children: null,
    interests: [],
    all_interests: false,
    credentials: [],
    title_rank: [],
    exc_title: [],
    exc_title_rank: [],
    title_role: [],
    inferred_salary_range: [],
    wealth_estimate: [],
    wealth_estimate_range: {},
    employee_count: [],
    stock_performance: [],
    latest_mim_event_type: [],
    m2_date: [],
    period: null,
    full_name: '',
    org_name: [],
    exc_current_experience: [],
    org_contains: [],
    ai_org_id: [],
    previous_experience_org_name: [],
    industry: [],
    exc_industry: [],
    naics_codes: [],
    schools: [],
    location_city: [],
    gender: null,
    marital_status: null,
    home_search: { geo: null, address: '' },
    home_address_suggest: [],
    exc_home_address_suggest: [],
    company_search: { geo: null, address: '' },
    county: [],
    slider_radius: 10,
    isSaveClicked: false,
    title: [],
    funding_round: [],
    latest_mim_monetary_value: [],
    // latest_mim_monetary_value_range: [],
    event_org_name: [],
    prospect_profile_name: '',
    default_view: false,
    email_alert: true,
    separate_email_alert: false,
    profile_id: 0,
    all_homes: false,
    tags_checked: [],
    shared_tags: [],
    wealth_model_checked: [],
    attributes_checked: [],
    attribute_function: true,
    import_source: [],
    file_imports: [],
    team_file_imports: [],
    filterFileImports: [],
    filterTeamFileImports: [],
    irs_tax_insights: { key: '', gte: '', lte: '' },
    mapped_relationships_filter: {},
    relmap_org: [],
    number_of_mapped_connections: [],
    property_type: [],
    home_value_estimate: null,
    home_purchase_date: [],
    home_sale_date: [],
    number_of_owned_properties: [],
    norm_home_owner: [],
    total_number_of_rooms: [],
    lotsize_square_feet: [],
    effective_year_built: [],
    number_of_bedrooms: [],
    number_of_baths: [],
    number_of_partial_baths: [],
    number_of_stories: [],
    number_of_units: [],
    garage_cars: [],
    is_pool_included: false,
    is_elevator_included: false,
  };

  /* used to keep the values in the ui
  when save is clicked we will copy this values in to masterfilters */
  masterFiltersOnSelect = {
    fetchData: true,
    filter_type: 'all',
    mapQuery: {},
    location_state: [],
    presence_of_children: null,
    interests: [],
    all_interests: false,
    credentials: [],
    title_rank: [],
    exc_title: [],
    exc_title_rank: [],
    title_role: [],
    inferred_salary_range: [],
    wealth_estimate: [],
    current_org_years: {},
    total_years_of_experience: {},
    employee_count: [],
    stock_performance: [],
    latest_mim_event_type: [],
    m2_date: [],
    period: null,
    full_name: '',
    org_name: [],
    exc_current_experience: [],
    org_contains: [],
    ai_org_id: [],
    previous_experience_org_name: [],
    industry: [],
    exc_industry: [],
    naics_codes: [],
    schools: [],
    location_city: [],
    gender: null,
    marital_status: null,
    home_search: { geo: null, address: '' },
    home_address_suggest: [],
    exc_home_address_suggest: [],
    company_search: { geo: null, address: '' },
    county: [],
    slider_radius: 10,
    isSaveClicked: false,
    title: [],
    funding_round: [],
    latest_mim_monetary_value: [],
    // latest_mim_monetary_value_range: [],
    event_org_name: [],
    prospect_profile_name: '',
    default_view: false,
    email_alert: true,
    separate_email_alert: false,
    profile_id: 0,
    all_homes: false,
    tags_checked: [],
    shared_tags: [],
    wealth_model_checked: [],
    attributes_checked: [],
    attribute_function: true,
    import_source: [],
    file_imports: [],
    team_file_imports: [],
    filterFileImports: [],
    filterTeamFileImports: [],
    irs_tax_insights: { key: '', gte: '', lte: '' },
    mapped_relationships_filter: {},
    relmap_org: [],
    number_of_mapped_connections: [],
    home_value_estimate: null,
    home_purchase_date: [],
    home_sale_date: [],
    property_type: [],
    number_of_owned_properties: [],
    norm_home_owner: [],
    total_number_of_rooms: [],
    lotsize_square_feet: [],
    effective_year_built: [],
    number_of_bedrooms: [],
    number_of_baths: [],
    number_of_partial_baths: [],
    number_of_stories: [],
    number_of_units: [],
    garage_cars: [],
    is_pool_included: false,
    is_elevator_included: false,
  };
  /* method used to hide and show clearAll and tickmark in primary Attributes of profile filter */
  get isPrimaryAttributesApplied() {
    if (
      (this.masterFiltersOnSelect.filter_type &&
        this.masterFiltersOnSelect.filter_type !== 'all') ||
      (this.masterFiltersOnSelect.location_city &&
        this.masterFiltersOnSelect.location_city.length > 0) ||
      (this.masterFiltersOnSelect.location_state &&
        this.masterFiltersOnSelect.location_state.length > 0) ||
      (this.masterFiltersOnSelect.inferred_salary_range &&
        this.masterFiltersOnSelect.inferred_salary_range.length > 0) ||
      (this.masterFiltersOnSelect.wealth_estimate &&
        this.masterFiltersOnSelect.wealth_estimate.length > 0) ||
      (this.masterFiltersOnSelect.org_name &&
        this.masterFiltersOnSelect.org_name.length > 0) ||
      (this.masterFiltersOnSelect.title &&
        this.masterFiltersOnSelect.title.length > 0) ||
      (this.masterFiltersOnSelect.schools &&
        this.masterFiltersOnSelect.schools.length > 0)
    )
      return true;
    return false;
  }

  /* method used to hide and show clearAll and tickmark in household Attributes of profile filter */
  get isHouseholdAttributesApplied() {
    if (
      this.masterFiltersOnSelect.all_homes ||
      (this.masterFiltersOnSelect.location_city &&
        this.masterFiltersOnSelect.location_city.length > 0) ||
      (this.masterFiltersOnSelect.location_state &&
        this.masterFiltersOnSelect.location_state.length > 0) ||
      (this.masterFiltersOnSelect.county &&
        this.masterFiltersOnSelect.county.length > 0) ||
      (this.masterFiltersOnSelect.home_address_suggest &&
        this.masterFiltersOnSelect.home_address_suggest.length > 0) ||
      (this.masterFiltersOnSelect.exc_home_address_suggest &&
        this.masterFiltersOnSelect.exc_home_address_suggest.length > 0) ||
      (this.masterFiltersOnSelect.home_search &&
        this.masterFiltersOnSelect.home_search.geo &&
        this.masterFiltersOnSelect.home_search.address) ||
      (this.masterFiltersOnSelect.home_postal_code &&
        this.masterFiltersOnSelect.home_postal_code.key &&
        this.masterFiltersOnSelect.home_postal_code.value &&
        this.masterFiltersOnSelect.home_postal_code.value.length > 0) ||
      (this.masterFiltersOnSelect.interests &&
        this.masterFiltersOnSelect.interests.length) ||
      (this.masterFiltersOnSelect.age &&
        this.masterFiltersOnSelect.age.key &&
        this.masterFiltersOnSelect.age.value &&
        this.masterFiltersOnSelect.age.value.length > 0) ||
      (this.masterFiltersOnSelect.gender &&
        this.masterFiltersOnSelect.gender.value) ||
      (this.masterFiltersOnSelect.marital_status &&
        this.masterFiltersOnSelect.marital_status.value) ||
      (this.masterFiltersOnSelect.presence_of_children &&
        this.masterFiltersOnSelect.presence_of_children !== null)
    )
      return true;
    return false;
  }

  /* method to hide and show clearAll and tickmark in professional Attributes of profile filter */
  get isProfessionalAttributesApplied() {
    if (
      (this.masterFiltersOnSelect.previous_experience_org_name &&
        this.masterFiltersOnSelect.previous_experience_org_name.length > 0) ||
      (this.masterFiltersOnSelect.current_org_years &&
        this.masterFiltersOnSelect.current_org_years.key &&
        this.masterFiltersOnSelect.current_org_years.value &&
        this.masterFiltersOnSelect.current_org_years.value.length > 0) ||
      (this.masterFiltersOnSelect.total_years_of_experience &&
        this.masterFiltersOnSelect.total_years_of_experience.key &&
        this.masterFiltersOnSelect.total_years_of_experience.value &&
        this.masterFiltersOnSelect.total_years_of_experience.value.length >
          0) ||
      (this.masterFiltersOnSelect.exc_current_experience &&
        this.masterFiltersOnSelect.exc_current_experience.length > 0) ||
      (this.masterFiltersOnSelect.org_name &&
        this.masterFiltersOnSelect.org_name.length > 0) ||
      (this.masterFiltersOnSelect.title &&
        this.masterFiltersOnSelect.title.length > 0) ||
      (this.masterFiltersOnSelect.schools &&
        this.masterFiltersOnSelect.schools.length > 0) ||
      (this.masterFiltersOnSelect.credentials &&
        this.masterFiltersOnSelect.credentials.length) ||
      (this.masterFiltersOnSelect.title_rank &&
        this.masterFiltersOnSelect.title_rank.length) ||
      (this.masterFiltersOnSelect.exc_title_rank &&
        this.masterFiltersOnSelect.exc_title_rank.length) ||
      (this.masterFiltersOnSelect.exc_title &&
        this.masterFiltersOnSelect.exc_title.length > 0) ||
      (this.masterFiltersOnSelect.title_role &&
        this.masterFiltersOnSelect.title_role.length > 0) ||
      (this.masterFiltersOnSelect.inferred_salary_range &&
        this.masterFiltersOnSelect.inferred_salary_range.length > 0) ||
      (this.masterFiltersOnSelect.wealth_estimate &&
        this.masterFiltersOnSelect.wealth_estimate.length > 0) ||
      (this.masterFiltersOnSelect.wealth_estimate_range &&
        this.masterFiltersOnSelect.wealth_estimate_range.key &&
        this.masterFiltersOnSelect.wealth_estimate_range.value &&
        this.masterFiltersOnSelect.wealth_estimate_range.value.length > 0) ||
      (this.masterFiltersOnSelect.remaining_share_value_sum &&
        this.masterFiltersOnSelect.remaining_share_value_sum.length > 0)
    )
      return true;
    return false;
  }

  /* method to hide and show clearAll and tickmark in US Tax Insights Attributes of profile filter */
  get isIrsTaxInsightsApplied() {
    if (
      this.masterFiltersOnSelect.irs_tax_insights &&
      this.masterFiltersOnSelect.irs_tax_insights.key &&
      (this.masterFiltersOnSelect.irs_tax_insights.gte ||
        this.masterFiltersOnSelect.irs_tax_insights.lte)
    )
      return true;
    return false;
  }

  /* method to hide and show clearAll and tickmark in company Attributes of profile filter */
  get isComapnyAttributesApplied() {
    if (
      (this.masterFiltersOnSelect.company_search &&
        this.masterFiltersOnSelect.company_search.geo &&
        this.masterFiltersOnSelect.company_search.address) ||
      (this.masterFiltersOnSelect.industry &&
        this.masterFiltersOnSelect.industry.length > 0) ||
      (this.masterFiltersOnSelect.exc_industry &&
        this.masterFiltersOnSelect.exc_industry.length > 0) ||
      (this.masterFiltersOnSelect.naics_codes &&
        this.masterFiltersOnSelect.naics_codes.length > 0) ||
      (this.masterFiltersOnSelect.stock_performance &&
        this.masterFiltersOnSelect.stock_performance.length > 0) ||
      (this.masterFiltersOnSelect.employee_count &&
        this.masterFiltersOnSelect.employee_count.length > 0) ||
      (this.masterFiltersOnSelect.company_type &&
        this.masterFiltersOnSelect.company_type.value !== '')
    )
      return true;
    return false;
  }

  /* method to hide and show clearAll and tickmark in professional Attributes of profile filter */
  get isMoneyinmotionAttributesApplied() {
    if (
      (this.masterFiltersOnSelect.latest_mim_event_type &&
        this.masterFiltersOnSelect.latest_mim_event_type.length) ||
      (this.masterFiltersOnSelect.funding_round &&
        this.masterFiltersOnSelect.funding_round.length > 0) ||
      // (this.masterFiltersOnSelect.latest_mim_monetary_value &&
      //   this.masterFiltersOnSelect.latest_mim_monetary_value.key &&
      //   this.masterFiltersOnSelect.latest_mim_monetary_value.value &&
      //   this.masterFiltersOnSelect.latest_mim_monetary_value.value.length >
      //     0) ||
      (this.masterFiltersOnSelect.m2_date &&
        this.masterFiltersOnSelect.m2_date.length > 0) ||
      (this.masterFiltersOnSelect.period &&
        this.masterFiltersOnSelect.period.value) ||
      (this.masterFiltersOnSelect.latest_mim_monetary_value &&
        this.masterFiltersOnSelect.latest_mim_monetary_value.length > 0)
    )
      return true;
    return false;
  }

  get isImportSourceAttributesApplied() {
    if (
      (this.masterFiltersOnSelect.import_source &&
        this.masterFiltersOnSelect.import_source.length > 0) ||
      (this.masterFiltersOnSelect.file_imports &&
        this.masterFiltersOnSelect.file_imports.length > 0) ||
      (this.masterFiltersOnSelect.team_file_imports &&
        this.masterFiltersOnSelect.team_file_imports.length > 0)
    )
      return true;
    return false;
  }
  get isPropertyApplied() {
    if (
      (this.masterFiltersOnSelect.property_type &&
        this.masterFiltersOnSelect.property_type.length > 0) ||
      (this.masterFiltersOnSelect.home_value_estimate &&
        this.masterFiltersOnSelect.home_value_estimate.key &&
        this.masterFiltersOnSelect.home_value_estimate.value &&
        this.masterFiltersOnSelect.home_value_estimate.value.length > 0) ||
      (this.masterFiltersOnSelect.home_value_estimate &&
        Array.isArray(this.masterFiltersOnSelect.home_value_estimate) &&
        this.masterFiltersOnSelect.home_value_estimate.length > 0) ||
      (this.masterFiltersOnSelect.home_purchase_date &&
        this.masterFiltersOnSelect.home_purchase_date.length > 0) ||
      (this.masterFiltersOnSelect.home_sale_date &&
        this.masterFiltersOnSelect.home_sale_date.length > 0) ||
      (this.masterFiltersOnSelect.number_of_owned_properties &&
        this.masterFiltersOnSelect.number_of_owned_properties.length > 0) ||
      (this.masterFiltersOnSelect.norm_home_owner &&
        this.masterFiltersOnSelect.norm_home_owner.length > 0) ||
      (this.masterFiltersOnSelect.total_number_of_rooms &&
        this.masterFiltersOnSelect.total_number_of_rooms.length > 0) ||
      (this.masterFiltersOnSelect.lotsize_square_feet &&
        this.masterFiltersOnSelect.lotsize_square_feet.length > 0) ||
      (this.masterFiltersOnSelect.effective_year_built &&
        this.masterFiltersOnSelect.effective_year_built.lenght > 0) ||
      (this.masterFiltersOnSelect.number_of_bedrooms &&
        this.masterFiltersOnSelect.number_of_bedrooms.length > 0) ||
      (this.masterFiltersOnSelect.number_of_baths &&
        this.masterFiltersOnSelect.number_of_baths.length > 0) ||
      (this.masterFiltersOnSelect.number_of_partial_baths &&
        this.masterFiltersOnSelect.number_of_partial_baths.length > 0) ||
      (this.masterFiltersOnSelect.number_of_stories &&
        this.masterFiltersOnSelect.number_of_stories.length > 0) ||
      (this.masterFiltersOnSelect.number_of_units &&
        this.masterFiltersOnSelect.number_of_units.length > 0) ||
      (this.masterFiltersOnSelect.garage_cars &&
        this.masterFiltersOnSelect.garage_cars.length > 0) ||
      this.masterFiltersOnSelect.is_pool_included ||
      this.masterFiltersOnSelect.is_elevator_included
    )
      return true;
    return false;
  }
  get isTagsApplied() {
    if (
      (this.masterFiltersOnSelect.tags_checked &&
        this.masterFiltersOnSelect.tags_checked.length > 0) ||
      (this.masterFiltersOnSelect.shared_tags &&
        this.masterFiltersOnSelect.shared_tags.length > 0)
    )
      return true;
    return false;
  }
  get isAttributesApplied() {
    if (
      this.masterFiltersOnSelect.attributes_checked &&
      this.masterFiltersOnSelect.attributes_checked.length > 0
    )
      return true;
    return false;
  }

  get isWealthModelApplied() {
    if (
      this.masterFiltersOnSelect.wealth_model_checked &&
      this.masterFiltersOnSelect.wealth_model_checked.length > 0
    )
      return true;
    return false;
  }

  get isRelationshipsApplied() {
    if (
      (this.masterFiltersOnSelect.filter_type &&
        this.masterFiltersOnSelect.filter_type !== 'all') ||
      (this.masterFiltersOnSelect.mapped_relationships_filter &&
        this.masterFiltersOnSelect.mapped_relationships_filter
          .mapped_source_filter_name &&
        (this.masterFiltersOnSelect.mapped_relationships_filter
          .mapped_source_b2b_id ||
          this.masterFiltersOnSelect.mapped_relationships_filter
            .mapped_source_b2c_id)) ||
      (this.masterFiltersOnSelect.relmap_org &&
        this.masterFiltersOnSelect.relmap_org.length > 0) ||
      (this.masterFiltersOnSelect.number_of_mapped_connections &&
        this.masterFiltersOnSelect.number_of_mapped_connections.length > 0)
    )
      return true;
    return false;
  }

  /* method used to check anything applied in profile-filter */
  get isMasterFilterApplied() {
    return (
      this.isPrimaryAttributesApplied ||
      this.isHouseholdAttributesApplied ||
      this.isProfessionalAttributesApplied ||
      this.isIrsTaxInsightsApplied ||
      this.isMoneyinmotionAttributesApplied ||
      this.isComapnyAttributesApplied ||
      this.isTagsApplied ||
      this.isAttributesApplied ||
      this.isWealthModelApplied ||
      this.isRelationshipsApplied ||
      this.isImportSourceAttributesApplied ||
      this.isPropertyApplied ||
      this.mapQuery
    );
  }

  resetPrimaryAttributes() {
    this.masterFiltersOnSelect.filter_type = 'all';
    this.masterFiltersOnSelect.location_city = [];
    this.masterFiltersOnSelect.location_state = [];
    this.masterFiltersOnSelect.org_name = [];
    this.masterFiltersOnSelect.title = [];
    this.masterFiltersOnSelect.schools = [];
    this.masterFiltersOnSelect.inferred_salary_range = [];
    this.masterFiltersOnSelect.wealth_estimate = [];
  }

  resetHouseholdAttributes() {
    this.masterFiltersOnSelect.all_homes = false;
    this.masterFiltersOnSelect.location_city = [];
    this.masterFiltersOnSelect.location_state = [];
    this.masterFiltersOnSelect.county = [];
    this.masterFiltersOnSelect.home_search = { geo: null, address: '' };
    this.masterFiltersOnSelect.slider_radius = 10;
    this.masterFiltersOnSelect.home_address_suggest = [];
    this.masterFiltersOnSelect.exc_home_address_suggest = [];
    this.masterFiltersOnSelect.home_postal_code = {};
    this.masterFiltersOnSelect.interests = [];
    this.masterFiltersOnSelect.age = {};
    this.masterFiltersOnSelect.all_interests = false;
    this.masterFiltersOnSelect.gender = null;
    this.masterFiltersOnSelect.marital_status = null;
    this.masterFiltersOnSelect.presence_of_children = null;
  }

  resetProfessionalAttributes() {
    this.masterFiltersOnSelect.previous_experience_org_name = [];
    this.masterFiltersOnSelect.current_org_years = {};
    this.masterFiltersOnSelect.total_years_of_experience = {};
    this.masterFiltersOnSelect.org_name = [];
    this.masterFiltersOnSelect.title = [];
    this.masterFiltersOnSelect.schools = [];
    this.masterFiltersOnSelect.credentials = [];
    this.masterFiltersOnSelect.title_rank = [];
    this.masterFiltersOnSelect.exc_title_rank = [];
    this.masterFiltersOnSelect.exc_current_experience = [];
    this.masterFiltersOnSelect.exc_title = [];
    this.masterFiltersOnSelect.title_role = [];
    this.masterFiltersOnSelect.inferred_salary_range = [];
    this.masterFiltersOnSelect.wealth_estimate = [];
    this.masterFiltersOnSelect.wealth_estimate_range = {};
    this.masterFiltersOnSelect.remaining_share_value_sum = [];
  }

  resetIrsTaxInsightsAttributes() {
    this.masterFiltersOnSelect.irs_tax_insights = { key: '', gte: '', lte: '' };
  }

  resetCompanyAttributes() {
    this.masterFiltersOnSelect.company_search = { geo: null, address: '' };
    this.masterFiltersOnSelect.industry = [];
    this.masterFiltersOnSelect.exc_industry = [];
    this.masterFiltersOnSelect.naics_codes = [];
    this.masterFiltersOnSelect.stock_performance = [];
    this.masterFiltersOnSelect.employee_count = [];
    this.masterFiltersOnSelect.company_type = null;
  }

  resetProperty() {
    this.masterFiltersOnSelect.property_type = [];
    this.masterFiltersOnSelect.home_value_estimate = null;
    this.masterFiltersOnSelect.home_purchase_date = [];
    this.masterFiltersOnSelect.home_sale_date = [];
    this.masterFiltersOnSelect.number_of_owned_properties = [];
    this.masterFiltersOnSelect.norm_home_owner = [];
    this.masterFiltersOnSelect.total_number_of_rooms = [];
    this.masterFiltersOnSelect.lotsize_square_feet = [];
    this.masterFiltersOnSelect.effective_year_built = [];
    this.masterFiltersOnSelect.number_of_bedrooms = [];
    this.masterFiltersOnSelect.number_of_baths = [];
    this.masterFiltersOnSelect.number_of_partial_baths = [];
    this.masterFiltersOnSelect.number_of_stories = [];
    this.masterFiltersOnSelect.number_of_units = [];
    this.masterFiltersOnSelect.garage_cars = [];
    this.masterFiltersOnSelect.is_pool_included = false;
    this.masterFiltersOnSelect.is_elevator_included = false;
  }

  resetMoneyinmotionAttributes() {
    this.masterFiltersOnSelect.latest_mim_event_type = [];
    this.masterFiltersOnSelect.funding_round = [];
    this.masterFiltersOnSelect.latest_mim_monetary_value = [];
    this.masterFiltersOnSelect.m2_date = [];
    this.masterFiltersOnSelect.period = null;
  }

  resetTagsAttributes() {
    this.masterFiltersOnSelect.tags_checked = [];
    this.masterFiltersOnSelect.shared_tags = [];
  }

  resetAttributes() {
    this.masterFiltersOnSelect.attribute_function = true;
    this.masterFiltersOnSelect.attributes_checked = [];
  }

  resetWealthModel() {
    this.masterFiltersOnSelect.wealth_model_checked = [];
  }

  resetImportSourceAttributes() {
    this.masterFiltersOnSelect.import_source = [];
    this.masterFiltersOnSelect.file_imports = [];
    this.masterFiltersOnSelect.team_file_imports = [];
  }

  resetRelationshipsAttributes() {
    this.masterFiltersOnSelect.filter_type = 'all';
    this.masterFiltersOnSelect.mapped_relationships_filter = {};
    this.masterFiltersOnSelect.relmap_org = [];
    this.masterFiltersOnSelect.number_of_mapped_connections = [];
  }

  resetMasterFilter() {
    this.masterFilters = {
      fetchData: true,
      filter_type: 'all',
      location_state: [],
      presence_of_children: null,
      interests: [],
      all_interests: false,
      credentials: [],
      title_rank: [],
      exc_title: [],
      exc_title_rank: [],
      title_role: [],
      inferred_salary_range: [],
      wealth_estimate: [],
      employee_count: [],
      stock_performance: [],
      latest_mim_event_type: [],
      m2_date: [],
      period: null,
      full_name: '',
      org_name: [],
      exc_current_experience: [],
      org_contains: [],
      ai_org_id: [],
      previous_experience_org_name: [],
      current_org_years: {},
      total_years_of_experience: {},
      industry: [],
      exc_industry: [],
      naics_codes: [],
      schools: [],
      location_city: [],
      gender: null,
      marital_status: null,
      home_address_suggest: [],
      exc_home_address_suggest: [],
      home_purchase_date: [],
      home_sale_date: [],
      home_search: { geo: null, address: '' },
      company_search: { geo: null, address: '' },
      county: [],
      slider_radius: 10,
      isSaveClicked: false,
      title: [],
      funding_round: [],
      latest_mim_monetary_value: [],
      // latest_mim_monetary_value_range: [],
      event_org_name: [],
      prospect_profile_name: '',
      default_view: false,
      email_alert: true,
      separate_email_alert: false,
      profile_id: 0,
      all_homes: false,
      tags_checked: [],
      shared_tags: [],
      wealth_model_checked: [],
      attributes_checked: [],
      attribute_function: true,
      import_source: [],
      file_imports: [],
      team_file_imports: [],
      filterFileImports: [],
      filterTeamFileImports: [],
      irs_tax_insights: { key: '', gte: '', lte: '' },
      mapped_relationships_filter: {},
      relmap_org: [],
      number_of_mapped_connections: [],
      property_type: [],
      home_value_estimate: null,
      number_of_owned_properties: [],
      norm_home_owner: [],
      total_number_of_rooms: [],
      lotsize_square_feet: [],
      effective_year_built: [],
      number_of_bedrooms: [],
      number_of_baths: [],
      number_of_partial_baths: [],
      number_of_stories: [],
      number_of_units: [],
      garage_cars: [],
      is_pool_included: false,
      is_elevator_included: false,
    };
  }

  resetMultipleFilters() {
    this.multipleFilters = {
      filterList: [],
      isFilterApplied: false,
      selectedId: -1,
      selectedIdList: [],
      allFilterIds: [],
      filterType: 'all',
    };
  }

  /* method called when we click reset
     to reset the values in the fields */

  reset() {
    this.masterFiltersOnSelect = {
      fetchData: true,
      filter_type: 'all',
      location_state: [],
      presence_of_children: null,
      interests: [],
      credentials: [],
      title_rank: [],
      exc_title: [],
      exc_title_rank: [],
      title_role: [],
      inferred_salary_range: [],
      wealth_estimate: [],
      wealth_estimate_range: {},
      employee_count: [],
      stock_performance: [],
      latest_mim_event_type: [],
      m2_date: [],
      period: null,
      full_name: '',
      org_name: [],
      exc_current_experience: [],
      org_contains: [],
      ai_org_id: [],
      previous_experience_org_name: [],
      industry: [],
      exc_industry: [],
      naics_codes: [],
      schools: [],
      location_city: [],
      gender: null,
      marital_status: null,
      home_address_suggest: [],
      exc_home_address_suggest: [],
      home_purchase_date: [],
      home_sale_date: [],
      home_search: { geo: null, address: '' },
      company_search: { geo: null, address: '' },
      county: '',
      slider_radius: 10,
      isSaveClicked: false,
      title: [],
      funding_round: [],
      latest_mim_monetary_value: [],
      // latest_mim_monetary_value_range: [],
      event_org_name: [],
      prospect_profile_name: '',
      default_view: false,
      email_alert: true,
      separate_email_alert: false,
      profile_id: 0,
      all_homes: false,
      tags_checked: [],
      shared_tags: [],
      wealth_model_checked: [],
      attributes_checked: [],
      all_interests: false,
      attribute_function: true,
      import_source: [],
      file_imports: [],
      team_file_imports: [],
      filterFileImports: [],
      filterTeamFileImports: [],
      current_org_years: {},
      total_years_of_experience: {},
      irs_tax_insights: { key: '', gte: '', lte: '' },
      mapped_relationships_filter: {},
      relmap_org: [],
      number_of_mapped_connections: [],
      number_of_owned_properties: [],
      home_value_estimate: null,
      norm_home_owner: [],
      total_number_of_rooms: [],
      lotsize_square_feet: [],
      effective_year_built: [],
      number_of_bedrooms: [],
      number_of_baths: [],
      number_of_partial_baths: [],
      number_of_stories: [],
      number_of_units: [],
      garage_cars: [],
      is_pool_included: false,
      is_elevator_included: false,
    };
  }
}

export default GlobalDataStore;
