import './BasicLayout.css';

import axios from 'axios';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { inject, observer } from 'mobx-react';
import { ConfigProvider } from 'antd';

import Footer from '../components/Footer';
import Header from '../components/Header';
import GlobalNotificationBar from '../components/GlobalNotificationBar';
import ModalViews from '../components/ModalViews';
import { getDomain } from '../utils/general';
import useDocumentTitle from '../utils/useDocumentTitle';

const BasicLayout = inject('dataStore')(
  inject('modalDataStore')(
    observer((props) => {
      const { isAuthenticated, getIdTokenClaims, logout } = useAuth0();
      const ldClient = useLDClient();

      // use the name from theme api to show DocumentTitle
      let title = 'Aidentified';
      if (localStorage.getItem('aidentified.theme')) {
        const { name } = JSON.parse(localStorage.getItem('aidentified.theme'));
        title = name ? name.toString().toTitleCase() : 'Aidentified';
      }
      useDocumentTitle(title);
      /** initializing using LaunchDarkly with user data
       * userData is only available after login, so its updating here,
       * ClientsideId is added at Index.js
       * https://docs.launchdarkly.com/sdk/features/identify#javascript
       */
      useEffect(() => {
        if (props?.dataStore?.currentUser?.ownerId) {
          console.log(
            `ldClient.identify(key: ${props.dataStore.currentUser.ownerId})`,
          );
          ldClient.identify({
            key: props.dataStore.currentUser.ownerId,
            name: props.dataStore.currentUser.full_name,
            email: props.dataStore.currentUser.email,
          });
        }
      }, [
        ldClient,
        props.dataStore.currentUser.email,
        props.dataStore.currentUser.full_name,
        props.dataStore.currentUser.ownerId,
      ]);

      useEffect(() => {
        const logoutFromApp = () => {
          logout({
            returnTo: `${getDomain()}/user/login`,
          });
        };

        axios.interceptors.request.use(
          (config) => {
            const requestConfig = config;
            if (isAuthenticated) {
              getIdTokenClaims()
                .then((token) => {
                  if (!token || !token.__raw) {
                    logoutFromApp();
                    return;
                  }
                  if (
                    requestConfig &&
                    requestConfig.headers &&
                    requestConfig.headers.common &&
                    requestConfig.headers.common.Authorization
                  ) {
                    requestConfig.headers.common.Authorization = `Bearer ${token.__raw}`;
                  } else if (
                    requestConfig &&
                    requestConfig.headers &&
                    requestConfig.headers.Authorization
                  ) {
                    requestConfig.headers.Authorization = `Bearer ${token.__raw}`;
                  }
                })
                .catch((e) => {
                  console.log(e);
                  logoutFromApp();
                });
            } else {
              logoutFromApp();
            }
            return requestConfig;
          },
          (error) => {
            console.log(error);
          },
        );
      }, [getIdTokenClaims, isAuthenticated, logout]);

      return (
        <div className="basic" id="basic">
          <div
            className={
              props.modalDataStore.profileModal.isVisible ||
              props.modalDataStore.companyProfileModal.isVisible
                ? 'modal-container basic-container'
                : 'basic-container'
            }
          >
            <Header />
            <div className="content">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: 'var(--color-primary)',
                    colorSecondary: 'var(--color-secondary)',
                  },
                }}
              >
                <GlobalNotificationBar />
                <Outlet />
                {/* render all modal views from here to avoid repeating codes in all pages */}
                <ModalViews />
              </ConfigProvider>
            </div>
            <Footer />
          </div>
        </div>
      );
    }),
  ),
);

export default BasicLayout;
