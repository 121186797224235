import './index.scss';

import { Button, Form, Input, message } from 'antd';
import { useEffect, useState } from 'react';
import { inject } from 'mobx-react';
import { useNavigate } from 'react-router';
import axios from 'axios';

import ICloudIcon from '../../assets/icons/ai_icloud.svg';

import CrmForm from '../CrmForm';
import { requestWithAuth } from '../../services/api';
import LoaderAnimation from '../AidLoader';
import MoreInfoSection from './moreInfoSection';

message.config({
  top: 80,
});

let source;

const ICloudImport = inject('dataStore')((props) => {
  const navigate = useNavigate();
  const { submitting } = props;
  const [showVerificationField, setShowVerificationField] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { CancelToken } = axios;
    source = CancelToken.source();
    return () => {
      message.destroy();
      if (source) source.cancel('Operation canceled by the user.');
    };
  }, []);

  const handleSubmit = (values) => {
    const { userName } = values;
    const { password } = values;

    const body = {
      user_name: userName,
      password,
    };

    setIsLoading(true);
    requestWithAuth('icloud-auth', 'POST', null, body, source.token).then(
      (response) => {
        if (response) {
          if (response.code === 200) {
            setIsLoading(false);
            if (response.is_2sa === true) {
              setShowVerificationField(true);
              if (document.getElementById('contentIcloud') !== null)
                document.getElementById('contentIcloud').style.display = 'none';
              if (document.getElementById('verificationCode') !== null)
                document.getElementById('verificationCode').style.display =
                  'block';
            } else if (response.is_2sa === false) {
              icloudContacts(0);
            } else {
              message.error(response.message, 10);
            }
          } else {
            message.error(response.message, 10);
            setIsLoading(false);
          }
        } else {
          message.error('Please try again', 5);
          setIsLoading(false);
        }
      },
    );
  };

  const validationSubmit = (values) => {
    icloudContacts(values.verificationCode);
  };

  const icloudContacts = (code) => {
    setIsLoading(true);
    const body = {
      code,
    };
    requestWithAuth('icloud-contacts', 'POST', null, body, source.token).then(
      (response) => {
        if (response) {
          if (response.code === 200) {
            props.onSuccess();
            message.success('iCloud Contacts Successfully Imported', 10);
            navigate('/connect/status', {
              state: {
                refreshStatus: true,
              },
            });
            setIsLoading(false);
          } else {
            message.error(response.message, 10);
            setIsLoading(false);
          }
        } else {
          message.error('Please try again', 5);
          setIsLoading(false);
        }
      },
    );
  };

  return (
    <div className="icloud-parent loader-wrapper">
      {isLoading && <LoaderAnimation loaderId="icloud-import-loader" />}
      <div
        className={
          isLoading
            ? 'icloud-container aid-div-disable'
            : 'icloud-container aid-div-enable'
        }
      >
        <div>
          <label className="connect-to">Import</label>
          <div
            style={{ paddingTop: 30, paddingLeft: 15, width: '90%' }}
            id="contentIcloud"
          >
            <CrmForm
              formLayout="vertical"
              inputSize="default"
              showLabels
              submitting={submitting}
              onFinish={handleSubmit}
              keys={{
                idLabel: 'Email',
                idKey: 'userName',
                passwordLabel: 'Password',
                passwordKey: 'password',
                buttonLabel: 'Import Contacts',
              }}
            />
          </div>
          {showVerificationField === true && (
            <div>
              <Form
                layout="vertical"
                onFinish={validationSubmit}
                hideRequiredMark
                className="form"
                autocomplete="__away"
              >
                <Form.Item
                  label="Verification code"
                  name="verificationCode"
                  rules={[
                    {
                      required: true,
                      message: `Verification code cannot be empty.`,
                    },
                  ]}
                >
                  <Input autoComplete="verification code" />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
        </div>
        <div>
          <div className="icloud-section">
            <img className="icloud-icon" src={ICloudIcon} alt="icloud" />
            <label className="icloud-label">iCloud Contact</label>
          </div>
          <div style={{ padding: '20px 0' }}>
            <p className="section-one">
              After entering your credentials, you can use either SMS
              authentication code or 2 factor authentication code (pop-up) to
              confirm your login.
            </p>
            <p className="section-two">
              When you enter your iCloud credentials, you are granting
              Aidentified access to your contacts. Contacts are imported into
              the Aidentified platform and matched with our profiles. Through
              this matching, additional household and professional attributes
              are provided as well as relationship paths to prospects. We do not
              sync information back to iCloud and we do not store your
              credentials.
            </p>
            <MoreInfoSection />
          </div>
        </div>
      </div>
    </div>
  );
});

export default ICloudImport;
