import React from 'react';

interface ConnectionStrengthProps {
  short?: boolean;
  strength: 'high' | 'med' | 'low';
}

export const ConnectionStrength: React.FC<ConnectionStrengthProps> = ({
  short = false,
  strength = 'high',
}: ConnectionStrengthProps) => {
  const getLabelContent = () => {
    switch (strength) {
      case 'high':
        return short ? 'High' : 'High strength';
      case 'med':
        return short ? 'Med' : 'Med strength';
      case 'low':
        return short ? 'Low' : 'Low strength';
      default:
        return 'unknown';
    }
  };

  const getBackgroundColor = () => {
    switch (strength) {
      case 'high':
        return 'var(--color-high-connection-strength)';
      case 'med':
        return 'var(--color-yellow-100)';
      case 'low':
        return 'var(--color-red-75)';
      default:
        return 'unknown';
    }
  };

  const divStyles = {
    color: 'var(--color-gray-900)',
    backgroundColor: getBackgroundColor(),
    padding: '6px 10px',
    lineHeight: '14px',
    borderRadius: '4px',
  };

  return (
    <span
      style={{
        ...divStyles,
      }}
    >
      {getLabelContent()}
    </span>
  );
};
