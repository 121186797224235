import { Modal } from 'antd';

const { warning, confirm, info } = Modal;

export function showWarning(message) {
  warning({
    title: message,
    okText: 'OK',
    className: 'small-popup',
    //   onOk() {},
  });
}

export function showConfirm(props) {
  const { onOk, onCancel, ...rest } = props;

  confirm({
    icon: null,
    onOk() {
      onOk(1);
    },
    onCancel() {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onCancel ? onCancel(0) : true;
    },
    ...rest,
  });
}

export function showInfo({ title, content, okText }) {
  info({
    icon: null,
    title: title,
    content: content,
    okText: okText,
  });
}
