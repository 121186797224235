import { requestWithAuth } from '../services/api';
import { setAuthority } from './authority';
/* call api user-type and set localstorage authority with
 ** id - user id
 ** type - admin or premium
 ** isReg - true if -> non-admin, non-coorporate, existing user, new user with plans also for users with maxio plans
 ** isCorporate - is_corporate from api response
 ** creditCount - credits from api */
export const setUserType = () => {
  const userType = {
    ownerId: 0,
    type: '',
    isReg: true,
    isCorporate: false,
    isAdmin: false,
    isExistingUser: false,
    creditCount: 0,
    totalCredits: 0,
    fullCreditsApplied: false,
    pagination_count: '',
    userSelectedColumns: [],
    userTabOrder: [],
    billingPortalUrl: null,
    isTrial: false,
    hideSuppressTag: false,
    hideSharedCsvFiles: false,
    activePlan: 'free',
    profileImageType: '',
    profileImageReference: '',
    functionFlags: [],
  };

  const getOrgName = (email, functionFlags) => {
    let orgName = '';
    if (email && email.includes('exclusiveresorts')) {
      orgName = 'EX';
    } else if (email && email.includes('perigonwealth')) {
      orgName = 'PR';
    } else if (functionFlags && functionFlags.includes('invited_user')) {
      orgName = 'INV';
    }
    return orgName;
  };

  return requestWithAuth('user-type', 'GET', null, null, null)
    .then((response) => {
      if (response && response.results && response.results[0]) {
        userType.ownerId = response.results[0].id || 0;
        // userType.type =
        //   response.results[0].user_type &&
        //   response.results[0].user_type.toLowerCase() === 'admin'
        //     ? 'admin'
        //     : 'premium';

        userType.isReg = response.results[0].is_first_time
          ? true
          : response.results[0].is_corporate !== false ||
            response.results[0].user_type === 'lite';
        userType.isExistingUser = response.results[0].is_first_time || false;
        userType.isCorporate = response.results[0].is_corporate || false;
        userType.type = response.results[0].user_type || '';
        userType.isAdmin = userType.type.toLowerCase() === 'admin';
        userType.creditCount = response.results[0].credits;
        //TODO: need  to update total credits, now its not available in api
        userType.totalCredits = response.results[0].total_credits || -500000;
        userType.fullCreditsApplied = response.results[0].full_credits_applied;

        userType.full_name = response.results[0].full_name;
        userType.email = response.results[0].email;
        userType.created_at = response.results[0].created_at;
        userType.company = response.results[0].company;
        userType.crm = response.results[0].crm;
        userType.page_size = 20;
        userType.functionFlags = response.results[0].function_flags;
        userType.org_name = getOrgName(
          response.results[0].email,
          response.results[0].function_flags,
        );
        userType.state = response.results[0].state;
        userType.title = response.results[0].title;
        userType.hasFilters = response.results[0].has_filters;
        userType.hasTeam = response.results[0].has_team;
        userType.hasImports = response.results[0].has_imports;
        userType.slug = response.results[0].slug;
        userType.industry = response.results[0].industry;
        userType.referrer_code = response.results[0].referrer_code;
        userType.account_rep = response.results[0].account_rep;
        userType.nexis_admin = response.results[0].nexis_admin;
        userType.pagination_count = response.results[0].pagination_count;
        userType.userSelectedColumns =
          response.results[0].prospect_header_order;
        userType.userTabOrder = response.results[0].prospect_tab_order || [];
        userType.enableSuppression =
          response.results[0].enable_suppression || true;
        userType.suppressedProfileCount =
          response.results[0].suppressed_profile_count || 0;
        userType.profileImageType = response.results[0].profile_image_type;
        userType.profileImageReference =
          response.results[0].profile_image_reference;

        // if (response.results[0].zillow_token !== undefined) {
        //   userType.zillowToken = response.results[0].zillow_token;
        // } else {
        //   userType.zillowToken = null;
        // }
        if (response.results[0].is_trial) {
          userType.isTrial = response.results[0].is_trial;
        }
        if (response.results[0].billing_portal_url) {
          userType.billingPortalUrl = response.results[0].billing_portal_url;
          userType.isReg = true;
        }
        userType.isNorthWesternMutual = response.results[0].is_nwm || false;
        userType.hideSuppressTag =
          response.results[0].function_flags &&
          response.results[0].function_flags.includes('hide_suppress_tag')
            ? true
            : false;
        userType.hideMonetaContactTag =
          response.results[0].function_flags &&
          response.results[0].function_flags.includes('hide_moneta_contact_tag')
            ? true
            : false;
        userType.hideSharedCsvFiles =
          response.results[0].function_flags &&
          response.results[0].function_flags.includes('hide_shared_csv_files')
            ? true
            : false;
        if (
          response.results[0].user_type !== 'lite' &&
          response.results[0].user_type !== 'inactive'
        ) {
          if (
            response.results[0].subscription_status === 'active' ||
            response.results[0].subscription_status === 'canceled' ||
            response.results[0].subscription_status === 'trial'
          ) {
            userType.activePlan = response.results[0].plan_code || 'free';
          }
        }
      }
      if (response !== '') setAuthority(['user', userType]);
      // localStorage.setItem('user-info', JSON.stringify(response.results[0]));
      if (!localStorage.getItem('aidentified.pfFinderHelp')) {
        localStorage.setItem('aidentified.pfFinderHelp', 'true');
      }
      return userType;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
};
