import { useEffect, useState } from 'react';
import { Collapse, Button, message } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';

import ProfileIcon from '../../assets/icons/ai_avatar.svg?react';

import withRouter from '../../hooks/withRouter';
import { requestWithAuth } from '../../services/api';
import WarningMessage from '../WarningMessage';
import './index.scss';
import LoaderAnimation from '../AidLoader';

const { Panel } = Collapse;
message.config({
  top: 80,
});
let source;

const Matches = inject(
  'dataStore',
  'globalDataStore',
)(
  observer((props) => {
    const { filterList, fullName, showProfile, b2bId, showCompanyProfile } =
      props;

    const [networkList, setNetworkList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filterId, setFilterId] = useState();
    const [isError, setIsError] = useState(false);
    const flags = useFlags();
    useEffect(() => {
      const { CancelToken } = axios;
      source = CancelToken.source();
      return () => {
        message.destroy();
        if (source) source.cancel('Operation canceled by the user.');
      };
    }, []);

    function networkListApi([id]) {
      setNetworkList([]);
      setIsError(false);
      setIsLoading(true);
      if (!id) return;
      setFilterId(id);

      const filterObj = props.globalDataStore.multipleFilters.filterList.filter(
        (item) => {
          if (item.value === parseInt(id)) return item.value === parseInt(id);
        },
      );
      if (filterObj.length > 0 && filterObj[0].filters.team_members) {
        delete filterObj[0].filters.team_members;
      }
      const body = {};
      const params = {
        page_size: 10,
        from: 0,
      };
      params.additional_info = 0;
      params.filter_type = filterObj[0].filter_type;

      if (b2bId) body.super_connector = b2bId;
      if (filterObj) body.search = filterObj[0].filters;
      requestWithAuth(
        'network-list-new',
        'POST',
        params,
        body,
        source.token,
        'progress_people_list',
      ).then((response) => {
        if (!response) return;
        if (response.code === 400) {
          setIsError(true);
        }

        if (response && response.results) setNetworkList(response.results);
        setIsLoading(false);
      });
    }

    function showMoreClick() {
      props.dataStore.superConnectorFilter = {
        filterId,
        b2bId,
      };
      props.navigate('/prospect-finder/people');
    }

    return (
      <div className="superconnector-matches">
        <div className="network-label">{fullName}&apos;s prospect matches</div>
        {filterList && filterList.length > 0 && (
          <Collapse
            accordion
            expandIconPosition="end"
            className="matches-collapse"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            onChange={networkListApi}
          >
            {filterList.map((filter, index) => {
              return (
                <Panel
                  key={filter.id}
                  Panel
                  header={
                    <div className="panel-header">
                      <label className="match-count">
                        {filter.connections_count}{' '}
                        {filter.connections_count > 1
                          ? 'Prospect Matches'
                          : 'Prospect Match'}
                      </label>
                      <span className="company-name">
                        {filter.name
                          ? filter.name.toString().toTitleCase()
                          : ''}
                      </span>
                    </div>
                  }
                >
                  <div className="collapse-body loader-wrapper">
                    {isLoading && (
                      <LoaderAnimation
                        loaderId={`matches-${index}-loader`}
                        size="small"
                        style={{ top: '20px' }}
                      />
                    )}
                    {networkList &&
                      networkList.length > 0 &&
                      networkList.map((connection) => {
                        return (
                          <div className="panel-content">
                            <div>
                              <ProfileIcon
                                style={{
                                  fill: 'var(--color-secondary)',
                                  height: 21,
                                  width: 25,
                                }}
                              />
                              <label
                                className="name-label"
                                onClick={() => {
                                  showProfile(
                                    connection.b2b_id,
                                    connection.b2c_id,
                                  );
                                }}
                              >
                                {connection.full_name}
                              </label>
                            </div>
                            {connection.title && (
                              <label className="second-label">
                                {connection.title}
                                {connection.title && connection.org_name && (
                                  <>{' @ '}</>
                                )}
                                <span
                                  // ="link"
                                  className={
                                    connection.ai_org_id
                                      ? 'link'
                                      : 'link-disabled'
                                  }
                                  onClick={(e) => {
                                    if (!connection || !connection.ai_org_id)
                                      return;
                                    e.stopPropagation();
                                    showCompanyProfile(
                                      connection.ai_org_id,
                                      connection.org_name,
                                    );
                                  }}
                                >
                                  {connection.org_name}
                                </span>
                              </label>
                            )}
                          </div>
                        );
                      })}
                  </div>
                  {isError && (
                    <WarningMessage
                      iconName="upgrade"
                      content="To view executives, upgrade today!"
                      buttonText="Subscribe"
                    />
                  )}
                  {filter.connections_count > 10 &&
                    networkList &&
                    networkList.length > 0 && (
                      <div className="show-more-div">
                        <Button
                          className="show-more"
                          onClick={() => {
                            showMoreClick();
                          }}
                        >
                          Show More
                        </Button>
                      </div>
                    )}
                </Panel>
              );
            })}
          </Collapse>
        )}
      </div>
    );
  }),
);

export default withRouter(Matches);
