import './index.scss';

import {
  Button,
  Checkbox,
  Collapse,
  Input,
  Modal,
  message,
  Switch,
  Radio,
  Tooltip,
  DatePicker,
  Tag,
} from 'antd';
import {
  LinkOutlined,
  CheckCircleFilled,
  TagsFilled,
  UpCircleOutlined,
  DownOutlined,
  DollarOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';

import FlagIcon from '../../assets/icons/ai_flag.svg?react';
import HouseHoldIcon from '../../assets/icons/ai_house_hold.svg?react';
import Professional from '../../assets/icons/ai_professional.svg?react';
import IrsIcon from '../../assets/icons/ai_irs.svg?react';
import ConnectIcon from '../../assets/icons/ai_connect.svg?react';
import CompanyIcon from '../../assets/icons/ai_company.svg?react';
import TriggerIcon from '../../assets/icons/ai_triggers.svg?react';
import AttributeIcon from '../../assets/icons/ai_attribute.svg?react';
import HelpIcon from '../../assets/icons/help-icon.svg?react';
import InfoIcon from '../../assets/icons/ai_info.svg?react';
import PropertyIcon from '../../assets/icons/ai_property.svg?react';
import deleteContact from '../../assets/icons/ai_delete_bin.svg?react';
import LeftArrow from '../../assets/icons/ai_ic_down_arrow.svg?react';

import {
  ModeledChildPresenceValues,
  companySizeFilter,
  companyType,
  companyViablityRanges,
  fundingRoundList,
  gender,
  interests,
  maritalStatus,
  salaryFilter,
  wealthScoreRanges,
  attributes,
  importSourceList,
  remainingShareValues,
  IrsItems,
  IrsItemsNew,
  garageLookup,
  Numbers1To10,
  lotSizeRanges,
  homeOwnerStatusList,
  Properties2To5,
  storiesList,
  periodList,
  propertyTypes,
  propertyValues,
  mappedConnectionsCount,
  credentialsLookup,
  wealthModelItems,
  urlNames,
  FunctionFlags,
} from '../../utils/constants';
import { showConfirm } from '../../utils/popup';
import { getBodyParams } from '../../utils/getBodyParams';
import { requestWithAuth } from '../../services/api';

import { hasEntitlement } from '../../utils/entitlements';

import Tags from '../Tags';
import AgePicker from '../RangePickers/AgePicker';
import NumberRangePicker from '../RangePickers/NumberRangePicker';
import IrsInsightsRangePicker from '../RangePickers/IrsInsightsRangePicker';
import ExactDateRangePicker from '../RangePickers/ExactDateRangePicker';
import DateRangePicker from '../RangePickers/DateRangePicker';
import PostalCodePicker from '../RangePickers/PostalCodePicker';
import CreatableSelect from '../CreatableSelect';
import DataSelect from '../DropdownMenu';
import LocationSearch from '../LocationSearch';
import MultiDataSearch from '../MultiDataSearch';
import TreeDataSelect from '../TreeDataSelect';
import filterStates from '../../utils/Statelist';
import LocationSlider from '../LocationSlider';
import GoogleSearch from '../GoogleSearch';
import TagsSearch from '../TagsFolder/TagsSearch';
import TagManagement from '../TagsFolder/TagManagement';
import AiTooltip from '../AiTooltip';
import LoaderAnimation from '../AidLoader';

message.config({
  top: 80,
});
let source;
const { RangePicker } = DatePicker;
const { CheckableTag } = Tag;
const wealthModelValues = wealthModelItems.map((obj) => obj.value);

const CustomPanel = ({
  header,
  HeaderIcon = null,
  children,
  Clear,
  Footer,
  showHelpModal,
  showWealthModalVisible,
  ...rest
}) => {
  return (
    <Collapse.Panel
      header={
        <>
          <div className="header-title">
            {HeaderIcon ? <HeaderIcon /> : <></>}

            <div>{header}</div>
            {header === 'US Income Tax Insights' && (
              <AiTooltip
                size="16px"
                marginBottom="0px"
                title={
                  <span>
                    This feature utilizes data collected from individual tax
                    filings consolidated at a Zip+4 level.
                    <br />
                    <br />
                    Zip+4 usually comprises ten to twenty homes or locations. If
                    a profile does not have a Zip+4 identifier, Aidentified
                    provides a summary at the ZIP level.
                  </span>
                }
              />
            )}
            {header === 'Triggers' && (
              <InfoIcon
                id={header}
                height="16px"
                width="16px"
                style={{ color: '#91AFCA' }}
                onClick={showHelpModal}
              />
            )}
            {header === 'Wealth & Income Components' && (
              <InfoIcon
                id="info-wealth-components"
                height="16px"
                width="16px"
                style={{ color: '#91AFCA' }}
                onClick={showWealthModalVisible}
              />
            )}
            {header === 'Tags' && (
              <AiTooltip
                size="16px"
                marginBottom="0px"
                title="Tags can be utilized for segmentation purposes, allowing for
                    effective organization and management of your prospects and
                    clients.Use Tags in accordance with your corporate
                    guidelines."
              />
            )}
          </div>
        </>
      }
      className="panel-header"
      {...rest}
    >
      {children}
      <div className="clear-and-footer">
        <Clear panelKey={rest.panelKey} />
        <Footer />
      </div>
    </Collapse.Panel>
  );
};

const ProfileFilter = inject(
  'globalDataStore',
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const [isLoading, setIsLoading] = useState(false);
    // const [toFilter, setToFilter] = useState(false);
    const [activeKey, setActiveKey] = useState(defaultActivePanel());
    const [attributesAllCheckedByAdmin, setAttributesAllCheckedByAdmin] =
      useState(false);
    const [tagData, setTagData] = useState(
      props.dataStore.lookUpLists.userTagFolders,
    );
    const [checkedTags, setCheckedTags] = useState(
      props.globalDataStore.masterFiltersOnSelect.tags_checked,
    );

    const [showTagsEdit, setShowTagsEdit] = useState(false);
    const [showFolderEdit, setShowFolderEdit] = useState(false);

    const flags = useFlags();
    const { currentUser } = props.dataStore;

    const newProfileActive = flags && flags.dntfd8933NewProfileView;
    const hasPremiumWealthTriggers = hasEntitlement(
      currentUser,
      FunctionFlags.premium_wealth_triggers,
    );
    const hasPremiumProperty = hasEntitlement(
      currentUser,
      FunctionFlags.profile_location,
    );

    function onCollapseChange(key) {
      /** to know if any of the panel is open
       * key shows active panel,
       * gives undefined if all are closed
       */
      setActiveKey(key);
    }

    useEffect(() => {
      const { CancelToken } = axios;
      source = CancelToken.source();
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', onBrowserBack);
      return () => {
        message.destroy();
        if (source) source.cancel('Operation canceled by the user.');
        window.removeEventListener('popstate', onBrowserBack);
      };
    }, []);

    function defaultActivePanel() {
      if (props.globalDataStore.isPrimaryAttributesApplied) return 'Basic';
      if (props.globalDataStore.isHouseholdAttributesApplied)
        return 'Household';
      if (props.globalDataStore.isProfessionalAttributesApplied)
        return 'Professional';
      if (props.globalDataStore.isRelationshipsApplied) return 'Relationships';
      if (props.globalDataStore.isMoneyinmotionAttributesApplied)
        return 'Triggers';
      if (props.globalDataStore.isComapnyAttributesApplied) return 'Company';
      if (props.globalDataStore.isIrsTaxInsightsApplied)
        return 'US Income Tax Insights';
      if (props.globalDataStore.isPropertyApplied) return 'Property';
      if (
        (props.globalDataStore.isTagsApplied &&
          props.dataStore.userTags &&
          props.dataStore.userTags.some((tag) =>
            props.globalDataStore.masterFiltersOnSelect.tags_checked.includes(
              tag.id,
            ),
          )) ||
        (props.dataStore.sharedTags &&
          props.dataStore.sharedTags.some((tag) =>
            props.globalDataStore.masterFiltersOnSelect.shared_tags.includes(
              tag.tag_id,
            ),
          ))
      )
        return 'Tags';
      if (props.globalDataStore.isAttributesApplied) return 'Attributes';
      if (props.globalDataStore.isImportSourceAttributesApplied)
        return 'Import Source';
      return '1';
    }

    function compare(a, b) {
      if (a.label && b.label) {
        const valA =
          typeof a.label === 'string' ? a.label.toUpperCase() : a.label;
        const valB =
          typeof b.label === 'string' ? b.label.toUpperCase() : b.label;

        let comparison = 0;
        if (valA > valB) {
          comparison = 1;
        } else if (valA < valB) {
          comparison = -1;
        }
        return comparison;
      }
    }

    function deepEqual(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        let val1 = object1[key];
        let val2 = object2[key];

        if (Array.isArray(val1) && Array.isArray(val2)) {
          val1 = val1.sort(compare);
          val2 = val2.sort(compare);
        }

        const areObjects = isObject(val1) && isObject(val2);
        if (
          (areObjects && !deepEqual(val1, val2)) ||
          (!areObjects && val1 !== val2)
        ) {
          if (
            !areObjects &&
            typeof val1 === 'string' &&
            typeof val2 === 'string'
          ) {
            if (val1.toUpperCase() === val2.toUpperCase()) return true;
            return false;
          }
          return false;
        }
      }

      return true;
    }

    function isObject(object) {
      return object != null && typeof object === 'object';
    }

    function fetchMultipleFilters(activeFlag) {
      setIsLoading(true);
      const params = {};
      params.ordering = props.globalDataStore.orderingParam;
      requestWithAuth('multiple-filters', 'GET', params, null, null)
        .then((response) => {
          const filters = [];
          const allFilterIds = [];
          let selectedIdList = [];
          if (response && response.length > 0) {
            response.map((filter, index) => {
              if (filter.name) {
                if (filter.is_active && activeFlag) {
                  props.dataStore.networkFilters.selectedIdValue = filter.id;
                  props.globalDataStore.multipleFilters.isFilterApplied = true;
                  props.globalDataStore.multipleFilters.selectedId = filter.id;
                  selectedIdList = [{ value: filter.id, label: filter.name }];
                  props.dataStore.appliedMasterFilters.filter_type =
                    filter.filter_type;
                  props.globalDataStore.masterFilters.filter_type =
                    filter.filter_type;
                } else if (
                  !activeFlag &&
                  !props.globalDataStore.masterFiltersOnSelect.is_update &&
                  index === 0
                ) {
                  /** create filter from prospect finder */
                  props.dataStore.networkFilters = cloneDeep(
                    props.globalDataStore.masterFiltersOnSelect,
                  );
                  props.dataStore.networkFilters.selectedIdValue = filter.id;
                }

                allFilterIds.push({
                  value: filter.id,
                  label: filter.name,
                });

                filters.push({
                  value: filter.id,
                  label: filter.name,
                  is_active: filter.is_active,
                  email_alert: filter.email_alert,
                  separate_email_alert: filter.separate_email_alert,
                  filters: filter.filters,
                  filter_type: filter.filter_type,
                  created_at: filter.created_at,
                  profile_count: filter.profile_count,
                });
              }
            });
          }
          props.globalDataStore.multipleFilters.filterList = filters;
          props.globalDataStore.multipleFilters.allFilterIds = allFilterIds;
          /** if no default filter then add all filters, for opportunities */
          if (selectedIdList.length === 0) {
            props.globalDataStore.multipleFilters.selectedIdList = [
              { value: -1, label: 'All Prospect Filters' },
              ...props.globalDataStore.multipleFilters.allFilterIds,
            ];
          } else {
            props.globalDataStore.multipleFilters.selectedIdList =
              selectedIdList;
          }
          props.globalDataStore.masterFiltersOnSelect.isSaveClicked = false;
          if (activeFlag) {
            props.dataStore.filters.isMasterSaveClickMimSummary = true;
            // props.dataStore.filters.isMasterSaveClickMimPeople = true;
            props.dataStore.filters.isMasterSaveClickNetworkSummary = true;
            props.dataStore.networkFilters.fetchData = true;
          }
          props.globalDataStore.masterFiltersOnSelect.is_update = false;
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }

    const fetchUserTags = () => {
      requestWithAuth('tags', 'GET', null, null, null).then((response) => {
        if (response && response.length) {
          props.dataStore.userTags = response;
        }
      });
    };

    const fetchTagFolders = () => {
      requestWithAuth('tag-folder', 'GET', null, null, null).then(
        (response) => {
          if (response) {
            props.dataStore.lookUpLists.userTagFolders = response;
          }
        },
      );
    };

    /* Method called to call user-filter api  */
    function saveMasterFilter() {
      props.globalDataStore.masterFiltersOnSelect.isSaveClicked = true;
      let body = {};
      let bodyParams;
      const { CancelToken } = axios;
      source = CancelToken.source();
      const params = {};
      if (props.globalDataStore.masterFiltersOnSelect.prospect_profile_name)
        params.name =
          props.globalDataStore.masterFiltersOnSelect.prospect_profile_name;

      if (props.globalDataStore.masterFiltersOnSelect.default_view) {
        params.is_active = 1;
      } else {
        params.is_active = 0;
      }
      if (props.globalDataStore.masterFiltersOnSelect.email_alert) {
        params.email_alert = 1;
      } else {
        params.email_alert = 0;
      }
      if (props.globalDataStore.masterFiltersOnSelect.separate_email_alert) {
        params.separate_email_alert = 1;
      } else {
        params.separate_email_alert = 0;
      }

      /* if reset is clicked will pass search:{} inside the body
       * or else will pass the selected items */
      if (props.globalDataStore.isMasterFilterApplied) {
        bodyParams = getBodyParams(
          props.globalDataStore.masterFilters,
          props.dataStore.lookUpLists,
          props.dataStore.superConnectorFilter,
          props.dataStore.teamFilters,
          props.dataStore.currentUser,
          props.dataStore.mappedRelationshipsFilter,
          props.modalDataStore.profileModal,
          null,
          false,
          true,
        );

        let bodyParamSearch = {};
        if (bodyParams.search && Object.keys(bodyParams.search).length > 0) {
          bodyParamSearch = bodyParams.search;
        }
        delete bodyParams.search;
        body = {
          search: {
            ...bodyParams,
            ...bodyParamSearch,
            team_members: props.dataStore.lookUpLists.teamMemberIds,
          },
        };

        if (props.globalDataStore.masterFilters.filter_type)
          params.filter_type = props.globalDataStore.masterFilters.filter_type;
      } else {
        // params = null;
        message.error('Please select search criteria', 5);
        body = {
          search: {},
        };
        setIsLoading(false);
        return;
      }

      if (props.globalDataStore.masterFiltersOnSelect.profile_id > 0) {
        requestWithAuth(
          `multiple-filters/${props.globalDataStore.masterFiltersOnSelect.profile_id}`,
          'PUT',
          params,
          body,
          source.token,
        ).then((response) => {
          if (!response) {
            setIsLoading(false);
          } else {
            /* these variables are set to true
                  when save is clicked for calling apis in
                  the insights page inside the autorun */
            props.dataStore.filters.isMasterSaveClickMimSummary = true;
            // props.dataStore.filters.isMasterSaveClickMimPeople = true;
            props.dataStore.filters.isMasterSaveClickNetworkSummary = true;
            if (props.globalDataStore.masterFiltersOnSelect.is_update) {
              props.dataStore.networkFilters = cloneDeep(
                props.globalDataStore.masterFiltersOnSelect,
              );
              props.dataStore.networkFilters.fetchData = true;
              // props.globalDataStore.masterFiltersOnSelect.is_update = false;
            }
            const { onClose } = props;
            if (onClose) {
              onClose();
              if (props.globalDataStore.masterFiltersOnSelect.isSaveClicked)
                fetchMultipleFilters(params.is_active);
            } else {
              setIsLoading(false);
            }
          }
        });
      } else {
        /* user-filters api POST for save click */
        requestWithAuth(
          'multiple-filters',
          'POST',
          params,
          body,
          source.token,
        ).then((response) => {
          if (!response) {
            setIsLoading(false);
          } else {
            // if (!params.is_active) {
            //   /* reset filters once the multiple-filter API is called*/
            //   // props.globalDataStore.resetMasterFilter();
            //   // props.dataStore.resetNetworkFilters();
            // }
            const { onClose } = props;
            if (onClose) {
              onClose();
              if (props.globalDataStore.masterFiltersOnSelect.isSaveClicked)
                fetchMultipleFilters(params.is_active);
            } else {
              setIsLoading(false);
            }
            /* these variables are set to true
                  when save is clicked for calling apis in
                  the insights page inside the autorun */
            // props.dataStore.filters.isMasterSaveClickMimSummary = true;
            // // props.dataStore.filters.isMasterSaveClickMimPeople = true;
            // props.dataStore.filters.isMasterSaveClickNetworkSummary = true;
            // props.dataStore.networkFilters.fetchData = true;
          }
        });
      }
    }

    function showHelpModal(e) {
      e.stopPropagation();
      props.modalDataStore.eventHelpModalVisible = true;
    }

    const showWealthModalVisible = (e) => {
      e.stopPropagation();
      props.modalDataStore.wealthModalVisible = true;
    };

    const showPersonProfile = (e, b2bId, b2cId) => {
      e.preventDefault();
      // profile clicked
      if (!b2bId && !b2cId) {
        message.warning(
          'This profile cannot be opened right now. Please try again later.',
          10,
        );
        return;
      }
      const { profileModal } = props.modalDataStore;
      profileModal.b2bId = b2bId;
      profileModal.b2cId = b2cId;
      profileModal.mimpersonId = '';
      profileModal.eventsId = '';
      profileModal.isVisible = true;
    };

    const findAttributes = () => {
      let attributesAllowed = props.dataStore.currentUser.isAdmin
        ? attributes
        : attributes.slice(0, -3);

      if (!flags.verifiedAttributes) {
        attributesAllowed = attributesAllowed.filter((el) => {
          return (
            el.label.split(' ')[0] !== 'AI' ||
            el.label === 'AI Inspected Home Address' ||
            el.label === 'AI Inspected Home Owner Status'
          );
        });
      }
      if (flags.neustarId) {
        attributesAllowed = attributesAllowed.filter(
          (el) => el.label !== 'Household',
        );
      } else {
        attributesAllowed = attributesAllowed.filter(
          (el) => el.label !== 'Neustar ID',
        );
      }
      return attributesAllowed;
    };

    /* Method called to delete user-filter  */
    const handleDelete = () => {
      setIsLoading(true);
      requestWithAuth(
        `multiple-filters/${props.globalDataStore.masterFiltersOnSelect.profile_id}`,
        'DELETE',
        null,
        null,
        null,
      )
        .then((response) => {
          if (response && response.code === 200) {
            /* these variables are set to true
                when save is clicked for calling apis in
                the insights page inside the autorun */
            props.dataStore.filters.isMasterSaveClickMimSummary = true;
            // props.dataStore.filters.isMasterSaveClickMimPeople = true;
            props.dataStore.filters.isMasterSaveClickNetworkSummary = true;
            const { onClose } = props;
            if (onClose) {
              onClose();
              fetchMultipleFilters();
            } else {
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          message.error('Please try again', 5);
          console.log(err);
        });
    };

    /* Method called when the save button is clicked */
    function handleSave() {
      if (!props.globalDataStore.masterFiltersOnSelect.prospect_profile_name) {
        message.error('Enter prospect profile name', 5);
        return;
      }

      setIsLoading(true);

      // props.dataStore.resetNetworkFilters();

      /* on save we are copying the items in the
        masterFiltersOnSelect in to masterfilters and n/w filters
        clonedeep is used to avoid reference issues while copying */

      props.globalDataStore.masterFilters = cloneDeep(
        props.globalDataStore.masterFiltersOnSelect,
      );
      if (props.globalDataStore.masterFiltersOnSelect.default_view)
        props.dataStore.networkFilters = cloneDeep(
          props.globalDataStore.masterFiltersOnSelect,
        );
      saveMasterFilter();
    }

    function onBrowserBack(e) {
      toggleConfirmPopup(e, true);
    }

    function toggleConfirmPopup(e, fromBackArrow) {
      if (
        !deepEqual(
          props.globalDataStore.masterFilters,
          props.globalDataStore.masterFiltersOnSelect,
        )
      ) {
        showConfirm({
          className: 'small-popup',
          closable: true,
          onOk: () => handleCancel(),
          onCancel: () => {
            if (fromBackArrow)
              window.history.pushState(null, null, window.location.pathname);
            return false;
          },
          title: 'Confirm',
          okText: 'Yes',
          cancelText: 'No',
          content: <div>Leave page without saving?</div>,
        });
      } else {
        // setToFilter(toFilter || false);
        handleCancel();
      }
    }

    function handleCancel() {
      const { onClose } = props;
      if (onClose) onClose();
      props.globalDataStore.masterFiltersOnSelect = cloneDeep(
        props.globalDataStore.masterFilters,
      );
      // if (toFilter === true && history.location.pathname !== '/savedsearches') {
      //   history.push({
      //     pathname: '/savedsearches',
      //   });
      // }
    }

    function handleFilterNameChange(event) {
      props.globalDataStore.masterFiltersOnSelect.prospect_profile_name =
        event.target.value;
    }

    function handleCheckbox(event) {
      props.globalDataStore.masterFiltersOnSelect[event.target.id] =
        event.target.checked;

      if (event.target.checked && event.target.id === 'separate_email_alert')
        props.globalDataStore.masterFiltersOnSelect.email_alert = true;
    }

    const handleDegreeConnectionsChange = (tag, checked) => {
      if (checked) {
        switch (tag) {
          case 'first_degree':
            props.globalDataStore.masterFiltersOnSelect.filter_type =
              props.globalDataStore.masterFiltersOnSelect.filter_type ===
              'second_degree'
                ? 'combined'
                : 'first_degree';

            break;
          case 'second_degree':
            props.globalDataStore.masterFiltersOnSelect.filter_type =
              props.globalDataStore.masterFiltersOnSelect.filter_type ===
              'first_degree'
                ? 'combined'
                : 'second_degree';

            break;
          case 'all':
            props.globalDataStore.masterFiltersOnSelect.filter_type = 'all';
            break;
          default:
            break;
        }
      } else {
        switch (tag) {
          case 'first_degree':
            props.globalDataStore.masterFiltersOnSelect.filter_type =
              props.globalDataStore.masterFiltersOnSelect.filter_type ===
              'combined'
                ? 'second_degree'
                : 'all';

            break;
          case 'second_degree':
            props.globalDataStore.masterFiltersOnSelect.filter_type =
              props.globalDataStore.masterFiltersOnSelect.filter_type ===
              'combined'
                ? 'first_degree'
                : 'all';

            break;
          case 'all':
            props.globalDataStore.masterFiltersOnSelect.filter_type = 'all';
            break;
          default:
            break;
        }
      }
    };

    /* method called when we select the
     * dropdown items in company and home search */
    function onLocationSelected(id, latLng, address) {
      props.globalDataStore.masterFiltersOnSelect.slider_radius = 10;
      props.globalDataStore.masterFiltersOnSelect[id] = {
        geo: latLng,
        address,
      };
    }

    const onLocationSliderChanged = (radius) => {
      props.globalDataStore.masterFiltersOnSelect.slider_radius =
        !radius || radius === 0 ? 0.1 : radius;
    };

    const onPropertyAttributesChecked = (e) => {
      props.globalDataStore.masterFiltersOnSelect[e.target.id] =
        !!e.target.checked;
    };

    const handleTagsEditCancel = () => {
      if (showTagsEdit) {
        setShowTagsEdit(false);
      } else if (showFolderEdit) {
        setShowFolderEdit(false);
      } else {
        handleCancel();
      }
    };

    const onTagSearch = (tags) => {
      if (showTagsEdit) {
        setShowTagsEdit(false);
      }
      if (showFolderEdit) {
        setShowFolderEdit(false);
      }
      setTagData(tags);
    };

    const handleEditTags = (value) => {
      setShowTagsEdit(value);
    };

    const handleEditFolder = (value) => {
      setShowFolderEdit(value);
    };

    const onYearChange = (date, dateString) => {
      if (date) {
        props.globalDataStore.masterFiltersOnSelect.effective_year_built = date;
      } else {
        props.globalDataStore.masterFiltersOnSelect.effective_year_built = '';
      }
    };

    /* method for dataselect and
     * multidatasearch when we select items in dropdown */
    function onDropDownItemSlected(selectedItems, id) {
      if (id === 'latest_mim_event_type') {
        if (selectedItems && selectedItems.some((el) => el.value === 'all')) {
          let eventsArrayType = props.dataStore.lookUpLists.eventList;

          if (newProfileActive) {
            // filter out any premium events the user is not entitled to
            eventsArrayType = props.dataStore.lookUpLists.eventList.filter(
              (e) =>
                e.value !== 'all' &&
                (!e.isPremium || (hasPremiumTriggers && e.isPremium)),
            );
          }

          props.globalDataStore.masterFiltersOnSelect.latest_mim_event_type =
            eventsArrayType;
        } else {
          props.globalDataStore.masterFiltersOnSelect.latest_mim_event_type =
            selectedItems;
        }
        // const isDeceasedTriggerSelected = selectedItems.some((item) =>
        //   item.value.includes('deceased'),
        // );
        // if (!isDeceasedTriggerSelected) {
        //   props.globalDataStore.masterFiltersOnSelect.latest_mim_monetary_value_range =
        //     [];
        // }
      } else {
        props.globalDataStore.masterFiltersOnSelect[id] = selectedItems;
      }
    }

    const onDateRangeChange = (value, id) => {
      props.globalDataStore.masterFiltersOnSelect[id] = value;
    };

    /* method in onchange of radiobutton to get the value of selected button */
    function onRadioButtonSelected(id, value) {
      props.globalDataStore.masterFiltersOnSelect[id] = value;
    }

    function onRangeSelected(id, value) {
      props.globalDataStore.masterFiltersOnSelect[id] = value;
    }

    const onRadioChange = (e) => {
      props.globalDataStore.masterFiltersOnSelect[e.target.name] =
        e.target.value === 'all';
    };

    function onRelationshipProfileSelected(value) {
      if (value && value.indexOf(',') > -1) {
        const res = value.split(',');
        props.globalDataStore.masterFiltersOnSelect.mapped_relationships_filter.mapped_source_filter_name =
          res[2] ? res[2] : '';
        props.globalDataStore.masterFiltersOnSelect.mapped_relationships_filter.mapped_source_b2b_id =
          res[0];
        props.globalDataStore.masterFiltersOnSelect.mapped_relationships_filter.mapped_source_b2c_id =
          res[1];
      } else {
        props.globalDataStore.masterFiltersOnSelect.mapped_relationships_filter =
          {};
      }
    }

    const onAttributesSwitch = (checked) => {
      props.globalDataStore.masterFiltersOnSelect.attribute_function =
        !!checked;
    };

    const onInterestsSwitch = (checked) => {
      props.globalDataStore.masterFiltersOnSelect.all_interests = !!checked;
    };

    function onAttributesChecked(checkedValues) {
      props.globalDataStore.masterFiltersOnSelect.attributes_checked =
        checkedValues;
      if (props.dataStore.currentUser.isAdmin) {
        /** Total length will be excess by one as nuestar id is added as an
         * alternative to household (having same key).
         */
        let attributesLength = attributes.length - 1;

        if (!flags || !flags.verifiedAttributes)
          attributesLength = attributesLength - 5;
        setAttributesAllCheckedByAdmin(
          checkedValues.length === attributesLength,
        );
      }
    }

    const onWealthModalChecked = (checkedValues) => {
      props.globalDataStore.masterFiltersOnSelect.wealth_model_checked = [
        ...checkedValues,
      ];
    };

    /**
     * OnChange listner method import source check box group
     * @param {*} checkedValues
     */
    const onSourceSelected = (checkedValues, name) => {
      try {
        if (name === 'team_file_imports') {
          props.globalDataStore.masterFiltersOnSelect.team_file_imports =
            checkedValues;
        } else if (name === 'file_imports') {
          props.globalDataStore.masterFiltersOnSelect.file_imports =
            checkedValues;
        } else
          props.globalDataStore.masterFiltersOnSelect[name] = checkedValues;
        // props.dataStore.networkFilters.fetchData = true;
      } catch (err) {
        console.error(err);
      }
    };

    const getImportSourceValue = (importSource) => {
      return importSource.map((source) => source.value);
    };

    const onImportFileAllChecked = (e) => {
      switch (e.target.id) {
        case 'import_source_all':
          props.globalDataStore.masterFiltersOnSelect.import_source = e.target
            .checked
            ? props.dataStore.lookUpLists.import_source
            : [];

          break;
        case 'file_imports_all':
          if (e.target.checked) {
            props.globalDataStore.masterFiltersOnSelect.file_imports =
              getImportSourceValue(props.dataStore.lookUpLists.fileImports);
          } else {
            props.globalDataStore.masterFiltersOnSelect.file_imports = [];
          }
          break;
        case 'team_file_imports_all':
          if (e.target.checked) {
            props.globalDataStore.masterFiltersOnSelect.team_file_imports =
              getImportSourceValue(props.dataStore.lookUpLists.teamFileImports);
          } else {
            props.globalDataStore.masterFiltersOnSelect.team_file_imports = [];
          }
          break;

        default:
          break;
      }
      props.dataStore.networkFilters.fetchData = true;
    };

    const getCheckBoxValue = () => {
      return props.dataStore.lookUpLists.teamFileImports.map((sharedCsv) => {
        return (
          <>
            <Checkbox value={sharedCsv.value}>
              <div>{sharedCsv.label}</div>
              {sharedCsv.owner && (
                <div className="owner-main">
                  by{' '}
                  <label
                    className="owner"
                    onClick={(e) =>
                      showPersonProfile(e, sharedCsv.b2bId, sharedCsv.b2cId)
                    }
                  >
                    {sharedCsv.owner}
                  </label>
                </div>
              )}
            </Checkbox>
            <br />
          </>
        );
      });
    };

    const onIrsTaxInsightInputChange = (e, values) => {
      if (values && values.length > 1) {
        props.globalDataStore.masterFiltersOnSelect.irs_tax_insights.gte =
          values[0];
        props.globalDataStore.masterFiltersOnSelect.irs_tax_insights.lte =
          values[1];
      }
    };

    const onIrsFilterChange = (checkedVal) => {
      props.globalDataStore.masterFiltersOnSelect.irs_tax_insights.key =
        checkedVal[0];
    };

    function renderClear({ panelKey }) {
      function onClear() {
        switch (panelKey) {
          case 'Basic':
            props.globalDataStore.resetPrimaryAttributes();
            break;
          case 'Household':
            props.globalDataStore.resetHouseholdAttributes();
            break;
          case 'Professional':
            props.globalDataStore.resetProfessionalAttributes();
            break;
          case 'Relationships':
            props.globalDataStore.resetRelationshipsAttributes();
            break;
          case 'Triggers':
            props.globalDataStore.resetMoneyinmotionAttributes();
            break;
          case 'Company':
            props.globalDataStore.resetCompanyAttributes();
            break;
          case 'US Income Tax Insights':
            props.globalDataStore.resetIrsTaxInsightsAttributes();
            break;
          case 'Property':
            props.globalDataStore.resetProperty();
            break;
          case 'Tags':
            props.globalDataStore.resetTagsAttributes();
            setTagData(props.dataStore.lookUpLists.userTagFolders);
            setCheckedTags([]);
            break;
          case 'Attributes':
            props.globalDataStore.resetAttributes();
            break;
          case 'Wealth Model':
            props.globalDataStore.resetWealthModel();
            break;
          case 'Import Source':
            props.globalDataStore.resetImportSourceAttributes();
            break;
          default:
            break;
        }
      }
      return (
        <div className="clear-filter" onClick={onClear}>
          Clear all
        </div>
      );
    }

    function renderIcon(panelKey, className = 'header-icons') {
      switch (panelKey) {
        case 'Basic':
          return <FlagIcon className={className} />;
        case 'Household':
          return <HouseHoldIcon className={className} />;
        case 'Professional':
          return <Professional className={className} />;
        case 'Relationships':
          return (
            <LinkOutlined style={{ fontSize: '19px' }} className={className} />
          );
        case 'Triggers':
          return <TriggerIcon className={className} />;
        case 'Company':
          return <CompanyIcon className={className} />;
        case 'US Income Tax Insights':
          return (
            <IrsIcon
              style={{
                stroke: 'var(--color-primary)',
                strokeWidth: '1.05px',
              }}
              className={className}
            />
          );
        case 'Property':
          return <PropertyIcon className={className} />;
        case 'Tags':
          return (
            <TagsFilled
              style={{
                fontSize: '20px',
              }}
              className={className}
            />
          );
        case 'Attributes':
          return (
            <AttributeIcon
              style={{
                width: '23px',
                height: '16px',
              }}
              className={className}
            />
          );
        case 'Wealth Model':
          return (
            <DollarOutlined
              style={{
                stroke: 'var(--color-primary)',
                strokeWidth: '1.05px',
              }}
              className={className}
            />
          );
        case 'Import Source':
          return <ConnectIcon className={className} />;
        default:
          return <></>;
      }
    }

    function renderHeaderIcon(panelKey) {
      /**
       * in case of collapsed there are 2 case
       * 1. has any filter applied within that section- if so show checked circle icon
       * 2. otherwise show default filter section icon
       * @panelKey = key specified for each panel
       */

      const hasAnyAttributesApplied = {
        Basic: props.globalDataStore.isPrimaryAttributesApplied,
        Household: props.globalDataStore.isHouseholdAttributesApplied,
        Professional: props.globalDataStore.isProfessionalAttributesApplied,
        Relationships: props.globalDataStore.isRelationshipsApplied,
        Triggers: props.globalDataStore.isMoneyinmotionAttributesApplied,
        Company: props.globalDataStore.isComapnyAttributesApplied,
        'US Income Tax Insights': props.globalDataStore.isIrsTaxInsightsApplied,
        Property: props.globalDataStore.isPropertyApplied,
        Tags:
          props.globalDataStore.isTagsApplied &&
          ((props.dataStore.userTags &&
            props.dataStore.userTags.some((tag) =>
              props.globalDataStore.masterFiltersOnSelect.tags_checked.includes(
                tag.id,
              ),
            )) ||
            (props.dataStore.sharedTags &&
              props.dataStore.sharedTags.some((tag) =>
                props.globalDataStore.masterFiltersOnSelect.shared_tags.includes(
                  tag.tag_id,
                ),
              ))),
        Attributes: props.globalDataStore.isAttributesApplied,
        'Wealth Model': props.globalDataStore.isWealthModelApplied,
        'Import Source': props.globalDataStore.isImportSourceAttributesApplied,
      };

      return (
        <div className="profile-filter-check">
          {panelKey && hasAnyAttributesApplied[panelKey] ? (
            <CheckCircleFilled
              style={{
                color: 'var(--color-high)',
                width: '16px',
              }}
            />
          ) : (
            renderIcon(panelKey)
          )}
        </div>
      );
    }

    function renderFooter() {
      if (!setActiveKey) return null;
      return (
        <div className="filter-footer">
          <Button onClick={handleSave} type="primary" key="save" id="save">
            Save &amp; Close
          </Button>
          <Button onClick={toggleConfirmPopup} key="cancel">
            Cancel
          </Button>
        </div>
      );
    }

    const handleRemove = (removedTag, key) => {
      props.globalDataStore.masterFiltersOnSelect.mapped_relationships_filter[
        key
      ] =
        props.globalDataStore.masterFiltersOnSelect.mapped_relationships_filter[
          key
        ].filter((tag) => tag !== removedTag);
    };

    const renderPreviousPath = () => {
      if (window.location && window.location.pathname) {
        const currentlocation = window.location.pathname;
        const pathArray = currentlocation.split('/');
        const finalPath = pathArray[pathArray.length - 1];
        return urlNames[finalPath] ? `to ${urlNames[finalPath]}` : '';
      }
      return '';
    };

    return (
      <div>
        <Modal
          width="100%"
          // centered
          wrapClassName="profile-filter"
          open
          mask={false}
          closable={false}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="loader-wrapper">
            {isLoading && <LoaderAnimation loaderId="profile-filter-loader" />}
            <div className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
              <div className="back-link link" onClick={onBrowserBack}>
                Back {renderPreviousPath()}
              </div>
              <div className="filter-container">
                <div className="filter-name-container">
                  <div className="name-label">{'Name Your Saved Search:'}</div>
                  <Input
                    // placeholder="Type Name Here"
                    className="filter-name"
                    onChange={handleFilterNameChange}
                    required
                    value={
                      props.globalDataStore.masterFiltersOnSelect
                        .prospect_profile_name
                    }
                  />
                </div>
                <div className="alerts-container">
                  <div>
                    <Checkbox
                      id="email_alert"
                      onChange={handleCheckbox}
                      disabled={
                        props.globalDataStore.masterFiltersOnSelect
                          .separate_email_alert
                      }
                      checked={
                        props.globalDataStore.masterFiltersOnSelect.email_alert
                      }
                    >
                      <span className="label">Apply to daily email alerts</span>
                      <span className="info-email-alert">
                        <i>
                          &nbsp; (Note: If no Triggers are selected, all
                          Triggers will be monitored.)
                        </i>
                      </span>
                    </Checkbox>
                    <br />
                    <Checkbox
                      id="separate_email_alert"
                      onChange={handleCheckbox}
                      checked={
                        props.globalDataStore.masterFiltersOnSelect
                          .separate_email_alert
                      }
                    >
                      <span className="label">
                        Send separate email alert for this filter
                      </span>
                    </Checkbox>
                    {props.globalDataStore.masterFiltersOnSelect.mapQuery && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={deleteContact}
                          alt="Delete "
                          style={{
                            width: '16px',
                            height: '16px',
                            marginRight: '8px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            props.globalDataStore.masterFiltersOnSelect.mapQuery =
                              null;
                          }}
                        />{' '}
                        <span className="label">
                          Filter contains selection criteria from map view
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="save-cancel-filter">{renderFooter()}</div>
                  {/* <div className="info">
                    Note: If no Triggers are selected, all Triggers will be
                    monitored.
                  </div> */}
                </div>
                <Collapse
                  accordion //to make one panel open at a time
                  activeKey={activeKey}
                  onChange={onCollapseChange}
                  expandIcon={({ isActive }) => {
                    return isActive ? <UpCircleOutlined /> : <DownOutlined />;
                  }}
                  expandIconPosition="end"
                  //   expandIcon={renderExpandIcon}
                  className="filter-collapse-container"
                  ghost // Make the collapse borderless and its background transparent
                >
                  <CustomPanel
                    key="Basic"
                    header="Basic"
                    HeaderIcon={() => renderHeaderIcon('Basic')}
                    Clear={renderClear}
                    Footer={renderFooter}
                  >
                    <>
                      <div className="degree-connections-new outer-container">
                        <div>
                          <span
                            style={{
                              fontSize: '10px',
                              color: 'var(--color-font-grey)',
                            }}
                          >
                            INTRODUCTION OPPORTUNITIES{' '}
                          </span>
                          <HelpIcon
                            id="info-degrees"
                            className="info-icon"
                            height="12px"
                            width="12px"
                          />
                        </div>
                        <div
                          className={
                            (props.globalDataStore.masterFiltersOnSelect
                              .relmap_org &&
                              props.globalDataStore.masterFiltersOnSelect
                                .relmap_org.length > 0) ||
                            (flags &&
                              flags.csvImportsAndDegrees &&
                              props.globalDataStore.masterFiltersOnSelect
                                .filter_type === 'all' &&
                              ((props.globalDataStore.masterFiltersOnSelect
                                .file_imports &&
                                props.globalDataStore.masterFiltersOnSelect
                                  .file_imports.length > 0) ||
                                (props.globalDataStore.masterFiltersOnSelect
                                  .team_file_imports &&
                                  props.globalDataStore.masterFiltersOnSelect
                                    .team_file_imports.length > 0)))
                              ? 'tags-container tags-container-disabled'
                              : 'tags-container'
                          }
                        >
                          <CheckableTag
                            className="tag1"
                            key={'first_degree'}
                            checked={
                              props.globalDataStore.masterFiltersOnSelect
                                .filter_type === 'first_degree' ||
                              props.globalDataStore.masterFiltersOnSelect
                                .filter_type === 'combined'
                            }
                            onChange={(checked) =>
                              handleDegreeConnectionsChange(
                                'first_degree',
                                checked,
                              )
                            }
                          >
                            <span>Your Network</span>
                            <span
                              style={{
                                fontSize: '10px',
                                color: 'var(--color-font-grey)',
                              }}
                            >
                              {' '}
                              1st
                            </span>
                          </CheckableTag>
                          <CheckableTag
                            className="tag2"
                            key={'second_degree'}
                            checked={
                              props.globalDataStore.masterFiltersOnSelect
                                .filter_type === 'second_degree' ||
                              props.globalDataStore.masterFiltersOnSelect
                                .filter_type === 'combined'
                            }
                            onChange={(checked) =>
                              handleDegreeConnectionsChange(
                                'second_degree',
                                checked,
                              )
                            }
                          >
                            <span>Get Introduced </span>
                            <span
                              style={{
                                fontSize: '10px',
                                color: 'var(--color-font-grey)',
                              }}
                            >
                              {' '}
                              2nd
                            </span>
                          </CheckableTag>

                          <CheckableTag
                            className="tag3"
                            key={'all'}
                            checked={
                              props.globalDataStore.masterFiltersOnSelect
                                .filter_type === 'all'
                            }
                            onChange={(checked) =>
                              handleDegreeConnectionsChange('all', checked)
                            }
                          >
                            <span>Everyone</span>
                          </CheckableTag>
                        </div>
                      </div>

                      <div className="flex-container outer-container">
                        <div>
                          <MultiDataSearch
                            id="location_city"
                            placeholder="Home City"
                            isMulti
                            from="masterFilter"
                            onItemSelected={onDropDownItemSlected}
                          />
                        </div>

                        <div className="drop-down-div">
                          <DataSelect
                            id="location_state"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .location_state
                            }
                            dropDownList={filterStates}
                            placeholder="State"
                            isMulti
                            onItemSelected={onDropDownItemSlected}
                          />
                        </div>
                      </div>

                      <div className="flex-container outer-container">
                        <CreatableSelect
                          id="org_name"
                          placeholder="Company"
                          isMulti
                          from="masterFilter"
                        />

                        <CreatableSelect
                          id="title"
                          placeholder="Title"
                          isMulti
                          from="masterFilter"
                        />
                        <CreatableSelect
                          id="schools"
                          placeholder="Schools"
                          isMulti
                          from="masterFilter"
                        />

                        <>
                          <div className="drop-down-div with-info-icon">
                            <DataSelect
                              id="inferred_salary_range"
                              from="master"
                              value={
                                props.globalDataStore.masterFiltersOnSelect
                                  .inferred_salary_range
                              }
                              isMulti
                              disabled={
                                newProfileActive && !hasPremiumWealthTriggers
                              }
                              dropDownList={salaryFilter}
                              placeholder="Inferred Income"
                              onItemSelected={onDropDownItemSlected}
                            />
                            <HelpIcon
                              id="info-inferred-salary"
                              className="help-icon-new"
                            />
                          </div>
                          <div className="drop-down-div with-info-icon">
                            <DataSelect
                              id="wealth_estimate"
                              from="master"
                              value={
                                props.globalDataStore.masterFiltersOnSelect
                                  .wealth_estimate
                              }
                              isMulti
                              isBeta={false}
                              dropDownList={wealthScoreRanges}
                              placeholder="Wealth Segment"
                              onItemSelected={onDropDownItemSlected}
                              disabled={
                                (newProfileActive &&
                                  !hasPremiumWealthTriggers) ||
                                (props.globalDataStore.masterFiltersOnSelect
                                  .wealth_estimate_range &&
                                props.globalDataStore.masterFiltersOnSelect
                                  .wealth_estimate_range.value &&
                                props.globalDataStore.masterFiltersOnSelect
                                  .wealth_estimate_range.value !== ''
                                  ? true
                                  : false)
                              }
                            />
                            <HelpIcon
                              id="info-wealth-estimate"
                              className="help-icon-new"
                            />
                          </div>
                        </>
                      </div>
                    </>
                  </CustomPanel>
                  <CustomPanel
                    key="Household"
                    header="Household"
                    HeaderIcon={() => renderHeaderIcon('Household')}
                    Clear={renderClear}
                    Footer={renderFooter}
                  >
                    <>
                      <div className="flex-container outer-container">
                        <div className="drop-down-div">
                          <div className="dropdown">
                            <div className="item-head">Home Search</div>
                            <div style={{ marginBottom: '11px' }}>
                              <Radio.Group
                                name="all_homes"
                                onChange={onRadioChange}
                                value={
                                  props.globalDataStore.masterFiltersOnSelect
                                    .all_homes
                                    ? 'all'
                                    : 'primary'
                                }
                              >
                                <Radio
                                  style={{
                                    color: 'var(--color-grey-dark)',
                                  }}
                                  value="primary"
                                >
                                  Primary
                                </Radio>
                                <Radio
                                  style={{
                                    color: 'var(--color-grey-dark)',
                                  }}
                                  // defaultChecked = {true}
                                  value="all"
                                >
                                  All
                                </Radio>
                              </Radio.Group>
                            </div>
                          </div>
                        </div>
                        <div>
                          <CreatableSelect
                            id="home_address_suggest"
                            placeholder="Home Street Address"
                            isMulti
                            from="masterFilter"
                          />
                        </div>
                        {flags && flags.excludeHomeAddress && (
                          <div>
                            <CreatableSelect
                              id="exc_home_address_suggest"
                              placeholder="Exclude Home Address"
                              isMulti
                              className="relative"
                              from="masterFilter"
                            />
                          </div>
                        )}

                        <div>
                          <MultiDataSearch
                            id="location_city"
                            placeholder="Home City"
                            isMulti
                            from="masterFilter"
                            onItemSelected={onDropDownItemSlected}
                          />
                        </div>
                        <div>
                          <div className="placeholder-active with-info-icon">
                            Geo Home City
                            <HelpIcon
                              id="info-home-search"
                              className="help-icon-new"
                            />
                          </div>
                          <LocationSearch
                            id="home_search"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .home_search
                            }
                            placeholder="Geo Home City"
                            onLocationSelected={onLocationSelected}
                            isDisabled={
                              props.globalDataStore.masterFiltersOnSelect
                                .all_homes
                            }
                            from="master"
                          />
                        </div>
                        <div>
                          <LocationSlider
                            radius={
                              props.globalDataStore.masterFiltersOnSelect
                                .slider_radius
                            }
                            onChange={onLocationSliderChanged}
                            isDisabled={
                              props.globalDataStore.masterFiltersOnSelect[
                                'home_search'
                              ].address === '' ||
                              props.globalDataStore.masterFiltersOnSelect
                                .all_homes
                            }
                          />
                        </div>
                        {/* </div>
                      <div className="flex-container outer-container"> */}

                        <div className="drop-down-div">
                          <DataSelect
                            id="location_state"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .location_state
                            }
                            dropDownList={filterStates}
                            placeholder="State"
                            isMulti
                            onItemSelected={onDropDownItemSlected}
                          />
                        </div>
                        <div>
                          <MultiDataSearch
                            id="county"
                            placeholder="County"
                            isDisabled={
                              props.globalDataStore.masterFiltersOnSelect
                                .all_homes
                            }
                            isMulti
                            from="masterFilter"
                          />
                        </div>
                        <div className="range zip-range">
                          <div className="rangepicker-placeholder-active">
                            Zip Code
                          </div>
                          <PostalCodePicker
                            id="home_postal_code"
                            from="master"
                            zipCode={
                              props.globalDataStore.masterFiltersOnSelect
                                .home_postal_code
                            }
                            onValueSelected={onRangeSelected}
                          />
                        </div>
                      </div>
                      <div className="flex-container outer-container">
                        <div className="drop-down-div with-info-icon">
                          <DataSelect
                            id="interests"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .interests
                            }
                            isMulti
                            dropDownList={interests}
                            placeholder="Modeled Interests"
                            onItemSelected={onDropDownItemSlected}
                          />
                          <HelpIcon
                            id="info-interests"
                            className="help-icon-new"
                          />
                        </div>
                        <div>
                          <div className="item-head">Apply Interests</div>
                          <Switch
                            style={{ marginTop: '8px' }}
                            size="small"
                            checkedChildren="ALL"
                            unCheckedChildren="ANY"
                            checked={
                              props.globalDataStore.masterFiltersOnSelect
                                .all_interests
                            }
                            onChange={onInterestsSwitch}
                          />
                        </div>
                      </div>

                      <div className="flex-container outer-container">
                        <div className="range">
                          <div className="rangepicker-placeholder-active">
                            Age
                          </div>
                          <AgePicker
                            id="age"
                            age={
                              props.globalDataStore.masterFiltersOnSelect.age
                            }
                            onValueSelected={onRangeSelected}
                          />
                        </div>
                        <div className="drop-down-div">
                          <DataSelect
                            id="gender"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect.gender
                            }
                            dropDownList={gender}
                            placeholder="Sex"
                            isMulti={false}
                            onItemSelected={onDropDownItemSlected}
                            closeMenuOnSelect
                          />
                        </div>
                        <div className="drop-down-div with-info-icon">
                          <DataSelect
                            id="marital_status"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .marital_status
                            }
                            dropDownList={maritalStatus}
                            placeholder="Modeled Marital Status"
                            isMulti={false}
                            onItemSelected={onDropDownItemSlected}
                            closeMenuOnSelect
                          />
                          <HelpIcon
                            id="info-marital-status"
                            className="help-icon-new"
                          />
                        </div>
                        <div className="drop-down-div with-info-icon">
                          <DataSelect
                            id="presence_of_children"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .presence_of_children
                            }
                            dropDownList={ModeledChildPresenceValues}
                            placeholder="Modeled Presence of Children"
                            closeMenuOnSelect
                            onItemSelected={onDropDownItemSlected}
                          />
                          <HelpIcon
                            id="info-presence-of-children"
                            className="help-icon-new"
                          />
                        </div>
                      </div>
                    </>
                  </CustomPanel>
                  <CustomPanel
                    key="Professional"
                    header="Professional"
                    HeaderIcon={() => renderHeaderIcon('Professional')}
                    Clear={renderClear}
                    Footer={renderFooter}
                  >
                    <>
                      <div className="flex-container outer-container">
                        <div className="drop-down-div with-info-icon">
                          <DataSelect
                            id="inferred_salary_range"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .inferred_salary_range
                            }
                            isMulti
                            disabled={
                              newProfileActive && !hasPremiumWealthTriggers
                            }
                            dropDownList={salaryFilter}
                            placeholder="Inferred Income"
                            onItemSelected={onDropDownItemSlected}
                          />
                          <HelpIcon
                            id="info-inferred-salary"
                            className="help-icon-new"
                          />
                        </div>
                        <div className="drop-down-div with-info-icon">
                          <DataSelect
                            id="wealth_estimate"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .wealth_estimate
                            }
                            isMulti
                            isBeta={false}
                            dropDownList={wealthScoreRanges}
                            placeholder="Wealth Segment"
                            onItemSelected={onDropDownItemSlected}
                            disabled={
                              (newProfileActive && !hasPremiumWealthTriggers) ||
                              (props.globalDataStore.masterFiltersOnSelect
                                .wealth_estimate_range &&
                              props.globalDataStore.masterFiltersOnSelect
                                .wealth_estimate_range.value &&
                              props.globalDataStore.masterFiltersOnSelect
                                .wealth_estimate_range.value !== ''
                                ? true
                                : false)
                            }
                          />
                          <HelpIcon
                            id="info-wealth-estimate"
                            className="help-icon-new"
                          />
                        </div>

                        {flags && flags.rangeForWealthSegment && (
                          <div className="range">
                            <div className="rangepicker-placeholder-active">
                              Wealth Segment Range
                            </div>
                            <NumberRangePicker
                              id="wealth_estimate_range"
                              placeholder="Wealth Segment Range"
                              number={
                                props.globalDataStore.masterFiltersOnSelect
                                  .wealth_estimate_range
                              }
                              onValueSelected={onRangeSelected}
                              disabled={
                                (newProfileActive &&
                                  !hasPremiumWealthTriggers) ||
                                (props.globalDataStore.masterFiltersOnSelect
                                  .wealth_estimate &&
                                props.globalDataStore.masterFiltersOnSelect
                                  .wealth_estimate.length > 0
                                  ? true
                                  : false)
                              }
                            />
                          </div>
                        )}
                        <div className="drop-down-div with-info-icon">
                          <DataSelect
                            id="remaining_share_value_sum"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .remaining_share_value_sum
                            }
                            disabled={
                              newProfileActive && !hasPremiumWealthTriggers
                            }
                            dropDownList={remainingShareValues}
                            placeholder="Remaining Share Balance"
                            isMulti
                            isBeta={false}
                            onItemSelected={onDropDownItemSlected}
                          />
                          <HelpIcon
                            id="info-remaining-share"
                            className="help-icon-new"
                          />
                        </div>

                        <CreatableSelect
                          id="org_name"
                          placeholder="Company"
                          isMulti
                          from="masterFilter"
                        />
                      </div>

                      <div className="flex-container outer-container">
                        <CreatableSelect
                          id="title"
                          placeholder="Title"
                          isMulti
                          from="masterFilter"
                        />
                        <div className="drop-down-div">
                          <DataSelect
                            id="title_rank"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .title_rank
                            }
                            dropDownList={
                              props.dataStore.lookUpLists.titleRankList
                            }
                            placeholder="Title Group"
                            isMulti
                            onItemSelected={onDropDownItemSlected}
                          />
                        </div>

                        <div className="drop-down-div">
                          <DataSelect
                            id="credentials"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .credentials
                            }
                            dropDownList={credentialsLookup}
                            placeholder="Credentials"
                            isMulti
                            onItemSelected={onDropDownItemSlected}
                          />
                        </div>

                        <div className="drop-down-div">
                          <DataSelect
                            id="exc_title_rank"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .exc_title_rank
                            }
                            dropDownList={
                              props.dataStore.lookUpLists.titleRankList
                            }
                            placeholder="Exclude Title Group"
                            isMulti
                            onItemSelected={onDropDownItemSlected}
                          />
                        </div>
                        <CreatableSelect
                          id="exc_title"
                          placeholder="Exclude Title"
                          isMulti
                          from="masterFilter"
                        />
                      </div>

                      <div className="flex-container outer-container">
                        <DataSelect
                          id="title_role"
                          value={
                            props.globalDataStore.masterFiltersOnSelect
                              .title_role
                          }
                          dropDownList={props.dataStore.lookUpLists.titleRole}
                          placeholder="Function"
                          isMulti
                          from="master"
                          onItemSelected={onDropDownItemSlected}
                        />
                        <div>
                          <MultiDataSearch
                            id="previous_experience_org_name"
                            placeholder="Previous Companies"
                            isMulti
                            from="masterFilter"
                            onItemSelected={onDropDownItemSlected}
                          />
                        </div>

                        <div style={{ marginTop: '10px' }}>
                          <CreatableSelect
                            id="exc_current_experience"
                            placeholder="Exclude Companies"
                            isMulti
                            from="masterFilter"
                            //onSelect={() => handleShowSavedFilter(true)
                          />
                        </div>

                        <div className="range">
                          <div className="rangepicker-placeholder-active">
                            Years of Experience at Current Employer
                          </div>
                          <NumberRangePicker
                            id="current_org_years"
                            placeholder="Years of Experience"
                            number={
                              props.globalDataStore.masterFiltersOnSelect
                                .current_org_years
                            }
                            onValueSelected={onRangeSelected}
                          />
                        </div>
                        <div className="range">
                          <div className="rangepicker-placeholder-active">
                            Years of Career Experience
                          </div>
                          <NumberRangePicker
                            id="total_years_of_experience"
                            placeholder="Years of Career Experience"
                            number={
                              props.globalDataStore.masterFiltersOnSelect
                                .total_years_of_experience
                            }
                            onValueSelected={onRangeSelected}
                          />
                        </div>

                        <CreatableSelect
                          id="schools"
                          placeholder="Schools"
                          isMulti
                          from="masterFilter"
                        />
                      </div>
                    </>
                  </CustomPanel>
                  <CustomPanel
                    key="Relationships"
                    header="Relationships"
                    HeaderIcon={() => renderHeaderIcon('Relationships')}
                    Clear={renderClear}
                    Footer={renderFooter}
                  >
                    <>
                      <div className="flex-container outer-container relationships">
                        <div className="degree-connections-new relationships-new">
                          <div>
                            <span
                              style={{
                                fontSize: '10px',
                                color: 'var(--color-font-grey)',
                              }}
                            >
                              INTRODUCTION OPPORTUNITIES{' '}
                            </span>
                            <HelpIcon
                              id="info-degrees"
                              className="info-icon"
                              height="12px"
                              width="12px"
                            />
                          </div>
                          <div
                            className={
                              (props.globalDataStore.masterFiltersOnSelect
                                .relmap_org &&
                                props.globalDataStore.masterFiltersOnSelect
                                  .relmap_org.length > 0) ||
                              (flags &&
                                flags.csvImportsAndDegrees &&
                                props.globalDataStore.masterFiltersOnSelect
                                  .filter_type === 'all' &&
                                ((props.globalDataStore.masterFiltersOnSelect
                                  .file_imports &&
                                  props.globalDataStore.masterFiltersOnSelect
                                    .file_imports.length > 0) ||
                                  (props.globalDataStore.masterFiltersOnSelect
                                    .team_file_imports &&
                                    props.globalDataStore.masterFiltersOnSelect
                                      .team_file_imports.length > 0)))
                                ? 'tags-container tags-container-disabled'
                                : 'tags-container'
                            }
                          >
                            <CheckableTag
                              className="tag1"
                              key={'first_degree'}
                              checked={
                                props.globalDataStore.masterFiltersOnSelect
                                  .filter_type === 'first_degree' ||
                                props.globalDataStore.masterFiltersOnSelect
                                  .filter_type === 'combined'
                              }
                              onChange={(checked) =>
                                handleDegreeConnectionsChange(
                                  'first_degree',
                                  checked,
                                )
                              }
                            >
                              <span>Your Network</span>
                              <span
                                style={{
                                  fontSize: '10px',
                                  color: 'var(--color-font-grey)',
                                }}
                              >
                                {' '}
                                1st
                              </span>
                            </CheckableTag>

                            <CheckableTag
                              className="tag2"
                              key={'second_degree'}
                              checked={
                                props.globalDataStore.masterFiltersOnSelect
                                  .filter_type === 'second_degree' ||
                                props.globalDataStore.masterFiltersOnSelect
                                  .filter_type === 'combined'
                              }
                              onChange={(checked) =>
                                handleDegreeConnectionsChange(
                                  'second_degree',
                                  checked,
                                )
                              }
                            >
                              <span>Get Introduced </span>
                              <span
                                style={{
                                  fontSize: '10px',
                                  color: 'var(--color-font-grey)',
                                }}
                              >
                                {' '}
                                2nd
                              </span>
                            </CheckableTag>

                            <CheckableTag
                              className="tag3"
                              key={'all'}
                              checked={
                                props.globalDataStore.masterFiltersOnSelect
                                  .filter_type === 'all'
                              }
                              onChange={(checked) =>
                                handleDegreeConnectionsChange('all', checked)
                              }
                            >
                              <span>Everyone</span>
                            </CheckableTag>
                          </div>
                        </div>
                        <div>
                          <DataSelect
                            id="number_of_mapped_connections"
                            styleChange
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .number_of_mapped_connections
                            }
                            dropDownList={mappedConnectionsCount}
                            placeholder="# of Mapped Relationships"
                            isMulti
                            from="master"
                            // TODO: remove this onSelect when changing flag check
                            onItemSelected={onDropDownItemSlected}
                          />
                        </div>
                        <div className="rel-search">
                          <div className={'placeholder-active'}>
                            Person’s Relationships
                          </div>
                          <div
                            className="search-filter"
                            style={{
                              paddingTop: '0px',
                            }}
                          >
                            <GoogleSearch
                              from="filter"
                              value={
                                props.globalDataStore.masterFiltersOnSelect
                                  ?.mapped_relationships_filter
                                  ?.mapped_source_filter_name
                              }
                              onSearch={(val) =>
                                (props.globalDataStore.masterFiltersOnSelect.mapped_relationships_filter.mapped_source_filter_name =
                                  val)
                              }
                              onSelect={onRelationshipProfileSelected}
                            />
                          </div>
                        </div>
                        <div className="with-info-icon">
                          <div className={'placeholder-active'}>
                            Employee Relationship Mapping
                          </div>
                          <div
                            style={{
                              paddingTop: '0px',
                            }}
                          >
                            <MultiDataSearch
                              id="relmap_org"
                              placeholder="Company Name"
                              isMulti
                              from="masterFilter"
                            />
                            <HelpIcon
                              id="info-relmap-org"
                              className="help-icon-new"
                            />
                          </div>
                        </div>

                        {props.globalDataStore.masterFiltersOnSelect
                          ?.mapped_relationships_filter
                          ?.mapped_relationship_weights?.length > 0 && (
                          <div className="dropdown dropdown-select">
                            <div className="placeholder-active">
                              Relationship Weights
                            </div>
                            <div className="tag-container ">
                              <div className="tags">
                                {props.globalDataStore.masterFiltersOnSelect?.mapped_relationships_filter?.mapped_relationship_weights.map(
                                  (item) => (
                                    <Tag
                                      closable
                                      className="tag"
                                      onClose={(e) => {
                                        e.preventDefault();
                                        handleRemove(
                                          item,
                                          'mapped_relationship_weights',
                                        );
                                      }}
                                    >
                                      {item === 'medium'
                                        ? 'Med'
                                        : item?.toString().toTitleCase()}
                                    </Tag>
                                  ),
                                )}
                              </div>
                              <div className="close-button">
                                <CloseOutlined
                                  onClick={() =>
                                    (props.globalDataStore.masterFiltersOnSelect.mapped_relationships_filter.mapped_relationship_weights =
                                      [])
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {props.globalDataStore.masterFiltersOnSelect
                          ?.mapped_relationships_filter
                          ?.mapped_relationship_types?.length > 0 && (
                          <div className="dropdown dropdown-select">
                            <div className="placeholder-active">
                              Relationship Types
                            </div>
                            <div className="tag-container ">
                              <div className="tags">
                                {props.globalDataStore.masterFiltersOnSelect?.mapped_relationships_filter?.mapped_relationship_types.map(
                                  (item) => (
                                    <Tag
                                      closable
                                      className="tag"
                                      onClose={(e) => {
                                        e.preventDefault();
                                        handleRemove(
                                          item,
                                          'mapped_relationship_types',
                                        );
                                      }}
                                    >
                                      {item?.toString().toTitleCase()}
                                    </Tag>
                                  ),
                                )}
                              </div>
                              <div className="close-button">
                                <CloseOutlined
                                  onClick={() =>
                                    (props.globalDataStore.masterFiltersOnSelect.mapped_relationships_filter.mapped_relationship_types =
                                      [])
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  </CustomPanel>
                  <CustomPanel
                    key="Triggers"
                    header="Triggers"
                    HeaderIcon={() => renderHeaderIcon('Triggers')}
                    showHelpModal={showHelpModal}
                    Clear={renderClear}
                    Footer={renderFooter}
                  >
                    <>
                      <div className="flex-container outer-container">
                        <div className="drop-down-div">
                          <DataSelect
                            id="latest_mim_event_type"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .latest_mim_event_type
                            }
                            dropDownList={props.dataStore.lookUpLists.eventList}
                            formatOptionLabel={(data) => {
                              // this can add the 'premium key' icons to the fitler to indicate that the user
                              // does not have access to the fitler. if the user has PREM access or the
                              // new profiel view is not active, then the filter will display as normal.
                              return (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: data.unsafeHtml || data.label,
                                  }}
                                />
                              );
                            }}
                            placeholder="Triggers"
                            isMulti
                            onItemSelected={onDropDownItemSlected}
                          />
                        </div>

                        <div className="drop-down-div">
                          <DataSelect
                            id="funding_round"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .funding_round
                            }
                            dropDownList={fundingRoundList}
                            disabled={
                              newProfileActive && !hasPremiumWealthTriggers
                            }
                            placeholder="Funding Round"
                            isMulti
                            onItemSelected={onDropDownItemSlected}
                          />
                        </div>
                        <div>
                          <DataSelect
                            id="latest_mim_monetary_value"
                            key="latest_mim_monetary_value"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .latest_mim_monetary_value
                            }
                            isMulti
                            disabled={
                              newProfileActive && !hasPremiumWealthTriggers
                            }
                            isBeta={false}
                            dropDownList={wealthScoreRanges}
                            placeholder="Value"
                            onItemSelected={onDropDownItemSlected}
                          />
                        </div>
                        {/* )} */}
                      </div>
                      <div
                        className="flex-container outer-container"
                        style={{ alignItems: 'flex-end' }}
                      >
                        <div className="drop-down-div">
                          <DataSelect
                            id="period"
                            value={
                              props.globalDataStore.masterFiltersOnSelect.period
                            }
                            dropDownList={periodList}
                            placeholder="Period"
                            isMulti={false}
                            from="master"
                            onItemSelected={onDropDownItemSlected}
                            disabled={
                              !props.globalDataStore.masterFiltersOnSelect
                                .period &&
                              props.globalDataStore.masterFiltersOnSelect
                                .m2_date &&
                              props.globalDataStore.masterFiltersOnSelect
                                .m2_date.length > 0
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div>
                          <div className="rangepicker-placeholder-active">
                            Time Frame
                          </div>
                          <DateRangePicker
                            id="m2_date"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .m2_date
                            }
                            onCalendarChange={onDateRangeChange}
                            disabled={
                              props.globalDataStore.masterFiltersOnSelect
                                .period &&
                              props.globalDataStore.masterFiltersOnSelect.period
                                .value !== ''
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </>
                  </CustomPanel>
                  <CustomPanel
                    key="Company"
                    header="Company"
                    HeaderIcon={() => renderHeaderIcon('Company')}
                    Clear={renderClear}
                    Footer={renderFooter}
                  >
                    <>
                      <div className="flex-container outer-container">
                        {/* <div>
                          <div className="item-head">
                            Company Location (10-mile radius)
                          </div>
                          <LocationSearch
                            id="company_search"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .company_search
                            }
                            placeholder="Company Location (10-mile radius)"
                            onLocationSelected={onLocationSelected}
                            from="master"
                          />
                        </div> */}
                        <div>
                          <DataSelect
                            id="industry"
                            styleChange
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .industry
                            }
                            dropDownList={
                              props.dataStore.lookUpLists.industries
                            }
                            placeholder="Industries"
                            isMulti
                            from="master"
                            isSearchable
                            // TODO: remove this onSelect when changing flag check
                            onItemSelected={onDropDownItemSlected}
                          />
                        </div>
                        <div>
                          <DataSelect
                            id="exc_industry"
                            styleChange
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .exc_industry
                            }
                            dropDownList={
                              props.dataStore.lookUpLists.industries
                            }
                            placeholder="Exclude Industries"
                            isMulti
                            from="master"
                            isSearchable
                            // TODO: remove this onSelect when changing flag check
                            onItemSelected={onDropDownItemSlected}
                          />
                        </div>

                        <TreeDataSelect
                          id="naics_codes"
                          styleChange
                          from="master"
                          dropDownList={props.dataStore.lookUpLists.naicsList}
                          value={
                            props.globalDataStore.masterFiltersOnSelect
                              .naics_codes
                          }
                          placeholder="NAICS"
                          isMulti
                          isSearchable
                          // TODO: remove this onSelect when changing flag check
                          onItemSelected={onDropDownItemSlected}
                        />

                        <div className="employee_confidence with-info-icon">
                          <DataSelect
                            id="stock_performance"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .stock_performance
                            }
                            isMulti
                            dropDownList={companyViablityRanges}
                            placeholder={
                              <div className="help_icon">
                                Company Viability
                                <div
                                  className="toolTipSummary"
                                  style={{
                                    paddingRight: '5px',
                                    margin: 'auto 0px',
                                  }}
                                />
                              </div>
                            }
                            onItemSelected={onDropDownItemSlected}
                          />
                          <HelpIcon
                            id="info-stock_performance"
                            className="help-icon-new"
                          />
                        </div>
                      </div>
                      <div className="flex-container outer-container">
                        {/* <div className="employee_confidence with-info-icon">
                          <DataSelect
                            id="stock_performance"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .stock_performance
                            }
                            isMulti
                            dropDownList={companyViablityRanges}
                            placeholder={
                              <div className="help_icon">
                                Company Viability
                                <div
                                  className="toolTipSummary"
                                  style={{
                                    paddingRight: '5px',
                                    margin: 'auto 0px',
                                  }}
                                />
                              </div>
                            }
                            onItemSelected={onDropDownItemSlected}
                          />
                          <HelpIcon
                            id="info-stock_performance"
                            className="help-icon"
                            height="14px"
                            width="14px"
                          />
                        </div> */}

                        <div className="drop-down-div">
                          <DataSelect
                            id="employee_count"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .employee_count
                            }
                            isMulti
                            dropDownList={companySizeFilter}
                            placeholder="Company Headcount"
                            onItemSelected={onDropDownItemSlected}
                          />
                        </div>
                        <div className="drop-down-div">
                          <DataSelect
                            id="company_type"
                            from="master"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .company_type
                            }
                            dropDownList={companyType}
                            placeholder="Company Type"
                            isMulti={false}
                            onItemSelected={onDropDownItemSlected}
                            closeMenuOnSelect
                          />
                        </div>
                      </div>
                    </>
                  </CustomPanel>
                  <CustomPanel
                    key="US Income Tax Insights"
                    header="US Income Tax Insights"
                    HeaderIcon={() =>
                      renderHeaderIcon('US Income Tax Insights')
                    }
                    Clear={renderClear}
                    Footer={renderFooter}
                  >
                    <div className="irs-section outer-container">
                      <div className="irs-range">
                        <div
                          style={{
                            color: 'var(--color-dark)',
                            fontWeight: 'bold',
                          }}
                        >
                          Step 1: Enter $ Range:
                        </div>
                        <div
                          className="irs-range"
                          style={{ padding: 0, width: '200px' }}
                        >
                          <IrsInsightsRangePicker
                            irsInsightsPicker
                            id="irs_tax_insights"
                            placeholder="Enter $ Range"
                            number={
                              props.globalDataStore.masterFiltersOnSelect
                                .irs_tax_insights
                            }
                            onValueSelected={onIrsTaxInsightInputChange}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          color: 'var(--color-dark)',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                        }}
                      >
                        Step 2: Select 1 Attribute to Filter:
                        <HelpIcon
                          className="help-icon"
                          height="14px"
                          width="14px"
                          onClick={() =>
                            (props.modalDataStore.taxDescriptionModalVisible = true)
                          }
                        />
                      </div>
                      <Checkbox.Group
                        options={
                          flags && flags.dntfd8933NewProfileView
                            ? IrsItemsNew
                            : IrsItems
                        }
                        value={
                          props.globalDataStore.masterFiltersOnSelect
                            .irs_tax_insights
                            ? props.globalDataStore.masterFiltersOnSelect
                                .irs_tax_insights.key
                            : []
                        }
                        disabled={
                          !props.globalDataStore.masterFiltersOnSelect
                            .irs_tax_insights.gte ||
                          !props.globalDataStore.masterFiltersOnSelect
                            .irs_tax_insights.lte
                        }
                        onChange={onIrsFilterChange}
                      />
                    </div>
                  </CustomPanel>
                  <CustomPanel
                    key="Property"
                    header="Property"
                    HeaderIcon={() => renderHeaderIcon('Property')}
                    Clear={renderClear}
                    Footer={renderFooter}
                  >
                    <div className="property-container">
                      <div className="flex-container outer-container">
                        <DataSelect
                          id="norm_home_owner"
                          value={
                            props.globalDataStore.masterFiltersOnSelect
                              .norm_home_owner
                          }
                          isMulti
                          dropDownList={homeOwnerStatusList}
                          disabled={newProfileActive && !hasPremiumProperty}
                          placeholder="Home Owner Status"
                          onItemSelected={onDropDownItemSlected}
                          from="master"
                        />
                        <DataSelect
                          id="number_of_owned_properties"
                          styleChange
                          value={
                            props.globalDataStore.masterFiltersOnSelect
                              .number_of_owned_properties
                          }
                          dropDownList={Properties2To5}
                          placeholder="Multiple Properties"
                          isMulti
                          // isSearchable
                          onItemSelected={onDropDownItemSlected}
                          from="master"
                        />
                        <DataSelect
                          id="property_type"
                          value={
                            props.globalDataStore.masterFiltersOnSelect
                              .property_type
                          }
                          isMulti
                          disabled={newProfileActive && !hasPremiumProperty}
                          dropDownList={propertyTypes}
                          placeholder="Property Type"
                          onItemSelected={onDropDownItemSlected}
                          from="master"
                        />

                        <DataSelect
                          id="home_value_estimate"
                          value={
                            props.globalDataStore.masterFiltersOnSelect
                              .home_value_estimate
                          }
                          isMulti
                          dropDownList={propertyValues}
                          disabled={newProfileActive && !hasPremiumProperty}
                          placeholder="Property Value"
                          onItemSelected={onDropDownItemSlected}
                          from="master"
                        />

                        <div className="range">
                          <div className="rangepicker-placeholder-active">
                            Home Purchase Date
                          </div>
                          <ExactDateRangePicker
                            placeholder="Home Purchase Date"
                            id="home_purchase_date"
                            disabled={newProfileActive && !hasPremiumProperty}
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .home_purchase_date
                            }
                            onCalendarChange={onDateRangeChange}
                          />
                        </div>
                        <div className="range">
                          <div className="rangepicker-placeholder-active">
                            Home Sale Date
                          </div>
                          <ExactDateRangePicker
                            placeholder="Home Sale Date"
                            disabled={newProfileActive && !hasPremiumProperty}
                            id="home_sale_date"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .home_sale_date
                            }
                            onCalendarChange={onDateRangeChange}
                          />
                        </div>

                        <DataSelect
                          id="total_number_of_rooms"
                          styleChange
                          value={
                            props.globalDataStore.masterFiltersOnSelect
                              .total_number_of_rooms
                          }
                          dropDownList={Numbers1To10}
                          placeholder="Total Number of Rooms"
                          isMulti
                          from="master"
                          // isSearchable
                          // TODO: remove this onSelect when changing flag check
                          onItemSelected={onDropDownItemSlected}
                        />

                        <DataSelect
                          id="lotsize_square_feet"
                          styleChange
                          value={
                            props.globalDataStore.masterFiltersOnSelect
                              .lotsize_square_feet
                          }
                          dropDownList={lotSizeRanges}
                          placeholder="Lot Square Feet"
                          isMulti
                          from="master"
                          // isSearchable
                          onItemSelected={onDropDownItemSlected}
                        />

                        <div>
                          <div className="rangepicker-placeholder-active">
                            Effective Year Built
                          </div>
                          <RangePicker
                            id="effective_year_built"
                            style={{
                              fontSize: '12px',
                            }}
                            className="date-range-picker"
                            // placeholder="Effective Year Built"
                            onChange={onYearChange}
                            picker="year"
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .effective_year_built &&
                              props.globalDataStore.masterFiltersOnSelect
                                .effective_year_built.length > 0
                                ? props.globalDataStore.masterFiltersOnSelect
                                    .effective_year_built
                                : null
                            }
                            disabledDate={(current) =>
                              current && current > dayjs().endOf('day')
                            }
                          />
                        </div>

                        <DataSelect
                          id="number_of_bedrooms"
                          styleChange
                          value={
                            props.globalDataStore.masterFiltersOnSelect
                              .number_of_bedrooms
                          }
                          dropDownList={Numbers1To10}
                          placeholder="Number of Bedrooms"
                          isMulti
                          from="master"
                          // isSearchable
                          // TODO: remove this onSelect when changing flag check
                          onItemSelected={onDropDownItemSlected}
                        />

                        <DataSelect
                          id="number_of_baths"
                          styleChange
                          value={
                            props.globalDataStore.masterFiltersOnSelect
                              .number_of_baths
                          }
                          dropDownList={Numbers1To10}
                          placeholder="Number of Baths"
                          isMulti
                          from="master"
                          // isSearchable
                          // TODO: remove this onSelect when changing flag check
                          onItemSelected={onDropDownItemSlected}
                        />
                        <DataSelect
                          id="number_of_partial_baths"
                          styleChange
                          value={
                            props.globalDataStore.masterFiltersOnSelect
                              .number_of_partial_baths
                          }
                          dropDownList={Numbers1To10}
                          placeholder="Number of Partial Baths"
                          isMulti
                          from="master"
                          // isSearchable
                          // TODO: remove this onSelect when changing flag check
                          onItemSelected={onDropDownItemSlected}
                        />

                        <DataSelect
                          id="number_of_stories"
                          styleChange
                          value={
                            props.globalDataStore.masterFiltersOnSelect
                              .number_of_stories
                          }
                          dropDownList={storiesList}
                          placeholder="Number of Stories"
                          isMulti
                          from="master"
                          // isSearchable
                          // TODO: remove this onSelect when changing flag check
                          onItemSelected={onDropDownItemSlected}
                        />

                        <DataSelect
                          id="number_of_units"
                          styleChange
                          value={
                            props.globalDataStore.masterFiltersOnSelect
                              .number_of_units
                          }
                          dropDownList={Numbers1To10}
                          placeholder="Number of Units"
                          isMulti
                          from="master"
                          // isSearchable
                          onItemSelected={onDropDownItemSlected}
                        />

                        <DataSelect
                          id="garage_cars"
                          styleChange
                          value={
                            props.globalDataStore.masterFiltersOnSelect
                              .garage_cars
                          }
                          dropDownList={garageLookup}
                          placeholder="Garage"
                          isMulti
                          from="master"
                          // isSearchable
                          onItemSelected={onDropDownItemSlected}
                        />
                      </div>
                      <div
                        className="flex-container outer-container"
                        style={{ alignItems: 'center' }}
                      >
                        <Checkbox
                          id="is_pool_included"
                          style={{ marginTop: '10px', display: 'flex' }}
                          checked={
                            props.globalDataStore.masterFiltersOnSelect
                              .is_pool_included
                          }
                          onChange={onPropertyAttributesChecked}
                        >
                          Pool
                        </Checkbox>

                        <Checkbox
                          id="is_elevator_included"
                          style={{ marginTop: '10px', display: 'flex' }}
                          checked={
                            props.globalDataStore.masterFiltersOnSelect
                              .is_elevator_included
                          }
                          onChange={onPropertyAttributesChecked}
                        >
                          Elevator
                        </Checkbox>
                      </div>
                    </div>
                  </CustomPanel>
                  <CustomPanel
                    key="Tags"
                    header="Tags"
                    HeaderIcon={() => renderHeaderIcon('Tags')}
                    Clear={renderClear}
                    Footer={renderFooter}
                  >
                    {flags && flags.tagFolders ? (
                      <div className="tag-folders-filter outer-container">
                        <TagsSearch
                          onSearch={onTagSearch}
                          checkedTags={
                            props.globalDataStore.masterFiltersOnSelect
                              .tags_checked
                          }
                          handleTagsCheck={(checked) => {
                            props.globalDataStore.masterFiltersOnSelect.tags_checked =
                              [...checked];
                          }}
                        />
                        <div className="tag-management">
                          <TagManagement
                            from="savedSearches"
                            title={'Tag Toggles and Management'}
                            userTagFolders={tagData}
                            fetchUserTags={fetchUserTags}
                            fetchTagFolders={fetchTagFolders}
                            checkedTags={checkedTags}
                            handleTagsCheck={(checked) => {
                              props.globalDataStore.masterFiltersOnSelect.tags_checked =
                                [...checked];
                              setCheckedTags(checked);
                            }}
                            handleEditTags={handleEditTags}
                            handleEditFolder={handleEditFolder}
                            showTagsEdit={showTagsEdit}
                            showFolderEdit={showFolderEdit}
                          />
                        </div>
                        {(showTagsEdit || showFolderEdit) && (
                          <div
                            className="footer"
                            onClick={handleTagsEditCancel}
                          >
                            <LeftArrow
                              style={{
                                transform: 'rotate(90deg)',
                                height: '13px',
                              }}
                            />
                            {/* {showTagsEdit
                                ? 'Back to all folders & tags'
                                : showFolderEdit
                                  ? 'Back to all folders'
                                  : 'Cancel'} */}
                            {showTagsEdit || showFolderEdit ? 'Back' : 'Cancel'}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="tags outer-container">
                        <div className="add-new">
                          <Tags />
                        </div>
                        <Checkbox.Group
                          className="tags-grid-container"
                          value={
                            props.globalDataStore.masterFiltersOnSelect
                              .tags_checked
                          }
                          onChange={(checkedValues) => {
                            props.globalDataStore.masterFiltersOnSelect.tags_checked =
                              checkedValues;
                          }}
                        >
                          {props.dataStore.userTags.map(({ id, tag }) => {
                            return (
                              <div
                                className={`tags-row ${
                                  tag === 'Suppress' && 'suppress-row'
                                }`}
                                style={
                                  (tag === 'Suppress' &&
                                    props.dataStore.currentUser
                                      .hideSuppressTag) ||
                                  (tag?.toLowerCase() === 'moneta contact' &&
                                    props.dataStore.currentUser
                                      .hideMonetaContactTag)
                                    ? {
                                        display: 'none',
                                      }
                                    : {}
                                }
                              >
                                <Checkbox value={id}>{tag}</Checkbox>
                                {tag !== 'Suppress' && (
                                  <Tags id={id} tagLabel={tag} isUpdate />
                                )}
                                {tag === 'Suppress' && (
                                  <Tooltip
                                    placement="bottom"
                                    color="var(--color-primary)"
                                    title={
                                      <span
                                        style={{ color: 'var(--color-white)' }}
                                      >
                                        Tags can be utilized for segmentation
                                        purposes, allowing for effective
                                        organization and management of your
                                        prospects and clients. Use Tags in
                                        accordance with your corporate
                                        guidelines.
                                      </span>
                                    }
                                  >
                                    <HelpIcon
                                      id="suppress"
                                      height="16px"
                                      width="16px"
                                      className="help-icon"
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            );
                          })}
                        </Checkbox.Group>
                        {props.dataStore.sharedTags &&
                          props.dataStore.sharedTags.length > 0 && (
                            <div style={{ marginTop: '30px' }}>
                              <label
                                style={{
                                  fontSize: '16px',
                                  color: 'var(--color-primary)',
                                }}
                              >
                                Shared Tags
                              </label>
                              <Checkbox.Group
                                className="tags-grid-container"
                                style={{
                                  height: 'fit-content',
                                  maxHeight: '190px',
                                }}
                                value={
                                  props.globalDataStore.masterFiltersOnSelect
                                    .shared_tags
                                }
                                onChange={(checkedValues) =>
                                  (props.globalDataStore.masterFiltersOnSelect.shared_tags =
                                    checkedValues)
                                }
                              >
                                {props.dataStore.sharedTags.map((tag) => {
                                  return (
                                    <div
                                      className="tags-row"
                                      style={
                                        (tag.tag_name === 'Suppress' &&
                                          props.dataStore.currentUser
                                            .hideSuppressTag) ||
                                        (tag.tag_name?.toLowerCase() ===
                                          'moneta contact' &&
                                          props.dataStore.currentUser
                                            .hideMonetaContactTag)
                                          ? {
                                              display: 'none',
                                            }
                                          : {}
                                      }
                                    >
                                      <Checkbox value={tag.tag_id}>
                                        {tag.tag_name}
                                        {tag.tag_owner_name && (
                                          <div>
                                            <span
                                              style={{
                                                color: 'var(--color-grey-dark)',
                                                fontSize: '11px',
                                              }}
                                            >
                                              by
                                            </span>{' '}
                                            <span
                                              className="link"
                                              onClick={(e) =>
                                                showPersonProfile(
                                                  e,
                                                  tag.tag_owner_b2b_id,
                                                  tag.tag_owner_b2c_id,
                                                )
                                              }
                                            >
                                              {tag.tag_owner_name}
                                            </span>
                                          </div>
                                        )}
                                      </Checkbox>
                                    </div>
                                  );
                                })}
                              </Checkbox.Group>
                            </div>
                          )}
                      </div>
                    )}
                  </CustomPanel>
                  <CustomPanel
                    key="Attributes"
                    header="Attributes"
                    HeaderIcon={() => renderHeaderIcon('Attributes')}
                    Clear={renderClear}
                    Footer={renderFooter}
                  >
                    <div className="attributes outer-container">
                      <div className="attributes-switch">
                        <Switch
                          size="small"
                          checkedChildren="ALL"
                          unCheckedChildren="ANY"
                          checked={
                            props.globalDataStore.masterFiltersOnSelect
                              .attribute_function
                          }
                          onChange={onAttributesSwitch}
                        />
                        <label>All</label>
                        <HelpIcon
                          id="info-attributes"
                          className="help-icon"
                          height="14px"
                          width="14px"
                        />
                      </div>

                      <Checkbox.Group
                        options={findAttributes()}
                        value={
                          props.globalDataStore.masterFiltersOnSelect
                            .attributes_checked
                        }
                        onChange={(checkedValues) =>
                          onAttributesChecked(checkedValues)
                        }
                        className={
                          attributesAllCheckedByAdmin
                            ? 'checkbox-color-change checkboxes-grid-container'
                            : 'checkboxes-grid-container'
                        }
                        style={{
                          gridTemplateRows: `${
                            props.dataStore.currentUser.isAdmin
                              ? 'repeat(6,auto)'
                              : 'repeat(5,auto)'
                          }`,
                        }}
                      />
                    </div>
                  </CustomPanel>

                  <CustomPanel
                    key="Wealth Model"
                    header="Wealth & Income Components"
                    HeaderIcon={() => renderHeaderIcon('Wealth Model')}
                    showWealthModalVisible={showWealthModalVisible}
                    Clear={renderClear}
                    Footer={renderFooter}
                  >
                    <div className="wealth-model  outer-container">
                      <Checkbox
                        className="wealth-select-all-check"
                        disabled={newProfileActive && !hasPremiumWealthTriggers}
                        checked={
                          props.globalDataStore.masterFiltersOnSelect
                            .wealth_model_checked &&
                          props.globalDataStore.masterFiltersOnSelect
                            .wealth_model_checked.length ===
                            wealthModelValues.length
                        }
                        onChange={(e) => {
                          if (e.target.checked)
                            onWealthModalChecked(wealthModelValues);
                          else onWealthModalChecked([]);
                        }}
                      >
                        Select All
                      </Checkbox>
                      <Checkbox.Group
                        options={wealthModelItems.map((item) => ({
                          ...item,
                          disabled:
                            newProfileActive && !hasPremiumWealthTriggers,
                        }))}
                        value={
                          props.globalDataStore.masterFiltersOnSelect
                            .wealth_model_checked
                        }
                        className="checkboxes-grid-container"
                        onChange={onWealthModalChecked}
                      />
                    </div>
                  </CustomPanel>

                  <CustomPanel
                    key="Import Source"
                    header="Import Source"
                    HeaderIcon={() => renderHeaderIcon('Import Source')}
                    Clear={renderClear}
                    Footer={renderFooter}
                  >
                    <div className="import-source-container outer-container">
                      <div className="import-label ">
                        Select options to monitor
                      </div>

                      <div className="import-container flex-container">
                        <div className="import-source">
                          <div className="flex-header">
                            <label className="import-labels">
                              Relationships
                            </label>
                            <Checkbox
                              id="import_source_all"
                              checked={
                                props.globalDataStore.masterFiltersOnSelect
                                  .import_source.length ===
                                props.dataStore.lookUpLists.import_source.length
                              }
                              onChange={onImportFileAllChecked}
                            />
                          </div>
                          <Checkbox.Group
                            style={{
                              width: '100%',
                              paddingTop: 10,
                              color: 'var(--color-primary)',
                              fontSize: 14,
                            }}
                            options={importSourceList}
                            value={
                              props.globalDataStore.masterFiltersOnSelect
                                .import_source
                            }
                            onChange={(checkedValues) =>
                              onSourceSelected(checkedValues, 'import_source')
                            }
                          />
                        </div>
                        {props.dataStore.lookUpLists.fileImports &&
                          props.dataStore.lookUpLists.fileImports.length >
                            0 && (
                            <div className="import-source">
                              <div className="flex-header">
                                <label className="import-labels">
                                  CSV Files
                                </label>
                                <Checkbox
                                  id="file_imports_all"
                                  disabled={
                                    flags &&
                                    flags.csvImportsAndDegrees &&
                                    props.globalDataStore.masterFiltersOnSelect
                                      .filter_type !== 'all'
                                  }
                                  className={
                                    flags &&
                                    flags.csvImportsAndDegrees &&
                                    props.globalDataStore.masterFiltersOnSelect
                                      .filter_type !== 'all'
                                      ? 'transparent-checkbox'
                                      : ''
                                  }
                                  checked={
                                    props.globalDataStore.masterFiltersOnSelect
                                      .file_imports &&
                                    props.globalDataStore.masterFiltersOnSelect
                                      .file_imports.length ===
                                      props.dataStore.lookUpLists.fileImports
                                        .length
                                  }
                                  onChange={onImportFileAllChecked}
                                />
                              </div>
                              <Checkbox.Group
                                style={{ width: '100%', paddingTop: 10 }}
                                disabled={
                                  flags &&
                                  flags.csvImportsAndDegrees &&
                                  props.globalDataStore.masterFiltersOnSelect
                                    .filter_type !== 'all'
                                }
                                className={
                                  flags &&
                                  flags.csvImportsAndDegrees &&
                                  props.globalDataStore.masterFiltersOnSelect
                                    .filter_type !== 'all'
                                    ? 'transparent-checkbox'
                                    : ''
                                }
                                options={
                                  props.dataStore.lookUpLists.fileImports
                                }
                                value={
                                  props.globalDataStore.masterFiltersOnSelect
                                    .file_imports
                                }
                                onChange={(checkedValues) =>
                                  onSourceSelected(
                                    checkedValues,
                                    'file_imports',
                                  )
                                }
                              />
                            </div>
                          )}
                        {!props.dataStore.currentUser.hideSharedCsvFiles &&
                          props.dataStore.lookUpLists.teamFileImports &&
                          props.dataStore.lookUpLists.teamFileImports.length >
                            0 && (
                            <div className="import-source">
                              <div className="flex-header">
                                <label className="import-labels">
                                  Shared CSV Files
                                </label>
                                <Checkbox
                                  id="team_file_imports_all"
                                  disabled={
                                    flags &&
                                    flags.csvImportsAndDegrees &&
                                    props.globalDataStore.masterFiltersOnSelect
                                      .filter_type !== 'all'
                                  }
                                  className={
                                    flags &&
                                    flags.csvImportsAndDegrees &&
                                    props.globalDataStore.masterFiltersOnSelect
                                      .filter_type !== 'all'
                                      ? 'transparent-checkbox'
                                      : ''
                                  }
                                  checked={
                                    props.globalDataStore.masterFiltersOnSelect
                                      .team_file_imports &&
                                    props.globalDataStore.masterFiltersOnSelect
                                      .team_file_imports.length ===
                                      props.dataStore.lookUpLists
                                        .teamFileImports.length
                                  }
                                  onChange={onImportFileAllChecked}
                                />
                              </div>
                              <Checkbox.Group
                                style={{ width: '100%', paddingTop: 10 }}
                                disabled={
                                  flags &&
                                  flags.csvImportsAndDegrees &&
                                  props.globalDataStore.masterFiltersOnSelect
                                    .filter_type !== 'all'
                                }
                                className={
                                  flags &&
                                  flags.csvImportsAndDegrees &&
                                  props.globalDataStore.masterFiltersOnSelect
                                    .filter_type !== 'all'
                                    ? 'transparent-checkbox'
                                    : ''
                                }
                                value={
                                  props.globalDataStore.masterFiltersOnSelect
                                    .team_file_imports
                                }
                                onChange={(checkedValues) =>
                                  onSourceSelected(
                                    checkedValues,
                                    'team_file_imports',
                                  )
                                }
                              >
                                {getCheckBoxValue()}
                              </Checkbox.Group>
                            </div>
                          )}
                      </div>
                    </div>
                  </CustomPanel>
                </Collapse>
                {/* {renderFooter()} */}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }),
);

export default ProfileFilter;
