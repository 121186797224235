import './index.scss';

import { inject, observer } from 'mobx-react';
import { Modal, Spin, Table } from 'antd';

import HelpIcon from '../../assets/icons/help-icon.svg?react';

import withRouter from '../../hooks/withRouter';
import { formatEventName, formatValue } from '../../utils/general';

const TriggerDetailModal = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const { onCancel, data, onRowClick, triggerPeriod } = props;

    const showHelpModal = (e) => {
      e.stopPropagation();
      props.modalDataStore.eventHelpModalVisible = true;
    };

    const columns = [
      {
        title: (
          <div style={{ position: 'relative', display: 'flex' }}>
            <div
              style={{
                marginLeft: '5px',
                fontWeight: 'bold',
              }}
            >
              Trigger
            </div>
            <div
              className="help-icon-event"
              onClick={showHelpModal}
              style={{ marginLeft: '7px' }}
            >
              <HelpIcon
                height="14px"
                width="14px"
                fill="var(--color-primary)"
              />
            </div>
          </div>
        ),
        key: 'event_type',
        dataIndex: 'event_type',
        render: (val) => {
          return formatEventName(val);
        },
      },
      {
        title: 'Executives',
        key: 'event_count',
        dataIndex: 'event_count',
        align: 'center',
        render: (val) => {
          return val ? val.toLocaleString() : '';
        },
      },
      {
        title: 'First Degree',
        key: 'first_degree_count',
        dataIndex: 'first_degree_count',
        align: 'center',
        render: (val) => {
          return val ? val.toLocaleString() : '';
        },
      },
      {
        title: 'Second Degree',
        key: 'second_degree_count',
        dataIndex: 'second_degree_count',
        align: 'center',
        render: (val) => {
          return val ? val.toLocaleString() : '';
        },
      },
      // {
      //     title: 'Companies',
      //     key: 'org_count',
      //     dataIndex: 'org_count',
      //     align: 'center'
      // },
      {
        title: 'Value',
        key: 'event_value',
        dataIndex: 'event_value',
        align: 'right',
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              {val === null ? '' : formatValue(val)}
            </div>
          );
        },
      },
      {
        title: '',
        key: 'dummy',
      },
    ];

    return (
      <Modal
        width="100%"
        open
        wrapClassName="peopleview-modal-class triggerview-modal-class"
        footer={null}
        onCancel={onCancel}
      >
        <div>
          <Spin size="small" spinning={false}>
            <Table
              dataSource={data}
              columns={columns}
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: () => {
                    onRowClick(
                      'triggers_overview',
                      record.event_type,
                      triggerPeriod,
                    );
                  },
                };
              }}
            />
          </Spin>
        </div>
      </Modal>
    );
  }),
);

export default withRouter(TriggerDetailModal);
