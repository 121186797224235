/* eslint-disable no-prototype-builtins */
import './index.scss';

import { inject, observer } from 'mobx-react';
import { CloseCircleFilled, ReloadOutlined } from '@ant-design/icons';

import { useFlags } from 'launchdarkly-react-client-sdk';

import HorizontalChart from '../Chart/HorizontalChart';
import ListChart from '../Chart/ListChart';
import VerticalChart from '../Chart/VerticalChart';
import { splitNumberWithCommas, formatEventName } from '../../utils/general';
import LoaderIcon from '../../assets/icons/ai_ic_logo_gold.svg?react';

const ChartBox = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const flags = useFlags();
    const newDashboard = flags && flags.dasboard2024;

    const headers = {
      location_state: 'States & Territories',
      location_city: 'Cities',
      home_postal_code: 'Zip Codes',
      industry: 'Industries',
      org_name: 'Companies',
      title_rank: 'Title Ranks',
      schools: 'Colleges/Universities',
      team_ids: 'Team Relationship Paths',
      inferred_salary_range: 'Inferred Salary Ranges',
      interests: 'Modeled Interests',
      stock_performance: 'Company Viablitiy',
      top_wealth_estimate: 'Wealth Segment',
      tags: 'Tags',
    };

    const getChartData = (response, id) => {
      const labels = [];
      const toolTipLabels = [];
      const data = [];
      const keyLabels = [];

      if (response && response.length > 0) {
        response.map((item) => {
          if (id === 'client_inv_trigger') {
            labels.push(formatEventName(item.key));
          } else {
            const value = item.key ? item.key.toString().toTitleCase() : '';
            labels.push(value);
          }

          if (item.hasOwnProperty('ai_org_id')) keyLabels.push(item.ai_org_id);
          else keyLabels.push(item.key);

          if (item.event_value) data.push(item.event_value);
          else
            data.push(item.unique_count ? item.unique_count : item.doc_count);
          if (item.hasOwnProperty('state_name')) {
            toolTipLabels.push(item.state_name);
          }
          if (item.hasOwnProperty('title_rank'))
            toolTipLabels.push(item.title_rank);
          if (item.hasOwnProperty('id')) toolTipLabels.push(item.id);
          return '';
        });
      }

      const bgColor = getComputedStyle(document.body)
        .getPropertyValue('--color-link')
        .replace(')', ', 0.65)')
        .replace('rgb', 'rgba');

      const chartData = {
        labels,
        keyLabels,
        toolTipLabels,

        datasets: [
          {
            label: 'Dataset 1',
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: bgColor,
            data,
          },
        ],
      };

      return chartData;
    };

    const getExtraId = (item) => {
      if (item.hasOwnProperty('state_name')) {
        return item.state_name;
      }
      if (item.hasOwnProperty('title_rank')) return item.title_rank;
      if (item.hasOwnProperty('id')) return item.id;
      else return '';
    };

    const getListChartData = (response) => {
      const chartData = [];
      if (response && response.length > 0) {
        response.map((item) => {
          if (item.key)
            chartData.push({
              label: item.key ? item.key.toString().toTitleCase() : '',
              count: item.unique_count ? item.unique_count : item.doc_count,
              key: item.hasOwnProperty('ai_org_id') ? item.ai_org_id : item.key,
              extraId: getExtraId(item),
            });
        });
      }
      return chartData;
    };

    const renderChart = () => {
      let type = props.type;
      if (props.id === 'inferred_salary_range' && props.totalCount !== 0) {
        type =
          props.totalCount < 3
            ? 'list'
            : props.totalCount < 6
              ? 'vertical'
              : 'horizontal';
      }

      switch (type) {
        case 'vertical':
          return (
            <VerticalChart
              data={
                props.chartData ? getChartData(props.chartData) : getChartData
              }
              click={props.onClick}
              showCompanyProfile={props.showCompanyProfile}
              id={props.id}
            />
          );
        case 'horizontal': {
          return (
            <HorizontalChart
              data={
                props.chartData
                  ? getChartData(props.chartData, props.id)
                  : getChartData
              }
              click={props.onClick}
              showCompanyProfile={props.showCompanyProfile}
              id={props.id}
            />
          );
        }
        case 'list': {
          return (
            <ListChart
              data={
                props.chartData
                  ? getListChartData(props.chartData)
                  : getListChartData()
              }
              boxName={props.box}
              click={props.onClick}
              showCompanyProfile={props.showCompanyProfile}
              id={props.id}
            />
          );
        }
        default:
          return <></>;
      }
    };

    return (
      <div id={props.id} className="loader-wrapper">
        {props.chartData === null && (
          <LoaderIcon className="aid-sidecar-ext-logo-spin-icon-only" />
        )}
        <div
          className={
            props.chartData === null
              ? 'dashboard-item chart-box aid-div-disable'
              : 'dashboard-item chart-box aid-div-enable'
          }
          style={{
            borderRadius: newDashboard ? '16px' : '4px',
            padding: newDashboard ? '25px 20px' : '0px 0px 15px 0px',
          }}
        >
          <ReloadOutlined
            className="reload-chart"
            id={`reload-${props.id}`}
            style={{ display: 'none' }}
            onClick={props.onReload}
          />

          {headers[props.id] && (
            <div className="chart-box-header">
              <div className="dashboard-item-title">
                {headers[props.id]}{' '}
                {props.totalCount && props.totalCount > 0 && (
                  <span className="dashboard-item-count">
                    {' '}
                    {splitNumberWithCommas(props.totalCount)}
                  </span>
                )}
              </div>
              <div>
                <CloseCircleFilled
                  onClick={props.onCloseButtonClick}
                  style={{ color: 'var(--color-secondary)' }}
                />
              </div>
            </div>
          )}

          <div className="graph-scroll-section">
            {props.chartData === undefined || props.totalCount === 0 ? (
              <div id={`no-data-${props.id}`} className="no-data">
                No Data
              </div>
            ) : (
              renderChart()
            )}
          </div>
        </div>
      </div>
    );
  }),
);

export default ChartBox;
