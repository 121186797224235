import { Button, Form, Input } from 'antd';
import { PureComponent } from 'react';

class CrmForm extends PureComponent {
  onChange = (passwordKey) => {
    let ids;
    if (document.querySelectorAll('[id=password]') !== null)
      ids = document.querySelectorAll('[id=password]');
    for (let i = 0; i < ids.length; i++) ids[i].type = passwordKey;
  };

  render() {
    const { onFinish, formLayout, showLabels, disable, keys } = this.props;
    const {
      idLabel,
      idKey,
      passwordLabel,
      passwordKey,
      buttonLabel = 'Submit',
    } = keys;

    return (
      <Form
        layout={formLayout}
        onFinish={onFinish}
        hideRequiredMark
        className="form"
        autoComplete="__away"
      >
        <Form.Item
          label={showLabels ? idLabel : ''}
          name={idKey}
          rules={[{ required: true, message: `${idLabel} cannot be empty.` }]}
          className="username"
        >
          <Input autcomplete="username" style={{ width: '90%' }} />
        </Form.Item>
        <Form.Item
          label={showLabels ? passwordLabel : ''}
          name={passwordKey}
          rules={[
            { required: true, message: `${passwordLabel} cannot be empty.` },
          ]}
        >
          <Input
            id="password"
            onChange={() => this.onChange(passwordKey)}
            autoComplete="password"
            style={{ width: '90%' }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className={
              disable ? 'button-crm-export-disabled' : 'button-crm-export'
            }
            disabled={disable}
          >
            {buttonLabel}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

CrmForm.defaultProps = {
  formLayout: 'horizontal',
  inputSize: 'large',
  showLabels: false,
};

export default CrmForm;
