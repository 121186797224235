import { message } from 'antd';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useNavigate } from 'react-router';

import {
  MaxioQueryInterface,
  ApiResponseInterface,
} from '../../types/GlobalTypes';
import { requestWithAuth } from '../../services/api';
import LoaderAnimation from '../../components/AidLoader';

message.config({
  top: 80,
});

/* a user that is not billed through maxio can still purchase credits
   through maxio. Maxio redirects to this route after successful purchase
   and we prepare a request to /v0/maxio-finish-credits
*/

const CreditsCallbackPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const body: MaxioQueryInterface = {};
    const urlParams = location.search;
    const query: MaxioQueryInterface = qs.parse(urlParams, {
      ignoreQueryPrefix: true,
    });
    if (
      query &&
      !(Object.keys(query).length === 0 && query.constructor === Object) &&
      query.maxio_customer_id
    )
      body.maxio_customer_id = query.maxio_customer_id;

    requestWithAuth('maxio-finish-credits', 'POST', null, body, null).then(
      (response: ApiResponseInterface) => {
        if (!response) return;
        if (response && (response.code === 200 || response.code === 307)) {
          navigate('/account/credits');
        } else {
          const errorMessage = response.detail
            ? response.detail
            : 'Billing system error';
          message.error(errorMessage, 10);
        }
      },
    );
  }, [location]);

  return (
    <div className="loader-wrapper">
      <LoaderAnimation loaderId="credits-callback-loader" />;
    </div>
  );
};
export default CreditsCallbackPage;
