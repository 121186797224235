import './index.scss';

const Education = ({ person }) => {
  // const flags = useFlags();
  const educationInfo =
    person.schools !== undefined &&
    person.schools !== null &&
    person.schools.length > 0;

  const getSchool = (schools) => {
    let schoolValue = '';
    if (schools !== undefined && schools !== null) {
      schoolValue = schools.map((school, index) => {
        return (
          <div>
            {index !== 0 && <div className="border" />}

            <div
              style={{
                padding: '10px 20px',
                lineHeight: '1.75',
              }}
            >
              <div className="value">
                {school ? school.toString().toTitleCase() : ''}
              </div>
            </div>
          </div>
        );
      });
    }

    return schoolValue;
  };

  return (
    <div className="profile-boxes">
      <div
        className="profile-box-header profile-titles "
        style={{ justifyContent: 'normal', gap: 5 }}
      >
        Education
        {/* {person.education_verified_on && (
          <Tooltip
            color={'var(--color-white)'}
            title={() => {
              return (
                <div className="tooltip-flex">
                  <VerifyIcon />
                  {`AI Inspected against two data sources on  ${moment(
                    person.education_verified_on,
                  ).format('MM/DD/YYYY')}`}
                </div>
              );
            }}
          >
            <VerifyIcon />
          </Tooltip>
        )} */}
      </div>
      <div className="border" />
      <div className="profile-box-body" style={{ padding: 0 }}>
        {educationInfo ? (
          getSchool(person.schools)
        ) : (
          <div className="no-data">No Education Information Available</div>
        )}
      </div>
    </div>
  );
};

export default Education;
