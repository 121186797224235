import './index.scss';
import { Button } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { showConfirm } from '../../utils/popup';

export const AboutText = (props) => {
  return (
    <div className="about-text">
      <div style={{ display: 'flex', height: '40px', alignItems: 'center' }}>
        {props.crm_icon && (
          <img className="crm-icon" src={props.crm_icon} alt="crm" />
        )}
        <div
          style={{ paddingLeft: props.crm_icon && '18px', fontSize: '18px' }}
        >
          {props.crm_name}
        </div>
      </div>
      <div className="more-about-section">
        To learn more about {props.crm_name}, please visit{' '}
        <span style={{ color: 'var(--color-link)' }}>
          <a rel="noopener noreferrer" href={props.crm_link} target="_blank">
            {props.crm_url}
          </a>
        </span>
      </div>
      <div className="note-section">
        <div style={{ paddingBottom: '20px' }}>Note</div>{' '}
        <div>
          Only one CRM can be in use. To change CRM application, please remove
          current integration.
        </div>
      </div>
    </div>
  );
};

export const IntegratedView = (props) => {
  const showModal = () => {
    showConfirm({
      className: 'small-popup',
      closable: true,
      onOk: () => props.onRemove(),
      title: `Remove ${props.crm_name}`,
      content: <h4>Please confirm disconnecting from {props.crm_name}</h4>,
    });
  };

  return (
    <div className="crm-result-view">
      <CheckCircleFilled className="tic" />
      <p className="text">{props.crm_name} account integrated</p>
      <Button type="primary" className="button-crm-export" onClick={showModal}>
        Remove
      </Button>
    </div>
  );
};
