import './index.scss';
import { useState } from 'react';
import { inject, observer } from 'mobx-react';

import ArrowIcon from '../../assets/icons/ai_arrow.svg?react';
import CreateFolderImage from '../../assets/icons/ai_add_tag_folder_icn.svg?react';
import PlusIcon from '../../assets/icons/ai_plus.svg?react';
import CreateTagElement from './CreateTagElement';
import FirstFolderSuccess from './FirstFolderSuccess';

const DemoTag = () => {
  return (
    <div className="demo-tag">
      Your Tag
      <PlusIcon />
    </div>
  );
};

const NoFolderView = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const { fetchTagFolders } = props;

    const [showSuccess, setShowSuccess] = useState(false);

    const onFolderCreation = () => {
      setShowSuccess(true);
      setTimeout(() => {
        fetchTagFolders();
        window.scrollTo(0, 0);
      }, 1000);
    };

    return (
      <div className="no-tags-container">
        <div>Folders</div>
        <div className="description" style={{ maxWidth: '400px' }}>
          Use folders to easily group multiple tags or add an entire folder to a
          suppression list. Start by creating your first folder.
        </div>
        {showSuccess ? (
          <FirstFolderSuccess />
        ) : (
          <div className="no-tags-content">
            <div className="demo-tags">
              <DemoTag />
              <DemoTag />
              <DemoTag />
            </div>
            <ArrowIcon style={{ marginRight: '-11px' }} />
            <CreateFolderImage />
            <CreateTagElement isFolder onCreation={onFolderCreation} />
          </div>
        )}
      </div>
    );
  }),
);

export default NoFolderView;
