import './index.scss';
import { inject, observer } from 'mobx-react';
import { Switch } from 'antd';

import SuppressedIcon from '../../assets/icons/ai_abort_icon.svg?react';
import AiTooltip from '../AiTooltip';
import { updateUserInfo } from '../../services/api';
import SuppressedPNGIcon from '../../assets/icons/ai_ic_suppress.png';

const Suppresion = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const handleSuppressChange = (isChecked) => {
      const body = {
        enable_suppression: isChecked,
      };
      updateUserInfo(props.dataStore.currentUser.ownerId, body).then(() => {
        props.dataStore.currentUser.enableSuppression = isChecked;
      });
    };

    return (
      <div>
        <div>
          <span className="title">Suppression Setting</span>
          <AiTooltip
            title="Suppresion removes profile from search results,
                              filters, daily opportunities and email exports."
          />
        </div>
        <Switch
          checkedChildren="ON"
          unCheckedChildren="OFF"
          checked={props.dataStore.currentUser.enableSuppression}
          className="suppress-switch"
          onChange={handleSuppressChange}
        />
        <div
          style={{ fontSize: '14px', color: 'var(--color-primary)' }}
          // onClick={handleViewSuppressedProfile}
        >
          View all suppressed profiles in your folders and tags
          {props.dataStore.currentUser.suppressedProfileCount &&
          props.dataStore.currentUser.suppressedProfileCount > 0 ? (
            <div>
              {/* <SuppressedIcon style={{ width: '12px' }} /> */}
              <img
                src={SuppressedPNGIcon}
                id="suppress-icon"
                style={{ marginBottom: 2 }}
              />{' '}
              ({props.dataStore.currentUser.suppressedProfileCount})
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }),
);

export default Suppresion;
