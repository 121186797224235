import './index.scss';

import LoadMore from '../../assets/icons/ai_load_more.svg?react';

import { formatEventName } from '../../utils/general';
import OpportunityCard from './OpportunityCard';

const OpportunityByType = (props) => {
  const {
    data,
    fetchData,
    showProfile,
    showCompanyProfile,
    openUserFilter,
    addReminder,
    opportunitiesCount,
    loading,
    toggleProgress,
    user,
  } = props;

  return (
    <div className="opportunity-type-grouping">
      {Object.entries(data).map((opportunities) => {
        /**[
         * [date, {...}],
         * [eventname,{...}],
         * ...
         * ] */
        if (opportunities[0] === 'date') return <></>;
        return (
          <>
            {opportunities.length === 2 && opportunities[1].length > 0 && (
              <div className="group-container">
                <div className="event-title">
                  <div className="title-rectangle-left">
                    {formatEventName(opportunities[0])}
                    <div className="title-triangle-left"></div>
                  </div>
                  <div className="title-rectangle-right">
                    {opportunitiesCount[opportunities[0]] === 1
                      ? `1 total opportunity`
                      : `${
                          opportunitiesCount[opportunities[0]]
                        } total opportunities`}
                  </div>
                </div>
                <div className="group-border">
                  <div className="card-items">
                    {opportunities[1].map((item, index) => {
                      return (
                        <OpportunityCard
                          data={item}
                          showProfile={showProfile}
                          user={user}
                          addReminder={(isReminder, value, b2bId, b2cId) =>
                            addReminder(
                              isReminder,
                              value,
                              b2bId,
                              b2cId,
                              index,
                              opportunities[0],
                            )
                          }
                          toggleProgress={(isLoading) =>
                            toggleProgress(isLoading, opportunities[0])
                          }
                          openUserFilter={openUserFilter}
                          showCompanyProfile={showCompanyProfile}
                        />
                      );
                    })}
                  </div>
                  {opportunities[1] &&
                    opportunities[1].length <
                      opportunitiesCount[opportunities[0]] && (
                      <LoadMore
                        style={{
                          width: 'max-content',
                          height: '132px',
                          display: 'block',
                          margin: 'auto',
                          marginBottom: '-30px',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          fetchData(opportunities[1].length, opportunities[0])
                        }
                      />
                    )}
                </div>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default OpportunityByType;
