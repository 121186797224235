import './index.scss';
import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

import successImage from '../../assets/images/lot-milestone-success.json';
export default function FirstFolderSuccess() {
  const animationRef = useRef(null);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: document.querySelector('#success'),
      animationData: successImage,
      loop: true,
      renderer: 'svg',
    });
    animationRef.current = animation;
    animation.addEventListener('complete', function () {
      animation.goToAndStop(30, false);
    });

    return () => {
      // Clean up: Stop animation and remove event listeners
      animationRef.current.stop();
      animationRef.current.destroy();
    };
  }, []);

  return (
    <div className="success-slide">
      <div id="success" className="animation" />
      <div className="success-message">
        You’ve created your first tag folder!
      </div>
    </div>
  );
}
