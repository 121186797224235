import './index.scss';

import { useEffect, useState } from 'react';
import { TreeSelect } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const { SHOW_PARENT } = TreeSelect;

const getChildNodes = (tree) => {
  return Object.keys(tree).reduce((result, key) => {
    if (tree[key]['children'] && tree[key]['children'].length > 0) {
      // If the value has children, recursively call the function
      result.push(...getChildNodes(tree[key]['children']));
    } else {
      // If the value is a leaf node, push it to the result array
      result.push(tree[key]);
    }
    return result;
  }, []);
};

const combineArraysToObj = (values, labels) => {
  const combined = [];
  labels.forEach((label, index) => {
    combined.push({
      label: label,
      value: values[index],
    });
  });
  return combined;
};

const TreeDataSelect = (props) => {
  const { onItemSelected, id, dropDownList, placeholder, from, value } = props;
  let childNodes = [];

  const [selectedOption, setSelectedOption] = useState(value ? value : []);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (!value || value.length === 0) setSelectedOption([]);
  }, [value]);

  const handleSearch = (search) => {
    setSearchValue(search);
  };

  const handleChange = (value, label) => {
    const selected = combineArraysToObj(value, label);
    setSelectedOption([...selected]);
    onItemSelected([...selected], id);
  };

  const handleKeyDown = (e) => {
    if (childNodes.length <= 0) childNodes = getChildNodes(dropDownList);

    if (e.key === 'Enter' && searchValue) {
      let filtered;
      if (isNaN(searchValue))
        filtered = childNodes.filter((node) =>
          node.title.toLowerCase().startsWith(searchValue.toLowerCase()),
        );
      else filtered = childNodes.filter((node) => node.value === searchValue);
      const selected = filtered.map((obj) => {
        return { label: obj.title, value: obj.value };
      });
      setSelectedOption([...selectedOption, ...selected]);
      onItemSelected([...selectedOption, ...selected], id);
      document.activeElement.blur();
    }
  };

  return (
    <div className="tree-list" id="tree">
      {(from === 'master' ||
        (selectedOption && Object.keys(selectedOption).length > 0)) && (
        <div className="placeholder-active">{placeholder}</div>
      )}
      <div id={`area${from}`}>
        <TreeSelect
          treeData={dropDownList}
          allowClear={{ clearIcon: <CloseOutlined /> }}
          placeholder="NAICS"
          treeCheckable
          value={selectedOption}
          onChange={handleChange}
          onSearch={handleSearch}
          onInputKeyDown={handleKeyDown}
          filterTreeNode={(search, item) => {
            if (isNaN(search)) {
              /** if the input  value is not a number check with title */
              return (
                item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
              );
            } else {
              /** if the input  value is a number check with value */
              return item.value === search;
            }
          }}
          showCheckedStrategy={SHOW_PARENT}
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          popupClassName={
            selectedOption && Object.keys(selectedOption).length > 0
              ? 'tree-drop-down-placeholder'
              : 'tree-drop-down'
          }
          getPopupContainer={() => document.getElementById(`area${from}`)}
        />
      </div>
    </div>
  );
};

export default TreeDataSelect;
