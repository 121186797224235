import './Settings.css';

import { Button, Form, Input, message } from 'antd';
import { useState } from 'react';

import { ApiResponseInterface } from '../../types/GlobalTypes';
import { requestWithAuth } from '../../services/api';
import LoaderAnimation from '../../components/AidLoader';

interface FormValues {
  oldPassword: string;
  newPassword: string;
  confirm: string;
}

const SecurityView: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm<FormValues>();

  const handleSubmit = (values: FormValues): void => {
    if (values.confirm !== values.newPassword) {
      form.setFields([
        {
          name: 'confirm',
          errors: ['The two passwords that you entered do not match!'],
        },
      ]);
      return;
    }
    setIsLoading(true);
    const body = {
      old_password: values.oldPassword,
      new_password: values.newPassword,
    };
    requestWithAuth(
      'change-user-password',
      'POST',
      null,
      body,
      null,
      'cover-spin',
    )
      .then((response: ApiResponseInterface) => {
        try {
          if (response) {
            setIsLoading(false);
            if (
              (response.statusCode && response.statusCode >= 400) ||
              (response.code && response.code >= 400)
            ) {
              const msg = response.message
                ? response.message
                : response.msg
                  ? response.msg
                  : 'Network request failed. Please try again later.';
              message.error(msg, 5);
            } else if (response.error_description) {
              message.error(response.error_description, 5);
            } else {
              message.success('Password Updated', 5);
            }
          }
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      })
      .catch((error: unknown) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div className="settingsView loader-wrapper">
      {isLoading && <LoaderAnimation loaderId="security-loader" />}
      <div
        className={
          isLoading
            ? 'formContainer aid-div-disable'
            : 'formContainer aid-div-enable'
        }
      >
        <div className="settingsview_title">Security Settings</div>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          requiredMark={false}
        >
          <Form.Item
            name="oldPassword"
            label="Old Password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
              {
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,

                message:
                  'Password must have at least 8 characters, contain lower and upper case letters and include number(s) and special character(s)',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    'The two passwords that you entered do not match!',
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              // loading={props.submitting}
              className="set-password-form-submit"
              type="primary"
              htmlType="submit"
            >
              Set new password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SecurityView;
