import './index.scss';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { CloseCircleFilled } from '@ant-design/icons';

const DragSortListItem = (props) => {
  const {
    swap,
    children,
    id,
    index,
    onCloseButtonClick,
    closeable = true,
  } = props;

  const handleDrop = (e) => {
    e.stopPropagation();
    swap(e.dragData.index, index, e.dragData);
  };

  return (
    <div>
      <DragDropContainer
        targetKey="DragSortListItem"
        dragData={{ label: id, index: index }}
        yOnly={true}
      >
        <DropTarget onHit={handleDrop} targetKey="DragSortListItem">
          <div id={`${id}-selected`} className="drag-sort-list-item">
            {children}
            {closeable && (
              <button
                className="close-button"
                onClick={() => onCloseButtonClick(id)}
              >
                <CloseCircleFilled />
              </button>
            )}
          </div>
        </DropTarget>
      </DragDropContainer>
    </div>
  );
};

export default DragSortListItem;
