import './index.scss';

import { inject, observer } from 'mobx-react';
import moment from 'moment';

import CompanyIcon from '../../assets/icons/ai_company_logo.svg?react';
import EditIcon from '../../assets/icons/ai_edit.svg?react';

import { commarize, getTimeofDayGreeting } from '../../utils/general';
import { LoadingSpinner } from '../AiFunctionalComponents';
import { AiButton } from '../AiButton';

const DashboardHeaderNew = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const { data, isLoading, onEditDashboardView } = props;

    const renderHeader = () => {
      const date = moment();
      return (
        <div className="flex flex-col gap-8 place-items-start">
          <h3 className="header">Dashboard</h3>
          <time
            className="date-label"
            dateTime={date.format('dddd, MMMM D, YYYY')}
          >
            {date.format('dddd, MMMM D, YYYY')}
          </time>
          <span className="margin-t-auto">
            {getTimeofDayGreeting(date)},{' '}
            {(props.dataStore.currentUser.full_name || '').split(' ')[0]}
          </span>
        </div>
      );
    };

    const renderStatistic = (label, val, bg, iconBg) => {
      return (
        <>
          <div
            className="flex gap-20"
            style={{
              backgroundColor: bg,
              padding: '28px 24px',
              borderRadius: 8,
            }}
          >
            <div style={{ color: iconBg }}>
              <CompanyIcon width={36} height={36} />
            </div>
            <div className="flex flex-col" style={{ color: iconBg }}>
              <span className="trigger-statistic-value">
                {val ? commarize(val) : 0}
              </span>
              <span className="trigger-statistic-label">{label}</span>
            </div>
          </div>
        </>
      );
    };

    const renderDashboardLayoutEditBtn = () => {
      return (
        <AiButton
          buttonType="secondary"
          buttonStyle={{
            flexGrow: 0,
            alignSelf: 'flex-end',
            width: 'auto',
            padding: '12px 24px',
          }}
          leftIcon={<EditIcon width={20} height={20} />}
          onClick={() => onEditDashboardView()}
        >
          Edit Dashboard View
        </AiButton>
      );
    };

    const renderStatistics = () => {
      return (
        <div className="grid grid-cols-2" style={{ gap: 16 }}>
          {renderStatistic(
            'Total Wealth Trigger Value',
            data.total_monetary_value,
            'var(--color-green-300)',
            'var(--color-teal-700)',
          )}
          {renderStatistic(
            'Total Triggers',
            data.total_event_count,
            'var(--color-yellow-200)',
            'var(--color-plum-600)',
          )}

          {renderStatistic(
            'People Impacted',
            data.total_executives,
            'var(--color-green-50)',
            'var(--color-teal-400)',
          )}
          {renderStatistic(
            'Companies Impacted',
            data.total_org_count,
            'var(--color-yellow-50)',
            'var(--color-plum-400)',
          )}
        </div>
      );
    };

    return (
      <div
        className={`
          flex 
          w-full 
          relative 
          rounded-16 
          justify-between
          ${isLoading ? 'aid-div-disable' : 'aid-div-enable'}`}
        style={{
          padding: '44px 40px',
          background: 'var(--color-white)',
        }}
      >
        {renderHeader()}
        <div className="flex flex-col" style={{ gap: '16px' }}>
          {renderDashboardLayoutEditBtn()}
          {renderStatistics()}
        </div>
        <LoadingSpinner isLoading={isLoading} />
      </div>
    );
  }),
);

export default DashboardHeaderNew;
