import './index.css';

import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { message, Checkbox, Input } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { TagFilled } from '@ant-design/icons';

import ExportCsvIcon from '../../assets/icons/ai_export_csv.svg?react';
import LocationIcon from '../../assets/icons/ai_map_marker.svg?react';

import PopUp from '../PopUp/PopUp';
import ExportHeaderOptionsNew from '../ExportHeaderOptionsNew';
import {
  handleCrmIconsAfterIntegration,
  handleCrmLabels,
} from '../../utils/crmMethods';
import { splitNumberWithCommas } from '../../utils/general';
import { showConfirm } from '../../utils/popup';
import { getBodyParams } from '../../utils/getBodyParams';
import Tags from '../Tags';
import { groupByText } from '../../utils/constants';
import { requestWithAuth } from '../../services/api';
import TagsNew from '../TagsNew';

message.config({
  top: 80,
});

const defaultHeadersOptions = [
  'First Name',
  'Last Name',
  'Current Company Name',
  'Current Company Title',
  'Inferred Income',
  'Wealth Segment',
  'Personal Email',
  'Professional Email',
  'Mobile Phone',
  'Home Address Line',
];

let isExportAll = false;
const ExportComponent = inject(
  'dataStore',
  'modalDataStore',
)(
  observer(
    ({
      dataCount,
      exportApis,
      rowSelection = { selectedRowKeys: [] },
      pagination,
      fromMapPage,
      company, // from company profile
      fromProfile,
      exportSTDuser,
      exportAdmin,
      cancelExportAdmin,
      deleteApi,
      cancelSTDUser,
      showExportPopUp,
      cancelExport,
      cancelConfirmPopUp,
      handleExportCsv,
      onCrmClick,
      refreshData,
      selectedProfileIds,
      // selectAllRows,
      sortInfo,
      exportFromRow,
      clearExport,
      setProgress,
      ...props
    }) => {
      const [linkedInCB, setLinkedInCB] = useState(false);
      const [emailCB, setEmailCB] = useState(true);
      const [phoneCB, setPhoneCB] = useState(true);
      const [nameOfImport, setNameOfImport] = useState('');
      const [exportPopUp, setExportPopUp] = useState(false);
      const [exportHeadersList, setExportHeadersList] = useState(
        defaultHeadersOptions,
      );
      const [saveLaterHeader, setSaveLaterHeader] = useState(true);

      const flags = useFlags();
      const navigate = useNavigate();

      const result =
        splitNumberWithCommas(dataCount) +
        ` ${
          props.dataStore.lookUpLists.isSummaryPage &&
          props.dataStore.selectedGroupByParam &&
          props.dataStore.selectedGroupByParam.length > 0
            ? groupByText[
                props.dataStore.selectedGroupByParam[
                  props.dataStore.selectedGroupByParam.length - 1
                ]
              ]
            : 'Results'
        }`;
      const selectedCount =
        exportFromRow && Array.isArray(exportFromRow)
          ? 1
          : rowSelection.selectedRowKeys.length;
      const location = useLocation();

      useEffect(() => {
        if (exportHeadersList.length === 0) {
          message.warning('Please select 1 attribute or more to continue', 5);
        }
      }, [exportHeadersList]);

      useEffect(() => {
        /** in export-csv if any error from api side,
         * after closing the confirmation popup show exprt detail popup */
        if (showExportPopUp) setExportPopUp(true);
      }, [showExportPopUp]);

      useEffect(() => {
        if (exportFromRow) {
          onExportClick('FROM_SELECTED_ROW_EXPORT', exportFromRow);
        }
      }, [exportFromRow]);

      const fetchUserTypeData = () => {
        requestWithAuth('user-type', 'GET', null, null, null).then(
          (response) => {
            if (response && response.results && response.results[0]) {
              props.dataStore.currentUser.creditCount =
                response.results[0].credits;
            }
          },
        );
      };

      /** PopUp for showing confirm export */
      const showConfirmation = () => {
        if (!nameOfImport.trim()) {
          message.error('Please enter name of export', 5);
          return;
        }
        const getConfirmationmessage = () => {
          if (
            exportHeadersList.indexOf('Degree of Connection') > -1 &&
            exportHeadersList.indexOf('Household Member') > -1
          )
            return 'Please confirm Household Member selection and Degree Relationships selection. Credits will be deducted for Household Members included and file processing time is longer';
          if (exportHeadersList.indexOf('Degree of Connection') > -1)
            return 'Please confirm Degree Relationships selection. File processing time is longer';
          if (exportHeadersList.indexOf('Household Member') > -1)
            return 'Please confirm Household Member Selection -Credits will be deducted for Household Members included.';

          return 'Please Confirm';
        };
        const content = (
          <>
            {`You selected  ${splitNumberWithCommas(
              isExportAll ? dataCount : selectedCount,
            )}
                            ${
                              (isExportAll ? dataCount : selectedCount) > 1
                                ? 'profiles'
                                : 'profile'
                            }  to export`}
            <br />
            {getConfirmationmessage()}
          </>
        );
        setExportPopUp(false);
        showConfirm({
          className: 'small-popup center-align',
          title: 'Confirm Export',
          closable: true,
          content: content,
          okText: 'Yes',
          onOk: () => {
            if (isExportAll) handleExportAllOk();
            else {
              if (handleExportCsv) {
                if (props.dataStore.currentUser.isAdmin) {
                  handleExportCsv(
                    nameOfImport,
                    saveLaterHeader,
                    exportHeadersList,
                    linkedInCB,
                    emailCB,
                    phoneCB,
                  );
                } else {
                  handleExportCsv(
                    nameOfImport,
                    saveLaterHeader,
                    exportHeadersList,
                  );
                }
              }
            }
          },
          onCancel: () => {
            if (isExportAll) return false;
            else cancelConfirmPopUp();
            // return false;
          },
        });
      };

      const onExportClick = (from, rowData) => {
        if (
          from &&
          from === 'FROM_SELECTED_ROW_EXPORT' &&
          rowSelection.selectedRowKeys.length <= 0 &&
          !rowData
        ) {
          return;
        }
        if (props.dataStore.currentUser.isAdmin) {
          resetAllFields();
        } else {
          setNameOfImport('');
          // setExportSTDuserPopUp(true);
        }

        setExportPopUp(true);

        isExportAll = !from || from !== 'FROM_SELECTED_ROW_EXPORT';
      };

      const resetAllFields = () => {
        setNameOfImport('');
        setLinkedInCB(false);
        setEmailCB(true);
        setPhoneCB(true);
      };

      const onExportCancel = () => {
        setExportPopUp(false);
        resetAllFields();
        if (cancelExport) cancelExport();
      };

      const onSaveLaterChange = (e) => {
        setSaveLaterHeader(e.target.checked);
      };

      const onExportHeaderChange = (selected) => {
        setExportHeadersList(selected);
      };

      /** Functionality for handling csv batch export */
      const handleExportAllOk = () => {
        const params = {};
        if (!fromMapPage) {
          // const size = pagination.pageSize;
          // const currentPage = pagination.current;
          if (sortInfo && sortInfo.order && sortInfo.columnKey) {
            params.sort_key = sortInfo.columnKey;
            params.sort_order = sortInfo.order === 'descend' ? 'desc' : 'asc';
          }
        }

        // if anything is searched, pass that
        if (
          !props.modalDataStore.profileModal ||
          !props.modalDataStore.profileModal.mappedRelationshipsFilter ||
          !props.modalDataStore.profileModal.mappedRelationshipsFilter
            .filterName
        ) {
          if (props.dataStore.quick_search)
            params.quick_search = props.dataStore.quick_search;
          if (props.dataStore.networkFilters.filter_type) {
            params.filter_type = props.dataStore.networkFilters.filter_type;
          }
        }

        if (
          props.dataStore.lookUpLists.isPeoplePage &&
          props.dataStore.networkFilters.auto_second_degree
        )
          params.auto_second_degree = 0;

        params.export_name = nameOfImport;

        if (props.dataStore.currentUser.isAdmin) {
          if (linkedInCB) {
            params.profile_refresh = 1;
          } else {
            params.profile_refresh = 0;
          }

          if (emailCB) {
            params.email_validation = 1;
          } else {
            params.email_validation = 0;
          }

          if (phoneCB) {
            if (flags.phoneValidation) params.phone_validation = 1;
          } else {
            params.phone_validation = 0;
          }
        }
        setProgress(true);
        let body = {};
        body = getBodyParams(
          props.dataStore.networkFilters,
          props.dataStore.lookUpLists,
          props.dataStore.superConnectorFilter,
          props.dataStore.teamFilters,
          props.dataStore.currentUser,
          props.dataStore.mappedRelationshipsFilter,
          props.modalDataStore.profileModal,
          props.dataStore.groupByValues,
          fromMapPage,
        );

        // if (
        //   flags &&
        //   flags.exportHeaderConfirmation &&
        //   exportHeadersList &&
        //   exportHeadersList.indexOf('Degree') > -1 &&
        //   exportHeadersList.indexOf('Relationship Paths') < 0
        // )
        //   exportHeadersList.push('Relationship Paths');

        if (!exportHeadersList.includes('First Name'))
          exportHeadersList.push('First Name');

        if (!exportHeadersList.includes('Last Name'))
          exportHeadersList.push('Last Name');

        if (exportHeadersList && exportHeadersList.length > 0) {
          body.export_headers = exportHeadersList;

          const index = exportHeadersList.indexOf('Household Member');
          if (index > -1) params.household_match = 1;

          if (saveLaterHeader) {
            const exportHeaderBody = {
              export_headers: exportHeadersList,
            };
            requestWithAuth(
              'user-export-headers',
              'POST',
              null,
              exportHeaderBody,
              null,
            );
          }
        }

        requestWithAuth(
          'csv-batch-export-new',
          'POST',
          params,
          body,
          null,
          null,
        ).then((response) => {
          setProgress(false);
          if (
            response &&
            response.errorCode === 'File with given name already exists'
          ) {
            setExportPopUp(true);
            message.error(response.errorCode, 5);
          } else if (response && response.code === 200) {
            setLinkedInCB(false);
            setEmailCB(false);
            setPhoneCB(false);
            setNameOfImport('');
            props.dataStore.currentUser.creditCount =
              props.dataStore.currentUser.creditCount - dataCount;
            message.success(
              'Thank you - Your request is being processed. You will receive an email with instructions to download when the file is ready.',
              10,
            );
          }
          if (!props.dataStore.currentUser.isAdmin) fetchUserTypeData();
        });
      };

      function inputNameOnChange(e) {
        setNameOfImport(e.target.value);
      }

      const linkedInCheckboxOnChange = (e) => {
        setLinkedInCB(e.target.checked);
      };

      const emailCheckboxOnChange = (e) => {
        setEmailCB(e.target.checked);
      };

      const phoneCheckboxOnChange = (e) => {
        setPhoneCB(e.target.checked);
      };

      const handleDelete = () => {
        if (rowSelection.selectedRowKeys.length <= 0) return;
        showConfirm({
          className: 'small-popup center-align',
          title: 'Confirm Delete',
          closable: true,
          content: 'This is a permanent delete - please confirm.',
          okText: 'Yes',
          onOk: () => {
            deleteApi();
          },
          onCancel: () => {
            deleteApi('cancel');
            return false;
          },
        });
      };

      const renderExportContent = () => {
        return (
          <div className="export-component-headers">
            <div className="popup-text">
              <label
                style={{
                  color: 'var(--color-primary)',
                }}
              >
                Name your export:
              </label>
              <Input
                className="input-name-box"
                onChange={inputNameOnChange}
                value={nameOfImport}
              />{' '}
            </div>
            {props.dataStore.currentUser.isAdmin && (
              <>
                <div className="enrich-part">
                  <Checkbox
                    onChange={linkedInCheckboxOnChange}
                    style={{ paddingBottom: 10 }}
                    checked={linkedInCB}
                  >
                    <span style={{ color: 'var(--color-primary' }}>
                      LinkedIn refresh
                    </span>
                  </Checkbox>
                  <br />
                  <Checkbox onChange={emailCheckboxOnChange} checked={emailCB}>
                    <span style={{ color: 'var(--color-primary' }}>
                      Validate Email
                    </span>
                  </Checkbox>
                  <br />
                  {flags.phoneValidation && (
                    <Checkbox
                      onChange={phoneCheckboxOnChange}
                      checked={phoneCB}
                      style={{ paddingTop: 10 }}
                    >
                      <span style={{ color: 'var(--color-primary' }}>
                        Validate Phone
                      </span>
                    </Checkbox>
                  )}
                </div>
              </>
            )}

            <div className="export-header-new">
              {/* <div className="export-header-section">
                  Select Your Export Option
                </div> */}

              <ExportHeaderOptionsNew
                onChange={onExportHeaderChange}
                exportHeadersList={exportHeadersList}
                saveLaterHeader={saveLaterHeader}
                onSaveLaterChange={onSaveLaterChange}
              />
            </div>

            <div className="export-note">
              Note: If applicable, ensure that your use of these products or
              services compiles with applicable laws and regulations relating to
              consumer privacy and solicitation.
            </div>
          </div>
        );
      };

      const getGridColumns = () => {
        if (company) return '1fr auto auto auto auto';
        if (fromProfile) {
          return '1fr auto auto auto auto';
        }
        const gridColumn = [
          'auto',
          'max-content',
          // 'max-content',
          '1fr',
          'max-content',
          'max-content',
          'max-content',
        ];

        if (!fromMapPage) {
          gridColumn.push('max-content');
          gridColumn.push('max-content');
        }

        return gridColumn.join(' ');
      };

      return (
        <>
          <div
            className={
              !props.dataStore.lookUpLists.isSummaryPage
                ? 'export-class-new'
                : 'export-class'
            }
            style={{
              gridTemplateColumns: getGridColumns(),
            }}
          >
            {!company && !fromProfile && (
              <>
                <div
                  className="count"
                  onClick={fromMapPage && props.navigateToPeopleView}
                  style={{ cursor: fromMapPage ? 'pointer' : 'unset' }}
                >
                  {result}
                </div>
              </>
            )}
            {dataCount > 0 &&
              props.dataStore.lookUpLists.isPeoplePage &&
              !props.dataStore.lookUpLists.isSummaryPage &&
              !company &&
              !fromProfile && (
                <>
                  <div>
                    <div style={{ display: 'flex' }}>
                      <div
                        className="view-label"
                        onClick={() => {
                          navigate('/prospect-finder/map');
                        }}
                      >
                        <LocationIcon style={{ marginRight: '5px' }} />
                        Show on Map
                      </div>
                    </div>
                  </div>
                </>
              )}

            {fromMapPage && (
              <>
                <div>
                  <span
                    className="count view-label"
                    onClick={props.navigateToPeopleView}
                  >
                    View List
                  </span>
                </div>
              </>
            )}

            {(!props.dataStore.lookUpLists.isSummaryPage ||
              company ||
              fromProfile) && (
              <>
                <div className="integrate-crm">
                  <img
                    style={{
                      width: '12px',
                      filter:
                        rowSelection.selectedRowKeys.length > 0 ||
                        props.dataStore.currentUser.crm === ''
                          ? props.dataStore.currentUser.crm === 'pd'
                            ? 'invert(0)'
                            : 'grayscale(0)'
                          : props.dataStore.currentUser.crm === 'pd'
                            ? 'invert(1)'
                            : 'grayscale(1)',
                    }}
                    alt=""
                    src={handleCrmIconsAfterIntegration(
                      props.dataStore.currentUser.crm,
                    )}
                  />
                  {rowSelection.selectedRowKeys.length > 0 ||
                  handleCrmLabels(props.dataStore.currentUser.crm) ===
                    'Integrate CRM' ? (
                    <div
                      className="link-label"
                      onClick={() => onCrmClick(false)}
                    >
                      {handleCrmLabels(props.dataStore.currentUser.crm)}
                    </div>
                  ) : (
                    <div className="disable-link">
                      {handleCrmLabels(props.dataStore.currentUser.crm)}
                    </div>
                  )}
                </div>

                {/*** Show the ExportCSV section only if there is data, check using
                 * @dataCount
                 * If any rows selected, then ExportCSV is available, otherwise ExportAll
                 * will be available
                 **/}

                {dataCount > 0 && (
                  <div className="export-csv">
                    <ExportCsvIcon fill={'var(--color-link)'} />
                    {rowSelection.selectedRowKeys.length < 1 ? (
                      // || selectAllRows
                      <label className="link-label" onClick={onExportClick}>
                        {props.dataStore.currentUser.isAdmin
                          ? 'Admin Export All'
                          : 'Export All'}
                      </label>
                    ) : (
                      <label
                        className={
                          rowSelection.selectedRowKeys.length > 0
                            ? 'link-label'
                            : 'disable-link'
                        }
                        onClick={() => {
                          onExportClick('FROM_SELECTED_ROW_EXPORT');
                        }}
                      >
                        {props.dataStore.currentUser.isAdmin
                          ? 'Admin Export'
                          : 'Export'}
                        {selectedCount > 0 ? '(' + selectedCount + ')' : ''}
                      </label>
                    )}
                  </div>
                )}

                {!fromMapPage &&
                  (flags && flags.tagFolders ? (
                    <TagsNew
                      from={'multiprofiles'}
                      selectedProfileIds={selectedProfileIds}
                      refreshData={refreshData}
                      dataCount={dataCount}
                    />
                  ) : (
                    <>
                      <div style={{ display: 'flex' }}>
                        <TagFilled
                          style={{
                            marginRight: '3px',
                            color: 'var(--color-link)',
                            // selectedProfileIds && selectedProfileIds.length > 0
                            //   ? 'var(--color-link)'
                            //   : 'var(--color-grey-dark)',
                          }}
                        />
                        <Tags
                          addFromTable
                          selectedProfileIds={selectedProfileIds}
                          tagClassName="active-section"
                          // tagClassName={
                          //   selectedProfileIds && selectedProfileIds.length > 0
                          //     ? 'active-section'
                          //     : 'inactive-section'
                          // }
                          resetSelection={cancelConfirmPopUp}
                          refreshData={refreshData}
                          // selectAllRows={selectAllRows}
                        />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <TagFilled
                          style={{
                            marginRight: '3px',
                            color: 'var(--color-link)',
                            // selectedProfileIds && selectedProfileIds.length > 0
                            //   ? 'var(--color-link)'
                            //   : 'var(--color-grey-dark)',
                          }}
                        />
                        <Tags
                          removeFromTable
                          selectedProfileIds={selectedProfileIds}
                          tagClassName="active-section"
                          // tagClassName={
                          //   selectedProfileIds && selectedProfileIds.length > 0
                          //     ? 'active-section'
                          //     : 'inactive-section'
                          // }
                          resetSelection={cancelConfirmPopUp}
                          refreshData={refreshData}
                          // selectAllRows={selectAllRows}
                        />
                      </div>
                    </>
                  ))}

                {exportPopUp && (
                  <PopUp
                    visible={exportPopUp}
                    title={
                      props.dataStore.currentUser.isAdmin
                        ? 'Admin Export All'
                        : 'Export All'
                    }
                    onOk={showConfirmation}
                    exportCsvText={renderExportContent()}
                    width="75%"
                    onCancel={onExportCancel}
                    textButton="Ok"
                  />
                )}

                {/* Delete option only available for admins for the pages - peopleview, profile, and companyprofile */}
                {props.dataStore.currentUser.isAdmin &&
                  (props.dataStore.lookUpLists.isPeoplePage ||
                    company ||
                    fromProfile) &&
                  dataCount > 0 && (
                    <div
                      //style={{ paddingLeft: '0' }}
                      className={
                        rowSelection.selectedRowKeys.length < 1
                          ? 'inactive-section'
                          : 'active-section'
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete();
                      }}
                    >
                      <DeleteFilled style={{ height: 16 }} />{' '}
                      <label
                        className={
                          rowSelection.selectedRowKeys.length > 0
                            ? 'link-label'
                            : 'disable-link'
                        }
                      >
                        Delete{' '}
                        {selectedCount > 0 ? '(' + selectedCount + ')' : ''}
                      </label>
                    </div>
                  )}
              </>
            )}
          </div>
        </>
      );
    },
  ),
);

export default ExportComponent;
