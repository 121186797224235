import './index.scss';
import './index.css';

import { Table } from 'antd';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import WarningMessage from '../../components/WarningMessage';
import ExportComponent from '../../components/ExportComponent';
import DiscoverBackground from '../../components/DiscoverBackground';
import LoaderAnimation from '../../components/AidLoader';

import { requestWithAuth, updateUserInfo } from '../../services/api';
import {
  companyViablityRanges,
  groupByFilter,
  interests,
  salaryFilter,
  wealthScoreRanges,
  companySizeFilter,
  remainingShareValues,
} from '../../utils/constants';
import {
  splitNumberWithCommas,
  formatEventName,
  getSubscription,
} from '../../utils/general';
import { getBodyParams } from '../../utils/getBodyParams';

let source;
const sortInfo = {
  column: '',
  order: '',
};

const InsightsView = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const location = useLocation();
    const [summaryList, setSummaryList] = useState([]);
    const [listPagination, setListPagination] = useState({
      totalPageSize: 0,
      currentPage: 1,
      size: props.dataStore.currentUser.pagination_count || 100,
    });
    const [title, setTitle] = useState('');
    const [responseCount, setResponseCount] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    const navigate = useNavigate();
    const flags = useFlags();
    // const [groupByParams, setGroupByParams] = useState(props.groupByParamValue || '');

    useEffect(() => {
      props.dataStore.lookUpLists.isSummaryPage = true;
      // summary filter need to reset initially

      // props.dataStore.resetGroupByValues();
      setResponseCount(0);

      if (document.getElementById('progress') != null) setProgress(true);
      if (document.getElementById('companyTable') !== null)
        document.getElementById('companyTable').style.display = 'none';

      const { CancelToken } = axios;
      source = CancelToken.source();

      if (
        props.dataStore.networkFilters.relmap_org &&
        props.dataStore.networkFilters.relmap_org.length > 0 &&
        props.dataStore.networkFilters.filter_type !== 'all'
      ) {
        props.dataStore.networkFilters.filter_type = 'all';
      }

      if (
        props.isCompany &&
        props.dataStore.isNetworkFilterApplied &&
        getSubscription(props.dataStore.currentUser)
      ) {
        fetchListData();
      }
      const autoUpdateDisposer = autorun(() => {
        if (!props.dataStore.lookUpLists.isSummaryPage) return;
        if (
          props.dataStore.networkFilters.fetchData &&
          props.dataStore.currentUser.activePlan
        ) {
          if (getSubscription(props.dataStore.currentUser)) {
            setListPagination({
              ...listPagination,
              currentPage: 1,
            });
            fetchListData();
          }
        }
      });
      return () => {
        if (source) source.cancel('Operation canceled by the user.');
        // locationListener();
        autoUpdateDisposer();
        props.dataStore.isShowingInsightsView = false;
        props.dataStore.lookUpLists.isSummaryPage = false;
        props.dataStore.lookUpLists.isRelationPathSelected = false;
        if (props.isCompany) {
          props.dataStore.lookUpLists.isCompanyPage = false;
        }
        sortInfo.column = '';
        sortInfo.order = '';
      };
    }, []);

    useEffect(() => {
      if (
        props.dataStore.selectedGroupByParam &&
        props.dataStore.selectedGroupByParam.length > 0 &&
        props.dataStore.isShowingInsightsView
      ) {
        setListPagination({
          ...listPagination,
          currentPage: 1,
        });
        if (
          props.dataStore.selectedGroupByParam.slice(-1).toString() ===
          'top_team_members'
        ) {
          /** when selected top_team_members clicked we are calling handledegree change in PF */
          props.dataStore.lookUpLists.isRelationPathSelected = true;
        } else {
          props.dataStore.lookUpLists.isRelationPathSelected = false;
        }

        const key = groupByFilter.filter(
          (params) =>
            params.value ===
            props.dataStore.selectedGroupByParam.slice(-1).toString(),
        );
        if (key && key.length > 0) setTitle(key[0].label || '');
        if (getSubscription(props.dataStore.currentUser)) fetchListData();
      }
      // else if (props.dataStore.selectedGroupByParam) {
      //     props.dataStore.networkFilters.fetchData = true;

      // }
    }, [...props.dataStore.selectedGroupByParam]);

    useEffect(() => {
      if (props.isCompany) {
        props.dataStore.lookUpLists.isCompanyPage = true;
        props.dataStore.lookUpLists.isRelationPathSelected = false;
        setTitle('Companies');
      }
      if (props.isCompany && !props.dataStore.isNetworkFilterApplied) {
        setResponseCount(0);
        setSummaryList([]);
        props.dataStore.networkFilters.fetchData = false;
      }
    }, [props.isCompany]);

    useEffect(() => {
      if (listPagination.totalPageSize === 0) return;
      fetchListData();
    }, [listPagination.size, listPagination.currentPage]);

    // useEffect(() => {
    //     if (
    //         location.pathname === '/prospect-finder/companies'
    //         // ||
    //         // location.pathname === '/prospect-finder/insights'
    //     ) {
    //         setSortedInfo({});
    //         setGroupByParams('org_name');
    //         setTitle('Companies');
    //         if (
    //             props.dataStore.isNetworkFilterApplied &&
    //             getSubscription()
    //         )
    //             fetchListData();

    //     }
    // }, [location])

    const getCompanyHeadCountValue = (selectedValue) => {
      let result;
      companySizeFilter.map((obj) => {
        if (obj.value === selectedValue) result = obj.label;
      });
      return result;
    };

    const setProgress = (value) => {
      setIsLoading(value);
    };

    const columns = [
      {
        title: title,
        key: 'key',
        sorter: true,
        // sortOrder: sortedInfo.columnKey === 'key' && sortedInfo.order,
        sortDirections: ['ascend', 'descend'],
        width: 400,
        render: (val) => {
          if (!val || !val.key) return <></>;
          const value = getValueLabels(val.key);
          return (
            <div
              className={
                title === 'Companies' && val.ai_org_id
                  ? 'company-link'
                  : 'company-link-disabled'
              }
              onClick={() => {
                if (!val.ai_org_id) return;
                props.showCompanyProfile(val.ai_org_id, val.key);
              }}
            >
              {title === 'Company Headcount' && value ? (
                <div>{getCompanyHeadCountValue(value)}</div>
              ) : (
                <div
                  style={{
                    textTransform:
                      title === 'Wealth Segment' ||
                      title === 'Remaining Share Balance'
                        ? 'none'
                        : 'capitalize',
                  }}
                >
                  {props.dataStore.selectedGroupByParam.slice(-1).toString() ===
                  'title_role'
                    ? value?.replace('_', ' ')
                    : value}
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: 'People',
        key: 'unique_count',
        align: 'center',
        sorter: true,
        // sortOrder: sortedInfo.columnKey === 'unique_count' && sortedInfo.order,
        sortDirections: ['ascend', 'descend'],
        width: 150,
        render: (rec, val) => {
          return (
            <div
              onClick={handleExecutiveClick.bind(
                this,
                rec.key,
                rec.team_member_id,
                rec.ai_org_id,
                rec.tag_id,
              )}
              className="executive-count"
            >
              <div>{splitNumberWithCommas(val.unique_count)}</div>
            </div>
          );
        },
      },
      {
        title: '',
        key: 'dummy',
      },
    ];

    const getValueLabels = (val) => {
      let value = val ? val.toString().toTitleCase() : val;
      if (
        props.dataStore.selectedGroupByParam.slice(-1).toString() ===
        'latest_mim_event_type'
      ) {
        value = formatEventName(val);
      } else if (
        props.dataStore.selectedGroupByParam.slice(-1).toString() ===
        'marital_status'
      ) {
        value = val === 'S' ? 'Single' : 'Married';
      } else if (
        props.dataStore.selectedGroupByParam.slice(-1).toString() === 'gender'
      ) {
        value = val === 'F' ? 'Female' : 'Male';
      } else if (
        props.dataStore.selectedGroupByParam.slice(-1).toString() ===
        'interests'
      ) {
        value = val.replace('Contribute', 'Charity');
      }

      return value;
    };

    // method to return the selected value
    const getValueSelected = (filterArray, selectedValue) => {
      let value;
      if (filterArray && filterArray.length > 0) {
        filterArray.map((filter) => {
          if (
            filter.label &&
            selectedValue &&
            filter.label.toString().toTitleCase() ===
              selectedValue.toString().toTitleCase()
          )
            value = [{ value: filter.value, label: selectedValue }];
        });
      }
      return value;
    };

    // method to call the api
    const fetchListData = () => {
      setProgress(true);
      if (document.getElementById('networkTable') !== null)
        document.getElementById('networkTable').style.display = 'none';

      if (source) source.cancel('Operation canceled by the user.');

      const { CancelToken } = axios;
      source = CancelToken.source();

      const body = getBodyParams(
        props.dataStore.networkFilters,
        props.dataStore.lookUpLists,
        props.dataStore.superConnectorFilter,
        props.dataStore.teamFilters,
        props.dataStore.currentUser,
        props.dataStore.mappedRelationshipsFilter,
        props.modalDataStore.profileModal,
        props.dataStore.groupByValues,
      );

      setSummaryList([]);
      setResponseCount(0);
      const selectedGroupBy = props.dataStore.selectedGroupByParam
        .slice(-1)
        .toString();

      let key;
      if (props.isCompany || selectedGroupBy === 'org_name')
        key = 'current_experience.ai_org_id';
      else if (selectedGroupBy === 'previous_experience.org_name')
        key = 'previous_experience.ai_org_id';
      else key = selectedGroupBy;

      const params = {
        page_size: listPagination.size,
        from: (listPagination.currentPage - 1) * listPagination.size,
        key,
      };

      if (props.dataStore.networkFilters.filter_type)
        params.filter_type = props.dataStore.networkFilters.filter_type;

      // if anything is searched, pass that
      if (props.dataStore.quick_search)
        params.quick_search = props.dataStore.quick_search;

      if (sortInfo.column && sortInfo.order) {
        params.sort_by = sortInfo.column === 'key' ? '_key' : '_count';
        params.sort_order = sortInfo.order;
      } else if (
        key === 'age_range' ||
        key === 'wealth_estimate' ||
        key === 'remaining_share_value_sum' ||
        key === 'employee_count' ||
        key === 'inferred_salary' ||
        key === 'mapped_connection_count'
      ) {
        params.sort_by = '_key';
        params.sort_order = 'asc';
      }

      requestWithAuth(
        'people-grouping-new',
        'POST',
        params,
        body,
        source.token,
      ).then((response) => {
        if (!response) return;
        setListPagination({
          ...listPagination,
          totalPageSize: response.count > 1000 ? 1000 : response.count,
        });

        // summaryDetail.totalCount = response.count;
        setSummaryList(
          response.results
            ? response.results.filter((obj) => {
                return obj.key !== 'unknown';
              })
            : [],
        );
        // if (groupByFilter && groupByFilter.length > 0) {
        //     setTitle(groupByFilter.filter(params=>params.value === groupByParams)[0])
        //     // groupByFilter.map((params) => {
        //     //     if (params.value === groupByParams)
        //     //         setTitle(params.label);
        //     // });
        // }
        if (response.count)
          setResponseCount(splitNumberWithCommas(response.count));
        else setResponseCount(0);

        setProgress(false);
        if (document.getElementById('table') !== null)
          document.getElementById('table').style.display = 'block';
      });

      props.dataStore.networkFilters.fetchData = false;
    };

    // method called when executives is clicked
    const handleExecutiveClick = (
      selectedValue,
      teamMemberId,
      orgId,
      tagId,
    ) => {
      const selectedParam = props.dataStore.selectedGroupByParam
        .slice(-1)
        .toString();
      props.dataStore.lookUpLists.isSummaryPage = false;
      switch (selectedParam) {
        case 'credentials':
        case 'property_type':
        case 'industry':
        case 'location_city':
        case 'schools':
        case 'location_state':
        case 'title':
        case 'title_role':
          props.dataStore.groupByValues[selectedParam] = [
            { value: selectedValue, label: selectedValue },
          ];
          break;

        case 'employee_count':
          props.dataStore.groupByValues[selectedParam] = [
            {
              value: selectedValue,
              label: getCompanyHeadCountValue(selectedValue),
            },
          ];
          break;

        case 'home_postal_code':
          props.dataStore.groupByValues.home_postal_code = selectedValue;
          break;
        case 'funding_round':
          props.dataStore.groupByValues.funding_round = selectedValue;
          break;
        case 'age_range': {
          let ageVal = {};
          if (selectedValue === '80+') ageVal = { gte: '81', lte: '150' };
          else {
            const ageArr = selectedValue.split('-');
            ageVal = { gte: ageArr[0], lte: ageArr[1] };
          }
          props.dataStore.groupByValues.age = ageVal;
          break;
        }
        case 'mapped_connection_count': {
          let mappedCountObj = {};
          if (selectedValue === '200+')
            mappedCountObj = { gte: 200, lte: null };
          else {
            const countArr = selectedValue.split('-');
            mappedCountObj = { gte: countArr[0], lte: countArr[1] };
          }
          props.dataStore.groupByValues.mapped_connection_count =
            mappedCountObj;
          break;

          // for (var i = mappedConnectionsCount.length - 1; i >= 0; i--){
          //   if(selectedValue > mappedConnectionsCount[i].valueObject.gte){
          //     props.dataStore.groupByValues.mapped_connection_count = mappedConnectionsCount[i].valueObject;
          //     break;
          //   }
          // }

          // props.dataStore.groupByValues.mapped_connection_count =
          //    {gte:selectedValue, lte: selectedValue };
          // break;
        }
        case 'number_of_owned_properties': {
          const selectedNum = selectedValue.split(' ')[0];
          const numRange = { label: selectedNum };
          if (selectedNum === '5+') {
            numRange.gte = '6';
            numRange.lte = null;
          } else {
            numRange.gte = selectedNum;
            numRange.lte = selectedNum;
          }
          props.dataStore.groupByValues.number_of_owned_properties = [numRange];
          break;
        }
        case 'org_name':
          props.dataStore.groupByValues[selectedParam] = [
            { value: orgId, label: selectedValue },
          ];
          break;
        case 'previous_experience.org_name':
          props.dataStore.groupByValues.previous_experience_org_name = [
            { value: orgId, label: selectedValue },
          ];
          break;
        case 'stock_performance':
          companyViablityRanges.map((confidence) => {
            if (confidence && confidence.label === selectedValue) {
              props.dataStore.groupByValues.stock_performance = [
                {
                  label: confidence.label,
                  value: confidence.value,
                },
              ];
            }
          });

          break;
        case 'latest_mim_event_type':
          props.dataStore.groupByValues.latest_mim_event_type = [
            { value: selectedValue, label: formatEventName(selectedValue) },
          ];
          // props.dataStore.networkFilters.is_wealth_events = 0;
          break;
        case 'title_rank':
          props.dataStore.groupByValues.title_rank = getValueSelected(
            props.dataStore.lookUpLists.titleRankList,
            selectedValue,
          );
          break;
        case 'inferred_salary':
          if (salaryFilter && salaryFilter.length > 0) {
            salaryFilter.map((salary) => {
              if (salary.value.toLowerCase() === selectedValue.toLowerCase()) {
                props.dataStore.groupByValues.inferred_salary_range = [
                  {
                    value: salary.value,
                    label: salary.label,
                    min: salary.min,
                    max: salary.max,
                  },
                ];
              }
            });
          }
          break;
        case 'wealth_estimate':
          if (wealthScoreRanges && wealthScoreRanges.length > 0) {
            wealthScoreRanges.map((score) => {
              if (score.label.toLowerCase() === selectedValue.toLowerCase()) {
                props.dataStore.groupByValues.wealth_estimate = [
                  {
                    value: score.value,
                    label: score.label,
                    min: score.min,
                    max: score.max,
                  },
                ];
              }
            });
          }
          break;

        case 'remaining_share_value_sum':
          if (remainingShareValues && remainingShareValues.length > 0) {
            remainingShareValues.map((shareObj) => {
              if (
                shareObj.tableValue.toLowerCase() ===
                selectedValue.toLowerCase()
              ) {
                props.dataStore.groupByValues.remaining_share_value_sum = [
                  {
                    value: shareObj.value,
                    label: shareObj.label,
                    min: shareObj.min,
                    max: shareObj.max,
                  },
                ];
              }
            });
          }
          break;
        case 'interests':
          props.dataStore.groupByValues.interests = getValueSelected(
            interests,
            selectedValue,
          );
          break;
        case 'top_team_members':
          props.dataStore.groupByValues.team_member_id = teamMemberId;
          break;
        case 'tags_column':
          props.dataStore.groupByValues.tags = {
            value: tagId,
            label: selectedValue,
          };
          break;
        case 'marital_status':
        case 'gender':
        default:
          props.dataStore.groupByValues[selectedParam] = selectedValue;
          break;
      }
      props.dataStore.isShowingInsightsView = false;
      if (props.isCompany) {
        props.dataStore.groupByValues.org_name = [
          { value: orgId, label: selectedValue },
        ];
        navigate('/prospect-finder/people');
      } else {
        window.history.pushState(
          { insight: selectedParam },
          null,
          window.location.pathname,
        );

        props.dataStore.networkFilters.fetchData = true;
        // props.dataStore.selectedGroupByParam = [];
      }
    };

    // method called on table onchange
    const handleTableChange = (pagination, filters, sorter) => {
      if (sorter && sorter.order) {
        sortInfo.column = sorter.columnKey;
        sortInfo.order = sorter.order === 'ascend' ? 'asc' : 'desc';
      } else {
        sortInfo.column = '';
        sortInfo.order = '';
      }

      if (pagination.pageSize !== listPagination.pageSize) {
        updateUserInfo(props.dataStore.currentUser.ownerId, {
          pagination_count: pagination.pageSize,
        });
        props.dataStore.currentUser.pagination_count = pagination.pageSize;
      }

      setListPagination({
        ...listPagination,
        currentPage: pagination.current,
        size: pagination.pageSize,
      });
      fetchListData();
    };

    if (
      !props.dataStore.currentUser.activePlan &&
      props.dataStore.currentUser.activePlan === ''
    ) {
      return <LoaderAnimation />;
    }

    if (!getSubscription(props.dataStore.currentUser)) {
      return (
        <WarningMessage
          iconName="upgrade"
          content="To view executives, upgrade today!"
          buttonText="Subscribe"
        />
      );
    }

    if (!props.dataStore.isNetworkFilterApplied && props.isCompany)
      return <DiscoverBackground />;

    return (
      <div
        className="insights-container loader-wrapper"
        style={{
          top: '-12px',
        }}
      >
        {isLoading && <LoaderAnimation loaderId="insights-loader" />}
        <div className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
          {/* <div className="search-result">
                    {responseCount} Result(s)
                </div> */}

          <ExportComponent
            dataCount={responseCount}
            setProgress={setProgress}
          />
          <div className="network-list-container">
            <Table
              className="insights-table"
              columns={columns}
              dataSource={summaryList}
              rowKey={(record) => record._id}
              pagination={{
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['20', '50', '100', '200'],
                total: listPagination.totalPageSize,
                pageSize: listPagination.size,
                current: listPagination.currentPage,
              }}
              onChange={handleTableChange}
              scroll={{ x: 'max-content', y: 600 }}
              showSorterTooltip={false}
              rowClassName="insights-table-row"
            />
          </div>
        </div>
      </div>
    );
  }),
);

export default InsightsView;
