import './index.scss';

import { useEffect, useState } from 'react';
import { message } from 'antd';
import axios from 'axios';
import { inject } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router';

import LoftyIcon from '../../assets/icons/ai_lofty.svg';
import RedtailIcon from '../../assets/icons/ai_redtail.svg';
import HubspotIcon from '../../assets/icons/ai_hubspot.svg';
import SalesforceIcon from '../../assets/icons/ai_salesforce.svg';

import ChimeView from '../../components/ConnectExport/chimeView';
import HubspotView from '../../components/ConnectExport/hubspotView';
import RedtailView from '../../components/ConnectExport/redtailView';
import SalesforceView from '../../components/ConnectExport/salesforceView';
import { showConfirm } from '../../utils/popup';
import { updateUserInfo, requestWithAuth } from '../../services/api';
import LoaderAnimation from '../../components/AidLoader';

message.config({
  top: 80,
});
let source;

const Export = inject('dataStore')((props) => {
  let resp = null;
  const navigate = useNavigate();
  const location = useLocation();
  const [activeExport, setActiveExport] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [crmValue, setCrmValue] = useState('');

  useEffect(() => {
    setIsLoading(true);
    requestWithAuth('user-type', 'GET', null, null, null).then((response) => {
      if (
        response &&
        response.results &&
        response.results[0] &&
        response.results[0].crm
      ) {
        setCrmValue(response.results[0].crm || '');
        props.dataStore.currentUser.crm = response.results[0].crm || '';

        if (
          location &&
          location.state &&
          location.state.crmSource &&
          location.state.response
        ) {
          resp = location.state.response;
          if (resp) {
            switch (location.state.crmSource) {
              case 'ct':
                if (resp.code === 200) {
                  setCrmValue('ct');
                  setActiveExport('ct');

                  updateUserInfo(props.dataStore.currentUser.ownerId, {
                    crm: 'ct',
                  });
                } else if (resp.errorCode !== undefined) {
                  message.error(resp.errorCode, 10);
                } else {
                  message.error('Please try again', 5);
                }
                break;
              case 'sf':
                setCrmValue('sf');
                setActiveExport('sf');
                updateUserInfo(props.dataStore.currentUser.ownerId, {
                  crm: 'sf',
                });
                break;
              case 'rt':
              case 'cm':
                if (resp) setActiveExport(resp);
                break;
              case 'pd':
                if (resp.code === 200) {
                  setCrmValue('pd');
                  setActiveExport('pd');
                  updateUserInfo(props.dataStore.currentUser.ownerId, {
                    crm: 'pd',
                  });
                } else if (resp.errorCode !== undefined) {
                  message.error(resp.errorCode, 10);
                } else {
                  message.error('Please try again', 5);
                }
                break;
              case 'hs':
                if (resp.code === 200) {
                  setCrmValue('hs');
                  setActiveExport('hs');
                  updateUserInfo(props.dataStore.currentUser.ownerId, {
                    crm: 'hs',
                  });
                } else if (resp.errorCode !== undefined) {
                  message.error(resp.errorCode, 10);
                } else {
                  message.error('Please try again', 5);
                }
                break;
              default:
                requestWithAuth('user-type', 'GET', null, null, null).then(
                  (response) => {
                    if (response && response.results && response.results[0]) {
                      const { crm } = response.results[0];
                      setCrmValue(crm);
                      props.dataStore.currentUser.crm = crm;
                    }
                  },
                );
                break;
            }
          } else {
            setCrmValue('');
            message.error('Please try again', 5);
          }
          window.history.replaceState(null, null, window.location.pathname);
        }
      }
      setIsLoading(false);

      if (localStorage.getItem('redirectionPath')) {
        const path = localStorage.getItem('redirectionPath');
        localStorage.removeItem('redirectionPath');
        navigate(path);
      }
    });

    const { CancelToken } = axios;
    source = CancelToken.source();
    return () => {
      message.destroy();
      if (source) source.cancel('Operation canceled by the user.');
    };
  }, []);

  const afterIntegrationSuccess = () => {
    requestWithAuth('user-type', 'GET', null, null, null).then((response) => {
      if (response && response.results && response.results[0]) {
        const { crm } = response.results[0];
        setCrmValue(crm);
        setActiveExport(crm || '');
        props.dataStore.currentUser.crm = crm;
      }
    });
    setIsLoading(false);
  };

  const removeIntegration = () => {
    setIsLoading(true);
    let urlPath = '';
    switch (crmValue) {
      case 'sf':
        urlPath = 'salesforce-register/0';
        break;
      case 'rt':
        urlPath = 'redtail-auth/0';
        break;
      case 'cm':
        urlPath = 'chime-token/0';
        break;
      case 'hs':
        urlPath = 'hubspot-register/0';
        break;
      default:
        break;
    }

    requestWithAuth(urlPath, 'DELETE', null, null, source.token).then(
      (response) => {
        if (!response) {
          setIsLoading(false);
          message.error('Something went wrong. Please try again.', 5);
          return;
        }
        const params = {
          crm: '',
        };
        updateUserInfo(props.dataStore.currentUser.ownerId, params)
          .then(() => {
            requestWithAuth('user-type', 'GET', null, null, null).then(
              (response) => {
                if (response && response.results && response.results[0]) {
                  const { crm } = response.results[0];
                  setCrmValue(crm);
                  props.dataStore.currentUser.crm = crm;
                }
              },
            );
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            message.error('Something went wrong. Please try again.', 5);
            setIsLoading(false);
          });
      },
    );
  };

  const ExportItem = ({ crmCode, title, icon }) => {
    return (
      <div
        className="container-item"
        onClick={() => {
          setActiveExport(crmCode);
        }}
        style={{
          height: '246px',
          width: '190px',
        }}
      >
        <div style={{ position: 'relative' }}>
          <img className="item-icon" src={icon} alt={title} />
        </div>
        <div className="title-name">{title}</div>
        {crmCode === crmValue && (
          <div
            onClick={(event) => {
              event.stopPropagation();
              showConfirm({
                className: 'small-popup',
                closable: true,
                onOk: () => removeIntegration(),
                title: `Remove ${getCrmName()}`,
                content: (
                  <h4>Please confirm disconnecting from {getCrmName()}</h4>
                ),
              });
            }}
            className="remove-text"
          >
            Remove
          </div>
        )}
      </div>
    );
  };

  const getCrmName = () => {
    let crmName = 'CRM';
    switch (crmValue) {
      case 'sf':
        crmName = 'Salesforce';
        break;
      case 'rt':
        crmName = 'Redtail';
        break;
      case 'cm':
        crmName = 'Lofty';
        break;
      case 'hs':
        crmName = 'Hubspot';
        break;
      default:
        break;
    }
    return crmName;
  };

  const renderConnectComponent = () => {
    switch (activeExport) {
      case 'cm':
        return (
          <ChimeView
            onSuccessIntegration={afterIntegrationSuccess}
            removeIntegration={removeIntegration}
            crmValue={crmValue}
          />
        );
      case 'hs':
        return (
          <HubspotView
            removeIntegration={removeIntegration}
            crmValue={crmValue}
          />
        );
      case 'rt':
        return (
          <RedtailView
            onSuccessIntegration={afterIntegrationSuccess}
            removeIntegration={removeIntegration}
            crmValue={crmValue}
          />
        );
      case 'sf':
        return (
          <SalesforceView
            removeIntegration={removeIntegration}
            crmValue={crmValue}
          />
        );
      default: {
        return (
          <>
            <div className="container" id="export-options">
              <ExportItem crmCode="hs" title="Hubspot" icon={HubspotIcon} />
              <ExportItem
                crmCode="sf"
                title="Salesforce"
                icon={SalesforceIcon}
              />
              <ExportItem crmCode="cm" title="Lofty" icon={LoftyIcon} />
              <ExportItem crmCode="rt" title="Redtail" icon={RedtailIcon} />
            </div>
          </>
        );
      }
    }
  };

  return (
    <div className="loader-wrapper">
      {isLoading && <LoaderAnimation loaderId="export-loader" />}
      <div
        id="export"
        className={
          isLoading
            ? 'import-export-container aid-div-disable'
            : 'import-export-container aid-div-enable'
        }
      >
        <div className="import-export-left">
          <div className="title">Export</div>
          <div className="description">
            Need to export a prospect or list? No problem. Just pick your
            destination.
          </div>
          {activeExport && (
            <div
              className="back-to"
              onClick={() => {
                setActiveExport('');
              }}
            >
              {'<< Back to Options'}
            </div>
          )}
        </div>
        <div className="export-right">{renderConnectComponent()}</div>
      </div>
    </div>
  );
});

export default Export;
