import './index.scss';

import { Card } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  formatValue,
  formatEventName,
  formatEventSource,
  formatEventDescription,
  formatInvestor,
} from '../../utils/general';
import CardsAndTable from '../CardsAndTable';

const eventFilingClick = (url) => {
  window.open(url);
};

const EmployeeStockTransactions = (props) => {
  const { list, showProfile } = props;
  const rowKeyArray = [];
  const columns = [
    {
      title: 'Trigger',
      dataIndex: 'event_type',
      key: 'event_type',
      width: 140,
      render: (val, rec) => {
        /* Setting id as unique key array */
        if (val === undefined || val === null) return '';
        rowKeyArray.push(rec.event_id);
        const value = formatEventName(val);
        return (
          <div
            className="event-type-header"
            onClick={() => {
              if (
                rec.event_filing_url &&
                val &&
                (val.includes('insider') || val === 'investment received')
              )
                eventFilingClick(rec.event_filing_url);
            }}
          >
            {value}
            {rec.event_filing_url &&
              val &&
              (val.includes('insider') || val === 'investment received') && (
                <SearchOutlined className="search-icon" />
              )}
          </div>
        );
      },
      filters: props.eventFilters, // exclude All from eventList
      filterMultiple: true,
      onFilter: (value, record) => record.event_type === value,
      sorter: (a, b) => {
        a = a.event_type || '';
        b = b.event_type || '';
        return a.localeCompare(b);
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      width: 200,
      dataIndex: 'event_description',
      className: 'column-right',
      key: 'event_description',
      render: (val, rec) => {
        if (rec.event_id === undefined || rec.event_id === null) return '';
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              color: 'var(--color-profile-labels)',
            }}
          >
            {formatEventDescription(val, rec.event_type)}
          </div>
        );
      },
    },
    {
      title: 'Source',
      width: 120,
      dataIndex: 'event_source',
      // className: 'column-right',
      // key: 'event_description',
      render: (val, rec) => {
        if (val === undefined) return '';
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              color: 'var(--color-profile-labels)',
              paddingLeft: '16px',
            }}
          >
            {formatEventSource(val)}
          </div>
        );
      },
    },
    {
      title: 'Investment Round',
      width: 150,
      dataIndex: 'event_funding_round',
      // className: 'column-right',
      // key: 'event_description',
      render: (val, rec) => {
        if (!val) return '';
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              color: 'var(--color-profile-labels)',
              paddingLeft: '16px',
            }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'Value',
      width: 150,
      dataIndex: 'event_monetary_value',
      className: 'column-right',
      key: 'event_monetary_value',
      sorter: (a, b) => a.event_monetary_value - b.event_monetary_value,
      sortDirections: ['descend', 'ascend'],
      render: (val) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              color: 'var(--color-primary)',
              fontWeight: '600',
            }}
          >
            {val === null ? '' : formatValue(val)}
          </div>
        );
      },
    },
    {
      title: 'Date',
      width: 110,
      dataIndex: 'event_date',
      key: 'event_date',
      render: (val) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              color: 'var(--color-primary)',
              fontWeight: '600',
            }}
          >
            {moment(val).format('MM/DD/YYYY')}
          </div>
        );
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        a = a.event_date || '';
        b = b.event_date || '';
        return a.localeCompare(b);
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'full_name',
      width: 180,
      sorter: (a, b) => {
        a = a.full_name || '';
        b = b.full_name || '';
        return a.localeCompare(b);
      },
      sortDirections: ['ascend', 'descend'],
      render: (val, rec) => {
        return (
          <div
            className="profile-link"
            onClick={(e) => {
              e.stopPropagation();
              showProfile(rec.b2b_id, rec.b2c_id);
            }}
          >
            {val ? val.toString().toTitleCase() : ''}
          </div>
        );
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 180,
      sorter: (a, b) => {
        a = a.title || '';
        b = b.title || '';
        return a.localeCompare(b);
      },
      sortDirections: ['ascend', 'descend'],
      render: (val) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
            }}
          >
            {val ? val.toString().toTitleCase() : ''}
          </div>
        );
      },
    },
  ];
  const cardColumns = [
    {
      title: 'Sort by:  Trigger',
      dataIndex: 'event_type',
      key: 'event_type',
      width: 190,
      render: (val, rec) => {
        /* Setting id as unique key array */
        if (val === undefined || val === null) return '';
        rowKeyArray.push(rec.event_id);
        const value = formatEventName(val);
        return <div className="event-type-header">{value}</div>;
      },
      filters: props.eventFilters, // exclude All from eventList
      filterMultiple: true,
      onFilter: (value, record) => record.event_type === value,
      sorter: (a, b) => {
        a = a.event_type || '';
        b = b.event_type || '';
        return a.localeCompare(b);
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      width: 200,
      dataIndex: 'event_description',
      className: 'column-right',
      key: 'event_description',
      render: (val, rec) => {
        if (rec.event_id === undefined || rec.event_id === null) return '';
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              color: 'var(--color-profile-labels)',
            }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'Value',
      width: 150,
      dataIndex: 'event_monetary_value',
      className: 'column-right',
      key: 'event_monetary_value',
      sorter: (a, b) => a.event_monetary_value - b.event_monetary_value,
      sortDirections: ['descend', 'ascend'],
      render: (val) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              color: 'var(--color-primary)',
              fontWeight: '600',
            }}
          >
            {val === null ? '' : formatValue(val)}
          </div>
        );
      },
    },
    {
      title: 'Date',
      width: 110,
      dataIndex: 'event_date',
      key: 'event_date',
      render: (val) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              color: 'var(--color-primary)',
              fontWeight: '600',
            }}
          >
            {moment(val).format('MM/DD/YYYY')}
          </div>
        );
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        a = a.event_date || '';
        b = b.event_date || '';
        return a.localeCompare(b);
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'full_name',
      width: 180,
      sorter: (a, b) => {
        a = a.full_name || '';
        b = b.full_name || '';
        return a.localeCompare(b);
      },
      sortDirections: ['ascend', 'descend'],
      render: (val, rec) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              color: 'var(--color-primary)',
              fontWeight: '600',
              cursor: 'pointer',
            }}
          >
            {val ? val.toString().toTitleCase() : ''}
          </div>
        );
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 180,
      sorter: (a, b) => {
        a = a.title || '';
        b = b.title || '';
        return a.localeCompare(b);
      },
      sortDirections: ['ascend', 'descend'],
      render: (val) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
            }}
          >
            {val ? val.toString().toTitleCase() : ''}
          </div>
        );
      },
    },
    { title: '', key: 'dummy' },
  ];

  const CompanyStockTransactionCard = (listItem) => {
    const item = listItem;
    const title = (
      <>
        {item.event_type && formatEventName(item.event_type)}
        {item.event_type &&
          (item.event_type.includes('insider') ||
            item.event_type === 'investment received') &&
          item.event_filing_url && (
            <SearchOutlined className="search-icon-card" />
          )}
      </>
    );
    return (
      <Card
        style={{
          height: '179px',
          //   overflowY: 'auto',
          width: '387px',
          position: 'relative',
          borderRadius: '6px',
          cursor: `${item.event_filing_url ? 'pointer' : 'default'}`,
        }}
        size="small"
        title={title}
        onClick={() => {
          if (
            item.event_type &&
            (item.event_type.includes('insider') ||
              item.event_type === 'investment received') &&
            item.event_filing_url
          )
            eventFilingClick(item.event_filing_url);
        }}
      >
        <div className="description-text">
          <span
            className="link"
            onClick={(e) => {
              e.stopPropagation();
              showProfile(item.b2b_id, item.b2c_id);
            }}
          >
            {item.full_name && item.full_name}
          </span>
          {item.full_name && item.title && ', '}
          {item.title && item.title}
          {item.event_description && (
            <div>
              {formatEventDescription(item.event_description, item.event_type)}
              {item.event_source &&
                ` (Source: ${formatEventSource(item.event_source)})`}
            </div>
          )}
          <div>
            {item.event_funding_round && (
              <>
                {' '}
                <span style={{ color: 'var(--color-grey-dark)' }}>
                  Investment Round{': '}
                </span>
                {item.event_funding_round}
              </>
            )}
          </div>
          <div>
            {item.event_investor_name && (
              <>
                {' '}
                <span style={{ color: 'var(--color-grey-dark)' }}>
                  Investors{': '}
                </span>
                {formatInvestor(item.event_investor_name)}
              </>
            )}
          </div>
        </div>

        <div className="card-bottom">
          {item.event_monetary_value !== undefined &&
            item.event_monetary_value !== null && (
              <div
                style={{
                  fontSize: '21px',
                  color: 'var(--color-primary)',
                  paddingRight: '9px',
                }}
              >
                {formatValue(item.event_monetary_value)}
              </div>
            )}
          {item.event_date && (
            <div style={{ color: 'var(--color-column)', paddingTop: '8px' }}>
              {moment(item.event_date).format('MM/DD/YYYY')}
            </div>
          )}
        </div>
      </Card>
    );
  };

  return (
    <div className="employee-transaction">
      <CardsAndTable
        columns={columns}
        cardColumns={cardColumns}
        list={list}
        cardFunction={CompanyStockTransactionCard}
        title="Employee Stock Transactions"
      />
    </div>
  );
};

export default EmployeeStockTransactions;
