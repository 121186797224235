import './index.css';

import { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const CopyToClipBoard = (props) => {
  const [open, setOpen] = useState(false);
  const { textId, value } = props;

  const copyData = (data) => {
    if (!navigator.clipboard) {
      const element = document.createElement('textarea');
      document.body.appendChild(element);
      element.value = data;
      element.select();
      document.execCommand('copy');
      document.body.removeChild(element);
    } else {
      navigator.clipboard.writeText(data);
    }
    setOpen(true);
    setTimeout(() => setOpen(false), 1000);
  };

  return (
    <div className="popupParent">
      <div title="Copy to Clipboard">
        <div onClick={() => copyData(value)} className="clipBoard">
          <Tooltip title="copied" trigger="click" showArrow={false} open={open}>
            {textId === 'email' ? (
              <CopyOutlined />
            ) : (
              <Button style={{ width: '90px' }} type="primary">
                Copy
              </Button>
            )}
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default CopyToClipBoard;
