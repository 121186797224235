import { inject } from 'mobx-react';

const MoreInfoSection = inject('dataStore')((props) => {
  /** 2nd screen of csv import doesn't need to show the text
   * For other screen @isCSVImport will be undefined
   * for csv import it will be true for 1st screen and false for 2nd screen
   */
  if (props.isCSVImport === false) return;
  return (
    <div className="learn-more">
      <span className="learn-more-section">
        By using this Import function
        {props.isCSVImport && <> and clicking ‘Next’</>}, you agree that the
        information you upload is U.S. information only and does not contain any
        global data, in particular EU data. Please visit our{' '}
        <a
          href="https://www.aidentified.com/terms-conditions/"
          target="_blank"
          rel="noreferrer noopener"
          className="learnMoreLink"
        >
          Terms &amp; Conditions
        </a>{' '}
        and our{' '}
        <a
          href="https://www.aidentified.com/privacy-policy-2/"
          target="_blank"
          className="learnMoreLink"
          rel="noreferrer noopener"
        >
          Privacy Policy{' '}
        </a>
        for further information about Aidentified{"'"}s services and personal
        information collection, processing and sharing practices
        {'. '}
        Send any questions to
        <a
          className="learnMoreLink"
          target="_blank"
          href="mailto: support@aidentified.com"
          rel="noreferrer noopener"
        >
          {' '}
          support@aidentified.com
        </a>
      </span>
    </div>
  );
});

export default MoreInfoSection;
