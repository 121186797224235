import './index.css';

import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Input, Select, DatePicker } from 'antd';
import dayjs from 'dayjs';

const InputGroup = Input.Group;
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'MM/DD/YYYY';

const ExactDateRangePicker = inject('dataStore')(
  observer((props) => {
    const [selectedRange, setSelectedRange] = useState([]);
    const [selectedOption, setSelectedOption] = useState('between');

    useEffect(() => {
      const dateRange = [];
      if (props.value && props.value.length > 0) {
        props.value.map((date) => {
          if (date) dateRange.push(dayjs(date).format('MM/DD/YYYY'));
        });

        if (props.value[0] === props.value[1]) setSelectedOption('exact');
        else setSelectedOption('between');
      }
      setSelectedRange(dateRange);
    }, [props.value]);

    const onRangePickerChange = (value) => {
      const { onCalendarChange, id } = props;

      if (value == null) {
        setSelectedRange([]);
        onCalendarChange([], id);
      } else {
        setSelectedRange(value);
        onCalendarChange(value, id);
      }
    };

    const onDatePickerChange = (value) => {
      const { onCalendarChange, id } = props;
      if (value == null) {
        setSelectedRange([]);
        onCalendarChange([], id);
      } else {
        setSelectedRange([value, value]);
        onCalendarChange([value, value], id);
      }
    };

    const onDropDownSelect = (value) => {
      const { onCalendarChange, id } = props;
      setSelectedOption(value === '1' ? 'between' : 'exact');
      onCalendarChange([], props.id);
    };

    return (
      <InputGroup>
        <div className="range-picker-parent-new purchase-date">
          <Select
            bordered={false}
            className="range-picker"
            popupClassName="range-picker-dropdown"
            disabled={props.disabled || false}
            style={{ minWidth: 100, height: 40 }}
            value={selectedOption === 'between' ? '1' : '2'}
            onChange={onDropDownSelect}
          >
            <Option value="1">Between</Option>
            <Option value="2">Exact</Option>
          </Select>
          {selectedOption === 'between' ? (
            <RangePicker
              className="date-picker large-content"
              format={dateFormat}
              bordered={false}
              value={
                selectedRange && selectedRange.length > 0
                  ? [dayjs(selectedRange[0]), dayjs(selectedRange[1])]
                  : undefined
              }
              onChange={onRangePickerChange}
              disabledDate={(current) =>
                current && current > dayjs().endOf('day')
              }
              disabled={props.disabled || false}
            />
          ) : (
            <DatePicker
              className="date-picker"
              format={dateFormat}
              disabled={props.disabled || false}
              value={
                selectedRange && selectedRange.length > 0
                  ? dayjs(selectedRange[0])
                  : undefined
              }
              onChange={onDatePickerChange}
            />
          )}
        </div>
      </InputGroup>
    );
  }),
);

export default ExactDateRangePicker;
