import './RelationshipIntelligence.css';

import { Component } from 'react';
import { Outlet } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import cloneDeep from 'lodash/cloneDeep';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import NavTab from '../../components/NavTab';
import Progress from '../../components/Progress';
import { requestWithAuth } from '../../services/api';
import { copyUserFilter } from '../../utils/copyUserFilter';
import { relationshipIntelligence } from '../../utils/constants';
import { getSubscription } from '../../utils/general';
import LoaderAnimation from '../../components/AidLoader';

class RelationshipIntelligence extends Component {
  constructor(props) {
    super(props);

    this.props.globalDataStore.resetMasterFilter();
    this.props.dataStore.resetNetworkFilters();
    this.props.dataStore.resetAppliedMasterFilter();
    this.props.globalDataStore.multipleFilters.isFilterApplied = false;
    this.props.globalDataStore.multipleFilters.selectedId =
      this.props.dataStore.networkFilters.selectedIdValue;
    let selectedIdList = [];
    const allFilterIds = [];
    if (this.props.globalDataStore.multipleFilters.filterList.length > 0) {
      this.props.globalDataStore.multipleFilters.filterList.map((filter) => {
        if (filter.is_active) {
          this.props.globalDataStore.multipleFilters.selectedId = filter.value;
          selectedIdList = [{ value: filter.value, label: filter.label }];
          this.props.dataStore.networkFilters.selectedIdValue = filter.value;
          this.props.dataStore.appliedMasterFilters.filter_type =
            filter.filter_type;
          this.props.globalDataStore.multipleFilters.isFilterApplied = true;
          this.props.globalDataStore.masterFilters.filter_type =
            filter.filter_type;
        }
        allFilterIds.push({
          value: filter.value,
          label: filter.label,
        });
      });
      props.globalDataStore.multipleFilters.allFilterIds = allFilterIds;
      /** if no default filter then add all filters, for opportunities & superconnectors */
      if (props.globalDataStore.multipleFilters.fromFilters) return;
      else if (selectedIdList.length === 0) {
        this.props.globalDataStore.multipleFilters.selectedIdList = [
          { value: -1, label: 'All Prospect Filters' },
          ...this.props.globalDataStore.multipleFilters.allFilterIds,
        ];
        // this.props.globalDataStore.multipleFilters.opporunitySelectedIdList =
        //   this.props.globalDataStore.multipleFilters.selectedIdList;
      } else {
        this.props.globalDataStore.multipleFilters.selectedIdList =
          selectedIdList;
        // this.props.globalDataStore.multipleFilters.opporunitySelectedIdList =
        //   selectedIdList;
      }
    } else if (getSubscription(props.dataStore.currentUser))
      this.fetchMultipleFilters();
  }

  componentWillUnmount() {
    this.props.dataStore.relationship_quick_search = '';
    this.props.globalDataStore.multipleFilters.fromFilters = false;
  }

  saveUserFilter = (
    filters,
    id,
    name,
    is_active,
    email_alert,
    separate_email_alert,
    filter_type,
  ) => {
    this.props.globalDataStore.reset();
    this.props.globalDataStore.multipleFilters.isFilterApplied = true;

    if (id) this.props.globalDataStore.masterFiltersOnSelect.profile_id = id;
    const dataStoreFilters = copyUserFilter(
      filters,
      this.props.globalDataStore.masterFiltersOnSelect,
      this.props.dataStore.lookUpLists,
      name,
      is_active,
      email_alert,
      separate_email_alert,
      false,
    );

    this.props.globalDataStore.masterFiltersOnSelect =
      cloneDeep(dataStoreFilters);

    this.props.globalDataStore.masterFilters = cloneDeep(
      this.props.globalDataStore.masterFiltersOnSelect,
    );

    this.props.globalDataStore.masterFiltersOnSelect.filter_type = filter_type;
    if (is_active) {
      this.props.dataStore.networkFilters = cloneDeep(
        this.props.globalDataStore.masterFiltersOnSelect,
      );
      this.props.dataStore.networkFilters.prospect_profile_name = name;
      this.props.dataStore.networkFilters.default_view = is_active;
      this.props.dataStore.networkFilters.selectedIdValue = id;
    }
  };

  fetchMultipleFilters = () => {
    requestWithAuth('multiple-filters', 'GET', null, null, null)
      .then((response) => {
        const filters = [];
        let selectedIdList = [];
        if (response && response.length > 0) {
          response.map((filter) => {
            if (filter.name) {
              if (filter.is_active) {
                this.props.dataStore.networkFilters.selectedIdValue = filter.id;
                this.props.globalDataStore.multipleFilters.isFilterApplied = true;
                this.props.globalDataStore.multipleFilters.selectedId =
                  filter.id;
                selectedIdList = [{ value: filter.id, label: filter.name }];
                this.props.dataStore.appliedMasterFilters.filter_type =
                  filter.filter_type;
                this.props.globalDataStore.masterFilters.filter_type =
                  filter.filter_type;
              }
              this.props.globalDataStore.multipleFilters.allFilterIds.push({
                value: filter.id,
                label: filter.name,
              });
              if (filter.is_active) {
                this.saveUserFilter(
                  filter.filters,
                  filter.id,
                  filter.name,
                  filter.is_active,
                  filter.email_alert,
                  filter.separate_email_alert,
                  filter.filter_type,
                );
              }
              filters.push({
                value: filter.id,
                label: filter.name,
                is_active: filter.is_active,
                email_alert: filter.email_alert,
                separate_email_alert: filter.separate_email_alert,
                filters: filter.filters,
                filter_type: filter.filter_type,
                created_at: filter.created_at,
                profile_count: filter.profile_count,
              });
            }
          });
          /** if no default filter then add all filters, only for opportunities */
          if (selectedIdList.length === 0) {
            this.props.globalDataStore.multipleFilters.selectedIdList = [
              { value: -1, label: 'All Prospect Filters' },
              ...this.props.globalDataStore.multipleFilters.allFilterIds,
            ];
            // this.props.globalDataStore.multipleFilters.opporunitySelectedIdList =
            //   this.props.globalDataStore.multipleFilters.selectedIdList;
          } else {
            this.props.globalDataStore.multipleFilters.selectedIdList =
              selectedIdList;
            // this.props.globalDataStore.multipleFilters.opporunitySelectedIdList =
            //   selectedIdList;
          }
        }
        this.props.globalDataStore.multipleFilters.filterList = filters;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    if (
      !this.props.dataStore.currentUser.activePlan &&
      !this.props.dataStore.currentUser.isAdmin
    )
      return (
        <div className="loader-wrapper">
          <LoaderAnimation loaderId="ri-loader" />;
        </div>
      );

    const navList = [...relationshipIntelligence];

    return (
      <div className="relationship-container">
        <>
          <div className="nav-tab-fixed no-print">
            <NavTab navList={navList} />
          </div>
          <div className="nav-tab-dummy" />
        </>

        <div className="relationship-component">
          <div id="right" className="right-col">
            <Outlet />
          </div>
        </div>
      </div>
    );
  }
}

export default withLDConsumer()(
  inject('dataStore')(
    inject('globalDataStore')(observer(RelationshipIntelligence)),
  ),
);
