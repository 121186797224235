import { useState, useEffect } from 'react';
import './index.scss';
import { Collapse, Button, Popconfirm } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import ProfileIcon from '../../assets/icons/ai_avatar.svg?react';

const { Panel } = Collapse;

const ContactsAndIntroduction = (props) => {
  const {
    data,
    theme,
    opportunity_degree,
    openProfileTab,
    showCompanyProfile,
  } = props;
  const [activeKeys, setActiveKeys] = useState([]);

  useEffect(() => {
    if (opportunity_degree === 1) return;
    const keys = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    data &&
      data.length > 0 &&
      data.map((item, index) => {
        if (
          (item.emails && item.emails.length > 0) ||
          (item.phones && item.phones.length > 0)
        )
          keys.push(`key${index}`);
      });
    setActiveKeys(keys);
  }, [data]);

  const renderEmailButton = (emails) => {
    if (emails.length === 1) {
      return (
        <a href={`mailto: ${emails[0]}`}>
          <Button className="collapse-button">Email</Button>
        </a>
      );
    }

    return (
      <Popconfirm
        placement="topRight"
        overlayClassName="opportunities-contact-modal"
        title={emails.map((email) => {
          return (
            <a href={`mailto: ${email}`}>
              <div>{email}</div>
            </a>
          );
        })}
      >
        <Button className="collapse-button">Email</Button>
      </Popconfirm>
    );
  };

  const renderPhoneButton = (phones) => {
    return (
      <Popconfirm
        placement="topRight"
        overlayClassName="opportunities-contact-modal"
        title={phones.map((phone) => {
          return <div>{phone}</div>;
        })}
      >
        <Button className="collapse-button">Call</Button>
      </Popconfirm>
    );
  };

  const header = (listItem) => (
    <div className="collapse-header">
      <div className="collapse-header-top">
        <div style={{ paddingRight: '6px' }}>
          <ProfileIcon
            style={{
              fill:
                theme === 'golden'
                  ? 'var(--color-golden-end)'
                  : 'var(--color-secondary)',
            }}
          />
        </div>

        <div
          className="cursor-pointer"
          //  style={{textDecoration: 'underline'}}
          onClick={(e) => {
            e.stopPropagation();
            openProfileTab(listItem.b2b_id, listItem.b2c_id);
          }}
        >
          {' '}
          {listItem.full_name}
        </div>
      </div>
      <div className="collapse-header-bottom">
        {listItem.title}
        {listItem.title && listItem.org_name ? ' @ ' : ''}
        <span
          className={listItem.ai_org_id ? 'link' : 'link-disabled'}
          onClick={(e) => {
            if (!listItem || !listItem.ai_org_id) return;
            e.stopPropagation();
            showCompanyProfile(listItem.ai_org_id, listItem.org_name);
          }}
        >
          {listItem.org_name}
        </span>
      </div>
    </div>
  );

  return (
    <>
      <div className="oppurtunities-right-component">
        {data && data.length > 0 && (
          <Collapse
            defaultActiveKey={activeKeys}
            activeKey={activeKeys}
            className={theme === 'golden' ? 'gold-bg' : 'light-blue-bg'}
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            bordered={false}
            onChange={(keys) => {
              setActiveKeys(keys);
            }}
          >
            {data.map((listItem, index) => {
              return (
                <Panel
                  showArrow={
                    !!(
                      (listItem.emails && listItem.emails.length > 0) ||
                      (listItem.phones && listItem.phones.length > 0)
                    )
                  }
                  collapsible={
                    (listItem.emails && listItem.emails.length > 0) ||
                    (listItem.phones && listItem.phones.length > 0)
                      ? ''
                      : 'disabled'
                  }
                  header={header(listItem)}
                  key={`key${index}`}
                  className="custom-panel"
                  style={{
                    border:
                      theme === 'golden'
                        ? '1px solid var(--color-golden-end)'
                        : '1px solid var(--color-link)',
                  }}
                  forceRender={
                    !!(
                      opportunity_degree !== 1 &&
                      ((listItem.emails && listItem.emails.length > 0) ||
                        (listItem.phones && listItem.phones.length > 0))
                    )
                  }
                >
                  <div className="collapse-content">
                    {opportunity_degree !== 1 && (
                      <div style={{ paddingBottom: '14px' }}>
                        {'Ask '}
                        <span
                          className="link"
                          onClick={(e) => {
                            e.stopPropagation();
                            openProfileTab(listItem.b2b_id, listItem.b2c_id);
                          }}
                        >
                          {listItem.full_name || ''}
                        </span>
                        {'  for an introduction.'}
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {listItem.phones &&
                        listItem.phones.length > 0 &&
                        renderPhoneButton(listItem.phones)}
                      {listItem.emails &&
                        listItem.emails.length > 0 &&
                        renderEmailButton(listItem.emails)}
                    </div>
                  </div>
                </Panel>
              );
            })}
          </Collapse>
        )}
      </div>
    </>
  );
};

export default ContactsAndIntroduction;
