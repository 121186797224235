import moment from 'moment';
import '@gouch/to-title-case';

const ProfileHistory = ({ historyData }) => {
  const {
    last_crm_export_date = null,
    last_crm_export = null,
    last_csv_export_date = null,
    last_csv_export = null,
    last_added_on_network_date = null,
    last_viewed_date = null,
    user_comment_details = null,
    note_details,
  } = historyData;

  function getDate(date) {
    return moment(date).format('MM/DD/YYYY');
  }

  function renderData(label, value) {
    if (!value) return;
    return (
      <div>
        <div className="profile-labels">{label}</div>
        <div className="profile-value">{getDate(value)}</div>
      </div>
    );
  }

  function renderCommentDetails() {
    if (!user_comment_details || user_comment_details.length <= 0) return;

    return (
      <>
        {user_comment_details.map((commentDetail) => {
          if (commentDetail.user_comment && commentDetail.created) {
            return (
              <>
                <div>
                  <div className="profile-labels">
                    {'Submitted Comment on '}
                    {getDate(commentDetail.created)}
                  </div>
                  <div className="profile-value">
                    {commentDetail.user_comment}
                  </div>
                </div>
                {commentDetail.admin_status &&
                  commentDetail.admin_status_time && (
                    <div>
                      <div className="profile-labels">
                        {'Aidentified responded on '}
                        {getDate(commentDetail.admin_status_time)}
                      </div>
                      <div className="profile-value">
                        {commentDetail.admin_status}
                      </div>
                    </div>
                  )}
              </>
            );
          }
        })}
      </>
    );
  }

  return (
    <div className="profile-history-section">
      <div
        className="profile-box-header profile-titles"
        style={{ borderBottom: '1px solid var(--color-horizontal-line)' }}
      >
        Profile History
      </div>
      <div className="history-content">
        {renderData('Last viewed on', last_viewed_date)}
        {note_details &&
          renderData(
            note_details.created.substring(
              0,
              note_details.created.lastIndexOf('.'),
            ) ==
              note_details.updated.substring(
                0,
                note_details.updated.lastIndexOf('.'),
              )
              ? 'Notes added on '
              : 'Notes updated on ',
            note_details.updated,
          )}
        {last_crm_export &&
          renderData(
            `Exported to ${last_crm_export.toString().toTitleCase()} on`,
            last_crm_export_date,
          )}
        {last_csv_export &&
          renderData(
            `Exported to CSV ${last_csv_export} on`,
            last_csv_export_date,
          )}
        {renderData('Added to Network on', last_added_on_network_date)}
        {renderCommentDetails()}
      </div>
    </div>
  );
};

export default ProfileHistory;
