import './index.scss';

import { Tooltip } from 'antd';
import moment from 'moment';

import VerifyIcon from '../../assets/icons/ai_ic_logo_gold.svg?react';
import UnVerifiedIcon from '../../assets/icons/ai_ic_unverified.svg?react';

function getDate(date) {
  return moment(date).format('MM/DD/YYYY');
}

function getText(title) {
  switch (title) {
    case 'Email':
      return 'email';
    case 'Phone':
      return 'phone number';
    default:
      return 'data';
  }
}

function Verified({
  verifiedDate,
  source = null,
  isVerified = true,
  title = '',
}) {
  // if (source) {
  //   /** Verified near email and phone */
  //   return (
  //     <Tooltip
  //       placement="right"
  //       color="linear-gradient(180deg, #FFFFFF 0%, #F4FAFF 100%)"
  //       overlayInnerStyle={{
  //         color: 'var(--color-black)',
  //         padding: '15px 20px',
  //         boxShadow: '0 3px 6px 0 rgba(0,0,0,0.3)',
  //       }}
  //       title={() => {
  //         return (
  //           <div className="verified-icon">
  //             Last verified with a 3rd party provider on {getDate(verifiedDate)}
  //           </div>
  //         );
  //       }}
  //     >
  //       {<VerifyIcon height={15} width={15} />}
  //     </Tooltip>
  //   );
  // }
  return (
    <Tooltip
      placement="right"
      color="linear-gradient(180deg, #FFFFFF 0%, #F4FAFF 100%)"
      overlayInnerStyle={{
        color: 'var(--color-black)',
        padding: '10px 20px',
        boxShadow: '0 3px 6px 0 rgba(0,0,0,0.3)',
      }}
      title={() => {
        return (
          <div className="verified-icon">
            <>
              {isVerified ? (
                <>
                  <div className="verified-title">Verified {title}</div>
                  This {getText(title)} has been verified across multiple data
                  sources on {getDate(verifiedDate)}
                </>
              ) : (
                <>
                  <div className="unverified-title">Unverified {title}</div>
                  This {getText(title)} could not be verified across multiple
                  data sources.
                </>
              )}
            </>
          </div>
        );
      }}
    >
      {isVerified ? (
        <VerifyIcon height={15} width={15} />
      ) : (
        <UnVerifiedIcon height={15} width={15} />
      )}
    </Tooltip>
  );
}

export default Verified;
