import './index.scss';
import { useNavigate } from 'react-router';
import NoDataIcon from '../../assets/icons/ai_opp_no_data.svg?react';

const NoOpportunityData = (props) => {
  const { page } = props;
  const navigate = useNavigate();
  if (page === 'Opportunities')
    return (
      <div className="no-data-wrapper">
        {/* <div className="no-data-image"> */}
        {/* <NoDataIcon className="no-data" /> */}
        <div className="no-data-text">
          <div className="filter">
            <div className="top-section">
              {/* <div style={{ fontSize: '25px', fontWeight: '300' }}>
                No results for now.
              </div> */}
              <div style={{ paddingTop: '7px', fontSize: '14px' }}>
                There are currently no {page} <br /> matching your Saved
                Searches
              </div>
            </div>
            <div className="bottom-section">
              <div style={{ textAlign: 'center' }}>
                You can modify or create new Saved Searches <br /> to expand
                Trigger criteria
                <div
                  className="go-to-filter"
                  onClick={() => {
                    navigate('/savedsearches');
                  }}
                >
                  Go to Saved Searches
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    );

  return (
    <div className="no-data-wrapper">
      <div className="no-data-image">
        <NoDataIcon className="no-data" />
        <div className="no-data-text">
          <div className="filter">
            <div className="top-section">
              <div style={{ fontSize: '25px', fontWeight: '300' }}>
                No results for now.
              </div>
              <div style={{ paddingTop: '7px', fontSize: '14px' }}>
                We are monitoring {page}.
              </div>
            </div>
            <div className="bottom-section">
              <div style={{ textAlign: 'center' }}>
                To increase the number of results, please modify/create your
                Prospect Filters.
                <div
                  className="go-to-filter"
                  onClick={() => {
                    navigate('/savedsearches');
                  }}
                >
                  Go to Saved Searches
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoOpportunityData;
