import './index.scss';
import { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { Input, message, Collapse, Radio, Checkbox } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';

import { Tooltip } from 'antd';
import { requestWithAuth } from '../../services/api';
import FolderIcon from '../../assets/icons/ai_folder_plus.svg?react';
import ArrowIcon from '../../assets/icons/ai_arrow.svg?react';
import OpenFolderIcon from '../../assets/icons/ai_folder_minus.svg?react';
import DeleteIcon from '../../assets/icons/ai_delete.svg?react';
import TransferIcon from '../../assets/icons/ai_icn_move_folder.svg?react';
import UnSuppressedIcon from '../../assets/icons/ai_ic_unsuppress.svg?react';
import SuppressedPNGIcon from '../../assets/icons/ai_ic_suppress.png';

import LoaderAnimation from '../AidLoader';
import CreateTagElement from './CreateTagElement';

const CollapsibleFolder = (props) => {
  const {
    id,
    initialValue,
    isSuppress,
    handleInputChange,
    onSuppressChange,
    handleDeleteFolder,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const tooltipRef = useRef(null);
  const inputErrorRef = useRef(null);
  const [deletionProgress, setDeletionProgress] = useState(false);
  const [showUndoDelete, setShowUndoDelete] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  let timer;

  useEffect(() => {
    setInputValue(initialValue || '');
  }, [initialValue]);

  useEffect(() => {
    timer = setTimeout(() => {
      if (showUndoDelete) {
        handleDeleteFolder(id);
        setShowUndoDelete(false);
      }
    }, 10000);
    return () => {
      clearTimeout(timer);
    };
  }, [showUndoDelete]);

  const handleDeleteClick = () => {
    setDeletionProgress(true);
    setTimeout(() => {
      setDeletionProgress(false);
      setShowUndoDelete(true);
    }, 3000);
  };

  const onUndoClick = () => {
    setShowUndoDelete(false);
  };

  const updateInputValue = (value) => {
    setInputValue(value);
  };

  const folderErrorTimeout = () => {
    setTimeout(() => {
      setShowWarning(false);
      // setInputValue(initialValue);
    }, 3000);
  };

  function myStopFunction() {
    clearTimeout(folderErrorTimeout);
  }

  return (
    <>
      {showUndoDelete ? (
        <div className="undo-delete-prompt">
          Deleted {initialValue} folder.
          <span className="undo-text" onClick={onUndoClick}>
            Undo
          </span>
        </div>
      ) : (
        <div className="collapse-component">
          <div
            className={
              deletionProgress
                ? 'collapse-item-grid collapse-parent-folder aid-div-disable'
                : 'collapse-item-grid collapse-parent-folder aid-div-enable'
            }
          >
            <div>
              {isSuppress ? (
                <img
                  src={SuppressedPNGIcon}
                  id="suppress-icon-in-open-folder"
                  className="suppressed-folder"
                />
              ) : (
                <></>
              )}
            </div>
            <div
              style={{ position: 'relative' }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.stopPropagation();
                  event.preventDefault();
                }
              }}
            >
              <Input
                id={`folder-${id}`}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={(e) => {
                  if (e.target.value !== initialValue) {
                    setShowWarning(true);
                    // const folderInput = document.getElementById(`folder-${id}`);
                    // if (folderInput) folderInput.focus();
                    folderErrorTimeout();
                  }
                }}
                onPressEnter={(event) => {
                  // event.stopPropagation();
                  // event.preventDefault();
                  const folderInput = document.getElementById(`folder-${id}`);
                  if (folderInput) folderInput.blur();
                  myStopFunction();
                  setShowWarning(false);
                  handleInputChange(
                    initialValue,
                    event.target.value,
                    id,
                    inputErrorRef,
                    updateInputValue,
                  );
                }}
                className="folder-name"
              />
              <div
                ref={inputErrorRef}
                className="input-error-ref input-error"
                style={{ visibility: 'hidden' }}
              >
                <CloseCircleFilled />
                Tag folder name already exists
              </div>
              {showWarning && (
                <div className="input-warning">Press Enter to update name!</div>
              )}
            </div>
            <div style={{ margin: 'auto' }}>
              <Tooltip
                placement={'right'}
                trigger={'hover'}
                arrow={false}
                color={'rgba(0,0,0,0.75)'}
                overlayClassName="delete-popup"
                title={
                  <div
                    onClick={() => {
                      handleDeleteClick();
                    }}
                    style={{
                      display: 'flex',
                      fontSize: 11,
                      gap: '6px',
                      alignItems: 'center',
                    }}
                  >
                    <DeleteIcon height={16} width={16} />
                    <span style={{ marginTop: 1 }}>Delete folder? </span>
                  </div>
                }
              >
                <div className="delete-icon">
                  <DeleteIcon />
                </div>
              </Tooltip>
            </div>
            {typeof isSuppress == 'boolean' && (
              <div style={{ margin: 'auto' }}>
                <Tooltip
                  placement={'right'}
                  trigger={'hover'}
                  arrow={false}
                  color={'rgba(0,0,0,0.75)'}
                  overlayClassName="suppress-popup"
                  title={
                    <div
                      onClick={() => {
                        onSuppressChange(!isSuppress, initialValue, id);
                      }}
                      style={{
                        display: 'flex',
                        fontSize: 11,
                        gap: '6px',
                        alignItems: 'center',
                      }}
                    >
                      {isSuppress ? (
                        <UnSuppressedIcon id="unsuppress-icon-new" />
                      ) : (
                        <img src={SuppressedPNGIcon} id="suppress-icon" />
                      )}
                      <span style={{ marginTop: 1 }}>
                        {isSuppress
                          ? ' Unsuppress folder?'
                          : ' Suppress folder?'}{' '}
                      </span>
                    </div>
                  }
                >
                  {isSuppress ? (
                    <img src={SuppressedPNGIcon} id="unsuppress-icon-new" />
                  ) : (
                    <img src={SuppressedPNGIcon} id="suppress-icon" />
                  )}
                </Tooltip>

                <div ref={tooltipRef}> </div>
              </div>
            )}
          </div>
          {deletionProgress && (
            <div className="loader">
              <div>Deleting folder</div>
              <LoaderAnimation
                size="small"
                loaderId="delete-tag-folder-loader"
                style={{ position: 'unset' }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const CollapseChild = (props) => {
  const { id, initialValue, handleInputChange, onDelete, onTransferClick } =
    props;

  const [inputValue, setInputValue] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const inputErrorRef = useRef(null);

  useEffect(() => {
    setInputValue(initialValue || '');
  }, [initialValue]);

  const updateInputValue = (value) => {
    setInputValue(value);
  };

  const TagErrorTimeout = () => {
    setTimeout(() => {
      setShowWarning(false);
      // setInputValue(initialValue);
    }, 3000);
  };

  function myStopFunction() {
    clearTimeout(TagErrorTimeout);
  }

  return (
    <div className="collapse-item-grid collapse-child">
      <div></div>
      <div style={{ position: 'relative' }}>
        <Input
          id={`child-${id}`}
          value={inputValue}
          prefix={
            <>
              <Tooltip
                placement={'left'}
                trigger={'hover'}
                arrow={false}
                overlayClassName="move-popup"
                color={'rgba(0,0,0,0.75)'}
                title={
                  <div
                    className="transfer-tooltip"
                    onClick={() => {
                      onTransferClick();
                    }}
                  >
                    {' '}
                    <div>Move tag? </div> <TransferIcon />
                  </div>
                }
              >
                <div className="transfer-icon">
                  <TransferIcon />
                </div>
              </Tooltip>
            </>
          }
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={(e) => {
            if (e.target.value !== initialValue) {
              setShowWarning(true);
              // const childInput = document.getElementById(`child-${id}`);
              // if (childInput) childInput.focus();
              TagErrorTimeout();
            }
          }}
          onPressEnter={(event) => {
            const childInput = document.getElementById(`child-${id}`);
            if (childInput) childInput.blur();
            myStopFunction();
            setShowWarning(false);
            handleInputChange(
              initialValue,
              event.target.value,
              id,
              inputErrorRef,
              updateInputValue,
            );
          }}
        />
        <div
          ref={inputErrorRef}
          className="input-error-ref input-error"
          style={{ visibility: 'hidden' }}
        >
          <CloseCircleFilled />
          Tag name already exists
        </div>
        {showWarning && (
          <div
            className="input-warning"
            style={{ paddingTop: '0px', left: '150px' }}
          >
            Press Enter to update name!
          </div>
        )}
      </div>

      <div style={{ margin: 'auto' }}>
        <Tooltip
          placement={'right'}
          trigger={'hover'}
          arrow={false}
          color={'rgba(0,0,0,0.75)'}
          overlayClassName="delete-popup"
          title={
            <div
              onClick={() => {
                onDelete();
              }}
              style={{
                display: 'flex',
                fontSize: 11,
                gap: '6px',
                alignItems: 'center',
              }}
            >
              <DeleteIcon height={16} width={16} />
              <span style={{ marginTop: 1 }}>Delete Tag? </span>
            </div>
          }
        >
          <div className="delete-icon">
            <DeleteIcon />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

const TransferUi = (props) => {
  const {
    transferTag,
    sourceFolder,
    folderData,
    closeTransferView,
    updateAfterTransfer,
  } = props;
  const [targetFolder, setTargetFolder] = useState(folderData[0]);
  const [showSuppressionWarning, setShowSuppressionWarning] = useState(false);
  const [transferProgress, setTransferProgress] = useState(false);
  const [isTransferClick, setIsTransferClick] = useState(false);
  const [showArrow, setShowArrow] = useState(true);
  const [hoveredFolder, setHoveredFolder] = useState('');

  useEffect(() => {
    document.addEventListener('keypress', (event) => {
      if (event.key === 'Enter') {
        transferToFolder(transferTag, targetFolder.id);
      }
    });
  });

  const transferToFolder = (tag, targetId) => {
    if (!tag || !targetId) return;
    setTransferProgress(true);
    const body = {
      tag_ids: [tag.id],
      tag_folder_id: targetId,
    };
    requestWithAuth(
      '/tag-folder/attach-tag-to-folder',
      'POST',
      null,
      body,
      null,
    ).then((response) => {
      setIsTransferClick(false);
      setShowSuppressionWarning(false);
      if (response && response.code === 200) {
        updateAfterTransfer();
        setTransferProgress(false);
        closeTransferView();
      } else {
        setTransferProgress(false);
      }
    });
  };

  return (
    <div className="transfer-ui-wrapper">
      <div className="header">
        Move tag <span className="bold-text">{transferTag.tag}</span> from
        folder
        {hoveredFolder ? <span> to {hoveredFolder}</span> : ''}
      </div>
      <div
        className="transfer-content scroll-container"
        style={{ pointerEvents: transferProgress ? 'none' : 'unset' }}
      >
        <div className="source-folder">
          <OpenFolderIcon className="folder-icon" />
          <div className="source-folder-text">
            <span style={{ width: '70%', overflow: 'ellipsis' }}>
              {sourceFolder.tag_folder_name}
            </span>
          </div>
        </div>
        <div
          className="target-selection"
          onMouseEnter={(e) => setShowArrow(false)}
          onMouseLeave={() => setShowArrow(true)}
        >
          <Radio.Group
            value={targetFolder}
            onChange={(e) => {
              setTargetFolder({ ...e.target.value });
            }}
          >
            {folderData &&
              folderData.map((folder) => {
                return (
                  <div
                    className={
                      folder.id === targetFolder.id &&
                      (isTransferClick || showSuppressionWarning)
                        ? 'selected-target'
                        : ''
                    }
                    onMouseLeave={() => setShowSuppressionWarning(false)}
                  >
                    {showSuppressionWarning &&
                      folder.id === targetFolder.id && (
                        <div
                          className="suppression-warning"
                          onClick={(e) => {
                            if (e.screenX == 0 && e.screenY == 0) {
                              /**disable triggering 'click' on arrow-key press event */
                              return;
                            } else {
                              e.stopPropagation();
                              setShowSuppressionWarning(false);
                              setIsTransferClick(true);
                              transferToFolder(transferTag, folder.id);
                            }
                          }}
                        >
                          <div>
                            This folder is under suppression
                            {/* <SuppressedIcon style={{ width: '10px' }} /> */}{' '}
                            <img src={SuppressedPNGIcon} id="suppress-icon" />
                          </div>
                          <div>
                            {' '}
                            Move this tag into a suppressed folder?
                            <TransferIcon />
                          </div>
                        </div>
                      )}
                    <Radio.Button
                      value={folder}
                      style={{
                        filter:
                          transferProgress && targetFolder.id !== folder.id
                            ? 'opacity(0.2)'
                            : 'opacity(1)',
                      }}
                      onClick={(e) => {
                        if (folder.is_suppression_tag)
                          setShowSuppressionWarning(true);
                        else {
                          if (e.screenX == 0 && e.screenY == 0) {
                            /**disable triggering 'click' on arrow-key press event */
                            return;
                          } else {
                            setIsTransferClick(true);
                            transferToFolder(transferTag, folder.id);
                          }
                        }
                      }}
                    >
                      <div
                        id={folder.tag_folder_name}
                        className="target-component"
                        style={{
                          left: transferProgress ? '-108px' : '0px',
                        }}
                        onMouseEnter={(e) => setHoveredFolder(e.target.id)}
                      >
                        {targetFolder.id === folder.id && (
                          <>
                            {transferProgress && (
                              <>
                                <LoaderAnimation
                                  style={{ position: 'unset' }}
                                  size="small"
                                  loaderId="tag-transfer"
                                />
                                <div className="spin-text">Moving tag</div>
                              </>
                            )}
                            {showArrow && !isTransferClick && (
                              <ArrowIcon className="transfer-arrow" />
                            )}
                          </>
                        )}
                        <ArrowIcon className="transfer-arrow-hover" />
                      </div>
                      {folder.tag_folder_name === hoveredFolder ? (
                        <>
                          <OpenFolderIcon className="folder-icon" />
                          {folder.is_suppression_tag && (
                            <img
                              src={SuppressedPNGIcon}
                              id="suppress-icon-in-open-folder"
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <FolderIcon className="folder-icon" />
                          {folder.is_suppression_tag && (
                            <img
                              src={SuppressedPNGIcon}
                              id="suppress-icon-in-folder"
                            />
                          )}
                        </>
                      )}
                      <div
                        style={{
                          marginTop: '6px',
                          marginLeft: folder.is_suppression_tag
                            ? '16px'
                            : '5px',
                        }}
                      >
                        {folder.tag_folder_name}(
                        {folder.tag_folder_members?.length || 0})
                      </div>
                    </Radio.Button>
                  </div>
                );
              })}
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};

const FolderEditView = inject('dataStore')(
  observer((props) => {
    const { fetchUserTags, fetchTagFolders, from, folderId } = props;

    const { userTagFolders } = props.dataStore.lookUpLists;
    const [transformedData, setTransformedData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showTransfer, setShowTransfer] = useState(false);
    const [transferTag, setTransferTag] = useState({ tag: '', id: '' });
    const [transferFolder, setTransferFolder] = useState({});
    const [allPanelKeys, setAllPanelKeys] = useState([]);
    const [activePanels, setActivePanels] = useState(
      folderId ? [folderId] : [],
    );
    useEffect(() => {
      transformData();
    }, [userTagFolders]);

    useEffect(() => {
      const scrollBody = document.querySelector('.collapse-container');
      const footer = document.querySelector('.folder-edit-footer');
      scrollBody.onscroll = (e) => {
        const target = e.target;
        if (from === !'savedSearches') {
          if (
            footer &&
            target &&
            target.scrollTop + target.clientHeight + 5 >= target.scrollHeight
          )
            footer.style.boxShadow = 'none';
          else {
            footer.style.boxShadow = '0px -20px 8px 0px #ffffff';
          }
        }
      };
    }, []);

    const fetchUpdatedData = () => {
      fetchTagFolders();
      fetchUserTags();
    };

    const handleDeleteFolder = (folderId) => {
      if (!folderId) return;
      const body = { tag_folder_ids: [folderId] };
      requestWithAuth(
        'tag-folder/delete-multiple',
        'POST',
        null,
        body,
        null,
      ).then((response) => {
        if (response && response.code === 200) {
          fetchTagFolders();
          fetchUserTags();
        } else {
          message.error('Something went wrong! Folder is not deleted');
        }
      });
    };

    const handleSuppress = (suppress, folderName, folderID) => {
      setIsLoading(true);
      const body = {
        tag_folder_name: folderName,
        is_suppression_tag: suppress,
      };
      requestWithAuth(`tag-folder/${folderID}`, 'PUT', null, body, null).then(
        (response) => {
          if (response && response.code === 200) {
            fetchTagFolders();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            return;
          }
        },
      );
    };

    const handleDeleteTags = (tagId) => {
      if (!tagId) {
        message.error('Please select tag to remove', 10);
        return;
      }
      setIsLoading(true);
      const body = {};
      body.tag_ids = [tagId];
      requestWithAuth('/tags/delete-multiple', 'POST', null, body, null).then(
        (response) => {
          if (response && response.code === 200) {
            fetchTagFolders();
            return;
          }
          return;
        },
      );
      setIsLoading(false);
    };

    const handleEditFolder = (
      currentName,
      newName,
      id,
      errorRef,
      updateInputValue,
    ) => {
      if (!currentName || !newName || currentName === newName) return;

      setProgress(true);
      const body = {};
      body.tag_folder_name = newName;
      requestWithAuth(`/tag-folder/${id}`, 'PUT', null, body, null).then(
        (response) => {
          if (response && response.code === 200) {
            setTimeout(() => {
              setProgress(false);
              fetchTagFolders();
            }, 3000);
          } else if (
            response &&
            response.response &&
            response.response.data &&
            response.response.data.detail &&
            response.response.data.detail ===
              'Tag Folder name already exists' &&
            errorRef &&
            errorRef.current
          ) {
            // setIsLoading(false);
            errorRef.current.style.visibility = 'visible';
            updateInputValue(currentName);
            setTimeout(() => {
              errorRef.current.style.visibility = 'hidden';
            }, 3000);
          }
          return;
        },
      );
      // setIsLoading(false);
    };

    async function handleEditTag(
      currentName,
      newName,
      Id,
      errorRef,
      updateInputValue,
    ) {
      if (!currentName || !newName || !Id || currentName === newName) return;
      setIsLoading(true);
      const body = {};
      body.tag = newName;
      requestWithAuth(`tags/${Id}`, 'PUT', null, body, null).then(
        (response) => {
          setIsLoading(false);
          if (response && response.code === 200) {
            fetchTagFolders();
          } else if (
            response &&
            response.response &&
            response.response.data &&
            response.response.data.detail &&
            response.response.data.detail === 'Tag name already exists' &&
            errorRef &&
            errorRef.current
          ) {
            errorRef.current.style.visibility = 'visible';
            updateInputValue(currentName);
            setTimeout(() => {
              errorRef.current.style.visibility = 'hidden';
            }, 3000);
          }
          return;
        },
      );
    }

    const toggleActivePanels = (e) => {
      if (e.target.checked) {
        setActivePanels([...allPanelKeys]);
      } else {
        setActivePanels([]);
      }
    };
    const renderChildren = (folder) => {
      if (
        !folder ||
        !folder.tag_folder_members ||
        folder.tag_folder_members.length === 0
      )
        return <></>;
      return (
        <div className="collapse-component">
          {folder.tag_folder_members.map((member) => {
            return (
              <CollapseChild
                id={member.tag.id}
                initialValue={member.tag.tag}
                handleInputChange={handleEditTag}
                onDelete={() => handleDeleteTags(member.tag.id)}
                onTransferClick={() => {
                  setTransferFolder({ ...folder });
                  setTransferTag({ tag: member.tag.tag, id: member.tag.id });
                  setShowTransfer(true);
                }}
              />
            );
          })}
        </div>
      );
    };

    const transformData = () => {
      let folderData = [];
      const panelKeys = [];
      folderData = userTagFolders.tag_folders.map((folder, folderIndex) => {
        panelKeys.push(folder.id);
        return {
          key: folder.id,
          label: (
            <CollapsibleFolder
              id={folder.id}
              initialValue={folder.tag_folder_name}
              isSuppress={folder.is_suppression_tag}
              handleInputChange={handleEditFolder}
              onSuppressChange={handleSuppress}
              handleDeleteFolder={handleDeleteFolder}
            />
          ),
          children: renderChildren(folder),
        };
      });
      setTransformedData([...folderData]);
      setAllPanelKeys([...panelKeys]);
    };

    const setProgress = (value) => {
      setIsLoading(value);
    };

    if (!transformedData) return <></>;
    return (
      <div className="folder-edit-view-wrapper">
        {!showTransfer && (
          <>
            <div className="title">Editing folders & content</div>
            <div className="description">
              Edit your folders, apply suppression to an entire folder to
              suppress all tags in it, add/delete new folders or move tags in
              your folders.
            </div>
          </>
        )}

        <div className="loader-wrapper">
          {isLoading && (
            <LoaderAnimation
              loaderId="folder-edit-loader"
              size="medium"
              style={{ top: '130px' }}
            />
          )}
          <div
            className={
              isLoading
                ? 'edit-container aid-div-disable'
                : 'edit-container aid-div-enable'
            }
          >
            {showTransfer ? (
              <TransferUi
                folderData={userTagFolders.tag_folders.filter(
                  (folder) => folder.id !== transferFolder.id,
                )}
                sourceFolder={transferFolder}
                transferTag={transferTag}
                updateAfterTransfer={fetchUpdatedData}
                closeTransferView={() => {
                  setShowTransfer(false);
                }}
              />
            ) : (
              <>
                <div className="folder-edit-header">
                  <div>
                    {`Folders (${transformedData.length || 0})`}
                    <Checkbox
                      className="open-all-text"
                      onClick={(e) => toggleActivePanels(e)}
                    >
                      {' '}
                      Open/close all
                    </Checkbox>
                  </div>
                  <div
                    className="collapse-item-grid"
                    style={{ marginTop: '7px', marginLeft: '4px' }}
                  >
                    <div></div>
                    <CreateTagElement
                      isFolder={true}
                      onCreation={fetchTagFolders}
                      setProgress={setProgress}
                    />
                    <div className="sub-header-text">Delete</div>
                    <div className="sub-header-text">Suppress</div>
                  </div>
                </div>
                <div className="collapse-container">
                  <Collapse
                    items={transformedData}
                    bordered={false}
                    collapsible="icon"
                    expandIcon={({ isActive }) =>
                      isActive ? <OpenFolderIcon /> : <FolderIcon />
                    }
                    activeKey={activePanels}
                    onChange={setActivePanels}
                  />
                </div>
                <div className="folder-edit-footer">
                  Deleting a folder will detach tags contained within the folder
                  but will not delete tags
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }),
);

export default FolderEditView;
