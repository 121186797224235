import './index.scss';

import { useState, useEffect, useRef } from 'react';
import { Button, Tooltip, message, Modal } from 'antd';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { LinkedinFilled, FacebookFilled } from '@ant-design/icons';

import Fire from '../../assets/icons/ai_fire.svg?react';
import TwitterIcon from '../../assets/icons/ai_x_logo.svg?react';
import {
  handleCrmIconsAfterIntegration,
  handleCrmLabels,
  handleCrmSync,
} from '../../utils/crmMethods';
import { showConfirm } from '../../utils/popup';
import Tags from '../Tags';
import SelectClubs from '../SelectClubs';

message.config({
  top: 80,
});

const HeaderSection = inject('dataStore')(
  observer((props) => {
    const {
      data,
      index,
      getDegreeFormats,
      user,
      toggleProgress,
      showProfile,
      showCompanyProfile,
      from,
    } = props;

    const navigate = useNavigate();
    const [leadIds, setLeadIds] = useState({});
    const [crmOk, setCrmOk] = useState(false);
    const [clubCode, setClubCode] = useState();
    const { confirm } = Modal;
    const modalRef = useRef(null);

    useEffect(() => {
      if (crmOk) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        props.dataStore.currentUser.creditCount >= 1 ||
        props.dataStore.currentUser.isAdmin
          ? handleCrmPopupOk(clubCode)
          : handleSubscribeRedirect();
      }
    }, [crmOk]);

    const exportPopUp = () => {
      return showConfirm({
        className: 'small-popup',
        closable: true,
        onOk: () => {
          setCrmOk(true);
        },
        title: 'Confirm Export',
        content: (
          <div style={{ textAlign: 'center' }}>
            You selected 1 profile to export
            <br />
            {props.dataStore.currentUser.creditCount >= 1 ||
            props.dataStore.currentUser.isAdmin
              ? 'Please confirm'
              : 'You require an additional 1 credit to proceed'}
          </div>
        ),
        okText:
          props.dataStore.currentUser.creditCount >= 1 ||
          props.dataStore.currentUser.isAdmin
            ? 'Yes'
            : 'Buy More',
      });
    };

    const clubModel = () => {
      modalRef.current = confirm({
        icon: null,
        okButtonProps: {
          disabled: true,
        },
        onOk() {
          exportPopUp();
          modalRef.current.destroy();
        },
        onCancel() {
          setClubCode();
          if (modalRef && modalRef.current) modalRef.current.destroy();
        },
        className: 'club-popUp',
        title: 'Select Club/Location',
        content: (
          <SelectClubs
            setClubVal={(val) => setClubCode(val)}
            modalRef={modalRef}
          />
        ),
      });
    };

    // Method to handle show popup
    const onCrmClick = (b2b_id, b2c_id) => {
      if (!user.crm) {
        navigate('/connect/export');
        return;
      }
      setLeadIds({ b2b_id, b2c_id });

      if (user.crm === 'sf' && props.dataStore.currentUser.org_name === 'INV')
        clubModel();
      else exportPopUp();
    };

    // Method to handle yes click of export csv
    const handleCrmPopupOk = (clubCode) => {
      toggleProgress(true);
      const body = {};
      const person = [];
      if (leadIds && leadIds.b2b_id) person.push(leadIds.b2b_id);
      if (leadIds && leadIds.b2c_id) person.push(leadIds.b2c_id);
      body.n2_person = person;

      if (props.dataStore.currentUser.org_name)
        body.org_name = props.dataStore.currentUser.org_name;

      if (clubCode) body.club_code = clubCode.value;

      handleCrmSync(
        props.dataStore.currentUser.ownerId,
        user.crm,
        body,
        onCrmTokenExpiry,
        'progress_opportunities',
        props.dataStore.networkFilters,
      ).then(() => {
        // if (!props.dataStore.currentUser.isAdmin) fetchUserTypeData();
        setLeadIds({});
        toggleProgress(false);
      });
    };

    const onCrmTokenExpiry = (url) => {
      navigate('/connect/export', {
        state: {
          crmSource: url === 'redtail-auth/0' ? 'rt' : 'cm',
          response: url === 'redtail-auth/0' ? 'rt' : 'cm',
        },
      });
    };

    const handleSubscribeRedirect = () => {
      navigate('/account/credits');
    };

    const getSocialHandle = (type) => {
      const socialHandle = data.social_handles;
      const socialHandlesView = [];
      if (socialHandle && socialHandle.length) {
        socialHandle.map((handle) => {
          if (handle.network === type && handle.url) {
            const social_url =
              handle.url.indexOf('://') === -1
                ? `https://${handle.url}`
                : handle.url;
            socialHandlesView.push(
              <Tooltip
                placement="bottomRight"
                color="var(--color-primary)"
                title={handle.url}
              >
                <a
                  href={social_url}
                  style={{
                    color: 'var(--color-link)',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon">
                    {type === 'facebook' ? (
                      <FacebookFilled />
                    ) : type === 'linkedin' ? (
                      <LinkedinFilled />
                    ) : (
                      <TwitterIcon className="twitter-icon" />
                    )}{' '}
                  </div>
                </a>
              </Tooltip>,
            );
          }
        });

        if (socialHandlesView.length > 0) {
          return <div style={{ display: 'flex' }}>{socialHandlesView}</div>;
        }
      }
    };

    return (
      <>
        <div className="header-section-parent">
          <div>
            <div className="header-section">
              <span
                className="title cursor-pointer"
                onClick={() => {
                  showProfile(data.b2b_id, data.b2c_id);
                }}
              >
                {data.full_name || ''}
              </span>
              <div style={{ display: 'flex' }}>
                <div className={getDegreeFormats(data.degree).class} />{' '}
                <span style={{ color: 'var(--color-dark-shade)' }}>
                  {getDegreeFormats(data.degree).label}
                </span>
              </div>

              <Tags
                fromRow
                selectedProfileIds={[
                  {
                    b2b_id: data.b2b_id,
                    b2c_id: data.b2c_id || '',
                  },
                ]}
              />

              <img
                src={handleCrmIconsAfterIntegration(user.crm)}
                alt={handleCrmLabels(user.crm)}
                width={28}
                height={20}
                onClick={(e) => {
                  e.stopPropagation();
                  onCrmClick(data.b2b_id, data.b2c_id);
                }}
                className="cursor-pointer"
              />

              <Button icon={<Fire />} className="hot-lead-button">
                {' '}
                HOT LEAD
              </Button>
            </div>
            <div className="sub-header-section">
              {data.title ? data.title.toString().toTitleCase() : ''}
              {data.org_name && (
                <>
                  {data.title ? ' @ ' : ''}
                  <span
                    className={data.ai_org_id ? 'link' : 'link-disabled'}
                    onClick={() => {
                      if (!data || !data.ai_org_id) return;
                      showCompanyProfile(data.ai_org_id, data.org_name);
                    }}
                  >
                    {data.org_name.toString().toTitleCase()}
                  </span>{' '}
                </>
              )}
              {(data.location_city || data.location_state) && (
                <>
                  <span>{`${data.title || data.org_name ? ' | ' : ''}`}</span>
                  {data.location_city ? data.location_city : ''}
                  <span>{`${
                    data.location_city && data.location_state ? ', ' : ''
                  }`}</span>
                  {data.location_state ? data.location_state : ''}
                </>
              )}
              {/* <ClockCircleOutlined /> 5:26 pm local time */}
            </div>
          </div>
        </div>
      </>
    );
  }),
);

export default HeaderSection;
