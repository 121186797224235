import './index.scss';

import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Select, Checkbox, Tooltip, Tag, Switch } from 'antd';
import { PlayCircleFilled } from '@ant-design/icons';
import cloneDeep from 'lodash/cloneDeep';

import HelpIcon from '../../assets/icons/help-icon.svg?react';
import MenuFoldIcon from '../../assets/icons/ai_menufold.svg?react';

import AppliedFilter from '../../components/AppliedFilter';
import NewAdvancedFilter from '../../components/NewAdvancedFilter';
import GroupByDropDown from '../../components/GroupByDropDown';
import MultiFilterSelect from '../../components/MultiFilterSelect';
import TagsFolder from '../../components/TagsFolder';
import AiTooltip from '../../components/AiTooltip';

import { importSource } from '../../utils/constants';
import { copyUserFilter } from '../../utils/copyUserFilter';
import { getTeamFileLookup } from '../../utils/general';

const { CheckableTag } = Tag;
const CheckboxGroup = Checkbox.Group;

const ProspectFinder = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    // const fixedContainerRef = useRef();
    // const [extraHeight, setExtraHeight] = useState('181px');
    // useEffect(() => {
    //   setExtraHeight(fixedContainerRef.current.offsetHeight + 'px');
    // });
    const [isMenufold, setIsMenufold] = useState(false);
    // let [selectAll, setSelectAll] = useState(true);
    const flags = useFlags();
    const [showSavedFilter, setShowSavedFilter] = useState(false);

    useEffect(() => {
      props.dataStore.lookUpLists.isProspectFinder = true;
      if (localStorage.getItem('tempNetworkFilters')) {
        props.dataStore.networkFilters = JSON.parse(
          localStorage.getItem('tempNetworkFilters'),
        );
        localStorage.removeItem('tempNetworkFilters');
        localStorage.removeItem('redirectionPath');
      }
      return () => {
        props.dataStore.resetNetworkFilters();
        props.dataStore.quick_search = '';
        props.dataStore.resetMappedFilters();
        props.dataStore.lookUpLists.isProspectFinder = false;
        props.dataStore.importSourceKeys.isImportSourceChecked = false;
        props.dataStore.importSourceKeys.isImportSourceSaved = false;
        props.dataStore.superConnectorFilter = null;
      };
    }, []);

    useEffect(() => {
      /** When clicked in the dropdown of Groupby - Relationship Path[top_team_members] */
      if (props.dataStore.lookUpLists.isRelationPathSelected) {
        // if already relmap org is applied, then choosing this -> filter should be all,
        // else for all other cases -> filter should be second degree
        if (
          props.dataStore.networkFilters.relmap_org &&
          props.dataStore.networkFilters.relmap_org.length > 0
        ) {
          handleDegreeChange('all');
        } else {
          handleDegreeChange('second_degree');
        }
      }
    }, [props.dataStore.lookUpLists.isRelationPathSelected]);

    const resetMapZoom = () => {
      if (props.dataStore.lookUpLists.map) {
        props.dataStore.lookUpLists.map.flyTo({
          zoom: 3,
          center: [-90.039891, 42.058919],
          speed: 2,
        });
      }
    };

    const setFileImports = (
      lookupList,
      CheckedList,
      lookupListTeam,
      CheckedListTeam,
    ) => {
      const files = [];
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      lookupList &&
        CheckedList &&
        lookupList.map((file) => {
          if (file && file.value && CheckedList.includes(file.value))
            files.push(file.value);
        });
      props.dataStore.networkFilters.file_imports = files;
      // if (props.dataStore.networkFilters.filter_type !== 'first_degree') {
      const teamFiles = [];
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      lookupListTeam &&
        CheckedListTeam &&
        lookupListTeam.map((file) => {
          if (file && file.value && CheckedListTeam.includes(file.value))
            teamFiles.push(file.value);
        });
      props.dataStore.networkFilters.team_file_imports = teamFiles;
      // }
    };

    const handleDegreeChange = (value) => {
      props.dataStore.networkFilters.filter_type = value;
      if (props.dataStore.networkFilters.filter_type === 'all') {
        if (props.dataStore.importSourceKeys.isImportSourceSaved) {
          const files = [];
          const teamFiles = [];
          files.push(...props.dataStore.networkFilters.filterFileImports);
          teamFiles.push(
            ...props.dataStore.networkFilters.filterTeamFileImports,
          );
          if (props.dataStore.importSourceKeys.isImportSourceChecked) {
            files.push(...props.dataStore.networkFilters.file_imports);
            teamFiles.push(...props.dataStore.networkFilters.team_file_imports);
          }
          setFileImports(
            props.dataStore.lookUpLists.fileImports,
            files,
            props.dataStore.lookUpLists.teamFileImports,
            teamFiles,
          );
        } else {
          props.dataStore.networkFilters.import_source = [];
          props.dataStore.networkFilters.file_imports = [];
          props.dataStore.networkFilters.team_file_imports = [];
          props.dataStore.importSourceKeys.isImportSourceChecked = false;
        }
      } else {
        if (
          props.dataStore.importSourceKeys.isImportSourceSaved ||
          props.dataStore.importSourceKeys.isImportSourceChecked
        ) {
          const files = [];
          const teamFiles = [];
          if (props.dataStore.importSourceKeys.isImportSourceSaved) {
            files.push(...props.dataStore.networkFilters.filterFileImports);
            teamFiles.push(
              ...props.dataStore.networkFilters.filterTeamFileImports,
            );
          }
          if (props.dataStore.importSourceKeys.isImportSourceChecked) {
            files.push(...props.dataStore.networkFilters.file_imports);
            teamFiles.push(...props.dataStore.networkFilters.team_file_imports);
          }
          setFileImports(
            props.dataStore.lookUpLists.fileImportsWithoutEnrich,
            files,
            props.dataStore.lookUpLists.teamFileImportsWithoutEnrich,
            teamFiles,
          );
        } else {
          props.dataStore.networkFilters.import_source = importSource;
          props.dataStore.networkFilters.file_imports =
            props.dataStore.lookUpLists.fileImportsWithoutEnrich.map((item) => {
              if (item.value) return item.value;
            });
          props.dataStore.networkFilters.team_file_imports =
            props.dataStore.lookUpLists.teamFileImportsWithoutEnrich.map(
              (item) => {
                if (item.value) return item.value;
              },
            );
        }
      }
      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3
      )
        resetMapZoom();
      else props.dataStore.networkFilters.fetchData = true;
    };

    const MenuFoldButton = () => {
      return (
        <div
          className="menu-fold-button"
          style={{
            transform: `${isMenufold ? 'rotate(180deg)' : ''}`,
            left: `${isMenufold ? 0 : '12px'}`,
          }}
          onClick={() => {
            if (isMenufold) {
              setIsMenufold(false);
              props.dataStore.lookUpLists.showFullMapWidth = false;
            } else {
              setIsMenufold(true);
              props.dataStore.lookUpLists.showFullMapWidth = true;
            }
          }}
        >
          <MenuFoldIcon style={{ margin: 'auto' }} />
        </div>
      );
    };

    const saveUserFilter = (
      filters,
      label,
      isActive,
      email_alert,
      separate_email_alert,
      filterType,
      fetchData,
    ) => {
      setShowSavedFilter(false);
      if (filterType) props.dataStore.networkFilters.filter_type = filterType;

      props.dataStore.importSourceKeys.isImportSourceSaved = false;
      props.dataStore.importSourceKeys.isImportSourceChecked = false;

      const dataStoreFilters = copyUserFilter(
        filters,
        props.dataStore.networkFilters,
        props.dataStore.lookUpLists,
        label,
        isActive,
        email_alert,
        separate_email_alert,
      );

      props.dataStore.networkFilters = cloneDeep(dataStoreFilters);

      /** For handling redirections specially on the keys import_source,
       * file_imports and team_file_imports. Extra conditions added in addition to the common method */
      if (filters.import_source) {
        props.dataStore.importSourceKeys.isImportSourceSaved = true;
      }

      if (filters.file_imports) {
        props.dataStore.networkFilters.filterFileImports = filters.file_imports;
        if (filterType === 'all') {
          props.dataStore.networkFilters.file_imports = filters.file_imports;
        } else {
          const files = [];
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          props.dataStore.lookUpLists.fileImportsWithoutEnrich &&
            props.dataStore.networkFilters.filterFileImports &&
            props.dataStore.lookUpLists.fileImportsWithoutEnrich.map((file) => {
              if (
                file &&
                file.value &&
                props.dataStore.networkFilters.filterFileImports.includes(
                  file.value,
                )
              )
                files.push(file.value);
            });
          props.dataStore.networkFilters.file_imports = files;
        }
        props.dataStore.importSourceKeys.isImportSourceSaved = true;
      }

      if (filters.team_file_imports) {
        props.dataStore.networkFilters.filterTeamFileImports =
          filters.team_file_imports;
        if (filterType === 'all') {
          props.dataStore.networkFilters.team_file_imports =
            filters.team_file_imports;
        } else {
          const files = [];
          const teamLookupToChoose = getTeamFileLookup(
            filterType,
            props.dataStore.lookUpLists.addImportsToTeam,
            props.dataStore.lookUpLists.teamFileImportsWithoutEnrich,
          );
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          teamLookupToChoose &&
            props.dataStore.networkFilters.filterTeamFileImports &&
            teamLookupToChoose.map((file) => {
              if (
                file &&
                file.value &&
                props.dataStore.networkFilters.filterTeamFileImports.includes(
                  file.value,
                )
              )
                files.push(file.value);
            });
          props.dataStore.networkFilters.team_file_imports = files;
        }
        props.dataStore.importSourceKeys.isImportSourceSaved = true;
      }

      if (!props.dataStore.importSourceKeys.isImportSourceSaved) {
        /** Consider the default imports(importSource, file imports, teamfile imports) scenario
         * based on the degree only if there is no imports(importSource, file imports, teamfile imports) selected within filter */
        if (filterType === 'all') {
          props.dataStore.networkFilters.import_source = [];
          props.dataStore.networkFilters.file_imports = [];
          props.dataStore.networkFilters.team_file_imports = [];
        } else {
          props.dataStore.networkFilters.import_source =
            props.dataStore.lookUpLists.import_source;
          props.dataStore.networkFilters.file_imports =
            props.dataStore.lookUpLists.fileImportsWithoutEnrich.map((item) => {
              if (item.value) return item.value;
            });
          const teamLookupToChoose = getTeamFileLookup(
            props.dataStore.networkFilters.filter_type,
            props.dataStore.lookUpLists.addImportsToTeam,
            props.dataStore.lookUpLists.teamFileImportsWithoutEnrich,
          );

          props.dataStore.networkFilters.team_file_imports =
            teamLookupToChoose.map((item) => {
              if (item.value) return item.value;
            });
        }
      }

      if (!fetchData) props.dataStore.networkFilters.fetchData = true;
    };
    const handleDegreeConnectionsChange = (tag, checked) => {
      if (checked) {
        switch (tag) {
          case 'first_degree':
            props.dataStore.networkFilters.filter_type =
              props.dataStore.networkFilters.filter_type === 'second_degree'
                ? 'combined'
                : 'first_degree';

            break;
          case 'second_degree':
            props.dataStore.networkFilters.filter_type =
              props.dataStore.networkFilters.filter_type === 'first_degree'
                ? 'combined'
                : 'second_degree';

            break;
          case 'all':
            props.dataStore.networkFilters.filter_type = 'all';
            // setSelectAll(true);
            break;
          default:
            break;
        }
      } else {
        switch (tag) {
          case 'first_degree':
            props.dataStore.networkFilters.filter_type =
              props.dataStore.networkFilters.filter_type === 'combined'
                ? // || selectAll
                  'second_degree'
                : 'all';

            break;
          case 'second_degree':
            props.dataStore.networkFilters.filter_type =
              props.dataStore.networkFilters.filter_type === 'combined'
                ? // || selectAll
                  'first_degree'
                : 'all';

            break;
          case 'all':
            props.dataStore.networkFilters.filter_type = 'all';
            // selectAll
            //   ? 'combined'
            //   : 'all';
            break;
          default:
            break;
        }
      }

      if (props.dataStore.networkFilters.filter_type === 'all') {
        if (props.dataStore.importSourceKeys.isImportSourceSaved) {
          const files = [];
          const teamFiles = [];
          files.push(...props.dataStore.networkFilters.filterFileImports);
          teamFiles.push(
            ...props.dataStore.networkFilters.filterTeamFileImports,
          );
          if (props.dataStore.importSourceKeys.isImportSourceChecked) {
            files.push(...props.dataStore.networkFilters.file_imports);
            teamFiles.push(...props.dataStore.networkFilters.team_file_imports);
          }
          setFileImports(
            props.dataStore.lookUpLists.fileImports,
            files,
            props.dataStore.lookUpLists.teamFileImports,
            teamFiles,
          );
        } else {
          props.dataStore.networkFilters.import_source = [];
          props.dataStore.networkFilters.file_imports = [];
          props.dataStore.networkFilters.team_file_imports = [];
          props.dataStore.importSourceKeys.isImportSourceChecked = false;
        }
      } else {
        const teamFileLookupToChoose = getTeamFileLookup(
          props.dataStore.networkFilters.filter_type,
          props.dataStore.lookUpLists.addImportsToTeam,
          props.dataStore.lookUpLists.teamFileImportsWithoutEnrich,
        );
        if (
          props.dataStore.importSourceKeys.isImportSourceSaved ||
          props.dataStore.importSourceKeys.isImportSourceChecked
        ) {
          const files = [];
          const teamFiles = [];
          if (props.dataStore.importSourceKeys.isImportSourceSaved) {
            files.push(...props.dataStore.networkFilters.filterFileImports);
            teamFiles.push(
              ...props.dataStore.networkFilters.filterTeamFileImports,
            );
          }
          if (props.dataStore.importSourceKeys.isImportSourceChecked) {
            files.push(...props.dataStore.networkFilters.file_imports);
            teamFiles.push(...props.dataStore.networkFilters.team_file_imports);
          }
          setFileImports(
            props.dataStore.lookUpLists.fileImportsWithoutEnrich,
            files,
            teamFileLookupToChoose,
            teamFiles,
          );
        } else {
          props.dataStore.networkFilters.import_source = importSource;
          props.dataStore.networkFilters.file_imports =
            props.dataStore.lookUpLists.fileImportsWithoutEnrich.map((item) => {
              if (item.value) return item.value;
            });
          props.dataStore.networkFilters.team_file_imports =
            teamFileLookupToChoose.map((item) => {
              if (item.value) return item.value;
            });
        }
      }
      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3
      )
        resetMapZoom();
      else props.dataStore.networkFilters.fetchData = true;
    };

    const onTagsChange = (checkedValues) => {
      props.dataStore.networkFilters.tags_checked = checkedValues;
      setShowSavedFilter(true);
      if (
        props.dataStore.lookUpLists.map &&
        props.dataStore.lookUpLists.map.getZoom() > 3
      ) {
        if (props.dataStore.lookUpLists.map) {
          props.dataStore.lookUpLists.map.flyTo({
            zoom: 3,
            center: [-90.039891, 42.058919],
            speed: 2,
          });
        }
      } else props.dataStore.networkFilters.fetchData = true;
    };

    return (
      <div className="propspect-finder-container">
        {!props.modalDataStore.companyProfileModal.isVisible &&
          !props.modalDataStore.profileModal.isVisible && <MenuFoldButton />}
        <div
          style={{
            width: isMenufold ? '0px' : '320px',
            transition: 'width 500ms',
          }}
        >
          <NewAdvancedFilter
            width={isMenufold ? '0px' : '320px'}
            onFilterChange={(val) => setShowSavedFilter(val)}
            handleDegreeConnectionsChange={handleDegreeConnectionsChange}
          />
        </div>

        <div
          className="right-component"
          style={{
            width: isMenufold ? 'calc(100vw - 80px)' : 'calc(100vw - 428px)',
            marginLeft: isMenufold ? '0px' : '28px',
          }}
        >
          <div className="top-section-fixed-container">
            <div className="top-section-container">
              <div
                className="top-flex-container"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: 10,
                  }}
                >
                  <div className="prospect-filter">
                    <div className="dropdown-label">SAVED SEARCHES</div>
                    <MultiFilterSelect
                      from="prospect-finder"
                      value={
                        props.dataStore.isNetworkFilterApplied &&
                        showSavedFilter ? (
                          <span
                            style={{
                              color: 'var(--color-link)',
                              fontSize: '13px',
                            }}
                          >
                            Save this Search
                          </span>
                        ) : (
                          props.dataStore.networkFilters.selectedIdValue
                        )
                      }
                      updateAfterSelect={saveUserFilter}
                      onUpdateOrCreate={() => {
                        setShowSavedFilter(false);
                      }}
                    />
                  </div>
                  <GroupByDropDown />

                  <div className="degree-connections-newer">
                    <div style={{ lineHeight: 1.75 }}>
                      <span
                        style={{
                          fontSize: '11px',
                          color: 'var(--color-black)',
                        }}
                      >
                        RELATIONSHIP PATHS{' '}
                      </span>
                      <HelpIcon
                        id="info-degrees"
                        className="info-icon"
                        height="12px"
                        width="12px"
                      />
                    </div>
                    <div
                      className={
                        (props.dataStore.lookUpLists.isSummaryPage &&
                          props.dataStore.lookUpLists.isRelationPathSelected) ||
                        (props.dataStore.networkFilters.relmap_org &&
                          props.dataStore.networkFilters.relmap_org.length >
                            0) ||
                        (flags.csvImportsAndDegrees &&
                          props.dataStore.networkFilters.filter_type ===
                            'all' &&
                          ((props.dataStore.networkFilters.file_imports &&
                            props.dataStore.networkFilters.file_imports.length >
                              0) ||
                            (props.dataStore.networkFilters.team_file_imports &&
                              props.dataStore.networkFilters.team_file_imports
                                .length > 0)))
                          ? 'tags-container tags-container-disabled'
                          : 'tags-container'
                      }
                    >
                      <CheckableTag
                        className="tag1-new"
                        key={'first_degree'}
                        checked={
                          props.dataStore.networkFilters.filter_type ===
                            'first_degree' ||
                          props.dataStore.networkFilters.filter_type ===
                            'combined'
                          // || selectAll
                        }
                        onChange={(checked) => {
                          // setSelectAll(false);
                          handleDegreeConnectionsChange(
                            'first_degree',
                            checked,
                          );
                        }}
                      >
                        <div className="corner-tag">1st</div>
                        <div className="tag-content">Your Network</div>
                      </CheckableTag>
                      <span style={{ margin: '0 .5vw', fontWeight: 600 }}>
                        +
                      </span>
                      <CheckableTag
                        className="tag2-new"
                        key={'second_degree'}
                        checked={
                          props.dataStore.networkFilters.filter_type ===
                            'second_degree' ||
                          props.dataStore.networkFilters.filter_type ===
                            'combined'
                          // || selectAll
                        }
                        onChange={(checked) => {
                          // setSelectAll(false);
                          handleDegreeConnectionsChange(
                            'second_degree',
                            checked,
                          );
                        }}
                      >
                        <div className="corner-tag">2nd</div>
                        <div className="tag-content">Get Introduced </div>
                      </CheckableTag>
                      <span
                        style={{
                          margin: '0 .5vw',
                          fontWeight: 400,
                          fontSize: '12px',
                        }}
                      >
                        or
                      </span>
                      <CheckableTag
                        className="tag3-new"
                        key={'all'}
                        checked={
                          props.dataStore.networkFilters.filter_type === 'all'
                        }
                        onChange={(checked) => {
                          // setSelectAll(true);
                          handleDegreeConnectionsChange('all', checked);
                        }}
                      >
                        <span className="tag-content">Everyone</span>
                      </CheckableTag>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '5px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flex: '1 1 auto',
                      alignItems: 'center',
                      gap: '3px',
                      margin: '15px 0px 0px 0px',
                    }}
                  >
                    <Switch
                      size="small"
                      checked={props.dataStore.networkFilters.attributes_checked.includes(
                        'is_notes_included',
                      )}
                      onChange={(checked) => {
                        if (checked)
                          props.dataStore.networkFilters.attributes_checked = [
                            ...props.dataStore.networkFilters
                              .attributes_checked,
                            'is_notes_included',
                          ];
                        else
                          props.dataStore.networkFilters.attributes_checked =
                            props.dataStore.networkFilters.attributes_checked.slice(
                              0,
                              -1,
                            );

                        props.dataStore.networkFilters.fetchData = true;
                      }}
                    />
                    <span
                      style={{
                        color: 'var(--color-primary)',
                        fontSize: '12px',
                      }}
                    >
                      Notes{' '}
                    </span>
                    <AiTooltip
                      marginBottom="0px"
                      title="See any profiles with notes added"
                    />
                  </div>
                  {flags && flags.tagFolders ? (
                    <TagsFolder />
                  ) : (
                    <div className="tags-section">
                      <div className="dropdown-label">TAGS</div>

                      <Select
                        mode="multiple"
                        bordered={false}
                        showSearch={false}
                        maxTagPlaceholder={null}
                        popupClassName="tag-select"
                        popupMatchSelectWidth={false}
                        placeholder={
                          <span
                            className={
                              props.dataStore.networkFilters.tags_checked &&
                              props.dataStore.networkFilters.tags_checked
                                .length > 0
                                ? 'tags-selected-placeholder'
                                : 'tags-placeholder'
                            }
                          >
                            {props.dataStore.networkFilters.tags_checked &&
                            props.dataStore.networkFilters.tags_checked.length >
                              0
                              ? props.dataStore.networkFilters.tags_checked
                                  .length
                              : 'None'}{' '}
                            Selected
                          </span>
                        }
                        tagRender={(p) => {
                          return (
                            <span className="tags-selected-placeholder">
                              {props.dataStore.networkFilters.tags_checked &&
                              props.dataStore.networkFilters.tags_checked
                                .length > 0
                                ? props.dataStore.networkFilters.tags_checked
                                    .length
                                : 'None'}{' '}
                              Selected
                            </span>
                          );
                        }}
                      >
                        <Select.Option>
                          <CheckboxGroup
                            style={{ width: '100%', paddingTop: 10 }}
                            value={props.dataStore.networkFilters.tags_checked}
                            onChange={(checkedValues) =>
                              onTagsChange(checkedValues)
                            }
                          >
                            {props.dataStore.userTags.map(({ id, tag }) => {
                              return (
                                <div
                                  className="tag-row"
                                  style={
                                    (tag === 'Suppress' &&
                                      props.dataStore.currentUser
                                        .hideSuppressTag) ||
                                    (tag?.toLowerCase() === 'moneta contact' &&
                                      props.dataStore.currentUser
                                        .hideMonetaContactTag)
                                      ? {
                                          display: 'none',
                                        }
                                      : {}
                                  }
                                >
                                  <Checkbox value={id}>{tag}</Checkbox>
                                  {tag === 'Suppress' && (
                                    <Tooltip
                                      placement="bottom"
                                      color="var(--color-white)"
                                      title={
                                        <span
                                          style={{
                                            color: 'var(--color-black)',
                                          }}
                                        >
                                          Suppress Tag removes profile from
                                          search results, filters, daily
                                          opportunities and email exports.
                                        </span>
                                      }
                                    >
                                      <HelpIcon
                                        id="suppress"
                                        height="14px"
                                        width="14px"
                                        className="info-icon"
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                              );
                            })}
                          </CheckboxGroup>
                        </Select.Option>
                      </Select>
                    </div>
                  )}
                </div>
              </div>

              {isMenufold &&
                localStorage.getItem('aidentified.pfFinderHelp') &&
                localStorage.getItem('aidentified.pfFinderHelp') ===
                  'false' && (
                  <div className="helper-section">
                    How Prospect Finder works
                    <div className="play-icon" id="prospect-finder-help-watch">
                      <PlayCircleFilled />
                      <span className="help-text">Watch Now</span>
                    </div>
                  </div>
                )}
            </div>
          </div>
          {/* dummy div for scroll */}
          <div
            style={{
              height: '97px',
              visibility: 'hidden',
            }}
          />
          {(props.dataStore.isNetworkFilterApplied ||
            props.dataStore.mappedRelationshipsFilter.filterName ||
            (props.dataStore.selectedGroupByParam &&
              props.dataStore.selectedGroupByParam.length > 0) ||
            (props.dataStore.lookUpLists.isPeoplePage &&
              props.dataStore.networkFilters.mapQuery &&
              Object.keys(props.dataStore.networkFilters.mapQuery).length >
                0)) && (
            <>
              <AppliedFilter />
            </>
          )}
          <Outlet />
        </div>
      </div>
    );
  }),
);

export default ProspectFinder;
