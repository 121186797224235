import './index.scss';
import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Tag, Tooltip } from 'antd';

import FavoritesIcon from '../../assets/icons/ai_star_icn.svg?react';
import NurtureIcon from '../../assets/icons/ai_nurture_icn.svg?react';
import SuppressedIcon from '../../assets/icons/ai_unavailable_icon.svg?react';
import ContactedIcon from '../../assets/icons/ai_box_check_icn.svg?react';
import PlusIcon from '../../assets/icons/ai_plus.svg?react';
import IconClose from '../../assets/icons/ai_ic_close.svg?react';
import CreateTagElement from './CreateTagElement';

const TagsList = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const {
      tags,
      // showSelectAll,
      checkedTags = [],
      handleTagsCheck,
      count,
      showCreateTag,
      isProfileModule,
      fetchTagFolders,
    } = props;
    const [sortedTags, setSortedTags] = useState([]);

    const defaultTags = [
      'Do not contact',
      'Favorites',
      'Nurture',
      'Contacted',
      'Suppress',
    ];

    useEffect(() => {
      // sortTagData(tags);
      if (count) setSortedTags([...tags.slice(0, count)]);
      else {
        sortTagData(tags);
        setSortedTags([...tags]);
      }
    }, [tags, count]);

    // const onCheckboxChange = (e) => {
    //   let selected = [];
    //   if (e.target.checked)
    //     selected = sortedTags.map((tag) => {
    //       return tag.id;
    //     });

    //   setSelectedTags([...selected]);
    // };

    const sortTagData = (objArray) => {
      objArray.sort((a, b) =>
        a?.tag.toLowerCase().localeCompare(b?.tag.toLowerCase()),
      );
    };

    const addToList = (newTag) => {
      if (fetchTagFolders) fetchTagFolders();
      return;
      // setSortedTags([...sortedTags, newTag])
    };

    const handleChange = (tag, checked) => {
      const nextSelectedTags = checked
        ? [...checkedTags, tag]
        : checkedTags.filter((t) => t !== tag);
      handleTagsCheck([...nextSelectedTags]);
    };

    const getDefaultTagIcon = (tagName) => {
      switch (tagName) {
        case 'Do not contact':
        case 'Suppress':
          return (
            <SuppressedIcon
              className="default-icon"
              style={{ height: '12px' }}
            />
          );
        case 'Favorites':
          return <FavoritesIcon className="default-icon" />;
        case 'Nurture':
          return <NurtureIcon className="default-icon" />;
        case 'Contacted':
          return <ContactedIcon className="default-icon" />;
        default:
          return;
      }
    };

    if (!sortedTags || sortedTags.length === 0) return <></>;

    return (
      <div className="tags-list">
        {/* {showSelectAll && (
          <Checkbox
            checked={tags.length === selectedTags.length}
            onChange={onCheckboxChange}
          />
        )} */}
        {sortedTags.map((tagItem) => {
          return (
            // <span>
            <Tooltip
              title={
                tagItem.tag.toLowerCase() === 'do not contact'
                  ? 'System Default Suppression Tag'
                  : props.sharedTags
                    ? 'shared tag'
                    : checkedTags?.includes(tagItem.id)
                      ? isProfileModule
                        ? 'Detach tag'
                        : 'Turn tag off'
                      : isProfileModule
                        ? 'Attach tag'
                        : 'Turn tag on'
              }
              overlayClassName="tag-tooltip"
              arrow={false}
              placement="bottomRight"
            >
              <Tag.CheckableTag
                key={tagItem.id}
                checked={checkedTags.includes(tagItem.id)}
                onClick={(e) => e.stopPropagation()}
                onChange={(checked) => handleChange(tagItem.id, checked)}
              >
                <div className="flex">
                  {defaultTags.includes(tagItem.tag) &&
                    getDefaultTagIcon(tagItem.tag)}
                  <div
                    className={
                      defaultTags.includes(tagItem.tag)
                        ? 'tag-text default-tag-text'
                        : 'tag-text'
                    }
                  >
                    {tagItem.tag}
                  </div>
                  {checkedTags.includes(tagItem.id) ? (
                    <IconClose style={{ color: '#3C3C3C', height: '10px' }} />
                  ) : (
                    <PlusIcon style={{ color: '#3C3C3C', height: '10px' }} />
                  )}
                </div>
              </Tag.CheckableTag>
            </Tooltip>
            // </span>
          );
        })}
        {showCreateTag && <CreateTagElement onCreation={addToList} />}
        {/* <span>
          {sortedTags.length > count && (
            <span style={{ width: 'max-content', fontSize: 10 }}>
              + {sortedTags.length - count} more
            </span>
          )}
        </span> */}
      </div>
    );
  }),
);

export default TagsList;
