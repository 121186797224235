import dayjs from 'dayjs';
import {
  ModeledChildPresenceValues,
  companySizeFilter,
  companyViablityRanges,
  companyType,
  remainingShareValues,
  interests,
  wealthScoreRanges,
  garageLookup,
  lotSizeRanges,
  storiesList,
  Numbers1To10,
  Properties2To5,
  propertyTypes,
  mappedConnectionsCount,
  credentialsLookup,
} from './constants';
import {
  getMultipleValueSelected,
  getSelectedValues,
  splitNumberWithCommas,
  flattenTree,
  getNewPropertyValueRange,
} from './general';
import filterStates from './Statelist';

function getZipValue(zip, zip4) {
  let gte = zip.gte;
  let lte = zip.lte;

  if (zip4 && zip4.gte) gte = `${gte}-${zip4.gte}`;

  if (zip4 && zip4.lte) lte = `${lte}-${zip4.lte}`;

  return {
    key: gte === lte ? 'exact' : 'between',
    value: [gte, lte],
  };
}

export function copyUserFilter(
  filters,
  dataStoreFilterArg,
  lookUpLists,
  name,
  is_active,
  email_alert,
  separate_email_alert,
) {
  const dataStoreFilters = { ...dataStoreFilterArg };
  let naicsFlat = [];
  let naicsArray = [];
  if (name) {
    dataStoreFilters.prospect_profile_name = name;
  }

  dataStoreFilters.default_view = is_active || false;

  dataStoreFilters.email_alert = !!email_alert;
  dataStoreFilters.separate_email_alert = !!separate_email_alert;

  Object.keys(filters).map((key) => {
    /* switch case for mapping the values from api
          and changing in to the format for display in the ui */
    switch (key) {
      case 'naics_codes':
        naicsFlat = flattenTree(lookUpLists.naicsList);
        naicsArray = filters.naics_codes.map((code) => {
          return naicsFlat.find((obj) => obj.value === code);
        });
        dataStoreFilters.naics_codes = naicsArray;
        break;
      case 'marital_status':
        if (filters.marital_status && filters.marital_status !== '') {
          if (filters.marital_status === 'M') {
            dataStoreFilters.marital_status = {
              label: 'Married',
              value: filters.marital_status,
            };
          } else {
            dataStoreFilters.marital_status = {
              label: 'Single',
              value: filters.marital_status,
            };
          }
        }
        break;
      case 'gender':
        if (filters.gender && filters.gender !== '') {
          if (filters.gender === 'M') {
            dataStoreFilters.gender = {
              label: 'Male',
              value: filters.gender,
            };
          } else {
            dataStoreFilters.gender = {
              label: 'Female',
              value: filters.gender,
            };
          }
        }
        break;
      case 'range_list':
        if (filters.range_list && filters.range_list.length > 0) {
          filters.range_list.map((obj) => {
            if (Object.keys(obj.range)[0] === 'age') {
              if (
                obj.range.age.gte &&
                obj.range.age.lte &&
                obj.range.age.gte === obj.range.age.lte
              ) {
                dataStoreFilters.age = {
                  key: 'exact',
                  value: [obj.range.age.gte, obj.range.age.gte],
                };
              } else if (obj.range.age.gte || obj.range.age.lte) {
                dataStoreFilters.age = {
                  key: 'between',
                  value: [obj.range.age.gte, obj.range.age.lte],
                };
              }
            }
          });
        }
        break;
      case 'wealth_estimate': {
        const wealthArray = [];
        const wealthRangeObj = {};
        if (filters.wealth_estimate && filters.wealth_estimate.length > 0) {
          filters.wealth_estimate.map((obj) => {
            if (obj.range && Object.keys(obj.range)[0] === 'wealth_estimate') {
              if (wealthScoreRanges && wealthScoreRanges.length > 0) {
                wealthScoreRanges.map((wealthScore) => {
                  if (obj.range) {
                    Object.keys(obj.range).map(function (key) {
                      if (
                        (obj.range[key].gte === wealthScore.min ||
                          obj.range[key].gte === wealthScore.min - 1) &&
                        (obj.range[key].lte === wealthScore.max ||
                          !obj.range[key].lte)
                      ) {
                        wealthArray.push({
                          value: wealthScore.value,
                          label: wealthScore.label,
                          min: wealthScore.min,
                          max: wealthScore.max,
                        });
                      } else {
                        if (obj.range[key].gte === obj.range[key].lte) {
                          wealthRangeObj.key = 'exact';
                          wealthRangeObj.value = [
                            obj.range[key].gte,
                            obj.range[key].gte,
                          ];
                        } else {
                          wealthRangeObj.key = 'between';
                          wealthRangeObj.value = [
                            obj.range[key].gte,
                            obj.range[key].lte,
                          ];
                        }
                      }
                    });
                  }
                });
              }
            }
          });
          if (wealthArray && wealthArray.length > 0)
            dataStoreFilters.wealth_estimate = wealthArray;
          else dataStoreFilters.wealth_estimate_range = wealthRangeObj;
        }
        break;
      }
      case 'property_type':
        dataStoreFilters[key] = getMultipleValueSelected(
          propertyTypes,
          filters[key],
        );
        break;
      case 'latest_mim_event_type':
        dataStoreFilters[key] = getMultipleValueSelected(
          lookUpLists.eventList,
          filters[key],
        );
        break;
      case 'home_search': {
        const latlong = filters[key].geo_distance.b2c_geo.split(',');
        dataStoreFilters[key] = {
          geo: { lat: latlong[0], lng: latlong[1] },
          address: filters[key].geo_distance.address,
        };
        let distance = filters[key].geo_distance.distance;
        distance = distance.replace('km', '');
        dataStoreFilters.slider_radius = Math.round(distance / 1.6);
        break;
      }
      case 'company_search': {
        const latlng = filters[key].geo_distance.b2b_geo.split(',');
        dataStoreFilters[key] = {
          geo: { lat: latlng[0], lng: latlng[1] },
          address: filters[key].geo_distance.address,
        };
        break;
      }
      case 'schools':
      case 'exc_home_address_suggest':
      case 'home_address_suggest': {
        const dataArray = [];
        if (filters[key]) {
          if (Array.isArray(filters[key])) {
            dataStoreFilters[key] = getSelectedValues(filters[key]);
          } else {
            if (filters[key].exact && filters[key].exact.length > 0) {
              filters[key].exact.map((exact) => {
                if (exact) {
                  // schoolArray.push(exact);
                  dataArray.push({ value: exact, label: exact });
                }
              });
            }
            if (filters[key].prefix && filters[key].prefix.length > 0) {
              filters[key].prefix.map((prefix) => {
                if (prefix) {
                  dataArray.push({
                    value: prefix,
                    label: prefix,
                    __isNew__: true,
                  });
                }
              });
            }
            dataStoreFilters[key] = dataArray;
          }
        }
        break;
      }
      case 'home_postal_code': {
        let zip = null,
          zip4 = null;
        if (
          filters.home_postal_code &&
          filters.home_postal_code.range &&
          filters.home_postal_code.range.home_postal_code
        )
          zip = filters.home_postal_code.range.home_postal_code;

        if (
          filters.home_zip4 &&
          filters.home_zip4.range &&
          filters.home_zip4.range.home_zip4
        )
          zip4 = filters.home_zip4.range.home_zip4;

        if (zip) {
          dataStoreFilters.home_postal_code = getZipValue(zip, zip4);
        }
        break;
      }
      case 'location_state': {
        const stateArray = [];
        const array = filters[key];
        if (array && array.length > 0) {
          array.map((filter) => {
            filterStates.map((state) => {
              if (filter === state.value) stateArray.push(state);
            });
          });
        }
        dataStoreFilters[key] = stateArray;
        break;
      }
      case 'map_query':
        dataStoreFilters.mapQuery = filters[key];
        dataStoreFilters.hasSavedMapQuery = true;
        break;
      case 'norm_home_owner':
      case 'location_city':
      case 'industry':
      case 'exc_industry':
      case 'inferred_salary_range':
      case 'funding_round':
      case 'event_org_name':
      case 'county':
        dataStoreFilters[key] = getSelectedValues(filters[key]);
        break;
      case 'org_name':
        if (
          dataStoreFilters.org_name &&
          Array.isArray(dataStoreFilters.org_name)
        )
          dataStoreFilters.org_name.push(...getSelectedValues(filters[key]));
        else dataStoreFilters.org_name = [...getSelectedValues(filters[key])];
        break;

      case 'exc_current_experience.ai_org_id':
        if (
          dataStoreFilters.exc_current_experience &&
          Array.isArray(dataStoreFilters.exc_current_experience)
        )
          dataStoreFilters.exc_current_experience.push(
            ...getSelectedValues(filters[key], 'ai_org_id'),
          );
        else
          dataStoreFilters.exc_current_experience = [
            ...getSelectedValues(filters[key], 'ai_org_id'),
          ];
        break;
      case 'org_contains':
        if (
          dataStoreFilters.org_name &&
          Array.isArray(dataStoreFilters.org_name)
        )
          dataStoreFilters.org_name.push(
            ...getSelectedValues(filters[key], 'org_contains'),
          );
        else
          dataStoreFilters.org_name = [
            ...getSelectedValues(filters[key], 'org_contains'),
          ];
        break;
      case 'exc_org_contains':
        if (
          dataStoreFilters.exc_current_experience &&
          Array.isArray(dataStoreFilters.exc_current_experience)
        )
          dataStoreFilters.exc_current_experience.push(
            ...getSelectedValues(filters[key], 'exc_org_contains'),
          );
        else
          dataStoreFilters.exc_current_experience = [
            ...getSelectedValues(filters[key], 'exc_org_contains'),
          ];
        break;
      case 'current_experience.ai_org_id':
        if (
          dataStoreFilters.org_name &&
          Array.isArray(dataStoreFilters.org_name)
        )
          dataStoreFilters.org_name.push(
            ...getSelectedValues(filters[key], 'ai_org_id'),
          );
        else
          dataStoreFilters.org_name = [
            ...getSelectedValues(filters[key], 'ai_org_id'),
          ];
        break;
      case 'previous_experience.ai_org_id':
        if (
          dataStoreFilters.previous_experience_org_name &&
          Array.isArray(dataStoreFilters.previous_experience_org_name)
        )
          dataStoreFilters.previous_experience_org_name.push(
            ...getSelectedValues(filters[key], 'ai_org_id'),
          );
        else
          dataStoreFilters.previous_experience_org_name = [
            ...getSelectedValues(filters[key], 'ai_org_id'),
          ];
        break;

      case 'latest_mim_monetary_value': {
        const rangeArray = [];
        // const valueArray = [];
        if (
          filters.latest_mim_monetary_value &&
          filters.latest_mim_monetary_value.length > 0
        ) {
          filters.latest_mim_monetary_value.map((obj) => {
            if (
              obj.range &&
              obj.range['events.event_monetary_value'] &&
              (obj.range['events.event_monetary_value'].gte ||
                obj.range['events.event_monetary_value'].lte)
            ) {
              // const valueObj = {};
              const wealthRangeObj = {};
              if (wealthScoreRanges && wealthScoreRanges.length > 0) {
                wealthScoreRanges.map((wealthScore) => {
                  if (
                    obj.range['events.event_monetary_value'].gte ===
                      wealthScore.min &&
                    (obj.range['events.event_monetary_value'].lte ===
                      wealthScore.max ||
                      !obj.range['events.event_monetary_value'].lte)
                  ) {
                    wealthRangeObj.value = wealthScore.value;
                    wealthRangeObj.label = wealthScore.label;
                    wealthRangeObj.min = wealthScore.min;
                    wealthRangeObj.max = wealthScore.max;
                  }
                });
              }
              if (wealthRangeObj && Object.keys(wealthRangeObj).length > 0) {
                rangeArray.push(wealthRangeObj);
              }
              // else if (
              //   obj.range['events.event_monetary_value'].gte &&
              //   obj.range['events.event_monetary_value'].lte &&
              //   obj.range['events.event_monetary_value'].gte ===
              //     obj.range['events.event_monetary_value'].lte
              // ) {
              //   valueObj.key = 'exact';
              //   valueObj.value = [
              //     obj.range['events.event_monetary_value'].gte,
              //     obj.range['events.event_monetary_value'].gte,
              //   ];
              // } else {
              //   valueObj.key = 'between';
              //   valueObj.value = [
              //     obj.range['events.event_monetary_value'].gte,
              //     obj.range['events.event_monetary_value'].lte,
              //   ];
              // }
              // if (valueObj && Object.keys(valueObj).length > 0) {
              //   valueArray.push(valueObj);
              // }
            }
          });
        }
        // if (valueArray && valueArray.length > 0)
        //   dataStoreFilters.latest_mim_monetary_value = valueArray[0];
        // if (rangeArray && rangeArray.length > 0)
        //   dataStoreFilters.latest_mim_monetary_value_range = rangeArray;
        if (rangeArray && rangeArray.length > 0)
          dataStoreFilters.latest_mim_monetary_value = rangeArray;
        break;
      }

      case 'home_value_estimate':
        if (filters[key] && filters[key].length > 0) {
          let rangeVals = [];
          const estimateRanges = [];
          filters[key].map((obj) => {
            if (obj && obj.range && obj.range.home_value_estimate) {
              rangeVals = getNewPropertyValueRange(
                obj.range.home_value_estimate.gte,
                obj.range.home_value_estimate.lte || null,
              );
            }
            estimateRanges.push(...rangeVals);
          });
          if (estimateRanges.length > 0) {
            dataStoreFilters[key] = estimateRanges;
          }
        }

        break;
      case 'current_org_years':
      case 'total_years_of_experience':
        if (filters[key] && filters[key].length > 0) {
          filters[key].map((obj) => {
            if (
              obj &&
              obj.range &&
              obj.range[key] &&
              obj.range[key].gte &&
              obj.range[key].lte &&
              obj.range[key].gte === obj.range[key].lte
            ) {
              dataStoreFilters[key] = {
                key: 'exact',
                value: [obj.range[key].gte, obj.range[key].gte],
              };
            } else if (obj.range[key].gte || obj.range[key].lte) {
              dataStoreFilters[key] = {
                key: 'between',
                value: [obj.range[key].gte, obj.range[key].lte],
              };
            }
          });
        }
        break;
      case 'company_type':
        companyType.map((type) => {
          if (type.value === filters[key]) {
            dataStoreFilters[key] = type;
          }
        });
        break;
      case 'number_of_stories':
        dataStoreFilters[key] = getMultipleValueSelected(
          storiesList,
          filters[key],
        );
        break;
      case 'garage_cars': {
        const garageArray = [];
        if (filters.garage_cars && filters.garage_cars.length > 0) {
          filters.garage_cars.map((obj) => {
            const garage = garageLookup.filter(
              (item) => item.value == obj.range.garage_cars.gte,
            );

            garageArray.push(garage[0]);
          });
          dataStoreFilters.garage_cars = garageArray;
        }
        break;
      }
      case 'number_of_owned_properties': {
        const ownedpropertyArray = [];
        if (filters[key] && filters[key].length > 0) {
          filters[key].map((obj) => {
            ownedpropertyArray.push(
              Properties2To5.filter(
                (item) => item.value == obj.range[key].gte,
              )[0],
            );
          });
          dataStoreFilters[key] = ownedpropertyArray;
        }
        break;
      }

      case 'total_number_of_rooms':
      case 'number_of_bedrooms':
      case 'number_of_baths':
      case 'number_of_partial_baths':
      case 'number_of_units': {
        const propertyArray = [];
        if (filters[key] && filters[key].length > 0) {
          filters[key].map((obj) => {
            propertyArray.push(
              Numbers1To10.filter(
                (item) => item.value == obj.range[key].gte,
              )[0],
            );
          });
          dataStoreFilters[key] = propertyArray;
        }
        break;
      }
      case 'is_pool_included':
      case 'is_elevator_included':
        dataStoreFilters[key] = filters[key];
        break;

      case 'lotsize_square_feet': {
        const sqArr = [];
        filters.lotsize_square_feet.map((obj) => {
          sqArr.push(
            lotSizeRanges.filter((item) => {
              return obj.range.lotsize_square_feet.gte === item.rangeObject.gte;
            })[0],
          );
        });
        dataStoreFilters.lotsize_square_feet = sqArr;
        break;
      }
      case 'effective_year_built': {
        const dateRange = [];
        dateRange.push(
          dayjs(
            `${filters.effective_year_built[0].range.effective_year_built.gte}-01-01`,
          ),
        );
        dateRange.push(
          dayjs(
            `${filters.effective_year_built[0].range.effective_year_built.lte}-01-01`,
          ),
        );
        dataStoreFilters.effective_year_built = dateRange;
        break;
      }
      case 'remaining_share_value_sum': {
        const shareArray = [];
        if (
          filters.remaining_share_value_sum &&
          filters.remaining_share_value_sum.length > 0
        ) {
          filters.remaining_share_value_sum.map((obj) => {
            if (Object.keys(obj.range)[0] === 'remaining_share_value_sum') {
              if (remainingShareValues && remainingShareValues.length > 0) {
                remainingShareValues.map((share) => {
                  if (obj.range) {
                    Object.keys(obj.range).map(function (key) {
                      if (
                        obj.range[key].gte === share.min ||
                        obj.range[key].gte === share.min - 1
                      ) {
                        shareArray.push({
                          value: share.value,
                          label: share.label,
                          min: share.min,
                          max: share.max,
                        });
                      }
                    });
                  }
                });
              }
            }
          });
          dataStoreFilters.remaining_share_value_sum = shareArray;
        }
        break;
      }
      case 'presence_of_children':
        if (filters.presence_of_children !== '') {
          dataStoreFilters.presence_of_children =
            ModeledChildPresenceValues.filter((item) => {
              return item.value === filters.presence_of_children;
            })[0];
        }
        break;
      case 'stock_performance': {
        const confidenceLevel = [];
        companyViablityRanges.map((confidence) => {
          filters[key].map((level) => {
            if (
              confidence &&
              level &&
              Array.isArray(level) &&
              confidence.value === level.join(', ')
            ) {
              confidenceLevel.push({
                label: confidence.label,
                value: confidence.value,
              });
            }
          });
        });
        dataStoreFilters[key] = confidenceLevel;
        break;
      }
      case 'employee_count':
        dataStoreFilters[key] = getMultipleValueSelected(
          companySizeFilter,
          filters[key],
        );
        break;
      case 'credentials':
        dataStoreFilters[key] = getMultipleValueSelected(
          credentialsLookup,
          filters[key],
        );
        break;
      case 'exc_title_rank':
      case 'title_rank':
        dataStoreFilters[key] = getMultipleValueSelected(
          lookUpLists.titleRankList,
          filters[key],
        );
        break;
      case 'title_role':
        dataStoreFilters[key] = getMultipleValueSelected(
          lookUpLists.titleRole,
          filters[key].exact,
        );
        break;
      case 'exc_title':
      case 'title': {
        const titleArray = [];
        if (filters[key]) {
          if (typeof filters[key] === 'string') {
            const filterValue = filters[key];
            titleArray.push({
              value: filterValue,
              label: filterValue,
              __isNew__: true,
            });
            dataStoreFilters[key] = titleArray;
          } else {
            if (filters[key].exact && filters[key].exact.length > 0) {
              filters[key].exact.map((exact) => {
                if (exact) {
                  titleArray.push({ value: exact, label: exact });
                }
              });
            }
            if (filters[key].prefix && filters[key].prefix.length > 0) {
              filters[key].prefix.map((prefix) => {
                if (prefix) {
                  titleArray.push({
                    value: prefix,
                    label: prefix,
                    __isNew__: true,
                  });
                }
              });
            }
            dataStoreFilters[key] = titleArray;
          }
        }
        break;
      }
      case 'tag_ids':
        if (filters.shared_tags && filters.shared_tags.length > 0) {
          dataStoreFilters.tags_checked = filters.tag_ids.filter(
            (tag) => !filters.shared_tags.includes(tag),
          );
        } else {
          dataStoreFilters.tags_checked = filters.tag_ids;
        }
        break;
      case 'shared_tags':
        dataStoreFilters.shared_tags = filters.shared_tags;
        break;
      case 'import_source':
      case 'file_imports':
      case 'team_file_imports':
      case 'attribute_function':
      case 'all_homes':
      case 'all_interests':
        dataStoreFilters[key] = filters[key];
        break;
      case 'relmap_org_ui':
        dataStoreFilters.relmap_org = filters[key];
        if (!filters[key] || filters[key].length <= 0) {
          dataStoreFilters.filter_type = 'second_degree';
        } else {
          dataStoreFilters.filter_type = 'all';
        }
        break;
      case 'number_of_mapped_connections':
        {
          const mappedValue = [];
          if (
            filters.number_of_mapped_connections &&
            Array.isArray(filters.number_of_mapped_connections)
          ) {
            filters.number_of_mapped_connections.map((val) => {
              const mappedCountObj = mappedConnectionsCount.find(
                (obj) =>
                  obj.valueObject.gte ===
                  val.range.number_of_mapped_connections.gte,
              );
              mappedValue.push(mappedCountObj);
            });
          }
          dataStoreFilters[key] = [...mappedValue];
        }
        break;
      case 'is_personal_emails_included':
      case 'is_work_emails_included':
      case 'is_mobile_phones_included':
      case 'is_other_phones_included':
      case 'is_household_members_included':
      case 'is_home_address_included':
      case 'is_twitter_included':
      case 'is_facebook_included':
      case 'is_linkedin_included':
      case 'is_b2c_id_included':
      case 'is_age_verified':
      case 'is_home_address_verified':
      case 'is_home_ownership_verified':
      case 'is_gender_verified':
      case 'is_career_verified':
      case 'is_income_verified':
      case 'is_wealth_verified':
      case 'li_only':
      case 'is_medical_included':
      case 'is_notes_included':
        if (
          !dataStoreFilters.attributes_checked ||
          !Array.isArray(dataStoreFilters.attributes_checked)
        )
          dataStoreFilters.attributes_checked = [];
        if (!dataStoreFilters.attributes_checked.includes(key))
          dataStoreFilters.attributes_checked.push(key);
        break;
      case 'is_age_included':
      case 'is_norm_home_owner_included':
      case 'is_home_value_estimate_included':
      case 'is_inferred_salary_included':
      case 'is_geo_tax_investable_assets_included':
      case 'is_b2b_id_included':
      case 'is_remaining_share_value_sum_included':
      case 'has_wealth_triggers':
        if (
          !dataStoreFilters.wealth_model_checked ||
          !Array.isArray(dataStoreFilters.wealth_model_checked)
        )
          dataStoreFilters.wealth_model_checked = [];
        dataStoreFilters.wealth_model_checked.push(key);
        break;
      case 'm2_date': {
        const m2dateRange = [];
        if (!filters.period || filters.period.value === '') {
          filters.m2_date.map((date) => {
            if (date) m2dateRange.push(dayjs(date).format('YYYY-MM-DD'));
          });
          dataStoreFilters.m2_date = m2dateRange;
        }
        break;
      }
      case 'home_sale_date':
      case 'home_purchase_date': {
        const psDate = [];
        filters[key].map((date) => {
          if (date) psDate.push(dayjs(date).format('YYYY-MM-DD'));
        });
        dataStoreFilters[key] = psDate;
        break;
      }
      case 'period':
        if (filters.period && filters.period !== '') {
          dataStoreFilters.period = {
            label: filters.period.toTitleCase(),
            value: filters.period,
          };
        }
        break;
      case 'geo_tax_wages_salaries_tips_etc':
      case 'geo_tax_household_income':
      case 'geo_tax_wealth_income':
      case 'geo_tax_retirement_income':
      case 'geo_tax_business_income_or_loss':
      case 'geo_tax_schedule_e_income':
      case 'geo_tax_non_wage_income':
      case 'geo_tax_disposable_income':
      case 'geo_tax_investable_assets':
      case 'geo_tax_capital_gain_or_loss':
      case 'geo_tax_ira_deduction':
      case 'geo_tax_total_gifts_to_charity':
      case 'geo_tax_real_estate_taxes':
      case 'geo_tax_home_mortgage_interest_and_points_reported_to_you': {
        const irsTaxInsights = filters[key].range[key];
        dataStoreFilters.irs_tax_insights = {
          key: key,
          gte: splitNumberWithCommas(irsTaxInsights.gte) || '',
          lte: splitNumberWithCommas(irsTaxInsights.lte) || '',
        };
        break;
      }

      case 'mapped_source_filter_name':
      case 'mapped_source_b2b_id':
      case 'mapped_source_b2c_id':
      case 'mapped_relationship_weights':
      case 'mapped_relationship_types':
        dataStoreFilters.mapped_relationships_filter[key] = filters[key] || '';
        break;

      default:
        break;
    }
    const interestArray = [];
    if (interests && interests.length > 0) {
      interests.map((interest) => {
        if (filters[interest.value])
          interestArray.push({
            value: interest.value,
            label: interest.label,
          });
      });
    }
    dataStoreFilters.interests = interestArray;
  }, {});
  return dataStoreFilters;
}
