import './index.scss';

import { message } from 'antd';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { inject, observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import WarningIcon from '../../assets/icons/ai_ic_warning.svg?react';
import { requestWithAuth } from '../../services/api';
import { wealthScoreRanges } from '../../utils/constants';
import { handleCrmSync } from '../../utils/crmMethods';
import LoaderAnimation from '../AidLoader';
import Comment from './Comment';
import ContactInfo from './ContactInfo';
import Education from './Education';
import Household from './Household';
import HouseholdMembers from './HouseholdMembers';
import MappedConnection from './MappedConnection';
import PotentialpathsNew from './PotentialpathsNew';
import Professional from './Professional';
import ProfileHeader from './ProfileHeader';
import ProfileHistory from './ProfileHistory';
import TalkingPoints from './TalkingPoints';
import TaxProfile from './TaxProfile';
import Triggers from './Triggers';

message.config({
  top: 80,
});
let source;
let currentB2bId, currentB2cId;
const Profile = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const [list, setList] = useState([]);
    const [potentialPathList, setPotentialPathList] = useState([]);
    const [houseHoldMembers, setHouseHoldMembers] = useState([]);
    const [mappedConnectionList, setMappedConnectionList] = useState([]);
    const [person, setPerson] = useState({});
    const [connectivityList, setConnectivityList] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [potentialProgress, setPotentialProgress] = useState(true);
    const [mappedProgress, setMappedProgress] = useState(true);
    const [crmValue, setCrmValue] = useState('');

    const scrollRef = useRef(null);
    const navigate = useNavigate();
    const flags = useFlags();
    let tagData = [];

    useEffect(() => {
      if (props.modalDataStore.trackModal.length === 0) {
        props.modalDataStore.trackModal.push({
          modal: 'profile',
          data: props.modalDataStore.profileModal, // first item in trackModal should keep whole profileModal
        });
      }
      /** close modal on browser back click, keeping the search criterias in advancedFilter.
       * so add same path in history to avoid url change.
       * browser back click event is handled in ModalView component  */
      window.history.pushState(null, null, window.location.pathname);
      const { crm, org_name } = props.dataStore.currentUser;
      setCrmValue(crm);
      fetchData();

      return () => {
        message.destroy();
        if (source) source.cancel('Operation canceled by the user.');
        // autoUpdateDisposer();

        if (
          tagData &&
          person.profile_history_details &&
          person.profile_history_details.tags_details
        ) {
          if (
            tagData.length !==
              person.profile_history_details.tags_details.length ||
            (tagData.length > 0 &&
              person.profile_history_details?.tags_details.length > 0 &&
              !person.profile_history_details.tags_details.every((tags) =>
                tagData.includes(tags.tag_name),
              ))
          ) {
            /** to refresh the list if tags added or removed*/
            props.modalDataStore.profileModal.fetchOnChange = true;
          }
        }
      };
    }, []);

    useEffect(() => {
      /** Scroll to top on loading new profiles from a profile,
       *  scroll only after spinner visibility gone, otherwise the scrollable div won't be accessible  */
      if (!isLoading && scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    }, [isLoading]);

    useEffect(() => {
      if (props.modalDataStore.profileModal.fetchProfileData) {
        setHouseHoldMembers([]);
        setMappedConnectionList([]);
        setPotentialPathList([]);
        setConnectivityList([]);
        setList([]);
        setPerson({});
        setPotentialProgress(true);
        setMappedProgress(true);
        fetchData();
      }
    }, [props.modalDataStore.profileModal.fetchProfileData]);

    const setProgress = (value) => {
      setMappedProgress(value);
    };

    const fetchData = () => {
      setIsLoading(true);
      const { b2bId, b2cId, eventsId, degree } =
        props.modalDataStore.profileModal;

      currentB2bId =
        b2bId && b2bId !== 'undefined' && b2bId !== 'null' ? b2bId : '';

      currentB2cId =
        b2cId && b2cId !== 'undefined' && b2cId !== 'null' ? b2cId : '';

      if (source) source.cancel('Operation canceled by the user.');

      const { CancelToken } = axios;
      source = CancelToken.source();

      fetchPersonalData();

      fetchHouseHoldMembersData(currentB2bId, currentB2cId);

      if (
        degree !== 0 ||
        (props.dataStore.networkFilters.relmap_org &&
          props.dataStore.networkFilters.relmap_org.length > 0)
      )
        fetchPotentialPaths(currentB2bId, currentB2cId);
      else setPotentialProgress(false);
      fetchConnectivityData(currentB2bId, currentB2cId);
      fetchMappedConnections(currentB2bId, currentB2cId);
      props.modalDataStore.profileModal.fetchProfileData = false;
    };

    function fetchPersonalData() {
      setIsLoading(true);
      const { token } = source;

      const params = {
        b2b_id: currentB2bId,
        b2c_id: currentB2cId,
      };

      let list = [];
      requestWithAuth(
        'network-profile',
        'GET',
        params,
        null,
        token,
        'progress-profile',
      ).then((response) => {
        setIsLoading(false);
        if (!response) return;

        const _person = response;
        if (
          _person.events &&
          _person.events.length > 0 &&
          Array.isArray(_person.events)
        ) {
          list = _person.events;
        }
        list = list || [];

        if (person.phones_verified) {
          if (_person.mobile_phones && _person.mobile_phones.length > 0) {
            _person.mobile_phones = _person.mobile_phones.filter(
              (key) => _person.phones_verified[key].verified !== false,
            );
          }
          if (_person.other_phones && _person.other_phones.length > 0) {
            _person.other_phones = _person.other_phones.filter(
              (key) =>
                key in _person.phones_verified &&
                _person.phones_verified[key].verified !== false,
            );
          }
        }

        if (
          _person.profile_history_details &&
          _person.profile_history_details.tags_details
        ) {
          tagData = _person.profile_history_details.tags_details.map(
            (tag) => tag.tag_name,
          );
        }

        setList(list);
        setPerson({ ..._person });
      });
    }

    function fetchPotentialPaths(b2bid, b2cid) {
      setPotentialProgress(true);
      const { token } = source;
      const targetB2bId = b2bid || '';
      const targetB2cId = b2cid || 0;

      const relmap = props.dataStore.networkFilters.relmap_org || [];
      let relmap_org = relmap.map((relmap) => {
        if (relmap) return relmap.label;
      });
      relmap_org = relmap_org.join('|');
      const url = 'potential-paths';
      const params = {
        target_b2c_id: targetB2cId,
        target_b2b_id: targetB2bId,
        page_size: 50,
      };
      if (relmap_org && relmap_org.length > 0) {
        params.relmap_org = relmap_org;
      }
      let potentialPathList = [];
      requestWithAuth(
        url,
        'GET',
        params,
        null,
        token,
        'progress-potential',
      ).then((response) => {
        if (response && response.count && response.count > 0) {
          if (response && response.results && response.results.length > 0)
            potentialPathList = response.results;
        }
        setPotentialPathList(potentialPathList);
        setPotentialProgress(false);
      });
    }

    function fetchConnectivityData(b2bid, b2cid) {
      const { token } = source;
      const targetB2bId = b2bid || '';
      const targetB2cId = b2cid === null || b2cid === undefined ? 0 : b2cid;

      if (targetB2bId === '' && targetB2cId === 0) return;
      let connectivityResponse = {};
      const params = {
        b2b_id: targetB2bId,
        b2c_id: targetB2cId,
      };

      const url = 'profile-connectivity';
      requestWithAuth(url, 'GET', params, null, token).then((response) => {
        if (!response) return;
        connectivityResponse = { ...connectivityResponse, ...response };
        setConnectivityList(connectivityResponse);
      });

      requestWithAuth('weather-talking-point', 'GET', params, null, token).then(
        (response) => {
          if (!response) return;
          connectivityResponse = { ...connectivityResponse, ...response };
          setConnectivityList(connectivityResponse);
        },
      );
    }

    function fetchHouseHoldMembersData(b2bId, b2cId) {
      if (!b2bId && !b2cId) {
        return;
      }
      let houseHoldMembers = [];
      const { token } = source;

      const params = {};

      if (b2bId) params.b2b_id = b2bId;
      if (b2cId) params.b2c_id = b2cId;

      requestWithAuth(
        'household-members-new',
        'GET',
        params,
        null,
        token,
        'progress-household',
      ).then((response) => {
        if (!response || !response.results) return;
        houseHoldMembers = response.results;
        setHouseHoldMembers(houseHoldMembers);
      });
    }

    function fetchMappedConnections(
      b2bid,
      b2cid,
      params = {
        sort_key: 'relationship_status',
        sort_order: 'asc',
        page_size: 20,
        page: 1,
      },
    ) {
      const targetB2bId = b2bid || '';
      const targetB2cId = b2cid || 0;
      const { profileModal } = props.modalDataStore;
      const searchObj = {};
      if (profileModal && profileModal.mappedRelationshipsFilter) {
        const {
          mappedSourceInferredSalary,
          mappedSourceWealthEstimate,
          mappedRelationshipWeights,
          mappedRelationshipTypes,
          mappedSourceLocationState,
          mappedSourceLocationCity,
          mappedSourceOrgname,
          mappedSourceTitle,
          mappedSourceSchool,
          pagination,
        } = profileModal.mappedRelationshipsFilter;

        if (mappedSourceInferredSalary && mappedSourceInferredSalary.length > 0)
          searchObj.inferred_salary_range = mappedSourceInferredSalary;

        if (
          mappedSourceWealthEstimate &&
          mappedSourceWealthEstimate.length > 0
        ) {
          const wealth_estimate_ranges = [];
          wealthScoreRanges.map((item) => {
            if (mappedSourceWealthEstimate.includes(item.value)) {
              wealth_estimate_ranges.push({
                range: {
                  wealth_estimate: {
                    gte: item.min,
                    lte: item.max,
                  },
                },
              });
            }
          });
          if (wealth_estimate_ranges.length > 0) {
            searchObj.wealth_estimate = wealth_estimate_ranges;
          }
        }

        if (mappedRelationshipWeights && mappedRelationshipWeights.length > 0)
          searchObj.mapped_relationship_weights = mappedRelationshipWeights;

        if (mappedRelationshipTypes && mappedRelationshipTypes.length > 0)
          searchObj.mapped_relationship_types = mappedRelationshipTypes;

        if (mappedSourceTitle) params.title = mappedSourceTitle;
        else delete params.title;

        if (mappedSourceOrgname) params.org_name = mappedSourceOrgname;
        else delete params.org_name;

        if (mappedSourceLocationCity)
          params.home_city = mappedSourceLocationCity;
        else delete params.home_city;

        if (mappedSourceLocationState)
          params.home_state = mappedSourceLocationState.toUpperCase();
        else delete params.home_state;

        if (pagination) {
          params.page_size = pagination.pageSize;
          params.page = pagination.current;
        }
      }

      if (targetB2bId === '' && targetB2cId === 0) return;
      setMappedProgress(true);
      const { token } = source;

      const body = targetB2bId
        ? { b2b_id: targetB2bId }
        : { b2c_id: targetB2cId };

      if (searchObj && Object.keys(searchObj).length > 0)
        body.search = searchObj;

      const url = `web-mapped-connections`;
      requestWithAuth(
        url,
        'POST',
        params
          ? params
          : {
              sort_key: 'relationship_status',
              sort_order: 'asc',
              page_size: 20,
              page: 1,
            },
        body,
        token,
        'progress-mapped-connections',
      ).then((response) => {
        if (!response) {
          setMappedConnectionList({ count: 0, results: [] });
          setMappedProgress(false);
          return;
        }
        setMappedProgress(false);
        if (response) setMappedConnectionList(response);
      });
    }

    const showOtherProfile = (b2bId, b2cId) => {
      // profile clicked from household, potential path ...

      if (!b2bId && !b2cId) {
        message.warning(
          'This profile cannot be opened right now. Please try again later.',
          10,
        );
        return;
      }

      props.modalDataStore.resetProfileModal(true);
      const { profileModal } = props.modalDataStore;
      profileModal.b2bId = b2bId;
      profileModal.b2cId = b2cId;
      profileModal.isVisible = true;

      props.modalDataStore.trackModal.push({
        modal: 'profile',
        data: {
          b2bId,
          b2cId,
        },
      });

      setHouseHoldMembers([]);
      setMappedConnectionList([]);
      setPotentialPathList([]);
      setConnectivityList([]);
      setList([]);
      setPerson({});
      setPotentialProgress(true);
      setMappedProgress(true);
      fetchData();
    };

    const showCompanyProfile = (id, orgName) => {
      if (!id) return;
      // click on any company name
      props.modalDataStore.resetProfileModal(true);
      props.modalDataStore.resetCompanyProfileModal();
      const { companyProfileModal } = props.modalDataStore;
      companyProfileModal.id = id;
      companyProfileModal.orgName = orgName;
      companyProfileModal.id = id;
      companyProfileModal.isVisible = true;
      props.modalDataStore.trackModal.push({
        modal: 'company',
        data: { id, orgName },
      });
    };

    const toggleProgress = (show) => {
      setIsLoading(show);
    };

    const onCrmTokenExpiry = (url) => {
      navigate('/connect/export', {
        state: {
          crmSource: url === 'redtail-auth/0' ? 'rt' : 'cm',
          response: url === 'redtail-auth/0' ? 'rt' : 'cm',
        },
      });
    };

    // Method to handle yes click of export csv
    const handleCrmPopupOk = (clubCode) => {
      const n2IdArray = [];
      const body = {};
      if (!currentB2bId && !currentB2cId) {
        message.warning('This lead cannot be pushed to CRM', 5);
        return;
      }
      if (currentB2bId) n2IdArray.push(currentB2bId);
      if (currentB2cId) n2IdArray.push(currentB2cId);
      body.n2_person = n2IdArray;

      if (props.dataStore.currentUser.org_name)
        body.org_name = props.dataStore.currentUser.org_name;

      if (clubCode) {
        body.club_code = clubCode.value;
      }
      setIsLoading(true);

      // handleCrmClick - is a method in utilis/crmsync.js
      // if any crm is integrated - call the method, else redirect to crm import page.
      // when handleCrmSync returns , it enteres the then part of promise and makes the progress bar invisible,
      // also unchecks the selected checkboxes.
      if (crmValue) {
        handleCrmSync(
          props.dataStore.currentUser.ownerId,
          crmValue,
          body,
          onCrmTokenExpiry,
        ).then(() => {
          setIsLoading(false);
        });
      } else {
        navigate('/connect/export');
      }
    };

    const onDataVerified = (emails, key) => {
      person[key] = emails;
      setPerson({ ...person });
    };

    const onMappedConnectionAllClick = (name) => {
      const { profileModal } = props.modalDataStore;
      props.dataStore.resetNetworkFilters();

      props.dataStore.mappedRelationshipsFilter = {
        ...profileModal.mappedRelationshipsFilter,
      };

      if (!props.dataStore.lookUpLists.isPeoplePage)
        navigate('/prospect-finder/people');
      props.dataStore.networkFilters.fetchData = true;
      profileModal.isVisible = false;
      props.modalDataStore.resetAll();
    };

    const onZipClick = (zipCode, zip4) => {
      const { profileModal } = props.modalDataStore;
      let zip = zipCode;
      if (zip4) {
        zip = `${zip}-${zip4}`;
      }
      props.dataStore.zipCodeFromProfile = {
        key: 'exact',
        value: [zip, zip],
      };
      if (!props.dataStore.lookUpLists.isPeoplePage)
        navigate('/prospect-finder/people');
      props.dataStore.networkFilters.fetchData = true;
      profileModal.isVisible = false;
      props.modalDataStore.resetAll();
    };

    const updatePerson = (key, data) => {
      switch (key) {
        case 'user_comment_details': {
          const historyDetails = person.profile_history_details || {};
          if (!historyDetails.user_comment_details) {
            historyDetails.user_comment_details = [];
          }
          historyDetails.user_comment_details.push(data);
          person.profile_history_details = { ...historyDetails };
          break;
        }
        case 'note_details': {
          const historyDetails = person.profile_history_details || {};
          if (!historyDetails.note_details) {
            historyDetails.note_details = {
              created: '',
              updated: '',
            };
          }
          historyDetails.note_details.note = data;
          person.profile_history_details = { ...historyDetails };
          break;
        }
        case 'tags_details': {
          const historyDetails = person.profile_history_details || {};
          historyDetails[key] = data;
          person.profile_history_details = { ...historyDetails };
          break;
        }
        default:
          person[key] = data;
          break;
      }
      setPerson({ ...person });
    };

    return (
      <div className="loader-wrapper">
        {isLoading && <LoaderAnimation loaderId="profile-loader" />}
        <div
          className={
            isLoading
              ? 'profile-wrapper aid-div-disable'
              : 'profile-wrapper aid-div-enable'
          }
        >
          <ProfileHeader
            person={{ ...person }}
            connectivity={connectivityList.connectivity_strength}
            showLoading={toggleProgress}
            crmOk={handleCrmPopupOk}
            updatePerson={updatePerson}
            onBackArrowClick={props.onBackArrowClick}
          />
          <div className="scroll-container">
            <div className="profile-body" ref={scrollRef}>
              {person && person.date_of_death && (
                <div
                  style={{
                    color: '#808184',
                    fontSize: 14,
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <WarningIcon height={18} />
                  You are viewing the profile of a deceased person - the
                  information on this profile might become less comprehensive
                  and relevant over time
                </div>
              )}
              <TalkingPoints
                connectivityList={connectivityList}
                openProfileTab={showOtherProfile}
              />
              {(potentialProgress ||
                (potentialPathList && potentialPathList.length > 0)) && (
                <PotentialpathsNew
                  potentialPathList={potentialPathList}
                  openProfileTab={showOtherProfile}
                  isLoading={potentialProgress}
                  showCompanyProfile={showCompanyProfile}
                  isRelmapOrg={
                    props.dataStore.networkFilters.relmap_org &&
                    props.dataStore.networkFilters.relmap_org.length > 0 &&
                    props.modalDataStore.trackModal.length === 1
                  }
                />
              )}
              <div
                style={{
                  display: 'flex',
                  gap: '21px',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ width: '100%' }}>
                  <Household person={person} toggleProgress={toggleProgress} />
                  <ContactInfo
                    person={person}
                    toggleProgress={toggleProgress}
                    onDataVerified={onDataVerified}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <Professional
                    person={person}
                    showCompanyProfile={showCompanyProfile}
                    toggleProgress={toggleProgress}
                  />
                  <Education person={person} toggleProgress={toggleProgress} />
                </div>
              </div>
              <TaxProfile person={person} handleViewAllClick={onZipClick} />
              {mappedConnectionList && (
                <>
                  <MappedConnection
                    mappedConnectionResults={mappedConnectionList}
                    showProfile={showOtherProfile}
                    showCompanyProfile={showCompanyProfile}
                    name={person.full_name}
                    handleViewAllClick={onMappedConnectionAllClick}
                    onTableChange={fetchMappedConnections}
                    b2bId={currentB2bId}
                    b2cId={currentB2cId}
                    mappedLoading={mappedProgress}
                    setProgress={setProgress}
                  />
                </>
              )}
              {houseHoldMembers && houseHoldMembers.length > 0 && (
                <HouseholdMembers
                  houseHoldMemberslist={houseHoldMembers}
                  showProfile={showOtherProfile}
                  showCompanyProfile={showCompanyProfile}
                />
              )}

              {list && list.length > 0 && (
                <Triggers
                  list={list}
                  showHelpModal={() => {
                    props.modalDataStore.eventHelpModalVisible = true;
                  }}
                />
              )}
              <div className="history-comment-section">
                <Comment person={person} onCommentSend={updatePerson} />
                {person &&
                  person.profile_history_details &&
                  Object.keys(person.profile_history_details).length > 0 && (
                    <ProfileHistory
                      historyData={person.profile_history_details}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }),
);

export default Profile;
