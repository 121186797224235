import './index.scss';
import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import EditIcon from '../../assets/icons/ai_edit.svg?react';
import DownArrow from '../../assets/icons/ai_ic_down.svg?react';
import TagsList from './TagsList';
import NoFolderView from './NoFolderView';
import FolderView from './FolderView';
import TagsEditView from './TagsEditView';
import FolderEditView from './FolderEditView';

const TagManagement = inject('dataStore')(
  observer((props) => {
    const {
      userTagFolders,
      fetchUserTags,
      fetchTagFolders,
      checkedTags = [],
      handleTagsCheck,
      handleEditTags,
      handleEditFolder,
      showTagsEdit,
      showFolderEdit,
    } = props;

    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
      const modalScrollBody = document.querySelector('.ant-modal-body');
      const footer = document.querySelector('.ant-modal-footer');
      modalScrollBody.onscroll = (e) => {
        const target = e.target;
        if (
          footer &&
          target &&
          target.scrollTop + target.clientHeight + 5 >= target.scrollHeight
        )
          footer.style.boxShadow = 'none';
        else {
          footer.style.boxShadow = ' 0px -58px 33px 5px #ffffff';
        }
      };
    }, []);

    if (showTagsEdit) {
      return (
        <TagsEditView
          handleEditTags={handleEditTags}
          fetchUserTags={fetchUserTags}
          fetchTagFolders={fetchTagFolders}
        />
      );
    }

    if (showFolderEdit) {
      return (
        <FolderEditView
          folderId={showFolderEdit}
          fetchTagFolders={fetchTagFolders}
          fetchUserTags={fetchUserTags}
        />
      );
    }

    return (
      <div>
        <div className="title">
          {props.title ? props.title : 'Your tags & folders'}
        </div>
        {userTagFolders &&
          userTagFolders.tag_folders &&
          userTagFolders.tag_folders.length > 0 && (
            <div className="description">
              Turn on/off tags here to filter the prospects list using your
              tags. Use folders for better tag organization.
            </div>
          )}
        {userTagFolders &&
          userTagFolders.orphan_tags &&
          userTagFolders.orphan_tags.length > 0 && (
            <div className="orphan-tags">
              <div className="sub-title-bar">
                <div>
                  Recently used tags ({userTagFolders.orphan_tags.length}){' '}
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEditTags(true)}
                  >
                    <EditIcon style={{ margin: '0 10px' }} />
                  </span>
                </div>
                <div
                  className="see-all-text"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setShowAll(!showAll);
                  }}
                >
                  <DownArrow
                    className="down-arrow"
                    style={{
                      transform: showAll ? ' rotate(180deg)' : 'rotate(0deg)',
                      marginBottom: '-1px',
                    }}
                  />
                  {showAll ? 'See less' : 'See all'}
                </div>
              </div>
              <div>
                <TagsList
                  tags={userTagFolders.orphan_tags}
                  checkedTags={checkedTags}
                  handleTagsCheck={handleTagsCheck}
                  showCreateTag
                  count={showAll ? null : 6}
                  fetchTagFolders={fetchTagFolders}
                />
              </div>
              {(!userTagFolders.orphan_tags ||
                userTagFolders.orphan_tags.length <= 3 ||
                showAll) && (
                <div style={{ marginTop: '20px' }}>
                  <TagsList
                    sharedTags
                    tags={userTagFolders.shared_tags}
                    checkedTags={checkedTags}
                    handleTagsCheck={handleTagsCheck}
                    count={10}
                    fetchTagFolders={fetchTagFolders}
                  />
                </div>
              )}
            </div>
          )}
        {userTagFolders &&
          userTagFolders.tag_folders &&
          userTagFolders.tag_folders.length > 0 && (
            <FolderView
              folders={userTagFolders.tag_folders}
              onEditClick={handleEditFolder}
              checkedTags={checkedTags}
              handleTagsCheck={handleTagsCheck}
              fetchTagFolders={fetchTagFolders}
            />
          )}
        {(!userTagFolders ||
          !userTagFolders.tag_folders ||
          userTagFolders.tag_folders.length <= 0) && (
          <NoFolderView fetchTagFolders={fetchTagFolders} />
        )}
      </div>
    );
  }),
);

export default TagManagement;
