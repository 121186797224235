import moment from 'moment';
import dayjs from 'dayjs';
import {
  extractYear,
  formatPropertyAttributes,
  getDateRangeFromPeriod,
  getNewPropertyValueRange,
} from './general';

export function getBodyParams(
  dataStoreFilters,
  lookUpLists,
  superConnectorFilter,
  teamFilters,
  currentUser,
  mappedRelationshipsFilter,
  profileModal,
  groupByValues,
  fromMapPage = false,
  profileFilter = false,
) {
  const {
    full_name,
    home_search,
    home_address_suggest,
    exc_home_address_suggest,
    county,
    age,
    gender,
    marital_status,
    presence_of_children,
    employee_count,
    number_of_mapped_connections,
    stock_performance,
    interests,
    org_name,
    exc_current_experience,
    previous_experience_org_name,
    current_org_years,
    total_years_of_experience,
    relmap_org,
    industry,
    exc_industry,
    credentials,
    title_rank,
    title,
    exc_title_rank,
    exc_title,
    title_role,
    schools,
    import_source,
    file_imports,
    team_file_imports,
    filter_type,
    company_search,
    inferred_salary_range,
    wealth_estimate,
    // latest_mim_monetary_value_range,
    wealth_estimate_range,
    latest_mim_event_type,
    latest_mim_monetary_value,
    tags_checked,
    shared_tags,
    attributes_checked,
    wealth_model_checked,
    attribute_function,
    all_interests,
    location_state,
    home_postal_code,
    all_homes,
    location_city,
    funding_round,
    m2_date,
    period,
    event_org_name,
    management_change_date_range,
    company_type,
    mapQuery,
    home_value_estimate,
    property_type,
    home_purchase_date,
    home_sale_date,
    norm_home_owner,
    is_elevator_included,
    garage_cars,
    number_of_owned_properties,
    is_pool_included,
    number_of_stories,
    number_of_baths,
    number_of_bedrooms,
    number_of_partial_baths,
    total_number_of_rooms,
    lotsize_square_feet,
    number_of_units,
    effective_year_built,
    remaining_share_value_sum,
    naics_codes,
    slider_radius,
    irs_tax_insights,
    mapped_relationships_filter,
    event_id,
    mimperson_id,
    daily_opportunities_id,
    daily_opportunities_date,
  } = dataStoreFilters;
  const body = {};
  const search = {};

  /** Export all from mapped relationship in profile.
   * If we add this filter, no other filters are needed.
   * That's why returning body.
   */
  if (profileModal && profileModal.mappedRelationshipsFilter.filterName) {
    const {
      // filterName,
      mappedSourceB2bId,
      mappedSourceB2cId,
      mappedSourceInferredSalary,
      mappedSourceWealthEstimate,
      mappedSourceLocationState,
      mappedSourceLocationCity,
      mappedSourceOrgname,
      mappedSourceTitle,
      mappedSourceSchool,
      mappedRelationshipWeights,
      mappedRelationshipTypes,
    } = profileModal.mappedRelationshipsFilter;
    if (mappedSourceB2bId) search.mapped_source_b2b_id = mappedSourceB2bId;
    if (mappedSourceB2cId) search.mapped_source_b2c_id = mappedSourceB2cId;
    // if (
    //   profileModal.mappedRelationshipsFilter.mappedSourceInferredSalary &&
    //   profileModal.mappedRelationshipsFilter.mappedSourceInferredSalary.length >
    //     0
    // ) {
    //   search.inferred_salary_range =
    //     profileModal.mappedRelationshipsFilter.mappedSourceInferredSalary;
    // }
    // if (
    //   profileModal.mappedRelationshipsFilter.mappedSourceWealthEstimate &&
    //   profileModal.mappedRelationshipsFilter.mappedSourceWealthEstimate.length >
    //     0
    // ) {
    //   search.wealth_estimate =
    //     profileModal.mappedRelationshipsFilter.mappedSourceWealthEstimate;
    // }

    if (mappedRelationshipTypes && mappedRelationshipTypes.length) {
      search.mapped_relationship_types = mappedRelationshipTypes;
    }
    if (mappedRelationshipWeights && mappedRelationshipWeights.length) {
      search.mapped_relationship_weights = mappedRelationshipWeights;
    }

    if (mappedSourceWealthEstimate && mappedSourceWealthEstimate.length) {
      const wealthArray = [];
      mappedSourceWealthEstimate.forEach((wealthScore) => {
        const wealthEstimate = {};
        if (wealthScore) {
          if (wealthScore.min !== null) wealthEstimate.gte = wealthScore.min;
          if (wealthScore.max !== null) wealthEstimate.lte = wealthScore.max;
          wealthArray.push({
            range: {
              wealth_estimate: wealthEstimate,
            },
          });
        }
      });
      search.wealth_estimate = wealthArray;
    }

    if (mappedSourceInferredSalary && mappedSourceInferredSalary.length) {
      mappedSourceInferredSalary.push(null);
      search.inferred_salary_range = mappedSourceInferredSalary.filter(
        (salaryRange) => {
          return salaryRange !== null;
        },
      );
    }

    if (mappedSourceLocationState) {
      search.location_state = [mappedSourceLocationState.toUpperCase()];
    }

    if (mappedSourceLocationCity) {
      search.location_city = [mappedSourceLocationCity];
    }

    if (mappedSourceTitle) {
      search.title = {
        exact: [],
        prefix: [mappedSourceTitle],
      };
    }

    if (mappedSourceSchool && mappedSourceSchool.length) {
      const schoolFilter = [];
      const schoolFilterAdded = [];
      mappedSourceSchool.forEach((school) => {
        if (school && school.__isNew__) {
          schoolFilterAdded.push(school.value);
        } else {
          schoolFilter.push(school.value);
        }
      });
      search.schools = {
        exact: schoolFilter,
        prefix: schoolFilterAdded,
      };
    }
    if (mappedSourceOrgname) {
      search.org_contains = [mappedSourceOrgname];
    }
    body.search = search;
    return body;
  }

  if (
    mapped_relationships_filter &&
    mapped_relationships_filter.mapped_source_filter_name
  ) {
    search.mapped_source_b2b_id =
      mapped_relationships_filter.mapped_source_b2b_id || '';
    search.mapped_source_b2c_id =
      mapped_relationships_filter.mapped_source_b2c_id || '';
    if (
      mapped_relationships_filter.mapped_relationship_weights &&
      mapped_relationships_filter.mapped_relationship_weights.length
    ) {
      search.mapped_relationship_weights =
        mapped_relationships_filter.mapped_relationship_weights;
    }
    if (
      mapped_relationships_filter.mapped_relationship_types &&
      mapped_relationships_filter.mapped_relationship_types.length
    ) {
      search.mapped_relationship_types =
        mapped_relationships_filter.mapped_relationship_types;
    }
    if (profileFilter) {
      /** inorder to keep the profile name of the mapped connections chosen from master filter.
       * Need to pass this only in filter save api (multiple-filters)
       */
      search.mapped_source_filter_name =
        mapped_relationships_filter.mapped_source_filter_name;
    }
  }

  if (!profileFilter && superConnectorFilter && superConnectorFilter.b2bId) {
    body.super_connector = superConnectorFilter.b2bId;
  }

  /** Redirected from a news letter or email */
  if (event_id) {
    search.daily_opportunities_event_id = event_id;
  }
  if (mimperson_id) {
    search.daily_opportunities_person_id = mimperson_id;
  }
  if (daily_opportunities_id) {
    search.daily_opportunities_id = daily_opportunities_id;
  }
  if (daily_opportunities_date) {
    search.daily_opportunities_date = daily_opportunities_date;
  }

  if (full_name) {
    search.full_name = full_name;
  }

  if (location_city && location_city.length) {
    search.location_city = location_city
      .map((city) => city?.value)
      .filter(Boolean);
  }

  if (location_state && location_state.length) {
    search.location_state = location_state
      .map((state) => state?.value)
      .filter(Boolean);
  }

  if (org_name && org_name.length) {
    const orgName = [];
    const orgId = [];
    const orgContains = [];
    org_name.forEach((org) => {
      if (org.__isNew__) {
        orgContains.push(org.value);
      } else if (
        org.value &&
        org.label &&
        org.value.toLowerCase() === org.label.toLowerCase()
      ) {
        orgName.push(org.value);
      } else {
        const orgObj = {};
        orgObj.ai_org_id = org.value;
        orgObj.name = org.label;
        orgId.push(orgObj);
      }
    });
    if (orgContains && orgContains.length) {
      search.org_contains = orgContains;
    }
    if (orgName && orgName.length) {
      search.org_name = orgName;
    }
    if (orgId && orgId.length) {
      search['current_experience.ai_org_id'] = orgId;
    }
  }

  if (exc_current_experience && exc_current_experience.length) {
    const orgId = [];
    const orgContains = [];

    exc_current_experience.forEach((org) => {
      if (org.__isNew__) {
        orgContains.push(org.value);
      } else {
        const orgObj = {};
        orgObj.ai_org_id = org.value;
        orgObj.name = org.label;
        orgId.push(orgObj);
      }
    });
    if (orgContains && orgContains.length) {
      search.exc_org_contains = orgContains;
    }
    if (orgId && orgId.length) {
      search['exc_current_experience.ai_org_id'] = orgId;
    }
  }

  if (title && title.length) {
    const titleFilter = [];
    const titleFilterAdded = [];
    title.forEach((titles) => {
      if (titles && titles.__isNew__) {
        titleFilterAdded.push(titles.value);
      } else {
        titleFilter.push(titles.value);
      }
    });
    search.title = {
      exact: titleFilter,
      prefix: titleFilterAdded,
    };
  }

  if (schools && schools.length) {
    const schoolFilter = [];
    const schoolFilterAdded = [];
    schools.forEach((school) => {
      if (school && school.__isNew__) {
        schoolFilterAdded.push(school.value);
      } else {
        schoolFilter.push(school.value);
      }
    });
    search.schools = {
      exact: schoolFilter,
      prefix: schoolFilterAdded,
    };
  }

  /** Household Section */
  if (all_homes) search.all_homes = all_homes;

  if (county && county.length) {
    search.county = county.map((item) => item?.value).filter(Boolean);
  }

  let zipValue;

  if (home_postal_code) zipValue = JSON.parse(JSON.stringify(home_postal_code));
  if (
    zipValue &&
    zipValue.key &&
    zipValue.value &&
    zipValue.value.length > 1 &&
    (zipValue.value[0] || zipValue.value[1])
  ) {
    const zipCode = {};
    const zip4 = {};

    zipValue.value.sort(function (a, b) {
      return a - b;
    });

    const zip1 = zipValue.value[0].split('-');
    const zip2 = zipValue.key === 'exact' ? zip1 : zipValue.value[1].split('-');

    zipCode.gte = zip1[0] || 0;
    zipCode.lte = zip2[0] || 0;

    zip4.gte = zip1[1] || null;
    zip4.lte = zip2[1] || null;

    search.home_postal_code = {
      range: {
        home_postal_code: zipCode,
      },
    };

    if (zip4.gte || zip4.lte) {
      search.home_zip4 = {
        range: {
          home_zip4: zip4,
        },
      };
    }
  }

  const range_list = [];
  if (age) {
    const ageValue = JSON.parse(JSON.stringify(age));
    if (
      ageValue &&
      ageValue.key &&
      ageValue.value &&
      ageValue.value.length > 1 &&
      (ageValue.value[0] || ageValue.value[1])
    ) {
      const age = {};
      ageValue.value.sort(function (a, b) {
        return a - b;
      });
      age.gte = ageValue.value[0] || 0;
      age.lte =
        ageValue.key === 'exact'
          ? ageValue.value[0] || 0
          : ageValue.value[1] || 0;
      range_list.push({
        range: {
          age,
        },
      });
    }
  }
  if (search && range_list && range_list.length) search.range_list = range_list;

  if (gender && gender.value) {
    search.gender = gender.value;
  }

  if (marital_status && marital_status.value) {
    search.marital_status = marital_status.value;
  }

  if (presence_of_children && presence_of_children.value !== null) {
    search.presence_of_children = presence_of_children.value;
  }

  if (home_address_suggest && home_address_suggest.length) {
    const addressFilter = [];
    const addressFilterAdded = [];
    home_address_suggest.forEach((address) => {
      if (address && address.__isNew__) {
        addressFilterAdded.push(address.value);
      } else {
        addressFilter.push(address.value);
      }
    });
    search.home_address_suggest = {
      exact: addressFilter,
      prefix: addressFilterAdded,
    };
  }
  if (exc_home_address_suggest && exc_home_address_suggest.length) {
    const addressFilter = [];
    const addressFilterAdded = [];
    exc_home_address_suggest.forEach((address) => {
      if (address && address.__isNew__) {
        addressFilterAdded.push(address.value);
      } else {
        addressFilter.push(address.value);
      }
    });
    search.exc_home_address_suggest = {
      exact: addressFilter,
      prefix: addressFilterAdded,
    };
  }

  if (home_search && home_search.geo) {
    search.home_search = {
      geo_distance: {
        distance: !slider_radius ? '16km' : slider_radius * 1.6 + 'km',
        address: home_search.address,
        b2c_geo: `${home_search.geo.lat}, ${home_search.geo.lng}`,
        distance_type: 'plane',
      },
    };
  }

  if (interests && interests.length > 0) {
    search.all_interests = all_interests || false;
    interests.forEach((interest) => {
      search[interest.value] = true;
    });
  }

  /** Household ends */

  /** Professional */

  if (credentials && credentials.length) {
    search.credentials = credentials.map((credential) => {
      return credential.value;
    });
  }

  if (title_rank && title_rank.length) {
    search.title_rank = title_rank.map((title) => {
      return title.value;
    });
  }

  if (exc_title_rank && exc_title_rank.length) {
    search.exc_title_rank = exc_title_rank.map((title) => {
      return title.value;
    });
  }

  if (exc_title && exc_title.length) {
    const titleFilter = [];
    const titleFilterAdded = [];
    exc_title.forEach((titles) => {
      if (titles && titles.__isNew__) {
        titleFilterAdded.push(titles.value);
      } else {
        titleFilter.push(titles.value);
      }
    });
    search.exc_title = {
      exact: titleFilter,
      prefix: titleFilterAdded,
    };
  }

  if (title_role && title_role.length) {
    const titleList = title_role.map((title) => {
      return title.value;
    });
    search.title_role = {
      exact: titleList,
      prefix: [],
    };
  }

  if (previous_experience_org_name && previous_experience_org_name.length) {
    const orgName = [];
    const orgId = [];
    previous_experience_org_name.forEach((org) => {
      if (org.value.toLowerCase() === org.label.toLowerCase()) {
        orgName.push(org.value);
      } else {
        const orgObj = {};
        orgObj.ai_org_id = org.value;
        orgObj.name = org.label;
        orgId.push(orgObj);
      }
    });
    if (orgName && orgName.length) {
      search['previous_experience.org_name'] = orgName;
    }
    if (orgId && orgId.length) {
      search['previous_experience.ai_org_id'] = orgId;
    }
  }
  if (total_years_of_experience) {
    const yearsOfExp = JSON.parse(JSON.stringify(total_years_of_experience));
    if (
      yearsOfExp &&
      yearsOfExp.key &&
      yearsOfExp.value &&
      yearsOfExp.value.length > 1 &&
      (yearsOfExp.value[0] || yearsOfExp.value[1])
    ) {
      const years = {};
      yearsOfExp.value.sort(function (a, b) {
        return a - b;
      });
      years.gte = yearsOfExp.value[0] || 0;
      years.lte =
        yearsOfExp.key === 'exact'
          ? yearsOfExp.value[0] || 0
          : yearsOfExp.value[1] || 0;
      search.total_years_of_experience = [
        {
          range: {
            total_years_of_experience: years,
          },
        },
      ];
    }
  }

  if (current_org_years) {
    const yearsOfExp = JSON.parse(JSON.stringify(current_org_years));
    if (
      yearsOfExp &&
      yearsOfExp.key &&
      yearsOfExp.value &&
      yearsOfExp.value.length > 1 &&
      (yearsOfExp.value[0] || yearsOfExp.value[1])
    ) {
      const years = {};
      yearsOfExp.value.sort(function (a, b) {
        return a - b;
      });
      years.gte = yearsOfExp.value[0] || 0;
      years.lte =
        yearsOfExp.key === 'exact'
          ? yearsOfExp.value[0] || 0
          : yearsOfExp.value[1] || 0;
      search.current_org_years = [
        {
          range: {
            current_org_years: years,
          },
        },
      ];
    }
  }

  if (inferred_salary_range && inferred_salary_range.length) {
    search.inferred_salary_range = inferred_salary_range
      .map((salaryRange) => salaryRange.value)
      .filter(Boolean);
  }

  if (wealth_estimate && wealth_estimate.length) {
    const wealthArray = [];
    wealth_estimate.forEach((wealthScore) => {
      const wealthEstimate = {};
      if (wealthScore) {
        if (wealthScore.min !== null) wealthEstimate.gte = wealthScore.min;
        if (wealthScore.max !== null) wealthEstimate.lte = wealthScore.max;
        wealthArray.push({
          range: {
            wealth_estimate: wealthEstimate,
          },
        });
      }
    });
    search.wealth_estimate = wealthArray;
  } else if (wealth_estimate_range) {
    const WealthSegmentRange = JSON.parse(
      JSON.stringify(wealth_estimate_range),
    );
    if (
      WealthSegmentRange &&
      WealthSegmentRange.key &&
      WealthSegmentRange.value &&
      WealthSegmentRange.value.length > 1 &&
      (WealthSegmentRange.value[0] || WealthSegmentRange.value[1])
    ) {
      const wealthRange = {};
      WealthSegmentRange.value.sort(function (a, b) {
        return a - b;
      });
      wealthRange.gte = WealthSegmentRange.value[0]
        ? parseInt(WealthSegmentRange.value[0].toString().replaceAll(',', ''))
        : 0;
      wealthRange.lte =
        WealthSegmentRange.key === 'exact'
          ? WealthSegmentRange.value[0]
            ? parseInt(
                WealthSegmentRange.value[0].toString().replaceAll(',', ''),
              )
            : 0
          : WealthSegmentRange.value[1]
            ? parseInt(
                WealthSegmentRange.value[1].toString().replaceAll(',', ''),
              )
            : 0;
      search.wealth_estimate = [
        {
          range: {
            wealth_estimate: wealthRange,
          },
        },
      ];
    }
  }

  if (remaining_share_value_sum && remaining_share_value_sum.length) {
    const remainingShareValue = [];
    remaining_share_value_sum.forEach((remainingShareObj) => {
      const remainingShare = {};
      if (remainingShareObj) {
        if (remainingShareObj.min !== null)
          remainingShare.gte = remainingShareObj.min;
        if (remainingShareObj.max !== null)
          remainingShare.lte = remainingShareObj.max;
        remainingShareValue.push({
          range: {
            remaining_share_value_sum: remainingShare,
          },
        });
      }
    });
    search.remaining_share_value_sum = remainingShareValue;
  }

  /** Professional ends */

  /** Relationships */
  if (number_of_mapped_connections && number_of_mapped_connections.length > 0) {
    const mappedConnectionsCount = [];
    number_of_mapped_connections.forEach((count) => {
      if (count.valueObject && count.valueObject.gte) {
        mappedConnectionsCount.push({
          range: {
            number_of_mapped_connections: { ...count.valueObject },
          },
        });
      }
    });
    if (search && mappedConnectionsCount && mappedConnectionsCount.length > 0) {
      search.number_of_mapped_connections = mappedConnectionsCount;
    }
  }

  /** Relationships ends */

  if (
    irs_tax_insights &&
    irs_tax_insights.key &&
    irs_tax_insights.lte &&
    irs_tax_insights.gte
  ) {
    const rangeValues = [
      irs_tax_insights.gte === -1
        ? null
        : irs_tax_insights.gte.replaceAll(',', ''),
      irs_tax_insights.lte === -1
        ? null
        : irs_tax_insights.lte.replaceAll(',', ''),
    ];
    if (rangeValues[0] && rangeValues[1] && rangeValues[0] !== rangeValues[1]) {
      rangeValues.sort((a, b) => {
        return a - b;
      });
    }
    search[irs_tax_insights.key] = {
      range: {
        [irs_tax_insights.key]: {
          gte: rangeValues[0],
          lte: rangeValues[1],
        },
      },
    };
  }

  /** Company */

  if (industry && industry.length) {
    search.industry = industry.map((ind) => ind?.value).filter(Boolean);
  }

  if (exc_industry && exc_industry.length) {
    search.exc_industry = exc_industry.map((ind) => ind?.value).filter(Boolean);
  }

  if (company_type && company_type.value !== '') {
    search.company_type = company_type.value;
  }

  if (!lookUpLists.isMapPage && stock_performance && stock_performance.length) {
    search.stock_performance = stock_performance.map((count) => {
      return count.value.split(', ');
    });
  }

  if (employee_count && employee_count.length) {
    search.employee_count = employee_count.map((count) => {
      return count.value;
    });
  }

  if (relmap_org && relmap_org.length) {
    search.relmap_org = relmap_org.map((org) => org?.label).filter(Boolean);
  }

  /** keeping label-value pair inorder to prefill in ui in saved filter  */
  if (profileFilter && relmap_org && relmap_org.length) {
    search.relmap_org_ui = relmap_org;
  }

  /** Company ends */

  /** Triggers */

  if (latest_mim_event_type && latest_mim_event_type.length) {
    search.latest_mim_event_type = latest_mim_event_type.map((event) => {
      return event.value;
    });
  }

  if (funding_round && funding_round.length) {
    search.funding_round = funding_round
      .map((fundinground) => fundinground?.value)
      .filter(Boolean);
  }

  // if (latest_mim_monetary_value) {
  //   const monetaryRange = JSON.parse(JSON.stringify(latest_mim_monetary_value));
  //   if (
  //     monetaryRange &&
  //     monetaryRange.key &&
  //     monetaryRange.value &&
  //     monetaryRange.value.length > 1 &&
  //     (monetaryRange.value[0] || monetaryRange.value[1])
  //   ) {
  //     const propertyValue = {};
  //     /** Inorder to support the data from old filter in which the value is a Number */
  //     monetaryRange.value[0] = monetaryRange.value[0]
  //       ? monetaryRange.value[0].toString().replaceAll(',', '')
  //       : 0;
  //     monetaryRange.value[1] = monetaryRange.value[1]
  //       ? monetaryRange.value[1].toString().replaceAll(',', '')
  //       : 0;

  //     monetaryRange.value.sort(function (a, b) {
  //       return a - b;
  //     });

  //     propertyValue.gte = monetaryRange.value[0];
  //     propertyValue.lte =
  //       monetaryRange.key === 'exact'
  //         ? monetaryRange.value[0]
  //         : monetaryRange.value[1];
  //     search.latest_mim_monetary_value = [
  //       {
  //         range: {
  //           'events.event_monetary_value': propertyValue,
  //         },
  //       },
  //     ];
  //   }
  // }

  // if (
  //   latest_mim_monetary_value_range &&
  //   latest_mim_monetary_value_range.length
  // ) {
  //   const rangeArray = [];
  //   latest_mim_monetary_value_range.forEach((range) => {
  //     const rangeObj = {};
  //     if (range) {
  //       if (range.min !== null) rangeObj.gte = range.min;
  //       if (range.max !== null) rangeObj.lte = range.max;
  //       rangeArray.push({
  //         range: {
  //           'events.event_monetary_value': rangeObj,
  //         },
  //       });
  //     }
  //   });
  //   if (search.latest_mim_monetary_value)
  //     search.latest_mim_monetary_value.push(...rangeArray);
  //   else search.latest_mim_monetary_value = rangeArray;
  // }

  if (latest_mim_monetary_value && latest_mim_monetary_value.length) {
    const rangeArray = [];
    latest_mim_monetary_value.forEach((range) => {
      const rangeObj = {};
      if (range) {
        if (range.min !== null) rangeObj.gte = range.min;
        if (range.max !== null) rangeObj.lte = range.max;
        rangeArray.push({
          range: {
            'events.event_monetary_value': rangeObj,
          },
        });
      }
    });
    search.latest_mim_monetary_value = rangeArray;
  }

  if (home_purchase_date && home_purchase_date.length) {
    const purchaseDateRange = [];
    home_purchase_date.forEach((date) => {
      if (date) purchaseDateRange.push(dayjs(date).format('YYYY-MM-DD'));
    });
    search.home_purchase_date = purchaseDateRange;
  }
  if (home_sale_date && home_sale_date.length) {
    const saleDateRange = [];
    home_sale_date.forEach((date) => {
      if (date) saleDateRange.push(dayjs(date).format('YYYY-MM-DD'));
    });
    search.home_sale_date = saleDateRange;
  }

  if (period && period.value) {
    /** Passing period to api only when it is from master filter. This is to know if
     *  m2_date is from period or timeframe.
     * When there is period we need to keep m2_date also as in RI page
     * back end is applying the filter */

    if (profileFilter) search.period = period.value;
    search.m2_date = getDateRangeFromPeriod(period.value);
  } else if (m2_date && m2_date.length) {
    const m2dateRange = [];
    m2_date.forEach((date) => {
      if (date) m2dateRange.push(dayjs(date).format('YYYY-MM-DD'));
    });
    search.m2_date = m2dateRange;
  }

  const management_changes = {};

  if (management_change_date_range && management_change_date_range.length) {
    const dateRangeManagement = [];
    management_change_date_range.forEach((date) => {
      if (date) dateRangeManagement.push(moment(date).format('YYYY-MM-DD'));
    });
    management_changes.management_change_date_range = dateRangeManagement;
    body.management_changes = management_changes;
  }

  /** Triggers ends */

  /** Property Attributes */

  if (home_value_estimate) {
    /** if filter is applied from new ui(discrete ranges), input will be
     * an array of pre-defined ranges
     * in order to support old filters saved with Range values as per old UI
     */
    if (home_value_estimate && Array.isArray(home_value_estimate)) {
      if (home_value_estimate.length > 0) {
        const propertyValueRange = [];
        home_value_estimate.forEach((val) => {
          if (val.valueObject) {
            propertyValueRange.push({
              range: {
                home_value_estimate: { ...val.valueObject },
              },
            });
          }
        });
        if (search && propertyValueRange && propertyValueRange.length > 0) {
          search.home_value_estimate = propertyValueRange;
        }
      }
    } else {
      const propertyRange = JSON.parse(JSON.stringify(home_value_estimate));
      if (
        propertyRange &&
        propertyRange.key &&
        propertyRange.value &&
        propertyRange.value.length > 1 &&
        (propertyRange.value[0] || propertyRange.value[1])
      ) {
        // const propertyValue = {};
        propertyRange.value.sort(function (a, b) {
          return a - b;
        });

        /** filters created with old ui (exact/between random no/range) ,
         * conversion to new style(pre-defined ranges) is needed
         */
        const convertedRanges = getNewPropertyValueRange(
          propertyRange.value[0],
          propertyRange.value[1],
        );
        if (convertedRanges && convertedRanges.length > 0) {
          const homeValueEstimates = convertedRanges.map((obj) => {
            if (!obj.valueObject.lte) {
              return {
                range: {
                  home_value_estimate: { gte: obj.valueObject.gte },
                },
              };
            }
            return {
              range: {
                home_value_estimate: obj.valueObject,
              },
            };
          });
          search.home_value_estimate = homeValueEstimates;
        }
      }
    }
  }

  if (property_type && property_type.length > 0) {
    search.property_type = property_type.map((obj) => obj.value);
  }
  if (naics_codes && naics_codes.length > 0) {
    search.naics_codes = naics_codes.map((obj) => obj?.value).filter(Boolean);
  }
  if (number_of_owned_properties && number_of_owned_properties.length > 0)
    search.number_of_owned_properties = formatPropertyAttributes(
      number_of_owned_properties,
      'number_of_owned_properties',
    );

  if (norm_home_owner && norm_home_owner.length > 0)
    search.norm_home_owner = norm_home_owner
      .map((obj) => obj?.value)
      .filter(Boolean);

  if (total_number_of_rooms && total_number_of_rooms.length > 0)
    search.total_number_of_rooms = formatPropertyAttributes(
      total_number_of_rooms,
      'total_number_of_rooms',
    );

  if (lotsize_square_feet && lotsize_square_feet.length > 0) {
    search.lotsize_square_feet = formatPropertyAttributes(
      lotsize_square_feet,
      'lotsize_square_feet',
    );
  }

  if (effective_year_built && effective_year_built.length > 0)
    search.effective_year_built = [
      {
        range: {
          effective_year_built: {
            gte: extractYear(effective_year_built[0]),
            lte: extractYear(effective_year_built[1]),
          },
        },
      },
    ];

  if (number_of_bedrooms && number_of_bedrooms.length > 0) {
    search.number_of_bedrooms = formatPropertyAttributes(
      number_of_bedrooms,
      'number_of_bedrooms',
    );
  }

  if (number_of_baths && number_of_baths.length > 0)
    search.number_of_baths = formatPropertyAttributes(
      number_of_baths,
      'number_of_baths',
    );

  if (number_of_partial_baths && number_of_partial_baths.length > 0)
    search.number_of_partial_baths = formatPropertyAttributes(
      number_of_partial_baths,
      'number_of_partial_baths',
    );

  if (number_of_stories && number_of_stories.length > 0)
    search.number_of_stories = number_of_stories
      .map((obj) => obj?.value)
      .filter(Boolean);

  if (number_of_units && number_of_units.length > 0)
    search.number_of_units = formatPropertyAttributes(
      number_of_units,
      'number_of_units',
    );

  if (garage_cars && garage_cars.length > 0)
    search.garage_cars = formatPropertyAttributes(garage_cars, 'garage_cars');

  if (is_pool_included) search.is_pool_included = is_pool_included;

  if (is_elevator_included) search.is_elevator_included = is_elevator_included;

  /** Property ends */

  // /** Tags */
  const tagIds = [];
  if (tags_checked && tags_checked.length > 0) tagIds.push(...tags_checked);
  if (shared_tags && shared_tags.length > 0) tagIds.push(...shared_tags);
  if (tagIds.length > 0) search.tag_ids = tagIds;

  if (profileFilter && shared_tags && shared_tags.length > 0) {
    search.shared_tags = shared_tags;
  }

  // /** End of Tags */

  /** Attributes */

  if (attributes_checked && attributes_checked.length > 0) {
    search.attribute_function = attribute_function;
    attributes_checked.forEach((key) => {
      search[key] = true;
    });
  }

  /** End of Attributes */

  /** Wealth Model */

  if (wealth_model_checked && wealth_model_checked.length > 0) {
    // search.attribute_function = attribute_function;
    wealth_model_checked.forEach((key) => {
      search[key] = true;
    });
  }

  /** End of Wealth Model */

  if (
    (lookUpLists.isRelationPathSelected ||
      !dataStoreFilters.relmap_org ||
      dataStoreFilters.relmap_org.length <= 0) &&
    !superConnectorFilter &&
    import_source &&
    import_source.length
  ) {
    body.import_source = import_source;
  }

  if (
    (lookUpLists.isRelationPathSelected ||
      !dataStoreFilters.relmap_org ||
      dataStoreFilters.relmap_org.length <= 0) &&
    !superConnectorFilter &&
    file_imports &&
    file_imports.length
  ) {
    body.file_imports = file_imports;
  }

  if (
    (!dataStoreFilters.relmap_org || dataStoreFilters.relmap_org.length <= 0) &&
    !superConnectorFilter &&
    // filter_type !== 'first_degree' &&
    team_file_imports &&
    team_file_imports.length
  ) {
    body.team_file_imports = team_file_imports;
  }

  if (
    (!dataStoreFilters.relmap_org || dataStoreFilters.relmap_org.length <= 0) &&
    !superConnectorFilter
  ) {
    let teamIds = [];
    if (teamFilters.team_ids && teamFilters.team_ids.length > 0) {
      teamIds = [...teamFilters.team_ids];
      body.team_members = teamIds;
    } else if (!teamFilters.uiTeamIds || teamFilters.uiTeamIds.length <= 0) {
      /** if not a part of any team, pass ownerId as team_members only for 1st, 2nd, combined */
      if (filter_type !== 'all') {
        teamIds.push(currentUser.ownerId);
        body.team_members = teamIds;
      }
    }
  }

  if (event_org_name && event_org_name.length) {
    search.event_org_name = event_org_name
      .map((company) => company?.value)
      .filter(Boolean);
  }

  if (company_search && company_search.geo) {
    search.company_search = {
      geo_distance: {
        distance: '16km',
        address: company_search.address,
        b2b_geo: `${company_search.geo.lat}, ${company_search.geo.lng}`,
      },
    };
  }

  if (search) body.search = search;

  if (
    //(fromMapPage || lookUpLists.isPeoplePage) &&
    mapQuery &&
    Object.keys(mapQuery).length > 0
  ) {
    body.map_query = mapQuery;
  }

  if (groupByValues) {
    const summary_search = getSummarySearch(groupByValues);

    if (summary_search && Object.keys(summary_search).length > 0)
      body.summary_search = summary_search;
  }

  return body;
}

const getSummarySearch = (groupByValues) => {
  const {
    age,
    schools,
    industry,
    org_name,
    location_city,
    home_postal_code,
    location_state,
    employee_count,
    stock_performance,
    title,
    title_role,
    latest_mim_event_type,
    marital_status,
    gender,
    credentials,
    title_rank,
    inferred_salary_range,
    wealth_estimate,
    interests,
    previous_experience_org_name,
    remaining_share_value_sum,
    tags,
    funding_round,
    number_of_owned_properties,
    property_type,
    mapped_connection_count,
  } = groupByValues;
  const summary_search = {};
  // summary view school click
  const schoolFilterSummary = [];
  if (schools && schools.length) {
    schools.forEach((school) => {
      if (school) {
        schoolFilterSummary.push(school.value);
      }
    });
    summary_search.schools = {
      exact: schoolFilterSummary,
      prefix: [],
    };
  }
  if (property_type && property_type.length > 0)
    summary_search.property_type = [property_type[0].value];

  // summary view industry click
  if (industry && industry.length) {
    summary_search.industry = industry.map((ind) => ind?.value).filter(Boolean);
  }

  if (remaining_share_value_sum && remaining_share_value_sum.length) {
    const remainingShareValue = [];
    remaining_share_value_sum.forEach((remainingShareObj) => {
      const remainingShare = {};
      if (remainingShareObj) {
        if (remainingShareObj.min !== null)
          remainingShare.gte = remainingShareObj.min;
        if (remainingShareObj.max !== null)
          remainingShare.lte = remainingShareObj.max;
        remainingShareValue.push({
          range: {
            remaining_share_value_sum: remainingShare,
          },
        });
      }
    });
    summary_search.remaining_share_value_sum = remainingShareValue;
  }

  // summary view orgname click
  if (org_name && org_name.length) {
    const orgName = [];
    const orgId = [];
    org_name.forEach((org) => {
      if (!org) return;
      if (org.label && org.value.toLowerCase() === org.label.toLowerCase()) {
        orgName.push(org.value);
      } else {
        const orgObj = {};
        orgObj.ai_org_id = org.value;
        orgObj.name = org.label;
        orgId.push(orgObj);
      }
    });
    if (orgName && orgName.length) {
      summary_search.org_name = orgName;
    }
    if (orgId && orgId.length) {
      summary_search['current_experience.ai_org_id'] = orgId;
    }
  }

  if (previous_experience_org_name && previous_experience_org_name.length) {
    const orgName = [];
    const orgId = [];
    previous_experience_org_name.forEach((org) => {
      if (org.value.toLowerCase() === org.label.toLowerCase()) {
        orgName.push(org.value);
      } else {
        const orgObj = {};
        orgObj.ai_org_id = org.value;
        orgObj.name = org.label;
        orgId.push(orgObj);
      }
    });
    if (orgName && orgName.length) {
      summary_search['previous_experience.org_name'] = orgName;
    }
    if (orgId && orgId.length) {
      summary_search['previous_experience.ai_org_id'] = orgId;
    }
  }
  // summary view location city click
  if (location_city && location_city.length) {
    summary_search.location_city = location_city
      .map((city) => city?.value)
      .filter(Boolean);
  }
  // summary view home postal code click
  if (home_postal_code) {
    summary_search.home_postal_code = {
      range: {
        home_postal_code: {
          gte: home_postal_code,
          lte: home_postal_code,
        },
      },
    };
  }

  // summary view age click
  const range_list = [];
  if (age && Object.keys(age).length > 0) {
    range_list.push({
      range: {
        age,
      },
    });
    summary_search.range_list = range_list;
  }

  // summary view location state click
  if (location_state && location_state.length) {
    summary_search.location_state = location_state
      .map((state) => state?.value)
      .filter(Boolean);
  }

  // summary view Company Viablitiy click
  if (stock_performance && stock_performance.length) {
    summary_search.stock_performance = stock_performance.map((count) => {
      return count.value.split(', ');
    });
  }

  // summary view employee count click
  if (employee_count && employee_count.length) {
    summary_search.employee_count = employee_count.map((count) => {
      return count.value;
    });
  }

  // summary view title click
  if (title && title.length) {
    const titleFilterSummary = [];
    title.forEach((titles) => {
      if (titles) {
        titleFilterSummary.push(titles.value);
      }
    });
    summary_search.title = {
      exact: titleFilterSummary,
      prefix: [],
    };
  }
  // summary view title Function click
  if (title_role && title_role.length) {
    const titleList = title_role.map((title) => {
      return title.value;
    });
    summary_search.title_role = {
      exact: titleList,
      prefix: [],
    };
  }

  // summary view latest mim event type click
  if (latest_mim_event_type && latest_mim_event_type.length) {
    summary_search.latest_mim_event_type = latest_mim_event_type.map(
      (event) => {
        return event.value;
      },
    );
  }
  if (credentials && credentials.length) {
    summary_search.credentials = credentials.map((credential) => {
      return credential.value;
    });
  }
  // summary view maritial statsu click
  if (marital_status) {
    summary_search.marital_status = marital_status;
  }
  // summary view gender click
  if (gender) {
    summary_search.gender = gender;
  }
  // summary view title rank click
  if (title_rank && title_rank.length) {
    summary_search.title_rank = title_rank.map((title) => {
      return title.value;
    });
  }
  // summary view inferred salary range click
  if (inferred_salary_range && inferred_salary_range.length) {
    summary_search.inferred_salary_range = inferred_salary_range.map(
      (salaryRange) => {
        return salaryRange.value;
      },
    );
  }
  // summary view wealth estimate click
  if (wealth_estimate && wealth_estimate.length) {
    const wealthArray = [];
    wealth_estimate.forEach((wealthScore) => {
      const wealthEstimate = {};
      if (wealthScore) {
        if (wealthScore.min !== null) wealthEstimate.gte = wealthScore.min;
        if (wealthScore.max !== null) wealthEstimate.lte = wealthScore.max;
        wealthArray.push({
          range: {
            wealth_estimate: wealthEstimate,
          },
        });
      }
    });
    summary_search.wealth_estimate = wealthArray;
  }

  // summary view number of mapped relationships click
  if (mapped_connection_count) {
    const numberOfMappedConnections = [];
    if (Object.keys(mapped_connection_count).length > 0) {
      numberOfMappedConnections.push({
        range: {
          number_of_mapped_connections: mapped_connection_count,
        },
      });
      summary_search.number_of_mapped_connections = numberOfMappedConnections;
    }
  }

  // summary view interests click
  if (interests && interests.length) {
    interests.forEach((interest) => {
      summary_search[interest.value] = true;
    });
  }

  // summary view tags click
  if (tags && tags.value) {
    summary_search.tag_ids = [tags.value];
  }

  // summary view funding round click
  if (funding_round && funding_round.length > 0) {
    summary_search.funding_round = [funding_round];
  }

  // summary view multiple properties click
  if (number_of_owned_properties && number_of_owned_properties.length) {
    const ownedProperties = [];
    number_of_owned_properties.forEach((numRange) => {
      ownedProperties.push({
        range: {
          number_of_owned_properties: {
            gte: numRange.gte,
            lte: numRange.lte,
          },
        },
      });
    });
    summary_search.number_of_owned_properties = ownedProperties;
  }
  return summary_search;
};
