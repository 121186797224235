/* global chrome */
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { inject, observer } from 'mobx-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { requestWithAuth } from '../../services/api';
import { setUserType } from '../../utils/userTypeAuthority';
import { setAxiosTokenInterceptor } from '../../components/Auth0AxiosInterceptor';
import { getDomain, setAppTheme } from '../../utils/general';
import Progress from '../../components/Progress';
import LoaderAnimation from '../../components/AidLoader';
import AutoFilters from './AutoFilters';

const LoginCallBack = inject('dataStore')(
  observer((props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isRegistered, setIsRegistered] = useState(true);
    const [isExistingUserFromMetadata, setIsExistingUserFromMetadata] =
      useState(false);
    const [userRole, setUserRole] = useState('');
    const [query, setQuery] = useState(null);
    const { user, isAuthenticated, getIdTokenClaims } = useAuth0();

    const flags = useFlags();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (isAuthenticated && user) {
        const pluginRedirectUrl = localStorage.getItem('chrome_redirect');
        if (pluginRedirectUrl) {
          localStorage.removeItem('chrome_redirect');
          getIdTokenClaims()
            .then((token) => {
              if (token) {
                chrome.runtime.sendMessage(
                  import.meta.env.VITE_EXTENSION_ID,
                  { token: token.__raw },
                  function (reply) {},
                );
              }
            })
            .catch((e) => {
              console.log(e, 'getIdTokenClaims');
            });
          window.location.replace(pluginRedirectUrl);
          return;
        }
        getIdTokenClaims().then((token) => {
          if (token) setAxiosTokenInterceptor(token.__raw);
          getThemeForUser();
          getZendeskToken();
        });
      }
    }, [isAuthenticated]);

    const getZendeskToken = () => {
      requestWithAuth('zendesk-chat-jwt', 'GET', null, null, null).then(
        (response) => {
          if (response && response.token) {
            window.zE('messenger', 'loginUser', function (callback) {
              callback(response.token);
            });
          }
        },
      );
    };

    const getThemeForUser = () => {
      requestWithAuth('themes', 'GET', null, null, null)
        .then((response) => {
          if (
            response &&
            response.results &&
            response.results.length > 0 &&
            response.results[0].slug
          ) {
            const hostArray = window.location.host.split('.');
            let domain = 'app';
            if (hostArray && hostArray.length > 0 && hostArray[0]) {
              domain = hostArray[0];
            }
            let redirectUrl = localStorage.getItem('redirect_to');
            if (redirectUrl) {
              setQuery(redirectUrl);
              localStorage.removeItem('redirect_to');
            }
            if (
              domain !== response.results[0].slug &&
              window.location.hostname !== 'localhost' &&
              !window.location.hostname.startsWith('staging.')
            ) {
              let url = `${getDomain(response.results[0].slug)}/user/login`;
              if (redirectUrl) {
                redirectUrl = redirectUrl.substring(1);
                url = `${url}?redirect_to=${redirectUrl}`;
              }
              window.location.href = url;

              // let url = `${getDomain(response.results[0].slug)}`;
              // if (redirectUrl)
              //   url = `${getDomain(response.results[0].slug)}${redirectUrl}`;
              // window.location.href = url;
            } else {
              const theme = response.results[0];
              const themeObject = {};
              themeObject.dark = theme.dark_color;
              themeObject.primary = theme.primary_color;
              themeObject.secondary = theme.secondary_color;
              themeObject.logoUrl = theme.logo_url;
              themeObject.favIcon = theme.favicon_url;
              themeObject.slug = theme.slug;
              themeObject.name = theme.name;

              localStorage.setItem(
                'aidentified.theme',
                JSON.stringify(themeObject),
              );
              setAppTheme();

              handleLoginResponse();
            }
          } else if (
            response &&
            response.results &&
            response.results.length > 0 &&
            response.results[0].user_type === 'inactive'
          ) {
            navigate(`/exception/403`, {
              state: {
                errorMessage:
                  'Subscription expired - please contact your account representative.',

                redirectUrl: '/user/login',
              },
            });
          } else {
            // handleLoginResponse();
            if (document.getElementById('cover-spin') !== null)
              document.getElementById('cover-spin').style.display = 'none';
            if (document.getElementById('validation-status') !== null) {
              document.getElementById('validation-status').style.display =
                'block';
              document.getElementById('validation-status').innerHTML =
                'Something went wrong. Please try again!';
            }
          }
        })
        .catch((error) => {
          // handleLoginResponse();
          if (document.getElementById('cover-spin') !== null)
            document.getElementById('cover-spin').style.display = 'none';
          if (document.getElementById('validation-status') !== null) {
            document.getElementById('validation-status').style.display =
              'block';
            document.getElementById('validation-status').innerHTML =
              'Something went wrong. Please try again!';
          }
          console.log(error);
        });
    };

    const handleLoginResponse = () => {
      if (user) {
        setUserType().then((res) => {
          if (res) {
            settingLoggedInUser(res);
          } else {
            if (document.getElementById('validation-status') !== null) {
              document.getElementById('validation-status').style.display =
                'block';
              document.getElementById('validation-status').innerHTML =
                'Please try again!';
            }
            if (document.getElementById('cover-spin') !== null)
              document.getElementById('cover-spin').style.display = 'none';
          }
        });
      } else {
        if (document.getElementById('cover-spin') !== null)
          document.getElementById('cover-spin').style.display = 'none';
        setIsLoggedIn(false);
      }
    };

    const settingLoggedInUser = (userType) => {
      if (document.getElementById('cover-spin') !== null)
        document.getElementById('cover-spin').style.display = 'none';
      setIsExistingUserFromMetadata(userType.isExistingUser || false);
      setIsRegistered(userType.isExistingUser ? true : userType.isReg);
      // setIsRegistered(userType.isExistingUser || false);
      setUserRole(userType.type);
      props.dataStore.currentUser = userType;
      localStorage.setItem('aidentified.pfFinderHelp', 'true');
      setIsLoggedIn(true);
    };

    if (isLoggedIn) {
      if (!isExistingUserFromMetadata && userRole !== 'lite') {
        return (
          <AutoFilters
            onFilterCompletion={() => {
              setIsExistingUserFromMetadata(true);
              setIsRegistered(true);
              props.dataStore.currentUser.isReg = true;
            }}
          />
        );
      }

      return (
        <Navigate
          to={query && query.length > 0 ? query : '/relationship-intelligence'}
        />
      );
    }

    return (
      <div className="loader-wrapper">
        <LoaderAnimation loaderId="user-layout-loader" />;
      </div>
    );
  }),
);

export default LoginCallBack;
