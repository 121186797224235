import './index.scss';
import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, message, Table } from 'antd';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';

import {
  splitNumberWithCommas,
  getDateRangeFromPeriod,
  getTeamFileLookup,
} from '../../utils/general';
import { copyUserFilter } from '../../utils/copyUserFilter';
import { getBodyParams } from '../../utils/getBodyParams';
import { requestWithAuth } from '../../services/api';
import withRouter from '../../hooks/withRouter';
import LoaderAnimation from '../AidLoader';

let source;
message.config({
  top: 80,
});
const sortInfo = {
  column: '',
  order: '',
};

const TriggerCompanyModal = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const { onCancel, onPeopleClick, triggerPeriod } = props;
    const [summaryList, setSummaryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [listPagination, setListPagination] = useState({
      totalPageSize: 0,
      currentPage: 1,
      size: 100,
    });
    const [sortOrder, setSortOrder] = useState('');
    useEffect(() => {
      fetchListData();
    }, []);

    useEffect(() => {
      if (listPagination.totalPageSize === 0) return;
      fetchListData();
    }, [listPagination.size, listPagination.currentPage, sortOrder]);

    const saveUserFilter = (
      filters,
      label,
      isActive,
      email_alert,
      separate_email_alert,
      filterType,
      fetchData,
    ) => {
      if (filterType) props.dataStore.networkFilters.filter_type = filterType;

      if (filterType === 'all') {
        props.dataStore.networkFilters.import_source = [];
        props.dataStore.networkFilters.file_imports = [];
        props.dataStore.networkFilters.team_file_imports = [];
      } else {
        props.dataStore.networkFilters.import_source =
          props.dataStore.lookUpLists.import_source;
        props.dataStore.networkFilters.file_imports =
          props.dataStore.lookUpLists.fileImportsWithoutEnrich.map((item) => {
            if (item.value) return item.value;
          });
        const teamLookupToChoose = getTeamFileLookup(
          props.dataStore.networkFilters.filter_type,
          props.dataStore.lookUpLists.addImportsToTeam,
          props.dataStore.lookUpLists.teamFileImportsWithoutEnrich,
        );
        props.dataStore.networkFilters.team_file_imports =
          teamLookupToChoose.map((item) => {
            if (item.value) return item.value;
          });
      }
      props.dataStore.importSourceKeys.isImportSourceSaved = false;
      props.dataStore.importSourceKeys.isImportSourceChecked = false;

      const dataStoreFilters = copyUserFilter(
        filters,
        props.dataStore.networkFilters,
        props.dataStore.lookUpLists,
        label,
        isActive,
        email_alert,
        separate_email_alert,
        false,
      );

      props.dataStore.networkFilters = cloneDeep(dataStoreFilters);

      /** For handling redirections specially on the keys import_source,
       * file_imports and team_file_imports. Extra conditions added in addition to the common method */
      if (filters.import_source) {
        props.dataStore.importSourceKeys.isImportSourceSaved = true;
      }

      if (filters.file_imports) {
        props.dataStore.networkFilters.filterFileImports = filters.file_imports;
        if (filterType === 'all') {
          props.dataStore.networkFilters.file_imports = filters.file_imports;
        } else {
          const files = [];
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          props.dataStore.lookUpLists.fileImportsWithoutEnrich &&
            props.dataStore.networkFilters.filterFileImports &&
            props.dataStore.lookUpLists.fileImportsWithoutEnrich.map((file) => {
              if (
                file &&
                file.value &&
                props.dataStore.networkFilters.filterFileImports.includes(
                  file.value,
                )
              )
                files.push(file.value);
            });
          props.dataStore.networkFilters.file_imports = files;
        }
        props.dataStore.importSourceKeys.isImportSourceSaved = true;
      }

      if (filters.team_file_imports) {
        props.dataStore.networkFilters.filterTeamFileImports =
          filters.team_file_imports;
        if (filterType === 'all') {
          props.dataStore.networkFilters.team_file_imports =
            filters.team_file_imports;
        } else {
          const teamLookupToChoose = getTeamFileLookup(
            props.dataStore.networkFilters.filter_type,
            props.dataStore.lookUpLists.addImportsToTeam,
            props.dataStore.lookUpLists.teamFileImportsWithoutEnrich,
          );
          const files = [];
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          teamLookupToChoose &&
            props.dataStore.networkFilters.filterTeamFileImports &&
            teamLookupToChoose.map((file) => {
              if (
                file &&
                file.value &&
                props.dataStore.networkFilters.filterTeamFileImports.includes(
                  file.value,
                )
              )
                files.push(file.value);
            });
          props.dataStore.networkFilters.team_file_imports = files;
        }
        props.dataStore.importSourceKeys.isImportSourceSaved = true;
      }

      if (!fetchData) props.dataStore.networkFilters.fetchData = true;
    };

    const showCompanyProfile = (id, orgName) => {
      if (!id) return;
      // click on any company name
      props.modalDataStore.resetProfileModal();
      props.modalDataStore.resetCompanyProfileModal();
      const { companyProfileModal } = props.modalDataStore;
      companyProfileModal.id = id;
      companyProfileModal.orgName = orgName;
      companyProfileModal.isVisible = true;
      if (
        props.modalDataStore.trackModal &&
        props.modalDataStore.trackModal.length > 0
      ) {
        props.modalDataStore.trackModal.push({
          modal: 'company',
          data: { id, orgName },
        });
      }
    };
    const fetchListData = () => {
      if (source) source.cancel('Operation canceled by the user.');

      const { CancelToken } = axios;
      source = CancelToken.source();
      setIsLoading(true);

      const {
        ownerTypeId,
        import_source,
        file_imports,
        team_file_imports,
        quickSearchRI,
        allTeamFiles = [],
        m2_date,
      } = props.dataStore.summaryFilter;
      props.dataStore.resetNetworkFilters();
      const filterId = props.dataStore.summaryFilter.selectedId;
      if (filterId !== -1) {
        props.dataStore.networkFilters.selectedIdValue = filterId;
        if (props.globalDataStore.multipleFilters.filterList.length > 0) {
          props.globalDataStore.multipleFilters.filterList.map((filter) => {
            if (filterId === filter.value) {
              saveUserFilter(
                filter.filters,
                filter.label,
                filter.is_active,
                filter.email_alert,
                filter.separate_email_alert,
                filter.filter_type,
                true,
              );
            }
          });
        }
      }

      if (quickSearchRI) {
        props.dataStore.quick_search = quickSearchRI;
      }

      if (typeof ownerTypeId === 'number') {
        if (props.dataStore.lookUpLists.team) {
          const memberList = [];
          props.dataStore.lookUpLists.team.map((team) => {
            if (team.id === ownerTypeId) {
              team.users.map((members) => {
                memberList.push(members.id);
              });
            }
          });
          props.dataStore.teamFilters.team_ids = memberList;
        }
      } else {
        switch (ownerTypeId) {
          case 'first':
            props.dataStore.teamFilters.team_ids = [
              props.dataStore.currentUser.ownerId,
            ];
            props.dataStore.networkFilters.filter_type = 'first_degree';
            break;
          case 'second':
            props.dataStore.teamFilters.team_ids =
              props.dataStore.lookUpLists.teamMemberIds;
            props.dataStore.networkFilters.filter_type = 'second_degree';
            break;
          case 'combined':
            props.dataStore.teamFilters.team_ids =
              props.dataStore.lookUpLists.teamMemberIds;
            props.dataStore.networkFilters.filter_type = 'combined';
            break;
          case 'all':
            props.dataStore.teamFilters.team_ids =
              props.dataStore.lookUpLists.teamMemberIds;
            props.dataStore.networkFilters.filter_type = 'all';
            break;
          default:
            break;
        }
      }

      props.dataStore.networkFilters.import_source = import_source || [];
      props.dataStore.networkFilters.file_imports = file_imports || [];
      props.dataStore.networkFilters.team_file_imports =
        team_file_imports || [];

      props.dataStore.networkFilters.latest_mim_event_type =
        props.dataStore.lookUpLists.eventList;
      props.dataStore.networkFilters.m2_date =
        getDateRangeFromPeriod(triggerPeriod);

      const body = getBodyParams(
        props.dataStore.networkFilters,
        props.dataStore.lookUpLists,
        props.dataStore.superConnectorFilter,
        props.dataStore.teamFilters,
        props.dataStore.currentUser,
        props.dataStore.mappedRelationshipsFilter,
        props.modalDataStore.profileModal,
        props.dataStore.groupByValues,
      );

      setSummaryList([]);

      const params = {
        page_size: listPagination.size,
        from: (listPagination.currentPage - 1) * listPagination.size,
        key: 'current_experience.ai_org_id',
      };

      if (props.dataStore.networkFilters.filter_type)
        params.filter_type = props.dataStore.networkFilters.filter_type;

      // if anything is searched, pass that
      if (props.dataStore.quick_search)
        params.quick_search = props.dataStore.quick_search;

      if (sortInfo.column && sortInfo.order) {
        params.sort_by = sortInfo.column === 'key' ? '_key' : '_count';
        params.sort_order = sortInfo.order;
      }

      requestWithAuth(
        'people-grouping-new',
        'POST',
        params,
        body,
        // source.token,
      ).then((response) => {
        if (!response) return;
        setListPagination({
          ...listPagination,
          totalPageSize: response.count > 1000 ? 1000 : response.count,
        });

        //summaryDetail.totalCount = response.count;
        setSummaryList(
          response.results
            ? response.results.filter((obj) => {
                return obj.key !== 'unknown';
              })
            : [],
        );
        setIsLoading(false);
      });

      props.dataStore.networkFilters.fetchData = false;
    };

    const handleTableChange = (pagination, filters, sorter) => {
      if (sorter && sorter.order) {
        sortInfo.column = sorter.columnKey;
        sortInfo.order = sorter.order === 'ascend' ? 'asc' : 'desc';
        setSortOrder(sortInfo.order);
      } else {
        sortInfo.column = '';
        sortInfo.order = '';
      }

      setListPagination({
        ...listPagination,
        currentPage: pagination.current,
        size: pagination.pageSize,
      });
    };

    const columns = [
      {
        title: 'Companies',
        key: 'key',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        width: 400,
        render: (val) => {
          if (!val || !val.key) return <></>;
          return (
            <div
              className={
                val.ai_org_id ? 'company-link' : 'company-link-disabled'
              }
              onClick={() => {
                if (!val.ai_org_id) return;
                showCompanyProfile(val.ai_org_id, val.key);
              }}
            >
              <div style={{ textTransform: 'capitalize' }}>{val.key}</div>
            </div>
          );
        },
      },
      {
        title: 'People',
        key: 'unique_count',
        align: 'center',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        width: 150,
        render: (rec, val) => {
          return (
            <div
              onClick={() => {
                if (!val || !val.unique_count || val.unique_count <= 0) return;
                onPeopleClick(
                  'triggers_view_box',
                  { value: val.ai_org_id, label: val.key },
                  triggerPeriod,
                );
              }}
              className="executive-count"
            >
              <div>{splitNumberWithCommas(val.unique_count)}</div>
            </div>
          );
        },
      },
      {
        title: '',
        key: 'dummy',
      },
    ];

    return (
      <Modal
        width="100%"
        open
        wrapClassName="peopleview-modal-class  impactview-modal-class"
        footer={null}
        onCancel={onCancel}
      >
        <div className="loader-wrapper">
          {isLoading && (
            <LoaderAnimation
              loaderId="trigger-modal-dash-loader"
              size="small"
            />
          )}
          <div className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
            <Table
              className="impactview-table"
              dataSource={summaryList}
              columns={columns}
              pagination={{
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['20', '50', '100', '200'],
                total: listPagination.totalPageSize,
                pageSize: listPagination.size,
                current: listPagination.currentPage,
              }}
              onChange={handleTableChange}
              scroll={{ y: 450 }}
              showSorterTooltip={false}
            />
          </div>
        </div>
      </Modal>
    );
  }),
);

export default withRouter(TriggerCompanyModal);
