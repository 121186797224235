import './index.scss';
import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import EditIcon from '../../assets/icons/ai_edit.svg?react';
import DownArrow from '../../assets/icons/ai_ic_down.svg?react';
import TagsList from '../TagsFolder/TagsList';
import FolderView from '../TagsFolder/FolderView';
import FolderEditView from '../TagsFolder/FolderEditView';
import TagsEditView from '../TagsFolder/TagsEditView';

const TagManagement = inject('dataStore')(
  observer((props) => {
    const {
      userTagFolders,
      fetchUserTags,
      fetchTagFolders,
      profileTagsData = [],
      changeProfileTagsData,
      handleEditTags,
      handleEditFolder,
      showTagsEdit,
      showFolderEdit,
      tagProfiles,
      tagSetup,
      selectedProfileIds,
      dataCount,
    } = props;
    const [showAll, setShowAll] = useState(false);

    const selectedCount =
      selectedProfileIds && selectedProfileIds.length > 0
        ? selectedProfileIds.length
        : dataCount || 0;

    useEffect(() => {
      const modalScrollBody = document.querySelector('.ant-modal-body');
      const footer = document.querySelector('.ant-modal-footer');
      modalScrollBody.onscroll = (e) => {
        const target = e.target;
        if (
          footer &&
          target &&
          target.scrollTop + target.clientHeight + 5 >= target.scrollHeight
        )
          footer.style.boxShadow = 'none';
        else {
          footer.style.boxShadow = ' 0px -58px 33px 5px #ffffff';
        }
      };
    }, []);

    if (showTagsEdit) {
      return (
        <TagsEditView
          handleEditTags={handleEditTags}
          fetchUserTags={fetchUserTags}
          fetchTagFolders={fetchTagFolders}
        />
      );
    }

    if (showFolderEdit) {
      return (
        <FolderEditView
          folderId={showFolderEdit}
          fetchTagFolders={fetchTagFolders}
          fetchUserTags={fetchUserTags}
          from={props.from || ''}
        />
      );
    }

    return (
      <div>
        <div className="tag-profiles-container">
          <span
            style={{
              lineHeight: 2,
              fontFamily: 'Poppins-Regular',
              fontSize: 18,
            }}
          >
            {tagSetup === 'showTagsForProfile' ? (
              <>Tags for this profile</>
            ) : (
              <>
                {tagProfiles === 'attach' ? 'Tag' : 'Untag'} {selectedCount}{' '}
                profile
                {selectedCount > 1 && 's'}
              </>
            )}
          </span>
          <span style={{ lineHeight: 2, color: '#808184' }}>
            {tagSetup === 'showTagsForProfile'
              ? 'Turn on any tags below to tag, or turn off tags below to untag'
              : selectedProfileIds && (
                  <>
                    {tagProfiles === 'attach'
                      ? 'Turn on any tags below to tag multiple profiles'
                      : 'Turn off any tags below to untag multiple profiles'}
                  </>
                )}
          </span>
        </div>
        {userTagFolders &&
          userTagFolders.orphan_tags &&
          userTagFolders.orphan_tags.length > 0 && (
            <div className="orphan-tags">
              <div className="sub-title-bar">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ fontSize: 15, fontFamily: 'Poppins-Medium' }}>
                    Recently used tags
                  </div>
                  <EditIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEditTags(true)}
                  />
                </div>
                <div
                  className="see-all-text"
                  onClick={() => {
                    setShowAll(!showAll);
                  }}
                >
                  <DownArrow
                    className="down-arrow"
                    style={{
                      transform: showAll ? ' rotate(180deg)' : 'rotate(0deg)',
                    }}
                  />
                  {showAll ? 'See less' : 'See all'}
                </div>
                {/* <div
                  className="add-new"
                  onClick={() => handleAddNewTag('orphanTags')}
                >
                  <PlusIcon /> Add new tag
                </div> */}
              </div>
              <TagsList
                tags={
                  showAll
                    ? userTagFolders.orphan_tags
                    : userTagFolders.orphan_tags.slice(0, 6)
                }
                fetchTagFolders={fetchTagFolders}
                checkedTags={profileTagsData}
                handleTagsCheck={(checked) => {
                  changeProfileTagsData([...checked]);
                }}
                showCreateTag
                isProfileModule
              />
            </div>
          )}
        {userTagFolders &&
          userTagFolders.tag_folders &&
          userTagFolders.tag_folders.length > 0 && (
            <div className="collapse-container-parent">
              <FolderView
                folders={
                  userTagFolders?.tag_folders.length
                    ? userTagFolders.tag_folders
                    : []
                }
                folderTitle={
                  <span style={{ fontSize: 15, fontFamily: 'Poppins-Medium' }}>
                    Folders ({userTagFolders.tag_folders.length})
                  </span>
                }
                onEditClick={handleEditFolder}
                checkedTags={profileTagsData}
                handleTagsCheck={(checked) => {
                  changeProfileTagsData([...checked]);
                }}
                fetchTagFolders={fetchTagFolders}
                isProfileModule
                //folderLength={userTagFolders.tag_folders.length}
              />
              {/* {!seeAllFolders && userTagFolders.tag_folders.length > 1 && (
                <div
                  style={{
                    textAlign: 'center',
                    color: 'var(--color-primary)',
                    paddingBottom: 10,
                  }}
                  onClick={() => setSeeAllFolders(!seeAllFolders)}
                >
                  See all folders{' '}
                  <DownIcon
                    width={9}
                    style={{ marginLeft: 10, marginBottom: 2 }}
                  />
                </div>
              )} */}
            </div>
          )}
        {/* {(!userTagFolders ||
          !userTagFolders.tag_folders ||
          userTagFolders.tag_folders.length <= 0) && <NoFolderView />} */}
      </div>
    );
  }),
);

export default TagManagement;
