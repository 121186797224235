import './index.scss';

import { Button } from 'antd';
import { inject, observer } from 'mobx-react';

import BusinessIcon from '../../assets/icons/ai_ic_business_income.svg?react';
import DonationIcon from '../../assets/icons/ai_ic_donation.svg?react';
import HouseholdIcon from '../../assets/icons/ai_ic_household_income.svg?react';
import InvestibleIcon from '../../assets/icons/ai_ic_investable.svg?react';
import WageIcon from '../../assets/icons/ai_ic_wage_income.svg?react';

import AiTooltip from '../AiTooltip';
import { formatValue } from '../../utils/general';
import { IrsItemsNew } from '../../utils/constants';

const IrsIcons = {
  geo_tax_wages_salaries_tips_etc: <WageIcon />,
  geo_tax_household_income: <HouseholdIcon />,
  geo_tax_business_income_or_loss: <BusinessIcon />,
  geo_tax_investable_assets: <InvestibleIcon />,
  geo_tax_total_gifts_to_charity: <DonationIcon />,
};

function TaxProfileItem({ label, value, icon }) {
  if (!value) return <></>;

  return (
    <div className="card">
      {icon ? icon : <></>}
      <div className="tax-profile-item">
        <div
          className="tax-profile-label"
          style={{ maxWidth: label === 'Average Wage' ? '60px' : '150px' }}
        >
          {label}
        </div>
        <div className="tax-profile-value"> {formatValue(value)}</div>
      </div>
    </div>
  );
}
const TaxProfile = inject('modalDataStore')(
  observer((props) => {
    const {
      home_postal_code,
      home_city,
      home_state,
      home_zip4,
      geo_tax_wages_salaries_tips_etc,
      geo_tax_household_income,
      geo_tax_business_income_or_loss,
      geo_tax_investable_assets,
      geo_tax_total_gifts_to_charity,
    } = props.person;

    if (
      !geo_tax_wages_salaries_tips_etc &&
      !geo_tax_household_income &&
      !geo_tax_business_income_or_loss &&
      !geo_tax_investable_assets &&
      !geo_tax_total_gifts_to_charity
    )
      return <></>;

    return (
      <div className="profile-boxes tax-profile break-inside-avoid">
        <div className="profile-box-header">
          <div className="profile-titles">
            Neighborhood Tax Profile
            <AiTooltip title="This section utilizes data collected from individual tax filings consolidated at a Zip+4 level.  Zip+4 usually comprises ten to twenty homes or locations.  If a profile does not have a Zip+4 identifier, Aidentified provides a summary at the ZIP level." />
          </div>
          <div className="title-right">
            {(home_city || home_state) && (
              <span>
                {home_city ? home_city.toTitleCase() : ''}
                {home_city && home_state ? ', ' : ''}
                {home_state || ''}
              </span>
            )}
            {home_postal_code && (
              <span>
                {'  '}&nbsp;
                {home_postal_code}
                {home_zip4 && <span> -{' ' + home_zip4}</span>}
              </span>
            )}
            {home_postal_code && (
              <Button
                className="link-button no-print"
                onClick={() =>
                  props.handleViewAllClick(home_postal_code, home_zip4)
                }
              >
                View Others in Prospect Finder
              </Button>
            )}
          </div>
        </div>

        {/* <div className="tax-profile-left">
          <div
            style={{
              color: 'var(--color-dark)',
              display: 'flex',
              alignItems: 'center',
              marginTop: '5px',
            }}
          >
            Definitions
            <InfoIcon
              className="info-icon"
              onClick={() => {
                props.modalDataStore.taxDescriptionModalVisible = true;
              }}
            />
          </div>
        </div> */}

        <div style={{ padding: 20 }}>
          <div className="item-container">
            <div className="items">
              {IrsItemsNew.map(({ label, value, icon }) => {
                return (
                  <TaxProfileItem
                    label={label}
                    value={props.person[value]}
                    icon={IrsIcons[value]}
                    key={value}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }),
);

export default TaxProfile;
