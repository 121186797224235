import './index.scss';
import BellIcon from '../../assets/icons/icn-dash-reminder.svg?react';
import CheckIcon from '../../assets/icons/ai_ic_check.svg?react';
import CloseIcon from '../../assets/icons/ai_ic_close.svg?react';

import { AiButton } from '../AiButton';

interface PaywallSpeedBumpProps {
  handleOnClose: () => void;
  handleOnUpgradePremium: () => void;
  handleProfileUnlock: () => void;
}

const PaywallSpeedBump = (props: PaywallSpeedBumpProps) => {
  return (
    <>
      <div
        className="w-full h-full flex flex-col place-items-center relative"
        style={{ gap: '12px' }}
      >
        <AiButton
          buttonclassName="absolute right-0"
          buttonStyle={{
            padding: '4px',
            backgroundColor: 'transparent',
          }}
          onClick={props.handleOnClose}
        >
          <CloseIcon width={16} height={16} />
        </AiButton>

        <div
          className="w-full flex flex-col items-center"
          style={{
            fontSize: '24px',
            lineHeight: '34px',
            fontWeight: 500,
            color: 'var(--color-gray-900)',
          }}
        >
          <span>Upgrade to unlock</span>
          <div className="flex flex-row gap-4 items-center">
            <span style={{ fontSize: '18px' }}>Premium Wealth Event</span>
            <BellIcon width={24} height={24} />
          </div>
        </div>

        <div className="grid grid-cols-2-condense gap-8 features items-center">
          <CheckIcon width={16} height={16} />
          <span className="feature-bullet">
            Get all 14 Wealth Trigger event types{' '}
          </span>

          <CheckIcon width={16} height={16} />
          <span className="feature-bullet">
            Includes all Wealth Trigger values and details in email alerts
          </span>

          {/* empty span to keep grid structure */}
          <span></span>
          <AiButton
            buttonStyle={{ padding: '4px', placeSelf: 'flex-start' }}
            variant="link"
          >
            See a list of all Wealth Triggers
          </AiButton>
        </div>
      </div>
      <div
        className="w-full flex flex-col place-items-center margin-t-auto"
        style={{ gap: '12px', paddingTop: '12px' }}
      >
        <AiButton
          buttonclassName="w-full"
          buttonStyle={{
            backgroundColor: 'var(--color-premium-green)',
            color: 'var(--color-white)',
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 500,
          }}
          onClick={props.handleOnUpgradePremium}
        >
          Upgrade to Premium
        </AiButton>

        <AiButton
          buttonclassName="w-full"
          variant="outline"
          buttonStyle={{
            backgroundColor: 'var(--color-white)',
            color: 'var(--color-gray-300)',
            fontSize: '16px',
            lineHeight: '20px',
            borderRadius: '4px',
            fontWeight: 400,
            borderColor: 'var(--color-gray-50)',
            borderWidth: '2px',
            borderStyle: 'solid',
          }}
          onClick={props.handleProfileUnlock}
        >
          Use 1 credit to unlock Premium attributes for this profile only
        </AiButton>

        <AiButton
          variant="link"
          buttonStyle={{
            backgroundColor: 'var(--color-white)',
            color: 'var(--color-gray-300)',
            fontSize: '12px',
            lineHeight: '20px',
            fontWeight: 400,
          }}
          onClick={props.handleOnClose}
        >
          No thanks
        </AiButton>
      </div>
    </>
  );
};

export default PaywallSpeedBump;
