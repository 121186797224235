import React from 'react';
import PremKeyIcon from '../../assets/icons/icn-prem-key-solid-green.svg?react';

interface PremiumLabelProps {
  icon?: boolean;
  label?: boolean;
  labelText?: string;
  outline?: boolean;
  fill?: boolean;
  bgColor?: 'premium-green' | 'white' | 'light-gray' | 'transparent';
  iconColor?: 'premium-green' | 'white';
  labelColor?: 'premium-green' | 'black' | 'white';
  borderColor?: 'premium-green' | 'light-gray';
}

export const PremiumLabel: React.FC<PremiumLabelProps> = ({
  icon = true,
  label = true,
  outline = true,
  fill = false,
  labelText = 'PREMIUM',
  bgColor = 'premium-green',
  borderColor = 'premium-green',
  iconColor = 'white',
  labelColor = 'white',
}: PremiumLabelProps) => {
  const bgc =
    bgColor == 'transparent' ? 'transparent' : `var(--color-${bgColor})`;

  let divStyles = {
    color: `var(--color-${labelColor})`,
    backgroundColor: bgc,
    width: 'fit-content',
    padding: '4px 8px',
    gap: '6px',
  };

  if (outline) {
    divStyles = Object.assign(divStyles, {
      border: `1px solid var(--color-${borderColor})`,
      borderRadius: '4px',
    });
  }

  if (fill) {
    divStyles = Object.assign(divStyles, {
      backgroundColor: bgc,
      color: `var(--color-${labelColor})`,
      border: 'none',
    });
  }

  const renderIcon = () => {
    return (
      <div
        className="ai-prem-icon-inline"
        style={{ color: `var(--color-${iconColor})` }}
      >
        <PremKeyIcon
          fill={`var(--color-${iconColor})`}
          style={{ height: '16px', width: '16px' }}
        />
      </div>
    );
  };

  const renderLabel = () => {
    return <span>{labelText}</span>;
  };

  return (
    <div
      className="ai-prem-icon-inline"
      style={{
        ...divStyles,
      }}
    >
      {label && renderLabel()}
      {icon && renderIcon()}
    </div>
  );
};
