import './index.css';

import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CheckCircleFilled } from '@ant-design/icons';

const ForgotPasswordSent = () => {
  const [isRedirect, setIsRedirect] = useState(false);

  if (isRedirect) {
    return <Navigate to="/user/login" />;
  }

  return (
    <div className="forgot-password-sent-container">
      <div className="main">
        <div>
          <CheckCircleFilled className="tick-icon" />
        </div>

        <div className="confirmation-text">
          Password reset instructions sent.
        </div>
        <div className="confirmation-subtext">
          {
            /* Please check your email for a link to reset your password. */
            // `If a matching account was found an email was sent to ${
            //   (props.location.state && props.location.state.email) || ''
            // } to allow you to reset your password.`
          }
          Please check your inbox for instructions. If you do not receive an
          email, please contact
          <a
            style={{ color: 'var(--color-link)' }}
            target="_blank"
            href="mailto: hello@aidentified.com"
            rel="noreferrer noopener"
          >
            {' '}
            hello@aidentified.com
          </a>
          .
        </div>

        <button
          type="submit"
          className="submit-button"
          onClick={() => {
            setIsRedirect(true);
          }}
          style={{ maxWidth: 'max-content' }}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default ForgotPasswordSent;
