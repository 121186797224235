/* global chrome */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import qs from 'qs';
import LoaderAnimation from '../../components/AidLoader';

const Login = (props) => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
  useEffect(() => {
    /**  redirected from extension logout, loggedout user, then redirect back to extension*/
    const pluginRedirectUrl = localStorage.getItem('chrome_redirect');
    if (pluginRedirectUrl) {
      localStorage.removeItem('chrome_redirect');
      chrome.runtime.sendMessage(
        import.meta.env.VITE_EXTENSION_ID,
        { token: null },
        function (reply) {},
      );
      window.location.replace(pluginRedirectUrl);
      return;
    }

    /**  redirected from extension login,
     * keep the redirect url of extension to redirect after login callback fron auth0
     */

    const urlParams = location.search;
    const query = qs.parse(urlParams, {
      ignoreQueryPrefix: true,
    });
    if (
      query &&
      !(Object.keys(query).length === 0 && query.constructor === Object) &&
      query.from
    ) {
      localStorage.setItem('chrome_redirect', query.from);
    } else {
      let redirect = urlParams;
      if (redirect && redirect.length > 0)
        redirect = redirect.replace('?redirect_to=', '/');
      if (redirect && redirect.length > 0 && redirect.slice(-1) === '&')
        redirect = redirect.slice(0, -1);
      if (redirect) localStorage.setItem('redirect_to', redirect);
    }
    loginWithRedirect();
  }, []);

  return (
    <div className="loader-wrapper">
      <LoaderAnimation loaderId="login-loader" />;
    </div>
  );
};

export default Login;
