import './index.scss';
import '@gouch/to-title-case';
import { message, Collapse } from 'antd';
import { useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';
import { CloseOutlined, CaretRightFilled } from '@ant-design/icons';
// import moment from 'moment';
import {
  filterLabelNames,
  importSource,
  IrsItems,
} from '../../utils/constants';
import { extractYear, splitNumberWithCommas } from '../../utils/general';

function getEventsLabel(lookup, selected) {
  if (lookup && selected && lookup.length - 1 === selected.length) return 'All';
  return getMultipleValueSelected(selected);
}

function getMultipleValueSelected(selected) {
  const selectedValue = selected.map((item) => item?.label);
  return selectedValue.join(', ');
}

function getTagsValueSelected(selectedIds, tagsLookup) {
  const selectedTags = selectedIds.map((id) => {
    return tagsLookup.find((obj) => obj.id === id)?.tag;
  });
  return selectedTags.filter((item) => item !== undefined).join(', ');
}

function getSharedTagsSelected(selectedIds, tagsLookup) {
  const selectedTags = selectedIds.map((id) => {
    return tagsLookup.find((obj) => obj.tag_id === id)?.tag_name;
  });
  return selectedTags.filter((item) => item !== undefined).join(', ');
}

function getExactOrPartialValueSearched(selected) {
  const exactValue = selected
    .filter((el) => el.__isNew__ === undefined)
    .map((obj) => obj.label);
  const partialValue = selected
    .filter((el) => el.__isNew__)
    .map((obj) => obj.label);

  if (partialValue.length > 0)
    return `${exactValue.join(', ')}${
      exactValue && exactValue.length ? ', ' : ''
    }Contains:  ${partialValue.join(', ')}`;
  else return `${exactValue.join(', ')}`;
}

function getLabel(lookup, selected) {
  if (lookup && selected) {
    const selectedValue = lookup.map((item) => {
      if (item.value === selected) return item.label;
    });
    return selectedValue.filter((item) => item !== undefined).join(', ');
  }
}

function getIrsRangeLabel(lowerLimit, upperLimit) {
  const gte = lowerLimit
    ? parseInt(lowerLimit.toString().replaceAll(',', ''))
    : null;
  const lte = upperLimit
    ? parseInt(upperLimit.toString().replaceAll(',', ''))
    : null;
  switch (true) {
    case !gte || !lte:
      return '';
    case lte === -1:
      return `${splitNumberWithCommas(gte)}+`;
    case gte === -1:
      return `0 - ${splitNumberWithCommas(lte)}`;
    case lte === gte:
      return `${splitNumberWithCommas(lte)}`;
    default:
      if (gte > lte)
        return `${splitNumberWithCommas(lte)} - ${splitNumberWithCommas(gte)}`;
      return `${splitNumberWithCommas(gte)} - ${splitNumberWithCommas(lte)}`;
  }
}

function getDateRange(homePurchaseDate) {
  if (homePurchaseDate[0] !== homePurchaseDate[1])
    return `${dayjs(homePurchaseDate[0]).format('MM/DD/YYYY')} - ${dayjs(
      homePurchaseDate[1],
    ).format('MM/DD/YYYY')}`;
  else return dayjs(homePurchaseDate[0]).format('MM/DD/YYYY');
}

function getRangeLabel(id, range, showFormatting) {
  if (
    !range ||
    !range.key ||
    !range.value ||
    range.value.length <= 1 ||
    (!range.value[0] && !range.value[1])
  )
    return '';

  if (range.key === 'exact')
    return showFormatting
      ? `${splitNumberWithCommas(range.value[0])}`
      : `${range.value[0]}`;

  switch (id) {
    case 'age':
    case 'current_org_years':
    case 'total_years_of_experience': {
      const val1 = parseInt(range.value[0]);
      const val2 = parseInt(range.value[1]);
      if (val2 > val1) return `${val1} - ${val2}`;
      return `${val2} - ${val1}`;
    }
    case 'wealth_estimate_range': {
      const val1 = range.value[0];
      const val2 = range.value[1];
      if (val2 > val1) return `${val1} - ${val2}`;
      return `${val2} - ${val1}`;
    }
    case 'home_postal_code': {
      return `${range.value[0]} - ${range.value[1]}`;
    }
    case 'latest_mim_monetary_value':
    case 'home_value_estimate': {
      const val1 = parseInt(range.value[0].toString().replaceAll(',', ''));
      const val2 = parseInt(range.value[1].toString().replaceAll(',', ''));
      if (val2 > val1)
        return `${splitNumberWithCommas(val1)} - ${splitNumberWithCommas(
          val2,
        )}`;
      return `${splitNumberWithCommas(val2)} - ${splitNumberWithCommas(val1)}`;
    }

    default:
      break;
  }
}

function getConnectionLabel(connection) {
  if (connection === 'first_degree') return 'First Degree';
  if (connection === 'second_degree') return 'Second Degree';
  if (connection === 'combined') return 'First & Second Degree';
  return 'All';
}

// method to format import source
function handleImportSource(value) {
  if (value && value.length > 0) {
    if (importSource.length === value.length) return 'All';

    const importSources = value.map((item) => {
      return item === 'icloud'
        ? 'iCloud'
        : item === 'linkedin'
          ? 'LinkedIn'
          : item === 'aidentified_auto'
            ? 'Aidentified Mapped Relationships'
            : item === 'aidentified'
              ? 'Added in Aidentified App'
              : item.toString().toTitleCase();
    });
    return importSources.join(', ');
  }
}

function getCsvSource(lookup, value) {
  if (value && value.length > 0) {
    if (lookup.length === value.length) return 'All';

    const source = [];
    lookup.map((item) => {
      if (value.includes(item.value)) source.push(item.label);
    });
    return source.join(', ');
  }
}
message.config({
  top: 80,
});

const AppliedFilter = inject('dataStore')(
  inject('modalDataStore')(
    observer((props) => {
      const fixedContainerRef = useRef();
      const [isOpen, setIsOpen] = useState(false);
      const flags = useFlags();

      const clearFromSelectedGroupBy = (key) => {
        const index = props.dataStore.selectedGroupByParam.indexOf(key);
        if (index > -1) props.dataStore.selectedGroupByParam.splice(index, 1);
      };
      const onClear = (e, id) => {
        e.stopPropagation();
        switch (id) {
          case 'groupby_schools':
          case 'groupby_industry':
          case 'groupby_org_name':
          case 'groupby_location_city':
          case 'groupby_location_state':
          case 'groupby_stock_performance':
          case 'groupby_employee_count':
          case 'groupby_latest_mim_event_type':
          case 'groupby_title_rank':
          case 'groupby_credentials':
          case 'groupby_wealth_estimate':
          case 'groupby_number_of_owned_properties':
          case 'groupby_funding_round':
          case 'groupby_interests':
          case 'groupby_title':
          case 'groupby_title_role':
          case 'groupby_property_type':
          case 'groupby_remaining_share_value_sum':
          case 'groupby_mapped_connection_count': {
            const key = id.substr(8);
            clearFromSelectedGroupBy(key);
            props.dataStore.groupByValues[key] = [];
            break;
          }
          case 'groupby_inferred_salary_range':
            clearFromSelectedGroupBy('inferred_salary');
            props.dataStore.groupByValues[id.substr(8)] = [];
            break;
          case 'groupby_previous_experience_org_name':
            clearFromSelectedGroupBy('previous_experience.org_name');
            props.dataStore.groupByValues[id.substr(8)] = [];
            break;
          case 'groupby_age':
            clearFromSelectedGroupBy('age_range');
            props.dataStore.groupByValues.age = null;
            break;
          case 'groupby_home_postal_code':
            clearFromSelectedGroupBy('home_postal_code');
            props.dataStore.groupByValues.home_postal_code = null;
            break;
          case 'groupby_top_team_members':
            clearFromSelectedGroupBy('top_team_members');
            props.dataStore.groupByValues['top_team_members'] = '';
            props.dataStore.lookUpLists.isRelationPathSelected = false;
            /** resetting filter_type, file_imports, team_file_imports, import_source
             * to defaults, as these were applied when 'groupby_top_team_members' is selected,
             * and need to be cleared on closing it.
             */
            props.dataStore.networkFilters.filter_type = 'all';
            props.dataStore.networkFilters.file_imports = [];
            props.dataStore.networkFilters.import_source = [];
            props.dataStore.networkFilters.team_file_imports = [];
            /** Clear team applied by groupby_top_team_members */
            props.dataStore.teamFilters.uiTeamIds = [[]];
            props.dataStore.teamFilters.team_ids = [];
            props.dataStore.teamFilters.checkedTeamIds = [];
            props.dataStore.groupByValues.team_member_id = '';
            props.dataStore.teamFilters.isAllChecked = false;

            break;
          case 'groupby_marital_status':
          case 'groupby_gender': {
            const key1 = id.substr(8);
            clearFromSelectedGroupBy(key1);
            props.dataStore.groupByValues[key1] = '';
            break;
          }
          case 'groupby_tags':
            clearFromSelectedGroupBy('tags_column');
            props.dataStore.groupByValues.tags = {};
            break;
          case 'filter_type':
            props.dataStore.networkFilters.filter_type = 'all';
            break;
          case 'is_elevator_included':
          case 'is_pool_included':
            props.dataStore.networkFilters[id] = false;
            break;
          case 'is_work_emails_included':
          case 'is_personal_emails_included':
          case 'is_mobile_phones_included':
          case 'is_other_phones_included':
          case 'is_facebook_included':
          case 'is_linkedin_included':
          case 'is_twitter_included':
          case 'is_home_address_included':
          case 'is_household_members_included':
          case 'is_b2c_id_included':
          case 'is_age_verified':
          case 'is_home_address_verified':
          case 'is_home_ownership_verified':
          case 'is_gender_verified':
          case 'is_career_verified':
          case 'is_income_verified':
          case 'is_wealth_verified':
          case 'li_only':
          case 'is_medical_included':
          case 'is_notes_included': {
            const index =
              props.dataStore.networkFilters.attributes_checked.indexOf(id);
            if (index !== -1)
              props.dataStore.networkFilters.attributes_checked.splice(
                index,
                1,
              );
            break;
          }
          case 'attribute_function':
            props.dataStore.networkFilters.attribute_function = true;
            break;
          // case 'is_wealth_events':
          //   props.dataStore.networkFilters.is_wealth_events = 1;
          //   break;
          case 'is_age_included':
          case 'is_norm_home_owner_included':
          case 'is_home_value_estimate_included':
          case 'is_inferred_salary_included':
          case 'is_geo_tax_investable_assets_included':
          case 'is_b2b_id_included':
          case 'is_remaining_share_value_sum_included':
          case 'has_wealth_triggers': {
            const index =
              props.dataStore.networkFilters.wealth_model_checked.indexOf(id);
            if (index !== -1)
              props.dataStore.networkFilters.wealth_model_checked.splice(
                index,
                1,
              );

            break;
          }

          case 'home_search':
          case 'company_search':
            props.dataStore.networkFilters[id] = {
              geo: null,
              address: '',
            };
            break;
          case 'home_value_estimate':
          case 'age':
          case 'home_postal_code':
          case 'current_org_years':
          case 'wealth_estimate_range':
          case 'total_years_of_experience':
            props.dataStore.networkFilters[id] = null;
            break;
          case 'interests':
            props.dataStore.networkFilters.all_interests = false;
            props.dataStore.networkFilters[id] = [];
            break;
          case 'property_type':
          case 'credentials':
          case 'title_rank':
          case 'exc_title_rank':
          case 'exc_title':
          case 'title_role':
          case 'inferred_salary_range':
          case 'stock_performance':
          case 'employee_count':
          case 'number_of_mapped_connections':
          case 'wealth_estimate':
          case 'org_name':
          case 'exc_current_experience':
          case 'previous_experience_org_name':
          case 'location_city':
          case 'schools':
          case 'home_address_suggest':
          case 'exc_home_address_suggest':
          case 'location_state':
          case 'funding_round':
          case 'event_org_name':
          case 'title':
          case 'naics_codes':
          case 'import_source':
          case 'file_imports':
          case 'team_file_imports':
          case 'norm_home_owner':
          case 'number_of_stories':
          case 'number_of_baths':
          case 'number_of_bedrooms':
          case 'number_of_partial_baths':
          case 'total_number_of_rooms':
          case 'lotsize_square_feet':
          case 'number_of_units':
          case 'remaining_share_value_sum':
          case 'number_of_owned_properties':
          case 'garage_cars':
          case 'county':
          case 'tags_checked':
          case 'shared_tags':
          case 'exc_industry':
          case 'latest_mim_monetary_value':
          case 'latest_mim_event_type':
            props.dataStore.networkFilters[id] = [];
            break;
          // case 'latest_mim_monetary_value':
          //   props.dataStore.networkFilters[id] = [];
          //   props.dataStore.networkFilters.latest_mim_monetary_value_range = [];
          //   break;
          // case 'latest_mim_event_type':
          //   props.dataStore.networkFilters[id] = [];
          //   props.dataStore.networkFilters.latest_mim_monetary_value_range = [];
          //   break;
          case 'industry':
            props.dataStore.networkFilters[id] = [];
            /**  management_change_date_range applied from
             *  company profile positions filled graph redirection */
            props.dataStore.networkFilters.management_change_date_range = [];
            break;
          case 'effective_year_built':
            props.dataStore.networkFilters[id] = [];
            break;
          case 'relmap_org':
            props.dataStore.networkFilters[id] = [];
            if (
              props.dataStore.lookUpLists.isRelationPathSelected &&
              (!props.dataStore.networkFilters.relmap_org ||
                props.dataStore.networkFilters.relmap_org.length <= 0)
            ) {
              props.dataStore.networkFilters.filter_type = 'second_degree';
            } else {
              props.dataStore.networkFilters.filter_type = 'all';
            }
            break;
          case 'm2_date':
          case 'home_purchase_date':
          case 'home_sale_date':
            props.dataStore.networkFilters[id] = [];
            break;
          case 'period':
            props.dataStore.networkFilters.period = null;
            props.dataStore.networkFilters.m2_date = [];
            break;
          case 'team':
            props.dataStore.teamFilters.uiTeamIds = [[]];
            props.dataStore.teamFilters.team_ids = [];
            props.dataStore.teamFilters.checkedTeamIds = [];
            props.dataStore.groupByValues.team_member_id = '';
            props.dataStore.teamFilters.isAllChecked = false;
            break;
          case 'gender':
            props.dataStore.networkFilters.gender = null;
            break;
          case 'marital_status':
            props.dataStore.networkFilters.marital_status = null;
            break;
          case 'presence_of_children':
            props.dataStore.networkFilters.presence_of_children = null;
            break;
          case 'quick_search':
            props.dataStore.quick_search = '';
            break;
          case 'company_type':
            props.dataStore.networkFilters.company_type = null;
            break;
          case 'is_mapped_relationships_applied': {
            props.dataStore.networkFilters.mapped_relationships_filter = {};
            props.dataStore.currentUser.userSelectedColumns =
              props.dataStore.currentUser.userSelectedColumns.filter(
                (item) =>
                  item !== 'relationship_status' &&
                  item !== 'relationship_type',
              );

            break;
          }
          case 'is_super_connectors_applied':
            props.dataStore.superConnectorFilter = null;
            break;
          case 'daily_opportunities_date':
          case 'daily_opportunities_id':
            props.dataStore.networkFilters.daily_opportunities_id = null;
            props.dataStore.networkFilters.daily_opportunities_date = null;
            props.dataStore.networkFilters.filter_name = null;
            break;
          case 'opportunities_person_name':
            props.dataStore.networkFilters.mimperson_id = null;
            props.dataStore.networkFilters.opportunities_person_name = null;
            break;
          case 'opportunities_event_name':
            props.dataStore.networkFilters.event_id = null;
            props.dataStore.networkFilters.opportunities_event_name = null;
            break;
          case 'irs_tax_insights':
            props.dataStore.networkFilters[id] = { key: '', gte: '', lte: '' };
            break;
          case 'map_query':
            props.dataStore.networkFilters.mapQuery = {};
            break;
          case 'mapped_relationship_weights':
          case 'mapped_relationship_types':
            props.dataStore.networkFilters.mapped_relationships_filter[id] = [];
            break;
          default:
            props.dataStore.networkFilters[id] = '';
            break;
        }
        if (
          props.dataStore.lookUpLists.map &&
          props.dataStore.lookUpLists.map.getZoom() > 3
        )
          resetMapZoom();
        else props.dataStore.networkFilters.fetchData = true;
        if (!props.dataStore.isNetworkFilterApplied) {
          props.dataStore.networkFilters.selectedIdValue = -1;
        }
      };

      const getGroupByFilter = (key) => {
        if (key === 'previous_experience.org_name')
          key = 'previous_experience_org_name';
        else if (key === 'inferred_salary') key = 'inferred_salary_range';
        if (
          props.dataStore.groupByValues[key] &&
          Array.isArray(props.dataStore.groupByValues[key])
        ) {
          if (props.dataStore.groupByValues[key].length > 0) {
            return FilterLabel(
              `groupby_${key}`,
              getMultipleValueSelected(props.dataStore.groupByValues[key]),
            );
          } else {
            return FilterLabel(`groupby_${key}`, '');
          }
        } else {
          switch (key) {
            case 'property_type':
              if (props.dataStore.groupByValues.property_type)
                return FilterLabel(
                  'groupby_property_type',
                  props.dataStore.groupByValues.property_type,
                );
              else return FilterLabel('groupby_property_type', '');

            case 'mapped_connection_count':
              if (
                props.dataStore.groupByValues.mapped_connection_count &&
                Object.keys(
                  props.dataStore.groupByValues.mapped_connection_count,
                ).length > 0
              ) {
                return FilterLabel(
                  'groupby_mapped_connection_count',
                  props.dataStore.groupByValues.mapped_connection_count.gte ===
                    '200'
                    ? '200+'
                    : `${props.dataStore.groupByValues.mapped_connection_count.gte}
                     -${props.dataStore.groupByValues.mapped_connection_count.lte}`,
                );
              } else {
                return FilterLabel('groupby_mapped_connection_count', '');
              }
            case 'age_range':
              if (
                props.dataStore.groupByValues.age &&
                Object.keys(props.dataStore.groupByValues.age).length > 0
              ) {
                return FilterLabel(
                  'groupby_age',
                  props.dataStore.groupByValues.age.gte === 80
                    ? '80+'
                    : `${props.dataStore.groupByValues.age.gte}-${props.dataStore.groupByValues.age.lte}`,
                );
              } else {
                return FilterLabel('groupby_age', '');
              }
            case 'home_postal_code':
              if (props.dataStore.groupByValues[key]) {
                return FilterLabel(
                  'groupby_home_postal_code',
                  props.dataStore.groupByValues.home_postal_code,
                );
              } else {
                return FilterLabel('groupby_home_postal_code', '');
              }
            case 'marital_status':
              if (
                props.dataStore.groupByValues[key] &&
                props.dataStore.groupByValues[key] !== ''
              ) {
                return FilterLabel(
                  'groupby_marital_status',
                  props.dataStore.groupByValues.marital_status.toUpperCase() ===
                    'M'
                    ? 'Married'
                    : props.dataStore.groupByValues.marital_status.toUpperCase() ===
                        'S'
                      ? 'Single'
                      : '',
                );
              } else {
                return FilterLabel('groupby_marital_status', '');
              }
            case 'gender':
              if (
                props.dataStore.groupByValues[key] &&
                props.dataStore.groupByValues[key] !== ''
              ) {
                return FilterLabel(
                  'groupby_gender',
                  props.dataStore.groupByValues.gender === 'M'
                    ? 'Male'
                    : 'Female',
                );
              }
              return FilterLabel('groupby_gender', '');
            case 'tags_column':
              if (
                props.dataStore.groupByValues.tags &&
                props.dataStore.groupByValues.tags !== ''
              ) {
                return FilterLabel(
                  'groupby_tags',
                  props.dataStore.groupByValues.tags.label,
                );
              }
              return FilterLabel('groupby_tags', '');
            case 'funding_round':
              if (
                props.dataStore.groupByValues.funding_round &&
                props.dataStore.groupByValues.funding_round.length > 0
              ) {
                return FilterLabel(
                  'groupby_funding_round',
                  props.dataStore.groupByValues[key],
                );
              }
              return FilterLabel('groupby_funding_round', '');
            case 'top_team_members':
              if (props.dataStore.groupByValues.top_team_members) {
                return FilterLabel(
                  'groupby_top_team_members',
                  props.dataStore.groupByValues.top_team_members,
                );
              }
              return FilterLabel('groupby_top_team_members', '');
            default:
              break;
          }
        }
      };
      function showPersonProfile(b2bId, b2cId) {
        // profile clicked
        if (!b2bId && !b2cId) {
          message.warning(
            'This profile cannot be opened right now. Please try again later.',
            10,
          );
          return;
        }

        props.modalDataStore.resetCompanyProfileModal();
        props.modalDataStore.resetProfileModal();
        const { profileModal } = props.modalDataStore;
        profileModal.from = 'n2';
        profileModal.b2bId = b2bId;
        profileModal.b2cId = b2cId;
        profileModal.mimpersonId = '';
        profileModal.eventsId = '';
        profileModal.isVisible = true;

        props.modalDataStore.trackModal.push({
          modal: 'profile',
          data: {
            from: 'n2',
            b2bId,
            b2cId,
          },
        });
      }

      const FilterLabel = (id, value) => {
        // if (!value) return filterLabelNames[id] || '';
        return (
          <div className={'filter-label'}>
            <div className="filter-name">
              {flags && flags.neustarId && id === 'is_b2c_id_included'
                ? 'Neustar ID'
                : [
                      'is_personal_emails_included',
                      'is_work_emails_included',
                      'is_facebook_included',
                      'is_home_address_included',
                      'is_household_members_included',
                      'is_mobile_phones_included',
                      'is_other_phones_included',
                      'is_twitter_included',
                      'is_super_connectors_applied',
                      // 'is_basement_included',
                      'is_elevator_included',
                      'is_pool_included',
                      // 'is_b2b_id_included',
                      'is_b2c_id_included',
                      'li_only',
                      'is_medical_included',
                      'map_query',
                      'is_notes_included',
                      'is_age_verified',
                      'is_home_address_verified',
                      'is_home_ownership_verified',
                      'is_gender_verified',
                      'is_career_verified',
                      'is_income_verified',
                      'is_wealth_verified',
                    ].includes(id) || !value
                  ? filterLabelNames[id]
                  : id === 'is_mapped_relationships_applied'
                    ? value
                    : `${filterLabelNames[id]}: ${value}`}
            </div>
            {/* <span style={{width:1,backgroundColor:"#FFF"}}></span> */}
            <CloseOutlined
              onClick={(e) => onClear(e, id)}
              style={{
                width: '11px',
                height: '11px',
                marginLeft: '5px',
                color: 'var(--color-column)',
                fontSize: '10px',
              }}
            />
          </div>
        );
      };

      const resetMapZoom = () => {
        if (props.dataStore.lookUpLists.map) {
          props.dataStore.lookUpLists.map.flyTo({
            zoom: 3,
            center: [-90.039891, 42.058919],
            speed: 2,
          });
        }
      };

      const renderAppliedFilters = () => {
        return (
          <div
            // ref={fixedContainerRef}
            className={'applied-filter-container-new'}
            style={{
              width: 'inherit',
              zIndex: 1,
            }}
            id="applied_filters"
          >
            {props.dataStore.networkFilters.mimperson_id &&
              props.dataStore.networkFilters.opportunities_person_name &&
              props.dataStore.networkFilters.opportunities_person_name !== '' &&
              props.dataStore.networkFilters.opportunities_person_name !==
                ' ' &&
              FilterLabel(
                'opportunities_person_name',
                props.dataStore.networkFilters.opportunities_person_name,
              )}
            {props.dataStore.networkFilters.event_id &&
              props.dataStore.networkFilters.opportunities_event_name &&
              props.dataStore.networkFilters.opportunities_event_name !== '' &&
              props.dataStore.networkFilters.opportunities_event_name !== ' ' &&
              FilterLabel(
                'opportunities_event_name',
                props.dataStore.networkFilters.opportunities_event_name,
              )}
            {props.dataStore.networkFilters.daily_opportunities_id &&
              props.dataStore.networkFilters.daily_opportunities_date &&
              FilterLabel(
                'daily_opportunities_date',
                `${props.dataStore.networkFilters.daily_opportunities_date} ${
                  props.dataStore.networkFilters.filter_name
                    ? 'with filter: ' +
                      props.dataStore.networkFilters.filter_name
                    : ''
                }`,
              )}
            {/* case of redirection from notification */}
            {props.dataStore.networkFilters.daily_opportunities_id &&
              !props.dataStore.networkFilters.daily_opportunities_date &&
              FilterLabel('daily_opportunities_id', 'today')}
            {props.dataStore.quick_search &&
              props.dataStore.quick_search !== '' &&
              props.dataStore.quick_search !== ' ' &&
              FilterLabel('quick_search', props.dataStore.quick_search)}
            {props.dataStore.networkFilters.filter_type &&
              props.dataStore.networkFilters.filter_type !== 'all' &&
              (!props.dataStore.networkFilters.relmap_org ||
                props.dataStore.networkFilters.relmap_org.length <= 0) &&
              !props.dataStore.lookUpLists.isRelationPathSelected &&
              FilterLabel(
                'filter_type',
                getConnectionLabel(props.dataStore.networkFilters.filter_type),
              )}
            {props.dataStore.networkFilters.full_name &&
              props.dataStore.networkFilters.full_name !== '' &&
              props.dataStore.networkFilters.full_name !== ' ' &&
              FilterLabel(
                'full_name',
                props.dataStore.networkFilters.full_name,
              )}
            {props.dataStore.networkFilters.age &&
              props.dataStore.networkFilters.age.key &&
              props.dataStore.networkFilters.age.value &&
              FilterLabel(
                'age',
                getRangeLabel('age', props.dataStore.networkFilters.age),
              )}
            {props.dataStore.networkFilters.gender &&
              props.dataStore.networkFilters.gender.value &&
              FilterLabel(
                'gender',
                props.dataStore.networkFilters.gender.value === 'M'
                  ? 'Male'
                  : 'Female',
              )}
            {props.dataStore.networkFilters.marital_status &&
              props.dataStore.networkFilters.marital_status.value &&
              FilterLabel(
                'marital_status',
                props.dataStore.networkFilters.marital_status.value.toUpperCase() ===
                  'M'
                  ? 'Married'
                  : props.dataStore.networkFilters.marital_status.value.toUpperCase() ===
                      'S'
                    ? 'Single'
                    : '',
              )}
            {props.dataStore.networkFilters.tags_checked &&
              props.dataStore.networkFilters.tags_checked.length > 0 &&
              FilterLabel(
                'tags_checked',
                getTagsValueSelected(
                  props.dataStore.networkFilters.tags_checked,
                  props.dataStore.userTags,
                ),
              )}
            {props.dataStore.networkFilters.shared_tags &&
              props.dataStore.networkFilters.shared_tags.length > 0 &&
              FilterLabel(
                'shared_tags',
                getSharedTagsSelected(
                  props.dataStore.networkFilters.shared_tags,
                  props.dataStore.sharedTags,
                ),
              )}

            {props.dataStore.networkFilters.attributes_checked &&
              props.dataStore.networkFilters.attributes_checked.length > 0 &&
              props.dataStore.networkFilters.attributes_checked.map((key) => {
                return FilterLabel(key, 'Included', key);
              })}
            {props.dataStore.networkFilters.wealth_model_checked &&
              props.dataStore.networkFilters.wealth_model_checked.length > 0 &&
              props.dataStore.networkFilters.wealth_model_checked.map((key) => {
                return FilterLabel(key, 'Included');
              })}

            {props.dataStore.networkFilters.home_address_suggest &&
              props.dataStore.networkFilters.home_address_suggest.length > 0 &&
              FilterLabel(
                'home_address_suggest',
                getExactOrPartialValueSearched(
                  props.dataStore.networkFilters.home_address_suggest,
                ),
              )}
            {props.dataStore.networkFilters.exc_home_address_suggest &&
              props.dataStore.networkFilters.exc_home_address_suggest.length >
                0 &&
              FilterLabel(
                'exc_home_address_suggest',
                getExactOrPartialValueSearched(
                  props.dataStore.networkFilters.exc_home_address_suggest,
                ),
              )}
            {/* {props.dataStore.networkFilters.home_address_suggest &&
props.dataStore.networkFilters.home_address_suggest !== '' &&
props.dataStore.networkFilters.home_address_suggest !== ' ' &&
FilterLabel(
  'home_address_suggest',
  props.dataStore.networkFilters.home_address_suggest,
)} */}
            {props.dataStore.networkFilters.home_search &&
              props.dataStore.networkFilters.home_search.geo &&
              props.dataStore.networkFilters.home_search.address &&
              FilterLabel(
                'home_search',
                props.dataStore.networkFilters.home_search.address,
              )}
            {props.dataStore.teamFilters.checkedTeamIds &&
              props.dataStore.teamFilters.checkedTeamIds.length > 0 &&
              FilterLabel('team', 'Applied')}
            {(!props.dataStore.networkFilters.relmap_org ||
              props.dataStore.networkFilters.relmap_org.length <= 0) &&
              !props.dataStore.lookUpLists.isRelationPathSelected &&
              props.dataStore.networkFilters.import_source &&
              props.dataStore.networkFilters.import_source.length > 0 &&
              (props.dataStore.networkFilters.filter_type === 'all' ||
                props.dataStore.networkFilters.import_source.length <=
                  props.dataStore.lookUpLists.import_source.length) &&
              FilterLabel(
                'import_source',
                handleImportSource(
                  props.dataStore.networkFilters.import_source,
                ),
              )}
            {(!props.dataStore.networkFilters.relmap_org ||
              props.dataStore.networkFilters.relmap_org.length <= 0) &&
              !props.dataStore.lookUpLists.isRelationPathSelected &&
              (!flags ||
                !flags.csvImportsAndDegrees ||
                props.dataStore.networkFilters.filter_type === 'all') &&
              props.dataStore.networkFilters.file_imports &&
              props.dataStore.networkFilters.file_imports.length > 0 &&
              (props.dataStore.networkFilters.filter_type === 'all' ||
                props.dataStore.networkFilters.file_imports.length <=
                  props.dataStore.lookUpLists.fileImportsWithoutEnrich
                    .length) &&
              FilterLabel(
                'file_imports',
                getCsvSource(
                  props.dataStore.networkFilters.filter_type === 'all'
                    ? props.dataStore.lookUpLists.fileImports
                    : props.dataStore.lookUpLists.fileImportsWithoutEnrich,
                  props.dataStore.networkFilters.file_imports,
                ),
              )}
            {(!flags ||
              !flags.csvImportsAndDegrees ||
              props.dataStore.networkFilters.filter_type === 'all') &&
              (!props.dataStore.networkFilters.relmap_org ||
                props.dataStore.networkFilters.relmap_org.length <= 0) &&
              !props.dataStore.lookUpLists.isRelationPathSelected &&
              props.dataStore.networkFilters.team_file_imports &&
              props.dataStore.networkFilters.team_file_imports.length > 0 &&
              (props.dataStore.networkFilters.filter_type === 'all' ||
                (props.dataStore.networkFilters.filter_type ===
                  'first_degree' &&
                  props.dataStore.networkFilters.team_file_imports.length <=
                    props.dataStore.lookUpLists.addImportsToTeam.length) ||
                props.dataStore.networkFilters.team_file_imports.length <=
                  props.dataStore.lookUpLists.teamFileImportsWithoutEnrich
                    .length) &&
              FilterLabel(
                'team_file_imports',
                getCsvSource(
                  props.dataStore.networkFilters.filter_type === 'all'
                    ? props.dataStore.lookUpLists.teamFileImports
                    : props.dataStore.networkFilters.filter_type !==
                        'first_degree'
                      ? props.dataStore.lookUpLists.teamFileImportsWithoutEnrich
                      : props.dataStore.lookUpLists.addImportsToTeam,
                  props.dataStore.networkFilters.team_file_imports,
                ),
              )}
            {props.dataStore.networkFilters.naics_codes &&
              props.dataStore.networkFilters.naics_codes.length > 0 &&
              FilterLabel(
                'naics_codes',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.naics_codes,
                ),
              )}
            {props.dataStore.networkFilters.norm_home_owner &&
              props.dataStore.networkFilters.norm_home_owner.length > 0 &&
              FilterLabel(
                'norm_home_owner',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.norm_home_owner,
                ),
              )}

            {props.dataStore.networkFilters.location_city &&
              props.dataStore.networkFilters.location_city.length > 0 &&
              FilterLabel(
                'location_city',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.location_city,
                ),
              )}
            {props.dataStore.networkFilters.location_state &&
              props.dataStore.networkFilters.location_state.length > 0 &&
              FilterLabel(
                'location_state',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.location_state,
                ),
              )}
            {props.dataStore.networkFilters.home_postal_code &&
              props.dataStore.networkFilters.home_postal_code.key &&
              props.dataStore.networkFilters.home_postal_code.value &&
              FilterLabel(
                'home_postal_code',
                getRangeLabel(
                  'home_postal_code',
                  props.dataStore.networkFilters.home_postal_code,
                ),
              )}
            {props.dataStore.networkFilters.county &&
              props.dataStore.networkFilters.county.length > 0 &&
              FilterLabel(
                'county',
                getMultipleValueSelected(props.dataStore.networkFilters.county),
              )}
            {props.dataStore.networkFilters.presence_of_children &&
              FilterLabel(
                'presence_of_children',
                props.dataStore.networkFilters.presence_of_children.value
                  ? 'Yes'
                  : props.dataStore.networkFilters.presence_of_children
                      .value !== null && 'No',
              )}
            {props.dataStore.networkFilters.wealth_estimate &&
              props.dataStore.networkFilters.wealth_estimate.length > 0 &&
              FilterLabel(
                'wealth_estimate',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.wealth_estimate,
                ),
              )}

            {props.dataStore.networkFilters.remaining_share_value_sum &&
              props.dataStore.networkFilters.remaining_share_value_sum.length >
                0 &&
              FilterLabel(
                'remaining_share_value_sum',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.remaining_share_value_sum,
                ),
              )}
            {props.dataStore.networkFilters.interests &&
              props.dataStore.networkFilters.interests.length > 0 &&
              FilterLabel(
                'interests',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.interests,
                ),
                // props.dataStore.networkFilters.interests[0].label,
              )}

            {props.dataStore.networkFilters.org_name &&
              props.dataStore.networkFilters.org_name.length > 0 &&
              FilterLabel(
                'org_name',
                getExactOrPartialValueSearched(
                  props.dataStore.networkFilters.org_name,
                ),
              )}
            {props.dataStore.networkFilters.previous_experience_org_name &&
              props.dataStore.networkFilters.previous_experience_org_name
                .length > 0 &&
              FilterLabel(
                'previous_experience_org_name',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.previous_experience_org_name,
                ),
              )}

            {props.dataStore.networkFilters.exc_current_experience &&
              props.dataStore.networkFilters.exc_current_experience.length >
                0 &&
              FilterLabel(
                'exc_current_experience',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.exc_current_experience,
                ),
              )}
            {props.dataStore.networkFilters.current_org_years &&
              props.dataStore.networkFilters.current_org_years.key &&
              props.dataStore.networkFilters.current_org_years.value &&
              FilterLabel(
                'current_org_years',
                getRangeLabel(
                  'current_org_years',
                  props.dataStore.networkFilters.current_org_years,
                ),
              )}

            {props.dataStore.networkFilters.wealth_estimate_range &&
              props.dataStore.networkFilters.wealth_estimate_range.key &&
              props.dataStore.networkFilters.wealth_estimate_range.value &&
              FilterLabel(
                'wealth_estimate_range',
                getRangeLabel(
                  'wealth_estimate_range',
                  props.dataStore.networkFilters.wealth_estimate_range,
                ),
              )}
            {props.dataStore.networkFilters.total_years_of_experience &&
              props.dataStore.networkFilters.total_years_of_experience.key &&
              props.dataStore.networkFilters.total_years_of_experience.value &&
              FilterLabel(
                'total_years_of_experience',
                getRangeLabel(
                  'total_years_of_experience',
                  props.dataStore.networkFilters.total_years_of_experience,
                ),
              )}
            {props.dataStore.networkFilters.industry &&
              props.dataStore.networkFilters.industry.length > 0 &&
              FilterLabel(
                'industry',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.industry,
                ),
              )}
            {props.dataStore.networkFilters.exc_industry &&
              props.dataStore.networkFilters.exc_industry.length > 0 &&
              FilterLabel(
                'exc_industry',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.exc_industry,
                ),
              )}
            {props.dataStore.networkFilters.credentials &&
              props.dataStore.networkFilters.credentials.length > 0 &&
              FilterLabel(
                'credentials',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.credentials,
                ),
              )}
            {props.dataStore.networkFilters.title_rank &&
              props.dataStore.networkFilters.title_rank.length > 0 &&
              FilterLabel(
                'title_rank',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.title_rank,
                ),
                // props.dataStore.networkFilters.title_rank[0].label,
              )}
            {props.dataStore.networkFilters.title &&
              props.dataStore.networkFilters.title.length > 0 &&
              FilterLabel(
                'title',
                getExactOrPartialValueSearched(
                  props.dataStore.networkFilters.title,
                ),
              )}
            {props.dataStore.networkFilters.exc_title_rank &&
              props.dataStore.networkFilters.exc_title_rank.length > 0 &&
              FilterLabel(
                'exc_title_rank',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.exc_title_rank,
                ),
              )}
            {props.dataStore.networkFilters.exc_title &&
              props.dataStore.networkFilters.exc_title.length > 0 &&
              FilterLabel(
                'exc_title',
                getExactOrPartialValueSearched(
                  props.dataStore.networkFilters.exc_title,
                ),
              )}
            {props.dataStore.networkFilters.title_role &&
              props.dataStore.networkFilters.title_role.length > 0 &&
              FilterLabel(
                'title_role',
                getExactOrPartialValueSearched(
                  props.dataStore.networkFilters.title_role,
                ),
              )}
            {props.dataStore.networkFilters.schools &&
              props.dataStore.networkFilters.schools.length > 0 &&
              FilterLabel(
                'schools',
                getExactOrPartialValueSearched(
                  props.dataStore.networkFilters.schools,
                ),
              )}
            {props.dataStore.networkFilters.company_search &&
              props.dataStore.networkFilters.company_search.geo &&
              props.dataStore.networkFilters.company_search.address &&
              FilterLabel(
                'company_search',
                props.dataStore.networkFilters.company_search.address,
              )}
            {(props.dataStore.lookUpLists.isPeoplePage ||
              props.dataStore.lookUpLists.isSummaryPage) &&
              props.dataStore.networkFilters.stock_performance &&
              props.dataStore.networkFilters.stock_performance.length > 0 &&
              FilterLabel(
                'stock_performance',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.stock_performance,
                ),
              )}
            {props.dataStore.networkFilters.number_of_mapped_connections &&
              props.dataStore.networkFilters.number_of_mapped_connections
                .length > 0 &&
              FilterLabel(
                'number_of_mapped_connections',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.number_of_mapped_connections,
                ),
              )}
            {props.dataStore.networkFilters.employee_count &&
              props.dataStore.networkFilters.employee_count.length > 0 &&
              FilterLabel(
                'employee_count',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.employee_count,
                ),
              )}
            {props.dataStore.networkFilters.inferred_salary_range &&
              props.dataStore.networkFilters.inferred_salary_range.length > 0 &&
              FilterLabel(
                'inferred_salary_range',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.inferred_salary_range,
                ),
              )}
            {props.dataStore.networkFilters.latest_mim_event_type &&
              props.dataStore.networkFilters.latest_mim_event_type.length > 0 &&
              FilterLabel(
                'latest_mim_event_type',
                // getMultipleValueSelected(
                //   props.dataStore.networkFilters.latest_mim_event_type,
                // ),
                getEventsLabel(
                  props.dataStore.lookUpLists.eventList,
                  props.dataStore.networkFilters.latest_mim_event_type,
                ),
                // props.dataStore.networkFilters.latest_mim_event_type[0].label,
              )}
            {/* {((props.dataStore.networkFilters.latest_mim_monetary_value &&
              props.dataStore.networkFilters.latest_mim_monetary_value.key &&
              props.dataStore.networkFilters.latest_mim_monetary_value.value) ||
              (props.dataStore.networkFilters.latest_mim_monetary_value_range &&
                props.dataStore.networkFilters.latest_mim_monetary_value_range
                  .length > 0)) &&
              FilterLabel(
                'latest_mim_monetary_value',
                [
                  getRangeLabel(
                    'latest_mim_monetary_value',
                    props.dataStore.networkFilters.latest_mim_monetary_value,
                  ),
                  getMultipleValueSelected(
                    props.dataStore.networkFilters
                      .latest_mim_monetary_value_range,
                  ),
                ]
                  .filter(Boolean)
                  .join(', '),
              )} */}
            {props.dataStore.networkFilters.latest_mim_monetary_value &&
              props.dataStore.networkFilters.latest_mim_monetary_value.length >
                0 &&
              FilterLabel(
                'latest_mim_monetary_value',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.latest_mim_monetary_value,
                ),
              )}

            {(!props.dataStore.networkFilters.period ||
              props.dataStore.networkFilters.period === '' ||
              props.dataStore.networkFilters.period.value === '') &&
              props.dataStore.networkFilters.m2_date &&
              props.dataStore.networkFilters.m2_date.length > 1 &&
              FilterLabel(
                'm2_date',
                getDateRange(props.dataStore.networkFilters.m2_date),
              )}
            {props.dataStore.networkFilters.home_purchase_date &&
              props.dataStore.networkFilters.home_purchase_date.length > 0 &&
              FilterLabel(
                'home_purchase_date',
                getDateRange(props.dataStore.networkFilters.home_purchase_date),
              )}
            {props.dataStore.networkFilters.home_sale_date &&
              props.dataStore.networkFilters.home_sale_date.length > 0 &&
              FilterLabel(
                'home_sale_date',
                getDateRange(props.dataStore.networkFilters.home_sale_date),
              )}

            {props.dataStore.networkFilters.period &&
              props.dataStore.networkFilters.period.value &&
              FilterLabel(
                'period',
                props.dataStore.networkFilters.period.value.toTitleCase(),
              )}
            {props.dataStore.networkFilters.relmap_org &&
              props.dataStore.networkFilters.relmap_org.length > 0 &&
              FilterLabel(
                'relmap_org',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.relmap_org,
                ),
              )}

            {props.dataStore.networkFilters.funding_round &&
              props.dataStore.networkFilters.funding_round.length > 0 &&
              FilterLabel(
                'funding_round',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.funding_round,
                ),
              )}
            {props.dataStore.networkFilters.event_org_name &&
              props.dataStore.networkFilters.event_org_name.length > 0 &&
              FilterLabel(
                'event_org_name',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.event_org_name,
                ),
              )}

            {props.dataStore.networkFilters.company_type &&
              props.dataStore.networkFilters.company_type.label !== '' &&
              FilterLabel(
                'company_type',
                props.dataStore.networkFilters.company_type.label,
              )}

            {props.dataStore.selectedGroupByParam &&
              props.dataStore.selectedGroupByParam.length > 0 && (
                <>
                  {props.dataStore.selectedGroupByParam.map((item) => {
                    return getGroupByFilter(item);
                  })}
                </>
              )}
            {props.dataStore.networkFilters.mapped_relationships_filter &&
              (props.dataStore.networkFilters.mapped_relationships_filter
                .mapped_source_b2b_id ||
                props.dataStore.networkFilters.mapped_relationships_filter
                  .mapped_source_b2c_id) &&
              props.dataStore.networkFilters.mapped_relationships_filter
                .mapped_source_filter_name &&
              FilterLabel(
                'is_mapped_relationships_applied',
                <div>
                  {
                    props.dataStore.networkFilters.mapped_relationships_filter
                      .mapped_source_filter_name
                  }
                  <span
                    className="mapped-link"
                    onClick={() => {
                      showPersonProfile(
                        props.dataStore.networkFilters
                          .mapped_relationships_filter.mapped_source_b2b_id,
                        props.dataStore.networkFilters
                          .mapped_relationships_filter.mapped_source_b2c_id,
                      );
                    }}
                  >
                    Go back to Profile
                  </span>
                </div>,
              )}
            {/**below two are filters applied in mapped rel table in profile
            which are not selectables in prospect finder */}
            {props.dataStore.networkFilters.mapped_relationships_filter
              .mapped_relationship_weights &&
              props.dataStore.networkFilters.mapped_relationships_filter
                .mapped_relationship_weights.length > 0 &&
              FilterLabel(
                'mapped_relationship_weights',
                props.dataStore.networkFilters.mapped_relationships_filter.mapped_relationship_weights
                  .join(', ')
                  .toString()
                  .replace('medium', 'med')
                  .toTitleCase(),
              )}
            {props.dataStore.networkFilters.mapped_relationships_filter
              .mapped_relationship_types &&
              props.dataStore.networkFilters.mapped_relationships_filter
                .mapped_relationship_types.length > 0 &&
              FilterLabel(
                'mapped_relationship_types',
                props.dataStore.networkFilters.mapped_relationships_filter.mapped_relationship_types.join(
                  ',',
                ),
              )}
            {props.dataStore.superConnectorFilter &&
              FilterLabel('is_super_connectors_applied', 'Applied')}

            {props.dataStore.networkFilters.is_elevator_included &&
              FilterLabel('is_elevator_included', 'Included')}
            {props.dataStore.networkFilters.garage_cars &&
              props.dataStore.networkFilters.garage_cars.length > 0 &&
              FilterLabel(
                'garage_cars',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.garage_cars,
                ),
              )}
            {props.dataStore.networkFilters.number_of_owned_properties &&
              props.dataStore.networkFilters.number_of_owned_properties.length >
                0 &&
              FilterLabel(
                'number_of_owned_properties',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.number_of_owned_properties,
                ),
              )}
            {props.dataStore.networkFilters.is_pool_included &&
              FilterLabel('is_pool_included', 'Included')}
            {props.dataStore.networkFilters.number_of_stories &&
              props.dataStore.networkFilters.number_of_stories.length > 0 &&
              FilterLabel(
                'number_of_stories',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.number_of_stories,
                ),
              )}
            {props.dataStore.networkFilters.number_of_baths &&
              props.dataStore.networkFilters.number_of_baths.length > 0 &&
              FilterLabel(
                'number_of_baths',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.number_of_baths,
                ),
              )}
            {props.dataStore.networkFilters.number_of_bedrooms &&
              props.dataStore.networkFilters.number_of_bedrooms.length > 0 &&
              FilterLabel(
                'number_of_bedrooms',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.number_of_bedrooms,
                ),
              )}
            {props.dataStore.networkFilters.number_of_partial_baths &&
              props.dataStore.networkFilters.number_of_partial_baths.length >
                0 &&
              FilterLabel(
                'number_of_partial_baths',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.number_of_partial_baths,
                ),
              )}
            {props.dataStore.networkFilters.total_number_of_rooms &&
              props.dataStore.networkFilters.total_number_of_rooms.length > 0 &&
              FilterLabel(
                'total_number_of_rooms',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.total_number_of_rooms,
                ),
              )}
            {props.dataStore.networkFilters.lotsize_square_feet &&
              props.dataStore.networkFilters.lotsize_square_feet.length > 0 &&
              FilterLabel(
                'lotsize_square_feet',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.lotsize_square_feet,
                ),
              )}
            {props.dataStore.networkFilters.number_of_units &&
              props.dataStore.networkFilters.number_of_units.length > 0 &&
              FilterLabel(
                'number_of_units',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.number_of_units,
                ),
              )}
            {props.dataStore.networkFilters.property_type &&
              props.dataStore.networkFilters.property_type.length > 0 &&
              FilterLabel(
                'property_type',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.property_type,
                ),
              )}

            {props.dataStore.networkFilters.home_value_estimate &&
              Array.isArray(
                props.dataStore.networkFilters.home_value_estimate,
              ) &&
              props.dataStore.networkFilters.home_value_estimate.length > 0 &&
              FilterLabel(
                'home_value_estimate',
                getMultipleValueSelected(
                  props.dataStore.networkFilters.home_value_estimate,
                ),
              )}

            {props.dataStore.networkFilters.home_value_estimate &&
              props.dataStore.networkFilters.home_value_estimate.key &&
              props.dataStore.networkFilters.home_value_estimate.value &&
              FilterLabel(
                'home_value_estimate',
                getRangeLabel(
                  'home_value_estimate',
                  props.dataStore.networkFilters.home_value_estimate,
                ),
              )}

            {props.dataStore.networkFilters.effective_year_built &&
              props.dataStore.networkFilters.effective_year_built.length > 0 &&
              FilterLabel(
                'effective_year_built',
                `${extractYear(
                  props.dataStore.networkFilters.effective_year_built[0],
                )} - ${extractYear(
                  props.dataStore.networkFilters.effective_year_built[1],
                )}`,
              )}
            {props.dataStore.networkFilters.irs_tax_insights &&
              props.dataStore.networkFilters.irs_tax_insights.key &&
              FilterLabel(
                'irs_tax_insights',
                `${getLabel(
                  IrsItems,
                  props.dataStore.networkFilters.irs_tax_insights.key,
                )}
    ${getIrsRangeLabel(
      props.dataStore.networkFilters.irs_tax_insights.gte,
      props.dataStore.networkFilters.irs_tax_insights.lte,
    )}`,
              )}
            {props.dataStore.lookUpLists.isPeoplePage &&
              props.dataStore.networkFilters.mapQuery &&
              Object.keys(props.dataStore.networkFilters.mapQuery).length > 0 &&
              FilterLabel('map_query', props.dataStore.networkFilters.mapQuery)}
          </div>
        );
      };

      return (
        <>
          <div
            className="applied-filter-outer-class-new"
            id="applied_filters_container"
            ref={fixedContainerRef}
          >
            <Collapse
              size="small"
              activeKey={isOpen ? '1' : []}
              onChange={() => {
                setIsOpen((prevState) => !prevState);
              }}
              expandIcon={({ isActive }) => (
                <CaretRightFilled
                  style={{ color: 'var(--color-column)', fontSize: '16px' }}
                  rotate={isActive ? 90 : 0}
                />
              )}
              // items={[
              //   {
              //     key: '1',
              //     label: 'This is large size panel header',
              //     children: <p>{"text"}</p>,
              //   },
              // ]}
            >
              <Collapse.Panel
                header={isOpen ? <></> : <>{renderAppliedFilters()}</>}
                key="1"
                bordered={false}
                extra={
                  <div
                    style={{
                      color: 'var(--color-link)',
                      cursor: 'pointer',
                      fontSize: 12,
                      lineHeight: 2,
                      paddingRight: '5px',
                      width: 'max-content',
                    }}
                    onClick={() => {
                      props.dataStore.resetNetworkFilters();
                      props.dataStore.importSourceKeys.isImportSourceChecked = false;
                      props.dataStore.importSourceKeys.isImportSourceSaved = false;
                      props.dataStore.superConnectorFilter = null;
                      props.dataStore.quick_search = '';
                      localStorage.removeItem('tempNetworkFilters');
                      if (
                        props.dataStore.lookUpLists.map &&
                        props.dataStore.lookUpLists.map.getZoom() > 3
                      )
                        resetMapZoom();
                      else props.dataStore.networkFilters.fetchData = true;
                    }}
                  >
                    Clear Search
                  </div>
                }
              >
                <div>{renderAppliedFilters()}</div>
              </Collapse.Panel>
            </Collapse>
          </div>
        </>
      );
    }),
  ),
);

export default AppliedFilter;
