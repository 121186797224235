// init Sentry
import './instrument';

import { Provider } from 'mobx-react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { PostHogProvider } from 'posthog-js/react';

import App from './App';
import DataStore from './stores/DataStore';
import GlobalDataStore from './stores/GlobalDataStore';
import ModalDataStore from './stores/ModalDataStore';
import { getDomain } from './utils/general';
// import reportWebVitals from './reportWebVitals';

const {
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID,
  VITE_LAUNCHDARKLY_CLIENT_SIDE_ID,
  VITE_POSTHOG_HOST,
  VITE_POSTHOG_API_KEY,
} = import.meta.env;

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: VITE_LAUNCHDARKLY_CLIENT_SIDE_ID,
    // Initialize with a common anonymous user key to limit client-side MAU
    // https://docs.launchdarkly.com/home/users/anonymous-users#understanding-how-anonymous-users-contribute-to-mau
    user: {
      key: 'anonymous_user',
      anonymous: true,
      name: 'Anonymous User',
      email: 'nobody@aidentified.com',
    },
  });

  const postHogOptions = {
    api_host: VITE_POSTHOG_HOST,
    session_idle_timeout_seconds: 60,
    before_send: function (capture_result) {
      if (!capture_result) {
        return null;
      }

      capture_result.properties = {
        ...{},
        ...capture_result.properties,
        $ai_application: 'relmap',
      };

      // only cpature events that have an identified user (based on django identified users)
      if (
        !capture_result.properties.distinct_id ||
        (capture_result.properties.distinct_id &&
          !/^\d+$/.test(capture_result.properties.distinct_id))
      ) {
        // returning null will ensure that we fall out of the "capture" events in posthog:
        // https://github.com/PostHog/posthog-js/blob/main/src/posthog-core.ts#L915
        return null;
      }

      capture_result.$set = {
        ...{},
        ...capture_result.$set,
        $ai_application: 'relmap',
      };
      capture_result.$set_once = {
        ...{},
        ...capture_result.$set,
        $ai_application: 'relmap',
      };
      return capture_result;
    },
  };

  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <Auth0Provider
      domain={VITE_AUTH0_DOMAIN}
      clientId={VITE_AUTH0_CLIENT_ID}
      redirectUri={`${getDomain()}/user/login/callback`}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      authorizationParams={{
        forgotPasswordLink: `${getDomain()}/user/forgot-password`,
      }}
    >
      <PostHogProvider apiKey={VITE_POSTHOG_API_KEY} options={postHogOptions}>
        <Provider
          globalDataStore={new GlobalDataStore()}
          dataStore={new DataStore()}
          modalDataStore={new ModalDataStore()}
        >
          <LDProvider>
            {/* <React.StrictMode> */}
            <App />
          </LDProvider>
          {/* </React.StrictMode> */}
        </Provider>
      </PostHogProvider>
    </Auth0Provider>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// XX: 2024-11-08 dkoch: disabling until we have a logging solution
// reportWebVitals();
