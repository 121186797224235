import React from 'react';
import LoaderIcon from '../../assets/icons/ai_ic_logo_gold.svg?react';

interface LoadingSpinnerParams {
  isLoading: boolean;
}

export const LoadingSpinner: React.FC<LoadingSpinnerParams> = ({
  isLoading = true,
}: LoadingSpinnerParams) => {
  return (
    <>
      {isLoading && (
        <LoaderIcon className="aid-sidecar-ext-logo-spin-icon-only" />
      )}
    </>
  );
};
