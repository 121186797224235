import './index.css';

import { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { commarize, splitNumberWithCommas } from '../../utils/general';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

class HorizontalChart extends Component {
  render() {
    const { data, id } = this.props;
    const onClickHandler = this.props.click;

    const getClickedItem = (id, index) => {
      let clickedItem = null;
      const { data } = this.props;

      if (id === 'title_rank') {
        clickedItem = {};
        clickedItem.value = data.toolTipLabels[index];
        clickedItem.label = data.labels[index];
      } else if (id === 'team_ids') {
        clickedItem = [data.toolTipLabels[index]];
      } else if (id === 'org_name' || id === 'client_inv_org_id') {
        clickedItem = {
          value: data.keyLabels[index],
          label: data.labels[index],
        };
      } else {
        if (index !== -1) clickedItem = data.keyLabels[index];
      }

      return clickedItem;
    };

    const options = {
      indexAxis: 'y',
      maintainAspectRatio: false,
      maxBarThickness: 6,
      hoverBackgroundColor: getComputedStyle(document.body).getPropertyValue(
        '--color-secondary',
      ),
      interaction: {
        mode: 'index',
      },
      plugins: {
        tooltip: {
          backgroundColor: getComputedStyle(document.body).getPropertyValue(
            '--color-white',
          ),
          titleColor: getComputedStyle(document.body).getPropertyValue(
            '--color-black',
          ),
          bodyColor: getComputedStyle(document.body).getPropertyValue(
            '--color-black',
          ),
          borderColor: getComputedStyle(document.body).getPropertyValue(
            '--color-light',
          ),
          borderWidth: 1,
          caretPadding: 10,
          padding: 10,
          xAlign: 'center',
          yAlign: 'top',
          callbacks: {
            label: function (context) {
              /** for state and zip, need to show the {state/zip: label} on hover, 
              for all others need to show only {label}. */
              let label;
              if (id === 'location_state' || id === 'home_postal_code') {
                label =
                  data.toolTipLabels[data.labels.indexOf(context.label)] || '';
                if (label) {
                  label += ': ';
                }
                label += splitNumberWithCommas(Number(context.parsed.x));
              } else {
                label = splitNumberWithCommas(Number(context.parsed.x));
              }

              return label;
            },
            labelColor: function (context) {
              return {
                borderColor: getComputedStyle(document.body).getPropertyValue(
                  '--color-light',
                ),
                backgroundColor: context.element.options.backgroundColor,
                borderWidth: 2,
                borderRadius: 2,
              };
            },
          },
        },
        legend: {
          display: false,
        },

        datalabels: {
          color: getComputedStyle(document.body).getPropertyValue(
            '--color-primary',
          ),
          listeners: {
            click: function (e) {
              if (e.dataIndex < 0) return;

              const labelClicked = getClickedItem(id, e.dataIndex);
              onClickHandler(id, labelClicked);
            },
          },

          labels: {
            l1: {
              align: 'start',
              marginLeft: 15,
              color: getComputedStyle(document.body).getPropertyValue(
                '--color-dark-shade',
              ),
              formatter: (value, context) => {
                if (value) return commarize(value);
              },
            },
          },
          align: 'start',
          anchor: 'start',
          padding: 0,
          font: {
            size: 12,
          },
        },
      },

      onClick: function (e, chartElement) {
        if (!chartElement || chartElement.length <= 0) return; // Clicked outside any bar.
        const clickedItem = getClickedItem(id, chartElement[0].index);
        // if(id === 'client_inv_org_id')
        //   showCompanyProfile(clickedItem.value, clickedItem.label)
        // else
        onClickHandler(id, clickedItem);
      },
      onHover: function (event, legendItem, legend) {
        event.chart.canvas.parentNode.style.cursor = legendItem[0]
          ? 'pointer'
          : 'default';
      },
      scales: {
        x: {
          display: false,
          grid: {
            display: false,
          },
          offset: false,
        },

        y: {
          ticks: {
            callback: function (val, index) {
              const tickVal = this.getLabelForValue(val);
              if (tickVal.length > 25) return `${tickVal.substr(0, 25)}...`; // truncate
              return tickVal;
            },
            color: getComputedStyle(document.body).getPropertyValue(
              '--color-primary',
            ),
            font: {
              size: 12,
            },
            padding: 45,
          },

          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
    };

    let chartHeight = 300;
    if (data && data.labels) {
      const numOfBars = data.labels.length;
      chartHeight = numOfBars >= 10 ? (400 * numOfBars) / 10 : 300;
    }

    const emptyData = {
      labels: [],
      datasets: [],
    };
    return (
      <div className="chartWrapper">
        <div
          className="chartAreaWrapper"
          style={{
            height: chartHeight,
          }}
        >
          <Bar
            data={this.props.data.datasets ? this.props.data : emptyData}
            options={options}
          />
        </div>
      </div>
    );
  }
}

export default HorizontalChart;
