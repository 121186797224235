import { Button, Input, message } from 'antd';
import { useEffect, useState } from 'react';

import { requestWithAuth } from '../../services/api';
import AiTooltip from '../AiTooltip';

const { TextArea } = Input;

function Notes(props) {
  const [IsButtonEnable, setIsButtonEnable] = useState(false);
  const [note, setNote] = useState('');

  useEffect(() => {
    const { profile_history_details = {} } = props.person;
    if (
      profile_history_details &&
      profile_history_details.note_details &&
      profile_history_details.note_details.note
    ) {
      setNote(profile_history_details.note_details.note);
    } else {
      setNote('');
    }
  }, [props.person.profile_history_details]);

  const handleSubmit = () => {
    const { b2b_id, b2c_id } = props.person;

    const body = {
      b2b_id: b2b_id,
      b2c_id: b2c_id,
      note: note.trim(),
    };

    requestWithAuth('profile-notes', 'POST', null, body, null, null).then(
      (res) => {
        if (res) {
          setIsButtonEnable(false);
          if (props.updatePerson)
            props.updatePerson('note_details', note.trim());
          message.success('Note Saved');
          if (props.onClose) props.onClose();
        }
      },
    );
  };

  return (
    <div className="profile-comment">
      <div
        className="profile-box-header profile-titles"
        style={{ justifyContent: 'flex-start' }}
      >
        Notes
        <AiTooltip
          placement="top"
          marginBottom="0"
          title="Notes are only visible to you and are to be used in compliance with your corporate guidelines."
        />
      </div>
      <div
        className="notes-section"
        style={{ margin: 'auto', width: '80%', height: 'calc(100% - 53px)' }}
      >
        <TextArea
          className="text-area-content notes-text-area"
          value={note}
          onInput={() => setIsButtonEnable(true)}
          onChange={(e) => {
            setNote(e.target.value);
          }}
        />
        <Button
          className={IsButtonEnable ? 'send-btn' : 'inactive-btn'}
          htmlType="submit"
          onClick={handleSubmit}
          disabled={!IsButtonEnable}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default Notes;
