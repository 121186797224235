import './index.scss';

import { Button, message } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';

import image1 from '../../assets/images/ai_salesforce1.png';
import image2 from '../../assets/images/ai_salesforce2.png';
import image3 from '../../assets/images/ai_salesforce3.png';
import image4 from '../../assets/images/ai_salesforce4.png';
import image5 from '../../assets/images/ai_salesforce5.png';
import image6 from '../../assets/images/ai_salesforce6.png';
import connectIcon from '../../assets/icons/ai_export_connect.svg?react';
import iconSalesForce from '../../assets/icons/ai_salesforce.svg?react';

import { requestWithAuth } from '../../services/api';
import { setCookie } from '../../utils/cookies';
import LoaderAnimation from '../AidLoader';
import { AboutText, IntegratedView } from './exportCRMSection';

message.config({
  top: 80,
});

let source;

const SalesforceView = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { CancelToken } = axios;
    source = CancelToken.source();
    return () => {
      message.destroy();
      if (source) source.cancel('Operation canceled by the user.');
    };
  }, []);

  const handleSubmit = (e, isSandBox) => {
    e.preventDefault();

    setIsLoading(true);

    let body = null;
    if (isSandBox) {
      body = { is_sandbox: true };
    }
    requestWithAuth('salesforce-register', 'POST', null, body, source.token)
      .then((response) => {
        localStorage.setItem('crm_source', 'sf');
        if (response) {
          const hostArray = window.location.host.split('.');
          if (hostArray && hostArray.length > 0 && hostArray[0]) {
            setCookie('slug', hostArray[0], 1);
          }
          const myWindow = window.open('myWindow', '_self');
          myWindow.document.write(response);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        message.error('Salesforce integration failed!', 5);
      });
  };

  const { crmValue, removeIntegration } = props;
  // const crmValue = 'sf';

  return (
    <div className="loader-wrapper">
      {isLoading && <LoaderAnimation loaderId="salesforce-loader" />}
      <div className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
        {crmValue !== 'sf' && (
          <>
            <div className="main-section">
              <div className="left-section">
                <div
                  // id="resultView"
                  style={{ display: 'block', marginBottom: 0 }}
                >
                  <div className="export-data-heading">Export</div>
                  <div className="export-icon">
                    <img src={connectIcon} />
                  </div>
                </div>
              </div>
              <div className="right-section">
                <AboutText
                  crm_name="Salesforce"
                  crm_link="http://salesforce.com"
                  crm_url="salesforce.com"
                  crm_icon={iconSalesForce}
                />
              </div>
            </div>
            <div className="sales-list-section">
              <label className="help-label">Please follow these steps:</label>
              <ol className="salesforce-steps">
                <li>
                  First{' '}
                  <span
                    style={{ color: 'var(--color-link)', cursor: 'pointer' }}
                    onClick={() => {
                      window.open(
                        ' https://login.salesforce.com/packaging/installPackage.apexp?p0=04t5Y000001iBeuQAE',
                      );
                    }}
                  >
                    install the app
                  </span>
                </li>
                <hr className="horizontal-line" />
                <li>
                  Setup permission set and add appropriate users:
                  <ol className="salesforce-inner-steps">
                    <li>
                      <div className="inner-item">
                        <img
                          src={image1}
                          style={{ height: '164px' }}
                          className="salesforce-image"
                          alt="image1"
                        />
                        <div className="sentence-style">
                          Under the permission set{' '}
                          <span className="inner-item-bold">
                            &ldquo;create a new permission set&rdquo;
                          </span>{' '}
                          (New Button)
                        </div>
                      </div>
                    </li>
                    <hr className="horizontal-line" />
                    <li>
                      <div className="inner-item">
                        <img
                          src={image2}
                          style={{ height: '176px' }}
                          className="salesforce-image"
                          alt="image2"
                        />
                        <div className="sentence-style">
                          You will be redirected to the below screen. Here{' '}
                          <span className="inner-item-bold">
                            add the name for the permission and the license as
                            &ldquo;Salesforce&rdquo;
                          </span>
                        </div>
                      </div>
                    </li>
                    <hr className="horizontal-line" />
                    <li>
                      <div className="inner-item">
                        <img
                          src={image3}
                          style={{ height: '174px' }}
                          className="salesforce-image"
                          alt="image3"
                        />
                        <div className="sentence-style">
                          On save button click you will be redirected to this
                          below screen. In this page{' '}
                          <span className="inner-item-bold">
                            click on the Object Settings -{'>'} Leads
                          </span>
                        </div>
                      </div>
                      <img
                        src={image4}
                        style={{
                          width: '309px',
                          height: '176px',
                          marginTop: '37px',
                          border: '5px solid var(--color-medium-blue)',
                          borderRadius: '6px',
                        }}
                        alt="image4"
                      />
                    </li>
                    <hr className="horizontal-line" />
                    <li>
                      <div className="inner-item">
                        <img
                          src={image5}
                          style={{
                            width: '312px',
                            height: '174px',
                          }}
                          className="salesforce-image"
                          alt="image5"
                        />
                        <div className="sentence-style">
                          Now add the permissions as in here
                        </div>
                      </div>
                    </li>
                    <hr className="horizontal-line" />
                    <li>
                      <div className="inner-item">
                        <img
                          src={image6}
                          // style={{ width: '294px' }}
                          className="salesforce-image"
                          alt="image6"
                        />
                        <div className="sentence-style">
                          Now{' '}
                          <span className="inner-item-bold">
                            click on the &ldquo;Manage Assignments&rdquo; button
                          </span>{' '}
                          at the top and{' '}
                          <span className="inner-item-bold">
                            assign the user
                          </span>{' '}
                          with the new permission set.
                        </div>
                      </div>
                    </li>
                  </ol>
                </li>
                <hr className="horizontal-line" />
                <li>
                  Now you are ready to connect:
                  <div className="bottom-section">
                    <div
                      style={{
                        display: 'flex',
                        gap: '20px',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        type="primary"
                        className="button-crm-export"
                        onClick={handleSubmit}
                      >
                        Connect to Salesforce
                      </Button>
                      <div>or</div>
                      <Button
                        type="primary"
                        className="button-crm-export"
                        onClick={(e) => handleSubmit(e, true)}
                      >
                        Connect to Sandbox
                      </Button>
                    </div>
                    <div className="bottom-line">You are now all set!</div>
                  </div>
                </li>
              </ol>
            </div>
          </>
        )}
        {crmValue === 'sf' && (
          <div className="main-section">
            <div className="left-section">
              {' '}
              <IntegratedView
                crm_name="Salesforce"
                onRemove={removeIntegration}
              />
            </div>
            <div className="right-section">
              {' '}
              <AboutText
                crm_name="Salesforce"
                crm_link="http://salesforce.com"
                crm_url="salesforce.com"
                crm_icon={iconSalesForce}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesforceView;
