import './index.scss';
import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { CloseCircleFilled } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import LoaderIcon from '../../assets/icons/ai_ic_logo_gold.svg?react';

const SuggestedVideo = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const [videoData, setVideoData] = useState([]);

    useEffect(() => {
      if (props.data && props.data.suggestions) {
        setVideoData(
          props.data.suggestions.filter(
            (s) => s && s.suggestion_type === 'video' && s.url,
          ),
        );
      }
    }, [props.data]);
    return (
      <div className="loader-wrapper">
        {props.data && Object.keys(props.data).length === 0 && (
          <LoaderIcon className="aid-sidecar-ext-logo-spin-icon-only" />
        )}

        <div
          className={
            props.data && Object.keys(props.data).length === 0
              ? 'dashboard-item suggested-video aid-div-disable'
              : 'dashboard-item suggested-video aid-div-enable'
          }
        >
          <div className="suggested-video-header">
            <div className="dashboard-item-title">Suggested Video</div>
            <CloseCircleFilled
              className="close-button"
              onClick={props.onCloseButtonClick}
            />
          </div>
          <div className="video-content">
            {videoData.length > 0 && videoData[0].url ? (
              <ReactPlayer
                url={videoData[0].url}
                controls
                width="400px"
                height="250px"
                style={{ overflow: 'hidden' }}
              />
            ) : (
              props.data &&
              Object.keys(props.data).length > 0 && (
                <div className="dashboard-item-empty">
                  No Suggestions for Now
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  }),
);

export default SuggestedVideo;
