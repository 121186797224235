import { DropTarget } from 'react-drag-drop-container';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  dashboardBoxItems,
  DashboardViewNewDefaultItems,
} from '../../utils/constants';
import DragSortListItem from './DragSortListItem';

const DragSortListBox = (props) => {
  const { itemList, className, swap, onCloseButtonClick } = props;

  const flags = useFlags();
  const newDashboard = flags && flags.dasboard2024;

  return (
    <div>
      <DropTarget targetKey="DragSortListItem">
        <div>
          {itemList.map((item, index) => {
            return (
              <DragSortListItem
                className={className}
                index={index}
                swap={swap}
                id={item}
                label={dashboardBoxItems[item]}
                onCloseButtonClick={onCloseButtonClick}
                closeable={
                  newDashboard && DashboardViewNewDefaultItems.includes(item)
                    ? false
                    : true
                }
              >
                {dashboardBoxItems[item]}
              </DragSortListItem>
            );
          })}
        </div>
      </DropTarget>
    </div>
  );
};

export default DragSortListBox;
