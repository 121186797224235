import './index.scss';

import { ReloadOutlined } from '@ant-design/icons';
import PieChart from '../Chart/PieChart';
import LoaderAnimation from '../AidLoader';

const PieChartSection = (props) => {
  const { data, loaderId } = props;
  return (
    <div style={{ marginTop: 0, position: 'relative' }}>
      {props.isLoading && <LoaderAnimation size="small" loaderId={loaderId} />}
      <div
        className={
          props.isLoading
            ? 'graph-section aid-div-disable'
            : 'graph-section aid-div-enable'
        }
      >
        <div>
          <div className="heading-label">Inferred Salary Ranges</div>
        </div>
        {props.reload ? (
          <div className="reload-icon">
            <ReloadOutlined onClick={() => props.apiClick('inferred_salary')} />
          </div>
        ) : (
          <div>
            {props?.data?.labels.length > 0 ? (
              // <div style={{ width: '373px' }}>
              <div
                style={{
                  display: 'flex',
                  gap: '30px',
                  paddingTop: '31px',
                  height: '300px',
                }}
              >
                <PieChart values={data} {...props} />
              </div>
            ) : (
              // </div>
              <div>
                <div className="no-data-label">No Data</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PieChartSection;
