import { DragDropContainer, DropTarget } from 'react-drag-drop-container';

const DraggableGridItem = (props) => {
  const { swap, index, render, item } = props;

  const handleDrop = (e) => {
    e.stopPropagation();
    swap(e.dragData.index, index, e.dragData);
  };

  return (
    <div>
      <DragDropContainer
        targetKey="DraggableGridItem"
        dragData={{ label: item, index: index }}
        disappearDraggedElement={false}
      >
        <DropTarget
          onHit={handleDrop}
          targetKey="DraggableGridItem"
          render={() => render(item)}
        />
      </DragDropContainer>
    </div>
  );
};

export default DraggableGridItem;
