import './index.scss';
import WealthIcon from '../../assets/icons/ai_ic_wealth.svg?react';

const WealthSegmentDescription = (props) => {
  const leftData = [
    {
      header: 'Age',
      content:
        'Indicates career stage, potential wealth, plus mortgage  assumptions and retirement horizons.',
    },
    {
      header: 'Career Information',
      content:
        ' Title, role, industry, company size, and geography all inform our wealth models.',
    },
    {
      header: 'Inferred Income',
      content:
        'Built around zip code, career information, and real estate holdings. Strong correlation to wealth modeling.',
    },
    {
      header: 'Property Ownership',
      content:
        ' Verified real estate ownership is often indicative of higher wealth segments.',
    },
  ];
  const rightData = [
    {
      header: 'Property Value',
      content:
        'Property values along with mortgage and equity assumptions are  strong indications of wealth segments.',
    },
    {
      header: 'Zip-Based Info',
      content:
        'Neighbourhood info provides insights on average tax profiles and more.',
    },
    {
      header: 'Equity Info',
      content:
        'Executive share balances of public companies, based on SEC filings.',
    },
    {
      header: 'Wealth Triggers',
      content:
        'Events including real estate transactions, stock sales, and  company acquisitions/investments that affect personal wealth.',
    },
  ];
  return (
    <div className="wealth-description-new">
      <div className="wealth-description-header">
        <WealthIcon />
        We use consumer and professional data from hundreds of sources to model
        our wealth insights.
      </div>
      <div className="wealth-description-content">
        <div>
          {leftData.map((item, index) => {
            return (
              <div key={index}>
                <div>{item.header}</div>
                <p>{item.content}</p>
              </div>
            );
          })}
        </div>
        <div className="line"></div>
        <div>
          {rightData.map((item, index) => {
            return (
              <div key={index}>
                <div>{item.header}</div>
                <p>{item.content}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WealthSegmentDescription;
