import { inject, observer } from 'mobx-react';
import { LeftOutlined } from '@ant-design/icons';
import LinkedinIcon from '../../assets/icons/ai_linked_in.svg?react';
import Facebook from '../../assets/icons/ai_facebook.svg?react';
import TwitterIcon from '../../assets/icons/ai_x_logo.svg?react';
import ExportPdfImage from '../../assets/icons/ai_ic_pdf.svg?react';

function formatUrl(url) {
  return url.indexOf('://') === -1 ? `https://${url}` : url;
}

const CompanyProfileHeaderNew = inject(
  'dataStore',
  'modalDataStore',
)(
  observer(({ data, onBackArrowClick, ...props }) => {
    const renderPrevious = () => {
      const { trackModal } = props.modalDataStore;
      if (trackModal.length > 1) {
        const lastItem = trackModal[trackModal.length - 2];
        if (lastItem) {
          if (lastItem.modal === 'profile') return 'previous prospect profile';
          else if (lastItem.modal === 'company')
            return 'previous company profile';
        }
      } else if (window.location && window.location.pathname) {
        const currentlocation = window.location.pathname;
        if (currentlocation.includes('/connect')) return 'Import/Export';
        else {
          const pathArray = currentlocation.split('/');
          const finalPath = currentlocation.includes('/prospect-finder')
            ? pathArray[pathArray.length - 2]
            : pathArray[pathArray.length - 1];
          return (
            <span style={{ textTransform: 'capitalize', display: 'contents' }}>
              {finalPath.replace(/-/g, ' ')}
            </span>
          );
        }
      }
      return 'Prospect Finder';
    };

    return (
      <div className="company-profile-header-blue">
        <div onClick={onBackArrowClick} className="back-profile-icon">
          <LeftOutlined /> Back to {renderPrevious()}
        </div>
        <div className="flex-container">
          {data && data.short_name && (
            <div className="company-name">
              {data.short_name.toString().toTitleCase()}
            </div>
          )}
          {data && data.logo_url ? (
            <div>
              <a
                href={data.url ? formatUrl(data.url) : ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={data.logo_url}
                  alt={data.short_name}
                  style={{ height: '60px' }}
                />
              </a>
            </div>
          ) : (
            <div style={{ height: '60px' }} />
          )}
        </div>
        <div style={{ cursor: 'pointer', display: 'flex', gap: '60px' }}>
          {data &&
            (data.linkedin_url || data.twitter_url || data.facebook_url) && (
              <div className="social-part">
                {data && data.linkedin_url && (
                  <div className="icon-header">
                    <a
                      href={formatUrl(data.linkedin_url)}
                      style={{ color: 'var(--color-link)', height: 20 }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedinIcon />
                    </a>
                  </div>
                )}
                {data && data.twitter_url && (
                  <div className="icon-header">
                    <a
                      href={formatUrl(data.twitter_url)}
                      style={{ color: 'var(--color-link)', height: 20 }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TwitterIcon />
                    </a>
                  </div>
                )}
                {data && data.facebook_url && (
                  <div className="icon-header">
                    <a
                      href={formatUrl(data.facebook_url)}
                      style={{ color: 'var(--color-link)', height: 20 }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Facebook />
                    </a>
                  </div>
                )}
              </div>
            )}
          <div
            className="pdf-export"
            onClick={() => {
              window.print();
            }}
          >
            <ExportPdfImage />
            <span style={{ marginTop: '2px' }}>PDF</span>
          </div>
        </div>
      </div>
    );
  }),
);

export default CompanyProfileHeaderNew;
