import './index.css';

import CompanyLogo from '../../assets/icons/ai_company_logo.svg?react';
import CityLogo from '../../assets/icons/ai_city_logo.svg?react';
import TitleLogo from '../../assets/icons/ai_title.svg?react';
import SalaryLogo from '../../assets/icons/ai_salary.svg?react';
import { commarize } from '../../utils/general';

const ListChart = (props) => {
  const { data, boxName, click, id, showCompanyProfile } = props;
  const getListImage = () => {
    switch (boxName) {
      case 'company':
        return <CompanyLogo />;
      case 'city':
        return <CityLogo />;
      case 'title':
        return <TitleLogo />;
    }
  };

  const getSalaryList = () => {
    return data.map((item) => {
      const { label, count, key } = item;
      return (
        <div
          className="list-element-vertical"
          onClick={() => {
            click(id, key);
          }}
        >
          <SalaryLogo />
          <span style={{ fontSize: '32px' }}> {label}</span>
          <span
            style={{
              color: 'var(--color-dark-shade)',
              fontSize: '14px',
            }}
          >
            {count ? commarize(count) : ''}
          </span>
        </div>
      );
    });
  };

  const getLastHovered = (id, key, label, extraId) => {
    let lastHoveredItemName;
    if (id === 'title_rank') {
      lastHoveredItemName = {};
      lastHoveredItemName.value = extraId;
      lastHoveredItemName.label = label;
    } else if (id === 'team_ids') {
      lastHoveredItemName = [extraId];
    } else if (id === 'org_name') {
      lastHoveredItemName = {
        value: key,
        label: label,
      };
    } else lastHoveredItemName = key;
    return lastHoveredItemName;
  };

  const getList = () => {
    return data.map((item) => {
      const { label, count, key, extraId } = item;
      return (
        <div className="list-element">
          <span
            style={{
              color: 'var(--color-dark-shade)',
              textAlign: 'right',
              fontSize: 12,
              // marginRight: '18px',
            }}
          >
            {count ? (
              <div
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  const lastHoveredItemName = getLastHovered(
                    id,
                    key,
                    label,
                    extraId,
                  );
                  if (
                    id === 'current_experience.ai_org_id' ||
                    id === 'previous_experience.ai_org_id'
                  )
                    click(id, label, key);
                  else click(id, lastHoveredItemName);
                }}
              >
                {commarize(count)}
              </div>
            ) : (
              ''
            )}
          </span>
          <span
            onClick={() => {
              const lastHoveredItemName = getLastHovered(
                id,
                key,
                label,
                extraId,
              );
              if (
                id === 'current_experience.ai_org_id' ||
                id === 'previous_experience.ai_org_id'
              )
                click(id, label, key);
              else if (id === 'org_name') showCompanyProfile(key, label);
              else click(id, lastHoveredItemName);
            }}
          >
            {label}
          </span>
        </div>
      );
    });
  };

  if (data && data.length > 0) {
    if (id === 'inferred_salary_range') {
      return <div style={{ display: 'flex' }}>{getSalaryList()}</div>;
    } else
      return (
        <div className="insights-list-parent">
          {getListImage()}
          <div
            style={{
              textAlign: 'left',
            }}
          >
            {getList()}
          </div>
        </div>
      );
  } else return <div></div>;
};

export default ListChart;
