import './index.css';
import { Component } from 'react';
import { Slider } from 'antd';

class LocationSlider extends Component {
  constructor(props) {
    super(props);
  }

  handleSliderChange = (radius) => {
    const { onChange } = this.props;
    onChange(radius);
  };

  handleSliderAfterChange = () => {
    const { onAfterChange } = this.props;
    if (onAfterChange) onAfterChange();
  };

  render() {
    const { radius = 10 } = this.props;
    return (
      <div>
        <div className="slider">Select Radius :</div>
        <Slider
          min={0}
          max={200}
          defaultValue={10}
          value={radius}
          onChange={this.handleSliderChange}
          onAfterChange={this.handleSliderAfterChange}
          disabled={this.props.isDisabled}
        />
        <div className={this.props.isDisabled ? 'disabled' : 'radius'}>
          {radius} mile
        </div>
      </div>
    );
  }
}

export default LocationSlider;
