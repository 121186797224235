import './index.scss';
import { Table } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';

const EventDescription = (props) => {
  const leftColumn = [
    {
      title: 'News Mention',
      description:
        'Published events and press releases from news outlets about your prospects.',
    },
    {
      title: 'Investment Received',
      description: 'Company received investment funding.',
    },
    {
      title: 'Company Achieves Unicorn Status',
      description: 'Private company reached $1 billion valuation.',
    },
    {
      title: 'Company Acquired',
      description:
        'Company was acquired, exit or change control event occurred.',
    },
    {
      title: 'Professional & Employment Updates',
      listItems: ['Job Title Change', 'Company Change'],
      description: 'Employment updates, job changes.',
    },
  ];

  const rightColumn = [
    {
      title: 'SEC Filings',
      listItems: ['IPO', 'SPAC', 'Insider Stock Sales, Acquisitions, Purchase'],
      description:
        'Initial Public Offering (new stock issuance) filings,Special Purpose Acquisition Companies (SPACs) via acquisitions, and all insider stock transactions.',
    },
    {
      title: 'Property & Listing Updates',
      listItems: [
        'Property Sale',
        'Property Purchase',
        'Property Listing Statuses',
      ],
      description: 'Property listing and status updates.',
    },
    {
      title: 'Potential Wealth Transfer',
      description: 'Death in the household for surviving household members.',
    },
  ];

  return (
    <div className="trigger-help-modal-new">
      <div className="description-title">Wealth Triggers</div>
      <div className="description-container">
        <div className="box">
          {leftColumn.map((event, index) => (
            <div key={index} className="box-contents">
              <div className="headings">{event.title}</div>
              {event.listItems && (
                <ul className="description list-item">
                  {event.listItems.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              )}
              <span className="description">{event.description}</span>
            </div>
          ))}
        </div>

        <div className="separator"></div>

        <div className="box" style={{ marginLeft: '50px' }}>
          {rightColumn.map((event, index) => (
            <div key={index} className="box-contents">
              <div className="headings">{event.title}</div>
              {event.listItems && (
                <ul className="description list-item">
                  {event.listItems.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              )}
              <span className="description">{event.description}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventDescription;
