export const showCompanyProfile = (id, orgName) => {
  if (!id) return;

  props.modalDataStore.resetProfileModal();
  props.modalDataStore.resetCompanyProfileModal();

  const { companyProfileModal } = props.modalDataStore;
  companyProfileModal.id = id;
  companyProfileModal.orgName = orgName;
  companyProfileModal.isVisible = true;
};

export const showProfile = (b2bId, b2cId) => {
  if (!b2bId && !b2cId) return;

  const { profileModal } = props.modalDataStore;
  profileModal.b2bId = b2bId;
  profileModal.b2cId = b2cId;
  profileModal.mimpersonId = '';
  profileModal.eventsId = '';
  profileModal.isVisible = true;
};
