import './index.css';

import { Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import useDeepEffect from '../../hooks/useDeepEffect';
import { splitNumberWithCommas } from '../../utils/general';

const InputGroup = Input.Group;
const { Option } = Select;

const IrsInsightsRangePicker = inject('dataStore')(
  observer((props) => {
    const [selectedOption, setSelectedOption] = useState('between');
    const [valueOne, setValueOne] = useState(null);
    const [valueTwo, setValueTwo] = useState(null);

    /** https://medium.com/better-programming/how-to-use-the-react-hook-usedeepeffect-815818c0ad9d */
    useDeepEffect(() => {
      const { number } = props;
      if (number && number.key && (number.gte || number.lte)) {
        if (number.gte && number.lte) {
          setSelectedOption(number.gte !== number.lte ? 'between' : 'exact');
          setValueOne(number.gte);
          setValueTwo(number.lte);
        } else if (!number.gte) {
          setSelectedOption('lte');
          setValueOne(number.lte);
          setValueTwo(number.lte);
        } else {
          setSelectedOption('gte');
          setValueOne(number.gte);
          setValueTwo(number.gte);
        }
      } else {
        setSelectedOption('between');
        setValueOne(null);
        setValueTwo(null);
      }
    }, [props.number]);

    useEffect(() => {
      const { id } = props;
      if (valueOne && valueTwo) {
        if (valueOne === '0' && valueTwo === '0') {
          props.dataStore.networkFilters[id] = {};
          setValueOne(null);
          setValueTwo(null);
        } else {
          onRangeSelected(valueOne, valueTwo);
        }
      }
    }, [valueOne, valueTwo]);

    useEffect(() => {
      if (valueOne || valueTwo) {
        if (selectedOption !== 'between') {
          onRangeSelected(valueOne, valueOne);
        } else {
          onRangeSelected(valueOne, valueTwo);
        }
      }
    }, [selectedOption]);

    // function to make ui changes based on exact and between dropdown choosing
    const onDropDownSelect = (value) => {
      setSelectedOption(value);

      if (props.onSelect) props.onSelect();
    };

    const onRangeChange = (event) => {
      const { id } = event.target;
      let { value } = event.target;

      value = value.replaceAll(',', '');
      if (!value.match(/^\d*$/)) return;

      const formattedVal = splitNumberWithCommas(value) || '';
      if (id === 'minValue') {
        setValueOne(formattedVal);
      } else if (id === 'maxValue') {
        setValueTwo(formattedVal);
      } else if (id === 'singleValue') {
        setValueOne(formattedVal);
        setValueTwo(formattedVal);
      }
      if (props.onSelect) props.onSelect();
    };

    const onRangeSelected = (value1, value2) => {
      const { onValueSelected } = props;
      if (!value1 && !value2) {
        onValueSelected(props.id, []);
      } else {
        if (selectedOption === 'exact') {
          onValueSelected(props.id, [value1, value1]);
        } else if (selectedOption === 'lte') {
          onValueSelected(props.id, [-1, value1]);
        } else if (selectedOption === 'gte') {
          onValueSelected(props.id, [value1, -1]);
        } else {
          onValueSelected(props.id, [value1, value2]);
        }
      }
    };

    return (
      <InputGroup>
        <div className="range-picker-parent-new">
          <Select
            bordered={false}
            className="range-picker"
            popupClassName="range-picker-dropdown"
            style={{
              minWidth: 110,
              height: 40,
            }}
            value={selectedOption}
            onChange={onDropDownSelect}
          >
            <Option value="between">Between</Option>
            <Option value="exact">Exact</Option>
            <Option value="lte">Less than</Option>
            <Option value="gte">Greater than</Option>
          </Select>
          {selectedOption === 'between' ? (
            <div className="flex large-content">
              <Input
                type="text"
                id="minValue"
                placeholder="##"
                value={valueOne}
                onChange={onRangeChange}
                style={{ width: '42%', textAlign: 'center' }}
              />
              <Input
                style={{
                  pointerEvents: 'none',
                  backgroundColor: '#fff',
                  width: '16%',
                  padding: 0,
                  textAlign: 'center',
                }}
                placeholder="~"
                disabled
              />
              <Input
                type="text"
                id="maxValue"
                placeholder="##"
                value={valueTwo}
                className="last-input"
                onChange={onRangeChange}
                style={{ width: '42%', textAlign: 'center' }}
              />
            </div>
          ) : (
            <Input
              type="text"
              id="singleValue"
              placeholder={props.placeholder}
              value={valueOne}
              className="last-input"
              onChange={onRangeChange}
            />
          )}
        </div>
      </InputGroup>
    );
  }),
);

export default IrsInsightsRangePicker;
