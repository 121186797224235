import './index.scss';
import { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

import Loader from '../../assets/icons/ai_ic_gold_loader.json';
export default function LoaderAnimation(props) {
  const animationRef = useRef(null);
  const { loaderId } = props;

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: document.getElementById(loaderId),
      animationData: Loader,
      loop: true,
      renderer: 'svg',
    });
    animationRef.current = animation;
    // animation.addEventListener('complete', function () {
    //   animation.goToAndStop(30, true);
    // });

    return () => {
      // Clean up: Stop animation and remove event listeners
      animationRef.current.stop();
      animationRef.current.destroy();
    };
  }, []);

  return (
    <div
      id={loaderId}
      aria-label="loading"
      className={
        props.className
          ? `loader-animation ${props.className}`
          : 'loader-animation'
      }
      style={{
        width:
          props.size === 'small'
            ? '25px'
            : props.size === 'medium'
              ? '50px'
              : '75px',
        height:
          props.size === 'small'
            ? '25px'
            : props.size === 'medium'
              ? '50px'
              : '75px',
        ...props.style,
      }}
    />
  );
}
