import { message } from 'antd';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useNavigate } from 'react-router';
import { requestWithAuth } from '../../services/api';
import { getCookie } from '../../utils/cookies';
import Progress from '../../components/Progress';

message.config({
  top: 80,
});

const ZendeskJWTCallBack = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const formRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    requestWithAuth('zendesk-sso-jwt', 'GET', null, null, null).then(
      (response) => {
        if (!response) return;
        if (response && response.token) {
          let zdFormUrl = 'https://aidentified.zendesk.com/access/jwt';
          const urlParams = location.search;
          const query = qs.parse(urlParams, {
            ignoreQueryPrefix: true,
          });
          if (
            query &&
            !(
              Object.keys(query).length === 0 && query.constructor === Object
            ) &&
            query.return_to
          )
            zdFormUrl += `?${qs.stringify({ return_to: query.return_to })}`;

          formRef.current.action = zdFormUrl;
          inputRef.current.value = response.token;
          formRef.current.submit();
        } else {
          navigate(`/exception/${response?.statusCode}`, {
            state: {
              errorMessage: response?.detail,
              from: 'zd-jwt',
            },
          });
        }
      },
    );
  }, [location]);

  return (
    <form
      ref={formRef}
      method="POST"
      action="https://aidentified.zendesk.com/access/jwt"
    >
      <input ref={inputRef} type="hidden" name="jwt"></input>
    </form>
  );
};
export default ZendeskJWTCallBack;
