import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { commarize } from '../../utils/general';

ChartJS.register(ArcElement);
const PieChart = (props) => {
  const { values, id, click } = props;

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        backgroundColor: getComputedStyle(document.body).getPropertyValue(
          '--color-white',
        ),
        titleColor: getComputedStyle(document.body).getPropertyValue(
          '--color-black',
        ),
        bodyColor: getComputedStyle(document.body).getPropertyValue(
          '--color-black',
        ),
        borderColor: getComputedStyle(document.body).getPropertyValue(
          '--color-light',
        ),
        borderWidth: 1,
        caretPadding: 10,
        padding: 10,
        callbacks: {
          label: function (context) {
            const label = context.label;
            const count = commarize(context.parsed || 0);
            return `${label}: ${count}`;
          },
        },
      },
    },
    // responsive: true,
    // maintainAspectRatio: false,
    onHover: function (event, legendItem, legend) {
      event.chart.canvas.parentNode.style.cursor = legendItem[0]
        ? 'pointer'
        : 'default';
    },
    onClick: function (e, chartElement) {
      if (chartElement.length <= 0) return; // Clicked outside any bar.
      click(id, values.labels[chartElement[0].index]);
    },
  };

  const generateLegend = () => {
    const dataList = values.data;
    const backgroundColors = [
      getComputedStyle(document.body).getPropertyValue('--color-golden-start'),
      getComputedStyle(document.body).getPropertyValue('--color-primary'),
      getComputedStyle(document.body).getPropertyValue('--color-link'),
      getComputedStyle(document.body).getPropertyValue('--color-column'),
      getComputedStyle(document.body).getPropertyValue('--color-medium'),
    ];
    const list = [];
    dataList.map((item, index) => {
      list.push(
        <div style={{ display: 'flex', color: 'var(--color-primary)' }}>
          <div>
            <span
              style={{
                height: '10px',
                width: '10px',
                backgroundColor: backgroundColors[index],
                borderRadius: '50%',
                display: 'inline-block',
              }}
            />
          </div>
          <div style={{ paddingLeft: '8px' }}>
            {values.labels[index]}
            <p style={{ color: 'var(--color-dark-shade)', fontSize: '12px' }}>
              {commarize(item)}
            </p>
          </div>
        </div>,
      );
    });

    return <div>{list}</div>;
  };

  const getFormattedData = () => {
    if (!document.getElementById('company-pie-chart') || !values.data) {
      return {
        labels: [],
        datasets: [],
      };
    }
    const ctx = document.getElementById('company-pie-chart').getContext('2d');
    const gradient1 = ctx.createLinearGradient(0, 0, 0, 175);
    gradient1.addColorStop(
      0.0,
      getComputedStyle(document.body).getPropertyValue('--color-golden-end'),
    );
    gradient1.addColorStop(
      1.0,
      getComputedStyle(document.body).getPropertyValue('--color-golden-start'),
    );

    const gradient2 = ctx.createLinearGradient(0, 0, 400, 400);
    gradient2.addColorStop(
      0,
      getComputedStyle(document.body).getPropertyValue('--color-primary'),
    );
    gradient2.addColorStop(
      1,
      getComputedStyle(document.body).getPropertyValue('--color-dark'),
    );

    const gradient3 = ctx.createLinearGradient(0, 0, 0, 175);
    gradient3.addColorStop(
      0,
      getComputedStyle(document.body).getPropertyValue('--color-link'),
    );
    gradient3.addColorStop(
      1,
      getComputedStyle(document.body).getPropertyValue('--color-link'),
    );

    const gradient4 = ctx.createLinearGradient(0, 0, 0, 175);
    gradient4.addColorStop(
      0,
      getComputedStyle(document.body).getPropertyValue('--color-column'),
    );
    gradient4.addColorStop(
      1,
      getComputedStyle(document.body).getPropertyValue('--color-column'),
    );

    const gradient5 = ctx.createLinearGradient(0, 0, 0, 175);
    gradient5.addColorStop(
      0,
      getComputedStyle(document.body).getPropertyValue('--color-medium'),
    );
    gradient5.addColorStop(
      1,
      getComputedStyle(document.body).getPropertyValue('--color-medium'),
    );
    const backgroundColorArray = [
      gradient1,
      gradient2,
      gradient3,
      gradient4,
      gradient5,
    ];

    return {
      datasets: [
        {
          data: values.data,
          borderWidth: 0,
          backgroundColor: backgroundColorArray,
        },
      ],
      labels: values.labels,
    };
  };

  return (
    <>
      <div>{generateLegend()}</div>
      <div style={{ width: '250px' }}>
        <Pie
          id="company-pie-chart"
          data={getFormattedData()}
          options={options}
        />
      </div>
    </>
  );
};

export default PieChart;
