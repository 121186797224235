import './index.scss';

import { ReloadOutlined } from '@ant-design/icons';
import HorizontalLineChart from '../Chart/HorizontalLineChart';
import ListChart from '../Chart/ListChart';
import { splitNumberWithCommas } from '../../utils/general';
import LoaderAnimation from '../AidLoader';

const listChartArray = [
  'location_city',
  'previous_experience.ai_org_id',
  'current_experience.ai_org_id',
  'industry',
];

const colorChangeKeys = ['title_rank', 'title'];

const ChartSection = ({ titleLabel, ...props }) => {
  // const { titleLabel, ...props } = props;

  const renderChart = (id) => {
    return (
      <div className="graph-scroll-section">
        {listChartArray.includes(id) && props.data && props.data.length > 0 ? (
          <ListChart {...props} />
        ) : props.data && props.data.labels && props.data.labels.length > 0 ? (
          <HorizontalLineChart {...props} />
        ) : (
          <div>
            <div className="no-data-label">No Data</div>
          </div>
        )}
      </div>
    );
  };

  let graphCount = 0;
  if (listChartArray.includes(props.id) && props.data && props.data.length > 0)
    graphCount = props.data.length;
  else if (props.data && props.data.labels && props.data.labels.length > 0)
    graphCount = props.data.labels.length;

  return (
    <>
      <div
        className="graph-section"
        style={{
          position: 'relative',
          backgroundColor: `${
            colorChangeKeys.includes(props.id)
              ? 'var(--color-light)'
              : 'var(--color-white)'
          }`,
          minHeight: `${props.id !== 'wealth_estimate' ? '300px' : '362px'}`,
        }}
      >
        {props.isLoading && (
          <LoaderAnimation size="small" loaderId={props.loaderId} />
        )}
        <div className={props.isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
          <div>
            <label className="heading-label">
              {titleLabel}{' '}
              {props.id !== 'wealth_estimate' && (
                <span style={{ color: 'var(--color-column)' }}>
                  {graphCount > 0 && splitNumberWithCommas(graphCount)}
                </span>
              )}
            </label>
          </div>
          {props.reload ? (
            <div className="reload-icon">
              <ReloadOutlined onClick={() => props.apiClick(props.id)} />
            </div>
          ) : (
            renderChart(props.id)
          )}
        </div>
      </div>
    </>
  );
};

export default ChartSection;
