import './index.css';

import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'antd';

import FlagIcon from '../../assets/icons/ai_flag.svg?react';
import Professional from '../../assets/icons/ai_professional.svg?react';
import CompanyIcon from '../../assets/icons/ai_company.svg?react';
import EducationIcon from '../../assets/icons/ai_education.svg?react';
import HelpIcon from '../../assets/icons/help-icon.svg?react';

import LocationSearch from '../../components/LocationSearch';
import LocationSlider from '../../components/LocationSlider';
import CreatableSelect from '../../components/CreatableSelect';
import DataSelect from '../../components/DropdownMenu';

import {
  companySizeFilter,
  industryList,
  wealthScoreRanges,
  titleRankList,
} from '../../utils/constants';
import { getDateRangeFromPeriod } from '../../utils/general';
import { requestWithAuth } from '../../services/api';
import { showWarning } from '../../utils/popup';
import Progress from '../../components/Progress';
import LoaderAnimation from '../../components/AidLoader';

const AutoFilters = inject('dataStore')(
  observer((props) => {
    const [industryType, setIndustryType] = useState(null);
    const [homeSearch, setHomeSearch] = useState({ geo: null, address: '' });
    const [radius, setRadius] = useState(10);
    const [employeeCount, setEmployeeCount] = useState([]);
    const [titleRank, setTitleRank] = useState([]);
    const [wealthEstimate, setWealthEstimate] = useState([]);
    const [eventList, setEventList] = useState([]);
    const [schools, setSchools] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      fetchEventsAggs();
    }, []);

    useEffect(() => {
      if (props.dataStore.currentUser.industry) {
        const currentUserIndustry = industryList.find(
          (obj) => obj.value === props.dataStore.currentUser.industry,
        );
        if (currentUserIndustry && currentUserIndustry.type)
          setIndustryType(currentUserIndustry.type);
      } else {
        props.onFilterCompletion();
      }
    }, [props.dataStore.currentUser.industry]);

    const fetchEventsAggs = () => {
      requestWithAuth('field-lookup', 'GET', { key: 'event_types' }, null, null)
        .then((response) => {
          const eventTypes = [];
          if (response && response.result && response.result.length) {
            response.result.map((event) => {
              if (event) {
                eventTypes.push(event);
              }
              return '';
            });
          }
          setEventList([...eventTypes]);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const onLocationSelected = (id, latLng, address) => {
      setHomeSearch({
        geo: latLng,
        address: address,
      });
    };

    const onLocationSliderChanged = (radius) => {
      setRadius(!radius || radius === 0 ? 0.1 : radius);
    };

    const onWealthEstimateSelected = (selectedItems) => {
      setWealthEstimate([...selectedItems]);
    };

    const onEmployeeCountSelected = (selectedItems) => {
      setEmployeeCount([...selectedItems]);
    };

    const onTitleRankSelected = (selectedItems) => {
      setTitleRank([...selectedItems]);
    };

    const onSchoolSelected = (selectedItems) => {
      setSchools([...selectedItems]);
    };

    const AddMainFilter = () => {
      const body = {};
      const params = {};

      params.name =
        industryType === 'education'
          ? 'Alumni Monitoring'
          : 'Prospects In My Area';

      params.email_alert = 0;

      params.filter_type = 'all';

      const search = {
        period: 'year',
        m2_date: getDateRangeFromPeriod('year'),
      };

      switch (industryType) {
        case 'b2c': {
          if (homeSearch && homeSearch.geo) {
            search.home_search = {
              geo_distance: {
                distance: !radius ? '16km' : radius * 1.6 + 'km',
                address: homeSearch.address,
                b2c_geo: `${homeSearch.geo.lat}, ${homeSearch.geo.lng}`,
                distance_type: 'plane',
              },
            };
          }

          if (wealthEstimate && wealthEstimate.length) {
            const wealthArray = [];
            wealthEstimate.map((wealthScore) => {
              const wealthEstimate = {};
              if (wealthScore) {
                if (wealthScore.min !== null)
                  wealthEstimate.gte = wealthScore.min;
                if (wealthScore.max !== null)
                  wealthEstimate.lte = wealthScore.max;
                wealthArray.push({
                  range: {
                    wealth_estimate: wealthEstimate,
                  },
                });
              }
            });
            search.wealth_estimate = wealthArray;
          }

          search.latest_mim_event_type = eventList;
          break;
        }
        case 'b2b': {
          if (employeeCount && employeeCount.length) {
            search.employee_count = employeeCount.map((count) => {
              return count.value;
            });
          }
          if (titleRank && titleRank.length) {
            search.title_rank = titleRank.map((title) => {
              return title.value;
            });
          }
          search.latest_mim_event_type = [
            'news',
            'investment received',
            'ipo',
            'company change',
            'job title change',
          ];
          break;
        }
        case 'education': {
          params.email_alert = 1;
          if (schools && schools.length) {
            const schoolFilter = [];
            const schoolFilterAdded = [];
            schools.map((school) => {
              if (school && school.__isNew__) {
                schoolFilterAdded.push(school.value);
              } else {
                schoolFilter.push(school.value);
              }
            });
            search.schools = {
              exact: schoolFilter,
              prefix: schoolFilterAdded,
            };
          }
          // if !schools, this is just a filter on eventList, seems unhelpful?
          search.latest_mim_event_type = eventList;
          break;
        }
        default:
          break;
      }

      body.search = search;
      body.isSignUp = true;
      //   body.team_members = props.dataStore.lookUpLists.teamMemberIds;

      requestWithAuth('multiple-filters', 'POST', params, body, null).then(
        (response) => {
          if (
            response &&
            (response.code === 200 ||
              response.detail == 'Filter with given name already exists')
          )
            props.onFilterCompletion();
        },
      );
    };

    const AddSubFilter = () => {
      const body = {};
      const params = {};
      const search = {
        period: 'year',
        m2_date: getDateRangeFromPeriod('year'),
      };

      params.name = '1st Degree Network Monitoring';
      params.filter_type = 'first_degree';
      params.email_alert = 1;

      switch (industryType) {
        case 'b2c': {
          search.latest_mim_event_type = eventList;
          break;
        }
        case 'b2b': {
          search.latest_mim_event_type = [
            'news',
            'investment received',
            'ipo',
            'company change',
            'job title change',
          ];
          break;
        }
        default:
          break;
      }
      body.search = search;
      body.isSignUp = true;

      requestWithAuth('multiple-filters', 'POST', params, body, null);
    };

    const validateFields = () => {
      switch (industryType) {
        case 'b2c': {
          return (
            homeSearch &&
            homeSearch.geo &&
            wealthEstimate &&
            wealthEstimate.length
          );
        }

        case 'b2b': {
          return (
            employeeCount &&
            employeeCount.length &&
            titleRank &&
            titleRank.length
          );
        }
        case 'education': {
          return schools && schools.length;
        }
        default:
          break;
      }
    };

    const handleConfirm = () => {
      if (validateFields()) {
        if (industryType !== 'education') AddSubFilter();
        AddMainFilter();
      } else {
        showWarning('Please fill all the fields to set-up your first filter');
      }
    };

    const renderContent = () => {
      switch (industryType) {
        case 'b2c':
          return (
            <>
              <div className="filter-item">
                <FlagIcon className="filter-icons" />
                <div>Enter your city:</div>
                <LocationSearch
                  id="home_search"
                  value={homeSearch}
                  placeholder="Geo-City"
                  onLocationSelected={onLocationSelected}
                  from="network"
                />
                <div></div>
                <div></div>
                <div style={{ maxWidth: '200px' }}>
                  <LocationSlider
                    radius={radius}
                    onChange={onLocationSliderChanged}
                    isDisabled={homeSearch.address === ''}
                  />
                </div>
              </div>

              <div className="filter-item">
                <Professional className="filter-icons" />
                <div>Targeted Wealth Segment(s):</div>
                <DataSelect
                  id="wealth_estimate"
                  value={wealthEstimate}
                  isMulti
                  isBeta={false}
                  dropDownList={wealthScoreRanges}
                  placeholder="Wealth Segment"
                  from="auto-filter"
                  onItemSelected={onWealthEstimateSelected}
                />
              </div>
            </>
          );

        case 'b2b':
          return (
            <>
              <div className="filter-item">
                <CompanyIcon className="filter-icons" />
                <div>Company Headcount</div>
                <div>
                  <DataSelect
                    id="employee_count"
                    value={employeeCount}
                    isMulti
                    dropDownList={companySizeFilter}
                    placeholder="Company Headcount"
                    from="auto-filter"
                    onItemSelected={onEmployeeCountSelected}
                  />
                </div>
              </div>
              <div className="filter-item">
                <Professional className="filter-icons" />
                <div>Title Group</div>
                <DataSelect
                  id="title_rank"
                  value={titleRank}
                  dropDownList={titleRankList}
                  placeholder="Title Group"
                  from="auto-filter"
                  isMulti
                  onItemSelected={onTitleRankSelected}
                />
              </div>
            </>
          );

        case 'education':
          return (
            <div className="filter-item" style={{ paddingTop: '20px' }}>
              <EducationIcon className="filter-icons" />
              <div>School(s)</div>
              <CreatableSelect
                id="schools"
                value={schools}
                placeholder="Schools"
                isMulti
                onSelect={onSchoolSelected}
              />
            </div>
          );

        default:
          return <></>;
      }
    };

    if (!industryType)
      return (
        <div className="loader-wrapper">
          <LoaderAnimation loaderId="auto-filters-loader" />;
        </div>
      );

    return (
      <div>
        <div className="auto-filter-container1"></div>
        <div className="auto-filter-container2"></div>
        <div className="auto-filter-container3"></div>
        <div className="loader-wrapper">
          {isLoading && <LoaderAnimation loaderId="auto-filters-loader" />}
          <div
            className={
              isLoading
                ? 'auto-filters aid-div-disable'
                : 'auto-filters aid-div-enable'
            }
          >
            <div className="filter-header">
              {/* <a> */}
              <img
                className="logo"
                src={getComputedStyle(document.body).getPropertyValue(
                  '--url-logo',
                )}
                alt="logo"
              />
              {/* </a> */}
            </div>
            <div className="auto-filters-card">
              <div className="auto-filters-title">
                Let’s get your first Search set-up to monitor Prospects:
              </div>
              <div className="filter-name-placeholder">Search name</div>
              <div className="filter-name">
                {industryType === 'education'
                  ? 'Alumni Monitoring'
                  : 'Prospects In My Area'}
              </div>
              <div className="card-content">{renderContent()}</div>

              <Button
                className="confirm-btn"
                disabled={!industryType}
                onClick={handleConfirm}
              >
                Save & Continue
              </Button>
            </div>
            <div className="info">
              <HelpIcon height="16px" width="16px" className="help-icon" />
              You can update and expand criteria in the Saved Searches section.
            </div>
          </div>
        </div>
      </div>
    );
  }),
);

export default AutoFilters;
