import './index.scss';
import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button } from 'antd';

import LeftArrow from '../../assets/icons/ai_ic_down_arrow.svg?react';

import AiModal from '../AiModal';
import { requestWithAuth } from '../../services/api';
import TagsSearch from './TagsSearch';
import TagManagement from './TagManagement';
import Suppression from './Suppression';

const TagsModal = inject('dataStore')(
  observer((props) => {
    const [tagData, setTagData] = useState(
      props.dataStore.lookUpLists.userTagFolders,
    );
    const [checkedTags, setCheckedTags] = useState(
      props.dataStore.networkFilters.tags_checked,
    );
    const [showTagsEdit, setShowTagsEdit] = useState(false);
    const [showFolderEdit, setShowFolderEdit] = useState(false);

    useEffect(() => {
      setTagData({ ...props.dataStore.lookUpLists.userTagFolders });
    }, [props.dataStore.lookUpLists.userTagFolders]);

    const fetchUserTags = () => {
      requestWithAuth('tags', 'GET', null, null, null).then((response) => {
        if (response && response.length) {
          props.dataStore.userTags = response;
        }
      });
    };

    const fetchTagFolders = () => {
      requestWithAuth('tag-folder', 'GET', null, null, null).then(
        (response) => {
          if (response) {
            props.dataStore.lookUpLists.userTagFolders = response;
          }
        },
      );
    };

    const handleCancel = () => {
      if (showTagsEdit) {
        setShowTagsEdit(false);
      } else if (showFolderEdit) {
        setShowFolderEdit(false);
      } else {
        props.onClose();
      }
    };

    const onTagSearch = (tags) => {
      if (showTagsEdit) {
        setShowTagsEdit(false);
      }
      if (showFolderEdit) {
        setShowFolderEdit(false);
      }
      setTagData(tags);
    };

    const handleEditTags = (value) => {
      setShowTagsEdit(value);
    };

    const handleEditFolder = (value) => {
      setShowFolderEdit(value);
    };

    return (
      <AiModal
        onCancel={props.onClose}
        wrapClassName="tags-modal"
        footer={[
          <Button key="cancel" className="btn-cancel" onClick={handleCancel}>
            <>
              {(showTagsEdit || showFolderEdit) && (
                <LeftArrow
                  style={{
                    transform: 'rotate(90deg)',
                    height: '13px',
                    marginRight: '10px',
                  }}
                />
              )}
              {/* {showTagsEdit
                ? 'Back to all folders & tags'
                : showFolderEdit
                  ? 'Back to all folders'
                  : 'Cancel'} */}
              {showTagsEdit || showFolderEdit ? 'Back' : 'Cancel'}
            </>
          </Button>,
          <Button
            key="ok"
            type="primary"
            className="btn-ok"
            onClick={() => {
              props.dataStore.networkFilters.tags_checked = checkedTags;
              props.dataStore.networkFilters.fetchData = true;
              props.onClose();
            }}
          >
            Done
          </Button>,
        ]}
      >
        <div className="tags-modal-content">
          <TagsSearch
            onSearch={onTagSearch}
            checkedTags={props.dataStore.networkFilters.tags_checked}
            handleTagsCheck={(checked) => {
              props.dataStore.networkFilters.tags_checked = [...checked];
            }}
          />
          <div className="tags-modal-container">
            <div className="tag-management">
              <TagManagement
                userTagFolders={tagData}
                fetchUserTags={fetchUserTags}
                fetchTagFolders={fetchTagFolders}
                checkedTags={checkedTags}
                handleTagsCheck={(checked) => setCheckedTags(checked)}
                handleEditTags={handleEditTags}
                handleEditFolder={handleEditFolder}
                showTagsEdit={showTagsEdit}
                showFolderEdit={showFolderEdit}
              />
            </div>
            <div className="separator" />
            <Suppression />
          </div>
        </div>
      </AiModal>
    );
  }),
);

export default TagsModal;
