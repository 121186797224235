import '@gouch/to-title-case';
import './index.scss';

import { useEffect, useState } from 'react';
import { Collapse, Popover } from 'antd';

import DropdownIcon from '../../assets/icons/ai_dropdown-savedsearches.svg?react';
import Ai_Blue_Logo from '../../assets/icons/ai_logomark_relationships_blue.svg?react';
import WorkSvg from './WorkSvg';

const statusColor = {
  high: '#003153',
  medium: '#E8C47C',
  low: '#E8C47C',
};

const { Panel } = Collapse;

const PotentialpathsNew = ({
  potentialPathList,
  openProfileTab,
  isLoading,
  showCompanyProfile,
  isRelmapOrg,
}) => {
  const [activeKeys, setActiveKeys] = useState([]);

  useEffect(() => {
    /** if Company Relationship Mapping is applied,
     * automatically expand the Potential Paths
     * when opening the profile.
     **/
    setActiveKeys(isRelmapOrg ? ['1'] : []);
  }, [isRelmapOrg]);

  const getFirstPerson = (path) => {
    if (path.source_type === 'user')
      return <div className={'first-text-div'}>You</div>;
    else
      return (
        <NameAndOrg
          b2bId={path.source_b2b_id}
          b2cId={path.source_b2c_id}
          name={path.source_name}
          title={path.source_title}
          source={path.source_type}
          // orgLogo={path.first_degree_org_logo_url}
          orgName={path.source_org_name}
          // homeCity={path.first_degree_home_city}
          // homeState={path.first_degree_home_state}
        />
      );
  };

  const formatDegreeLink = (link) => {
    return link.replace('on same', 'in same').replace('same', 'the same');
  };

  const getDegreeLink = (degreeLink) => {
    if (!degreeLink || degreeLink.length <= 0 || !Array.isArray(degreeLink))
      return '';
    let degreeLinkString = [];

    degreeLinkString = degreeLink.map((linkData, index) => {
      if (linkData.link) {
        if (linkData.link_detail && linkData.link_detail.length > 0) {
          if (linkData.link === 'user imports') {
            return (
              <span key={index}>
                {index !== 0 && (
                  <>
                    ,<br />
                  </>
                )}
                {linkData.link_detail.join(', ')}
              </span>
            );
          }

          if (
            [
              'work in same organization - ',
              'household member is in same organization - ',
              'previously worked in same organization - ',
              'household member worked in same organization - ',
            ].includes(linkData.link)
          ) {
            return (
              <span key={index}>
                {index !== 0 && (
                  <>
                    ,<br />
                  </>
                )}
                {formatDegreeLink(linkData.link)}{' '}
                {linkData.link_detail.map((detail, indx) => {
                  return (
                    <>
                      {indx !== 0 && <>, </>}
                      <span
                        key={detail}
                        onClick={() => showCompanyProfile(null, null)}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {detail}
                      </span>
                    </>
                  );
                })}
              </span>
            );
          }
          return (
            <span key={index}>
              {index !== 0 && (
                <>
                  ,<br />
                </>
              )}
              {formatDegreeLink(linkData.link)}
              <span style={{ textTransform: 'capitalize' }}>
                {' '}
                {linkData.link_detail.join(', ')}
              </span>
            </span>
          );
        }
        return <span key={index}>{formatDegreeLink(linkData.link)}</span>;
      }
    });
    return degreeLinkString;
  };

  const Arrow = (props) => {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          // width: `${props.className === 'potential-single' ? '100%' : '100%'}`,

          // flexGrow: props.className === 'potential-arrow-first' ? 0 : 1,
          // flexShrink: props.className === 'potential-arrow-first' ? 0 : 1,
        }}
      >
        <div className="arrow-line">
          <div className="arrow-container">
            <div className={`potential-arrow-${props.status}`} />
            <div
              style={{
                color: statusColor[props.status],
                minWidth: 10,
                minHeight: 10,
                borderRadius: '50%',
                backgroundColor: statusColor[props.status],
              }}
            ></div>
          </div>
        </div>
        <div
          className="popover-division"
          style={{
            textAlign:
              props.className === 'potential-arrow-second' ? 'right' : 'left',
          }}
        >
          <Popover
            style={{ padding: '5px' }}
            rootClassName="potential-arrow-popup"
            content={
              <div className="potential-arrow-popup-content">
                {props.degreeLink}
              </div>
            }
            trigger="hover"
            // placement={
            //   props.className === 'potential-arrow-second'
            //     ? 'topRight'
            //     : 'topLeft'
            // }
          >
            <div
              className={
                props.className === 'potential-arrow-second'
                  ? 'span-style style-new'
                  : 'span-style'
              }
            >
              {props.degreeLink[0]}
              {/* {props.degreeLink.length > 1 && '...'} */}
            </div>
          </Popover>
        </div>
      </div>
    );
  };

  const NameAndOrg = ({
    b2bId,
    b2cId,
    name,
    title,
    orgLogo,
    orgName,
    homeCity,
    homeState,
    source = null,
  }) => {
    return (
      <div className="collapsible" onClick={() => openProfileTab(b2bId, b2cId)}>
        <div
          className={
            title || homeCity || homeState
              ? 'first-degree-tab cursor-style'
              : 'first-degree-tab'
          }
        >
          <div className="first-degree-name">
            {name && name !== '' ? name : ''}
          </div>
          <div className="first-degree-company">
            {orgLogo ? (
              <div className="org-logo">
                <img
                  src={orgLogo}
                  alt={orgName.substring(0, 2)}
                  className="company-logo"
                />
              </div>
            ) : (
              <div className="company-icon">
                <WorkSvg svgId="potentialWorkSvg" />
              </div>
            )}
          </div>
        </div>
        {(title || homeCity || homeState) && (
          <div className="expanded-content">
            {title && (
              <div
                className="expanded-item-flex"
                style={{ alignItems: 'baseline' }}
              >
                <div className="expanded-item-label">Title</div>{' '}
                <div className="expanded-item-value">{title}</div>
              </div>
            )}
            {/* In case of source type = org, there won't be any org url to show company logo, also no city and state,
              so company name is shown in place of city and state */}
            {source && orgName && (
              <div
                className="expanded-item-flex"
                style={{ alignItems: 'baseline' }}
              >
                <div className="expanded-item-label">Company</div>{' '}
                <div className="expanded-item-value">{orgName}</div>
              </div>
            )}
            <div
              className="expanded-item-flex"
              // style={{ flexWrap: 'wrap' }}
            >
              {homeCity && (
                <div className="expanded-item-flex">
                  <div className="expanded-item-label">City</div>
                  <div className="expanded-item-value">{homeCity}</div>
                </div>
              )}
              {homeState && (
                <div className="expanded-item-flex">
                  <div className="expanded-item-label">State</div>
                  <div className="expanded-item-value">{homeState}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  function renderTitle() {
    let title = isRelmapOrg
      ? 'Employee Relationship Paths'
      : 'Relationship Paths';

    if (potentialPathList.length === 1)
      title = title.replace('have ', 'have a ').replace('Paths', 'Path');

    return title;
  }

  function renderDirectPath(path, i) {
    return (
      <div className="potential-path-outer-div direct-path">
        {getFirstPerson(path, true)}
        {i === 0 && path.source_type === 'user' ? (
          <Ai_Blue_Logo
            width={37}
            height={37}
            style={{ marginLeft: 10, marginRight: '-1px' }}
          />
        ) : (
          <div></div>
        )}
        <Arrow
          degreeLink={getDegreeLink(path.first_degree_link)}
          className="potential-single"
          status={path.first_degree_relationship_status}
        />
        <div className="last-text-div">
          <div>This prospect</div>
        </div>
      </div>
    );
  }

  function renderConnectivePath(path, i) {
    return (
      <div className="potential-path-outer-div">
        {getFirstPerson(path)}
        <div style={{ minWidth: 25 }}></div>
        <Arrow
          degreeLink={getDegreeLink(path.first_degree_link)}
          className="potential-arrow-first"
          status={path.first_degree_relationship_status}
        />

        <NameAndOrg
          b2bId={path.first_degree_b2b_id}
          b2cId={path.first_degree_b2c_id}
          name={path.first_degree_name}
          title={path.first_degree_title}
          orgLogo={path.first_degree_org_logo_url}
          orgName={path.first_degree_org_name}
          homeCity={path.first_degree_home_city}
          homeState={path.first_degree_home_state}
        />
        <Arrow
          degreeLink={getDegreeLink(path.second_degree_link)}
          className="potential-arrow-second"
          status={path.second_degree_relationship_status}
        />

        <div className="last-text-div">
          <div>
            {path.second_degree_name && path.second_degree_name !== ''
              ? 'This prospect'
              : ''}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="potential-paths-new">
      <Collapse
        className="potential-path-collapse"
        expandIconPosition="end"
        activeKey={activeKeys}
        onChange={(keys) => {
          setActiveKeys(keys);
        }}
        expandIcon={({ isActive }) => ''}
      >
        <Panel
          key="1"
          header={
            <div className="collapse-header profile-box-header">
              <div className="profile-titles">
                {renderTitle()}
                <div className="title-triangle-left"></div>

                {potentialPathList.length > 0 && (
                  <>
                    &nbsp;
                    <span style={{ fontSize: '18px' }}>
                      {'(' + potentialPathList.length + ')'}
                    </span>
                  </>
                )}
                <DropdownIcon
                  onClick={(keys) => {
                    setActiveKeys(keys);
                  }}
                  style={{
                    color: 'var(--color-dark-shade)',
                    transform:
                      Array.isArray(activeKeys) && activeKeys.includes('1')
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',

                    width: '30px',
                    marginLeft: '15px',
                  }}
                />
              </div>
              <div className="potential-indicator">
                <div style={{ fontWeight: 600, fontFamily: 'Poppins-Medium' }}>
                  Relationship Strength
                </div>
                <div className="indicator-value">
                  <div
                    className="potential-arrow-high"
                    style={{ width: '42px' }}
                  />
                  High
                </div>
                <div className="indicator-value">
                  <div
                    className="potential-arrow-medium"
                    style={{ width: '42px' }}
                  />
                  Med
                </div>
                <div className="indicator-value">
                  <div
                    className="potential-arrow-low"
                    style={{ width: '42px' }}
                  />
                  Low
                </div>
              </div>
            </div>
          }
        >
          {isLoading ? (
            <div className="potential-path-placeholder">
              We are scanning billions of relationship paths for you -
              we&apos;ll have results in a couple of seconds!
            </div>
          ) : (
            <div className="potential-path-outer">
              <div className="potential-path-first">
                {potentialPathList &&
                  potentialPathList.length > 0 &&
                  potentialPathList[0] &&
                  potentialPathList[0].source_type === 'user' &&
                  potentialPathList[0].first_degree_id &&
                  potentialPathList[0].first_degree_link &&
                  potentialPathList[0].first_degree_link.length > 0 &&
                  (!potentialPathList[0].second_degree_id ||
                    potentialPathList[0].second_degree_id === '') &&
                  (!potentialPathList[0].second_degree_link ||
                    potentialPathList[0].second_degree_link.length === 0) &&
                  renderDirectPath(potentialPathList[0], 0)}
              </div>
              <div className="potential-path-outer-div-parent">
                <div
                  className="potential-path-parent"
                  style={{
                    overflow: 'auto',
                    maxHeight: '30vh',
                    paddingTop: '10px',
                    paddingBottom:
                      potentialPathList && potentialPathList.length <= 2
                        ? '10px'
                        : 'unset',
                    minHeight:
                      potentialPathList && potentialPathList.length > 2
                        ? '150px'
                        : 'unset',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    // marginBottom: '50px',
                  }}
                >
                  {potentialPathList.map((path, i) => {
                    if (path && path.source_type === 'org')
                      return renderDirectPath(path, i);
                    if (
                      path &&
                      path.first_degree_id &&
                      path.second_degree_id &&
                      path.first_degree_id !== '' &&
                      path.second_degree_id !== ''
                    )
                      return renderConnectivePath(path, i);
                  })}
                </div>
              </div>
            </div>
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

export default PotentialpathsNew;
