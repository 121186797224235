const getRandomIntegerInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// NOTE: the way this is setup, it's somewhat not decodeable by design.
export function caesarCipher(text, shift) {
  if (!text) return '';
  if (!shift) shift = new Date().getMilliseconds();
  if (shift < 1) shift = 2;

  let result = '';

  // pseudo randomize the shift
  shift = getRandomIntegerInclusive(1, shift);

  // if shift has remainder of 0 with module 26 (or 10), we get the base text returned.
  // in this event, we need to ensure we up the shift a bit
  if (shift % 26 == 0) shift = shift + new Date().getDate();
  if (shift % 10 == 0) shift = shift + new Date().getDate();

  for (let i = 0; i < text.length; i++) {
    let char = text[i];
    const code = text.charCodeAt(i);
    let shiftedCode;

    if (char.match(/[a-z]/i)) {
      if (code >= 65 && code <= 90) {
        shiftedCode = ((code - 65 + shift) % 26) + 65;
      } else {
        shiftedCode = ((code - 97 + shift) % 26) + 97;
      }
    } else if (char.match(/[0-9]/i)) {
      // shift numbers to alpha characters with the addition of
      // the two numbers are the end.
      shiftedCode = ((code - 48 + shift) % 10) + 48 + 49;
    } else {
      shiftedCode = code;
    }

    char = String.fromCharCode(shiftedCode);
    result += char;
  }

  return result;
}
