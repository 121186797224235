import './index.css';

import { Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { DebounceInput } from 'react-debounce-input';

import useDeepEffect from '../../hooks/useDeepEffect';

const InputGroup = Input.Group;
const { Option } = Select;
const ageMax = 100;
const ageMin = 18;
let timer = null;
const AgePicker = inject('dataStore')(
  observer((props) => {
    const [selectedOption, setSelectedOption] = useState('between');
    const [valueOne, setValueOne] = useState(null);
    const [valueTwo, setValueTwo] = useState(null);

    useEffect(() => {
      document.addEventListener('wheel', disableIncrementOnScroll);

      return () =>
        document.removeEventListener('wheel', disableIncrementOnScroll);
    }, []);

    const disableIncrementOnScroll = () => {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    };

    /** https://medium.com/better-programming/how-to-use-the-react-hook-usedeepeffect-815818c0ad9d */
    useDeepEffect(() => {
      const { age } = props;
      if (age && age.key && age.value && age.value.length > 0) {
        setSelectedOption(age.key);
        if (valueOne !== age.value[0]) setValueOne(age.value[0]);
        setValueTwo(age.key === 'between' ? age.value[1] : age.value[0]);
      } else {
        if (age && age.key && age.key === 'exact') {
          setSelectedOption('exact');
        } else {
          setSelectedOption('between');
        }
        setValueOne(null);
        setValueTwo(null);
      }
    }, [props.age]);

    // function to make ui changes based on exact and between dropdown choosing
    const onDropDownSelect = (value) => {
      setSelectedOption(value === '1' ? 'between' : 'exact');
      if (valueOne || valueTwo) {
        if (value !== '1') {
          setValueTwo(valueOne);
          onRangeSelected(
            undefined,
            undefined,
            value === '1' ? 'between' : 'exact',
          );
        } else {
          onRangeSelected(
            undefined,
            undefined,
            value === '1' ? 'between' : 'exact',
          );
        }
      }
      if (props.onSelect) props.onSelect();
    };

    const SetValues = (id, value) => {
      let left = valueOne;
      let right = valueTwo;

      if (id === 'minAge') {
        setValueOne(value);
        left = value;
      } else if (id === 'maxAge') {
        setValueTwo(value);
        right = value;
      } else if (id === 'exactAge') {
        left = value;
        right = value;
        setValueOne(value);
        setValueTwo(value);
      }
      if (left && right) {
        onRangeSelected(left, right);
      } else if (left === '' && right === '') {
        onRangeSelected(left, right);
      }
      if (value > ageMin && value < ageMax && props.onSelect) props.onSelect();
    };

    const checkMinValue = (e) => {
      const { id } = e.target;
      let { value } = e.target;
      if (value && value < ageMin) {
        value = ageMin;
        SetValues(id, value);
      }
    };

    const checkMaxValue = (e) => {
      const { id } = e.target;
      let { value } = e.target;
      if (value > ageMax) {
        value = ageMax;
      }
      SetValues(id, value);
    };

    const debounce_onRangeChange = (event) => {
      /** Check for max value on typing and then set with the max or typed in value,
       * Check for min value after changing focus, otherwise the value cannot get updated
       * Only update if the value is less than min
       */
      timer = setTimeout(() => checkMaxValue(event), 500);
    };

    const onRangeSelected = (
      one = valueOne,
      two = valueTwo,
      selected = selectedOption,
    ) => {
      const { onValueSelected } = props;
      if (!one && !two) {
        onValueSelected(props.id, {});
      } else if (one >= 0 && two >= 0) {
        const age = {
          key: selected,
          value: [one, two],
        };
        onValueSelected(props.id, age);
      }
    };

    return (
      <InputGroup>
        <div className="range-picker-parent-new">
          <Select
            bordered={false}
            className="range-picker"
            popupClassName="range-picker-dropdown"
            style={{ minWidth: 100, height: 40 }}
            value={selectedOption === 'between' ? '1' : '2'}
            onChange={onDropDownSelect}
          >
            <Option value="1">Between</Option>
            <Option value="2">Exact</Option>
          </Select>
          {selectedOption === 'between' ? (
            <div className="flex">
              <Input
                autoComplete="off"
                id="minAge"
                placeholder="##"
                type="number"
                value={valueOne || ''}
                //onChange={debounce_fun}
                onBlur={checkMinValue}
                style={{ width: '42%', textAlign: 'center' }}
                onInput={(e) => {
                  /**working */
                  if (timer) {
                    clearTimeout(timer);
                  }
                  setValueOne(
                    e.target.value.includes('.')
                      ? e.target.value - (e.target.value % 1)
                      : e.target.value,
                  ); /**Math.floor need extra check when 0 comes */
                  debounce_onRangeChange(
                    e,
                  ); /** to avoid DEBOUNCEDINPUT ,bcoz  couldnt find a soln to restrict input to whole numbers*/
                }}
              />
              <Input
                style={{
                  pointerEvents: 'none',
                  backgroundColor: '#fff',
                  width: '16%',
                  padding: 0,
                  textAlign: 'center',
                }}
                placeholder="~"
                disabled
              />

              <Input
                autoComplete="off"
                id="maxAge"
                className="last-input"
                placeholder="##"
                type="number"
                value={valueTwo || ''}
                //onChange={onRangeChange}
                onBlur={checkMinValue}
                style={{ width: '42%', textAlign: 'center' }}
                onInput={(e) => {
                  /**working */
                  if (timer) {
                    clearTimeout(timer);
                  }
                  setValueTwo(
                    e.target.value.includes('.')
                      ? e.target.value - (e.target.value % 1)
                      : e.target.value,
                  ); /**Math.floor need extra check when 0 comes */
                  debounce_onRangeChange(e);
                }}
              />
            </div>
          ) : (
            <Input
              type="number"
              id="exactAge"
              placeholder="Age"
              value={valueOne}
              className="last-input"
              onBlur={checkMinValue}
              //onChange={onRangeChange}
              onInput={(e) => {
                /**working */
                if (timer) {
                  clearTimeout(timer);
                }
                setValueOne(Math.floor(e.target.value));
                setValueTwo(Math.floor(e.target.value));
                debounce_onRangeChange(e);
              }}
            />
          )}
        </div>
      </InputGroup>
    );
  }),
);

export default AgePicker;
