import './index.css';

import { useState, useEffect, useRef } from 'react';
import { Collapse, Checkbox, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { requestWithAuth } from '../../services/api';

const CheckboxSection = ({ children, value, onCheckboxSelect }) => {
  return (
    <>
      <Checkbox.Group
        className="checkboxes-grid-container"
        value={value}
        onChange={(selected) => {
          onCheckboxSelect(selected);
        }}
      >
        {children}
      </Checkbox.Group>
      <br />
    </>
  );
};

const ExportHeaderOptionsNew = (props) => {
  const { saveLaterHeader, onSaveLaterChange, onChange } = props;
  let { exportHeadersList } = props;

  const firstRender = useRef(true);
  const [household, setHousehold] = useState(false);
  const [householdList, setHouseholdList] = useState([]);
  const [professionalList, setProfessionalList] = useState([]);
  const [triggersList, setTriggersList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [taxInsightsList, setTaxInsightsList] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [expandedPanels, setExpandedPanels] = useState([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
  ]);
  /**Keeping panels expanded as default, if inactive as default and  one panel checkbox attribute is changed the values is checkboxes inside other collapsed panel will turn null */
  /** These are keys passing to the apis. Some are different from the label. */

  /** These are keys passing to the apis. Some are different from the label. */
  const householdOptions = [
    'Home Address Line',
    'Home City',
    'Home State',
    'Home Postal Code',
    'County',
    'Home Owner Status',
    'Estimated Home Value',
    'Age',
    'Sex',
    'Marital Status',
    'Presence of Children',
    'Interests',
  ];
  const professionalOptions = [
    'Current Company Name',
    'Current Company Title',
    'Current Position(s) Date',
    'Previous Company Name',
    'Previous Company Title',
    'Previous Position(s) Date',
    'Years of Experience',
    'Total Years of Experience',
    'Current Company Title Rank',
    'Inferred Income',
    'Wealth Segment',
    'Remaining Share Balance Value',
    'Education',
    'Title Function',
  ];
  const triggerOptions = [
    'Trigger Type',
    'Trigger Description',
    'Trigger Timeframe',
    'Trigger Value',
    'Trigger URL',
  ];
  const taxOptions = [
    'Wage',
    'Household Income',
    'Wealth Income',
    'Retirement Income',
    'Business Income',
    'Schedule E Income',
    'Non Wage Income',
    'Disposable Income',
    'Total Investable Assets',
    'Capital Gains',
    'IRA Deduction',
    'Total Gifts to Charity',
    'Real Estate Taxes',
    'Home Mortgage Interest and Points Reported',
  ];
  const companyOptions = [
    'Current Company Headquarter Address Line',
    'Current Company Work City',
    'Current Company Work State',
    'Current Company Work Postal Code',
    'Industry',
    'Current Company Viability',
    'Current Company Employee Count',
    'Current Company Type',
    'Current Organization URL',
    'Current Company Stock Ticker',
  ];

  const attributesOptions = [
    'Personal Email',
    'Professional Email',
    'Mobile Phone',
    'Other Phone',
    'Twitter Page',
    'Facebook Page',
    'LinkedIn Page',
    'Note',
    'Tags',
    'Person ID Hash',
    'Introducer',
    'Introducer Import Source',
    'Relationship Path',
    'Relationship Detail',
    'Relationship Strength',
    'Degree of Connection',
  ];

  const flags = useFlags();

  useEffect(() => {
    fetchExportHeaders();
  }, []);

  useEffect(() => {
    exportHeadersList = [
      ...householdList,
      ...professionalList,
      ...triggersList,
      ...companyList,
      ...taxInsightsList,
      ...attributesList,
    ];
    /**
     * Household Member Checkbox is Placed outside the CheckboxGroup,
     * So the onchange of CheckboxGroup doesn't contains householdmember
     * And we need to handle the household separately
     */
    if (household) exportHeadersList.push('Household Member');
    else {
      const index = exportHeadersList.indexOf('Household Member');
      if (index > -1) exportHeadersList.splice(index, 1);
    }

    /** in first render, before api call, all checkbox values will be
     * empty and calling props.onChange with empty values will trigger
     * empty warning. To avoid this, keep a ref with value 'true' and
     * conditionally calling props.onChange
     */
    if (firstRender && firstRender.current) firstRender.current = false;
    else onChange(exportHeadersList);
  }, [
    household,
    householdList,
    professionalList,
    triggersList,
    companyList,
    taxInsightsList,
    attributesList,
  ]);

  /** Functionality for calling api of export headers */
  const fetchExportHeaders = () => {
    if (document.getElementById('progress') !== null)
      document.getElementById('progress').style.display = 'block';

    requestWithAuth('user-export-headers', 'GET', null, null, null).then(
      (response) => {
        if (
          response &&
          response.export_headers &&
          response.export_headers.length > 0
        ) {
          const respHeaders = response.export_headers;
          if (respHeaders && respHeaders.length > 0) {
            /** as some of the  export headers are renamed,
             * headers those are already saved in old names
             * are needed to be changed while retrieving from GET api
             * eg: https://aidentified.atlassian.net/browse/DNTFD-8391 */

            const index = respHeaders.indexOf('Relationship Probability');
            if (index !== -1) {
              respHeaders.splice(index, 1);
              respHeaders.push('Relationship Strength');
            }
            const degreeIndex = respHeaders.indexOf('Degree');
            if (degreeIndex !== -1) {
              respHeaders.splice(index, 1);
              respHeaders.push('Degree of Connection');
            }
          }
          setDefaultOptions(respHeaders);
        }
        if (document.getElementById('progress') !== null)
          document.getElementById('progress').style.display = 'none';
      },
    );
  };

  const setDefaultOptions = (exportHeaders) => {
    const index = exportHeaders.indexOf('Household Member');
    if (index > -1) setHousehold(true);
    else setHousehold(false);
    setSectionsData(exportHeaders, householdOptions, setHouseholdList);
    setSectionsData(exportHeaders, professionalOptions, setProfessionalList);
    setSectionsData(exportHeaders, triggerOptions, setTriggersList);
    setSectionsData(exportHeaders, companyOptions, setCompanyList);
    setSectionsData(exportHeaders, taxOptions, setTaxInsightsList);
    setSectionsData(exportHeaders, getAllAttributes(), setAttributesList);
  };

  const setSectionsData = (exportHeaders, options, action) => {
    const defaultOptions = exportHeaders.filter((item) => {
      return options.includes(item);
    });
    action([...defaultOptions]);
  };

  const getAllAttributes = () => {
    const attributeOptions = [...attributesOptions];
    if (flags && flags.neustarId) attributeOptions.push('Neustar ID');
    return attributeOptions;
  };

  const getAllOptions = () => {
    const allOptions = [
      ...householdOptions,
      ...professionalOptions,
      ...triggerOptions,
      ...companyOptions,
      ...taxOptions,
      ...getAllAttributes(),
    ];
    return allOptions;
  };

  const isAllSelected = () => {
    const allOptions = getAllOptions();
    const index = exportHeadersList.indexOf('Household Member');
    if (index > -1) {
      return exportHeadersList.length - 1 === allOptions.length;
    }
    return exportHeadersList.length === allOptions.length;
  };

  const handleAllSelect = (e) => {
    if (e.target.checked) {
      setHouseholdList([...householdOptions]);
      setProfessionalList([...professionalOptions]);
      setTriggersList([...triggerOptions]);
      setCompanyList([...companyOptions]);
      setTaxInsightsList([...taxOptions]);
      setAttributesList([...getAllAttributes()]);
    } else {
      setHouseholdList([]);
      setProfessionalList([]);
      setTriggersList([]);
      setCompanyList([]);
      setTaxInsightsList([]);
      setAttributesList([]);
    }
  };

  const handleExpandAll = () => {
    // Set the keys of all panels
    setExpandedPanels(['1', '2', '3', '4', '5', '6']);
  };
  const handleCollapseAll = () => {
    setExpandedPanels([]);
  };
  const handlePanelChange = (key) => {
    setExpandedPanels(key);
  };

  return (
    <>
      <div className="connect-to">Select Your Export Option</div>
      <div className="select-all-checkbox">
        <Checkbox
          style={{ height: 'fit-content' }}
          checked={isAllSelected()}
          onChange={handleAllSelect}
        >
          Include All Attributes in Export File
        </Checkbox>
        <div></div>
        <div></div>
        <Checkbox
          id="save_later"
          checked={saveLaterHeader}
          onChange={onSaveLaterChange}
        >
          Save Selection for Next Use
        </Checkbox>
        <div style={{ textAlign: 'left' }}>
          <Checkbox
            value="Household Member"
            checked={household}
            onChange={(e) => {
              setHousehold(e.target.checked);
            }}
          >
            Include Household Member
          </Checkbox>
          <div
            style={{
              marginLeft: '24px',
              fontSize: '12px',
              fontStyle: 'italic',
            }}
          >
            (1 credit per Household Member)
          </div>
        </div>
        <div style={{ textAlign: 'right' }}>
          {' '}
          <Button
            type="primary"
            onClick={
              expandedPanels && expandedPanels.length > 0
                ? handleCollapseAll
                : handleExpandAll
            }
          >
            {expandedPanels && expandedPanels.length > 0
              ? 'Collapse All'
              : 'Expand All'}{' '}
          </Button>
        </div>
      </div>
      <div>
        <Collapse
          bordered={false}
          size="small"
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <DownOutlined
              style={{ color: 'var(--color-dark-shade)' }}
              rotate={isActive ? 180 : 0}
            />
          )}
          onChange={handlePanelChange}
          activeKey={expandedPanels}
        >
          <Collapse.Panel
            key="1"
            collapsible="icon"
            header={
              <div className="collapse-header">
                <div className="collapse-title">Household</div>

                <Checkbox
                  checked={householdList.length === householdOptions.length}
                  onChange={(e) => {
                    if (e.target.checked)
                      setHouseholdList([...householdOptions]);
                    else setHouseholdList([]);
                  }}
                >
                  Select All
                </Checkbox>
              </div>
            }
          >
            <CheckboxSection
              value={householdList}
              onCheckboxSelect={(selected) => {
                setHouseholdList([...selected]);
              }}
            >
              <Checkbox value="Home Address Line"> Address Line </Checkbox>
              <Checkbox value="Home City">Home City</Checkbox>
              <Checkbox value="Home State">Home State</Checkbox>
              <Checkbox value="Home Postal Code">Zip Code </Checkbox>
              <Checkbox value="County">County </Checkbox>
              <Checkbox value="Home Owner Status">Home Owner Status</Checkbox>
              <Checkbox value="Estimated Home Value">Home Value</Checkbox>
              <Checkbox value="Age">Age </Checkbox>
              <Checkbox value="Sex">Sex </Checkbox>
              <Checkbox value="Marital Status">
                Modeled Marital Status{' '}
              </Checkbox>
              <Checkbox value="Presence of Children">
                Modeled Presence of Children
              </Checkbox>
              <Checkbox value="Interests">Modeled Interests</Checkbox>
            </CheckboxSection>
          </Collapse.Panel>
          <Collapse.Panel
            key="2"
            collapsible="icon"
            header={
              <div className="collapse-header">
                <div className="collapse-title">Professional</div>

                <Checkbox
                  checked={
                    professionalList.length === professionalOptions.length
                  }
                  onChange={(e) => {
                    if (e.target.checked)
                      setProfessionalList([...professionalOptions]);
                    else setProfessionalList([]);
                  }}
                >
                  Select All
                </Checkbox>
              </div>
            }
          >
            <CheckboxSection
              value={professionalList}
              onCheckboxSelect={(selected) => {
                setProfessionalList([...selected]);
              }}
            >
              <Checkbox value="Current Company Name">
                {' '}
                Current Company(s){' '}
              </Checkbox>
              <Checkbox value="Current Company Title">
                {' '}
                Current Position(s){' '}
              </Checkbox>
              <Checkbox value="Current Position(s) Date">
                Current Position(s) Dates{' '}
              </Checkbox>
              <Checkbox value="Previous Company Name">
                Previous Company(s)
              </Checkbox>
              <Checkbox value="Previous Company Title">
                Previous Position(s)
              </Checkbox>
              <Checkbox value="Previous Position(s) Date">
                Previous Position(s) Dates{' '}
              </Checkbox>
              <Checkbox value="Years of Experience">
                Years of Experience at Current Employer
              </Checkbox>
              <Checkbox value="Total Years of Experience">
                Total Years of Experience
              </Checkbox>
              <Checkbox value="Current Company Title Rank">
                Title Group{' '}
              </Checkbox>
              <Checkbox value="Inferred Income">Inferred Income</Checkbox>
              <Checkbox value="Wealth Segment">Wealth Segment </Checkbox>
              <Checkbox value="Remaining Share Balance Value">
                Remaining Share Balance
              </Checkbox>
              <Checkbox value="Education">Education</Checkbox>
              <Checkbox value="Title Function">Function</Checkbox>
            </CheckboxSection>
          </Collapse.Panel>
          <Collapse.Panel
            key="3"
            collapsible="icon"
            header={
              <div className="collapse-header">
                <div className="collapse-title">Triggers</div>

                <Checkbox
                  checked={triggersList.length === triggerOptions.length}
                  onChange={(e) => {
                    if (e.target.checked) setTriggersList([...triggerOptions]);
                    else setTriggersList([]);
                  }}
                >
                  Select All
                </Checkbox>
              </div>
            }
          >
            <CheckboxSection
              value={triggersList}
              onCheckboxSelect={(selected) => {
                setTriggersList([...selected]);
              }}
            >
              <Checkbox value="Trigger Type"> Trigger Type </Checkbox>
              <Checkbox value="Trigger Description">
                Triggers Description{' '}
              </Checkbox>
              <Checkbox value="Trigger Timeframe">Timeframe</Checkbox>
              <Checkbox value="Trigger Value">Value</Checkbox>
              <Checkbox value="Trigger URL">
                Individual/Corporate Trigger URL
              </Checkbox>
            </CheckboxSection>
          </Collapse.Panel>
          <Collapse.Panel
            key="4"
            collapsible="icon"
            header={
              <div className="collapse-header">
                <div className="collapse-title">Company</div>

                <Checkbox
                  checked={companyList.length === companyOptions.length}
                  onChange={(e) => {
                    if (e.target.checked) setCompanyList([...companyOptions]);
                    else setCompanyList([]);
                  }}
                >
                  Select All
                </Checkbox>
              </div>
            }
          >
            <CheckboxSection
              value={companyList}
              onCheckboxSelect={(selected) => {
                setCompanyList([...selected]);
              }}
            >
              <Checkbox value="Current Company Headquarter Address Line">
                {' '}
                Current Headquarter Address Line
              </Checkbox>
              <Checkbox value="Current Company Work City">City</Checkbox>
              <Checkbox value="Current Company Work State">State</Checkbox>
              <Checkbox value="Current Company Work Postal Code">
                Zip Code{' '}
              </Checkbox>
              <Checkbox value="Industry">Industry </Checkbox>
              <Checkbox value="Current Company Viability">
                Company Viability{' '}
              </Checkbox>
              <Checkbox value="Current Company Employee Count">
                Company Headcount{' '}
              </Checkbox>
              <Checkbox value="Current Company Type">Company Type</Checkbox>
              <Checkbox value="Current Organization URL">
                Company Website
              </Checkbox>
              <Checkbox value="Current Company Stock Ticker">
                Company Stock Ticker
              </Checkbox>
            </CheckboxSection>
          </Collapse.Panel>
          <Collapse.Panel
            key="5"
            collapsible="icon"
            header={
              <div className="collapse-header">
                <div className="collapse-title">US Income Tax Insights</div>

                <Checkbox
                  checked={taxInsightsList.length === taxOptions.length}
                  onChange={(e) => {
                    if (e.target.checked) setTaxInsightsList([...taxOptions]);
                    else setTaxInsightsList([]);
                  }}
                >
                  Select All
                </Checkbox>
              </div>
            }
          >
            <CheckboxSection
              value={taxInsightsList}
              onCheckboxSelect={(selected) => {
                setTaxInsightsList([...selected]);
              }}
            >
              <Checkbox value="Wage"> Wage </Checkbox>
              <Checkbox value="Household Income">Household Income</Checkbox>
              <Checkbox value="Wealth Income">Wealth Income</Checkbox>
              <Checkbox value="Retirement Income">Retirement Income</Checkbox>
              <Checkbox value="Business Income">Business Income </Checkbox>
              <Checkbox value="Schedule E Income">Schedule E Income </Checkbox>
              <Checkbox value="Non Wage Income">Non Wage Income </Checkbox>
              <Checkbox value="Disposable Income">Disposable Income</Checkbox>
              <Checkbox value="Total Investable Assets">
                Total Investable Assets
              </Checkbox>
              <Checkbox value="Capital Gains">Capital Gains</Checkbox>
              <Checkbox value="IRA Deduction">IRA Deduction</Checkbox>
              <Checkbox value="Total Gifts to Charity">
                Total Gifts to Charity{' '}
              </Checkbox>
              <Checkbox value="Real Estate Taxes">Real Estate Taxes </Checkbox>
              <Checkbox value="Home Mortgage Interest and Points Reported">
                Home Mortgage Interest and Points Reported
              </Checkbox>
            </CheckboxSection>
          </Collapse.Panel>
          <Collapse.Panel
            key="6"
            collapsible="icon"
            header={
              <div className="collapse-header">
                <div className="collapse-title">Attributes</div>

                <Checkbox
                  checked={attributesList.length === getAllAttributes().length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setAttributesList([...getAllAttributes()]);
                    } else setAttributesList([]);
                  }}
                >
                  Select All
                </Checkbox>
              </div>
            }
          >
            <CheckboxSection
              value={attributesList}
              onCheckboxSelect={(selected) => {
                setAttributesList([...selected]);
              }}
            >
              <Checkbox value="Personal Email"> Email (personal) </Checkbox>
              <Checkbox value="Professional Email">Email (work) </Checkbox>
              <Checkbox value="Mobile Phone">Phone (mobile)</Checkbox>
              <Checkbox value="Other Phone">Phone (Other) </Checkbox>
              <Checkbox value="Twitter Page">Twitter </Checkbox>
              <Checkbox value="Facebook Page">Facebook </Checkbox>
              <Checkbox value="LinkedIn Page">LinkedIn</Checkbox>
              {flags && flags.neustarId && (
                <Checkbox value="Neustar ID">Neustar ID</Checkbox>
              )}
              <Checkbox value="Note">Notes</Checkbox>

              <Checkbox value="Tags">Tags</Checkbox>
              <Checkbox value="Person ID Hash">Profile ID</Checkbox>

              <Checkbox value="Introducer">Introducer</Checkbox>
              <Checkbox value="Introducer Import Source">
                Introducer Import Source
              </Checkbox>
              <Checkbox value="Relationship Path">Relationship Path</Checkbox>
              <Checkbox value="Relationship Detail">
                Relationship Detail
              </Checkbox>
              <Checkbox value="Relationship Strength">
                Relationship Strength
              </Checkbox>
              <Checkbox value="Degree of Connection">
                {' '}
                Degree of Connection{' '}
              </Checkbox>
            </CheckboxSection>
          </Collapse.Panel>
        </Collapse>
      </div>
    </>
  );
};

export default ExportHeaderOptionsNew;
