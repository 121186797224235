import './index.css';

import { useEffect, useState, useRef } from 'react';
import { Table, message, Select, Modal } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Resizable } from 'react-resizable';
import ReactDragListView from 'react-drag-listview';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { requestWithAuth } from '../../services/api';
import { showConfirm } from '../../utils/popup';
import { handleCrmSync } from '../../utils/crmMethods';
import { splitNumberWithCommas } from '../../utils/general';
import { networkListColumns } from '../../utils/constants';
import ExportComponent from '../ExportComponent';
import SelectClubs from '../SelectClubs';

message.config({
  top: 80,
});

let id1 = null;
let id2 = null;
let sortInfo = null;

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></span>
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: true,
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const ExportTable = inject(
  'dataStore',
  'modalDataStore',
)(
  observer(
    ({
      dataCount,
      data = [],
      columns,
      pagination,
      pageSize,
      rowKey,
      rowKeyArray,
      type,
      onChange,
      expandedRowShowing,
      exportAll,
      expandable,
      crmRowCredentials,
      clearCrmCredentials,
      showTableTop = true,
      searchFilter,
      company,
      editable,
      fromProfile,
      refreshData,
      changeSelectedProfileIds,
      exportFromRow,
      clearExport,
      setProgress,
      ...props
    }) => {
      const navigate = useNavigate();
      const flags = useFlags();

      const [selectedRowKeys, setSelectedRowKeys] = useState([]);
      const [n2PersonId, setN2PersonId] = useState([]);
      const [exportConfirmPopUp, setExportConfirmPopUp] = useState(false);
      const [b2bId, setB2bId] = useState([]);
      const [b2cId, setB2cId] = useState([]);
      const [selectedProfileIds, setSelectedProfileIds] = useState([]);
      const [updatedColumns, setUpdatedColumns] = useState(
        editable ? [] : columns,
      );
      const [crmOk, setCrmOk] = useState(false);
      const [clubCode, setClubCode] = useState();
      const { confirm } = Modal;
      const modalRef = useRef(null);
      /** expandedRowKeys is not getting updated as it is not a state variable in the parentComponent
       * so making it observable here
       */
      const [expandedRowKeys, setExpandedRowKeys] = useState(rowKeyArray);
      const [fromRow, setFromRow] = useState(false);

      useEffect(() => {
        setExpandedRowKeys(rowKeyArray);
      }, [rowKeyArray]);

      useEffect(() => {
        if (editable && columns && columns.length > 0) {
          if (
            !props.dataStore.currentUser.userSelectedColumns ||
            props.dataStore.currentUser.userSelectedColumns.length <= 0
          )
            props.dataStore.currentUser.userSelectedColumns = columns.map(
              (col) => col.key,
            );
          const selected = props.dataStore.currentUser.userSelectedColumns.map(
            (key) => {
              return columns.filter((col) => col.key === key)[0];
            },
          );
          setUpdatedColumns(selected);
        }
      }, [editable]);

      /** For handling CRM clicked within row */
      useEffect(() => {
        if (crmRowCredentials) {
          id1 = crmRowCredentials.id1 || null;
          id2 = crmRowCredentials.id2 || null;

          onCrmClick(true);
        }
      }, [crmRowCredentials]);

      useEffect(() => {
        if (crmOk) {
          handleCrmOkClick(fromRow, clubCode);
        }
      }, [crmOk]);

      /** Reset selected row informaion on api calls from parent component */
      useEffect(() => {
        setSelectedRowKeys([]);
        setN2PersonId([]);
        setSelectedProfileIds([]);
        if (changeSelectedProfileIds) changeSelectedProfileIds([]);
      }, [props.refreshSelectedRows]);

      const paginationProps = {
        showSizeChanger: true,
        showQuickJumper: true,
        // changed page size options to 50,100 and 200
        pageSizeOptions: ['20', '50', '100', '200'],
        /** by default defaultPageSize is 10,
         * to change that to current size or 200
         */
        defaultPageSize: pageSize,
        // set selected page size of user even after signout
        ...pagination,
      };

      const handleTableChange = (pagination, filters, sorter) => {
        sortInfo = sorter;
        if (onChange) {
          onChange(pagination, filters, sorter);
        }
      };

      const handleRowClick = (data) => {
        if (
          props.modalDataStore.trackModal &&
          props.modalDataStore.trackModal.length > 0
        ) {
          // if modal is open from another modal reset existing
          props.modalDataStore.resetCompanyProfileModal();
          props.modalDataStore.resetProfileModal(true);
        }
        const { profileModal } = props.modalDataStore;
        profileModal.b2bId = data.b2b_id;
        profileModal.b2cId = data.b2c_id;
        profileModal.mimpersonId = data.mim_person_id || data.id || '';
        profileModal.eventsId = data.events_id || data.mim_event_id || '';
        profileModal.degree = data.degree;
        profileModal.isVisible = true;

        if (
          props.modalDataStore.trackModal &&
          props.modalDataStore.trackModal.length > 0
        ) {
          if (
            props.modalDataStore.trackModal[
              props.modalDataStore.trackModal.length - 1
            ].modal === 'profile'
          ) {
            /* When <ExportTable> is called from profile page */
            profileModal.fetchProfileData = true;
          }
          // if modal is open from another modal add new one to trackModal
          props.modalDataStore.trackModal.push({
            modal: 'profile',
            data: {
              from: 'n2',
              b2bId: data.b2b_id,
              b2cId: data.b2c_id,
            },
          });
        }
      };

      const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          const n2Ids = [];
          const b2bIds = [];
          const b2cIds = [];
          const selectedB2bB2c = [];
          // setTimeout(() => {
          selectedRows.map((obj) => {
            if (obj.b2b_id) {
              n2Ids.push(obj.b2b_id);
              b2bIds.push(obj.b2b_id);
            }
            if (obj.b2c_id) {
              n2Ids.push(obj.b2c_id);
              b2cIds.push(obj.b2c_id);
            }
            // if (obj.b2b_id || obj.b2c_id) {
            //   selectedB2bB2c.push(
            //     (({ b2b_id, b2c_id, full_name, tags }) => ({
            //       b2b_id,
            //       b2c_id,
            //       full_name,
            //       tags,
            //     }))(obj),
            //   );
            // }
            if (obj.b2b_id || obj.b2c_id) {
              const newObj = {
                full_name: obj.full_name,
                tags: obj.tags,
              };

              if (obj.b2b_id) {
                newObj.b2b_id = obj.b2b_id;
              }

              if (obj.b2c_id) {
                newObj.b2c_id = obj.b2c_id;
              }

              selectedB2bB2c.push(newObj);
            }
          });
          setSelectedRowKeys(selectedRowKeys);
          setN2PersonId(n2Ids);
          setB2bId(b2bIds);
          setB2cId(b2cIds);
          setSelectedProfileIds(selectedB2bB2c);
          changeSelectedProfileIds(selectedB2bB2c);
          // }, 5);
        },
      };

      const onCrmTokenExpiry = (url) => {
        navigate('/connect/export', {
          state: {
            crmSource: url === 'redtail-auth/0' ? 'rt' : 'cm',
            response: url === 'redtail-auth/0' ? 'rt' : 'cm',
          },
        });
      };

      const fetchUserTypeData = () => {
        requestWithAuth('user-type', 'GET', null, null, null).then(
          (response) => {
            if (response && response.results && response.results[0]) {
              props.dataStore.currentUser.creditCount =
                response.results[0].credits;
            }
          },
        );
      };

      const handleSubscribeRedirect = () => {
        navigate('/account/credits');
      };

      const handleExportCsv = (
        nameOfImport,
        saveLaterHeader,
        exportHeadersList,
        linkedInCB,
        emailCB,
        phoneCB,
      ) => {
        setProgress(true);
        const params = {};
        params.export_name = nameOfImport;
        if (props.dataStore.currentUser.isAdmin) {
          if (linkedInCB) {
            params.profile_refresh = 1;
          } else {
            params.profile_refresh = 0;
          }

          if (emailCB) {
            params.email_validation = 1;
          } else {
            params.email_validation = 0;
          }

          if (phoneCB) {
            if (flags.phoneValidation) params.phone_validation = 1;
          } else {
            params.phone_validation = 0;
          }
        }
        const body = {};
        // if (
        //   flags &&
        //   flags.exportHeaderConfirmation &&
        //   exportHeadersList.indexOf('Degree') > -1 &&
        //   exportHeadersList.indexOf('Relationship Paths') < 0
        // )
        //   exportHeadersList.push('Relationship Paths');

        if (!exportHeadersList.includes('First Name'))
          exportHeadersList.push('First Name');

        if (!exportHeadersList.includes('Last Name'))
          exportHeadersList.push('Last Name');

        if (
          saveLaterHeader &&
          exportHeadersList &&
          exportHeadersList.length > 0
        ) {
          const body = {
            export_headers: exportHeadersList,
          };
          requestWithAuth('user-export-headers', 'POST', null, body, null);
        }

        if (Array.isArray(n2PersonId) && n2PersonId.length)
          body.n2_person = n2PersonId;
        else if (
          exportFromRow &&
          Array.isArray(exportFromRow) &&
          exportFromRow.length
        )
          body.n2_person = exportFromRow;
        else body.n2_person = [];

        if (exportHeadersList && exportHeadersList.length > 0) {
          body.export_headers = exportHeadersList;

          const index = exportHeadersList.indexOf('Household Member');
          if (index > -1) params.household_match = 1;
        }
        requestWithAuth(
          'export-csv',
          'POST',
          params,
          body,
          null,
          'progress_people_list',
        ).then((response) => {
          if (
            response &&
            response.errorCode === 'File with given name already exists'
          ) {
            setExportConfirmPopUp(true);
            message.error(response.errorCode, 10);
            setProgress(false);
            return;
          }
          setSelectedRowKeys([]);
          setN2PersonId([]);
          setB2bId([]);
          setB2cId([]);
          setSelectedProfileIds([]);
          changeSelectedProfileIds([]);
          setProgress(false);

          if (response && response.code === 200) {
            setExportConfirmPopUp(false);
            message.success(
              'Thank you - Your request is being processed. You will receive an email with instructions to download when the file is ready.',
              10,
            );
          }
          if (!props.dataStore.currentUser.isAdmin) fetchUserTypeData();
        });
      };

      const exportPopUp = (isFromRow, selectedRowKeys) => {
        let totalContactSelected = 1;
        if (selectedRowKeys && selectedRowKeys.length > 0) {
          totalContactSelected = selectedRowKeys.length;
        }
        const additionalCredits =
          totalContactSelected - props.dataStore.currentUser.creditCount;
        const content = (
          <>
            You selected{' '}
            {isFromRow ? 1 : splitNumberWithCommas(selectedRowKeys.length)}
            {isFromRow || selectedRowKeys.length <= 1
              ? ' profile '
              : ' profiles '}{' '}
            to export
            <br />
            {totalContactSelected <= props.dataStore.currentUser.creditCount
              ? 'Please Confirm'
              : `You require an additional ${splitNumberWithCommas(
                  additionalCredits,
                )} ${
                  additionalCredits > 1 ? ' credits ' : ' credit '
                }to proceed`}
          </>
        );
        showConfirm({
          className: 'small-popup center-align',
          title: 'Confirm Export',
          closable: true,
          content: content,
          okText:
            totalContactSelected <= props.dataStore.currentUser.creditCount
              ? 'Yes'
              : 'Buy More',
          onOk: () => {
            if (
              totalContactSelected <= props.dataStore.currentUser.creditCount
            ) {
              setFromRow(isFromRow);
              setCrmOk(true);
            } else handleSubscribeRedirect();
          },
          onCancel: () => {
            onConfirmPopUpCancel();

            return false;
          },
        });
      };
      const clubModel = (isFromRow, selectedRowKeys) => {
        modalRef.current = confirm({
          icon: null,
          okButtonProps: {
            disabled: true,
          },

          onOk() {
            exportPopUp(isFromRow, selectedRowKeys);
            modalRef.current.destroy();
          },
          onCancel() {
            setClubCode();
            if (modalRef && modalRef.current) modalRef.current.destroy();
          },
          className: 'club-popUp',
          title: 'Select Club/Location',
          content: (
            <SelectClubs
              setClubVal={(val) => setClubCode(val)}
              modalRef={modalRef}
            />
          ),
        });
      };

      const onCrmClick = (isFromRow) => {
        if (!props.dataStore.currentUser.crm) {
          navigate('/connect/export');
          return;
        }
        if (
          props.dataStore.currentUser.crm === 'sf' &&
          props.dataStore.currentUser.org_name === 'INV'
        )
          clubModel(isFromRow, selectedRowKeys);
        else exportPopUp(isFromRow, selectedRowKeys);
      };

      const handleCrmOkClick = (isFromRow, clubCode) => {
        setProgress(true);
        const body = {};

        let person = [];
        if (Array.isArray(n2PersonId) && n2PersonId.length && !isFromRow) {
          /** Click from button with selected rows  */
          person = n2PersonId;
        } else {
          /** Click from Row */

          if (id1) person.push(id1);
          if (id2) person.push(id2);
        }
        body.n2_person = person;

        if (props.dataStore.currentUser.org_name)
          body.org_name = props.dataStore.currentUser.org_name;

        if (clubCode) body.club_code = clubCode.value;

        // handleCrmSync - is a method in utilis/crmsync.js
        // if any crm is integrated - call the method, else redirect to crm import page.
        // when handleCrmSync returns , it enteres the then part of promise and makes the progress bar invisible,
        // also unchecks the selected checkboxes.
        if (props.dataStore.currentUser.crm) {
          handleCrmSync(
            props.dataStore.currentUser.ownerId,
            props.dataStore.currentUser.crm,
            body,
            onCrmTokenExpiry,
            'progress_people_list',
            props.dataStore.networkFilters,
          ).then(() => {
            setSelectedRowKeys([]);
            setSelectedProfileIds([]);
            changeSelectedProfileIds([]);
            setN2PersonId([]);
            id1 = null;
            id2 = null;
            clearCrmCredentials();
            if (!props.dataStore.currentUser.isAdmin) fetchUserTypeData();
            setProgress(false);
          });
        } else {
          navigate('/connect/export');
        }
      };

      const onCloseExport = () => {
        setExportConfirmPopUp(false);
        setN2PersonId([]);
        setSelectedRowKeys([]);
        setSelectedProfileIds([]);
        changeSelectedProfileIds([]);
        setFromRow(false);
        setCrmOk(false);
        clearExport();
      };

      const onConfirmPopUpCancel = (e) => {
        setSelectedRowKeys([]);
        setN2PersonId([]);
        setB2bId([]);
        setB2cId([]);
        setSelectedProfileIds([]);
        changeSelectedProfileIds([]);
        id1 = null;
        id2 = null;
        clearCrmCredentials();
      };

      const handleDeleteClick = (from) => {
        if (from === 'cancel') {
          setSelectedRowKeys([]);
          setSelectedProfileIds([]);
          changeSelectedProfileIds([]);
          setN2PersonId([]);
          setB2bId([]);
          setB2cId([]);
          return;
        }
        setProgress(true);
        const body = {};
        if (b2bId) body.b2b_ids = b2bId;
        if (b2cId) body.b2c_ids = b2cId;
        requestWithAuth('delete-vault-profiles', 'POST', null, body, null).then(
          (response) => {
            const selectedRowLength = selectedRowKeys.length;
            setSelectedRowKeys([]);
            setSelectedProfileIds([]);
            changeSelectedProfileIds([]);
            setN2PersonId([]);
            setProgress(false);
            if (response && response.code && response.code === 200) {
              // props.dataStore.networkFilters.fetchData = true;
              if (props.deleteData)
                props.deleteData(b2bId, b2cId, selectedRowLength);
              setB2bId([]);
              setB2cId([]);
            }
          },
        );
      };

      const handleResize =
        (index) =>
        (e, { size }) => {
          e.stopPropagation();
          const newColumns = [...updatedColumns];
          if (size.width < 130) {
            newColumns[index] = {
              ...newColumns[index],
              width: 130,
            };
          } else if (size.width > 500) {
            newColumns[index] = {
              ...newColumns[index],
              width: 500,
            };
          } else {
            newColumns[index] = {
              ...newColumns[index],
              width: size.width,
            };
          }
          setUpdatedColumns([...newColumns]);
          // props.dataStore.currentUser.userSelectedColumns = newColumns;
        };

      const updateColumnSelection = () => {
        const selected = props.dataStore.currentUser.userSelectedColumns;
        if (selected && selected.length > 0) {
          const selectedColumns =
            props.dataStore.currentUser.userSelectedColumns.map((key) => {
              return columns.filter((col) => col.key === key)[0];
            });
          setUpdatedColumns(selectedColumns);
        }
      };

      const onColumnDeselect = (selected) => {
        const index =
          props.dataStore.currentUser.userSelectedColumns.indexOf(selected);
        if (index > -1)
          props.dataStore.currentUser.userSelectedColumns.splice(index, 1);
        updateColumnSelection();
      };

      const onColumnSelect = (selected) => {
        props.dataStore.currentUser.userSelectedColumns.push(selected);
        if (selected === 'note' || selected === 'tags')
          props.dataStore.networkFilters.fetchData = true;

        updateColumnSelection();
      };

      const revisedColumns = updatedColumns.map((col, index) => ({
        ...col,
        title: <span className="dragHandler">{col?.title}</span>,
        onHeaderCell: (column) => ({
          width: column.width,
          onResize: handleResize(index),
        }),
      }));

      const dragProps = {
        onDragEnd(fromIndex, toIndex) {
          /** ignore checkbox column at index 0 */
          if (!fromIndex) return;
          const columns = [...updatedColumns];
          const item = columns.splice(fromIndex - 1, 1)[0];
          columns.splice(toIndex - 1, 0, item);
          props.dataStore.currentUser.userSelectedColumns = columns
            .map((col) => col?.key)
            .filter((item) => item !== undefined);
          setUpdatedColumns([...columns]);
        },
        lineClassName: 'drop-line',
        nodeSelector: 'th',
        handleSelector: '.dragHandler',
        ignoreSelector: 'react-resizable-handle',
      };

      return (
        <div className="export-table">
          {showTableTop && (
            <div style={{ display: 'flex' }}>
              {editable && (
                <Select
                  bordered={false}
                  tagRender={() => {
                    return;
                  }}
                  popupClassName="column-selector-dropdown"
                  className="column-selector"
                  suffixIcon={
                    <EditOutlined
                      style={{
                        fontSize: '20px',
                        color: 'var(--color-primary)',
                        // marginTop: '5px'
                      }}
                    />
                  }
                  mode="multiple"
                  onSelect={onColumnSelect}
                  onDeselect={onColumnDeselect}
                  options={networkListColumns}
                  value={networkListColumns.filter((elem) => {
                    return updatedColumns.some((ele) => {
                      return ele?.key === elem.value;
                    });
                  })}
                  // getPopupContainer={() => document.getElementById('netwotkList')}
                />
              )}
              <ExportComponent
                dataCount={dataCount}
                rowSelection={rowSelection}
                types={type}
                pagination={paginationProps}
                company={company}
                fromProfile={fromProfile}
                showExportPopUp={exportConfirmPopUp}
                cancelExport={onCloseExport}
                cancelConfirmPopUp={onConfirmPopUpCancel}
                deleteApi={handleDeleteClick}
                handleExportCsv={handleExportCsv}
                onCrmClick={onCrmClick}
                selectedProfileIds={selectedProfileIds}
                refreshData={refreshData}
                sortInfo={sortInfo}
                exportFromRow={exportFromRow}
                clearExport={clearExport}
                setProgress={setProgress}
              />
            </div>
          )}
          <ReactDragListView.DragColumn {...dragProps}>
            <Table
              rowKey={rowKey}
              expandable={{
                ...expandable,
                expandIconColumnIndex: -1,
                expandedRowKeys,
                defaultExpandAllRows: true,
                expandedRowClassName: () => 'expanded-row',
              }}
              components={
                editable
                  ? {
                      header: {
                        cell: ResizableTitle,
                      },
                    }
                  : {}
              }
              bordered={editable}
              columns={editable ? revisedColumns : columns}
              dataSource={data}
              showSorterTooltip={false}
              pagination={paginationProps}
              onChange={handleTableChange}
              rowSelection={showTableTop ? rowSelection : null}
              rowClassName={'export-table-row'}
              scroll={props.scroll || { x: 'max-content', y: 600 }}
              onRow={(record) => ({
                onClick: () => {
                  handleRowClick(record);
                },
              })}
            />
          </ReactDragListView.DragColumn>
        </div>
      );
    },
  ),
);

export default ExportTable;
