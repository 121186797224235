import './index.scss';

import { Button, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import { InfoCircleFilled } from '@ant-design/icons';
import CompanyIcon from '../../assets/icons/ai_company.svg?react';
import {
  splitNumberWithCommas,
  getEmployeeConfidenceLevel,
  formatPhoneNumber,
} from '../../utils/general';
import LoaderAnimation from '../AidLoader';

const CompanyInfo = inject('dataStore')(
  observer((props) => {
    const { data } = props;

    // const seeAllEmployees = () => {
    //   props.dataStore.companyFilter = null;
    //   props.dataStore.companyFilter = props.dataStore.companyFilter || {};
    //   props.dataStore.companyFilter.org_name = data.short_name;
    //   props.history.push({
    //     pathname: '/prospect-finder/people',
    //   });
    // };

    const getHqAddress = () => {
      const address1 = `${
        data.address_line1
          ? data.address_line1.toString().toLowerCase().toTitleCase()
          : ''
      } ${
        data.address_line2
          ? data.address_line2.toString().toLowerCase().toTitleCase()
          : ''
      }`.trim();
      const address2 = `${
        data.city ? data.city.toString().toLowerCase().toTitleCase() : ''
      } ${
        data.region ? data.region.toString().toLowerCase().toTitleCase() : ''
      } ${data.state ? data.state : ''} ${
        data.postal_code ? data.postal_code : ''
      }`.trim();
      return address1 || address2 ? (
        <>
          <hr className="horizontal-line" />
          <div className="label-value">
            <span className="label-span">HQ Address</span>
            <span className="value-span">
              {address1}
              {address1 && address2 && <br />}
              {address2}
            </span>
          </div>
        </>
      ) : (
        <></>
      );
    };

    return (
      <div style={{ position: 'relative' }}>
        {props.isLoading && (
          <LoaderAnimation size="medium" loaderId="company-info-loader" />
        )}
        <div className={props.isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
          <div className="company-info">
            <div className="company-header">
              <div>
                <CompanyIcon
                  width={18}
                  height={16}
                  style={{
                    color: 'var(--color-icon)',
                    fill: 'var(--color-icon)',
                  }}
                />
              </div>
              <div>
                <label className="heading">
                  Company
                  <span
                    style={{
                      color: 'var(--color-profile-labels)',
                      fontWeight: 'normal',
                    }}
                  >
                    {' '}
                    Info
                  </span>
                </label>
              </div>
            </div>
            <div className="detail-section">
              {data && data.short_description && (
                <div className="label-value">
                  <span className="label-span">Overview</span>
                  <span className="value-span">{data.short_description}</span>
                </div>
              )}
              {data && data.industry && (
                <div className="label-value">
                  <span className="label-span">Industry</span>
                  <span className="value-span">
                    {data.industry.toString().toTitleCase()}
                  </span>
                </div>
              )}
              {data &&
                data.naics_descriptions &&
                data.naics_descriptions.length > 0 && (
                  <>
                    <hr className="horizontal-line" />
                    <div className="label-value">
                      <span className="label-span">NAICS</span>
                      <span className="value-span">
                        {data.naics_descriptions.join(', ')}
                      </span>
                    </div>
                  </>
                )}
              {data && data.type && (
                <>
                  <hr className="horizontal-line" />
                  <div className="label-value">
                    <span className="label-span">Company Type</span>
                    <span className="value-span">
                      {data.type.toString().toTitleCase()}
                    </span>
                  </div>
                </>
              )}
              {data && data.stock_ticker && (
                <>
                  <hr className="horizontal-line" />
                  <div className="label-value">
                    <span className="label-span">Stock Ticker</span>
                    <span className="value-span">{data.stock_ticker}</span>
                  </div>
                </>
              )}
              {data && data.domain && (
                <>
                  <hr className="horizontal-line" />
                  <div className="label-value">
                    <span className="label-span">Web</span>
                    <span className="link">
                      <a
                        href={`//${data.domain}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data.domain.toString().toTitleCase()}
                      </a>
                    </span>
                  </div>
                </>
              )}
              {data && data.phone && (
                <>
                  <hr className="horizontal-line" />
                  <div className="label-value">
                    <span className="label-span">Phone</span>
                    <span className="value-span">
                      {formatPhoneNumber(data.phone)}
                    </span>
                  </div>
                </>
              )}
              {data &&
                data.stock_performance !== null &&
                data.stock_performance !== undefined && (
                  <>
                    <hr className="horizontal-line" />
                    <div className="label-value">
                      <span className="label-span">
                        Company Viability{' '}
                        <Tooltip
                          placement="left"
                          title="Company Viability is based on a six-month evaluation of the employer’s stock price.  This is updated daily."
                        >
                          <span
                            className="toolTipImageDiv"
                            style={{
                              lineHeight: '30px',
                              verticalAlign: 'middle',
                            }}
                          >
                            <InfoCircleFilled />
                          </span>
                        </Tooltip>
                      </span>
                      <span className="value-span">
                        {' '}
                        {getEmployeeConfidenceLevel(data.stock_performance)}
                      </span>
                    </div>
                  </>
                )}
              {getHqAddress()}
            </div>
          </div>
          {data && (
            <div className="see-all-section">
              <Button type="primary">
                <span
                  className="button-label"
                  onClick={() => props.seeInPeopleView('people')}
                >
                  See all {splitNumberWithCommas(data.employee_count) || ''} US
                  employees
                </span>
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }),
);

export default CompanyInfo;
