import './index.scss';

import { inject, observer } from 'mobx-react';
import { CloseCircleFilled } from '@ant-design/icons';

import { commarize } from '../../utils/general';
import LoaderIcon from '../../assets/icons/ai_ic_logo_gold.svg?react';

const WeeklyEngagement = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const { data, isLoading } = props;
    return (
      <div className="loader-wrapper">
        {isLoading && (
          <LoaderIcon className="aid-sidecar-ext-logo-spin-icon-only" />
        )}
        <div
          className={
            isLoading
              ? 'dashboard-item weekly-engagement aid-div-disable'
              : 'dashboard-item  weekly-engagement aid-div-enable'
          }
        >
          {isLoading && (
            <LoaderIcon className="aid-sidecar-ext-logo-spin-icon-only" />
          )}
          <div className="weekly-engagement-header">
            <div className="dashboard-item-title" style={{ display: 'inline' }}>
              Weekly Engagement
            </div>
            <div>
              <CloseCircleFilled
                onClick={props.onCloseButtonClick}
                style={{ color: 'var(--color-secondary)' }}
              />
            </div>
          </div>
          <div className="data-section">
            <div className="count-label-section" style={{ paddingLeft: 0 }}>
              <div
                className="count"
                style={{ backgroundColor: 'var(--color-light-grey)' }}
              >
                {data.search_count ? commarize(data.search_count) : 0}
              </div>
              <div className="label">Number of Searches</div>
            </div>
            <div className="count-label-section">
              <div
                className="count"
                style={{ backgroundColor: 'var(--color-horizontal-line)' }}
              >
                {data.profiles_viewed_count
                  ? commarize(data.profiles_viewed_count)
                  : 0}
              </div>
              <div className="label">Profile View</div>
            </div>
            <div className="count-label-section">
              <div
                className="count"
                style={{ backgroundColor: 'var(--color-profile-connectivity)' }}
              >
                {data.export_count ? commarize(data.export_count) : 0}
              </div>
              <div className="label">Number of Exports</div>
            </div>
            <div
              className="count-label-section"
              style={{ borderRight: 'none', paddingRight: 0 }}
            >
              <div
                className="count"
                style={{ backgroundColor: 'var(--color-medium-blue)' }}
              >
                {data.csv_import_count ? commarize(data.csv_import_count) : 0}
              </div>
              <div className="label">Number of CSV Imports</div>
            </div>
            {/* <div className='count-label-section' style={{ borderRight: 'none', paddingRight: 0 }}>
                            <div className='count' style={{ backgroundColor: 'var(--color-light-green)' }}>{data.team_member_count ? commarize(data.team_member_count) : 0}</div>
                            <div className='label'>Team Members</div>
                        </div> */}
          </div>
        </div>
      </div>
    );
  }),
);

export default WeeklyEngagement;
