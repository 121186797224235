import { Tooltip } from 'antd';

import InfoIcon from '../../assets/icons/ai_info.svg?react';

const AiTooltip = ({
  placement = 'bottom',
  title,
  size = '14px',
  iconColor = '#91AFCA',
  marginBottom = '-2px',
  children,
}) => {
  return (
    <Tooltip
      placement={placement}
      color="linear-gradient(180deg, #FFFFFF 0%, #F4FAFF 100%)"
      title={title ? title : ''}
      overlayInnerStyle={{
        color: 'var(--color-black)',
        padding: '8px 14px',
        boxShadow: '0 3px 6px 0 rgba(0,0,0,0.3)',
        fontSize: '12px',
      }}
    >
      {children ? (
        children
      ) : (
        <InfoIcon
          style={{
            color: iconColor,
            cursor: 'pointer',
            width: size,
            height: size,
            marginBottom: marginBottom,
            marginLeft: '5px',
          }}
        />
      )}
    </Tooltip>
  );
};

export default AiTooltip;
