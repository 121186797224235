import './index.scss';
import { inject, observer } from 'mobx-react';
import { CloseCircleFilled } from '@ant-design/icons';
import { useEffect, useState, useRef } from 'react';
import LoaderIcon from '../../assets/icons/ai_ic_logo_gold.svg?react';

const WeeklyTip = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const [tipsData, setTipsData] = useState([]);

    const descriptionRef = useRef(null);

    useEffect(() => {
      if (props.data && props.data.suggestions) {
        setTipsData(
          props.data.suggestions.filter(
            (s) =>
              s &&
              s.suggestion_type === 'tip' &&
              s.image_url &&
              s.title &&
              s.description,
          ),
        );
      }
    }, [props.data]);

    useEffect(() => {
      if (
        descriptionRef &&
        descriptionRef.current &&
        tipsData &&
        tipsData[0].description
      ) {
        const exp_match =
          /(\b(https?|):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
        /* For reference($1):: https://www.geeksforgeeks.org/how-to-replace-plain-url-with-link-using-javascript/ */
        const result = tipsData[0].description.replace(
          exp_match,
          "<a href='$1' target='_blank'>$1</a>",
        );
        if (descriptionRef && descriptionRef.current)
          descriptionRef.current.innerHTML = result;
      }
    }, [tipsData, descriptionRef]);

    return (
      <div className="loader-wrapper">
        {props.data && Object.keys(props.data).length === 0 && (
          <LoaderIcon className="aid-sidecar-ext-logo-spin-icon-only" />
        )}
        <div
          className={
            props.data && Object.keys(props.data).length === 0
              ? 'dashboard-item weekly-tip aid-div-disable'
              : 'dashboard-item  weekly-tip aid-div-enable'
          }
        >
          <div className="weekly-tip-header">
            <div className="dashboard-item-title">Weekly Tip</div>
            <div>
              <CloseCircleFilled
                onClick={props.onCloseButtonClick}
                style={{ color: 'var(--color-secondary)' }}
              />
            </div>
          </div>
          <div className="weekly-tip-content">
            {tipsData.length > 0 ? (
              <div className="rectangle">
                <img
                  style={{ width: '200px' }}
                  src={tipsData[0].image_url}
                  alt="suggested_image"
                  draggable="false"
                ></img>

                <div className="description-sec">
                  <div className="description-title">{tipsData[0].title}</div>
                  <div
                    className="description"
                    id="description"
                    ref={descriptionRef}
                  ></div>
                </div>
              </div>
            ) : (
              props.data &&
              Object.keys(props.data).length > 0 && (
                <div className="dashboard-item-empty">
                  No Suggestions for Now
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  }),
);

export default WeeklyTip;
