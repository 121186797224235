import {
  FunctionFlags,
  StandardEventTypes,
  PremiumEventTypes,
} from './constants';

export function produceMaskedContent(length = 1) {
  if (length <= 0) {
    legnth = 1;
  }

  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!$%^&*()+',.<>?/-=";
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  return result;
}

export function hasEntitlement(currentUser, functionFlag) {
  if (!currentUser) return false;
  if (currentUser.type === 'admin') return true;
  if (!currentUser.functionFlags || currentUser.functionFlags.length == 0)
    return false;

  return currentUser.functionFlags.includes(functionFlag);
}

export function isEntitledToPremiumContent(currentUser, profile, functionFlag) {
  if (!currentUser || !profile) return false;

  if (hasEntitlement(currentUser, functionFlag)) {
    return true;
  }

  return isProfilePurchased(currentUser, profile);
}

export function isPremiumTrigger(trigger) {
  if (!trigger) return false;

  return Object.values(PremiumEventTypes).includes(trigger);
}

export function isProfilePurchased(currentUser, person) {
  if (!currentUser || !person) return false;

  // TODO: Implement the logic to check if the profile/person is purchased once API is ready
  return false;
}

export function availableTriggers(functionFlags) {
  const triggers = Object.values(StandardEventTypes);

  if (functionFlags.includes(FunctionFlags.premium_wealth_triggers)) {
    triggers.push(...Object.values(PremiumEventTypes));
  }

  return triggers;
}
