import { formatEventSource } from '../../utils/general';

import './index.scss';

const OpportunityInfo = (props) => {
  const {
    title,
    date,
    description,
    eventSource,
    mentions,
    articleUrl,
    investor,
  } = props;

  return (
    <div
      className="opportunity-info"
      style={{
        cursor: articleUrl ? 'pointer' : 'auto',
        paddingTop: '5px',
      }}
      onClick={() => {
        if (articleUrl && articleUrl.length)
          window.open(articleUrl, '_blank', 'noreferrer');
      }}
    >
      <div className="opportunity-box">{title}</div>
      <div className="opportunity-info-box">
        <div>
          {description}
          {description &&
            eventSource &&
            ` (Source: ${formatEventSource(eventSource)})`}
          {investor && <div>Investor: {investor}</div>}
          {mentions && <div dangerouslySetInnerHTML={{ __html: mentions }} />}
          {articleUrl && (
            <div>
              <a href={articleUrl} target="_blank" rel="noreferrer">
                View entire article
              </a>
            </div>
          )}
        </div>
        {/*
        <div style={{ display: 'flex', gap: '10px', paddingTop: '11px' }}>
          {work_emails && work_emails.length > 0 && (
            <Button className="collapse-button">
              <div className="button-content">
                <MailIcon className="icon" />
                Email this Person
              </div>
            </Button>
          )}
          {phones && phones.length > 0 && (
            <Button className="collapse-button">
              <div className="button-content">
                <PhoneIcon />
                Make a call
              </div>
            </Button>
          )}
        </div>
        */}
      </div>
    </div>
  );
};

export default OpportunityInfo;
