import './index.scss';

import { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Select } from 'antd';

import { groupByFilter } from '../../utils/constants';

const GroupByDropDown = inject('dataStore')(
  observer((props) => {
    useEffect(() => {
      window.addEventListener('popstate', handlePopState);

      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }, []);

    const handlePopState = (event) => {
      // The popstate event is fired each time when the current history entry changes.

      props.dataStore.networkFilters.fetchData = false;
      if (props.dataStore.selectedGroupByParam.length > 0) {
        const selectedGroupByVals = props.dataStore.selectedGroupByParam || [];

        if (
          selectedGroupByVals &&
          Array.isArray(selectedGroupByVals) &&
          selectedGroupByVals.length
        ) {
          if (
            selectedGroupByVals[selectedGroupByVals.length - 1] ==
              'age_range' &&
            props.dataStore.groupByValues['age'] &&
            Object.keys(props.dataStore.groupByValues['age']).length > 0
          ) {
            props.dataStore.groupByValues['age'] = {};
            props.dataStore.isShowingInsightsView = true;
            props.dataStore.networkFilters.fetchData = true;
          } else if (
            selectedGroupByVals[selectedGroupByVals.length - 1] ==
              'tags_column' &&
            props.dataStore.groupByValues['tags'] &&
            Object.keys(props.dataStore.groupByValues['tags']).length > 0
          ) {
            props.dataStore.groupByValues['tags'] = {};
            props.dataStore.isShowingInsightsView = true;
            props.dataStore.networkFilters.fetchData = true;
          } else if (
            selectedGroupByVals[selectedGroupByVals.length - 1] ==
            'top_team_members'
          ) {
            if (!props.dataStore.isShowingInsightsView) {
              props.dataStore.groupByValues['team_member_id'] = '';
              props.dataStore.teamFilters.checkedTeamIds = [];

              props.dataStore.teamFilters.isAllChecked = true;
              props.dataStore.networkFilters.filter_type = 'all';
              props.dataStore.isShowingInsightsView = true;
            } else {
              selectedGroupByVals.pop();

              props.dataStore.selectedGroupByParam = [...selectedGroupByVals];
              props.dataStore.networkFilters.filter_type = 'all';
              props.dataStore.teamFilters.uiTeamIds = [[]];
              props.dataStore.teamFilters.team_ids = [];
              props.dataStore.teamFilters.checkedTeamIds = [];
              props.dataStore.groupByValues.team_member_id = '';
              props.dataStore.teamFilters.isAllChecked = false;
              props.dataStore.networkFilters.team_file_imports = [];
              props.dataStore.networkFilters.import_source = [];
              props.dataStore.networkFilters.file_imports = [];
              props.dataStore.isShowingInsightsView = false;
              props.dataStore.lookUpLists.insightHistory = true;
              //props.dataStore.networkFilters.fetchData = true
            }
          } else if (
            selectedGroupByVals[selectedGroupByVals.length - 1] ==
              'inferred_salary' &&
            props.dataStore.groupByValues['inferred_salary_range'] &&
            Object.keys(props.dataStore.groupByValues['inferred_salary_range'])
              .length > 0
          ) {
            props.dataStore.groupByValues['inferred_salary_range'] = [];
            props.dataStore.isShowingInsightsView = true;
            props.dataStore.networkFilters.fetchData = true;
          } else if (
            props.dataStore.groupByValues[
              selectedGroupByVals[selectedGroupByVals.length - 1]
            ] &&
            props.dataStore.groupByValues[
              selectedGroupByVals[selectedGroupByVals.length - 1]
            ].length > 0
          ) {
            props.dataStore.groupByValues[
              selectedGroupByVals[selectedGroupByVals.length - 1]
            ] = null;

            props.dataStore.isShowingInsightsView = true;
          } else {
            selectedGroupByVals.pop();
            props.dataStore.selectedGroupByParam = [...selectedGroupByVals];
            props.dataStore.isShowingInsightsView = false;

            props.dataStore.lookUpLists.insightHistory = true;
          }
        }
      }
    };

    const onGroupByChange = (value) => {
      if (value === 'select') {
        props.dataStore.isShowingInsightsView = false;
        props.dataStore.lookUpLists.isSummaryPage = false;
        props.dataStore.selectedGroupByParam = [];
        props.dataStore.resetGroupByValues();
      } else {
        if (props.dataStore.isShowingInsightsView)
          props.dataStore.selectedGroupByParam.pop();
        props.dataStore.isShowingInsightsView = true;
        props.dataStore.selectedGroupByParam.push(value);
      }
      window.history.pushState(
        { insightview: 'insightview' },
        null,
        window.location.pathname,
      );

      props.dataStore.networkFilters.fetchData = true;
    };

    if (
      !props.dataStore.lookUpLists.isPeoplePage &&
      !(
        props.dataStore.lookUpLists.isSummaryPage &&
        !props.dataStore.lookUpLists.isCompanyPage
      )
    )
      return <div></div>;

    return (
      <div className="group-by-dropdown">
        <div className="group-by-dropdown-container">
          <div className="dropdown-label">GROUP BY</div>
          <Select
            value={
              props.dataStore.selectedGroupByParam &&
              props.dataStore.selectedGroupByParam.length > 0 &&
              props.dataStore.isShowingInsightsView
                ? props.dataStore.selectedGroupByParam.slice(-1).toString()
                : 'select'
            }
            //className={'group-by-filter-new'}
            onChange={onGroupByChange}
            dropdownMatchSelectWidth={false}
            bordered={false}
            // options={groupByFilter}
          >
            {groupByFilter.map((params) => (
              <>
                <Select.Option
                  value={params.value}
                  disabled={props.dataStore.selectedGroupByParam.includes(
                    params.value,
                  )}
                >
                  {params.label}
                </Select.Option>
              </>
            ))}
          </Select>
        </div>
      </div>
    );
  }),
);

export default GroupByDropDown;
