import './index.scss';

import { inject, observer } from 'mobx-react';
import { Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';

import EventDescription from './EventDescription';
import EventDescriptionNew from './EventDescriptionNew';
import TaxDescription from './TaxDescription';
import WealthSegmentDescription from './WealthSegmentDescription';
import CreditDescription from './CreditDescription';

const HelpModal = (props) => {
  const flags = useFlags();
  const handleCancel = () => {
    const { onClose } = props;
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      width="80%"
      wrapClassName={
        props.type === 'tax' ||
        (props.type === 'event' && (!flags || !flags.dntfd9217DeceasedTriggers))
          ? 'description-modal'
          : 'description-modal-new'
      }
      style={{
        marginTop:
          props.modalDataStore.profileModal.isVisible &&
          (props.type === 'wealth' ||
            (props.type === 'event' &&
              flags &&
              flags.dntfd9217DeceasedTriggers))
            ? '300px'
            : '125px',
      }}
      centered
      open
      footer={null}
      onCancel={handleCancel}
      closeIcon={
        props.type === 'tax' ||
        (props.type === 'event' &&
          (!flags || !flags.dntfd9217DeceasedTriggers)) ? (
          ''
        ) : (
          <CloseCircleOutlined style={{ fontSize: 20 }} />
        )
      }
    >
      <div
        className={
          props.type === 'tax' ||
          (props.type === 'event' &&
            (!flags || !flags.dntfd9217DeceasedTriggers))
            ? ''
            : 'description-modal-container'
        }
      >
        {props.type === 'event' && (
          <>
            {flags && flags.dntfd9217DeceasedTriggers ? (
              <EventDescriptionNew />
            ) : (
              <EventDescription />
            )}
          </>
        )}
        {props.type === 'tax' && <TaxDescription />}
        {props.type === 'wealth' && <WealthSegmentDescription />}
        {props.type === 'credits' && <CreditDescription />}
      </div>
    </Modal>
  );
};

export default inject('modalDataStore')(observer(HelpModal));
