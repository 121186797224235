import { message } from 'antd';
import { requestWithAuth, updateUserInfo } from '../services/api';

import IntegrateCrm from '../assets/icons/ai_integrate.svg';
import iconLofty from '../assets/icons/ai_lofty.svg';
import iconContactually from '../assets/icons/ai_contactually.svg';
import iconHubSpot from '../assets/icons/ai_hubspot.svg';
import iconPipedrive from '../assets/icons/ai_pipedrive.svg';
import iconRedtail from '../assets/icons/ai_redtail.svg';
import iconSalesforce from '../assets/icons/ai_salesforce.svg';
import { setCookie } from './cookies';

// import iconCrm from '../assets/integrate_crm.svg';

message.config({
  top: 80,
});

const setFilters = (tempFilters, crmValue) => {
  if (tempFilters) {
    localStorage.setItem('tempNetworkFilters', JSON.stringify(tempFilters));
    localStorage.setItem('redirectionPath', window.location.pathname);
  }
  if (crmValue) localStorage.setItem('crm_source', crmValue);
};

const handleSuccess = (progressBar) => {
  if (document.getElementById(progressBar) !== null)
    document.getElementById(progressBar).style.display = 'none';
  message.success('Prospect(s) transferring now', 5);
};

const handleError = (errMsg, progressBar) => {
  if (document.getElementById(progressBar) !== null)
    document.getElementById(progressBar).style.display = 'none';
  message.error(errMsg, 5);
};

/**  this method is to handle the token expiry case of redtail and chime.
 * since this.props is not accessible from here,
 * so we call a callback function on sucess of the api. */
const deleteIntegrationHandle = (userId, url, onCrmTokenExipry) => {
  if (document.getElementById('progress') !== null)
    document.getElementById('progress').style.display = 'block';
  requestWithAuth(url, 'DELETE', null, null, null)
    .then((response) => {
      if (response && response.code === 200) {
        const params = {
          crm: '',
        };

        updateUserInfo(userId, params)
          .then(() => {
            if (document.getElementById('progress') !== null)
              document.getElementById('progress').style.display = 'none';

            if (onCrmTokenExipry) onCrmTokenExipry(url);
          })
          .catch((error) => {
            if (document.getElementById('progress') !== null)
              document.getElementById('progress').style.display = 'none';
          });
      }
    })
    .catch(() => {
      if (document.getElementById('progress') !== null)
        document.getElementById('progress').style.display = 'none';
    });
};

// any new crm is added, for calling the sync api add in this method.
export const handleCrmSync = async (
  userId,
  crmValue,
  body,
  onCrmTokenExipry,
  progressBar,
  tempFilters,
  params,
) => {
  const hostArray = window.location.host.split('.');

  switch (crmValue) {
    case 'sf':
      {
        const responseSf = await requestWithAuth(
          'salesforce-sync',
          'POST',
          params,
          body,
          null,
          progressBar,
        );

        if (responseSf !== undefined && responseSf !== null) {
          if (responseSf.code === 200) {
            handleSuccess(progressBar);
          } else if (responseSf.errorCode) {
            handleError(responseSf.errorCode, progressBar);
          } else {
            setFilters(tempFilters, crmValue);
            if (hostArray && hostArray.length > 0 && hostArray[0]) {
              setCookie('slug', hostArray[0], 1);
            }
            const myWindow = window.open('myWindow', '_self');
            myWindow.document.write(responseSf);
          }
        }
      }
      break;

    case 'ct':
      {
        const responseCt = await requestWithAuth(
          'contactually-sync',
          'POST',
          params,
          body,
          null,
          progressBar,
        );

        if (responseCt) {
          if (responseCt.code === 200) {
            handleSuccess(progressBar);
          } else if (responseCt.code === 302) {
            setFilters(tempFilters, crmValue);
            const clientId = responseCt.client_id;
            // let slug = 'app';
            // const hostArray = window.location.host.split('.');
            // if (hostArray && hostArray.length > 0 && hostArray[0]) {
            //   slug = hostArray[0];
            // }
            if (hostArray && hostArray.length > 0 && hostArray[0]) {
              setCookie('slug', hostArray[0], 1);
            }
            window.open(
              `https://www.contactually.com/oauth2/authorize?client_id=${clientId}&redirect_uri=https%3A%2F%2Fapp.aidentified.com%2Fcrm%2Fcallback&response_type=code&scope=all%3Amanage`,
              '_self',
            );
          } else if (responseCt.errorCode) {
            handleError(responseCt.errorCode, progressBar);
          }
        }
      }
      break;

    case 'cm':
      {
        const responseCm = await requestWithAuth(
          'chime-sync',
          'POST',
          params,
          body,
          null,
          progressBar,
        );

        if (responseCm) {
          if (responseCm.code === 200) {
            handleSuccess(progressBar);
          } else if (responseCm.code === 302) {
            deleteIntegrationHandle(userId, 'chime-token/0', onCrmTokenExipry);
            setFilters(tempFilters, crmValue);
          } else if (responseCm.errorCode) {
            handleError(responseCm.errorCode, progressBar);
          }
        }
      }
      break;

    case 'rt':
      {
        const responseRt = await requestWithAuth(
          'redtail-sync',
          'POST',
          params,
          body,
          null,
          progressBar,
        );

        if (responseRt) {
          if (responseRt.code === 200) {
            handleSuccess(progressBar);
          } else if (responseRt.code === 302) {
            setFilters(tempFilters, crmValue);
            deleteIntegrationHandle(userId, 'redtail-auth/0', onCrmTokenExipry);
          } else if (responseRt.errorCode) {
            handleError(responseRt.errorCode, progressBar);
          }
        }
      }
      break;

    case 'pd':
      {
        const responsePd = await requestWithAuth(
          'pipedrive-sync',
          'POST',
          params,
          body,
          null,
          progressBar,
        );

        if (responsePd) {
          if (responsePd.code === 200) {
            handleSuccess(progressBar);
          } else if (responsePd.code === 302) {
            setFilters(tempFilters, crmValue);
            const clientId1 = responsePd.client_id;
            // let slug_1 = 'app';
            // const hostArray_1 = window.location.host.split('.');
            // if (hostArray_1 && hostArray_1.length > 0 && hostArray_1[0]) {
            //   slug_1 = hostArray_1[0];
            // }
            if (hostArray && hostArray.length > 0 && hostArray[0]) {
              setCookie('slug', hostArray[0], 1);
            }
            window.open(
              `https://oauth.pipedrive.com/oauth/authorize?client_id=${clientId1}&redirect_uri=https://app.aidentified.com/crm/callback`,
              '_self',
            );
          } else if (responsePd.errorCode) {
            handleError(responsePd.errorCode, progressBar);
          }
        }
      }
      break;

    case 'hs':
      {
        const responseHs = await requestWithAuth(
          'hubspot-sync',
          'POST',
          params,
          body,
          null,
          progressBar,
        );
        if (responseHs) {
          if (responseHs.code === 200) {
            handleSuccess(progressBar);
          } else if (responseHs.code === 302) {
            setFilters(tempFilters, crmValue);
            if (responseHs.authorization_url) {
              if (hostArray && hostArray.length > 0 && hostArray[0]) {
                setCookie('slug', hostArray[0], 1);
              }
              window.open(responseHs.authorization_url, '_self');
            }
          } else if (responseHs.errorCode) {
            handleError(responseHs.errorCode, progressBar);
          }
        }
      }
      break;
    default:
      break;
  }
};

// this method is to display names on hover of the icons
export const handleCrmLabels = (crmValue) => {
  switch (crmValue) {
    case 'sf':
      return 'Add To Salesforce';
    case 'cm':
      return 'Add To Lofty';
    case 'ct':
      return 'Add To Contactually';
    case 'rt':
      return 'Add To Redtail';
    case 'pd':
      return 'Add To Pipedrive';
    case 'hs':
      return 'Add To HubSpot';
    default:
      return 'Integrate with CRM';
  }
};

// this method is to display the icons accordingly after integration
export const handleCrmIconsAfterIntegration = (crmValue) => {
  switch (crmValue) {
    case 'sf':
      return iconSalesforce;
    case 'cm':
      return iconLofty;
    case 'ct':
      return iconContactually;
    case 'rt':
      return iconRedtail;
    case 'pd':
      return iconPipedrive;
    case 'hs':
      return iconHubSpot;
    default:
      // return iconCrm;
      return IntegrateCrm;
  }
};

export const getCrmNames = (crmValue) => {
  switch (crmValue) {
    case 'sf':
      return 'Salesforce';
    case 'cm':
      return 'Lofty';
    case 'ct':
      return 'Contactually';
    case 'rt':
      return 'Redtail';
    case 'pd':
      return 'Pipedrive';
    case 'hs':
      return 'HubSpot';
  }
};
