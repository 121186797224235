import './index.scss';

import React, { useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { Table, message } from 'antd';
import type { TableProps, ColumnsType } from 'antd/es/table';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';

import {
  SortInfoInterface,
  PaginationInterface,
  TableParamsInterface,
} from '../../types/GlobalTypes';
import { requestWithAuth } from '../../services/api';
import LoaderAnimation from '../../components/AidLoader';
import AiTable from '../../components/AiTable';
import { toTitleCase } from '../../utils/general';

message.config({
  top: 80,
});

const sortInfo: SortInfoInterface = {
  order: '',
};

let source: CancelTokenSource;
interface CreditLogDataInterface {
  id: number;
  created: string;
  file_name: string;
  contacts_count: number;
  credits_charged: number;
}

interface CreditLogResponse {
  count?: number;
  results?: CreditLogDataInterface[];
}

const CreditUsage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [creditLog, setCreditLog] = useState<CreditLogDataInterface[]>([]);
  const [listPagination, setListPagination] = useState<PaginationInterface>({
    total: 0,
    pageSize: 20,
    current: 1,
  });
  const flags = useFlags();

  useEffect(() => {
    if (listPagination.total === 0) return;
    fetchCreditData();
  }, [listPagination.pageSize, listPagination.current]);

  useEffect(() => {
    fetchCreditData();
  }, []);

  const fetchCreditData = () => {
    const { CancelToken } = axios;
    source = CancelToken.source();
    setIsLoading(true);
    const params: TableParamsInterface = {
      page_size: listPagination.pageSize,
      page: listPagination.current,
    };
    if (sortInfo.order) {
      params.ordering = sortInfo.order;
    }
    requestWithAuth('credits-log', 'GET', params, null, source.token).then(
      (response: CreditLogResponse) => {
        if (!response) {
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
        setListPagination((prevState) => ({
          ...prevState,
          total: response.count > 1000 ? 1000 : response.count,
        }));
        setCreditLog(response.results);
      },
    );
  };

  const handleTableChange: TableProps<CreditLogDataInterface>['onChange'] = (
    pagination,
    filters,
    sorter,
  ) => {
    if (sorter && sorter?.['order'] && sorter?.['field']) {
      if (sorter?.['order'] === 'ascend') sortInfo.order = sorter?.['field'];
      else sortInfo.order = '-' + sorter?.['field'];
    } else {
      sortInfo.order = '';
    }

    if (
      pagination.pageSize === listPagination.pageSize &&
      pagination.current === listPagination.current
    ) {
      fetchCreditData();
    }
    setListPagination({
      total: pagination.total,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const paginationProps = {
    ...listPagination,
  };

  const columns: ColumnsType<CreditLogDataInterface> = [
    {
      title: 'Date',
      dataIndex: 'created',
      key: 'created',
      width: 120,
      render: (val: string) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
            }}
          >
            <div>
              {flags && flags.dntfd8933NewProfileView
                ? moment.utc(val).format('MM/DD/YYYY HH:mm')
                : moment(val).format('MM/DD/YYYY')}
            </div>
          </div>
        );
      },
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title:
        flags && flags.dntfd8933NewProfileView ? 'File Name' : 'Name of File',
      dataIndex: 'file_name',
      width: 90,
      render: (val: string) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
            }}
          >
            {val ? toTitleCase(val.toString()) : ''}
          </div>
        );
      },
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title:
        flags && flags.dntfd8933NewProfileView
          ? 'Number of Profiles'
          : 'No of Profiles',
      dataIndex: 'contacts_count',
      width: 90,
      render: (val: string) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
            }}
          >
            {val ? toTitleCase(val.toString()) : ''}
          </div>
        );
      },
    },
    {
      title:
        flags && flags.dntfd8933NewProfileView ? 'Credit Used' : 'Credits Used',
      dataIndex: 'credits_charged',
      width: 90,
      render: (val: string) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
            }}
          >
            {val ? toTitleCase(val.toString()) : ''}
          </div>
        );
      },
    },
  ];

  return (
    <div className="loader-wrapper">
      {isLoading && <LoaderAnimation loaderId="credit-usage-loader" />}
      <div
        className={
          isLoading
            ? 'credit-usage aid-div-disable'
            : 'credit-usage aid-div-enable'
        }
      >
        {flags && flags.dntfd8933NewProfileView ? (
          <div className="new-credit-table">
            <div className="credit-history-heading">Credit History</div>
            <AiTable
              className="credit-table"
              rowKey={(rec) => `${rec.id}`}
              data={creditLog}
              pagination={paginationProps}
              onChange={() => handleTableChange}
              columns={columns}
              scroll={{ x: 'max-content', y: 400 }}
            />
          </div>
        ) : (
          <Table
            className="credit-table"
            columns={columns}
            dataSource={creditLog}
            rowKey={(rec) => `${rec.id}`}
            showSorterTooltip={false}
            pagination={{
              ...paginationProps,
              pageSize: listPagination.pageSize,
            }}
            onChange={handleTableChange}
            scroll={{ x: 'max-content', y: 600 }}
          />
        )}
      </div>
    </div>
  );
};

export default CreditUsage;
