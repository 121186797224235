import './index.scss';

import { Card } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  formatValue,
  formatEventName,
  formatEventSource,
  formatEventDescription,
  formatInvestor,
} from '../../utils/general';
import CardsAndTable from '../CardsAndTable';

const googleSearchIconClick = (name, company) => {
  const searchValue = `${name} , ${company}`;
  window.open(`https://www.google.com/search?q=${searchValue}`);
};

const CompanyEventsCard = (listItem) => {
  const item = listItem;
  const title = (
    <>
      {item.event_type && formatEventName(item.event_type)}
      {item.event_type && !item.event_type.includes('insider') && (
        <SearchOutlined className="search-icon-card" />
      )}
    </>
  );
  return (
    <Card
      style={{
        height: '179px',
        //   overflowY: 'auto',
        width: '387px',
        position: 'relative',
        borderRadius: '6px',
        cursor: 'pointer',
      }}
      size="small"
      title={title}
      onClick={() => {
        if (item.event_type && !item.event_type.includes('insider')) {
          if (
            item.event_type === 'investment received' &&
            item.event_filing_url
          ) {
            window.open(item.event_filing_url);
          } else {
            googleSearchIconClick(item.event_org_name, item.event_type);
          }
        }
      }}
    >
      <div className="description-text">
        {item.event_description && (
          <>
            {formatEventDescription(item.event_description, item.event_type)}
            {item.event_source &&
              ` (Source: ${formatEventSource(item.event_source)})`}
          </>
        )}
        <div>
          {item.event_funding_round && (
            <>
              {' '}
              <span style={{ color: 'var(--color-grey-dark)' }}>
                Investment Round{': '}
              </span>
              {item.event_funding_round}
            </>
          )}
        </div>
        <div>
          {item.event_investor_name && (
            <>
              {' '}
              <span style={{ color: 'var(--color-grey-dark)' }}>
                Investors{': '}
              </span>
              {formatInvestor(item.event_investor_name)}
            </>
          )}
        </div>
      </div>

      <div className="card-bottom">
        {item.event_monetary_value !== undefined &&
          item.event_monetary_value !== null && (
            <div
              style={{
                fontSize: '21px',
                color: 'var(--color-primary)',
                paddingRight: '9px',
              }}
            >
              {formatValue(item.event_monetary_value)}
            </div>
          )}
        {item.event_date && (
          <div style={{ color: 'var(--color-column)', paddingTop: '8px' }}>
            {moment(item.event_date).format('MM/DD/YYYY')}
          </div>
        )}
      </div>
    </Card>
  );
};

const CompanyEvents = (props) => {
  const { list } = props;
  const rowKeyArray = [];
  const columns = [
    {
      title: 'Trigger',
      dataIndex: 'event_type',
      key: 'event_type',
      width: 140,
      render: (val, rec) => {
        /* Setting id as unique key array */
        if (val === undefined || val === null) return '';
        rowKeyArray.push(rec.event_id);
        const value = formatEventName(val);
        return (
          <div
            className="event-type-header"
            onClick={() => {
              if (val && !val.includes('insider')) {
                if (rec.event_filing_url && val === 'investment received') {
                  window.open(rec.event_filing_url);
                } else {
                  googleSearchIconClick(rec.event_org_name, val);
                }
              }
            }}
          >
            {value}

            {val && !val.includes('insider') && (
              <SearchOutlined className="search-icon" />
            )}
          </div>
        );
      },
      filters: props.eventFilters, // exclude All from eventList
      filterMultiple: true,
      onFilter: (value, record) => record.event_type === value,
      sorter: (a, b) => {
        a = a.event_type || '';
        b = b.event_type || '';
        return a.localeCompare(b);
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Source',
      width: 120,
      dataIndex: 'event_source',
      // className: 'column-right',
      // key: 'event_description',
      render: (val, rec) => {
        if (val === undefined) return '';
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              color: 'var(--color-profile-labels)',
              paddingLeft: '16px',
            }}
          >
            {formatEventSource(val)}
          </div>
        );
      },
    },
    {
      title: 'Investment Round',
      width: 150,
      dataIndex: 'event_funding_round',
      // className: 'column-right',
      // key: 'event_description',
      render: (val, rec) => {
        if (!val) return '';
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              color: 'var(--color-profile-labels)',
              paddingLeft: '16px',
            }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'Value',
      width: 150,
      dataIndex: 'event_monetary_value',
      className: 'column-right',
      key: 'event_monetary_value',
      sorter: (a, b) => a.event_monetary_value - b.event_monetary_value,
      sortDirections: ['descend', 'ascend'],
      render: (val) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              color: 'var(--color-primary)',
              fontWeight: '600',
            }}
          >
            {val === null ? '' : formatValue(val)}
          </div>
        );
      },
    },
    {
      title: 'Date',
      width: 110,
      dataIndex: 'event_date',
      key: 'event_date',
      render: (val) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              color: 'var(--color-primary)',
              fontWeight: '600',
            }}
          >
            {moment(val).format('MM/DD/YYYY')}
          </div>
        );
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        a = a.event_date || '';
        b = b.event_date || '';
        return a.localeCompare(b);
      },
      sortDirections: ['ascend', 'descend'],
    },
  ];
  const cardColumns = [
    {
      title: 'Sort by:  Trigger',
      dataIndex: 'event_type',
      key: 'event_type',
      width: 190,
      render: (val, rec) => {
        /* Setting id as unique key array */
        if (val === undefined || val === null) return '';
        rowKeyArray.push(rec.event_id);
        const value = formatEventName(val);
        return <div className="event-type-header">{value}</div>;
      },
      filters: props.eventFilters, // exclude All from eventList
      filterMultiple: true,
      onFilter: (value, record) => record.event_type === value,
      sorter: (a, b) => {
        a = a.event_type || '';
        b = b.event_type || '';
        return a.localeCompare(b);
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Value',
      dataIndex: 'event_monetary_value',
      className: 'column-right',
      width: 100,
      key: 'event_monetary_value',
      sorter: (a, b) => a.event_monetary_value - b.event_monetary_value,
      sortDirections: ['descend', 'ascend'],
      render: (val) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              color: 'var(--color-primary)',
              fontWeight: '600',
            }}
          >
            {val === null ? '' : formatValue(val)}
          </div>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'event_date',
      key: 'event_date',
      width: 120,
      render: (val) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              color: 'var(--color-primary)',
              fontWeight: '600',
            }}
          >
            {moment(val).format('MM/DD/YYYY')}
          </div>
        );
      },
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        a = a.event_date || '';
        b = b.event_date || '';
        return a.localeCompare(b);
      },
      sortDirections: ['ascend', 'descend'],
    },
    { title: '', key: 'dummy' },
  ];

  return (
    <CardsAndTable
      columns={columns}
      cardColumns={cardColumns}
      list={list}
      cardFunction={CompanyEventsCard}
      title="Company Triggers"
    />
  );
};

export default CompanyEvents;
