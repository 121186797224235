import './index.scss';

import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Divider, Radio } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import LoaderIcon from '../../assets/icons/ai_ic_logo_gold.svg?react';

import CsvIcon from '../../assets/icons/ai_file_csv.svg';

import ChartBox from './ChartBox';

const ClientInvolvement = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const navigate = useNavigate();
    const [radioValue, setRadioValue] = useState('file_imports');
    const [data, setData] = useState({});
    const borderRef = useRef(null);
    const {
      isLoading,
      clientInvData,
      csvBoxes,
      onRadioChange,
      onGraphClick,
      showCompanyProfile,
    } = props;

    const getCsvBoxes = (fileImports) => {
      const fileImportsData = [];
      Object.entries(fileImports).map(([key, value]) => {
        if (value && value.enriched_contacts !== 0) {
          fileImportsData.push({
            doc_count: value.total_imports,
            key,
            unique_count: value.enriched_contacts,
          });
        }
      });
      return {
        results: fileImportsData,
        count: fileImportsData.length,
      };
    };

    useEffect(() => {
      onRadioChange(radioValue);
      if (borderRef && borderRef.current) {
        borderRef.current.style.left = '0px';
      }
    }, []);

    useEffect(() => {
      if (radioValue === 'file_imports') setData({ ...getCsvBoxes(csvBoxes) });
      else setData({ ...clientInvData });
    }, [clientInvData, csvBoxes]);

    const onRadioButtonChange = (e) => {
      setData({});
      onRadioChange(e.target.value);
      setRadioValue(e.target.value);
      if (borderRef && borderRef.current) {
        /** bottom border of checked radio button is showing use a div and its postion is mannually adjusted on radiobutton change */
        borderRef.current.style.left =
          e.target.value === 'file_imports'
            ? '0px'
            : e.target.value === 'current_experience.ai_org_id'
              ? '60px'
              : '135px';
      }
    };

    return (
      <>
        <div className="loader-wrapper">
          {isLoading && (
            <LoaderIcon className="aid-sidecar-ext-logo-spin-icon-only" />
          )}
          <div
            className={
              isLoading
                ? 'dashboard-item client-involvement aid-div-disable'
                : 'dashboard-item client-involvement aid-div-enable'
            }
          >
            <div className="client-involvement-header">
              <div style={{ display: 'flex' }}>
                <div className="dashboard-item-title">CSV Import</div>
                <div className="client-involvement-radio">
                  <Radio.Group
                    onChange={onRadioButtonChange}
                    defaultValue="file_imports"
                  >
                    <Radio.Button className="radio-style" value="file_imports">
                      File
                    </Radio.Button>
                    <Radio.Button
                      className="radio-style"
                      value="current_experience.ai_org_id"
                    >
                      Company
                    </Radio.Button>
                    <Radio.Button
                      className="radio-style"
                      value="latest_mim_event_type"
                    >
                      Trigger
                    </Radio.Button>
                  </Radio.Group>
                  <div className="bottom-line" ref={borderRef} />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <CloseCircleFilled
                  onClick={props.onCloseButtonClick}
                  style={{ color: 'var(--color-secondary)' }}
                />
              </div>
            </div>
            <Divider className="dash-board-divider" />
            <div className="client-involvement-box">
              {!isLoading &&
              props.dataStore.lookUpLists.fileImports &&
              props.dataStore.lookUpLists.fileImports.length === 0 ? (
                <div
                  className="dashboard-item-empty no-csv"
                  onClick={() => {
                    navigate('/connect/import', {
                      state: {
                        fromSource: 'csv',
                      },
                    });
                  }}
                >
                  <img className="csv-icon" src={CsvIcon} alt="csv" />
                  <span style={{ paddingLeft: '3px' }}>
                    Import CSV to view results
                  </span>
                </div>
              ) : data && data.results && data.results.length > 0 ? (
                <div className="client-involvement-content">
                  <ChartBox
                    id={
                      radioValue === 'latest_mim_event_type'
                        ? 'client_inv_trigger'
                        : radioValue === 'file_imports'
                          ? 'client_inv_files'
                          : 'client_inv_org_id'
                    }
                    type="horizontal"
                    chartData={data ? data.results : null}
                    totalCount={data ? data.count : 0}
                    onClick={onGraphClick}
                    showCompanyProfile={showCompanyProfile}
                  />
                </div>
              ) : (
                <div className="dashboard-item-empty">
                  {isLoading ? '' : 'No Data'}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }),
);

export default ClientInvolvement;
