import './index.scss';

import { message, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useState, useEffect, useRef } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { inject, observer } from 'mobx-react';
import namecase from 'namecase';

import ConnectivityIcon from '../../assets/icons/ai_meter_signal.svg?react';

import {
  handleCrmIconsAfterIntegration,
  handleCrmLabels,
} from '../../utils/crmMethods';
import { wealthScoreRanges, salaryFilter } from '../../utils/constants';
import Tags from '../Tags';
import ExportTable from '../ExportTable';
import RowExtra from '../RowExtra';
import LoaderAnimation from '../AidLoader';
import MappedConnectionsChart from './MappedConnectionsChart';

message.config({
  top: 80,
});
const sortInfo = {
  column: 'link',
  order: 'asc',
};

const MappedConnection = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const {
      mappedConnectionResults,
      showCompanyProfile,
      name,
      handleViewAllClick,
      b2bId,
      b2cId,
      mappedLoading,
      setProgress,
    } = props;
    const [crmCredentials, setCrmCredentials] = useState(null);
    const [isRowCrmValue, setisRowCrmValue] = useState('');
    const [mappedConnectionData, setMappedConnectionData] = useState([]);
    const [mappedCount, setMappedCount] = useState(0);
    const [isDeleteClick, setIsDeleteClick] = useState(false);
    const [relLinks, setRelLinks] = useState([]);
    const [selectedProfileIds, setSelectedProfileIds] = useState([]);
    const [exportFromRow, setExportFromRow] = useState(null);

    const searchInputRef = useRef(null);
    const flags = useFlags();

    const { trackModal, profileModal } = props.modalDataStore;
    let params = {
      sort_key: 'relationship_status',
      sort_order: 'asc',
      page_size: 20,
      page: 1,
    };

    useEffect(() => {
      /** Export all from mapped relationship in profile need mapped b2b and b2c ids
       * it is added at this useeffect @profileModal.mappedRelationshipsFilter
       */
      profileModal.mappedRelationshipsFilter = {};
      if (trackModal && trackModal.length > 0) {
        const currentModal = trackModal[trackModal.length - 1];
        /** It is the case of back to profile when multiple profiles are opened */
        if (
          currentModal &&
          currentModal.data &&
          currentModal.data.mappedRelationshipsFilter
        ) {
          profileModal.mappedRelationshipsFilter = {
            ...currentModal.data.mappedRelationshipsFilter,
          };
        }
      }
      profileModal.mappedRelationshipsFilter.filterName = `${name}'s Mapped Connections`;
      profileModal.mappedRelationshipsFilter.mappedSourceB2bId = b2bId;
      profileModal.mappedRelationshipsFilter.mappedSourceB2cId = b2cId;
      params = {
        sort_key: 'relationship_status',
        sort_order: 'asc',
        page_size: 20,
        page: 1,
      };
      return () => {
        params = {};
        profileModal.mappedRelationshipsFilter.filterName = '';
        profileModal.mappedRelationshipsFilter.mappedSourceB2bId = '';
        profileModal.mappedRelationshipsFilter.mappedSourceB2cId = '';
        profileModal.mappedRelationshipsFilter.mappedSourceInferredSalary = [];
        profileModal.mappedRelationshipsFilter.mappedSourceWealthEstimate = [];
        profileModal.mappedRelationshipsFilter.mappedRelationshipWeights = [];
        profileModal.mappedRelationshipsFilter.mappedRelationshipTypes = [];
      };
    }, [b2bId, b2cId, name]);

    useEffect(() => {
      setMappedConnectionData(mappedConnectionResults.results);
      setMappedCount(mappedConnectionResults.count);
      if (mappedConnectionResults?.all_links?.length > 0)
        setRelLinks(
          mappedConnectionResults.all_links.reduce((acc, curr) => {
            if (curr)
              return [
                ...acc,
                { text: curr.toString().toTitleCase(), value: curr },
              ];
          }, []),
        );
    }, [mappedConnectionResults]);

    useEffect(() => {
      setisRowCrmValue(props.dataStore.currentUser.crm);
    }, [props.dataStore.currentUser.crm]);

    const handleExportCsvClick = (recordb2b, recordb2c) => {
      if (!isRowCrmValue) {
        props.navigate('/connect/export');
        return;
      }

      setCrmCredentials({
        id1: recordb2b || '',
        id2: recordb2c || '',
      });
    };

    const searchKey = {
      title: 'mappedSourceTitle',
      org_name: 'mappedSourceOrgname',
      home_city: 'mappedSourceLocationCity',
      home_state: 'mappedSourceLocationState',
    };
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInputRef}
            placeholder={`Search ${dataIndex}`}
            value={
              // eslint-disable-next-line
              selectedKeys[0]
                ? selectedKeys[0]
                : profileModal.mappedRelationshipsFilter[
                    searchKey[dataIndex]
                  ] || ''
            }
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              // this.setSearchValuesInState(e.target.value, dataIndex);
            }}
            onPressEnter={() =>
              handleSearch(selectedKeys, confirm, dataIndex, close)
            }
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys, confirm, dataIndex, close)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm, dataIndex, close)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? 'var(--color-primary)' : undefined,
          }}
        />
      ),

      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInputRef.current.select());
        }
      },
    });

    const handleSearch = (selectedKeys, confirm, dataIndex, close) => {
      const searchData = selectedKeys[0];
      if (searchData !== undefined)
        profileModal.mappedRelationshipsFilter[searchKey[dataIndex]] =
          searchData;
      // switch (dataIndex) {
      //   case 'title':
      //     profileModal.mappedRelationshipsFilter.mappedSourceTitle = searchData;
      //     break;
      //   case 'org_name':
      //     profileModal.mappedRelationshipsFilter.mappedSourceOrgname = searchData;
      //     break;
      //   case 'home_city':
      //     profileModal.mappedRelationshipsFilter.mappedSourceLocationCity = searchData;
      //     break;
      //   case 'home_state':
      //     profileModal.mappedRelationshipsFilter.mappedSourceLocationState = searchData.toUpperCase();
      //     break;
      //   default:
      //     break;
      // }
      close();
      props.onTableChange(b2bId, b2cId, params);
    };

    const handleReset = (clearFilters, confirm, dataIndex, close) => {
      clearFilters();
      switch (dataIndex) {
        case 'title':
          profileModal.mappedRelationshipsFilter.mappedSourceTitle = '';
          break;
        case 'org_name':
          profileModal.mappedRelationshipsFilter.mappedSourceOrgname = '';
          break;
        case 'home_city':
          profileModal.mappedRelationshipsFilter.mappedSourceLocationCity = '';
          break;
        case 'home_state':
          profileModal.mappedRelationshipsFilter.mappedSourceLocationState = '';
          break;
        default:
          break;
      }
      close();
      props.onTableChange(b2bId, b2cId, params);
    };

    const columns = [
      { title: '', dataIndex: 'dummy', width: 10 },
      {
        title: 'Name',
        dataIndex: 'full_name',
        height: 0,
        width: 190,
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        render: (val, rec, index) => {
          return (
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                className="primary-bold-text"
                style={{
                  width: '85%',
                  wordBreak: 'break-word',
                  color: 'var(--color-black)',
                }}
              >
                {val ? namecase(val) : ''}
              </div>
              <RowExtra
                rec={rec}
                handleExportCsvClick={handleExportCsvClick}
                selectedProfileIds={selectedProfileIds}
                handleExportFromRowClick={(n2Ids) => setExportFromRow(n2Ids)}
              />
            </div>
          );
        },
      },
      {
        title: 'Relationship Strength',
        dataIndex: 'relationship_status',
        width: 150,
        height: 0,
        defaultSortOrder: 'ascend',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        filters: [
          {
            text: 'Low',
            value: 'low',
          },
          {
            text: 'Med',
            value: 'medium',
          },
          {
            text: 'High',
            value: 'high',
          },
        ],
        filteredValue:
          profileModal &&
          profileModal.mappedRelationshipsFilter &&
          profileModal.mappedRelationshipsFilter.mappedRelationshipWeights &&
          profileModal.mappedRelationshipsFilter.mappedRelationshipWeights
            .length > 0
            ? profileModal.mappedRelationshipsFilter.mappedRelationshipWeights
            : null,
        render: (val, rec) => {
          return val ? (
            <div style={{ textAlign: 'center' }}>
              <ConnectivityIcon
                className={
                  val === 'high'
                    ? 'connectivity-icon-high'
                    : val === 'medium'
                      ? 'connectivity-icon-medium'
                      : 'connectivity-icon-low'
                }
              />
            </div>
          ) : (
            <></>
          );
        },
      },
      {
        title: 'Relationship Path',
        dataIndex: 'link',
        width: 250,
        height: 0,
        defaultSortOrder: 'ascend',
        filters: relLinks,
        filteredValue:
          profileModal &&
          profileModal.mappedRelationshipsFilter &&
          profileModal.mappedRelationshipsFilter.mappedRelationshipTypes &&
          profileModal.mappedRelationshipsFilter.mappedRelationshipTypes
            .length > 0
            ? profileModal.mappedRelationshipsFilter.mappedRelationshipTypes
            : null,
        render: (val, rec) => {
          return (
            <div>
              {val.map((val) => {
                return <div>{val ? val.toString().toTitleCase() : ''}</div>;
              })}
            </div>
          );
        },
      },
      {
        title: 'Title',
        dataIndex: 'title',
        width: 150,
        height: 0,
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        ...getColumnSearchProps('title'),
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-column)',
              }}
            >
              {val ? val.toString().toTitleCase() : ''}
            </div>
          );
        },
      },
      {
        title: 'Company',
        dataIndex: 'org_name',
        width: 200,
        height: 0,
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        ...getColumnSearchProps('org_name'),
        render: (val, rec) => {
          return (
            <div
              className={
                rec.ai_org_id ? 'company-link' : 'company-link-disabled'
              }
            >
              <span
                onClick={(e) => {
                  if (!rec || !rec.ai_org_id) return;
                  e.stopPropagation();
                  showCompanyProfile(rec.ai_org_id, val);
                }}
              >
                {val ? val.toString().toTitleCase() : ''}
              </span>
            </div>
          );
        },
      },
      {
        title: 'Inferred Income',
        dataIndex: 'inferred_salary_range',
        width: 135,
        height: 0,
        // sorter: true,
        // sortDirections: ['ascend', 'descend'],
        filters: salaryFilter,
        filteredValue:
          profileModal &&
          profileModal.mappedRelationshipsFilter &&
          profileModal.mappedRelationshipsFilter.mappedSourceInferredSalary &&
          profileModal.mappedRelationshipsFilter.mappedSourceInferredSalary
            .length > 0
            ? profileModal.mappedRelationshipsFilter.mappedSourceInferredSalary
            : null,
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-mapped-columns)',
              }}
            >
              {val ? val : ''}
            </div>
          );
        },
      },
      {
        title: 'Wealth Segment',
        dataIndex: 'wealth_estimate',
        width: 150,
        height: 0,
        // sorter: true,
        // sortDirections: ['ascend', 'descend'],
        filters: wealthScoreRanges,
        filteredValue:
          profileModal &&
          profileModal.mappedRelationshipsFilter &&
          profileModal.mappedRelationshipsFilter.mappedSourceWealthEstimate &&
          profileModal.mappedRelationshipsFilter.mappedSourceWealthEstimate
            .length > 0
            ? profileModal.mappedRelationshipsFilter.mappedSourceWealthEstimate
            : null,
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-mapped-columns)',
              }}
            >
              {val ? val : ''}
            </div>
          );
        },
      },
      {
        title: 'City',
        dataIndex: 'home_city',
        width: 150,
        height: 0,
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        ...getColumnSearchProps('home_city'),
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-mapped-columns)',
              }}
            >
              {val ? val.toString().toLowerCase().toTitleCase() : ''}
            </div>
          );
        },
      },
      {
        title: 'State',
        dataIndex: 'home_state',
        width: 80,
        height: 0,
        alignn: 'center',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        ...getColumnSearchProps('home_state'),
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-mapped-columns)',
              }}
            >
              {val}
            </div>
          );
        },
      },
    ];

    const paginationProps = {
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ['20', '50', '100', '200'],
      total: mappedConnectionResults.count,
      pageSize:
        profileModal.mappedRelationshipsFilter &&
        profileModal.mappedRelationshipsFilter.pagination &&
        profileModal.mappedRelationshipsFilter.pagination.pageSize
          ? profileModal.mappedRelationshipsFilter.pagination.pageSize
          : 20,
      current:
        profileModal.mappedRelationshipsFilter &&
        profileModal.mappedRelationshipsFilter.pagination &&
        profileModal.mappedRelationshipsFilter.pagination.current
          ? profileModal.mappedRelationshipsFilter.pagination.current
          : 1,
    };

    const handleTableChange = (pagination, filtersArg, sorter) => {
      Object.keys(filtersArg).reduce((obj, key) => {
        switch (key) {
          case 'inferred_salary_range': {
            profileModal.mappedRelationshipsFilter.mappedSourceInferredSalary =
              filtersArg[key] || [];
            break;
          }
          case 'wealth_estimate': {
            profileModal.mappedRelationshipsFilter.mappedSourceWealthEstimate =
              filtersArg[key] || [];

            break;
          }
          case 'relationship_status': {
            profileModal.mappedRelationshipsFilter.mappedRelationshipWeights =
              filtersArg[key] || [];
            break;
          }
          case 'link': {
            profileModal.mappedRelationshipsFilter.mappedRelationshipTypes =
              filtersArg[key] || [];
            break;
          }

          default:
            break;
        }
      }, {});

      if (sorter && sorter.order) {
        sortInfo.column = sorter.field;
        sortInfo.order = sorter.order === 'ascend' ? 'asc' : 'desc';
      } else {
        sortInfo.column = '';
        sortInfo.order = '';
      }

      if (pagination.pageSize && pagination.current) {
        profileModal.mappedRelationshipsFilter.pagination = {
          pageSize: pagination.pageSize,
          current: pagination.current,
        };
      }
      if (sortInfo.column && sortInfo.order) {
        params.sort_key =
          sortInfo.column === 'link' ? 'relationship_status' : sortInfo.column;
        params.sort_order = sortInfo.order;
      }

      props.onTableChange(b2bId, b2cId, params);
    };

    const handleDeleteData = (b2bId, b2cId, length) => {
      setIsDeleteClick(true);
      const tableDataResult = mappedConnectionData.filter((data) => {
        if (!b2bId.includes(data.b2b_id) && !b2cId.includes(data.b2c_id))
          return data;
      });
      setMappedCount(mappedCount - length);
      setMappedConnectionData(tableDataResult);
    };

    function hasData(key) {
      return (
        profileModal &&
        profileModal.mappedRelationshipsFilter &&
        profileModal.mappedRelationshipsFilter[key] &&
        profileModal.mappedRelationshipsFilter[key].length > 0
      );
    }

    function hasTableChange() {
      return (
        isDeleteClick ||
        hasData('mappedSourceTitle') ||
        hasData('mappedSourceOrgname') ||
        hasData('mappedSourceLocationCity') ||
        hasData('mappedSourceLocationState') ||
        hasData('mappedSourceInferredSalary') ||
        hasData('mappedSourceWealthEstimate') ||
        hasData('mappedRelationshipWeights') ||
        hasData('mappedRelationshipTypes')
      );
    }

    if (
      (!mappedConnectionData || mappedConnectionData.length <= 0) &&
      !hasTableChange()
    )
      return <> </>;

    return (
      <>
        <div className="loader-wrapper">
          {mappedLoading && (
            <LoaderAnimation
              loaderId="mapped-connection-loader"
              size="medium"
            />
          )}
          <div
            className={
              mappedLoading
                ? 'mapped-connection aid-div-disable'
                : 'mapped-connection aid-div-enable'
            }
          >
            {' '}
            <div
              className="profile-box-header"
              style={{ borderBottom: '1px solid var(--color-light)' }}
              onClick={() => handleViewAllClick(name)}
            >
              <span className="profile-titles">
                {name
                  ? `${name}'s Ai Mapped Connections ${
                      mappedCount && mappedCount > 0
                        ? '(' + mappedCount + ')'
                        : ''
                    }`
                  : `AI Mapped Connections ${
                      mappedCount && mappedCount > 0 && '(' + mappedCount + ')'
                    }`}
              </span>
              <Button type="primary" size="small" style={{ fontSize: '12px' }}>
                View in Prospect Finder
              </Button>
            </div>
            <div style={{ paddingTop: 20, paddingBottom: 5 }}>
              <div className="legend">
                <label>Relationship Strength</label>
                <div>
                  <ConnectivityIcon className="connectivity-icon-high" /> High
                </div>
                <div>
                  <ConnectivityIcon className="connectivity-icon-medium" /> Med
                </div>
                <div>
                  <ConnectivityIcon className="connectivity-icon-low" /> Low
                </div>
              </div>
              {/* <hr /> */}
              <ExportTable
                dataCount={mappedCount}
                changeSelectedProfileIds={(ids) => setSelectedProfileIds(ids)}
                columns={columns} //new column relationship weight added
                expandedRowShowing={false}
                pagination={paginationProps}
                pageSize={paginationProps.pageSize}
                onChange={handleTableChange}
                data={mappedConnectionData}
                crmRowCredentials={crmCredentials}
                clearCrmCredentials={() => {
                  setCrmCredentials(null);
                }}
                deleteData={handleDeleteData}
                fromProfile
                setProgress={setProgress}
                rowKey={(rec) => `${rec.b2b_id}${rec.b2c_id}`}
                exportFromRow={exportFromRow}
                clearExport={() => {
                  setExportFromRow(null);
                }}
              />
            </div>
          </div>
        </div>
        <MappedConnectionsChart
          name={name}
          b2bId={b2bId}
          b2cId={b2cId}
          handleClick={handleViewAllClick}
        />
      </>
    );
  }),
);

export default MappedConnection;
