import './index.css';
import '../MultiDataSearch/index.css';

import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import CreatableSelect from 'react-select/creatable';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import axios from 'axios';

import { requestWithAuth } from '../../services/api';

let source;
class CreatableMultiSelect extends Component {
  state = {
    options: [],
  };

  handleInputChange = (value) => {
    const options = [];
    const { id } = this.props;
    if (value.length > 0) {
      if (source) source.cancel('Operation canceled by the user.');
      const { CancelToken } = axios;
      source = CancelToken.source();
      const params = {};
      params.lookup_type = 'n2';
      params.search_key =
        id === 'org_name' || id === 'exc_current_experience'
          ? 'ai_org'
          : id === 'exc_title'
            ? 'title'
            : id === 'exc_home_address_suggest'
              ? 'home_address_suggest'
              : id;
      params.search_value = value;
      if (this.props.dataStore.networkFilters.filter_type) {
        params.filter_type = this.props.dataStore.networkFilters.filter_type;
      }
      requestWithAuth('auto-suggest-new', 'GET', params, null, source.token)
        .then((response) => {
          if (response && response.result) {
            response.result.map((data) => {
              if (data)
                switch (id) {
                  case 'org_name':
                  case 'exc_current_experience':
                    options.push({
                      value: data.ai_org_id,
                      label: data.name
                        ? data.name.toString().toTitleCase()
                        : '',
                      labelValue: data.name,
                    });
                    break;
                  default:
                    options.push({
                      value: data,
                      label: data.toString().toTitleCase(),
                    });
                    break;
                }
              return '';
            });
          }
          this.setState({ options });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  resetMapZoom = () => {
    if (this.props.dataStore.lookUpLists.map) {
      this.props.dataStore.lookUpLists.map.flyTo({
        zoom: 3,
        center: [-90.039891, 42.058919],
        speed: 2,
      });
    }
  };

  handleChange = (selected) => {
    const { id, from, onSelect } = this.props;

    if (
      selected &&
      selected.length > 0 &&
      document.getElementById(`${id}_${from}_search_icon`) !== null
    )
      document.getElementById(`${id}_${from}_search_icon`).style.display =
        'none';
    else if (document.getElementById(`${id}_${from}_search_icon`) !== null)
      document.getElementById(`${id}_${from}_search_icon`).style.display =
        'block';

    switch (from) {
      case 'masterFilter':
        this.props.globalDataStore.masterFiltersOnSelect[id] = selected;
        break;
      case 'network': {
        this.props.dataStore.networkFilters[id] = selected;
        if (
          this.props.dataStore.lookUpLists.map &&
          this.props.dataStore.lookUpLists.map.getZoom() > 3
        )
          this.resetMapZoom();
        else this.props.dataStore.networkFilters.fetchData = true;
        if (onSelect) {
          onSelect(selected);
        }
        break;
      }
      case 'profile': {
        if (onSelect) {
          onSelect(selected, id);
        }
        break;
      }

      default:
        if (onSelect) {
          onSelect(selected);
        }
        break;
    }
  };

  debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this;
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  handleScrollToBottom = () => {
    if (document.getElementById('user-filter-parent'))
      document.getElementById('user-filter-parent').scrollBy({
        top: 250,
        behavior: 'smooth',
      });
  };

  getValue = () => {
    const { id, from } = this.props;
    switch (from) {
      case 'masterFilter':
        return this.props.globalDataStore.masterFiltersOnSelect[id];
      case 'network':
        return this.props.dataStore.networkFilters[id];
      default:
        return this.props.value;
    }
  };

  render() {
    const {
      id,
      isMulti,
      placeholder,
      isClearable = true,
      from,
      showContains = true,
      disabled = false,
    } = this.props;
    const { options } = this.state;
    return (
      <div
        style={{
          marginTop: `${from === 'masterFilter' ? '0px' : '10px'}`,
        }}
        // style={{ marginTop: '10px' }}
      >
        {(from === 'masterFilter' ||
          (this.getValue() && this.getValue().length > 0)) && (
          <div className={'placeholder-active'}>{placeholder}</div>
        )}
        <CreatableSelect
          formatCreateLabel={(inputValue) => {
            if (showContains) return `Contains "${inputValue}"`;
          }}
          isValidNewOption={(inputValue) => {
            if (!showContains) return false;
            else if (inputValue && inputValue !== '') return true;
          }}
          placeholder={placeholder}
          value={this.getValue()}
          isMulti={isMulti}
          className={'multi-auto-suggest multi-auto-suggest-new'}
          classNamePrefix="ai"
          isSearchable
          isClearable={isClearable}
          hideSelectedOptions={id === 'org_name'} // FIX: issue with 2 compnies with same id on selected gets both cleared
          cropWithEllipsis
          closeMenuOnSelect={false}
          closeMenuOnScroll
          //   onMenuScrollToBottom={() => {
          //     this.handleScrollToBottom();
          //   }}
          onFocus={this.inFocus}
          onChange={this.handleChange}
          onInputChange={this.debounce(this.handleInputChange, 500)}
          options={options}
          isDisabled={disabled}
          onBlur={() => {
            this.setState({
              options: [],
            });
          }}
          createOptionPosition="first"
          noOptionsMessage={() => null}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          filterOption={false}
        />
      </div>
    );
  }
}

export default withLDConsumer()(
  inject('dataStore')(
    inject('globalDataStore')(observer(CreatableMultiSelect)),
  ),
);
