import './index.scss';

import { Button, message, Radio } from 'antd';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { CheckCircleFilled } from '@ant-design/icons';

import HandShake from '../../assets/icons/ai_handshake.svg?react';
import Bell from '../../assets/icons/ai_bell.svg?react';

import { attributes, filterLabelNames } from '../../utils/constants';
import {
  formatEventName,
  getFormatedFilterValues,
  formatEventDescription,
  formatInvestor,
} from '../../utils/general';
import Connectivity from './Connectivity';
import ContactAndIntroduction from './ContactsAndIntroduction';
import HeaderSection from './HeaderSection';
import OpportunityInfo from './OpportunityInfo';

message.config({
  top: 80,
});

const connectivityList = (connectivityResponse, mutualContacts) => {
  const list = Object.entries(connectivityResponse).filter(
    (value) =>
      value[0] !== 'connectivity_strength' &&
      value[0] !== 'connectivity_score' &&
      value[0] !== 'wealth_estimate' &&
      value[1] &&
      value[1].length > 0,
  );
  if (mutualContacts && mutualContacts.length > 0) {
    const contactsArray = ['mutual_contacts'];
    contactsArray.push(mutualContacts);
    list.unshift(contactsArray);
  }
  return list;
};

const OpportunityByDate = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const {
      data,
      fetchData,
      onReminderClick,
      showProfile,
      showCompanyProfile,
      addReminder,
      opportunitiesCount,
      toggleProgress,
      user,
      openUserFilter,
    } = props;

    function getDegreeFormats(degree) {
      switch (degree) {
        case 1:
          return {
            class: 'high-indicator',
            label: '1st',
          };
        case 2:
          return {
            class: 'medium-indicator',
            label: '2nd',
          };
        default:
          return {
            class: '',
            label: '',
          };
      }
    }

    function renderItemTopSection(item, index) {
      return (
        <div
          className="top-section"
          style={{
            borderWidth:
              item && item.filter_list && item.filter_list.length > 0
                ? '1px'
                : '0px',
            justifyContent: 'flex-start',
          }}
        >
          {item && item.events.event_date && (
            <div
              style={{
                fontSize: '14px',
                color: 'var(--color-primary)',
              }}
            >
              Trigger Date:{' '}
              {moment(item.events.event_date).format('MM/DD/YYYY')}
            </div>
          )}
        </div>
      );
    }

    function getFilterCriteria(id) {
      const filterMatchingId =
        props.globalDataStore.multipleFilters.filterList.filter((filter) => {
          return filter.value === id;
        });
      if (!filterMatchingId || filterMatchingId.length <= 0) return;
      const { filters, filter_type } = filterMatchingId[0];
      const filterObj =
        filter_type && filter_type !== 'all'
          ? { ...filters, filter_type }
          : { ...filters };

      if (filterObj.org_name || filterObj['current_experience.ai_org_id']) {
        const orgNames = [];
        if (filterObj.org_name) {
          orgNames.push(...filterObj.org_name);
        }
        if (filterObj['current_experience.ai_org_id']) {
          filterObj['current_experience.ai_org_id'].map((org) => {
            orgNames.push(org.name);
          });
        }
        delete filterObj['current_experience.ai_org_id'];
        filterObj.org_name = orgNames;
      }

      if (filterObj.home_postal_code && filterObj.home_postal_code.range) {
        const postalCode = {
          home_postal_code: filterObj.home_postal_code.range.home_postal_code,
          home_zip4:
            filterObj.home_zip4 && filterObj.home_zip4.range
              ? filterObj.home_zip4.range.home_zip4
              : null,
        };
        delete filterObj['home_postal_code'];
        delete filterObj['home_zip4'];
        filterObj.home_postal_code = postalCode;
      }

      if (filterObj === undefined || filterObj === null) return '';

      const filterItems = Object.entries(filterObj).filter((value) => {
        return (
          value[1] &&
          ![
            'team_members',
            'date_filter',
            'latest_mim_date',
            'no_of_years',
            'mapped_source_b2b_id',
            'mapped_source_b2c_id',
            'relmap_org_ui',
          ].includes(value[0])
        );
      });
      const filterValue = [];
      filterItems.map((value) => {
        if (value && value.length > 1 && value[1] !== true) {
          const label = filterLabelNames[value[0]]
            ? `${filterLabelNames[value[0]]}:`
            : '';
          filterValue.push(
            `${label} ${getFormatedFilterValues(
              value,
              props.dataStore.lookUpLists.titleRankList,
              props.dataStore.lookUpLists.fileImports,
              props.dataStore.lookUpLists.teamFileImports,
            )}`,
          );
        }
      });
      if (attributes && attributes.length > 0) {
        attributes.map((attribute) => {
          if (filters[attribute.value]) filterValue.push(attribute.label);
        });
      }
      if (filterObj.all_homes) filterValue.push('All Home Search');
      return filterValue.join('; ');
    }

    function renderProspectProfiles(item, includeCriteria) {
      /** 2 styles of showing prospectProfiles
       * 1-no criterias included => {person} matches your Prospect Profile: {filterName}
       * 2-criterias included => {filterName} : {criterias}
       */
      return (
        <>
          {item.filter_list.map((filter, index) => {
            return (
              <>
                {includeCriteria ? (
                  <div>
                    {index !== 0 && <br />}
                    <div
                      className="link"
                      onClick={() => {
                        openUserFilter(filter.id);
                      }}
                    >
                      {filter.name}:
                    </div>
                    <div>{getFilterCriteria(filter.id)}</div>
                  </div>
                ) : (
                  <span
                    style={{ textDecoration: 'underline' }}
                    className="link"
                    onClick={() => {
                      openUserFilter(filter.id);
                    }}
                  >
                    {index !== 0 ? ', ' : ''}
                    {filter.name}
                  </span>
                )}
              </>
            );
          })}
        </>
      );
    }

    function reminderSection(data, index) {
      return (
        <div
          className="reminder-top-section cursor-pointer"
          onClick={onReminderClick}
        >
          <div className="bell-section">
            <Bell fill="var(--color-white)" />
            <label style={{ paddingLeft: 7 }}>
              Reminder
              <span style={{ color: 'var(--color-header-text)' }}>
                {' '}
                you asked for {data.remind_days}{' '}
                {data.remind_days <= 1 ? 'day' : 'days'} ago
              </span>
            </label>
          </div>
          <div className="main-section">
            <span
              className="full-name cursor-pointer"
              style={{ textDecoration: 'underline' }}
              onClick={(e) => {
                e.stopPropagation();
                showProfile(data.b2b_id, data.b2c_id);
              }}
            >
              {data.full_name}
            </span>
            <div className="degree-section">
              <div className={getDegreeFormats(data.degree).class} />{' '}
              <span>{getDegreeFormats(data.degree).label}</span>
            </div>
            <div className="title-section">
              {data.title ? data.title.toString().toTitleCase() : ''}{' '}
              {data.org_name && (
                <>
                  @&nbsp;
                  <span
                    className={!data || !data.ai_org_id ? '' : 'link'}
                    onClick={() => {
                      if (!data || !data.ai_org_id) return;
                      showCompanyProfile(data.ai_org_id, data.org_name);
                    }}
                  >
                    {data.org_name.toString().toTitleCase()}
                  </span>{' '}
                </>
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div id="card-content" className="opportunity-date-grouping">
        {data.map((item, index) => {
          if (item.remind_days > 0) return reminderSection(item, index);
          return (
            <div className="card-item">
              {renderItemTopSection(item, index)}
              <div className="card-content">
                <div className="card-details-left">
                  <HeaderSection
                    data={item}
                    index={index}
                    getDegreeFormats={getDegreeFormats}
                    user={user}
                    toggleProgress={toggleProgress}
                    showProfile={showProfile}
                    showCompanyProfile={showCompanyProfile}
                  />

                  <div className="card-details-main">
                    {item &&
                    item.events &&
                    Object.keys(item.events).length !== 0 ? (
                      <OpportunityInfo
                        title={formatEventName(item.events.event_type)}
                        date={item.events.event_date}
                        description={
                          !item.events.event_monetary_value &&
                          item.events.event_description
                            ? formatEventDescription(
                                item.events.event_description,
                                item.events.event_type,
                              )
                            : formatEventDescription(
                                item.events.event_description,
                                item.events.event_type,
                              ) || ''
                        }
                        eventSource={
                          item.events.event_type === 'news'
                            ? item.events.event_domain
                            : item.events.event_source
                        }
                        mentions={
                          item.events.event_type === 'news'
                            ? item.events.event_context
                            : ''
                        }
                        articleUrl={
                          item.events.event_type === 'news'
                            ? item.events.event_filing_url
                            : null
                        }
                        investor={
                          item.events.event_investor_name
                            ? formatInvestor(item.events.event_investor_name)
                            : ''
                        }
                      />
                    ) : item.filter_list && item.filter_list.length > 0 ? (
                      <OpportunityInfo
                        title="Prospect Profiles"
                        description={renderProspectProfiles(item, true)}
                      />
                    ) : (
                      <></>
                    )}

                    <div className="card-details-info">
                      {((item.degree === 1 && item.full_name) ||
                        item.mutual_count > 0 ||
                        (item.talking_points &&
                          Object.keys(connectivityList(item.talking_points))
                            .length > 0)) && (
                        <div style={{ fontWeight: 'bold' }}>
                          Reach out today because:
                        </div>
                      )}

                      {item.degree === 1 && item.full_name && (
                        <div>
                          <span style={{ paddingRight: '10px' }}>
                            <CheckCircleFilled
                              style={{
                                color: 'var(--color-golden-end)',
                              }}
                            />
                          </span>
                          You know {item.full_name}
                        </div>
                      )}
                      {((item.talking_points &&
                        Object.keys(connectivityList(item.talking_points))
                          .length > 0) ||
                        item.mutual_count > 0) && (
                        <div className="talking-points">
                          <Connectivity
                            list={connectivityList(
                              item.talking_points,
                              item.mutual_contacts,
                            )}
                            openProfileTab={showProfile}
                          />
                        </div>
                      )}
                    </div>
                    {item &&
                      item.filter_list &&
                      item.filter_list.length > 0 && (
                        <>
                          <div style={{ marginTop: '25px' }}>
                            <span className="filters-list">Filter : </span>
                            {renderProspectProfiles(item, false)}
                          </div>
                        </>
                      )}

                    <div className="flex-align-with-gap reminder-section">
                      <Bell fill="var(--color-primary)" />
                      <span>Remind Me: </span>
                      <Radio.Group
                        onChange={(e) => {
                          addReminder(
                            true,
                            e.target.value,
                            item.b2b_id,
                            item.b2c_id,
                            index,
                          );
                        }}
                        value=""
                        buttonStyle="solid"
                      >
                        <Radio.Button value={1}>
                          <span>Tomorrow</span>
                        </Radio.Button>
                        <Radio.Button value={3}>
                          <span> 3 days</span>
                        </Radio.Button>
                        <Radio.Button value={7}>
                          <span> 1 week</span>
                        </Radio.Button>
                        <Radio.Button value={14}>
                          <span>2 weeks</span>
                        </Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>
                </div>

                {item.mutual_count > 0 && (
                  <div className="card-right-section">
                    <div className="card-right-title">
                      {item.degree === 1 ? (
                        <>Mutual Contacts ({item.mutual_count})</>
                      ) : (
                        <div className="flex-align-with-gap">
                          <HandShake /> {'Ask for an introduction'}(
                          {item.mutual_count})
                        </div>
                      )}
                    </div>
                    <ContactAndIntroduction
                      data={item.mutual_contacts}
                      theme={item.degree === 1 ? 'blue' : 'golden'}
                      opportunity_degree={item.degree}
                      openProfileTab={showProfile}
                      showCompanyProfile={showCompanyProfile}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <div className="show-more-section">
          <Button
            className="show-more-button"
            onClick={() => {
              fetchData(data.length);
            }}
            style={{
              visibility: `${
                data && data.length < opportunitiesCount ? 'visible' : 'hidden'
              }`,
            }}
          >
            Show More
          </Button>
        </div>
      </div>
    );
  }),
);

export default OpportunityByDate;
