import './index.scss';

import { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { FileDrop } from 'react-file-drop';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import Papa from 'papaparse';
import JSZip from 'jszip';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Button,
  message,
  Input,
  Checkbox,
  Select,
  Divider,
  Table,
  Tooltip,
  Collapse,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CsvIcon from '../../assets/icons/ai_file_csv.svg?react';
import FileUploaded from '../../assets/icons/ai_file_uploaded.svg?react';
import InfoIcon from '../../assets/icons/info.svg?react';

import CreatableSelect from '../CreatableSelect';
import DataSelect from '../DropdownMenu';
import { putWithAuth, requestWithAuth } from '../../services/api';
import { showConfirm, showWarning } from '../../utils/popup';
import LoaderAnimation from '../AidLoader';
import MoreInfoSection from './moreInfoSection';

const { Option } = Select;
message.config({
  top: 80,
});

let source;
let csvData = [];
let isParsingCompleted = false;
let isDoneClicked = false;
let outputFile;

const columnNames = [
  'first_name',
  'last_name',
  'email_1',
  'phone_1',
  'school',
  'linkedin_url',
  'twitter_url',
  'facebook_url',
  'home_street_address',
  'home_city',
  'home_state',
  'home_zip',
  'title',
  'company_name',
  'company_url',
  'company_street_address',
  'company_city',
  'company_state',
  'company_zip',
  'neustar_id',
];

const orgOnlyColumns = [
  'organization_name',
  'organization_url',
  'organization_city',
  'organization_state',
  'naics',
];

const CsvImportNew = inject('dataStore')(
  observer((props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    // const [fileList, setfileList] = useState([]);
    const [fileName, setFileName] = useState('');
    const [inputFile, setInputFile] = useState(null);
    const [nameOfImport, setNameOfImport] = useState('');
    const [linkedInCB, setLinkedInCB] = useState(false);
    const [emailCB, setEmailCB] = useState(false);
    const [phoneCB, setPhoneCB] = useState(false);
    const [teamCB, setTeamCB] = useState(false);
    const [firstDegreeCB, setFirstDegreeCB] = useState(false);
    // const [firstDegreeTeam, setFirstDegreeTeam] = useState(false);
    const [schools, setSchools] = useState([]);
    const [emailOnly, setEmailOnly] = useState(false);
    const [isOrgMatch, setIsOrgMatch] = useState(false);
    const [addressOnly, setAddressOnly] = useState(false);
    const [isSuppress, setIsSuppress] = useState(false);
    const [isHouseholdIncluded, setIsHouseholdIncluded] = useState(false);
    const [fileContents, setFileContents] = useState([]);
    const [showMapping, setShowMapping] = useState(false);
    const [mappedHeaders, setMappedHeaders] = useState([]);
    const [tagsApplied, setTagsApplied] = useState([]);
    const [teamsApplied, setTeamsApplied] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isUniAndNameOnly, setIsUniAndNameOnly] = useState(false);
    const [isTagIncludedinTeam, setIsTagIncludedinTeam] = useState(false);
    const [columnHeaderMapping, setColumnHeaderMapping] = useState(
      columnNames.sort(),
    );

    const flags = useFlags();
    const tagRef = useRef(null);

    useEffect(() => {
      const { CancelToken } = axios;
      source = CancelToken.source();
      if (props.dataStore.currentUser.isAdmin) {
        setEmailCB(true);
        if (flags.phoneValidation) setPhoneCB(true);
      } else {
        setEmailCB(false);
        setPhoneCB(false);
      }
      navigate(location.pathname);
      window.addEventListener('popstate', onBackClicked);

      return () => {
        message.destroy();
        if (source) source.cancel('Operation canceled by the user.');
        window.removeEventListener('popstate', onBackClicked);
      };
    }, []);
    useEffect(() => {
      if (
        props.dataStore.currentUser.type === 'lite' &&
        props.dataStore.lookUpLists.team &&
        props.dataStore.lookUpLists.team.length === 1
      )
        setTeamsApplied({
          label: props.dataStore.lookUpLists.team[0].name,
          value: props.dataStore.lookUpLists.team[0].id,
        });
    }, []);

    useEffect(() => {
      setInputFile(props.inputFile);
      setIsFileUploaded(props.inputFile ? true : false);
      setFileName(props.fileName);
    }, [props.inputFile]);

    // useEffect(() => {
    //   if (firstDegreeTeam && (!firstDegreeCB || teamsApplied.length === 0)) {
    //     setFirstDegreeTeam(false);
    //   }
    // }, [firstDegreeCB, teamsApplied]);

    useEffect(() => {
      if (isOrgMatch) {
        setColumnHeaderMapping([...orgOnlyColumns.sort()]);
      } else {
        setColumnHeaderMapping([...columnNames.sort()]);
      }
    }, [isOrgMatch]);

    const getTagsLookup = () => {
      let tagList = [];
      if (props.dataStore.userTags && props.dataStore.userTags.length > 0) {
        tagList = props.dataStore.userTags
          .map(({ id, tag }) => {
            return { label: tag, value: id };
          })
          .filter((e) => e);
      }
      return tagList;
    };

    const getTeamsLookup = () => {
      let teamsList = [];
      if (
        props.dataStore.lookUpLists.team &&
        props.dataStore.lookUpLists.team.length > 0
      ) {
        teamsList = props.dataStore.lookUpLists.team
          .map(({ id, name }) => {
            return { label: name, value: id };
          })
          .filter((e) => e);
      }
      if (teamsList.length > 1)
        teamsList.unshift({ label: 'All', value: 'all' });
      return teamsList;
    };

    const onBackClicked = (e) => {
      e.preventDefault();
      if (isDoneClicked) {
        showConfirm({
          className: 'small-popup',
          // title: 'Confirm Delete',
          closable: true,
          content:
            'Your File is still processing. Leaving the page causes losing your data. Are you sure about leaving this page?',
          okText: 'Ok',
          onOk: () => {
            navigate(-1);
            return false;
          },
          onCancel: () => {
            window.history.pushState(null, null, window.location.pathname);
            return false;
          },
        });
      } else {
        navigate(-1);
      }
    };

    const getMappedLabels = (header) => {
      switch (header.toLowerCase()) {
        case 'first_name':
        case 'first name':
          return 'first_name';
        case 'last_name':
        case 'last name':
          return 'last_name';
        case 'school':
        case 'schools':
          return 'school';
        case 'linkedin_url':
        case 'linkedin url':
        case 'linkedin page':
        case 'linkedin':
          return 'linkedin_url';
        case 'twitter_url':
        case 'twitter url':
        case 'twitter':
          return 'twitter_url';
        case 'facebook_url':
        case 'facebook url':
        case 'facebook':
          return 'facebook_url';
        case 'home_street_address':
        case 'home street address':
          return 'home_street_address';
        case 'home_city':
        case 'home city':
          return 'home_city';
        case 'home_state':
        case 'home state':
          return 'home_state';
        case 'home_zip':
        case 'home zip':
          return 'home_zip';
        case 'title':
        case 'position':
          return 'title';
        case 'company_name':
        case 'company name':
        case 'company':
          return 'company_name';
        case 'company_url':
        case 'company url':
          return 'company_url';
        case 'company_street_address':
        case 'company street address':
          return 'company_street_address';
        case 'company_city':
        case 'company city':
          return 'company_city';
        case 'company_state':
        case 'company state':
          return 'company_state';
        case 'company_zip':
        case 'company zip':
          return 'company_zip';
        case 'neustar_id':
        case 'neustar id':
          return 'neustar_id';
        default:
          break;
      }
    };

    const getOrgOnlyMappedLabels = (header) => {
      switch (header.toLowerCase()) {
        case 'company_name':
        case 'company name':
        case 'company':
        case 'organization_name':
        case 'organization name':
        case 'organization':
          return 'organization_name';
        case 'company_url':
        case 'company url':
        case 'organization_url':
        case 'organization url':
          return 'organization_url';
        case 'company_city':
        case 'company city':
        case 'organization_city':
        case 'organization city':
          return 'organization_city';
        case 'company_state':
        case 'company state':
        case 'organization_state':
        case 'organization state':
          return 'organization_state';
        default:
          break;
      }
    };

    const formatMappedLabels = (header) => {
      let label = header.toLowerCase().trim();
      /** To remove special characters from label */
      if (/[^a-z0-9 _]/g.test(label))
        label = label.replace(/[^a-zA-Z0-9 _]/g, '');
      if (/\s/.test(label)) label = label.replaceAll(' ', '_');
      return label;
    };

    /**
     * Method to convert the data with mapped headers to csv.
     * @param {* Array, that is to converted to csv} data
     * @returns the csv data
     */
    async function convertToCsv(data) {
      return Papa.unparse(data);
    }

    const setToDefault = () => {
      setLinkedInCB(false);
      setEmailOnly(false);
      setAddressOnly(false);
      setIsUniAndNameOnly(false);
      setIsOrgMatch(false);
      // setFirstDegreeTeam(false);
      setIsTagIncludedinTeam(false);
      setIsSuppress(false);
      setIsHouseholdIncluded(false);
      setTeamCB(false);
      setFirstDegreeCB(false);
      setTeamsApplied([]);
      setTagsApplied([]);
      if (props.dataStore.currentUser.isAdmin) {
        setEmailCB(true);
        if (flags.phoneValidation) setPhoneCB(true);
      } else {
        setEmailCB(false);
        setPhoneCB(false);
      }
    };

    const onCancel = () => {
      setIsFileUploaded(false);
      setInputFile(null);
      setFileName('');
      setNameOfImport('');
      setSchools([]);
      setToDefault();
      onMappingCancel();
      if (props.onSuccess) {
        props.onSuccess();
      }
      if (props.onCancel) {
        props.onCancel();
      }
    };

    const inputNameOnChange = (e) => {
      setNameOfImport(e.target.value);
    };

    const onSchoolsChange = (selectedSchool) => {
      setSchools(selectedSchool);
    };

    const checkboxOnChange = (e) => {
      setTeamCB(e.target.checked);
    };

    const linkedInCheckboxOnChange = (e) => {
      setLinkedInCB(e.target.checked);
    };

    const emailCheckboxOnChange = (e) => {
      setEmailCB(e.target.checked);
    };

    const phoneCheckboxOnChange = (e) => {
      setPhoneCB(e.target.checked);
    };

    const firstDegreeOnChange = (e) => {
      setFirstDegreeCB(e.target.checked);
    };

    const emailOnlyCheckboxOnChange = (e) => {
      setEmailOnly(e.target.checked);
    };

    const onTagsChange = (tags) => {
      if (!tags || tags.length < 1) {
        setIsHouseholdIncluded(false);
        setIsTagIncludedinTeam(false);
      }
      setTagsApplied([...tags]);
    };

    const onTeamsChange = (teams) => {
      if (teams && teams.some((el) => el.value === 'all')) {
        const allTeams = getTeamsLookup().filter((e) => e.value !== 'all');
        setTeamsApplied([...allTeams]);
      } else {
        setTeamsApplied([...teams]);
      }
    };

    const onNext = () => {
      window.scrollTo(0, 0);
      if (!outputFile) {
        message.error('Please select a file', 5);
        return;
      }
      isDoneClicked = false;
      onFinish();
      // setShowExportHeaders(true);
    };

    const onFinish = () => {
      window.scrollTo(0, 0);

      setIsLoading(true);
      const params = {
        import_name: nameOfImport,
      };

      let tagIds = [];
      let teamIds = [];

      if (isOrgMatch) {
        params.source = 'organization';
      }
      if (firstDegreeCB) {
        params.data_enrichment_only = 0;
      } else {
        params.data_enrichment_only = 1;
      }

      if (emailOnly) params.email_only_match = 1;
      else params.email_only_match = 0;

      if (addressOnly) params.address_only_match = 1;
      else params.address_only_match = 0;

      if (isHouseholdIncluded) params.add_tag_to_household_members = 1;
      else params.add_tag_to_household_members = 0;

      if (linkedInCB) {
        params.profile_refresh = 1;
      } else {
        params.profile_refresh = 0;
      }

      if (emailCB) {
        params.email_validation = 1;
      } else {
        params.email_validation = 0;
      }

      if (phoneCB) {
        if (flags.phoneValidation) params.phone_validation = 1;
      } else {
        params.phone_validation = 0;
      }
      // if (firstDegreeTeam) params.add_import_profiles_to_team = 1;
      // else params.add_import_profiles_to_team = 0;

      if (isUniAndNameOnly) params.university_and_name_match = 1;
      else params.university_and_name_match = 0;

      if (isTagIncludedinTeam) params.add_tag_to_selected_teams = 1;
      else params.add_tag_to_selected_teams = 0;

      /** To exclude export header check at API side */
      params.exclude_export_headers = 1;

      const enrichment_filters = {};

      if (schools && schools.length) {
        const schoolFilter = [];
        const schoolFilterAdded = [];
        schools.map((school) => {
          if (school && school.__isNew__) {
            schoolFilterAdded.push(school.value);
          } else {
            schoolFilter.push(school.value);
          }
        });
        enrichment_filters.schools = {
          exact: schoolFilter,
          //prefix: schoolFilterAdded,
        };
      }

      const formData = new FormData();
      formData.append('file', outputFile);
      if (enrichment_filters)
        formData.append(
          'enrichment_filters',
          JSON.stringify(enrichment_filters),
        );

      if (tagsApplied && tagsApplied.length > 0) {
        tagIds = tagsApplied
          .map(({ value }) => {
            return value;
          })
          .filter((e) => e);
      }
      if (teamsApplied && teamsApplied.length > 0) {
        teamIds = teamsApplied
          .map(({ value }) => {
            return value;
          })
          .filter((e) => e);
      }
      formData.append('mapped_tags', JSON.stringify(tagIds));
      formData.append('allowed_teams ', JSON.stringify(teamIds));
      putWithAuth(
        'csv-file-import',
        params,
        formData,
        outputFile.name,
        source.token,
      ).then((response) => {
        setToDefault();
        if (!response) return;

        if (response.contact_import_url) {
          if (response.detail) message.success(response.detail, 10);
          setTimeout(() => {
            onCancel();
            navigate('/connect/status', {
              state: {
                refreshStatus: true,
              },
            });
          }, 600);
        } else if (response.errorCode) {
          message.error(response.errorCode, 10);
          onMappingCancel();
        } else if (response.message) {
          message.error(response.message, 10);
          onMappingCancel();
        } else if (response.detail) {
          message.error(response.detail, 10);
          onMappingCancel();
        }

        setIsLoading(false);
      });
    };

    const convertToFile = () => {
      convertToCsv(csvData)
        .then((csv) => {
          outputFile = new Blob([csv], {
            type: 'text/csv;charset=utf-8;',
          });
          onNext();
          // if (blobFile.size < 20971520) {
          //   // setOutputFile(blobFile);
          //   onFinish(blobFile);
          // } else {
          //   const zip = new JSZip();
          //   zip.file(fileName, blobFile);
          //   zip
          //     .generateAsync({
          //       type: 'blob',
          //       compression: 'DEFLATE',
          //       compressionOptions: {
          //         level: 9,
          //       },
          //     })
          //     .then((file) => {
          //       // setOutputFile(file);
          //       onFinish(file);
          //     });
          // }
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    };

    function getDuplicateHeaders(arr) {
      return arr.filter(
        (currentValue, currentIndex) =>
          arr.indexOf(currentValue) !== currentIndex,
      );
    }

    const validateHeaders = () => {
      setIsLoading(true);
      /** Change headers in original data */
      const headers = [];
      let duplicatedHeaders = [];

      csvData[0].map((header, index) => {
        if (mappedHeaders[index]) headers.push(mappedHeaders[index]);
        else {
          headers.push(formatMappedLabels(header));
        }
      });

      /** To change the name when duplication occur. ie, when user changes thefield to home_city for city
       * and we have another field Home City and marked it as none as per our code it will assign as home_city
       * which may cause duplication.
       */
      headers.map((value, index) => {
        if (!mappedHeaders[index]) {
          if (mappedHeaders.includes(value))
            headers[index] = headers[index].replace(value, 'input_' + value);
        }
      });

      duplicatedHeaders = getDuplicateHeaders(headers);
      if (duplicatedHeaders && duplicatedHeaders.length > 0) {
        showWarning(
          `All column names need to be unique. In this import,
          ${duplicatedHeaders.join(', ')} ${
            duplicatedHeaders.length === 1 ? 'is' : 'are'
          }
          duplicated.  Please update and import again.`,
        );
        setIsLoading(false);
        // onMappingCancel();
        return;
      }

      /** Limit row count
       * data enrichment only option selected -> max row count 150k
       * add to contacts option selected (data_enrichment_only = false) -> max row count 30k
       */

      if (firstDegreeCB && csvData && csvData.length - 1 > 30000) {
        /** data_enrichment_only = 0  */
        message.error('Contacts count exceeded limit of 30,000', 5);
        setIsLoading(false);
        onMappingCancel();
        return;
      }

      if (!firstDegreeCB && csvData && csvData.length - 1 > 150000) {
        /** data_enrichment_only = 1 */
        message.error('Data Enrichment count exceeded limit of 150,000', 5);
        setIsLoading(false);
        onMappingCancel();
        return;
      }

      if (schools && schools.length > 0 && headers.includes('school')) {
        showWarning(
          'The input file shouldn’t have a column mapped to school if university enrichment is applied. Please check your file and import again.',
        );
        setIsLoading(false);
        return;
      }

      /** Add header validations
       * check for mandatory fields:
       * if address_only is checked home_street_address and (home_city or home_zip ) is a mandatory field
       * if email_only is checked, email1 is a mandatory field
       * else, any of ["first_name", "last_name", "email1", "phone1", "home_street_address",
       * "home_city", "home_state","home_zip", "company_name", "linkedin_url"]
       *
       */

      const additional_headers = [
        'first_name',
        'last_name',
        'email_1',
        'phone_1',
        'home_street_address',
        'home_city',
        'home_state',
        'home_zip',
        'company_name',
        'linkedin_url',
      ];

      /** DNTFD-5489 All uploaded CSVs have to have a first_name and a last_name column at a minimum.
       * (or an email, if it’s an email-only match).
       * (address_only. home_street_address and (home_city or home_zip ) is a mandatory field)
       * if match using organisation is checked(flag applicable), only mandatory column is company_name.
       * if match using university and name only, school and firstname/lastname is mandatory
       * Otherwise we have first_name and last_name are mandatory, so no need to check additional_headers
       * HOWEVER, if there is a linkedin or neustar column, those can be matched without first/last name,
       * so let files through with only those.
       */

      if (isOrgMatch && headers.includes('school')) {
        showWarning(
          "The input file shouldn’t have a column mapped to school if 'Match on organization and  return current employees is checked' is applied. Please check your file and import again.",
        );
        setIsLoading(false);
        return;
      }

      if (isOrgMatch && !headers.includes('organization_name')) {
        showWarning('Missing mandatory field: organization_name');
        setIsLoading(false);
        return;
      }

      if (addressOnly) {
        if (!headers.includes('home_street_address')) {
          showWarning('Missing mandatory field: home_street_address');
          setIsLoading(false);
          return;
        }

        if (!headers.includes('home_city') && !headers.includes('home_zip')) {
          showWarning('Missing mandatory field: home_city or home_zip');
          setIsLoading(false);
          return;
        }
      }

      if (emailOnly && !headers.includes('email_1')) {
        showWarning('Missing mandatory field: email_1');
        setIsLoading(false);
        return;
      }
      if (isUniAndNameOnly) {
        if (!headers.includes('school') && (!schools || schools.length <= 0)) {
          showWarning('Missing mandatory field: school');
          setIsLoading(false);
          return;
        } else if (
          !headers.includes('first_name') &&
          !headers.includes('last_name')
        ) {
          showWarning('Missing mandatory field: first_name/last_name');
          setIsLoading(false);
          return;
        }
      }

      const ignoreNameCol =
        headers.includes('linkedin_url') || headers.includes('neustar_id');

      if (
        !emailOnly &&
        !addressOnly &&
        !ignoreNameCol &&
        !isUniAndNameOnly &&
        !isOrgMatch
      ) {
        if (!headers.includes('first_name')) {
          showWarning('Missing mandatory field: first_name');
          setIsLoading(false);
          return;
        }

        if (!headers.includes('last_name')) {
          showWarning('Missing mandatory field: last_name');
          setIsLoading(false);
          return;
        }
      }

      csvData[0] = [...headers];

      setIsLoading(false);
      convertToFile();
    };

    const anthropicColumnNormalizer = (columnHeaders) => {
      /** In the CSV upload process, on the column normalization screen, call this route with the complete list of columns from the customer’s CSV,
       *  just want to log the user’s input. So, no need to handle response and errors
       */
      const body = { input_columns: columnHeaders };

      requestWithAuth(
        'anthropic-column-normalizer',
        'POST',
        null,
        body,
        source.token,
      );
    };

    const formatHeaders = () => {
      /** structure the data column wise to show in the list */

      mappedHeaders.length = csvData[0].length;
      csvData[0].map((column, index) => {
        if (!mappedHeaders[index]) {
          let header;
          if (isOrgMatch) {
            header = getOrgOnlyMappedLabels(column);
          } else {
            if (
              column.toLowerCase().includes('email') &&
              !mappedHeaders.includes('email_1')
            ) {
              header = 'email_1';
            } else if (
              column.toLowerCase().includes('phone') &&
              !mappedHeaders.includes('phone_1')
            ) {
              header = 'phone_1';
            } else header = getMappedLabels(column);
          }
          if (!mappedHeaders.includes(header)) mappedHeaders[index] = header;
        }
      });
      setMappedHeaders(mappedHeaders);

      // setFileContents(mappingUIData);
      /** anthropicColumnNormalizer - Not good to call inside renderMappingComponent - it will get called multiple times
       * so calling it just before rendering mapping Component
       */
      anthropicColumnNormalizer(csvData[0]);
      setShowMapping(true);
      setIsLoading(false);
    };

    const parseFile = (inputFile) => {
      /**
       * Parse file using Papa parse
       * Result will be an array of array
       * Get the first row , validate and change the headers
       * After mapping the headers convert the array to csv
       * using papa parse
       */
      csvData = [];

      Papa.parse(inputFile, {
        delimiter: ',',
        worker: true,
        step: function (results, parser) {
          if (results.errors && results.errors.length > 0) {
            csvData = [];
            parser.abort();
          }

          if (results.data) {
            csvData.push(results.data);
            if (csvData.length === 4) {
              setFileContents(csvData);
              formatHeaders();
            }
          }
        },
        complete: function () {
          if (csvData && csvData.length > 1) {
            isParsingCompleted = true;
            if (csvData.length < 4) {
              setFileContents(csvData);
              formatHeaders();
            }

            if (isDoneClicked) validateHeaders();
          } else {
            message.error('Invalid File', 5);
            // message.error('Unprocessible Entity', 5);
            setIsLoading(false);
          }
        },
      });
    };

    const onImportFileClick = () => {
      window.scrollTo(0, 200);

      if (!nameOfImport.trim()) {
        message.error('Please enter name of import', 5);
        return;
      }

      if (nameOfImport.length > 100) {
        message.error('File name length exceeded', 5);
        return;
      }
      if (nameOfImport.match(/[^\w\s()\-,.]/)) {
        message.error('Invalid file name', 5);
        return;
      }
      if (
        props.dataStore.currentUser.type === 'lite' &&
        teamsApplied.length === 0
      ) {
        message.error('Please select a team(s)');
        return;
      }
      if (inputFile.size > 52428800) {
        message.error(
          'Files with size greater than 50MB cannot be imported',
          5,
        );
        return;
      }

      setIsLoading(true);

      const params = { import_name: nameOfImport };

      requestWithAuth(
        'csv-file-import-name-check',
        'GET',
        params,
        null,
        source.token,
      ).then((response) => {
        setIsLoading(false);
        if (response && response.code === 200) {
          onStartMapping();
        }
      });
    };

    const onStartMapping = () => {
      // if (!file) {
      //   message.error('Please select a file', 5);
      //   return;
      // }

      setIsLoading(true);
      /**
       * if zip file => unzip the file and then parse
       * On unzipping we get the file data, parse it using the data
       * otherwise just parse by passing the file
       * */

      if (
        inputFile.type === 'application/x-zip-compressed' ||
        inputFile.type === 'application/zip'
      ) {
        const jsZip = new JSZip();
        jsZip.loadAsync(inputFile).then(function (zip) {
          Object.keys(zip.files)
            .filter((filename) => {
              /** if a file compressed from Mac it may contain __MACOSX files and folders
               * so remove those ones */
              return !filename.match(/^__MACOSX\//);
            })
            .forEach((filename) => {
              zip.files[filename].async('string').then(function (fileData) {
                parseFile(fileData);
              });
            });
        });
      } else {
        parseFile(inputFile);
      }
    };

    /**
     *
     * @param {* The file selected/dropped} inputFile
     * Checks if it's zip/csv, if so then proceed,
     * else throw an error and return
     * @returns
     */
    const checkFileType = (inputFile) => {
      if (
        inputFile.type === 'application/x-zip-compressed' ||
        inputFile.type === 'application/zip' ||
        inputFile.type === 'application/vnd.ms-excel' ||
        inputFile.type === 'text/csv'
      ) {
        setFileName(inputFile.name);
      } else {
        message.error('Incorrect file format', 5);
        return;
      }
      setInputFile(inputFile);
      setIsFileUploaded(true);
    };

    /**
     * Method invoked when file is selected by click.
     * Will be an array and thus getting the first file
     * and passing to the method @checkFileType for classify file types and then parsing
     * @param {* slecetd files array} data
     */
    const onFileSelected = (data) => {
      checkFileType(data.target.files[0]);
    };

    /**
     * Method invoked when file is dropped.
     * Will be an array and thus getting the first file
     * and passing to the method @checkFileType for classify file types and then parsing
     * @param {* dropped files array} files
     */
    const handleDrop = (files) => {
      checkFileType(files[0]);
    };

    const renderMappingComponent = (columnData) => {
      const data = [];
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      columnData &&
        columnData.length &&
        columnData[0].map((item, index) => {
          data.push({
            importedField: columnData[0] ? columnData[0][index] : '',
            aidentifiedField: mappedHeaders[index] || '',
            example1:
              columnData.length > 1 && columnData[1]
                ? columnData[1][index]
                : '',
            example2:
              columnData.length > 2 && columnData[2]
                ? columnData[2][index]
                : '',
            example3:
              columnData.length > 3 && columnData[3]
                ? columnData[3][index]
                : '',
          });
        });

      const addItem = (event, index) => {
        for (let i = 1; i <= 100; i++) {
          if (!columnHeaderMapping.includes(`${event.target.id}_${i}`)) {
            columnHeaderMapping.push(`${event.target.id}_${i}`);
            setColumnHeaderMapping([...columnHeaderMapping.sort()]);
            mappedHeaders[index] = `${event.target.id}_${i}`;
            setMappedHeaders([...mappedHeaders]);
            data[index].aidentifiedField = `${event.target.id}_${i}`;
            break;
          }
        }
      };

      const columns = [
        {
          title: 'Your Column Header',
          // (
          //   <>
          //     <div style={{ fontSize: '12px', fontWeight: '400' }}>
          //       Your Column Header
          //     </div>
          //     <div>Imported Field</div>
          //   </>
          // ),
          dataIndex: 'importedField',
          width: 200,
          // key: 'importedField',
          className: 'mapping-table-column',
        },
        {
          title: 'Aidentified Column Header',
          // (
          //   <>
          //     <div style={{ fontSize: '12px', fontWeight: '400' }}>
          //       Aidentified Column Header
          //     </div>
          //     <div>Aidentified Field</div>
          //   </>
          // ),
          dataIndex: 'aidentifiedField',
          width: 200,
          // key: 'aidentifiedField',
          render: (val, record, index) => {
            return (
              <Select
                style={{ width: '200px' }}
                placeholder="Lookup matching fields"
                value={val}
                // disabled={!!mappedHeaders[index]}
                popupClassName="csv-matching-options"
                onSelect={(value) => {
                  if (value === 'none') mappedHeaders[index] = undefined;
                  else mappedHeaders[index] = value;

                  setMappedHeaders([...mappedHeaders]);
                }}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    {!isOrgMatch && (
                      <>
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'nowrap',
                            flexDirection: 'column',
                          }}
                        >
                          {/* <Input style={{ flex: 'auto' }} value={name} onChange={this.onNameChange} /> */}
                          <a
                            style={{
                              flex: 'none',
                              padding: '8px',
                              display: 'block',
                              cursor: 'pointer',
                            }}
                            id="email"
                            onClick={(e) => addItem(e, index)}
                          >
                            <PlusOutlined /> Add Email
                          </a>
                          <Divider style={{ margin: '4px 0' }} />
                          <a
                            style={{
                              flex: 'none',
                              padding: '8px',
                              display: 'block',
                              cursor: 'pointer',
                            }}
                            id="phone"
                            onClick={(e) => addItem(e, index)}
                          >
                            <PlusOutlined /> Add Phone
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                )}
              >
                {columnHeaderMapping.map((item, index) => (
                  <>
                    {index === 0 && <Option value="none">None</Option>}
                    <Option
                      key={item}
                      value={item}
                      disabled={!!mappedHeaders.includes(item)}
                    >
                      {item}
                    </Option>
                  </>
                ))}
              </Select>
            );
          },
        },
        {
          title: 'Example 1',
          dataIndex: 'example1',
          width: 130,
          // key: 'example1',
          className: 'mapping-table-column',
          render: (val) => {
            return <div className="overflow-check">{val}</div>;
          },
        },
        {
          title: 'Example 2',
          dataIndex: 'example2',
          width: 130,
          // key: 'example2',
          className: 'mapping-table-column',
          render: (val) => {
            return <div className="overflow-check">{val}</div>;
          },
        },
        {
          title: 'Example 3',
          dataIndex: 'example3',
          width: 130,
          // key: 'example3',
          className: 'mapping-table-column',
          render: (val) => {
            return <div className="overflow-check">{val}</div>;
          },
        },
      ];

      return (
        <div className="mapped-list-item-new">
          <Table
            dataSource={data}
            columns={columns}
            bordered
            pagination={false}
            scroll={props.scroll || { x: 'max-content', y: 300 }}
            rowClassName="mapping-table"
          />
        </div>
      );
    };

    const onMappingCancel = () => {
      csvData = [];
      setShowMapping(false);
      // setShowExportHeaders(false);
      setMappedHeaders([]);
      setFileContents([]);
      isParsingCompleted = false;
      isDoneClicked = false;
    };

    const renderMappingUI = () => {
      const onMappingCompleted = () => {
        setIsLoading(true);
        isDoneClicked = true;
        if (isParsingCompleted) {
          validateHeaders();
        }
      };

      return (
        <>
          {/* <div
            style={{
              color: 'var(--color-primary)',
              fontSize: '14px',
              fontWeight: '600',
            }}
          >
            Now, let’s match your file column headers to Aidentified’s
            attributes
          </div> */}
          {/* <div className="info-mapping">
            {/* We have mapped your file field names to ours. If there is an
            incorrect mapping or if a mapping was not found, please use the
            drop-down in the Aidentified Field column to select the appropriate
            choice.

            <div>Field column to select the appropriate choice.</div>
          </div> */}
          <div className="mapping-list-ui">
            {renderMappingComponent(fileContents)}
          </div>
          <div className="button-container">
            <Button className="cancel-button" onClick={onMappingCancel}>
              Back
            </Button>
            <Button onClick={onMappingCompleted}>Complete Import</Button>
          </div>
        </>
      );
    };

    const fetchUserTags = () => {
      requestWithAuth('tags', 'GET', null, null, null)
        .then((response) => {
          if (response && response.length) {
            props.dataStore.userTags = [...response];
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    };

    const handleCreate = (tagName) => {
      setIsLoading(true);
      requestWithAuth(
        'tags',
        'POST',
        null,
        {
          tag: tagName,
        },
        null,
      )
        .then((response) => {
          if (response && response.code === 200) {
            fetchUserTags();
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          message.error('Please try again', 5);
          console.log(err);
          setIsLoading(false);
        });
    };
    const renderCreateModal = () => {
      showConfirm({
        className: 'small-popup',
        title: 'Create New Tag',
        closable: true,
        content: <Input ref={tagRef} placeholder="Tag Name" />,
        okText: 'Save',
        onOk: () => {
          handleCreate(tagRef.current.input.value);
        },
        onCancel: () => {
          return false;
        },
      });
    };

    const renderFileUploadedUI = () => {
      return (
        <div className="file-uploaded" style={{ paddingTop: 15 }}>
          <div className="file-section">
            <div className="file-icon">
              <FileUploaded />
            </div>
            <div className="file-name">{fileName}</div>
            <div className="remove-label" onClick={onCancel}>
              <label style={{ cursor: 'pointer' }}>Remove</label>
            </div>
          </div>
          <div
            style={{
              fontSize: '16px',
              fontWeight: '600',
              color: 'var(--color-primary)',
            }}
          >
            Let&apos;s Get Started
          </div>

          <div className="input-section">
            <label
              style={{
                marginLeft: '4px',
                color: 'var(--color-primary)',
              }}
            >
              Name your import:
            </label>
            <Input
              className="input-name-box"
              value={nameOfImport || ''}
              onChange={inputNameOnChange}
            />{' '}
            <br />
            <label
              style={{
                marginLeft: '2px',
                fontStyle: 'italic',
                color: 'var(--color-primary)',
                opacity: '.5',
              }}
            >
              Supported characters for the name are letters, numbers, spaces,
              and ( ) _ - , .
            </label>
          </div>
          <div className="radio-section">
            <div style={{ padding: '10px 20px 10px 0px' }}>
              <Checkbox
                onChange={firstDegreeOnChange}
                value={firstDegreeCB}
                disabled={isOrgMatch}
              >
                <span style={{ color: 'var(--color-primary' }}>
                  Add imported contacts to Your Network(1<sup>st</sup> degree)
                  and enrich. (30,000 limit)
                </span>
              </Checkbox>
            </div>
            {/* <div
              className={
                props.dataStore.currentUser.isAdmin ? 'enrich-part' : ''
              }
            > */}
            <Collapse
              className="previous-homes-collapse"
              size="small"
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <Button type="primary" style={{ color: 'var(--color-white)' }}>
                  <div>{isActive ? 'Close' : 'Click to Open'}</div>
                </Button>
              )}
            >
              <Collapse.Panel
                header={
                  <div
                    style={{ fontWeight: '600', color: 'var(--color-primary)' }}
                  >
                    Extra Options:
                  </div>
                }
              >
                <b style={{ color: 'var(--color-primary)' }}>
                  Matching Options:
                </b>
                <Checkbox
                  onChange={emailOnlyCheckboxOnChange}
                  checked={emailOnly}
                  disabled={isOrgMatch}
                  style={{
                    paddingBottom: 10,
                  }}
                >
                  <span className="radio-section">
                    {/* In addition to Aidentified matching logic, also match using{' '} */}
                    Include match using <b> email only</b>.
                    <Tooltip
                      placement="right"
                      color="var(--color-primary)"
                      title="This may return results where
                          name does not match such as the case with shared email
                          accounts. Using this option can increase the number of
                          results."
                    >
                      <InfoIcon className="info-icon" />
                    </Tooltip>
                  </span>
                </Checkbox>
                <Checkbox
                  onChange={(e) => {
                    setAddressOnly(e.target.checked);
                  }}
                  checked={addressOnly}
                  disabled={isOrgMatch}
                  style={{
                    paddingBottom: 10,
                  }}
                >
                  <span className="radio-section">
                    {/* In addition to Aidentified matching logic, also match using */}
                    Include match using <b> address only</b>.
                    <Tooltip
                      placement="right"
                      color="var(--color-primary)"
                      title="This may return multiple records per
                    address. Each returned record will consume one credit when
                    exporting file."
                    >
                      <InfoIcon className="info-icon" />
                    </Tooltip>
                  </span>
                </Checkbox>
                <Checkbox
                  onChange={(e) => {
                    setIsOrgMatch(e.target.checked);
                    setEmailOnly(false);
                    setAddressOnly(false);
                    setIsUniAndNameOnly(false);
                  }}
                  checked={isOrgMatch}
                  disabled={firstDegreeCB}
                  style={{
                    paddingBottom: 10,
                  }}
                >
                  <span className="radio-section">
                    Match on <b>organization and return current employees</b>
                    <Tooltip
                      placement="right"
                      color="var(--color-primary)"
                      title="This process takes a list of organization names with optional additional attributes and creates a list of all current employees of the organization."
                    >
                      <InfoIcon className="info-icon" />
                    </Tooltip>
                  </span>
                </Checkbox>

                <Checkbox
                  onChange={(e) => {
                    setIsUniAndNameOnly(e.target.checked);
                  }}
                  checked={isUniAndNameOnly}
                  disabled={isOrgMatch}
                  style={{
                    paddingBottom: 10,
                  }}
                >
                  <span className="radio-section">
                    {/* In addition to Aidentified matching logic, also match using */}
                    Include match using <b>university and name only.</b>
                    <Tooltip
                      placement="right"
                      color="var(--color-primary)"
                      title="This process utilizes the provided name and university information to search for a distinct match within the Aidentified dataset, aiming to identify additional matches. It's important to note that the results may include some inaccuracies since the complete population of alumni for each university may not be accessible."
                    >
                      <InfoIcon className="info-icon" />
                    </Tooltip>
                  </span>
                </Checkbox>

                {/* <div>
                      <span style={{ color: 'var(--color-primary)' }}>
                        For university enrichment, please confirm university(s):
                      </span>
                    </div> */}
                <CreatableSelect
                  id="schools"
                  disabled={
                    !isUniAndNameOnly && !props.dataStore.currentUser.isAdmin
                  }
                  showContains={false}
                  placeholder="Select University"
                  isMulti
                  from="import"
                  value={schools}
                  onSelect={onSchoolsChange}
                />

                <div className="radio-section">
                  {/* Allow team members to view imported file */}
                  <div>
                    <b style={{ color: 'var(--color-primary)' }}>
                      Team Options:
                    </b>
                  </div>
                  Select teams to share this import as their 2nd-degree network
                  <DataSelect
                    value={teamsApplied}
                    dropDownList={getTeamsLookup()}
                    placeholder="Select Team(s)"
                    isMulti
                    onItemSelected={onTeamsChange}
                  />
                  {/* <Checkbox
                    onChange={(e) => {
                      setFirstDegreeTeam(e.target.checked);
                    }}
                    checked={firstDegreeTeam}
                    value={firstDegreeTeam}
                    disabled={!firstDegreeCB || teamsApplied.length === 0}
                  >
                    <span style={{ color: 'var(--color-primary' }}>
                      Add imported contacts to Your Network(1<sup>st</sup>{' '}
                      degree) for selected Team(s)
                      <p>
                        (Note: to select for you, choose option above{' '}
                        <b>Extra Options</b>)
                      </p>
                    </span>
                  </Checkbox> */}
                </div>

                {props.dataStore.currentUser.type !== 'lite' && (
                  <div className="radio-section">
                    <div>
                      <b style={{ color: 'var(--color-primary)' }}>
                        Tag Options:
                      </b>
                    </div>
                    Apply Tag(s) to matched records
                    <Tooltip
                      placement="right"
                      color="var(--color-primary)"
                      title="Your imported records will have the selected tags applied to them.
                      Please note that there is a limit of 300,000 tags that can be applied to profiles overall."
                    >
                      <InfoIcon className="info-icon" />
                    </Tooltip>
                    <div className="tag-section">
                      <DataSelect
                        value={tagsApplied}
                        dropDownList={getTagsLookup()}
                        placeholder="Select Tag(s)"
                        isMulti
                        onItemSelected={onTagsChange}
                      />
                      <div className="tag-button" onClick={renderCreateModal}>
                        New Tag
                      </div>
                    </div>
                    <Checkbox
                      style={{ paddingBottom: 10 }}
                      checked={isHouseholdIncluded}
                      disabled={tagsApplied.length === 0}
                      onChange={(e) => {
                        setIsHouseholdIncluded(e.target.checked);
                      }}
                    >
                      <span className="radio-section">
                        Add selected Tag(s) to household members
                      </span>
                    </Checkbox>
                    <Checkbox
                      style={{ paddingBottom: 20 }}
                      checked={isTagIncludedinTeam}
                      disabled={tagsApplied.length === 0}
                      onChange={(e) => {
                        setIsTagIncludedinTeam(e.target.checked);
                      }}
                    >
                      <span className="radio-section">
                        Add selected Tag(s) to selected Team(s)
                      </span>
                    </Checkbox>
                  </div>
                )}

                {props.dataStore.currentUser.isAdmin && (
                  <>
                    <div>
                      <b style={{ color: 'var(--color-primary)' }}>
                        Admin Options:
                      </b>
                    </div>
                    <Checkbox
                      checked={linkedInCB}
                      onChange={linkedInCheckboxOnChange}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      <span style={{ color: 'var(--color-primary' }}>
                        LinkedIn refresh
                      </span>
                    </Checkbox>{' '}
                    <Checkbox
                      checked={emailCB}
                      onChange={emailCheckboxOnChange}
                      // style={{ alignItems: 'center' }}
                    >
                      <span style={{ color: 'var(--color-primary' }}>
                        Validate Email
                      </span>
                    </Checkbox>
                    {flags.phoneValidation && (
                      <Checkbox
                        checked={phoneCB}
                        onChange={phoneCheckboxOnChange}
                        style={{
                          paddingTop: 10,
                        }}
                      >
                        <span style={{ color: 'var(--color-primary' }}>
                          Validate Phone
                        </span>
                      </Checkbox>
                    )}
                  </>
                )}
              </Collapse.Panel>
            </Collapse>
            {/* </div> */}
          </div>

          <div className="submit-section">
            <Button type="primary" onClick={onImportFileClick}>
              Next
            </Button>
          </div>
        </div>
      );
    };

    const renderImportTips = () => {
      return (
        <div>
          <div className="csv-section">
            <img className="csv-icon" src={CsvIcon} alt="csv" />
            <label className="csv-label">CSV Imports</label>
          </div>
          <div style={{ padding: '20px 0' }}>
            <label className="import-label">Import Tips</label>
            <p className="paragraph-data">
              When you upload a CSV file or a zipped CSV file, Aidentified
              enriches the contacts and provides results in file to download.
            </p>
            <div className="about-text-csv" style={{ marginTop: '0px' }}>
              You can also view results in
              <a href="/prospect-finder/people"> Prospect Finder </a>
              and in{' '}
              <a href="/relationship-intelligence/dashboard">
                {' '}
                Relationship Intelligence / Dashboard
              </a>
              .
            </div>
            <div className="list-section">
              <label>File must contain both:</label>
              <ul>
                <li>a first, last name</li>
                <li>
                  at least one field for an email, address or phone number
                </li>
              </ul>
              <label style={{ fontWeight: 'bold' }}>
                You will receive an email when the enrichment is complete.
              </label>
            </div>
            <div className="note-section">
              Imported contact information is used for matching only –
              Aidentified does not integrate your data into our data set.
            </div>
            <div className="import-note">
              Note: If applicable, ensure that your use of these products or
              services compiles with applicable laws and regulations relating to
              consumer privacy and solicitation.
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="csv-container loader-wrapper">
        {isLoading && (
          <LoaderAnimation
            loaderId="csv-import-new-loader"
            style={{ top: '200px' }}
          />
        )}
        <div className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
          {/* <Prompt
          when={isDoneClicked}
          message={(location, action) => {
            if (
              location.pathname.startsWith('/connect/export') ||
              location.pathname.startsWith('/connect/status')
            )
              return true;
            else
              return 'Your File is still processing. Leaving the page causes losing your data. Are you sure about leaving this page? ';
          }}
          // message="Your File is still processing. Leaving the page causes losing your data. Are you sure about leaving this page? "
          /> */}
          <div>
            <div
              // className={!showMapping ? 'inner-container' : ''}
              className="modal-container"
              style={{
                marginBottom: '20px',
                // gridTemplateColumns: isFileUploaded ? '0.75fr' : '0.5fr 0.5fr',
              }}
            >
              <div>
                <label className="connect-to">
                  {showMapping
                    ? 'Now, let’s match your file column headers to Aidentified’s attributes'
                    : 'Import'}
                </label>
                <div style={{ padding: '30px 0px 20px 8px' }}>
                  {showMapping ? (
                    renderMappingUI()
                  ) : (
                    <FileDrop onDrop={handleDrop}>
                      <div className="drop-section">
                        {isFileUploaded ? (
                          renderFileUploadedUI()
                        ) : (
                          <div style={{ minHeight: '70px' }}></div>
                        )}
                      </div>
                    </FileDrop>
                  )}
                </div>
              </div>
              {!isFileUploaded && renderImportTips()}
            </div>

            {/* {!showMapping && (
            <div>
              <label className="format-section">
                Recommended CSV column header format for import
              </label>
              <br />
              <label className="format-section1">
                To make sure your file is compliant with our app
                <span style={{ fontWeight: 'bold' }}>
                  {' '}
                  edit your CSV column headers
                </span>{' '}
                to match:
              </label>
              <div>
                <div className="list-div">
                  <div>
                    <ul className="list">
                      {column_headers.map((header, index) => {
                        return (
                          <>
                            {index < 9 && (
                              <>
                                <li className="list-item">{header}</li>
                              </>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                  <div>
                    <ul>
                      {column_headers.map((header, index) => {
                        return (
                          <>
                            {index >= 9 && (
                              <>
                                <li className="list-item">{header}</li>
                              </>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}  */}
            {isFileUploaded && (
              <div style={{ paddingBottom: 20 }}>
                <MoreInfoSection from="singleline" isCSVImport={!showMapping} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }),
);

export default CsvImportNew;
