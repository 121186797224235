import './CreditsView.css';

import { Button, Card, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import axios, { CancelTokenSource } from 'axios';

import { CreditsViewPropsInterface } from '../../types/AccountTypes';
import { requestWithAuth } from '../../services/api';
import { splitNumberWithCommas } from '../../utils/general';
import { planCredits } from '../../utils/constants';
import LoaderAnimation from '../../components/AidLoader';

message.config({
  top: 80,
});

let source: CancelTokenSource;
const { Option } = Select;

const CreditsView: React.FC<CreditsViewPropsInterface> = inject('dataStore')(
  observer((props) => {
    const [isCreditsNeeded, setIsCreditsNeeded] = useState<boolean>(false);
    // const [creditsAddButton, setCreditsAddButton] = useState(false)
    const [creditsQuantity, setCreditsQuantity] = useState<string>('');
    const [creditBalance, setCreditBalance] = useState<number>(0);
    // const [taxPercent, setTaxPercent] = useState(0);
    const [totalCreditAmount, setTotalCreditAmount] = useState<number>(0);
    // const [taxAmount, setTaxAmount] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
      const { CancelToken } = axios;
      source = CancelToken.source();
      fetchUserTypeData();

      return () => {
        message.destroy();
        if (source) source.cancel('Operation canceled by the user.');
      };
    }, []);

    useEffect(() => {
      // after completing buying credits
      if (props.creditsCompleted) {
        setIsLoading(false);
        setIsCreditsNeeded(false);
        setCreditBalance(props.dataStore.currentUser.creditCount);
      }
    }, [props.creditsCompleted]);

    const fetchUserTypeData = () => {
      setIsLoading(true);
      requestWithAuth(
        'user-type',
        'GET',
        null,
        null,
        source.token,
        'progress-credits-view',
      ).then((response) => {
        if (response && response.results && response.results[0]) {
          setCreditBalance(response.results[0].credits);
          props.dataStore.currentUser.creditCount = response.results[0].credits;
        }
        setIsLoading(false);
      });
    };

    const addMoreCredit = () => {
      setIsCreditsNeeded(true);
    };

    const cancelCredits = () => {
      setIsCreditsNeeded(false);
      setCreditsQuantity('');
      // setTaxAmount(0);
    };

    const buyMoreCredits = () => {
      //   if (!props.billingInfo) {
      //     // if no billing info added show payment page
      //     const { showPaymentOption } = props;
      //     showPaymentOption(totalCreditAmount, creditsQuantity);
      //     return;
      //   }

      setIsLoading(true);
      const body = {
        create: parseInt(creditsQuantity),
        quantity: parseInt(creditsQuantity),
      };

      if (props.onBuyCredits) props.onBuyCredits(body);
    };

    const handleChange = (value: string) => {
      setCreditsQuantity(value);
      const selectedPlan = planCredits.find(
        (plan) => plan.credit === parseInt(value),
      );
      if (selectedPlan) {
        setTotalCreditAmount(selectedPlan.amount);
        // let taxAmount = 0;
        // taxAmount = ((plan.amount * taxPercent) / 100).toFixed(2);
        // setTaxAmount(taxAmount);
      }
    };

    const numberWithCommas = (x: number) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
      <div className="loader-wrapper">
        {isLoading && <LoaderAnimation loaderId="credits-view-loader" />}
        <div
          className={
            isLoading
              ? 'creditsView aid-div-disable'
              : 'creditsView aid-div-enable'
          }
        >
          {isCreditsNeeded ? (
            <>
              {/* {!props.billingInfo && (
                <div className="container">
                  <div className="outerDiv">
                    <div className="Firstlabel">1</div>
                    <div className="Firstlabelright">Select Credits</div>
                  </div>
                  <div className="outerDiv">
                    <div className="label">2</div>
                    <div className="labelright">Payment and Confirm</div>
                  </div>
                </div>
              )} */}
              <Card
                className={
                  creditsQuantity ? 'creditsCardBuy' : 'creditsCardNotSelected'
                }
              >
                <div
                  className={
                    creditsQuantity
                      ? 'creditsCardToBuyContainer'
                      : 'creditsCardBuySelected'
                  }
                >
                  <div className="buyCreditsView">
                    <div className="dropdown">
                      <Select
                        placeholder="Select Credits"
                        onChange={handleChange}
                      >
                        {planCredits.map((plan, index) => (
                          <Option key={index} value={plan.credit}>
                            {splitNumberWithCommas(plan.credit)}{' '}
                            {`($${splitNumberWithCommas(plan.amount)})`}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    {props.billingInfo && (
                      <div className="creditTaxDivision" id="creditTaxDivision">
                        {/* <div className="creditTaxValue">
                          <div>Tax {taxPercent}%</div>
                          <div className="creditTaxPercent">
                            {' '}
                            {`$${numberWithCommas(taxAmount)}`}{' '}
                          </div>
                        </div> */}
                        <div className="taxTotalAmount">
                          <div className="creditSubTotal">Sub Total</div>
                          <div className="creditTotalAmount">
                            {' '}
                            {`$${numberWithCommas(totalCreditAmount)}`}{' '}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      creditsQuantity
                        ? 'buySelectDivider'
                        : 'buySelectDividerNotselected'
                    }
                  />
                  <div className="buyMoreButton">
                    <Button
                      onClick={buyMoreCredits}
                      disabled={!creditsQuantity}
                      className={
                        creditsQuantity
                          ? 'buyMoreCreditEnabled'
                          : 'buyMoreCredit'
                      }
                    >
                      Buy More Credits
                    </Button>
                    <div
                      onClick={cancelCredits}
                      style={{
                        marginTop: 11,
                        textAlign: 'center',
                        cursor: 'pointer',
                        color: 'var(--color-dark)',
                        fontWeight: 'bold',
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </Card>
            </>
          ) : (
            <div className="creditContainer">
              <Card className="creditsCard">
                <div className="creditsCardToAdd">
                  <div className="creditsViewTitle">
                    Credit Balance:
                    <span
                      style={{
                        color: 'var(--color-primary)',
                        fontWeight: 'bold',
                      }}
                    >
                      {splitNumberWithCommas(creditBalance)}
                    </span>
                  </div>
                  <Button
                    // disabled={!creditsAddButton}
                    // className={
                    //     creditsAddButton
                    //         ? 'addMoreCredit'
                    //         : 'addMoreCreditDisabled'
                    // }
                    className="addMoreCredit"
                    onClick={addMoreCredit}
                  >
                    Add More Credits
                  </Button>
                </div>
              </Card>
              <div className="creditsNote">
                <label className="noteSection">How Credits work:</label>
                <p className="paragraphData" style={{ paddingTop: '20px' }}>
                  Credits are used when you export contacts out of the
                  Aidentified application to a connected CRM application or as a
                  CSV file.
                </p>
                <p className="paragraphData">
                  When you export a profile, 1 credit is deducted from your
                  account when a verified email is included. If you export 10
                  profiles, 10 credits are deducted when all 10 profiles have
                  verified emails.
                </p>
                <p className="paragraphData">
                  For CSV files, credits are not charged when importing. When
                  you download the file, you will be charged credits.{' '}
                </p>
                <p className="paragraphData">
                  Prior to the download commencing, the number of credits
                  required will be provided. If you have sufficient credits in
                  your account, the file will download after confirming the
                  download. If not, you will be directed to buy additional
                  credits. Credits will be deducted when download is complete.
                </p>
                <p className="paragraphNoteData">
                  <label style={{ fontWeight: 'bold' }}>Note:</label> emails are
                  verified for deliverability through a third-party service.
                  Based on the email score, emails are retained or removed.
                  While this service provides a high degree of confidence, it is
                  not possible to guarantee email deliverability.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }),
);

export default CreditsView;
