import './index.css';

import { inject, observer } from 'mobx-react';

import { NavLink } from 'react-router-dom';

const NavTab = inject('dataStore')(
  observer(({ columns, data, ...props }) => {
    const { navList = [] } = props;

    return (
      <div className="navTab">
        <div className="nav-tab-top">
          {navList.map((nav) => (
            <NavLink
              key={nav.name}
              to={nav.path}
              id={nav.id}
              className={({ isActive }) =>
                isActive ? 'navigation selected-nav' : 'navigation'
              }
            >
              <span>{nav.name}</span>
            </NavLink>
          ))}
        </div>
      </div>
    );
  }),
);

export default NavTab;
