import { message, Tooltip, Tag } from 'antd';

import InfoIcon from '../../assets/icons/info.svg?react';

import Tags from '../Tags';
import { requestWithAuth } from '../../services/api';

const TagSection = ({ b2bId, b2cId, tagData, updatePerson }) => {
  const selectedProfileIds = [
    {
      b2b_id: b2bId,
      b2c_id: b2cId || '',
    },
  ];

  const handleTagDelete = (e, tagId) => {
    e.preventDefault();
    requestWithAuth(
      'profile-tags/delete-multiple',
      'POST',
      null,
      {
        b2b_b2c_list: selectedProfileIds,
        tag_ids: [tagId],
      },
      null,
    ).then((response) => {
      if (response && response.code === 200) {
        message.success('Tag detached', 5);
        updatePerson(
          'tags_details',
          tagData.filter((item) => item.tag !== tagId),
        );
      }
    });
  };

  /*return (
    <div className="profile-boxes">
      <div className="profile-box-header">
        <div className="profile-titles">
          Tags
          <Tooltip
            placement="top"
            color="var(--color-primary)"
            title="Tags can be utilized for segmentation purposes, allowing for effective organization and management of your prospects and clients.  
            Use Tags in accordance with your corporate guidelines."
          >
            <InfoIcon className="info-icon" />
          </Tooltip>
        </div>

        <Tags
          fromProfile
          profileTags={tagData}
          selectedProfileIds={selectedProfileIds}
          updatePerson={updatePerson}
        />
      </div>
      <div className="border" />
      <div className="profile-box-body">
        {tagData &&
          tagData.length > 0 &&
          tagData.map((item) => {
            return (
              <Tag
                className="profile-tag"
                closable
                onClose={(e) => {
                  handleTagDelete(e, item.tag);
                }}
              >
                {item.tag_name}
              </Tag>
            );
          })}
      </div>
    </div>
  );*/
  return (
    <div>
      <div className="profile-titles">
        Tags
        <Tooltip
          placement="top"
          color="var(--color-primary)"
          title="Tags can be utilized for segmentation purposes, allowing for effective organization and management of your prospects and clients.  
            Use Tags in accordance with your corporate guidelines."
        >
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '300px' }}>
        {tagData &&
          tagData.length > 0 &&
          tagData.map((item) => {
            return (
              <Tag
                className="profile-tag"
                closable={item.pid ? true : false}
                onClose={(e) => {
                  handleTagDelete(e, item.tag);
                }}
              >
                {item.tag_name}
              </Tag>
            );
          })}
      </div>
      <div>
        <Tags
          fromProfile
          profileTags={tagData}
          selectedProfileIds={selectedProfileIds}
          updatePerson={updatePerson}
          tagClassName="link-button"
        />
      </div>
    </div>
  );
};

export default TagSection;
