import './index.css';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Component } from 'react';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

class LocationSearch extends Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }

  handleChange = (address) => {
    // const { id, from } = this.props;

    // if (address && document.getElementById(`${id}_${from}_close`))
    //   document.getElementById(`${id}_${from}_close`).className =
    //     'fa fa-times icon';
    // else if (document.getElementById(`${id}_${from}_close`))
    //   document.getElementById(`${id}_${from}_close`).className =
    //     'fa fa-search icon';
    this.setState({ address });
  };

  handleSelect = (address) => {
    const { onLocationSelected, id } = this.props;
    this.setState({ address });
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => onLocationSelected(id, latLng, address))
      .catch((error) => console.error('Error', error));
  };

  clearText = (e) => {
    const { onLocationSelected, id } = this.props;
    onLocationSelected(id, undefined, '');
    this.setState({ address: '' });
    // e.target.className = 'fa fa-search icon';
  };

  // hideDatePicker = () => {
  //   if (document.getElementById('date') !== null)
  //     document.getElementById('date').style.display = 'none';
  // };
  componentDidMount() {
    this.selectOption(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.value) {
      this.selectOption(nextProps);
    }
  }

  selectOption = (nextProps) => {
    this.setState({
      address: nextProps.value.address,
    });
  };

  render() {
    const { placeholder, id, from, value, isDisabled = false } = this.props;
    return (
      <PlacesAutocomplete
        id={id}
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        debounce={500}
        disabled
        searchOptions={{ componentRestrictions: { country: 'us' } }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="relative">
            <div
              className={
                isDisabled
                  ? 'input-container disabled-container'
                  : 'input-container'
              }
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <input
                  {...getInputProps({
                    placeholder,
                    className: 'location-search-input',
                    autoComplete: 'no',
                  })}
                  disabled={isDisabled}
                  // onClick={this.hideDatePicker}
                />
                {value?.geo ? (
                  <CloseOutlined
                    className="search-icon"
                    id={`${id}_${from}_close`}
                    onClick={this.clearText}
                  />
                ) : (
                  <SearchOutlined
                    className="search-icon"
                    id={`${id}_${from}_close`}
                    onClick={this.clearText}
                  />
                )}
              </div>
              {/* <Icon
                type={value && value !== {} && value.geo ? 'close' : 'search'}
                className="icon"
                id={`${id}_${from}_close`}
                onClick={this.clearText}
              /> */}
              {/* <i
                className={
                  value && value !== {} && value.geo
                    ? 'fa fa-times icon'
                    : 'fa fa-search icon'
                }
                id={`${id}_${from}_close`}
                onClick={this.clearText}
              /> */}
            </div>

            <div className="autocomplete-dropdown-container">
              <div
                className={
                  suggestions && suggestions.length
                    ? 'dropdown-container-border'
                    : ''
                }
              >
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  // const style = suggestion.active
                  //     ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  //     : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        // style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearch;
