import './UserLayout.css';

import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { CopyrightOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';

import qs from 'qs';
import LoaderAnimation from '../components/AidLoader';
import { getDomain } from '../utils/general';
import useDocumentTitle from '../utils/useDocumentTitle';

const UserLayout = inject('dataStore')(
  observer((props) => {
    const { isLoading, logout } = useAuth0();
    const location = useLocation();

    if (isLoading)
      return (
        <div className="loader-wrapper">
          <LoaderAnimation loaderId="user-layout-loader" />;
        </div>
      );

    let title = 'Aidentified';
    if (localStorage.getItem('aidentified.theme')) {
      const { name } = JSON.parse(localStorage.getItem('aidentified.theme'));
      title = name ? name.toString().toTitleCase() : 'Aidentified';
    }
    useDocumentTitle(title);
    useEffect(() => {
      const urlParams = location.search;
      const query = qs.parse(urlParams, {
        ignoreQueryPrefix: true,
      });
      if (
        query &&
        !(Object.keys(query).length === 0 && query.constructor === Object) &&
        query.from &&
        query.is_logout
      ) {
        /** redirected from logout in extension,
         * keep the extension redirect url in local storage and
         * after logout from auth0 use this url to redirect back to extension
         */
        localStorage.setItem('chrome_redirect', query.from);
        logout({
          returnTo: `${getDomain()}/user/login`,
        });
      }
    }, []);

    return (
      <div className="main-container" data-testid="main-container">
        {/* <div className="main-container-bg"> */}
        <Outlet />

        <div className="footer">
          <div className="text">
            Copyright
            {/* <Icon type="copyright" /> */}
            <CopyrightOutlined
              style={{ paddingLeft: '2px', paddingRight: '2px' }}
            />
            {new Date().getFullYear()} Aidentified, Inc.
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }),
);

export default UserLayout;
