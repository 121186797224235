import './index.scss';
import '../MultiDataSearch/index.css';

import { inject, observer } from 'mobx-react';
import { useEffect } from 'react';
import Select from 'react-select';

const Filter = inject('globalDataStore')(
  observer((props) => {
    const defaultFilter =
      props.globalDataStore.multipleFilters.filterList.filter((filter) => {
        return filter.is_active === true;
      });

    const options = props.globalDataStore.multipleFilters.filterList.map(
      (filter) => {
        return {
          value: filter.value,
          label: filter.label.toString().toTitleCase(),
        };
      },
    );

    options.unshift({
      value: -1,
      label: 'All Saved Searches',
    });
    if (props.noFilters) options.push({ value: -2, label: 'No Filters' });

    useEffect(() => {
      // for company profile 1stand2nd degree list, there is an option for
      // no filters and if there is no dafault filter, no filters will be default
      if ((!defaultFilter || defaultFilter.length <= 0) && props.noFilters) {
        props.globalDataStore.multipleFilters.selectedIdList = [
          { value: -2, label: 'No Filters' },
        ];
      }
    }, [props.noFilters]);

    const handleChange = (selected, e) => {
      switch (e.action) {
        case 'select-option': {
          if (e.option.value === -1) {
            /** all filter */
            props.globalDataStore.multipleFilters.selectedIdList = [];
            props.globalDataStore.multipleFilters.selectedIdList = [
              ...props.globalDataStore.multipleFilters.allFilterIds,
            ];
          } else if (e.option.value === -2) {
            /** show all hidden items */
            if (props.noFilters)
              props.globalDataStore.multipleFilters.selectedIdList = [
                { value: -2, label: 'No Filters' },
              ];
          } else {
            if (
              props.globalDataStore.multipleFilters.selectedIdList.some(
                (opt) => opt.value === -2,
              )
            ) {
              props.globalDataStore.multipleFilters.selectedIdList = [];
            }
            props.globalDataStore.multipleFilters.selectedIdList =
              selected.filter(function (obj) {
                return obj.value !== -2;
              });
          }
          break;
        }
        case 'deselect-option':
        case 'remove-value': {
          if (
            !props.globalDataStore.multipleFilters.selectedIdList ||
            props.globalDataStore.multipleFilters.selectedIdList.length <= 1
          )
            return;

          const option = e.option || e.removedValue;
          if (option.value === -1) {
            props.globalDataStore.multipleFilters.selectedIdList =
              defaultFilter && defaultFilter.length > 0
                ? [
                    {
                      value: defaultFilter[0].value,
                      label: defaultFilter[0].label,
                    },
                  ]
                : props.noFilters
                  ? [{ value: -2, label: 'No Filters' }]
                  : [...props.globalDataStore.multipleFilters.allFilterIds];
          } else {
            if (
              props.globalDataStore.multipleFilters.selectedIdList.some(
                (opt) => opt.value === option.value,
              )
            ) {
              props.globalDataStore.multipleFilters.selectedIdList =
                props.globalDataStore.multipleFilters.selectedIdList.filter(
                  function (obj) {
                    return obj.value !== option.value && obj.value !== -1;
                  },
                );
            }

            if (
              props.globalDataStore.multipleFilters.selectedIdList.length <= 0
            ) {
              props.globalDataStore.multipleFilters.selectedIdList =
                defaultFilter && defaultFilter.length > 0
                  ? [
                      {
                        value: defaultFilter[0].value,
                        label: defaultFilter[0].label,
                      },
                    ]
                  : props.noFilters
                    ? [{ value: -2, label: 'No Filters' }]
                    : [...props.globalDataStore.multipleFilters.allFilterIds];
            }
          }
          break;
        }
        default:
          break;
      }
    };

    return (
      <>
        <Select
          value={props.globalDataStore.multipleFilters.selectedIdList}
          isMulti
          className="multi-auto-suggest"
          classNamePrefix="ai"
          isClearable={false}
          isSearchable={false}
          hideSelectedOptions={false}
          cropWithEllipsis
          closeMenuOnSelect={false}
          closeMenuOnScroll
          // onMenuScrollToBottom={() => {
          //   handleScrollToBottom();
          // }}
          components={props.components}
          onChange={handleChange}
          options={options}
        />
      </>
    );
  }),
);

export default Filter;
