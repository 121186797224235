import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import { requestWithAuth } from '../../services/api';
import { wealthScoreRanges } from '../../utils/constants';
import PieChartSection from '../CompanyProfile/pieChartSection';
import ChartSection from '../CompanyProfile/chartSection';

const MappedConnectionsChart = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const [wealthData, setWealthData] = useState([]);
    const [salaryData, setSalaryData] = useState([]);
    const [salaryLoading, setSalaryLoading] = useState(false);
    const [wealthSegmentLoading, setWealthSegmentLoading] = useState(false);
    const [salaryReload, setSalaryReload] = useState(false);
    const [wealthSegmentReload, setWealthSegmentReload] = useState(false);

    useEffect(() => {
      if (props.b2bId || props.b2cId) {
        fetchGraphData('inferred_salary');
        fetchGraphData('wealth_estimate');
      }
    }, [props.b2bId, props.b2cId]);

    function fetchGraphData(key) {
      const params = {
        page_size: 100,
        from: 0,
        key,
        filter_type: 'all',
      };

      const search = {
        mapped_source_b2b_id: props.b2bId || '',
        mapped_source_b2c_id: props.b2cId || '',
      };

      // if (searchObj && Object.keys(searchObj).length > 0) body.search = searchObj;

      requestWithAuth('people-grouping-new', 'POST', params, {
        search: search,
      }).then((response) => {
        if (!response) return;
        switch (key) {
          case 'inferred_salary':
            setSalaryLoading(false);
            if (response && response.results) {
              setSalaryData(response.results);
            } else {
              setSalaryReload(true);
            }
            break;

          case 'wealth_estimate':
            setWealthSegmentLoading(false);
            if (response && response.results) {
              setWealthData(response.results);
            } else setWealthSegmentReload(true);
            break;

          default:
            break;
        }
      });
    }

    function preparePieChartData(dataArray) {
      const labelArray = [];
      const valueArray = [];
      dataArray.map((value) => {
        labelArray.push(value.key);
        valueArray.push(value.unique_count);
      });
      return {
        data: valueArray,
        labels: labelArray,
      };
    }

    function prepareGraphData(dataArray, colorChange) {
      const labels = [];
      const keyLabels = [];
      dataArray.map((value) => {
        labels.push(value.key ? value.key.toString().toTitleCase() : '');
        // eslint-disable-next-line no-prototype-builtins
        if (value.hasOwnProperty('ai_org_id')) keyLabels.push(value.ai_org_id);
      });
      return {
        labels,
        keyLabels,
        // keyLabels: dataArray.map((value) => value.key),
        // toolTipLabels: dataArray.map((value) =>
        //   value.key ? value.key.toUpperCase() : '',
        // ),

        datasets: [
          {
            barThickness: 6,
            // backgroundColor: 'rgb(184,217,146)',
            // backgroundColor: 'rgb(184,217,146)',
            backgroundColor: getComputedStyle(document.body)
              .getPropertyValue(
                colorChange ? `${colorChange}` : '--color-golden-end',
              )
              .replace(')', ', 0.65)')
              .replace('rgb', 'rgba'),
            hoverBackgroundColor: getComputedStyle(
              document.body,
            ).getPropertyValue('--color-primary'),
            data: dataArray.map((value) => value.unique_count),
          },
        ],
      };
    }

    const onGraphClick = (id, item) => {
      /**clear all before applying chart data */
      props.modalDataStore.profileModal.mappedRelationshipsFilter = {
        ...props.modalDataStore.profileModal.mappedRelationshipsFilter,
        mappedSourceInferredSalary: [],
        mappedSourceWealthEstimate: [],
        mappedSourceLocationState: '',
        mappedSourceLocationCity: '',
        mappedSourceOrgname: '',
        mappedSourceTitle: '',
        mappedSourceSchool: [],
        mappedRelationshipWeights: [],
        mappedRelationshipTypes: [],
      };

      if (!item) return;
      const search = {};
      switch (id) {
        case 'inferred_salary_range':
          props.modalDataStore.profileModal.mappedRelationshipsFilter.mappedSourceInferredSalary =
            [item];
          break;
        case 'wealth_estimate': {
          const wealth_estimate = wealthScoreRanges.filter((score) => {
            return score.label.toLowerCase() === item.toLowerCase();
          });
          props.modalDataStore.profileModal.mappedRelationshipsFilter.mappedSourceWealthEstimate =
            [wealth_estimate[0].value];

          break;
        }
        default:
          break;
      }
      if (props.handleClick) props.handleClick(props.name, search);
    };

    return (
      <div className="mapped-connection">
        <Spin
          style={{ position: 'absolute', top: '75px' }}
          size="small"
          spinning={false}
          //   spinning={isLoading || mappedLoading}
        >
          <div
            className="profile-box-header"
            style={{ borderBottom: '1px solid var(--color-light)' }}
            // onClick={() => handleViewAllClick(name)}
          >
            <span className="profile-titles">
              Ai Mapped Connections By Inferred Income and Wealth Segment
            </span>
          </div>
          <div className="chart-section">
            <PieChartSection
              data={preparePieChartData(salaryData)}
              id="inferred_salary_range"
              titleLabel="Inferred Salary Ranges"
              click={onGraphClick}
              isLoading={salaryLoading}
              reload={salaryReload}
              apiClick={fetchGraphData}
              loaderId="profile-pie-chart-loader"
            />
            <ChartSection
              data={prepareGraphData(wealthData)}
              titleLabel="Wealth Segment"
              id="wealth_estimate"
              click={onGraphClick}
              isLoading={wealthSegmentLoading}
              reload={wealthSegmentReload}
              apiClick={fetchGraphData}
              loaderId="mapped-wealth-loader"
            />
          </div>
        </Spin>
      </div>
    );
  }),
);

export default MappedConnectionsChart;
