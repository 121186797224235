import '@gouch/to-title-case';
import './index.scss';

import { inject, observer } from 'mobx-react';
import { Tooltip, Collapse } from 'antd';
import moment from 'moment';
import hash from 'object-hash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DownOutlined, CheckOutlined } from '@ant-design/icons';

import LinkedinIcon from '../../assets/icons/ai_linked_in.svg?react';
import WealthIcon from '../../assets/icons/ai_ic_wealth.svg?react';
import InfoIconNew from '../../assets/icons/ai_info.svg?react';
import IconCheck from '../../assets/icons/ai_ic_check.svg?react';
import IconClose from '../../assets/icons/ai_ic_close.svg?react';
import { getWealthScoreLabel, formatValue } from '../../utils/general';
import WorkSvg from './WorkSvg';

const { Panel } = Collapse;

function getPreviousDay() {
  const today = new Date();

  return new Date(today.setDate(today.getDate() - 1));
}

function getDate(date) {
  return moment(date).format('MM/DD/YYYY');
}

const WealthSegmentItems = ({ icon: Icon, content, status, marginLeft }) => {
  return (
    <div className="wealth-item-value">
      <Icon transform="scale(.7)" style={{ marginLeft: marginLeft || 0 }} />
      {content}
      <div>
        {status ? (
          <CheckOutlined
            style={{
              color: 'var(--color-green)',
              transform: 'scale(1.3)',
            }}
          />
        ) : (
          <span style={{ fontWeight: '800' }}>N/A</span>
        )}
      </div>
    </div>
  );
};

const Professional = inject('modalDataStore')(
  observer((props) => {
    const { person, showCompanyProfile } = props;

    const flags = useFlags();

    const getWorkAddress = (data, hashValue) => {
      const address1 = `${
        data?.work_address_line1
          ? data?.work_address_line1.toString().toLowerCase().toTitleCase()
          : ''
      } ${
        data?.work_address_line2
          ? data?.work_address_line2.toString().toLowerCase().toTitleCase()
          : ''
      }`.trim();
      const address2 = `${
        data?.work_city
          ? data?.work_city.toString().toLowerCase().toTitleCase()
          : ''
      } ${data?.work_state ? data?.work_state : ''} ${
        data?.work_postal_code ? data?.work_postal_code : ''
      }`.trim();
      return (
        <>
          {address1 && <div>{address1}</div>}
          {address2 && <div>{address2}</div>}
        </>
      );
    };

    const showWealthModalVisible = (e) => {
      e.stopPropagation();
      props.modalDataStore.wealthModalVisible = true;
    };

    function renderLinkedinView() {
      return (
        <div className="profile-boxes-right">
          {person.norm_linkedin_urls !== undefined &&
            person.norm_linkedin_urls.length > 0 && (
              <>
                View profile on:
                <Tooltip
                  placement="bottomRight"
                  color="var(--color-primary)"
                  title={`//${person.norm_linkedin_urls[0]}`}
                >
                  <a
                    href={`//${person.norm_linkedin_urls[0]}`}
                    style={{ color: 'var(--color-link)' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedinIcon style={{ width: 18, height: 18 }} />
                  </a>
                </Tooltip>
              </>
            )}
        </div>
      );
    }

    function renderCompanyTemplate(exp, verifiedDate) {
      let startDate, endDate;
      let carrierDate;
      if (exp.start_date && exp.end_date) {
        startDate = moment(exp.start_date).format('MMM YYYY');
        endDate = moment(exp.end_date).format('MMM YYYY');
        carrierDate = `${startDate} - ${endDate}`;
      } else if (exp.start_date) {
        startDate = moment(exp.start_date).format('MMM YYYY');
      }

      const hashValue = hash([exp.org_name, exp.title]);
      return (
        <div className="company-experience additional">
          {exp.org_details && exp.org_details.logo_url ? (
            <div className="org-logo">
              <a href={exp.org_details.url} target="_blank" rel="noreferrer">
                <img
                  src={exp.org_details.logo_url}
                  alt={exp.org_name}
                  className="company-logo"
                />
              </a>
            </div>
          ) : (
            <div className="org-logo">
              <WorkSvg svgId="ProfessionalWorkSvg" />
            </div>
          )}
          {(exp.org_name || getWorkAddress(exp)) && (
            <div>
              <div className="profile-labels">Company</div>
              {exp.org_name && (
                <div
                  className={
                    exp.ai_org_id
                      ? 'profile-value link'
                      : 'profile-value link-disabled'
                  }
                  onClick={() => {
                    if (!exp.ai_org_id) return;
                    showCompanyProfile(exp.ai_org_id, exp.org_name);
                  }}
                >
                  {exp.org_name.toTitleCase()}
                </div>
              )}
              <div className="value" style={{ fontSize: 14, fontWeight: 400 }}>
                {getWorkAddress(exp, hashValue)}
              </div>
            </div>
          )}
          {exp.title && (
            <div>
              <div className="profile-labels flex">
                Title
                {/* {verifiedDate && (
                  <Tooltip
                    color={'var(--color-white)'}
                    title={() => {
                      return (
                        <div className="tooltip-flex">
                          <VerifyIcon />
                          AI Inspected against two data sources on{' '}
                          {getDate(verifiedDate)}
                        </div>
                      );
                    }}
                  >
                    <VerifyIcon />
                  </Tooltip>
                )} */}
              </div>
              <div className="value" style={{ fontSize: 14 }}>
                {exp.title}
              </div>
            </div>
          )}
          {exp.start_date && (
            <div>
              <div className="profile-labels">
                {carrierDate ? 'Period' : 'Start Date'}
              </div>
              <div className="value" style={{ fontSize: 14 }}>
                {carrierDate ? carrierDate : startDate}
              </div>
            </div>
          )}
        </div>
      );
    }

    function renderWealthScoreLabel(score) {
      // const scoreLabels = getWealthScoreLabel(score).split(": ");
      return (
        <>
          <div className="value">{getWealthScoreLabel(score)}</div>
          {/* <div className='profile-value'>{scoreLabels[0]}</div> */}
        </>
      );
    }

    function renderWealthComponents() {
      if (
        !person.wealth_components ||
        Object.keys(person.wealth_components).length <= 0
      )
        return <></>;

      const included = [];
      const excluded = [];
      const wealthComponentLabels = {
        age: 'Age',
        career_info: 'Career Info',
        property_ownership: 'Property Ownership',
        property_value: 'Property Value',
        inferred_income: 'Inferred Income',
        zip4_tax_info: 'Zip-Based Info',
        remaining_share_balance: 'Equity Info',
        wealth_triggers: 'Wealth Triggers',
      };
      Object.entries(person.wealth_components).map(([key, value]) => {
        if (value) included.push(wealthComponentLabels[key]);
        else excluded.push(wealthComponentLabels[key]);
      });

      return (
        <>
          {(included.length > 0 || excluded.length > 0) && (
            <>
              <div className="border-class"></div>
              <div></div>

              <div
                className="wealth-components-header"
                style={{ fontSize: 11, fontWeight: 600, color: '#949AA8' }}
              >
                Wealth & Income Components
                <InfoIconNew
                  style={{ height: 16 }}
                  className="info-icon"
                  onClick={showWealthModalVisible}
                />
              </div>
              {included.length > 0 && (
                <div className="profile-labels" style={{ fontSize: 11 }}>
                  <IconCheck
                    style={{ marginBottom: '-2px', marginRight: '4px' }}
                  />{' '}
                  Included
                </div>
              )}
              <div className="included-components profile-labels">
                {included.map((component) => {
                  return <div>{component}</div>;
                })}
              </div>
              {excluded.length > 0 && (
                <div className="profile-labels" style={{ fontSize: 11 }}>
                  <IconClose
                    style={{
                      marginBottom: '-4px',
                      marginRight: '4px',
                      color: '#91AFCA',
                    }}
                  />{' '}
                  Excluded
                </div>
              )}
              <div className="excluded-components profile-labels">
                {excluded.map((component) => {
                  return <div>{component}</div>;
                })}
              </div>
            </>
          )}
        </>
      );
    }
    return (
      <>
        <div style={{ width: '100%' }}>
          <div className="profile-boxes">
            <div className="profile-box-header">
              <div className="profile-titles">Professional Info</div>
              {renderLinkedinView()}
            </div>
            <div className="border" />
            <div className="profile-box-body" style={{ padding: 0 }}>
              {person.current_experience ? (
                person.current_experience.map((exp) => {
                  return renderCompanyTemplate(
                    exp,
                    person.current_position_verified_on,
                  );
                })
              ) : (
                <div className="no-data">No Professional info available </div>
              )}

              {((person.wealth_estimate !== undefined &&
                person.wealth_estimate !== null &&
                person.wealth_estimate >= 0) ||
                person.inferred_salary_range ||
                person.remaining_share_balance) && (
                <div className="wealth-section-parent">
                  <div className="wealth-section-area">
                    <div className="wealth-icon">
                      <WealthIcon />
                    </div>
                    {person.wealth_estimate && (
                      <div>
                        <div
                          className="profile-labels "
                          style={{ marginBottom: 10 }}
                        >
                          Wealth Segment
                        </div>
                        <div className="value flex">
                          {getWealthScoreLabel(person.wealth_estimate)}
                        </div>
                      </div>
                    )}

                    {person.inferred_salary_range && (
                      <div>
                        <div
                          className="profile-labels "
                          style={{ marginBottom: 10 }}
                        >
                          Inferred Income
                        </div>
                        <div className="value">
                          {person.inferred_salary_range}
                        </div>
                      </div>
                    )}

                    {person.remaining_share_balance &&
                      person.remaining_share_balance.length > 0 &&
                      person.remaining_share_balance.filter((item) => {
                        return item.remaining_share_balance !== 0;
                      }).length > 0 && (
                        <div>
                          <div
                            className="profile-labels"
                            style={{ marginBottom: 10 }}
                          >
                            Share Balance(s)
                          </div>
                          <div className="remaining-share-value-section">
                            {person.remaining_share_balance.map((value) => {
                              if (!value || !value.remaining_share_balance)
                                return <> </>;
                              return (
                                <div className="value remaining-share-value">
                                  {`${
                                    value.remaining_share_balance
                                      ? formatValue(
                                          value.remaining_share_balance,
                                        )
                                      : '$0'
                                  } ${
                                    value.stock_ticker
                                      ? 'of ' + value.stock_ticker
                                      : ''
                                  }`}
                                </div>
                              );
                            })}
                          </div>
                          {/* {person.remaining_share_verified_on &&
                 <div className='tooltip-flex' style={{ padding: '0px 0px 20px 20px' }}>
                  <VerifyIcon />
                   {`AI Inspected ${moment(person.remaining_share_verified_on).format('MM/DD/YYYY')}`}
                 </div>
               } */}
                        </div>
                      )}

                    {renderWealthComponents()}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Career History */}
          {person.previous_experience &&
            person.previous_experience.length > 0 && (
              <div className="career-history profile-boxes">
                <Collapse
                  className="career-history-collapse"
                  expandIconPosition="end"
                  expandIcon={({ isActive }) => (
                    <DownOutlined
                      style={{ color: 'var(--color-dark-shade)' }}
                      rotate={isActive ? 180 : 0}
                    />
                  )}
                >
                  <Panel
                    header={
                      <div className="profile-box-header">
                        <div className="profile-titles">Career History</div>
                        {/* <span>{renderLinkedinView()}</span> */}
                      </div>
                    }
                  >
                    <div
                      className="profile-box-body"
                      style={{
                        padding: 0,
                        backgroundColor: 'var(--color-history)',
                        borderRadius: '0 0 6px 6px',
                      }}
                    >
                      {person.previous_experience.map((exp) => {
                        return renderCompanyTemplate(
                          exp,
                          person.career_history_verified_on,
                        );
                      })}
                    </div>
                  </Panel>
                </Collapse>
              </div>
            )}
        </div>
      </>
    );
  }),
);

export default Professional;
