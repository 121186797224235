import './index.css';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router';
import { inject, observer } from 'mobx-react';

import { getDomain } from '../../utils/general';

const Exception = inject('dataStore')(
  observer((props) => {
    const [errorCode, setErrorCode] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [redirectUrl, setRedirectUrl] = useState('');
    const [buttonTxt, setButtonTxt] = useState('Back to Home');

    const location = useLocation();
    const { logout } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
      let code = 404;
      let msg = 'Sorry, the page you visited does not exist';
      let redirectUrl = null;
      if (location.pathname.split('/')[2] === 'session-expired') {
        code = '';
        msg = 'Your session has expired, click here to log in again';
        setButtonTxt('Login');
      }

      if (location.pathname.split('/')[1] === 'exception') {
        if (
          location.pathname.split('/')[2] &&
          location.pathname.split('/')[2] !== 'undefined'
        )
          code = location.pathname.split('/')[2];
        msg = 'Sorry, the server is reporting an error';

        if (location && location.state && location.state.redirectUrl) {
          redirectUrl = location.state.redirectUrl;
        }

        if (location && location.state && location.state.errorMessage) {
          msg = location.state.errorMessage;
          window.history.replaceState(null, null, window.location.pathname);
        }
      }

      setErrorCode(code);
      setErrorMsg(msg);
      setRedirectUrl(redirectUrl);
    }, []);

    return (
      <div className="page-not-found-container">
        <div className="main">
          <div className={'left' + (errorCode ? '' : ' no-error-code')}>
            <img
              src={getComputedStyle(document.body).getPropertyValue(
                '--url-logo',
              )}
              alt="logo"
              className="logo"
            />
          </div>
          <div className={'right' + (errorCode ? '' : ' no-error-code')}>
            {errorCode && <h1>{errorCode}</h1>}

            <div className="not-found-subtext">{errorMsg}</div>

            <button
              type="submit"
              className="not-found-button"
              onClick={(e) => {
                e.stopPropagation();
                if (buttonTxt === 'Login') {
                  logout({
                    returnTo: `${getDomain()}/user/login`,
                  });
                } else if (redirectUrl) {
                  props.dataStore.reset();
                  logout({
                    returnTo: `${getDomain()}${redirectUrl}`,
                  });
                } else {
                  navigate('/');
                }
              }}
            >
              <div className="link">{buttonTxt}</div>
            </button>
          </div>
        </div>
      </div>
    );
  }),
);

export default Exception;
