import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
import moment from 'moment';
import { Table, Collapse, Button, Modal, message } from 'antd';
import { CaretRightFilled } from '@ant-design/icons';

import DownloadIcon from '../../assets/icons/download_icon.svg';
import deleteContact from '../../assets/icons/ai_delete_bin.svg';

import { requestWithAuth } from '../../services/api';
import { getSourceName, splitNumberWithCommas } from '../../utils/general';
import { showConfirm } from '../../utils/popup';
import ExportHeaderOptionsNew from '../../components/ExportHeaderOptionsNew';
import LoaderAnimation from '../../components/AidLoader';

let source;
const sortInfo = {
  column: '',
  order: '',
};

message.config({
  top: 80,
});

const { Panel } = Collapse;

const defaultHeadersOptions = [
  'First Name',
  'Last Name',
  'Current Company Name',
  'Current Company Title',
  'Inferred Income',
  'Wealth Segment',
  'Personal Email',
  'Professional Email',
  'Mobile Phone',
  'Home Address Line',
];

const ImportList = (props) => {
  const { userType, credit, isOpen } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 20,
    current: 1,
  });
  const [saveLaterHeader, setSaveLaterHeader] = useState(true);
  const [showExportHeader, setShowExportHeader] = useState(false);
  const [showCreditCheck, setShowCreditCheck] = useState(false);
  // const [ordering, setOrdering] = useState({});
  // const [isImported, setIsImported] = useState(false);
  // const [isFilterApplied, setIsFilterApplied] = useState(false);
  // const [isImportClicked, setIsImportClicked] = useState(false);
  // const [params, setParams] = useState({});
  const [importList, setImportList] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState({ impIds: [], expIds: [] });
  const location = useLocation();
  const navigate = useNavigate();
  const flags = useFlags();
  const [exportHeadersList, setExportHeadersList] = useState(
    defaultHeadersOptions,
  );

  useEffect(() => {
    return () => {
      if (source) source.cancel('Operation canceled by the user.');
    };
  }, []);

  useEffect(() => {
    if (location && location.state && location.state.refreshStatus) {
      fetchImportData();
      window.history.replaceState(null, null, window.location.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (listPagination.total === 0) return;
    fetchImportData();
  }, [listPagination.pageSize, listPagination.current]);
  const checkElementVisibility = () => {
    if (
      document.getElementById('mymportTableContainer') &&
      document.getElementById('import-options') &&
      document.getElementById('export-options')
    ) {
      fetchImportData();
    }
  };

  useEffect(() => {
    if (isOpen) fetchImportData();
    const intervalId = setInterval(checkElementVisibility, 3 * 60 * 1000);
    if (!isOpen) clearInterval(intervalId);
    return () => clearInterval(intervalId);
  }, [isOpen]);

  const onSaveLaterChange = (e) => {
    setSaveLaterHeader(e.target.checked);
  };

  const onExportHeaderChange = (e) => {
    setExportHeadersList(e);
  };

  const fetchImportData = () => {
    const { CancelToken } = axios;
    source = CancelToken.source();
    setIsLoading(true);

    const params = {
      page_size: listPagination.pageSize,
      page: listPagination.current,
      // ...dateFilter,
    };
    if (sortInfo.column && sortInfo.order) {
      params.sort_key = sortInfo.column;
      params.sort_order = sortInfo.order;
    }

    requestWithAuth(
      'contact-import-export',
      'GET',
      params,
      null,
      source.token,
    ).then((response) => {
      if (!response) return;

      setIsLoading(false);

      setListPagination((prevState) => ({
        ...prevState,
        total: response.count > 1000 ? 1000 : response.count,
      }));

      setImportList(response.results);
      // if (response.results && response.results.length > 0)
      //   // myImportDetails.isImported = true;
      //   setIsImported(true);
    });
  };

  const getConfirmationmessage = () => {
    if (
      (exportHeadersList.indexOf('Degree') > -1 ||
        exportHeadersList.indexOf('Degree of Connection') > -1) &&
      exportHeadersList.indexOf('Household Member') > -1
    )
      return 'Please confirm Household Member selection and 2nd Degree Relationships selection. Credits will be deducted for Household Members included and file processing time is longer';
    if (
      exportHeadersList.indexOf('Degree') > -1 ||
      exportHeadersList.indexOf('Degree of Connection') > -1
    )
      return 'Please confirm Degree Relationships selection. File processing time is longer';
    if (exportHeadersList.indexOf('Household Member') > -1)
      return 'Please confirm Household Member Selection. Credits will be deducted for Household Members included.';

    return 'Please confirm Import';
  };

  const handlePrepareDownload = (id, name) => {
    setIsLoading(true);
    if (!exportHeadersList.includes('First Name'))
      exportHeadersList.push('First Name');

    if (!exportHeadersList.includes('Last Name'))
      exportHeadersList.push('Last Name');

    if (saveLaterHeader && exportHeadersList && exportHeadersList.length > 0) {
      // if (
      //   flags &&
      //   flags.exportHeaderConfirmation &&
      //   exportHeadersList.indexOf('Degree') > -1 &&
      //   exportHeadersList.indexOf('Relationship Paths') < 0
      // )
      //   exportHeadersList.push('Relationship Paths');

      const body = {
        export_headers: exportHeadersList,
      };
      requestWithAuth('user-export-headers', 'POST', null, body, null);
    }

    requestWithAuth(
      'process-import-file',
      'POST',
      null,
      {
        import_id: id,
        export_headers: exportHeadersList,
      },
      null,
    ).then((response) => {
      // if (!response) return;
      if (response) {
        importList.map((item) => {
          if (item.import_id === id) item.import_status = 'Preparing File';
        });
        setImportList(importList);
      }
      setIsLoading(false);
      setShowExportHeader(false);
    });
  };
  const downloadCsv = (id, name) => {
    setIsLoading(true);
    const body = { export_id: id };
    requestWithAuth('download-csv-url', 'POST', null, body, source.token).then(
      (response) => {
        setIsLoading(false);
        if (!response) return;
        if (response.code === 200) {
          const link = document.createElement('a');
          link.href = response.url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else if (response.code === 400) {
          if (response.charge_credits && response.user_credits) {
            showConfirm({
              className: 'small-popup',
              onOk: handleSubscribeRedirect,
              title: 'Confirm',
              content: (
                <>
                  This export requires {response.charge_credits} credits, which
                  exceeds your current balance of {response.user_credits}{' '}
                  {response.user_credits === 1 ? 'credit' : 'credits'}. Please
                  contact Customer Support or purchase additional credits{' '}
                  <a href="/account/credits">here.</a>{' '}
                </>
              ),
              closable: true,
              okText: 'Buy More',
            });
          }
        }
        fetchImportData();
      },
    );
  };

  const creditCheck = (id, name, credit_charged) => {
    /*** if credit charged is greater than 0 and user is not admin then show the pop up***/
    if (credit_charged && credit_charged !== 0 && userType !== 'admin') {
      showCreditPopUp(id, name, credit_charged);
    } else downloadCsv(id, name);
  };

  const onPrepareDownload = (id, name, credit_charged) => {
    prepareFile = {
      id,
      name,
      creditCharged: credit_charged,
    };
    setShowExportHeader(true);
  };

  const showCreditCheckPopup = () => {
    let confirmText;
    // if (!prepareFile.creditCharged) return;
    if (!prepareFile.creditCharged || credit >= prepareFile.creditCharged) {
      confirmText = (
        <div>
          This download requires an estimated{' '}
          {splitNumberWithCommas(prepareFile.creditCharged)}{' '}
          {prepareFile.creditCharged === 1 ? ' credit ' : ' credits '}
          out of your remaining {splitNumberWithCommas(credit)} credits. The
          actual number of credits will be deducted when your file is prepared
          (not to exceed estimated).
        </div>
      );
      showConfirm({
        className: 'small-popup',
        onOk: () => handlePrepareDownload(prepareFile.id, prepareFile.name),
        title: 'Prepare',
        content: confirmText,
        closable: true,
        okText: 'Prepare',
      });
    } else {
      confirmText = (
        <div>
          This download requires an estimated{' '}
          {splitNumberWithCommas(prepareFile.creditCharged)}{' '}
          {prepareFile.creditCharged === 1 ? ' credit ' : ' credits '}
          out of your remaining {splitNumberWithCommas(credit)} credits. (
          {splitNumberWithCommas(prepareFile.creditCharged - credit)} additional
          credits required). The actual number of credits will be deducted when
          your file is prepared (not to exceed estimated).
        </div>
      );
      showConfirm({
        className: 'small-popup',
        onOk: handleSubscribeRedirect,
        title: 'Prepare',
        content: confirmText,
        closable: true,
        okText: 'Purchase Credits',
      });
    }
  };

  const showCreditPopUp = (id, name, creditCharged) => {
    let confirmText;
    if (creditCharged && credit >= creditCharged) {
      confirmText = (
        <div>
          To download this file, {splitNumberWithCommas(creditCharged)}{' '}
          {creditCharged === 1 ? ' credit ' : ' credits '}
          will be deducted from your account. <br />
          Current Credit Balance: {credit}
        </div>
      );
      showConfirm({
        className: 'small-popup',
        onOk: () => downloadCsv(id, name),
        title: 'Confirm',
        content: confirmText,
        closable: true,
        okText: 'Proceed',
      });
    } else {
      confirmText = (
        <div>
          To download this file, {splitNumberWithCommas(creditCharged)}
          {creditCharged === 1 ? ' credit is ' : ' credits are '}
          required. <br />
          Current Credit Balance: {credit}
        </div>
      );
      showConfirm({
        className: 'small-popup',
        onOk: handleSubscribeRedirect,
        title: 'Confirm',
        content: confirmText,
        closable: true,
        okText: 'Buy More',
      });
    }
  };

  const handleTableChange = (pagination, filtersArg, sorter) => {
    if (sorter && sorter.order) {
      sortInfo.column = sorter.field;
      sortInfo.order = sorter.order === 'ascend' ? 'asc' : 'desc';
    } else {
      sortInfo.column = '';
      sortInfo.order = '';
    }

    if (
      pagination.pageSize === listPagination.pageSize &&
      pagination.current === listPagination.current
    ) {
      fetchImportData();
    }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
    // const filters = Object.keys(filtersArg).reduce((obj, key) => {
    //   const newObj = { ...obj };
    //   let value = '';
    //   if (filtersArg[key] != null) value = getValue(filtersArg[key]);
    //   if (key === 'created') {
    //     if (value !== null && value !== '' && value !== 'all') {
    //       if (value === '2020') {
    //         newObj.date_filter = 'year';
    //         newObj.no_of_years = 1;
    //       } else {
    //         newObj.date_filter = value;
    //       }
    //     }
    //   }
    //   return newObj;
    // }, {});

    // myImportDetails.size = pagination.pageSize;
    // myImportDetails.currentPage = pagination.current;
    // setIsFilterApplied(true);

    // myImportDetails.isFilterApplied = true;
    // let ordering;
    // if (sorter.field) {
    //   ordering = `${sorter.order === 'descend' ? '-' : ''}${sorter.field}`;
    // }
    // setOrdering(
    //   sorter.order
    //     ? {
    //         order: sorter.order === 'descend' ? 'desc' : 'asc',
    //         key: sorter.field,
    //       }
    //     : {},
    // );
  };

  const rowSelection = {
    columnWidth: '60px',
    columnTitle: (
      <div
        className="delete-icon-container"
        onClick={() =>
          handleDeleteClick(selectedKeys.impIds, selectedKeys.expIds, true)
        }
      >
        <img
          src={deleteContact}
          alt="Delete "
          style={{
            width: '23px',
            height: '18px',
            cursor: 'pointer',
          }}
        />
      </div>
    ),
    getCheckboxProps: (record) => {
      return {
        disabled: !checkForDelete(record),
      };
    },
    onChange: (selectedRowKeys, selectedRows) => {
      const imports = [];
      const exports = [];
      selectedRows.map((row) => {
        if (row.type == 'Import') imports.push(row.import_id);
        else if (row.type == 'Export') exports.push(row.export_id);
      });
      setSelectedKeys({ impIds: imports, expIds: exports });
    },
  };

  const handleDeleteClick = (impId, expId, multiDelete) => {
    if (
      multiDelete &&
      selectedKeys.impIds.length === 0 &&
      selectedKeys.expIds.length === 0
    )
      return;
    showConfirm({
      className: 'small-popup',
      closable: true,
      onOk: () => {
        handleOk(impId, expId, multiDelete);
      },
      title: 'Confirm Delete',
      okText: 'Yes',
      content: (
        <p>
          Are you sure you want to delete the selected file(s)? This can not be
          undone.
        </p>
      ),
    });
  };

  const checkForDelete = (record) => {
    const statusArray = ['not started', 'done', 'error'];
    const { import_id, export_id, import_status, export_status } = record;
    if (import_id && !export_id) {
      if (statusArray.includes(import_status)) return true;
    } else if (import_id && export_id) {
      if (['not started', 'error'].includes(import_status)) return true;
      if (import_status === 'done' && statusArray.includes(export_status))
        return true;
    } else if (export_id) {
      if (statusArray.includes(export_status)) return true;
    }
    return false;
  };

  const renderStatus = (record) => {
    const { export_id, import_id, export_status, import_status } = record;
    /** if only export_id => export csv
        if only import_id => imports like linkedin, icloud etc
        if both => import csv
      */
    if (
      export_status &&
      export_id &&
      export_status !== 'done' &&
      export_status !== 'error'
    )
      return 'Preparing File';

    if (import_status && import_id && export_id)
      return import_status.toString().toTitleCase();

    if (export_status && export_id)
      return export_status.toString().toTitleCase();

    if (import_status && import_id)
      return import_status.toString().toTitleCase();
    return '';
  };

  const handleOk = (impId, expId, multiDelete) => {
    setIsLoading(true);
    const body = {};
    let importIds, exportIds;
    if (multiDelete) {
      /** if multiple delete, impId & expId will be arrays, otherwise single ids */
      if (impId && impId.length > 0) importIds = impId;
      if (expId && expId.length > 0) exportIds = expId;
    } else {
      if (impId) importIds = [impId];
      if (expId) exportIds = [expId];
    }
    body.import_id = importIds;
    body.export_id = exportIds;

    requestWithAuth(
      `import-export-delete`,
      'POST',
      null,
      body,
      source.token,
    ).then((response) => {
      // fetchImportData();
      /** Now deleting Contact Import(csv import) is a background process,
       * so update the list manually
       */
      const list = importList.filter((item) => {
        return (
          (importIds &&
            importIds.length &&
            item.import_id &&
            !importIds.includes(item.import_id)) ||
          (exportIds &&
            exportIds.length &&
            item.export_id &&
            !exportIds.includes(item.export_id))
        );
      });
      setImportList(list);
      setIsLoading(false);
    });
    /** clear selectedKeys after deletion to prevent popup on
     * multiple-delete click without selecting any row*/
    setSelectedKeys({ impIds: [], expIds: [] });
  };

  const handleSave = (row) => {
    let importName = {};
    importName = {
      import_name: row.import_name,
    };

    setIsLoading(true);

    requestWithAuth(
      `contact-imports/${row.id}`,
      'PUT',
      null,
      importName,
      source.token,
    ).then((response) => {
      fetchImportData();
    });
  };

  const paginationProps = {
    ...listPagination,
  };

  const handleSubscribeRedirect = () => {
    navigate('/account/credits');
  };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      width: 90,
      render: (val) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
            }}
          >
            {val ? val.toString().toTitleCase() : ''}
          </div>
        );
      },
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // className: 'status-column-header',
      width: 110,
      render: (val, record) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              textAlign: 'left',
            }}
          >
            {renderStatus(record)}
          </div>
        );
      },
      // sorter: true,
      // sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Date',
      dataIndex: 'created',
      key: 'created',
      width: 120,
      render: (val) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
            }}
          >
            <div>{moment(val).format('MM/DD/YYYY')}</div>
          </div>
        );
      },
      // filters: [
      //   {
      //     text: 'One Week',
      //     value: 'week',
      //   },
      //   {
      //     text: 'One Month',
      //     value: 'month',
      //   },
      //   {
      //     text: 'YTD',
      //     value: 'year',
      //   },
      //   {
      //     text: '2020',
      //     value: '2020',
      //   },
      //   {
      //     text: 'All',
      //     value: 'all',
      //   },
      // ],
      // filterMultiple: false,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 180,
      editable: true,
      render: (val) => {
        return (
          <div>
            <span>{val}</span>
          </div>
        );
      },
    },
    {
      title: 'No of Contacts',
      dataIndex: 'no_of_contacts',
      align: 'right',
      width: 150,
      render: (val) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
            }}
          >
            {val && val.toLocaleString()}
          </div>
        );
      },
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Matched',
      dataIndex: 'matched_record_count',
      align: 'right',
      width: 95,
      render: (val, rec) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
            }}
          >
            {(val && val.toLocaleString()) || (rec.type === 'Import' ? 0 : '-')}
          </div>
        );
      },
    },
    {
      title: 'Match Rate',
      dataIndex: '',
      align: 'right',
      width: 95,
      render: (val, rec) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
            }}
          >
            {(rec['matched_record_count'] &&
              rec['no_of_contacts'] &&
              Math.round(
                (parseInt(rec['matched_record_count']) /
                  parseInt(rec['no_of_contacts'])) *
                  100,
              ) + '%') ||
              (rec.type === 'Import' ? 0 : '-')}
          </div>
        );
      },
    },
    {
      title: 'Source',
      dataIndex: 'source',
      width: 150,
      render: (val, record) => {
        if (record.type === 'export') return <div>-</div>;
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
              textAlign: 'left',
            }}
          >
            {getSourceName(val) === 'Data Enrichment'
              ? 'Application'
              : getSourceName(val)}
          </div>
        );
      },
    },
    {
      title: 'File',
      dataIndex: 'name',
      width: 120,
      render: (val, record) => {
        if (record.source === 'aidentified') {
          return (
            <span
              style={{
                color: 'var(--color-link)',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(`/prospect-finder/`, {
                  state: {
                    fromPage: 'Status',
                  },
                });
              }}
            >
              View Relationships
            </span>
          );
        }
        if (
          record.import_id &&
          record.import_status === 'done' &&
          (!record.export_status || record.export_status === 'not started') &&
          userType !== 'lite' &&
          userType !== 'inactive' &&
          (record.source.toLowerCase() === 'csv' ||
            record.source === 'Data Enrichment')
        ) {
          return (
            <Button
              type="primary"
              onClick={() => {
                onPrepareDownload(record.import_id, val, record.charge_credits);
              }}
            >
              Prepare Download
            </Button>
          );
        }
        if (
          record.export_id &&
          record.import_id &&
          record.export_status !== 'done'
        )
          return (
            <div>
              {record.export_status
                ? record.export_status.toString().toTitleCase()
                : ''}
            </div>
          );

        return (
          <div
            style={{
              width: '100%',
              // wordBreak: 'break-word',
              display: 'block',
            }}
          >
            {val &&
            record.export_status === 'done' &&
            record.source &&
            userType !== 'lite' &&
            userType !== 'inactive' &&
            (['csv', 'xlsx'].includes(record.source.toLowerCase()) ||
              record.source === 'Data Enrichment') ? (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  //downloadCsv(record.export_id, val);
                  creditCheck(record.export_id, val, record.charge_credits);
                }}
              >
                <img src={DownloadIcon} style={{ width: '14px' }} />
                <span
                  style={{ color: 'var(--color-link)', paddingLeft: '5px' }}
                >
                  Download
                </span>
              </div>
            ) : (
              '-'
            )}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'delete_icon',
      align: 'center',
      render: (val, record) => {
        return checkForDelete(record) ? (
          <div className="delete-main-container">
            <div
              className="delete-icon-container"
              onClick={() =>
                handleDeleteClick(record.import_id, record.export_id, false)
              }
            >
              <img
                src={deleteContact}
                alt="Delete "
                style={{
                  width: '23px',
                  height: '18px',
                  cursor: 'pointer',
                }}
              />{' '}
            </div>
          </div>
        ) : null;
      },
    },
    Table.SELECTION_COLUMN,
  ];

  const editableColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,

        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div className="loader-wrapper">
      {isLoading && <LoaderAnimation loaderId="status-loader" />}
      <div className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
        <div id="mymportTableContainer">
          {/* {importList && importList.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : ( */}
          <Table
            bordered={true}
            rowSelection={rowSelection}
            className={'myImportTableNew'}
            columns={editableColumns}
            dataSource={importList}
            // components={components}
            // rowClassName={() => 'editable-row'}
            rowKey={(rec) => `${rec.type}${rec.export_id || rec.import_id}`}
            // rowKey="id"
            showSorterTooltip={false}
            pagination={paginationProps}
            pageSize={listPagination.pageSize}
            // pagination={this.myImportDetails.pagination}
            onChange={handleTableChange}
            scroll={{ x: 'max-content', y: 600 }}
          />
        </div>
        <div>
          {showExportHeader && (
            <Modal
              width={'75%'}
              centered
              className="export_headers_modal"
              open={showExportHeader}
              onCancel={() => setShowExportHeader(false)}
              footer={false}
            >
              <ExportHeaderOptionsNew
                onChange={onExportHeaderChange}
                exportHeadersList={exportHeadersList}
                saveLaterHeader={saveLaterHeader}
                onSaveLaterChange={onSaveLaterChange}
              />
              <div style={{ textAlign: 'right' }}>
                <Button
                  type="primary"
                  onClick={
                    /** even though the header name 'Degree'
                     * was renamed to 'Degree of Connection',
                     * header name in older exports will still
                     *  be 'Degree'. So existence of both
                     * names are checked here
                     */
                    exportHeadersList.indexOf('Degree') > -1 ||
                    exportHeadersList.indexOf('Degree of Connection') > -1 ||
                    exportHeadersList.indexOf('Household Member') > -1
                      ? () => {
                          showConfirm({
                            className: 'small-popup',
                            closable: true,
                            content: <>{getConfirmationmessage()}</>,
                            okText: 'Yes',
                            onOk: () => {
                              //showCreditCheckPopup();
                              handlePrepareDownload(
                                prepareFile.id,
                                prepareFile.name,
                              );
                            },
                          });
                        }
                      : // : showCreditCheckPopup
                        () =>
                          handlePrepareDownload(
                            prepareFile.id,
                            prepareFile.name,
                          )
                  }
                >
                  Continue
                </Button>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

let prepareFile = {};

const Status = inject('dataStore')(
  observer((props) => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const flags = useFlags();

    useEffect(() => {
      if (location && location.state && location.state.expandStatus) {
        setIsOpen(true);
      }
    }, [location]);

    return (
      <div id="status" className="import-export-status-container">
        <Collapse
          activeKey={isOpen ? '1' : []}
          onChange={() => setIsOpen((prevState) => !prevState)}
          expandIcon={({ isActive }) => (
            <CaretRightFilled
              style={{ color: 'var(--color-column)', fontSize: '16px' }}
              rotate={isActive ? 90 : 0}
            />
          )}
        >
          <Panel
            header="Status of Import/Export"
            key="1"
            extra={
              <Button type="primary">
                <div>{isOpen ? 'Close' : 'Click to Open'}</div>
              </Button>
            }
          >
            <ImportList
              userType={props.dataStore.currentUser.type}
              credit={props.dataStore.currentUser.creditCount}
              isOpen={isOpen}
            />
          </Panel>
        </Collapse>
      </div>
    );
  }),
);

export default Status;
