import './index.scss';

import { useState } from 'react';

import { Radio, Table, Empty } from 'antd';
import CardsIcon from '../../assets/icons/ai_cards.svg?react';
import ListIcon from '../../assets/icons/ai_list.svg?react';

const CardsAndTable = (props) => {
  const { columns, cardColumns, list, cardFunction, title } = props;
  const [displayType, setDisplayType] = useState('cards');
  const [tableList, setTableList] = useState(list || []);
  const displayArray = [
    {
      label: 'Cards',
      value: 'cards',
      icon: <CardsIcon className="toggle-icon" />,
    },
    {
      label: 'List',
      value: 'list',
      icon: <ListIcon className="toggle-icon" />,
    },
  ];
  return (
    <div className="card-table-section">
      <div className="card-table-section-header">
        <div className="title">
          {title}{' '}
          <span style={{ color: 'var(--color-column)' }}>
            {tableList.length}
          </span>
        </div>
        <div className="display-buttons">
          <Radio.Group
            onChange={(e) => {
              setDisplayType(e.target.value);
            }}
            value={displayType}
          >
            {displayArray.map((display) => {
              return (
                <Radio.Button value={display.value}>
                  {display.icon} {display.label}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </div>
      </div>
      <div>
        {displayType === 'list' ? (
          <Table
            rowKey={(obj) => obj.event_id}
            style={{ marginTop: '20px' }}
            dataSource={props.list}
            columns={columns}
            pagination={false}
            scroll={{ x: 'max-content', y: 350 }}
            summary={(currentData) => {
              if (JSON.stringify(tableList) !== JSON.stringify(currentData))
                setTableList(currentData);
            }}
            showSorterTooltip={false}
          />
        ) : (
          <div className="cards-section">
            <Table
              rowKey={(obj) => obj.event_id}
              style={{ marginTop: '20px' }}
              dataSource={props.list}
              columns={cardColumns}
              pagination={false}
              scroll={{ x: 'max-content', y: 350 }}
              showSorterTooltip={false}
              summary={(currentData) => {
                if (JSON.stringify(tableList) !== JSON.stringify(currentData))
                  setTableList(currentData);
              }}
            />

            {
              // showMore?
              tableList && tableList.length > 0 ? (
                tableList.map((listItem) => {
                  return <> {cardFunction(listItem)}</>;
                })
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
              //   : tableList.slice(0, 3).map((listItem) => {
              //       return <EventsCard listItem={listItem} />;
              //     })
            }

            {/* <Table
                  rowKey={(obj) => obj.event_id}
                  style={{ marginTop: '20px' }}
                  dataSource={list}
                  columns={columns}
                  pagination={false}
                  scroll={{ x: 'max-content', y: 350 }}
                /> */}
          </div>
        )}
      </div>
      {/* {tableList.length > 3 && (
            <>
              {showMore ? (
                <div
                  className="add-more-section"
                  onClick={() => {
                    setShowMore(false);
                  }}
                >
                  {' '}
                  Collapse Content
                </div>
              ) : (
                <div
                  className="add-more-section"
                  onClick={() => {
                    setShowMore(true);
                  }}
                >
                  Show {tableList.length - 3} more
                </div>
              )}
            </>
          )} */}
    </div>
  );
};

export default CardsAndTable;
