import './index.css';

import { Component } from 'react';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { commarize, splitNumberWithCommas } from '../../utils/general';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);
class HorizontalLineChart extends Component {
  render() {
    const { data, id, click } = this.props;

    const options = {
      indexAxis: 'y',
      // layout: {
      //   padding: {
      //     left: 0,
      //     right: 30,
      //   },
      // },
      // responsive: true,
      // cornerRadius: 8,
      maintainAspectRatio: false,
      maxBarThickness: 6,
      hoverBackgroundColor: getComputedStyle(document.body).getPropertyValue(
        '--color-secondary',
      ),
      interaction: {
        mode: 'index',
        axis: 'y',
      },
      plugins: {
        tooltip: {
          backgroundColor: getComputedStyle(document.body).getPropertyValue(
            '--color-white',
          ),
          titleColor: getComputedStyle(document.body).getPropertyValue(
            '--color-black',
          ),
          bodyColor: getComputedStyle(document.body).getPropertyValue(
            '--color-black',
          ),
          borderColor: getComputedStyle(document.body).getPropertyValue(
            '--color-light',
          ),
          borderWidth: 1,
          caretPadding: 10,
          padding: 10,
          xAlign: 'center',
          yAlign: 'top',
          callbacks: {
            label: function (context) {
              return splitNumberWithCommas(Number(context.parsed.x));
            },
            labelColor: function (context) {
              return {
                borderColor: getComputedStyle(document.body).getPropertyValue(
                  '--color-light',
                ),
                backgroundColor: context.element.options.backgroundColor,
                borderWidth: 2,
                // borderDash: [2, 2],
                borderRadius: 2,
              };
            },
          },
        },
        legend: {
          display: false,
        },
        datalabels: {
          color: getComputedStyle(document.body).getPropertyValue(
            '--color-primary',
          ),

          labels: {
            l1: {
              color: getComputedStyle(document.body).getPropertyValue(
                '--color-dark-shade',
              ),
              formatter: (value, context) => {
                if (value) return commarize(value);
              },
            },
          },
          align: 'start',
          anchor: 'start',
          font: {
            size: 12,
          },
        },
      },

      onClick(e, chartElement) {
        if (!chartElement || chartElement.length <= 0) return; // Clicked outside any bar.
        if (
          id === 'current_experience.ai_org_id' ||
          id === 'previous_experience.ai_org_id'
        )
          click(
            id,
            data.labels[chartElement[0].index],
            data.keyLabels[chartElement[0].index],
          );
        else click(id, data.labels[chartElement[0].index]);
      },
      onHover: function (event, legendItem, legend) {
        event.chart.canvas.parentNode.style.cursor = legendItem[0]
          ? 'pointer'
          : 'default';
      },
      scales: {
        x: {
          display: false,
          grid: {
            display: false,
          },
        },

        y: {
          ticks: {
            callback: function (val, index) {
              const tickVal = this.getLabelForValue(val);
              if (tickVal.length > 25) return `${tickVal.substr(0, 25)}...`; // truncate
              return tickVal;
            },
            color: getComputedStyle(document.body).getPropertyValue(
              '--color-primary',
            ),
            font: {
              size: 12,
            },
            padding: 45,
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
    };

    let chartHeight = 250;
    if (data && data.labels) {
      const numOfBars = data.labels.length;
      // chartHeight = numOfBars > 10 ? (300 * numOfBars) / 10 : 300;
      chartHeight = numOfBars > 5 ? 50 * numOfBars : 250;
    }

    const emptyData = {
      labels: [],
      datasets: [],
    };

    return (
      <div className="chartWrapper">
        <div
          // className="chartAreaWrapper"
          style={{
            height: chartHeight,
            // paddingTop: '31px',
          }}
        >
          <Bar
            data={this.props.data.datasets ? this.props.data : emptyData}
            options={options}
          />
        </div>
      </div>
    );
  }
}
export default HorizontalLineChart;
