import './index.scss';

import { Button, message } from 'antd';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import { useNavigate } from 'react-router';
import FileUploaded from '../../assets/icons/ai_file_uploaded.svg?react';

import image1 from '../../assets/images/ai_import_slide_one.png';
import image2 from '../../assets/images/ai_import_slide_two.png';
import image3 from '../../assets/images/ai_import_slide_three.png';
import FiledropBackground from '../../assets/images/ai_filedrop_bg.svg?react';
import { putWithAuth, requestWithAuth } from '../../services/api';
import LoaderAnimation from '../AidLoader';

message.config({
  top: 80,
});

let source;

const LinkedInImport: React.FC = () => {
  const navigate = useNavigate();
  const [fileList, setfileList] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const { CancelToken } = axios;
    source = CancelToken.source();
    fetchImportsCount();
    return () => {
      message.destroy();
      if (source) source.cancel('Operation canceled by the user.');
    };
  }, []);

  const fetchImportsCount = () => {
    const allCountParams = {
      key: 'all_count',
      type: 'first',
    };
    requestWithAuth(
      'network-summary-new',
      'POST',
      allCountParams,
      null,
      source.token,
      null,
    );
  };

  const handleDrop = (files: FileList) => {
    const fileArray = Array.from(files);
    setIsFileUploaded(true);
    setfileList(fileArray);
  };

  const onFileSelected = (data: React.ChangeEvent<HTMLInputElement>) => {
    setIsFileUploaded(true);
    // setfileList(data.target.files);
    if (data.target.files) {
      setfileList(Array.from(data.target.files));
    }
  };

  const onCancel = () => {
    setIsFileUploaded(false);
    setfileList([]);
  };

  const onSubmit = () => {
    // const { fileList } = this.state;

    if (!fileList || !fileList[0]) {
      message.error('Please select a file', 5);
      return;
    }

    setIsLoading(true);
    const params = {
      source: 'linkedin',
    };
    const file = fileList[0];
    const formData = new FormData();
    formData.append('file', file);
    putWithAuth(
      'csv-file-import',
      params,
      formData,
      file.name,
      source.token,
    ).then((response) => {
      if (!response) return;
      if (response.detail) {
        if (response.contact_import_url) {
          message.success(response.detail, 10);
          onCancel();
          navigate('/connect/status', {
            state: {
              refreshStatus: true,
            },
          });
        } else {
          message.error(response.detail, 10);
        }
      } else if (response.errorCode) {
        message.error(response.errorCode, 10);
      }

      setIsLoading(false);
    });
  };

  return (
    <div className="linkedin-container loader-wrapper">
      {isLoading && <LoaderAnimation loaderId="linkedin-import-loader" />}
      <div className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
        <div className="header-text">LinkedIn Connections</div>
        <div className="inner-container">
          <div className="filedrop-parent">
            <FileDrop onDrop={handleDrop}>
              {isFileUploaded ? (
                <>
                  <div className="file-uploaded">
                    <div className="file-icon">
                      <FileUploaded />
                    </div>
                    <div className="file-name">{fileList[0].name}</div>
                    <div className="remove-label" onClick={onCancel}>
                      <label style={{ cursor: 'pointer' }}>Remove</label>
                    </div>
                    <div className="submit-section">
                      <Button type="primary" onClick={onSubmit}>
                        Import Connections
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className={'filedrop-area drop-section'}>
                  <FiledropBackground style={{ maxWidth: '100%' }} />
                  <input
                    ref={inputRef}
                    autoComplete="__away"
                    className="upload-link"
                    type="file"
                    name="file"
                    onChange={onFileSelected}
                    accept=" .csv, .zip "
                  />
                </div>
              )}
            </FileDrop>
          </div>
          <div className="linkedin-info-section">
            <div className="linkedin-info-header">Your LinkedIn data</div>
            <div className="linkedin-info">
              <div>
                Your imported connections are not visible to anyone else on the
                platform unless you have accepted to be part of a team. In this
                case, your team will also be able to view your connections.
              </div>
              <br />
              <div>
                Your LinkedIn file contains first name, last name, title and
                company. We use this information to match to our Aidentified
                profiles.
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '25px' }}>
          <label className="help-label">Follow these easy steps</label>
          <ol className="linkedin-steps">
            <li>
              <div className="each-step">
                <span className="text">
                  Go to
                  <a
                    className="info-link"
                    href="https://www.linkedin.com/psettings/member-data"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    LinkedIn&apos;s data export page.
                  </a>
                </span>
              </div>
            </li>

            <li>
              <div className="each-step">
                <div className="second-step">
                  Select the first option - Download larger data archive, then
                  click on{' '}
                  <span style={{ fontWeight: '700' }}> Request archive </span>
                </div>
                <img src={image1} className="slider-image" alt="image1" />
              </div>
            </li>

            <li>
              <div className="each-step" style={{ paddingBottom: '0px' }}>
                <span className="text">
                  When your export file is ready, LinkedIn will email you a link
                  to download the archive as a zip file. It might come in 2
                  parts but you can use the first file that’s ready to download.
                  On the same screen as step 1 above,{' '}
                  <a
                    className="info-link"
                    href="https://www.linkedin.com/psettings/member-data"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    LinkedIn&apos;s data export page
                  </a>{' '}
                  click{' '}
                  <span style={{ fontWeight: '700' }}>Download Archive</span>
                </span>
                <img src={image2} className="slider-image-two" alt="image2" />
              </div>
            </li>

            <li>
              <div className="each-step">
                <span className="text">
                  Return to this screen and click on{' '}
                  <span style={{ fontWeight: '700' }}>
                    Import Zip or CSV file
                  </span>{' '}
                  at the top to upload your file for processing.
                </span>
              </div>
            </li>

            <li>
              <div className="each-step">
                <span className="text">
                  The import status of your LinkedIn connections will be listed
                  under{' '}
                  <span style={{ fontWeight: '700' }}>
                    Status of import/export
                  </span>
                  . Once it’s done processing, you’ll see your LinkedIn
                  connections in the app.
                </span>
                <div style={{ padding: '20px 20px 0px 20px' }}>
                  <img
                    src={image3}
                    className="slider-image-three"
                    alt="image3"
                  />
                </div>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default LinkedInImport;
