import axios from 'axios';
import { message } from 'antd';
import { stringify } from 'qs';
import auth0 from 'auth0-js';
import { getDomain } from '../utils/general';
import { setAuthority } from '../utils/authority';

message.config({
  top: 80,
});

const { VITE_COREDATA_API_URL, VITE_AUTH0_DOMAIN, VITE_AUTH0_CLIENT_ID } =
  import.meta.env;

const responseError = (error) => {
  console.log('responseError', error);
  if (error.status === 401) {
    // TODO
    // logout from app
  }
};

const requestError = (error) => {
  console.log('requestError', error);
};

const serverError = (error) => {
  console.log('serverError', error.message);
  console.log(error.config);
};

export async function requestLookupData(url) {
  // Remove the Authorization header to avoid CORS problems
  const axiosCopy = axios.create();
  delete axiosCopy.defaults.headers.common.Authorization;
  return axiosCopy
    .request({
      url,
      method: 'GET',
    })
    .then((response) => {
      return response.data;
    });
}

export async function updateUserInfo(id, body, progress) {
  const url = 'user-type';
  const absoluteUrl = `${VITE_COREDATA_API_URL}${url}/${id}`;
  return axios
    .request({
      url: absoluteUrl,
      method: 'PATCH',
      data: {
        ...body,
      },
      // cancelToken,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      if (error && error.message === 'Operation canceled by the user.') {
        console.log('Request canceled', error.message);
      } else {
        if (document.getElementById(progress) !== null)
          document.getElementById(progress).style.display = 'none';
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // responseError(error.response)
          console.log(error.response);
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail
          )
            message.error(error.response.data.detail, 10);
          else if (
            error.response &&
            error.response.data &&
            error.response.data.error_description
          )
            message.error(error.response.data.error_description, 10);
          else if (
            error.response &&
            error.response.data &&
            error.response.data.errorCode
          )
            message.error(error.response.data.errorCode, 10);
          else if (
            error.response &&
            error.response.data &&
            error.response.data.message
          )
            message.error(error.response.data.message, 10);
          // return error.response;
          // message.error(error.response);
        } else {
          // serverError(error);
          console.log(error);
          // return error;
          message.error('Network request failed. Please try again later.', 5);
        }
        return null;
      }
    });
}

export async function requestWithOutAuth(url, method, params, body) {
  let urlWithParams = `${VITE_COREDATA_API_URL}${url}`;
  urlWithParams =
    params === null ? urlWithParams : `${urlWithParams}?${stringify(params)}`;
  return axios
    .request({
      url: urlWithParams,
      method,
      data: {
        ...body,
      },
    })
    .then((response) => {
      return response.data;
    });
}

export async function requestWithAuth(
  url,
  method,
  params,
  body,
  cancelToken,
  progressId,
) {
  const progress = progressId || 'progress';
  const absoluteUrl = `${VITE_COREDATA_API_URL}${url}`;
  return axios
    .request({
      url: absoluteUrl,
      method,
      params: { ...params },
      data: {
        ...body,
      },
      cancelToken,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      if (error && error.message === 'Operation canceled by the user.') {
        console.log('Request canceled', error.message);
      } else {
        if (document.getElementById(progress) !== null)
          document.getElementById(progress).style.display = 'none';
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // responseError(error.response)
          console.log(error.response);
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail &&
            error.response.data.detail ===
              'Filter with given name already exists' &&
            window.location.pathname === '/user/login/callback'
          )
            return error.response.data;
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail &&
            error.response.data.detail === 'User not Found'
          ) {
            if (
              window.location.pathname !== '/vip/signup/callback' &&
              window.location.pathname !== '/vip/signup/maxio'
            )
              window.location.replace('/vip/signup/callback');
            return;
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.detail &&
            error.response.data.detail ===
              'Authentication credentials were not provided.'
          ) {
            if (window.location.pathname === '/user/login/callback')
              window.location.replace('/');
            return;
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.detail &&
            (error.response.data.detail === 'User inactive or deleted.' ||
              error.response.data.detail ===
                'Subscription expired - please contact your account representative.')
          ) {
            return {
              results: [{ user_type: 'inactive', is_first_time: true }],
            };
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.detail &&
            error.response.data.detail === 'Invalid user account'
          ) {
            if (
              window.location.pathname === '/vip/signup/callback' ||
              window.location.pathname === '/vip/signup/maxio'
            ) {
              const webAuth = new auth0.WebAuth({
                domain: VITE_AUTH0_DOMAIN,
                clientID: VITE_AUTH0_CLIENT_ID,
              });
              webAuth.logout({
                returnTo: `${getDomain()}/vip/register`,
              });
              window.location.replace('/vip/register');
            }
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.detail &&
            error.response.data.detail !== 'ID not found' &&
            error.response.data.detail !== 'Authorization not found' &&
            error.response.data.detail !== 'Signature has expired' &&
            error.response.data.detail !==
              'Session Expired - Redirecting to Login Screen'
          )
            message.error(error.response.data.detail, 10);
          else if (
            error.response &&
            error.response.data &&
            error.response.data.detail &&
            (error.response.data.detail === 'Signature has expired' ||
              'Session Expired - Redirecting to Login Screen')
          ) {
            setAuthority('guest');
            localStorage.removeItem('tempNetworkFilters');
            localStorage.removeItem('crm_source');
            localStorage.removeItem('redirectionPath');
            localStorage.removeItem('aidentified.pfFinderHelp');
            localStorage.removeItem('aidentified.theme');
            if (window.location.pathname !== '/user/session-expired')
              window.location.replace(`${getDomain()}/user/session-expired`);
            return '';
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.error_description
          )
            message.error(error.response.data.error_description, 10);
          else if (
            error.response &&
            error.response.data &&
            error.response.data.errorCode
          )
            message.error(error.response.data.errorCode, 10);
          else if (
            error.response &&
            error.response.data &&
            error.response.data.message
          )
            message.error(error.response.data.message, 10);
          // return error.response;
          // message.error(error.response);
        } else {
          // serverError(error);
          console.log(error);
          // return error;
          message.error('Network request failed. Please try again later.', 5);
        }
        return url.includes('maxio') ||
          url.includes('tags') ||
          url.includes('tag-folder')
          ? error
          : null;
      }
    });
}

export async function putWithAuth(url, params, file, filename, cancelToken) {
  return axios
    .request({
      url: `${VITE_COREDATA_API_URL}${url}?${stringify(params)}`,
      method: 'put',
      data: file,
      cancelToken,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.message === 'Operation canceled by the user.') {
        console.log('Request canceled', error.message);
      } else {
        if (document.getElementById('progress') !== null)
          document.getElementById('progress').style.display = 'none';
        if (error.response) {
          if (error.response.status === 422 || error.response.status === 400) {
            return error.response.data;
          }
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          responseError(error.response);
        } else if (error.request) {
          // The request was made but no response was received
          message.error(
            <>
              We are unable to process this file. Please contact support at
              <a
                className="support"
                target="_blank"
                href="mailto: support@aidentified.com"
                rel="noreferrer"
              >
                {' '}
                support@aidentified.com
              </a>
            </>,
            10,
          );
          requestError(error);
        } else {
          serverError(error);
        }
      }
    });
}
