import React, { useEffect, useRef, useState } from 'react';
import './index.scss';

import { Form, Input, Select, Button, message, Tooltip, Avatar } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';

import { AccountPropsInterface } from '../../types/AccountTypes';
import {
  putWithAuth,
  requestWithAuth,
  updateUserInfo,
} from '../../services/api';
import LoaderAnimation from '../../components/AidLoader';
import AiModal from '../../components/AiModal';
import Statelist from '../../utils/Statelist';
import { avatars } from '../../utils/constants';
import { setUserType } from '../../utils/userTypeAuthority';

import EllipseIcon from '../../assets/icons/ai_settings_ellipse.svg?react';
import UploadIcon from '../../assets/icons/ai_icn-upload.svg?react';
import CloseIcon from '../../assets/icons/ai_close_circle.svg?react';
import InfoIcon from '../../assets/icons/ai_info.svg?react';

const { Option } = Select;

interface AvatarInterface {
  key?: string;
  label?: string;
  Icon?: React.ComponentType;
}

const AccountProfile: React.FC<AccountPropsInterface> = inject('dataStore')(
  observer((props: AccountPropsInterface) => {
    const user = props.dataStore.currentUser;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isInfoShowing, setIsInfoShowing] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<AvatarInterface | null>(
      null,
    );
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (
        props.dataStore.currentUser &&
        props.dataStore.currentUser.profileImageType === 'placeholder' &&
        props.dataStore.currentUser.profileImageReference &&
        props.dataStore.currentUser.profileImageReference !== 'not_set'
      ) {
        const selectedItem = avatars.filter(
          (item) =>
            item.key === props.dataStore.currentUser.profileImageReference,
        );
        if (selectedItem && selectedItem[0]) setActiveIndex(selectedItem[0]);
      }
    }, [props.dataStore.currentUser]);

    const getStateOptions = () => {
      const stateOptions = Statelist.map(
        (state: { value: string; text: string }) => {
          return (
            <Option key={state.value} value={state.value}>
              {state.text}
            </Option>
          );
        },
      );
      stateOptions.unshift(<Option value="">Select</Option>);
      return stateOptions;
    };

    const handleAvatarClick = (item: AvatarInterface) => {
      if (activeIndex === item) {
        /** if already selected avatar is chosen, deselect it */
        handleRemoveProfilePic();
        return;
      }
      setActiveIndex(item);
      setIsLoading(true);
      updateUserInfo(props.dataStore.currentUser.ownerId, {
        profile_image_type: 'placeholder',
        profile_image_reference: item.key,
      }).then((res) => {
        if (res) {
          setUserType().then((user) => {
            if (user) {
              props.dataStore.currentUser = user;
            }
          });
        }
        setIsLoading(false);
      });
    };

    const handleSubmit = (values: {
      full_name: string;
      company: string;
      state: string;
      title: string;
    }) => {
      const body = {
        data: values,
      };
      setIsLoading(true);
      requestWithAuth(
        `user-type/${props.dataStore.currentUser.ownerId}`,
        'PUT',
        null,
        body,
        null,
        'cover-spin',
      ).then((res) => {
        if (res) {
          props.dataStore.currentUser.full_name = values.full_name;
          props.dataStore.currentUser.company = values.company;
          props.dataStore.currentUser.state = values.state;
          props.dataStore.currentUser.title = values.title;
          message.success('Profile Updated');
        }
        setIsLoading(false);
      });
    };

    const handleRemoveProfilePic = () => {
      setActiveIndex(null);
      setIsLoading(true);
      updateUserInfo(props.dataStore.currentUser.ownerId, {
        profile_image_type: 'not_set',
        profile_image_reference: null,
      }).then((res) => {
        if (res) {
          setUserType().then((user) => {
            if (user) {
              props.dataStore.currentUser = user;
            }
          });
        }
        setIsLoading(false);
      });
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0];
      if (!file) return;
      setIsLoading(true);
      const formData = new FormData();
      formData.append('profile_image', file);

      putWithAuth('upload-profile-image', null, formData, null, null).then(
        (res) => {
          if (res && res.message && (res.code === 200 || res.code === 201)) {
            message.success(res.message);
            setUserType().then((user) => {
              if (user) {
                props.dataStore.currentUser = user;
              }
              setIsLoading(false);
            });
          } else {
            message.error('Something went wrong. Please try again.');
            setIsLoading(false);
          }
        },
      );
      setActiveIndex(null);
    };

    const handleUploadClick = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };

    const renderIcons = (): JSX.Element[] => {
      return avatars.map((item, index) => (
        <>
          <div className="icon-container" key={index}>
            <div
              className={
                activeIndex?.key === item?.key
                  ? 'icon-avatar selected-avatar'
                  : 'icon-avatar'
              }
              onClick={() => handleAvatarClick(item)}
            >
              <item.Icon />
            </div>
            <div className="icon-name">{item.label}</div>
          </div>
        </>
      ));
    };

    const renderUserAvatar = (): JSX.Element => {
      const { profileImageType, profileImageReference } = user;
      if (
        profileImageReference &&
        profileImageType &&
        profileImageType === 'uploaded'
      ) {
        return (
          <>
            <img src={profileImageReference} alt="User" />
            <Tooltip
              placement="bottom"
              color="linear-gradient(180deg, #FFFFFF 0%, #F4FAFF 100%)"
              title={'Remove image'}
              styles={{
                body: {
                  color: 'var(--color-black)',
                  padding: '8px 14px',
                  boxShadow: '0 3px 6px 0 rgba(0,0,0,0.3)',
                  fontSize: '12px',
                },
              }}
            >
              <CloseIcon
                className="close-icon"
                onClick={handleRemoveProfilePic}
              />
            </Tooltip>
          </>
        );
      } else {
        return <EllipseIcon className="ellipsis" />;
      }
    };

    if (!props.dataStore.currentUser || !props.dataStore.currentUser.email)
      return (
        <div
          className="baseView loader-wrapper"
          style={{ height: '60vh', padding: '10px' }}
        >
          {isLoading && (
            <div id="cover-spin">
              <LoaderAnimation loaderId="invalid-user-base-loader" />
            </div>
          )}
        </div>
      );

    return (
      <div className=" loader-wrapper">
        {isLoading && (
          <div id="cover-spin">
            <LoaderAnimation loaderId="account-profile-loader" />
          </div>
        )}
        <div
          className={
            isLoading
              ? 'profile-edit-wrapper aid-div-disable'
              : 'profile-edit-wrapper aid-div-enable'
          }
        >
          <div className="form-data">
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={{
                full_name: user && user.full_name ? user.full_name : '',
                title: user && user.title ? user.title : '',
                company: user && user.company ? user.company : '',
                state: user && user.state ? user.state : '',
              }}
            >
              <Form.Item
                label="Name"
                name="full_name"
                rules={[{ message: 'Name cannot be empty' }]}
              >
                <Input
                  id="full_name"
                  className="formItem"
                  type="text"
                  style={{ color: '#003153', borderColor: '#003153' }}
                />
              </Form.Item>
              <Form.Item label="Email">
                <Input
                  className="formItem"
                  value={user && user.email ? user.email : ''}
                  disabled
                />
              </Form.Item>
              <Form.Item label="Company" name="company">
                <Input
                  id="company"
                  className="formItem"
                  type="text"
                  style={{ color: '#003153', borderColor: '#003153' }}
                />
              </Form.Item>
              <Form.Item label="Title" name="title">
                <Input
                  id="title"
                  className="formItem"
                  type="text"
                  style={{ color: '#003153', borderColor: '#003153' }}
                />
              </Form.Item>
              <Form.Item label="State" name="state">
                <Select
                  id="state"
                  className="formItem"
                  suffixIcon={
                    <DownOutlined
                      style={{ color: '#003153', borderColor: '#003153' }}
                    />
                  }
                >
                  {getStateOptions()}
                </Select>
              </Form.Item>
              <Button htmlType="submit" className="button-class">
                Save Changes
              </Button>
            </Form>
          </div>
          <div className="right-block">
            <div className="avatar-label">Aidentified Avatars</div>
            <div className="icons">{renderIcons()}</div>
            <div className="separator"></div>
            <div className="description">
              <div className="avatar-label">Upload Image</div>
              <div className="upload">
                <div className="user-avatar">{renderUserAvatar()}</div>
                <Button
                  htmlType="submit"
                  onClick={handleUploadClick}
                  className="formItem"
                  type="primary"
                >
                  <UploadIcon />
                  Upload
                </Button>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  style={{ display: 'none' }}
                />
                <div className="paragraph">
                  See important notice about image use
                  <InfoIcon
                    style={{
                      height: '14px',
                      color: 'var(--color-icon)',
                      marginBottom: '-3px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setIsInfoShowing(true)}
                  />
                  {/* By uploading an image, you agree that you own all rights to
                  the image. This image will only be used within the Aidentified
                  app and is not visible to other users in the app. */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isInfoShowing && (
          <AiModal
            wrapClassName="profile-image-info-modal"
            onCancel={() => setIsInfoShowing(false)}
          >
            <>
              By uploading an image, you consent to the use of such image while
              you are an Aidentified app user and you agree that you own all
              rights to the image. This image will only be used within the
              Aidentified app while you are using the app, will not be added to
              any Aidentified database, and is not visible to other users in the
              app. You can delete an image at any time and images uploaded will
              automatically be deleted by Aidentified when you stop using the
              app as part of Aidentified’s internal data retention policies.
            </>
          </AiModal>
        )}
      </div>
    );
  }),
);

export default AccountProfile;
