import './index.scss';

import { Button } from 'antd';
import { inject, observer } from 'mobx-react';

import UpgradeSubscription from '../../assets/icons/ai_upgrade_subscription.svg?react';
import WarningIcon from '../../assets/icons/ai_no_data_warning.svg?react';

import withRouter from '../../hooks/withRouter';

const getIcon = (iconName) => {
  switch (iconName) {
    case 'upgrade':
      return (
        <UpgradeSubscription
          className="warning-icon"
          style={{ width: '60px', height: '60px' }}
        />
      );
    case 'search-criteria':
      return (
        <WarningIcon className="warning-icon" style={{ height: '135px' }} />
      );
    default:
      break;
  }
};
const WarningMessage = inject('dataStore')(
  observer(({ iconName, content, buttonText, ...props }) => {
    const liteUserMessage =
      'To view this section, please contact your account representative to upgrade.';
    const inactiveUserMessage =
      'To view this section, please contact your account representative to upgrade.';

    const getRenderContent = () => {
      if (
        props.dataStore.currentUser.type !== 'lite' &&
        props.dataStore.currentUser.type !== 'inactive'
      ) {
        if (buttonText && buttonText === 'Subscribe') {
          // TODO: Inorder to change all page just added this condition for an easy fix
          return (
            <>
              Please contact{' '}
              <a
                className="support"
                target="_blank"
                href="mailto: support@aidentified.com"
                rel="noreferrer"
              >
                {' '}
                support@aidentified.com
              </a>{' '}
              to activate your account
            </>
          );
        }
        return content;
      } else if (props.dataStore.currentUser.type === 'lite')
        return liteUserMessage;
      else return inactiveUserMessage;
    };

    return (
      <div className="message-container">
        <div className="warning-section">
          {getIcon(iconName)}
          <p>{getRenderContent()}</p>

          {props.dataStore.currentUser.type !== 'lite' &&
            props.dataStore.currentUser.type !== 'inactive' &&
            buttonText &&
            buttonText !== 'Subscribe' &&
            props.dataStore.currentUser.billingPortalUrl && (
              <div>
                <Button
                  className="subscribe-button"
                  // onClick={handleSubscribeRedirect}
                >
                  <a
                    href={props.dataStore.currentUser.billingPortalUrl}
                    target="_blank"
                    style={{ color: 'var(--color-black)' }}
                    rel="noreferrer"
                  >
                    {buttonText}
                  </a>
                  {/* {buttonText} */}
                </Button>
              </div>
            )}
        </div>
      </div>
    );
  }),
  // };
);
export default withRouter(WarningMessage);
