import './index.scss';

import { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import WarningMessage from '../../components/WarningMessage';
import Export from './Export';
import Import from './Import';
import Status from './Status';

const Connect = inject('dataStore')(
  observer((props) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const parentRef = useRef(null);

    useEffect(() => {
      // scrollPathChange();
      // window.addEventListener('scroll', scrollPathChange);
      // return () => {
      //   window.removeEventListener('scroll', scrollPathChange);
      // };
    }, []);

    useEffect(() => {
      // onPathNameChange();
    }, [pathname]);

    const onPathNameChange = () => {
      const doc = document.getElementById('connect-parent');
      if (!doc) return;
      const path = pathname.split('/')[2];
      if (parentRef.current) {
        if (path === 'export') {
          let offsetTop = 700;
          if (
            document.getElementById('export') &&
            document.getElementById('export').offsetTop
          )
            offsetTop = document.getElementById('export').offsetTop - 50;
          window.scroll(window.scrollX, offsetTop);
        } else if (path === 'import') {
          let offsetTop = 500;
          if (
            document.getElementById('import') &&
            document.getElementById('import').offsetTop
          )
            offsetTop = document.getElementById('import').offsetTop - 50;
          window.scroll(window.scrollX, offsetTop);
        }
      }
    };

    const scrollPathChange = () => {
      const doc = document.getElementById('connect-parent');
      if (!doc) return;
      let exportOffset = 700;
      if (
        document.getElementById('export') &&
        document.getElementById('export').offsetTop
      )
        exportOffset = document.getElementById('export').offsetTop - 50;

      let importOffset = 500;
      if (
        document.getElementById('import') &&
        document.getElementById('import').offsetTop
      )
        importOffset = document.getElementById('import').offsetTop - 50;

      setTimeout(() => {
        if (window.scrollY >= exportOffset) {
          if (pathname !== '/connect/export') navigate('/connect/export');
        } else if (window.scrollY >= importOffset) {
          if (pathname !== '/connect/import') navigate('/connect/import');
        } else if (window.scrollY >= 0) {
          if (pathname !== '/connect/status') navigate('/connect/status');
        }
      }, 10);
    };
    if (props.dataStore.currentUser.type === 'inactive')
      return <WarningMessage iconName="upgrade" />;

    return (
      <div
        className="connect-outer-container"
        id="connect-parent"
        ref={parentRef}
      >
        <div className="container-auto">
          <Status />
          <Import />
          <Export location={props.location ? props.location : ''} />

          <div
            style={{
              marginBottom: '24px',
              color: 'var(--color-primary)',
              opacit: '0.5',
            }}
          >
            Note: Triggers sourced from Crunchbase are not exportable based on
            terms of Crunchbase Terms of Use.
          </div>
        </div>
      </div>
    );
  }),
);

export default Connect;
