import './index.css';

import { AutoComplete, Tooltip } from 'antd';
import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { SearchOutlined } from '@ant-design/icons';

import ClearButton from '../../assets/icons/ai_close_circle.svg?react';
import SearchButton from '../../assets/icons/ai_ic_search.svg?react';

import withRouter from '../../hooks/withRouter';
import { requestWithAuth } from '../../services/api';
import LoaderAnimation from '../AidLoader';

const { Option } = AutoComplete;
class GoogleSearch extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
    this.state = {
      options: [],
      isFocused: false,
    };
  }

  resetMapZoom = () => {
    if (this.props.dataStore.lookUpLists.map) {
      this.props.dataStore.lookUpLists.map.flyTo({
        zoom: 3,
        center: [-90.039891, 42.058919],
        speed: 2,
      });
    }
  };

  onSelect = (value) => {
    if (value === 'progress') return;
    if (this.props.from === 'filter') {
      this.props.onSelect(value);
      return;
    }
    if (value === 'allmatches') {
      this.props.modalDataStore.resetAll();
      if (!this.props.dataStore.lookUpLists.isPeoplePage) {
        this.props.navigate('/prospect-finder/people');
        this.setState({ options: [] });
      }
    } else {
      this.props.modalDataStore.resetProfileModal();
      setTimeout(() => {
        const { profileModal } = this.props.modalDataStore;
        if (value !== null && value.indexOf(',') > -1) {
          const res = value.split(',');
          profileModal.b2bId = res[0];
          profileModal.b2cId = res[1];
        }
        profileModal.isVisible = true;

        this.props.modalDataStore.trackModal = [
          {
            modal: 'profile',
            data: profileModal,
          },
        ];
      });
    }
  };

  handleSearch = (value) => {
    let options = [];
    if (this.props.from !== 'summary') {
      options = [
        <Option key="progress" value="progress">
          <div className="loader-wrapper">
            <LoaderAnimation
              loaderId="gsearch-loader"
              style={{ top: '0px' }}
              size="small"
            />
          </div>
        </Option>,
      ];
      this.setState({ options });
    }
    if (this.props.from === 'summary') {
      this.props.dataStore.relationship_quick_search = value;
    } else if (this.props.from === 'filter') {
      this.props.onSearch(value);
    } else {
      this.props.dataStore.quick_search = value;
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.props.from === 'summary') {
        this.props.dataStore.networkFilters.quickSearchRI = true;
        return;
      }
      if (
        this.props.dataStore.lookUpLists.map &&
        this.props.dataStore.lookUpLists.map.getZoom() > 3
      )
        this.resetMapZoom();
      else {
        /**pass quicksearch in apis if searched in header not in filter section */
        if (this.props.from !== 'filter')
          this.props.dataStore.networkFilters.fetchData = true;
      }

      const params = {};
      // if (this.props.from === 'filter')
      //   params.quick_search = this.props.dataStore.filter_quick_search;
      // else
      //   params.quick_search = this.props.dataStore.quick_search;
      params.quick_search = value;
      requestWithAuth('quick-search-ds2', 'GET', params, null, null)
        .then((response) => {
          if (response && Array.isArray(response) && response.length > 0) {
            const totalOption = response.length + 1;
            options = response.map((data) => (
              <Option
                style={{ display: 'flex' }}
                key={`${data.b2b_id}-${data.b2c_id}`}
                value={`${data.b2b_id || ''},${data.b2c_id || ''},${
                  data.full_name || ''
                }`}
              >
                <span
                  style={{
                    color: 'var(--color-dark)',
                    fontWeight: '500',
                    // marginRight: 5,
                  }}
                >
                  {data.full_name}
                  &nbsp;
                </span>

                {data.degree !== 0 &&
                data.degree !== null &&
                data.degree !== undefined ? (
                  <span
                    style={{
                      color: 'var(--color-grey-dark)',
                      // marginLeft: 5,
                      // marginRight: 5,
                    }}
                  >
                    &#8226; {` ${data.degree}`}
                    &nbsp;
                  </span>
                ) : null}

                {data.title !== null && data.title !== undefined ? (
                  <span
                    style={{
                      color: 'var(--color-grey-dark)',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'no-wrap',
                      overflow: 'hidden',
                      // marginLeft: 5,
                    }}
                  >
                    &#8226;
                    {` ${data.title}`}
                  </span>
                ) : null}
              </Option>
            ));
            if (this.props.from !== 'filter' && totalOption > response.length) {
              options.push(
                <Option
                  style={{ display: 'flex' }}
                  key="allmatches"
                  value="allmatches"
                >
                  <div
                    style={{
                      color: 'var(--color-dark)',
                      fontWeight: '500',
                      // marginRight: 5,
                    }}
                  >
                    Show All Matches
                  </div>
                </Option>,
              );
            }
          } else {
            options = [];
          }
          this.setState({ options });
        })
        .catch(function (error) {
          console.log(error);
        });
    }, 500);
  };

  onFocused = () => {
    this.setState({ isFocused: true });
  };

  onBlured = () => {
    // if (
    //   (this.props.from === 'summary' &&
    //     this.props.dataStore.relationship_quick_search) ||
    //   (this.props.from !== 'summary' && this.props.dataStore.quick_search)
    // ) {
    //   this.setState({ isFocused: false });
    // }
    if (this.props.onLeave) this.props.onLeave();
    this.setState({ isFocused: false });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.from !== 'filter' &&
      !this.props.dataStore.quick_search &&
      prevProps.dataStore.quick_search !== this.props.dataStore.quick_search
    ) {
      this.setState({ options: [] });
    }
  }

  getClassName = (hasData) => {
    if (this.props.from === 'header') return 'panel-search header-panel';
    if (this.props.from === 'summary' || this.state.isFocused || hasData)
      return 'panel-search white-bg';

    return 'panel-search dark-bg';
  };

  render() {
    const hasData = !!this.props.value;
    return (
      <div>
        <Tooltip
          title={
            this.props.dataStore.currentUser.type === 'lite' ||
            this.props.dataStore.currentUser.type === 'inactive'
              ? 'To use this, please contact your account representative to upgrade.'
              : ''
          }
          placement="bottom"
          color="black"
          overlayInnerStyle={{
            color: 'white',
          }}
        >
          <div
            // className="panel-search"
            // style={{
            //   backgroundColor: `${
            //     this.state.isFocused ||
            //     this.props.from === 'summary' ||
            //     (this.props.from !== 'summary' &&
            //       !this.state.isFocused &&
            //       this.props.dataStore.quick_search &&
            //       this.props.dataStore.quick_search !== '')
            //       ? 'var(--color-white)'
            //       : 'var(--color-dark)'
            //   }`,
            // }}
            className={this.getClassName(hasData)}
          >
            {this.props.from === 'header' && (
              <SearchButton className="prefix-search-icon" />
            )}
            <AutoComplete
              className="autoComplete"
              defaultOpen
              popupClassName="certain-category-search-dropdown"
              //   dataSource={this.state.options}
              value={this.props.value}
              style={{
                width: '100%',
                fontSize: '16px',
              }}
              dropdownMatchSelectWidth={350}
              disabled={
                this.props.dataStore.currentUser.type === 'lite' ||
                this.props.dataStore.currentUser.type === 'inactive'
                  ? true
                  : false
              }
              onSelect={this.onSelect}
              onSearch={this.handleSearch}
              placeholder={this.props.placeHolder || 'Search'}
              onFocus={this.onFocused}
              onBlur={this.onBlured}
              autoFocus={this.props.onLeave ? true : false}

              // allowClear={this.props.allowClear}
            >
              {this.state.options}
            </AutoComplete>
            {this.state.isFocused || !hasData ? (
              this.props.from !== 'header' ? (
                <SearchOutlined className="search-icon" />
              ) : (
                <></>
              )
            ) : (
              <ClearButton
                data-testid="clear-search-button"
                style={{ fill: 'var(--color-icon)', cursor: 'pointer' }}
                className="search-icon"
                onClick={() => {
                  if (this.props.from === 'summary')
                    this.props.dataStore.relationship_quick_search = '';
                  else if (this.props.from === 'filter') {
                    this.props.onSelect();
                  } else this.props.dataStore.quick_search = '';
                }}
              />
            )}
          </div>
        </Tooltip>
      </div>
    );
  }
}

export default withRouter(
  inject('dataStore')(
    inject('globalDataStore')(inject('modalDataStore')(observer(GoogleSearch))),
  ),
);
