import { Navigate } from 'react-router-dom';

import { inject, observer } from 'mobx-react';
import { useAuth0 } from '@auth0/auth0-react';
import { getAuthority } from '../../utils/authority';
import Progress from '../Progress';
import { getDomain } from '../../utils/general';
import LoaderAnimation from '../AidLoader';

const logoutOldUser = () => {
  // to handle authority strture change in local stoarage
  /* eslint-disable-next-line */
  const { logout } = useAuth0(); // FIXME: please fix eslint error
  const user = JSON.parse(localStorage.getItem('gotrue.user'));
  if (
    getAuthority() !== undefined &&
    getAuthority()[1] &&
    typeof getAuthority()[1] === 'object' &&
    (!user || !user.token)
  ) {
    return;
  }
  localStorage.removeItem('gotrue.user');
  logout({
    returnTo: `${getDomain()}/user/login`,
  });
};

const PrivateRoute = inject('dataStore')(
  observer((props) => {
    const { isLoading, isAuthenticated } = useAuth0();

    logoutOldUser();

    if (isLoading)
      return (
        <div className="loader-wrapper">
          <LoaderAnimation loaderId="private-route-loader" />;
        </div>
      );

    if (!isLoading && isAuthenticated) {
      if (
        props.dataStore.currentUser.isAdmin ||
        props.dataStore.currentUser.isReg ||
        props.dataStore.currentUser.isExistingUser
      ) {
        return props.children;
      } else {
        return <Navigate to="/user/login/callback" />;
      }
    } else {
      return (
        <Navigate
          to={
            window.location.pathname.substring(1) !== ''
              ? `/user/login?redirect_to=${window.location.pathname.substring(
                  1,
                )}${window.location.search}`
              : '/user/login'
          }
        />
      );
    }
  }),
);

export default PrivateRoute;
