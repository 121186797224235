import './index.scss';

import { inject, observer } from 'mobx-react';
import { CloseCircleFilled } from '@ant-design/icons';

import { commarize } from '../../utils/general';
import { LoadingSpinner } from '../../components/AiFunctionalComponents';

const WeeklyEngagement = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const { data, isLoading } = props;

    const renderHeader = () => {
      return (
        <div className="weekly-engagement-header">
          <div className="dashboard-item-title" style={{ display: 'inline' }}>
            Weekly Engagement
          </div>
        </div>
      );
    };

    const renderStatistic = (label, val, bg) => {
      return (
        <>
          <div className="count" style={{ backgroundColor: bg }}>
            {val ? commarize(val) : 0}
          </div>
          <div className="label">{label}</div>
        </>
      );
    };

    return (
      <div
        className={`
          dashboard-item weekly-engagement 
          relative 
          w-full 
          flex 
          flex-col 
          rounded-16 
          ${isLoading ? 'aid-div-disable' : 'aid-div-enable'}`}
      >
        <LoadingSpinner isLoading={isLoading} />
        {renderHeader()}

        <div className="grid grid-cols-4 rounded-16" style={{ paddingTop: 20 }}>
          <div className="count-label-section" style={{ paddingLeft: 0 }}>
            {renderStatistic(
              'Number of Searches',
              data.search_count,
              'var(--color-light-grey)',
            )}
          </div>
          <div className="count-label-section">
            {renderStatistic(
              'Profile View',
              data.profiles_viewed_count,
              'var(--color-horizontal-line)',
            )}
          </div>
          <div className="count-label-section">
            {renderStatistic(
              'Number of Exports',
              data.export_count,
              'var(--color-profile-connectivity)',
            )}
          </div>
          <div
            className="count-label-section"
            style={{ borderRight: 'none', paddingRight: 0 }}
          >
            {renderStatistic(
              'Number of CSV Imports',
              data.csv_import_count,
              'var(--color-medium-blue)',
            )}
          </div>
        </div>
      </div>
    );
  }),
);

export default WeeklyEngagement;
