import './index.scss';

import { useNavigate } from 'react-router';
import { inject, observer } from 'mobx-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { CloseCircleFilled } from '@ant-design/icons';

import ImportSummary from '../OverviewImportSummary';
import LoaderIcon from '../../assets/icons/ai_ic_logo_gold.svg?react';

const DataSources = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const { data, csvBoxes, showDataSourceProgress, showCompanyProfile, team } =
      props;
    const { icloud, google, outlook, linkedin } = data;
    const navigate = useNavigate();

    const flags = useFlags();
    const newDashboard = flags && flags.dasboard2024;

    const importBoxes = [
      {
        id: 1,
        name: 'linkedin',
      },
      {
        id: 2,
        name: 'icloud',
      },
      {
        id: 3,
        name: 'google',
      },
      {
        id: 4,
        name: 'outlook',
      },
      // {
      //   id: 5,
      //   name: "status",
      // },
    ];

    const getImportSourceData = (name) => {
      switch (name) {
        case 'linkedin':
          return linkedin;

        case 'icloud':
          return icloud;

        case 'google':
          return google;

        case 'outlook':
          return outlook;

        default:
          return {};
      }
    };

    const getImportBoxes = () => {
      return importBoxes.map((importbox) => (
        <ImportSummary
          data={getImportSourceData(importbox.name) || {}}
          source={importbox.name}
          showCompanyProfile={showCompanyProfile}
          team={team}
        />
      ));
    };

    /**  for import boxes like csv files */
    const getCsvBoxes = () => {
      return Object.entries(csvBoxes).map((csvbox) => (
        <>
          {csvbox[1].enriched_contacts !== 0 && (
            <ImportSummary
              type="file_imports"
              data={csvbox[1]}
              source={csvbox[0]}
              showCompanyProfile={showCompanyProfile}
              team={team}
            />
          )}
        </>
      ));
    };

    const getDataSourceBoxes = () => {
      return (
        <>
          <div className="top-container">{getImportBoxes()}</div>
        </>
      );
    };

    return (
      <div className="loader-wrapper">
        {showDataSourceProgress && (
          <LoaderIcon className="aid-sidecar-ext-logo-spin-icon-only" />
        )}
        <div
          className={
            showDataSourceProgress
              ? 'dashboard-item data-sources aid-div-disable'
              : 'dashboard-item data-sources aid-div-enable'
          }
          style={{
            borderRadius: newDashboard ? '16px' : '4px',
            padding: newDashboard ? '25px 20px' : '0px 0px 15px 0px',
          }}
        >
          <div className="data-sources-header">
            <div className="dashboard-item-title">
              External Relationship Paths
            </div>
            <div>
              <CloseCircleFilled
                onClick={props.onCloseButtonClick}
                style={{ color: 'var(--color-secondary)' }}
              />
            </div>
          </div>
          <div className="data-sources-content">{getDataSourceBoxes()}</div>
        </div>
      </div>
    );
  }),
);

export default DataSources;
