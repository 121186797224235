import './index.scss';

import { inject, observer } from 'mobx-react';
import { useEffect, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import TagIcon from '../../assets/icons/ai_tag_shadow.svg?react';
import SearchRoundedIcon from '../../assets/icons/ai_ic_search.svg?react';

import DataSelect from '../DropdownMenu';

const TagsSearch = inject('dataStore')(
  observer((props) => {
    const { onSearch } = props;
    const [options, setOptions] = useState([]);

    useEffect(() => {
      const newOptions = [];

      /** Folders  - Folder Name as option */
      props.dataStore.lookUpLists.userTagFolders?.tag_folders?.forEach(
        (foldertag, folderIndex) => {
          newOptions.push({
            label: foldertag.tag_folder_name,
            value: foldertag.tag_folder_name,
            extra: {
              type: 'tagFolder',
              folder_index: folderIndex,
              foldertag: foldertag,
            },
          });
        },
      );

      /** Tags in folders - Tag Name as option */
      props.dataStore.lookUpLists.userTagFolders?.tag_folders?.forEach(
        (foldertag, folderIndex) => {
          foldertag.tag_folder_members.forEach((tag, tagIndex) => {
            newOptions.push({
              label: tag.tag.tag,
              value: tag.tag.tag,
              extra: {
                type: 'taginfolder',
                folder_index: folderIndex,
                foldertag: foldertag,
                tag: tag,
              },
            });
          });
        },
      );

      props.dataStore.lookUpLists.userTagFolders?.orphan_tags?.forEach(
        (orphantag, orphanTagIndex) => {
          newOptions.push({
            label: orphantag.tag,
            value: orphantag.tag,
            extra: {
              type: 'orphanTag',
              orphan_tag_index: orphanTagIndex,
              orphantag: orphantag,
            },
          });
        },
      );
      props.dataStore.lookUpLists.userTagFolders?.shared_tags?.forEach(
        (sharedtag) => {
          newOptions.push({
            label: sharedtag.tag,
            value: sharedtag.tag,
            extra: { type: 'sharedTag', sharedtag: sharedtag },
          });
        },
      );

      setOptions(newOptions);
    }, [props.dataStore.lookUpLists.userTagFolders]);

    const onDropDownItemSlected = (selectedItems, id) => {
      if (!selectedItems || selectedItems.length < 1) {
        onSearch(props.dataStore.lookUpLists.userTagFolders);
        return;
      }
      const arraytoTransform = selectedItems.map((obj) => obj.extra);
      const newTagData = { tag_folders: [], orphan_tags: [], shared_tags: [] };
      let folderIndex;
      let tagIndex;
      arraytoTransform.forEach((obj) => {
        switch (obj.type) {
          case 'taginfolder':
            folderIndex = newTagData.tag_folders?.findIndex(
              (item) => item.id === obj.foldertag.id,
            );

            if (folderIndex === -1)
              newTagData.tag_folders.push({
                ...obj.foldertag,
                tag_folder_members: [obj.tag],
              });
            else {
              tagIndex = newTagData.tag_folders[
                folderIndex
              ].tag_folder_members?.findIndex((item) => item.id === obj.tag.id);
              if (tagIndex === -1)
                newTagData.tag_folders[folderIndex].tag_folder_members.push(
                  obj.tag,
                );
            }
            break;
          case 'tagFolder':
            folderIndex = newTagData.tag_folders?.findIndex(
              (item) => item.id === obj.foldertag.id,
            );
            if (folderIndex >= 0)
              newTagData.tag_folders.splice(folderIndex, 1, obj.foldertag);
            else newTagData.tag_folders.push(obj.foldertag);
            break;
          case 'orphanTag':
            newTagData.orphan_tags.push(obj.orphantag);
            break;
          case 'sharedTag':
            newTagData.shared_tags.push(obj.sharedtag);
            break;
          default:
            break;
        }
      });

      onSearch(newTagData);
    };

    const customFilterOption = (option, searchInput) => {
      // Show options only if searchInput is not empty
      if (searchInput === '') {
        return false;
      }
      // Default filter behavior (show all options if searchInput is not empty)
      return option.label.toLowerCase().includes(searchInput.toLowerCase());
    };

    return (
      <div className="tag-search">
        <TagIcon height={60} width={60} style={{ marginTop: '10px' }} />
        <DataSelect
          from="auto-filter"
          dropDownList={options}
          filterOption={customFilterOption}
          placeholder={
            <span
              style={{
                color: '#91AFCA',
                fontSize: '15px',
                letterSpacing: '.5px',
              }}
            >
              Search for a tag
            </span>
          }
          isMulti
          onItemSelected={onDropDownItemSlected}
          dropdownicon={<SearchRoundedIcon className="search-icon" />}
        />
      </div>
    );
  }),
);

export default TagsSearch;
