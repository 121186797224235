import './index.scss';

import { Tooltip } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';

import VerifyIcon from '../../assets/icons/ai_ic_logo_gold.svg?react';
import VerifyIconAi from '../../assets/icons/ai_golden_logo.svg?react';

function getDate(date) {
  return moment(date).format('MM/DD/YYYY');
}

function Verified({ verifiedDate, source = null }) {
  const flags = useFlags();

  return (
    <Tooltip
      placement="bottom"
      color="linear-gradient(180deg, #FFFFFF 0%, #F4FAFF 100%)"
      overlayInnerStyle={{
        color: 'var(--color-black)',
        padding: '15px 20px',
        boxShadow: '0 3px 6px 0 rgba(0,0,0,0.3)',
      }}
      title={() => {
        return (
          <div className="verified-icon">
            {source
              ? 'Last verified with a 3rd party provider on '
              : 'Last inspected from two data sources on '}
            {getDate(verifiedDate)}
          </div>
        );
      }}
    >
      <VerifyIcon height={15} width={15} />
    </Tooltip>
  );
}

export default Verified;
