import './index.scss';

import { Tooltip, Collapse } from 'antd';
import { useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import namecase from 'namecase';
import { useFlags } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';

import Facebook from '../../assets/icons/ai_facebook.svg?react';
import ZillowIcon from '../../assets/icons/ai_zillow_CRM-09.svg?react';
import HelpIcon from '../../assets/icons/info.svg?react';
import TwitterIcon from '../../assets/icons/ai_x_logo.svg?react';
import HomeIcon from '../../assets/icons/ai_home.svg?react';

import { formatValue, splitNumberWithCommas } from '../../utils/general';
import ReactGoogleMapImage from '../ReactMapGoogleImage';
import Verified from './Verified';

const { Panel } = Collapse;
const { VITE_GOOGLE_MAPS_KEY } = import.meta.env;

function Address(props) {
  const {
    propertyType,
    ownerStatus,
    homeOwnershipVerifiedOn,
    propertyValue,
    purchaseDate,
    salesDate,
    googleMapApiConfig,
    address1,
    address2,
    county,
    addressVerifiedOn,
    getZillowButton,
    showPropertyListing,
    listingStatus,
    listingPrice,
    listingDate,
  } = props;

  const getListingUpdate = (status) => {
    switch (status.toLowerCase()) {
      case 'active':
      case 'contract pending':
        return 'Property Listed';
      case 'sold':
      default:
        return 'Property Unlisted';
    }
  };
  return (
    <>
      <div className="location-address-section">
        <div>
          <HomeIcon height={36} />
        </div>
        <div>
          {ownerStatus && (
            <div style={{ marginBottom: '15px' }}>
              <div className="profile-labels flex">
                Owner Status
                {homeOwnershipVerifiedOn && (
                  <Verified verifiedDate={homeOwnershipVerifiedOn} />
                )}
              </div>

              <div className="address-value">{ownerStatus}</div>
            </div>
          )}
          {/* : (
         <>
           {/* show dummy div only if any of the property data is available
          {(propertyType || propertyValue || purchaseDate || salesDate) && (
            <div />
          )}
        </>
        )} */}
          <div>
            <div className="profile-labels flex" style={{ gap: '8px' }}>
              <div>Address</div>

              {addressVerifiedOn && (
                <Verified verifiedDate={homeOwnershipVerifiedOn} />
              )}

              {address1 && <> {getZillowButton(address1, address2)}</>}
            </div>
            <div className="address-value">
              {address1}
              {address1 && address2 && <br />}
              {address2}
              {county && <div>County: {county}</div>}
            </div>
          </div>
        </div>
        <div>
          {(propertyType || propertyValue || purchaseDate || salesDate) && (
            <div className="property-detail">
              {propertyType && (
                <div>
                  <div className="profile-labels">Property Type</div>
                  <div className="address-value">{propertyType}</div>
                </div>
              )}
              {propertyValue && (
                <div>
                  <div className="profile-labels">Value</div>

                  <div className="address-value">
                    {formatValue(propertyValue)}
                  </div>
                </div>
              )}
              {purchaseDate && (
                <div>
                  <div className="profile-labels">Purchase Date</div>

                  <div className="address-value">
                    {dayjs(purchaseDate).format('MM/DD/YYYY')}
                  </div>
                </div>
              )}
              {salesDate && (
                <div>
                  <div className="profile-labels">Sale Date</div>

                  <div className="address-value">
                    {dayjs(salesDate).format('MM/DD/YYYY')}
                  </div>
                </div>
              )}
            </div>
          )}
          {/* {(ownerStatus ||
        propertyType ||
        propertyValue ||
        purchaseDate ||
        salesDate) && <div>{/* dummy div }</div>} */}

          <div>
            <a
              href={
                'https://www.google.com/maps/place/' + googleMapApiConfig.center
              }
              target="_blank"
              rel="noreferrer"
            >
              <div></div>{' '}
              {/** dummy div to make map image start from the level of values */}
              <ReactGoogleMapImage
                config={googleMapApiConfig}
                wrapperStyle={{ width: '100%' }}
                style={{
                  width: '159px',
                  height: '92px',
                  border: '1px solid var(--color-white)',
                }}
              />
            </a>
          </div>
        </div>
      </div>
      {showPropertyListing &&
        (listingStatus || listingPrice || listingDate) && (
          <div className="property-listing-section">
            <div></div>
            {listingStatus && (
              <>
                <div>
                  <div className="profile-labels">Listing Update</div>
                  <div
                    className="address-value"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {getListingUpdate(listingStatus)}
                  </div>
                </div>
                <div>
                  <div className="profile-labels">Listing Status</div>
                  <div
                    className="address-value"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {listingStatus}
                  </div>
                </div>
              </>
            )}
            {listingPrice && (
              <div>
                <div className="profile-labels">Listing Price</div>
                <div className="address-value">{`$${splitNumberWithCommas(
                  listingPrice,
                )}`}</div>
              </div>
            )}
            {listingDate && (
              <div>
                <div className="profile-labels">Listing Date</div>
                <div className="address-value">
                  {dayjs(listingDate).format('MM/DD/YYYY')}
                </div>
              </div>
            )}
          </div>
        )}
    </>
  );
}

function Household({ person, toggleProgress }) {
  const flags = useFlags();
  const [secondHomes, setSecondHomes] = useState([]);
  const [currentHomes, setCurentHomes] = useState([]);
  const [expandCurrentHomes, setExpandCurrentHomes] = useState(false);

  useEffect(() => {
    if (person && person.homes) {
      const homesArray = person.homes.filter((home) => home.is_current);
      setCurentHomes([...homesArray]);
    }
    /**to filter out second homes with null values to avoid map data view with no prospect locators  */
    if (person && person.second_homes && person.second_homes.length > 0) {
      setSecondHomes(
        person.second_homes.filter((homeAddress) => {
          return (
            homeAddress &&
            !Array.isArray(homeAddress) &&
            (homeAddress.city || homeAddress.state || homeAddress.postal_code)
          );
        }),
      );
    } else {
      setSecondHomes([]);
    }
  }, [person]);

  const hasSocialHandles = () => {
    const socialHandle = person.social_handles;
    if (!socialHandle) return false;
    const hasFbOrTwitter = socialHandle.find((items) => {
      return items.network === 'facebook' || items.network === 'twitter';
    });
    if (hasFbOrTwitter) return true;
    else return false;
  };

  const getSocialHandle = (type) => {
    // social handle of 2 types are showing in profile -> facebook and twitter
    // 'type' is kept for return only of given type
    const socialHandle = person.social_handles;
    const socialHandlesView = [];
    if (socialHandle && socialHandle.length > 0) {
      socialHandle.map((handle) => {
        if (handle.network === type && handle.url) {
          const social_url =
            handle.url.indexOf('://') === -1
              ? `https://${handle.url}`
              : handle.url;
          socialHandlesView.push(
            <Tooltip
              placement="bottomRight"
              color="var(--color-primary)"
              title={handle.url}
            >
              <a
                href={social_url}
                style={{
                  height: '20px',
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {type === 'facebook' ? (
                  <Facebook />
                ) : (
                  <TwitterIcon className="twitter-icon" />
                )}{' '}
              </a>
            </Tooltip>,
          );
        }
      });

      if (socialHandlesView.length > 0) {
        return socialHandlesView;
        // return <div style={{display: 'flex'}}>{socialHandlesView}</div>;
      }
    }
  };

  const renderInterests = (person) => {
    let interestArray = [];

    if (
      person.interests &&
      person.interests.length > 0 &&
      Array.isArray(person.interests)
    )
      interestArray = person.interests;

    // interests.map((interest) => {
    //   if (interest && person[interest.value]) {
    //     interestArray.push(interest.label);
    //   }
    // });

    if (interestArray.length > 0) {
      return (
        <>
          <div className="profile-labels flex" style={{ paddingBottom: '5px' }}>
            <span>Modeled Interests</span>
            <Tooltip
              placement="top"
              color="var(--color-primary)"
              title="The modeled data attributes are derived from an Aidentified data provider’s use of a nationally recognized opt-in survey of respondents nationwide, which is a comprehensive, continuously fielded survey inclusive of both English and Spanish speakers."
            >
              <HelpIcon className="info-icon" />
            </Tooltip>
          </div>
          <div className="profile-value">{interestArray.join(', ')}</div>
        </>
      );
    }
  };

  const searchZillowHome = (addressOne, addressTwo) => {
    const zillowSiteAddress = 'zillow.com';
    window.open(
      `https://www.google.com/search?q=${zillowSiteAddress} ${addressOne.address1} ${addressTwo.address2}`,
    );
  };

  const getZillowButton = (address1, address2) => {
    return (
      <span style={{ lineHeight: 1 }}>
        <Tooltip
          placement="bottomRight"
          color="var(--color-primary)"
          title="Zillow Search"
        >
          <ZillowIcon
            className="zillow-search-icon-home"
            style={{
              height: 28,
              width: 15,
            }}
            onClick={() => searchZillowHome({ address1 }, { address2 })}
          />
        </Tooltip>
      </span>
    );
  };

  const getHomeAddress = (
    homeAddress,
    addressVerifiedOn,
    isMainAddress,
    homeOwnershipVerifiedOn,
    homePurchaseDate,
    showPropertyListing,
  ) => {
    const {
      city,
      number_of_owners,
      property_id,
      property_type,
      state,
      street_address,
      home_address_line1,
      value,
      postal_code,
      county,
      listing_status,
      listing_price,
      listing_date,
    } = homeAddress;
    const address1 = `${
      street_address
        ? street_address.toString().toLowerCase().toTitleCase()
        : home_address_line1
          ? home_address_line1.toString().toLowerCase().toTitleCase()
          : ''
    }`;

    const comma = city && state ? ', ' : '';
    const address2 = `${(city || '')
      .toString()
      .toLowerCase()
      .toTitleCase()}${comma}${state || ''} ${postal_code || ''}`.trim();

    const address = `${address1 || ''} ${address2 || ''}`.trim();
    const googleMapApiConfig = {
      center: address,
      size: '500x240',
      zoom: '15',
      key: VITE_GOOGLE_MAPS_KEY,
      maptype: 'roadmap',
    };

    return (
      <Address
        propertyType={
          property_type && property_type !== 'Public Works'
            ? property_type
            : null
        }
        ownerStatus={
          (!isMainAddress || person.norm_home_owner) &&
          property_type &&
          property_type === 'Residential'
            ? isMainAddress
              ? person.norm_home_owner
              : 'Home Owner'
            : null
        }
        homeOwnershipVerifiedOn={homeOwnershipVerifiedOn}
        propertyValue={!!value && value !== '0' ? value : null}
        purchaseDate={
          homeOwnershipVerifiedOn && addressVerifiedOn && homePurchaseDate
            ? homePurchaseDate
            : null
        }
        googleMapApiConfig={googleMapApiConfig}
        address1={address1}
        address2={address2}
        county={county}
        addressVerifiedOn={addressVerifiedOn}
        getZillowButton={getZillowButton}
        showPropertyListing={showPropertyListing}
        listingStatus={listing_status}
        listingPrice={listing_price}
        listingDate={listing_date}
      />
    );
  };

  const getHomeAddressNew = (homeAddress) => {
    const {
      city,
      property_id,
      property_type,
      state,
      home_value_estimate,
      postal_code,
      county,
      norm_home_owner,
      home_purchase_date,
      home_sale_date,
      home_address_line1,
      home_address_line2,
      home_address_verified_on,
      home_ownership_verified_on,
      listing_status,
      listing_price,
      listing_date,
    } = homeAddress;

    const street_address = `${
      home_address_line1
        ? home_address_line1.toString().toLowerCase().toTitleCase()
        : ''
    } ${
      home_address_line2
        ? home_address_line2.toString().toLowerCase().toTitleCase()
        : ''
    }`.trim();
    const address1 = `${
      street_address
        ? street_address.toString().toLowerCase().toTitleCase()
        : ''
    }`;

    const comma = city && state ? ', ' : '';
    const address2 = `${(city || '')
      .toString()
      .toLowerCase()
      .toTitleCase()}${comma}${state || ''} ${postal_code || ''}`.trim();

    const address = `${address1 || ''} ${address2 || ''}`.trim();
    const googleMapApiConfig = {
      center: address,
      size: '500x240',
      zoom: '15',
      key: VITE_GOOGLE_MAPS_KEY,
      maptype: 'roadmap',
    };
    return (
      <Address
        propertyType={
          property_type && property_type !== 'Public Works'
            ? property_type
            : null
        }
        ownerStatus={
          norm_home_owner && property_type && property_type === 'Residential'
            ? norm_home_owner
            : null
        }
        homeOwnershipVerifiedOn={home_ownership_verified_on}
        propertyValue={
          !!home_value_estimate && home_value_estimate !== '0'
            ? home_value_estimate
            : null
        }
        purchaseDate={
          home_ownership_verified_on &&
          home_address_verified_on &&
          home_purchase_date
            ? home_purchase_date
            : null
        }
        salesDate={home_sale_date}
        googleMapApiConfig={googleMapApiConfig}
        address1={address1}
        address2={address2}
        county={county}
        addressVerifiedOn={home_address_verified_on}
        getZillowButton={getZillowButton}
        showPropertyListing={flags && flags.dntfd8665AttomHomeListings}
        listingStatus={listing_status}
        listingPrice={listing_price}
        listingDate={listing_date}
      />
    );
  };

  const renderLocationSection = (person, showPropertyListing = false) => {
    function getMainAddress() {
      return {
        city: person.home_city,
        state: person.home_state,
        street_address: `${
          person.home_address_line1
            ? person.home_address_line1.toString().toLowerCase().toTitleCase()
            : ''
        } ${
          person.home_address_line2
            ? person.home_address_line2.toString().toLowerCase().toTitleCase()
            : ''
        }`.trim(),
        value: person.home_value_estimate,
        postal_code: person.home_postal_code,
        county: person.county,
        property_type: person.property_type,
        listing_status: person.listing_status,
        listing_price: person.listing_price,
        listing_date: person.listing_date,
      };
    }
    return (
      <>
        {showPropertyListing ? (
          <>
            <div
              className="profile-labels flex"
              style={{ padding: '20px 0px 10px 0px' }}
            >
              <span>Locations & Properties</span>
            </div>
            <div className="location-and-properties">
              {(person.home_address_line1 ||
                person.home_address_line2 ||
                person.home_city ||
                person.home_state ||
                person.home_postal_code ||
                person.county ||
                person.listing_status ||
                person.listing_price ||
                person.listing_date) && (
                <div>
                  {getHomeAddress(
                    getMainAddress(),
                    person.home_address_verified_on,
                    true,
                    person.home_ownership_verified_on,
                    person.home_purchase_date,
                    showPropertyListing,
                  )}
                </div>
              )}
              {secondHomes && secondHomes.length > 0 && (
                <>
                  {expandCurrentHomes ? (
                    <>
                      {secondHomes.map((homeAddress) => {
                        return (
                          <>
                            <div className="line" />
                            {getHomeAddress(
                              homeAddress,
                              person.home_address_verified_on,
                              false,
                              person.home_ownership_verified_on,
                              person.home_purchase_date,
                              showPropertyListing,
                            )}
                          </>
                        );
                      })}
                      <div
                        className="see-more-text"
                        style={{ marginTop: '23px' }}
                        onClick={() => setExpandCurrentHomes(false)}
                      >
                        See less properties
                        <DownOutlined className="arrow" rotate={180} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="line" />
                      <div
                        className="see-more-text"
                        onClick={() => setExpandCurrentHomes(true)}
                      >
                        See more properties
                        <DownOutlined className="arrow" />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div
              className="profile-labels"
              style={{ padding: '20px 0 14px 0' }}
            >
              Locations
            </div>
            <div>
              {(person.home_address_line1 ||
                person.home_address_line2 ||
                person.home_city ||
                person.home_state ||
                person.home_postal_code ||
                person.county) && (
                <div className="location-and-properties">
                  {getHomeAddress(
                    getMainAddress(),
                    person.home_address_verified_on,
                    true,
                    person.home_ownership_verified_on,
                    person.home_purchase_date,
                  )}
                </div>
              )}
              {secondHomes &&
                secondHomes.length > 0 &&
                secondHomes.map((homeAddress) => {
                  return (
                    <div className="location-and-properties">
                      {getHomeAddress(
                        homeAddress,
                        person.home_address_verified_on,
                        false,
                        person.home_ownership_verified_on,
                      )}
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </>
    );
  };

  const getGender = (gender) => {
    const g = gender.toLowerCase();
    if (g === 'm') return 'Male';
    else if (g === 'f') return 'Female';

    return g.toTitleCase();
  };

  const contactInfo =
    person.gender ||
    (person.age !== undefined && person.age !== null && person.age !== 0) ||
    person.marital_status ||
    (person.num_of_children !== undefined &&
      person.num_of_children !== null &&
      person.num_of_children !== 0);

  const personalInfo =
    person.full_name ||
    contactInfo ||
    person.home_address_line1 ||
    person.home_address_line2 ||
    person.home_city ||
    person.home_state ||
    person.home_postal_code ||
    person.county ||
    (person.second_homes && person.second_homes.length > 0);

  return (
    <div className="profile-boxes">
      <div className="profile-box-header">
        <div className="profile-titles">Household</div>
        {hasSocialHandles() && (
          <div className="profile-boxes-right">
            View profile on:
            {getSocialHandle('facebook')}
            {getSocialHandle('twitter')}
          </div>
        )}
      </div>
      <div className="border" />
      {personalInfo ? (
        <div className="profile-box-body">
          {person.full_name && (
            <>
              <div className="profile-labels">Name</div>
              <div
                className="value"
                style={{ fontSize: '21px', marginBottom: '10px' }}
              >
                {namecase(person.full_name)}
              </div>
            </>
          )}
          {contactInfo && (
            <div className="info">
              {person.age !== null &&
                person.age !== undefined &&
                person.age > 0 && (
                  <div>
                    <div className="profile-labels flex">
                      Age
                      {/* {person.age_verified_on && (
                        <Verified verifiedDate={person.age_verified_on} />
                      )} */}
                    </div>
                    <div className="value">{person.age}</div>
                  </div>
                )}
              {person.gender && (
                <div>
                  <div className="profile-labels flex">
                    Sex
                    {/* {person.gender_verified_on && (
                      <Verified verifiedDate={person.gender_verified_on} />
                    )} */}
                  </div>

                  <div className="value">{getGender(person.gender)}</div>
                </div>
              )}
              {person.marital_status && (
                <div>
                  <div className="profile-labels flex">
                    <span>Modeled Marital Status</span>
                    <Tooltip
                      placement="top"
                      color="var(--color-primary)"
                      title="The modeled data attributes are derived from an Aidentified data provider’s use of a nationally recognized opt-in survey of respondents nationwide, which is a comprehensive, continuously fielded survey inclusive of both English and Spanish speakers."
                    >
                      <HelpIcon className="info-icon" />
                    </Tooltip>
                  </div>
                  <div className="value">
                    {person.marital_status === 'M' ||
                    person.marital_status === 'married'
                      ? 'Married'
                      : 'Single'}
                  </div>
                </div>
              )}
              {person.presence_of_children && (
                <div>
                  <div className="profile-labels flex">
                    <span>Modeled Presence of Children</span>
                    <Tooltip
                      placement="top"
                      color="var(--color-primary)"
                      title="The modeled data attributes are derived from an Aidentified data provider’s use of a nationally recognized opt-in survey of respondents nationwide, which is a comprehensive, continuously fielded survey inclusive of both English and Spanish speakers."
                    >
                      <HelpIcon className="info-icon" />
                    </Tooltip>
                  </div>
                  <div className="value">Yes</div>
                </div>
              )}
            </div>
          )}
          {renderInterests(person)}

          {flags &&
            flags.currentAndPreviousProperty &&
            person.homes &&
            person.homes.length > 0 && (
              <>
                {currentHomes && currentHomes.length > 0 && (
                  <>
                    {flags && flags.dntfd8665AttomHomeListings ? (
                      <div>
                        <div
                          className="profile-labels flex"
                          style={{ padding: '20px 0px 10px 0px' }}
                        >
                          <span>Locations & Properties</span>
                        </div>

                        <div className="location-and-properties">
                          {getHomeAddressNew(currentHomes[0])}
                          {currentHomes && currentHomes.length > 1 && (
                            <>
                              {expandCurrentHomes ? (
                                <div>
                                  {currentHomes.map((home, index) => {
                                    if (index === 0) return <></>;
                                    return (
                                      <>
                                        <div className="line" />
                                        {getHomeAddressNew(home)}
                                      </>
                                    );
                                  })}
                                  <div
                                    className="see-more-text"
                                    style={{ marginTop: '23px' }}
                                    onClick={() => setExpandCurrentHomes(false)}
                                  >
                                    See less properties
                                    <DownOutlined
                                      className="arrow"
                                      rotate={180}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div className="line" />
                                  <div
                                    className="see-more-text"
                                    onClick={() => setExpandCurrentHomes(true)}
                                  >
                                    See more properties
                                    <DownOutlined className="arrow" />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          className="profile-labels flex"
                          style={{ padding: '10px 0' }}
                        >
                          <span>Current Property(ies)</span>
                        </div>
                        <>
                          {person.homes
                            .filter((home) => home.is_current)
                            .map((homeAddress) => {
                              return (
                                <div className="location-and-properties">
                                  {getHomeAddressNew(homeAddress)}
                                </div>
                              );
                            })}
                        </>
                      </>
                    )}
                  </>
                )}

                {person.homes.filter((home) => !home.is_current).length > 0 && (
                  <Collapse
                    className="previous-homes-collapse"
                    size="small"
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <DownOutlined
                        style={{ color: 'var(--color-dark-shade)' }}
                        rotate={isActive ? 180 : 0}
                      />
                    )}
                  >
                    <Panel
                      header={
                        <div className="">
                          <div className="profile-labels flex">
                            <span>Previous Property(ies)</span>
                            <Tooltip
                              placement="top"
                              color="var(--color-primary)"
                              title="Previous property(ies) are added based on property sales."
                            >
                              <HelpIcon className="info-icon" />
                            </Tooltip>
                          </div>
                        </div>
                      }
                    >
                      <>
                        {person.homes
                          .filter((home) => !home.is_current)
                          .map((homeAddress) => {
                            return (
                              <div className="location-and-properties">
                                {getHomeAddressNew(homeAddress)}
                              </div>
                            );
                          })}
                      </>
                    </Panel>
                  </Collapse>
                )}
              </>
            )}

          {(!flags || !flags.currentAndPreviousProperty) &&
            (person.home_address_line1 ||
              person.home_address_line2 ||
              person.home_city ||
              person.home_state ||
              person.home_postal_code ||
              person.county ||
              person.home_value_estimate ||
              person.property_type ||
              person.listing_status ||
              person.listing_price ||
              person.listing_date ||
              (secondHomes && secondHomes.length > 0)) && (
              <>
                {flags && flags.dntfd8665AttomHomeListings
                  ? renderLocationSection(person, true)
                  : renderLocationSection(person)}
              </>
            )}
        </div>
      ) : (
        <div className="no-data">No Household info available </div>
      )}
    </div>
  );
}

export default Household;
