import './index.scss';

import axios from 'axios';
import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { message } from 'antd';
import { CloseOutlined, CloseCircleFilled } from '@ant-design/icons';

import { requestWithAuth } from '../../services/api';
import {
  dashboardBoxItems,
  DashboardViewUngroupedLabels,
  DashboardViewBusinessInsightsLabels,
  DashboardViewEducationInsightsLabels,
  DashboardViewWealthInsightsLabels,
  DashboardViewLocationInsightsLabels,
  DashbaordViewDefaultItems,
} from '../../utils/constants';
import { getDateRangeFromPeriod, getSubscription } from '../../utils/general';

import WarningMessage from '../../components/WarningMessage';
import DashboardHeader from '../../components/DashboardComponents/DashboardHeader';
import ChartBox from '../../components/DashboardComponents/ChartBox';
import ClientInvolvement from '../../components/DashboardComponents/ClientInvolvement';
import DataSources from '../../components/DashboardComponents/DataSources';
import Opportunities from '../../components/DashboardComponents/Opportunities';
import TeamMembers from '../../components/DashboardComponents/TeamMembers';
import Triggers from '../../components/DashboardComponents/Triggers';
import NetworkDNA from '../../components/DashboardComponents/NetworkDNA';
import WeeklyEngagement from '../../components/DashboardComponents/WeeklyEngagement';
import PeopleViewModal from '../../components/DashboardComponents/PeopleViewModal';
import WeeklyTip from '../../components/DashboardComponents/WeeklyTip';
import SuggestedVideo from '../../components/DashboardComponents/SuggestedVideo';
import DragDropListItem from '../../components/DashboardComponents/DragDropListItem';
import DragSortListBox from '../../components/DashboardComponents/DragSortListBox';
import DraggableGridBox from '../../components/DashboardComponents/DraggableGridBox';
import LoaderAnimation from '../../components/AidLoader';

message.config({
  top: 80,
});

const { WealthTriggerOpportunities, ...dashboardAvailableItems } =
  dashboardBoxItems;

const defaultItems = [...DashbaordViewDefaultItems];

const { VITE_COREDATA_API_URL } = import.meta.env;
let source;
let clientInvolvementKey = '';
let triggerKey = 'week';
let teamValue;
let sourceImportId = [];
let boxItem = {};
let filterBoolean = false;

const Dashboard = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const [showEditView, setShowEditView] = useState(false);
    const [userDashboardData, setUserDashboardData] = useState({});
    const [selectedItems, setSelectedItems] = useState([]); //selected labels
    const [itemList, setItemList] = useState({
      TriggersOverview: true,
      OpportunitiesbyFilters: true,
      CSVImport: true,
      WeeklyTip: true,
      SuggestedVideo: true,
      ExternalRelationshipPaths: true,
      WeeklyEngagement: true,
      TeamMembers: true,
      NetworkDNA: true,
      TeamRelationshipPaths: true,
      Companies: true,
      CompanyViabiliy: true,
      Industries: true,
      TitleRanks: true,
      InferredSalaryRanges: true,
      WealthSegment: true,
      Cities: true,
      StatesandTerritories: true,
      ZipCodes: true,
      CollegesandUniversities: true,
      HouseholdInterests: true,
      Tags: true,
    }); //ids

    const [tagData, setTagData] = useState([]);
    const [stockPerformance, setStockPerformance] = useState({});
    const [salaryData, setSalaryData] = useState({});
    const [dataSources, setDataSources] = useState({});
    const [importSourceLoading, setImportSourceLoading] = useState(false);
    const [fileImportLoading, setFileImportLoading] = useState(false);
    const [csvBoxes, setCsvBoxes] = useState({});
    const [sharedFileIds, setSharedFileIds] = useState([]);
    const [allTeamFilesImports, setAllTeamFilesImports] = useState([]);
    const [importSourceIds, setImportSourceIds] = useState([]);
    const [myFileImportsIds, setMyFileImportsIds] = useState([]);
    const [clientInvData, setClientInvData] = useState({});
    const [isClientInvLoading, setIsClientInvLoading] = useState(true);
    const [cityData, setCityData] = useState({});
    const [companyData, setCompanyData] = useState({});
    const [titleData, setTitleData] = useState({});
    const [teamData, setTeamData] = useState({});
    const [industryData, setIndustryData] = useState({});
    const [wealthSegmentData, setWealthSegmentData] = useState({});
    const [stateData, setStateData] = useState({});
    const [zipData, setZipData] = useState({});
    const [schoolData, setSchoolData] = useState({});
    const [interestData, setInterestData] = useState({});
    const [eventData, setEventData] = useState([]);
    const [opportunitiesData, setOpportunitiesData] = useState([]);
    const [triggerTotalCount, setTriggerTotalCount] = useState({});
    const [isLoading, setIsloading] = useState(false);
    const [weeklyEngagementData, setWeeklyEngagementData] = useState({});
    const [modalVisibility, setModalVisibility] = useState(false);
    const [dashboardDropdownItems, setDashboardDropdownItems] = useState({
      relationship_path: {
        label: 'All',
        value: { name: 'All', id: 'all' },
      },
      filter: props.globalDataStore.multipleFilters.selectedId,
      sources: {
        label: 'All Selected',
        value: 'all_selected',
      },
    });

    //grouping
    const ungroupedLabels = [...DashboardViewUngroupedLabels];
    const businessInsightsLabels = [...DashboardViewBusinessInsightsLabels];
    const wealthInsightsLabels = [...DashboardViewWealthInsightsLabels];
    const locationInsightsLabels = [...DashboardViewLocationInsightsLabels];
    const educationInsightsLabels = [...DashboardViewEducationInsightsLabels];

    useEffect(() => {
      teamValue = 'all';
      const { CancelToken } = axios;
      source = CancelToken.source();
      /** Fix to avoid apis calling twice, on refreshing filter data is not passing to apis..
       * if props.globalDataStore.multipleFilters.isFilterApplied is
       * true apis will be called twice **/
      // if (!props.globalDataStore.multipleFilters.isFilterApplied){
      //   fetchData();}
      // fetchOpportunities();
      if (getSubscription(props.dataStore.currentUser)) {
        fetchUserDashBoardData();
        fetchFIleLookups('file_imports');
        fetchFIleLookups('all_count');
      }
      window.addEventListener('beforeunload', onPageReload);

      return () => {
        /** unmount- save the selected filters*/
        sourceImportId = [];
        triggerKey = 'week';
        saveSelectedFilters();
        if (source) source.cancel('Operation canceled by the user.');
        window.removeEventListener('beforeunload', onPageReload);
      };
    }, []);

    useEffect(() => {
      /** fetch client involvement data only after the lookups get data */
      if (
        props.dataStore.lookUpLists.fileImports &&
        props.dataStore.lookUpLists.fileImports.length > 0 &&
        props.dataStore.lookUpLists.eventList &&
        props.dataStore.lookUpLists.eventList.length > 0 &&
        selectedItems.includes('CSVImport')
      )
        fetchClientInvData();
    }, [
      props.dataStore.lookUpLists.fileImports,
      props.dataStore.lookUpLists.eventList,
    ]);

    useEffect(() => {
      /** keep selectedItems in a sesssion variable so that,
       *  on unmounting/refreshing component we can update the user selected items to the backend */
      window.sessionStorage.setItem(
        'ai-dashboard-selected',
        selectedItems.join(','),
      );
    }, [selectedItems]);

    useEffect(() => {
      /** keep selected dropdowns in a sesssion variable so that,
       *  on unmounting/refreshing component we can update the user selected dropdowns to the backend */
      window.sessionStorage.setItem(
        'dashboard_dropdown_items',
        JSON.stringify(dashboardDropdownItems),
      );
    }, [dashboardDropdownItems]);

    /*** Fix for While refreshing filter data is not getting passed to api***/
    useEffect(() => {
      if (
        props.globalDataStore.multipleFilters.isFilterApplied &&
        !filterBoolean
      ) {
        fetchSelectedApis(selectedItems);
      }
    }, [props.globalDataStore.multipleFilters.isFilterApplied]);

    const onPageReload = () => {
      /** when a page reload happens - save the selected filters*/
      saveSelectedFilters();
    };

    const saveSelectedFilters = () => {
      if (!getSubscription(props.dataStore.currentUser)) return;

      /** To avoid infinite call to user-dashboard,
       * if the user is on dashboard page, and token expires
       * the user will be loggedout,
       * during this time dashboard is unmounting and calls user-dashboard
       * again its get 401 token expiry and tries to logout thus make in infinite call
       */
      if (
        localStorage.getItem('aidentified-authority') &&
        JSON.parse(localStorage.getItem('aidentified-authority'))[0] === 'guest'
      )
        return;

      const body = {
        items: window.sessionStorage.getItem('ai-dashboard-selected')
          ? window.sessionStorage.getItem('ai-dashboard-selected').split(',')
          : [],
        dashboard_dropdown_items: window.sessionStorage.getItem(
          'dashboard_dropdown_items',
        )
          ? JSON.parse(
              window.sessionStorage.getItem('dashboard_dropdown_items'),
            )
          : {},
      };
      requestWithAuth('user-dashboard', 'POST', null, body, null);
    };

    const fetchFIleLookups = (key) => {
      const params = { key };

      if (key === 'file_imports') params.type = 'team';
      else params.type = 'first';

      requestWithAuth(
        'network-summary-new',
        'POST',
        params,
        null,
        source.token,
        null,
      ).then((response) => {
        if (response) {
          if (response) {
            if (key === 'file_imports') {
              const files = [];
              Object.entries(response).map(([key, val]) => {
                if (val.shared && val.id && val.id.length) {
                  files.push(val.id[0]);
                }
              });
              setAllTeamFilesImports([...files]);
            } else {
              if (
                response.linkedin !== '' &&
                response.icloud !== '' &&
                response.outlook !== '' &&
                response.google !== ''
              ) {
                setDataSources(response);
              }
            }
          }
        }
      });
    };

    const fetchDataOnSelect = (selectedItem) => {
      switch (selectedItem) {
        case 'TriggersOverview':
          if (!eventData || eventData.length <= 0) fetchEventsCount();
          break;
        case 'CSVImport':
          if (!clientInvData) fetchClientInvData();
          break;
        case 'WeeklyEngagement':
          if (
            !weeklyEngagementData ||
            Object.keys(weeklyEngagementData).length <= 0
          )
            fetchWeeklyEngagementData();
          break;
        case 'TeamRelationshipPaths':
          if (!teamData || Object.keys(teamData).length <= 0) fetchTeamData();
          break;
        case 'OpportunitiesbyFilters':
          if (!opportunitiesData || !opportunitiesData.opp)
            fetchOpportunities();
          break;
        case 'Tags':
          if (!tagData || !tagData.results) fetchTagData();
          break;
        case 'NetworkDNA': {
          /** NetworkDNA shows data from different apis -
           * industry, stockPerformance, company, title, wealthSegment, salary, zipCodes, city, states, colleges, houseHoldInterests
           * So if NetworkDNA is selected call these apis, otherwise call these if these are selected
           */
          if (!industryData || !industryData.top_industries)
            fetchIndustryData();
          if (!stockPerformance || !stockPerformance.top_stock_performance)
            fetchCompanyViabilityData();
          if (!companyData || !companyData.top_ai_org_ids) fetchCompanyData();
          if (!titleData || !titleData.top_titles) fetchTitleData();
          if (!wealthSegmentData || !wealthSegmentData.top_wealth_estimate)
            fetchWealthSegmentData();
          if (!salaryData || !salaryData.top_salary_ranges) fetchSalaryData();
          if (!zipData || !zipData.top_zip_codes) fetchZipData();
          if (!cityData || !cityData.top_cities) fetchCityData();
          if (!stateData || !stateData.top_states) fetchStateData();
          if (!schoolData || !schoolData.top_colleges) fetchSchoolData();
          if (!interestData || !interestData.top_interests) fetchInterestData();
          break;
        }
        case 'Industries':
          if (!industryData || !industryData.top_industries)
            fetchIndustryData();
          break;
        case 'CompanyViabiliy':
          if (!stockPerformance || !stockPerformance.top_stock_performance)
            fetchCompanyViabilityData();
          break;
        case 'Companies':
          if (!companyData || !companyData.top_ai_org_ids) fetchCompanyData();
          break;
        case 'TitleRanks':
          if (!titleData || !titleData.top_titles) fetchTitleData();
          break;
        case 'WealthSegment':
          if (!wealthSegmentData || !wealthSegmentData.top_wealth_estimate)
            fetchWealthSegmentData();
          break;
        case 'InferredSalaryRanges':
          if (!salaryData || !salaryData.top_salary_ranges) fetchSalaryData();
          break;
        case 'ZipCodes':
          if (!zipData || !zipData.top_zip_codes) fetchZipData();
          break;
        case 'Cities':
          if (!cityData || !cityData.top_cities) fetchCityData();
          break;
        case 'StatesandTerritories':
          if (!stateData || !stateData.top_states) fetchStateData();
          break;
        case 'CollegesandUniversities':
          if (!schoolData || !schoolData.top_colleges) fetchSchoolData();
          break;
        case 'HouseholdInterests':
          if (!interestData || !interestData.top_interests) fetchInterestData();
          break;
        default:
          break;
      }
    };

    const fetchSelectedApis = (itemsSelected) => {
      fetchAllCount('all_count');
      fetchAllCount('file_imports');

      if (itemsSelected.includes('TriggersOverview')) fetchEventsCount();
      if (itemsSelected.includes('WeeklyEngagement'))
        fetchWeeklyEngagementData();
      if (itemsSelected.includes('TeamRelationshipPaths')) fetchTeamData();
      if (itemsSelected.includes('OpportunitiesbyFilters'))
        fetchOpportunities();
      if (itemsSelected.includes('Tags')) fetchTagData();
      /** NetworkDNA shows data from different apis -
       * industry, stockPerformance, company, title, wealthSegment, salary, zipCodes, city, states, colleges, houseHoldInterests
       * So if NetworkDNA is selected call these apis, otherwise call these if these are selected
       */
      if (itemsSelected.includes('NetworkDNA')) {
        fetchIndustryData();
        fetchCompanyViabilityData();
        fetchCompanyData();
        fetchTitleData();
        fetchWealthSegmentData();
        fetchSalaryData();
        fetchZipData();
        fetchCityData();
        fetchStateData();
        fetchSchoolData();
        fetchInterestData();
      } else {
        if (itemsSelected.includes('Industries')) fetchIndustryData();
        if (itemsSelected.includes('CompanyViabiliy'))
          fetchCompanyViabilityData();
        if (itemsSelected.includes('Companies')) fetchCompanyData();
        if (itemsSelected.includes('TitleRanks')) fetchTitleData();
        if (itemsSelected.includes('WealthSegment')) fetchWealthSegmentData();
        if (itemsSelected.includes('InferredSalaryRanges')) fetchSalaryData();
        if (itemsSelected.includes('ZipCodes')) fetchZipData();
        if (itemsSelected.includes('Cities')) fetchCityData();
        if (itemsSelected.includes('StatesandTerritories')) fetchStateData();
        if (itemsSelected.includes('CollegesandUniversities'))
          fetchSchoolData();
        if (itemsSelected.includes('HouseholdInterests')) fetchInterestData();
      }

      /*** Client Involvement is calling in the useeffect after getting file imports lookup */
    };

    const setDefault = () => {
      setSelectedItems([...defaultItems]);
      setItemList((prev) =>
        Object.assign(
          {},
          {
            ...prev,
            ...Object.fromEntries(
              Object.entries(prev)
                .filter(([_key]) => defaultItems.includes(_key))
                .map(([_key]) => [_key, false]),
            ),
          },
        ),
      );
      fetchSelectedApis(defaultItems);
    };

    const fetchUserDashBoardData = () => {
      /** if no selected items set by the user, then show defaultItems */
      requestWithAuth('user-dashboard', 'GET', null, null, null).then(
        (response) => {
          if (response) {
            setUserDashboardData(response);

            if (
              response.dashboard_dropdown_items &&
              Object.keys(response.dashboard_dropdown_items).length > 0
            ) {
              setDashboardDropdownItems({
                ...response.dashboard_dropdown_items,
              });
              if (response.dashboard_dropdown_items.relationship_path) {
                const dropdownItem =
                  response.dashboard_dropdown_items.relationship_path;
                if (Array.isArray(dropdownItem.id)) {
                  /** Within RelationshipPaths Dropdown Importsource (linkedin/ Icloud/ google/ outlook) selected*/
                  boxItem = { import_source: dropdownItem['name'] };
                  sourceImportId = Array.isArray(dropdownItem.id)
                    ? dropdownItem.id
                    : [dropdownItem.id];

                  teamValue = 'first';
                } else {
                  /** Within RelationshipPaths Dropdown (All/ 1st/ 2nd/ 1st & 2nd / Any teams) selected*/
                  teamValue = dropdownItem.id;
                  boxItem = {};
                  sourceImportId = [];
                }
              }

              if (response.dashboard_dropdown_items.filter) {
                props.globalDataStore.multipleFilters.selectedId =
                  response.dashboard_dropdown_items.filter;
                props.globalDataStore.multipleFilters.isFilterApplied =
                  response.dashboard_dropdown_items.filter !== -1;
              }
              if (response.dashboard_dropdown_items.sources) {
                boxItem = response.dashboard_dropdown_items.sources;
                const importIds =
                  response.dashboard_dropdown_items.sources.file_imports || [];
                sourceImportId = [...sourceImportId, ...importIds];
              }
            }

            if (
              response.dashboard_items &&
              response.dashboard_items.length > 0
            ) {
              const filteredItems = response.dashboard_items.filter(
                (x) => x !== '',
              );

              if (filteredItems.length > 0) {
                const selected = [];
                filteredItems.map((item) => {
                  if (Object.keys(dashboardAvailableItems).includes(item)) {
                    //this check is used to support converting labels and ids

                    // Don't update itemList directly... need to update via the setItemList, bulk update below based on "selected"
                    // itemList[item] = false;

                    selected.push(item);
                  }
                });

                if (selected && selected.length > 0) {
                  setItemList((prev) => ({
                    ...prev,
                    ...Object.fromEntries(selected.map((k) => [k, false])),
                  }));
                  setSelectedItems([...selected]);

                  fetchSelectedApis(selected);
                } else {
                  setDefault();
                }
              }
            } else {
              setDefault();
            }
          }
        },
      );
    };
    const fetchTagData = () => {
      // if (document.getElementById('progress') !== null)
      //   document.getElementById('progress').style.display = 'block';
      // if (document.getElementById('networkTable') !== null)
      //   document.getElementById('networkTable').style.display = 'none';

      //if (source) source.cancel('Operation canceled by the user.');

      // const { CancelToken } = axios;
      // source = CancelToken.source();
      setTagData([]);
      const body = {};

      let search = {};

      const params = {
        page_size: 100,
        from: 0,
        key: 'tags_column',
      };
      if (props.dataStore.quick_search)
        params.quick_search = props.dataStore.quick_search;
      if (props.globalDataStore.multipleFilters.selectedId !== -1) {
        const selectedFilter =
          props.globalDataStore.multipleFilters.filterList.filter((item) => {
            return (
              item.value === props.globalDataStore.multipleFilters.selectedId
            );
          });
        //   body = getBodyParams(
        //     selectedFilter,
        //   props.dataStore.lookUpLists,
        //   props.dataStore.superConnectorFilter,
        //   props.dataStore.teamFilters,
        //   props.dataStore.currentUser,
        //   props.dataStore.mappedRelationshipsFilter,
        //   props.modalDataStore.profileModal,
        //   //props.dataStore.groupByValues,
        // );

        if (selectedFilter.filter_type)
          params.filter_type = selectedFilter.filter_type;

        // if anything is searched, pass that
        // if (props.dataStore.quick_search)
        //   params.quick_search = props.dataStore.quick_search;

        if (
          selectedFilter &&
          selectedFilter.length > 0 &&
          selectedFilter[0].filters
        ) {
          const {
            import_source,
            file_imports,
            team_file_imports,
            team_members,
            ...filtersFromSelected
          } = selectedFilter[0].filters;

          if (import_source && import_source.length) {
            body.import_source = import_source;
          }

          if (file_imports && file_imports.length) {
            body.file_imports = file_imports;
          }

          if (
            selectedFilter.filter_type !== 'first_degree' &&
            team_file_imports &&
            team_file_imports.length
          ) {
            body.team_file_imports = team_file_imports;
          }

          search = { ...filtersFromSelected };
        }
        if (search) body.search = search;
      }

      requestWithAuth(
        'people-grouping-new',
        'POST',
        params,
        body,
        source.token,
      ).then((response) => {
        if (!response) return;

        setTagData(response ? response : {});
      });
    };

    const fetchClientInvData = () => {
      const body = {};
      // this widget only shows CSV imports, so intentionally show nothing
      // if we pick an import source
      if (
        (boxItem.import_source && boxItem.import_source.length) ||
        clientInvolvementKey === 'file_imports'
      ) {
        setClientInvData(null);
        setIsClientInvLoading(false);
        return;
      }

      if (teamValue === 'first') {
        body.team_members = [props.dataStore.currentUser.ownerId];
      } else if (teamValue === 'all') {
        body.team_members = props.dataStore.lookUpLists.teamMemberIds;
      } else {
        const memberList = [];
        if (props.dataStore.lookUpLists.team) {
          props.dataStore.lookUpLists.team.map((team) => {
            if (team.id === teamValue) {
              team.users.map((members) => {
                memberList.push(members.id);
              });
            }
          });
          body.team_members = memberList;
        }
      }
      let search = {};
      const params = {
        page_size: 100,
        from: 0,
        key: clientInvolvementKey || 'current_experience.ai_org_id',
      };

      if (props.dataStore.quick_search)
        params.quick_search = props.dataStore.quick_search;

      setClientInvData(null);
      setIsClientInvLoading(true);

      if (props.globalDataStore.multipleFilters.selectedId !== -1) {
        const selectedFilter =
          props.globalDataStore.multipleFilters.filterList.filter((item) => {
            return (
              item.value === props.globalDataStore.multipleFilters.selectedId
            );
          });

        if (selectedFilter.filter_type)
          params.filter_type = selectedFilter.filter_type;

        if (
          selectedFilter &&
          selectedFilter.length > 0 &&
          selectedFilter[0].filters
        ) {
          const {
            import_source,
            file_imports,
            team_file_imports,
            team_members,
            ...filtersFromSelected
          } = selectedFilter[0].filters;

          if (import_source && import_source.length) {
            body.import_source = import_source;
          }

          if (file_imports && file_imports.length) {
            body.file_imports = file_imports;
          }

          if (
            selectedFilter.filter_type !== 'first_degree' &&
            team_file_imports &&
            team_file_imports.length
          ) {
            body.team_file_imports = team_file_imports;
          }

          search = { ...filtersFromSelected };
        }
      }

      if (boxItem.import_source && boxItem.import_source.length) {
        body.import_source = boxItem.import_source;
      }

      if (boxItem.file_imports && boxItem.file_imports.length) {
        body.file_imports = boxItem.file_imports;
      }

      if (boxItem.team_file_imports && boxItem.team_file_imports.length) {
        body.team_file_imports = boxItem.team_file_imports;
      }

      if (!body.file_imports)
        body.file_imports = props.dataStore.lookUpLists.fileImports.map(
          (file_import) => {
            return file_import.value;
          },
        );

      // if (!search.latest_mim_event_type)
      //   search.latest_mim_event_type =
      //     props.dataStore.lookUpLists.eventList.map((mim_event) => {
      //       return mim_event.value;
      //     });

      if (search) body.search = search;

      requestWithAuth(
        'people-grouping-new',
        'POST',
        params,
        body,
        source.token,
      ).then((response) => {
        if (!response) return;
        setClientInvData(response);
        setIsClientInvLoading(false);
      });
    };

    const fetchOpportunities = () => {
      setOpportunitiesData(null);

      requestWithAuth('dashboard-ri-counts-new', 'GET').then((response) => {
        if (!response || !response.data) {
          setOpportunitiesData({});
          return;
        }
        setOpportunitiesData(response.data);
        // setIsClientInvLoading(false);
      });
    };

    const fetchData = (onEditViewClick = false) => {
      if (!onEditViewClick) {
        fetchAllCount('all_count');
        fetchAllCount('file_imports');
      }
      fetchEventsCount();
      fetchWeeklyEngagementData();
      fetchTeamData();
      fetchCompanyData();
      fetchCompanyViabilityData();
      fetchIndustryData();
      fetchTitleData();
      fetchSalaryData();
      fetchWealthSegmentData();
      fetchCityData();
      fetchStateData();
      fetchZipData();
      fetchSchoolData();
      fetchInterestData();
    };

    const requestNetworkSummary = (body, key, reload) => {
      const url = 'network-summary-new';
      const params = {
        key,
        type: teamValue,
      };

      if (typeof teamValue === 'number') {
        params.type = 'team';
        params.team_id = teamValue;
      }

      if (props.dataStore.relationship_quick_search)
        params.quick_search = props.dataStore.relationship_quick_search;
      if (props.globalDataStore.multipleFilters.isFilterApplied) {
        params.is_user_filter = 1;
        params.filter_id = props.globalDataStore.multipleFilters.selectedId;
      } else params.is_user_filter = 0;

      if (sourceImportId && sourceImportId.length > 0)
        body.source_import_ids = sourceImportId.filter(Number);

      const absoluteUrl = `${VITE_COREDATA_API_URL}${url}`;
      return axios
        .request({
          url: absoluteUrl,
          method: 'POST',
          params,
          data: {
            ...body,
          },
          cancelToken: source.token,
        })
        .then((response) => {
          if (response && response.data) return response.data;
          else return {};
        })
        .catch((error) => {
          if (error.message === 'Operation canceled by the user.') {
            console.log('Request canceled', error.message);
          } else {
            console.log(error);
            if (document.getElementById(`${reload}`) !== null)
              document.getElementById(`${reload}`).style.display = 'block';
          }
          const obj = {};
          obj[key] = [];
          obj.total_count = 0;
          return obj;
        });
    };

    const fetchAllCount = (key) => {
      /** Filters will be applied so that we can show the data in External Relationship Paths and CSVImport files */
      if (key === 'all_count') {
        setImportSourceIds([]);
        setDataSources({});
        setImportSourceLoading(true);
      } else {
        setCsvBoxes([]);
        setFileImportLoading(true);
      }
      const allCountParams = {
        key,
        type: teamValue,
      };

      if (typeof teamValue === 'number') {
        allCountParams.type = 'team';
        allCountParams.team_id = teamValue;
      } else if (key === 'all_count') {
        allCountParams.type = 'first';
      }

      if (props.dataStore.relationship_quick_search)
        allCountParams.quick_search = props.dataStore.relationship_quick_search;
      if (props.globalDataStore.multipleFilters.isFilterApplied) {
        allCountParams.is_user_filter = 1;
        allCountParams.filter_id =
          props.globalDataStore.multipleFilters.selectedId;
      } else allCountParams.is_user_filter = 0;

      requestWithAuth(
        'network-summary-new',
        'POST',
        allCountParams,
        null,
        source.token,
        null,
      ).then((response) => {
        if (response) {
          if (key === 'all_count') {
            if (
              response.linkedin !== '' &&
              response.icloud !== '' &&
              response.outlook !== '' &&
              response.google !== ''
            ) {
              setDataSources(response);
              const importSources = [];
              Object.entries(response).map(([key, val]) => {
                if (val.enriched_contacts) {
                  importSources.push(key);
                }
              });
              setImportSourceIds([...importSources]);
            }
          } else {
            setCsvBoxes(response);
            setSharedFileIds([]);
            setMyFileImportsIds([]);
            if (response) {
              const files = [],
                sharedFiles = [];
              Object.entries(response).map(([key, val]) => {
                if (!val.shared && val.id && val.id.length) {
                  files.push(val.id[0]);
                } else sharedFiles.push(val);
              });
              setMyFileImportsIds([...files]);
              setSharedFileIds([...sharedFiles]);
            }
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        key === 'all_count'
          ? setImportSourceLoading(false)
          : setFileImportLoading(false);
      });
    };

    const fetchEventsCount = (value) => {
      setIsloading(true);
      const params = {};

      if (teamValue) params.type = teamValue;
      if (typeof teamValue === 'number') {
        params.type = 'team';
        params.team_id = teamValue;
      }

      if (props.dataStore.relationship_quick_search)
        params.quick_search = props.dataStore.relationship_quick_search;

      if (props.globalDataStore.multipleFilters.isFilterApplied) {
        params.is_user_filter = 1;
        params.filter_id = props.globalDataStore.multipleFilters.selectedId;
      } else params.is_user_filter = 0;
      params.date_filter = triggerKey;
      const body = {
        source_import_ids: sourceImportId.filter(Number),
      };

      setTriggerTotalCount({});
      requestWithAuth(
        'event-summary-filters',
        'POST',
        params,
        body,
        source.token,
      ).then((response) => {
        setIsloading(false);
        if (!response) {
          return;
        }

        setEventData(response.events || []);

        if (response.total_counts) {
          setTriggerTotalCount(response.total_counts);
        }
      });
    };

    /*** API for weekly engagement section***/
    const fetchWeeklyEngagementData = () => {
      setIsloading(true);
      requestWithAuth(
        'weekly-engagement-log',
        'GET',
        null,
        null,
        source.token,
      ).then((response) => {
        if (!response) return;
        setIsloading(false);
        setWeeklyEngagementData(response);
      });
    };

    const fetchTeamData = () => {
      if (document.getElementById('progress-team') !== null)
        document.getElementById('progress-team').style.display = 'block';
      if (document.getElementById('reload-team_ids') !== null)
        document.getElementById('reload-team_ids').style.display = 'none';
      setTeamData(null);
      const body = {};
      requestNetworkSummary(body, 'top_team_members', 'reload-team_ids').then(
        (response) => {
          if (response) {
            setTeamData(response);
          }
        },
      );
    };

    const fetchCompanyData = () => {
      if (document.getElementById('progress-company') !== null)
        document.getElementById('progress-company').style.display = 'block';
      if (document.getElementById('reload-org_name') !== null)
        document.getElementById('reload-org_name').style.display = 'none';

      setCompanyData(null);
      const body = {};
      requestNetworkSummary(body, 'top_ai_org_ids', 'reload-org_name').then(
        (response) => {
          if (response) {
            setCompanyData(response);
          }
        },
      );
    };

    const fetchCompanyViabilityData = () => {
      if (document.getElementById('progress-employment') !== null)
        document.getElementById('progress-employment').style.display = 'block';
      if (document.getElementById('reload-stock_performance') !== null)
        document.getElementById('reload-stock_performance').style.display =
          'none';

      setStockPerformance(null);
      const body = {};
      requestNetworkSummary(
        body,
        'top_stock_performance',
        'reload-stock_performance',
      ).then((response) => {
        if (response) {
          setStockPerformance(response);
        }
      });
    };

    const fetchIndustryData = (team) => {
      if (document.getElementById('progress-industry') !== null)
        document.getElementById('progress-industry').style.display = 'block';
      if (document.getElementById('reload-industry') !== null)
        document.getElementById('reload-industry').style.display = 'none';
      setIndustryData(null);
      const body = {};
      requestNetworkSummary(body, 'top_industries', 'reload-industry').then(
        (response) => {
          if (response) {
            setIndustryData(response);
          }
        },
      );
    };

    const fetchTitleData = () => {
      if (document.getElementById('progress-title') !== null)
        document.getElementById('progress-title').style.display = 'block';
      if (document.getElementById('reload-title_rank') !== null)
        document.getElementById('reload-title_rank').style.display = 'none';

      setTitleData(null);
      const body = {};
      requestNetworkSummary(body, 'top_titles', 'reload-title_rank').then(
        (response) => {
          if (response) {
            setTitleData(response);
          }
        },
      );
    };

    const fetchSalaryData = () => {
      if (document.getElementById('progress-salary') !== null)
        document.getElementById('progress-salary').style.display = 'block';
      if (document.getElementById('reload-inferred_salary_range') !== null)
        document.getElementById('reload-inferred_salary_range').style.display =
          'none';

      setSalaryData(null);

      const body = {};
      requestNetworkSummary(
        body,
        'top_salary_ranges',
        'reload-inferred_salary_range',
      ).then((response) => {
        if (response) {
          setSalaryData(response);
        }
      });
    };

    const fetchWealthSegmentData = () => {
      if (document.getElementById('progress-wealthSegment') !== null)
        document.getElementById('progress-wealthSegment').style.display =
          'block';
      if (document.getElementById('reload-top_wealth_estimate') !== null)
        document.getElementById('reload-top_wealth_estimate').style.display =
          'none';
      setWealthSegmentData(null);
      const body = {};
      requestNetworkSummary(
        body,
        'top_wealth_estimate',
        'reload-top_wealth_estimate',
      ).then((response) => {
        if (response) {
          setWealthSegmentData(response);
        }
      });
    };

    const fetchCityData = () => {
      if (document.getElementById('progress-city') !== null)
        document.getElementById('progress-city').style.display = 'block';
      if (document.getElementById('reload-location_city') !== null)
        document.getElementById('reload-location_city').style.display = 'none';

      setCityData(null);
      const body = {};
      requestNetworkSummary(body, 'top_cities', 'reload-location_city').then(
        (response) => {
          if (response) {
            setCityData(response);
          }
        },
      );
    };

    const fetchStateData = () => {
      if (document.getElementById('progress-state') !== null)
        document.getElementById('progress-state').style.display = 'block';
      if (document.getElementById('reload-location_state') !== null)
        document.getElementById('reload-location_state').style.display = 'none';
      setStateData(null);
      const body = {};
      requestNetworkSummary(body, 'top_states', 'reload-location_state').then(
        (response) => {
          if (response) {
            setStateData(response);
          }
        },
      );
    };

    const fetchZipData = () => {
      if (document.getElementById('progress-zip') !== null)
        document.getElementById('progress-zip').style.display = 'block';
      if (document.getElementById('reload-home_postal_code') !== null)
        document.getElementById('reload-home_postal_code').style.display =
          'none';
      setZipData(null);
      const body = {};
      requestNetworkSummary(
        body,
        'top_zip_codes',
        'reload-home_postal_code',
      ).then((response) => {
        if (response) {
          setZipData(response);
        }
      });
    };

    const fetchSchoolData = () => {
      if (document.getElementById('progress-school') !== null)
        document.getElementById('progress-school').style.display = 'block';
      if (document.getElementById('reload-schools') !== null)
        document.getElementById('reload-schools').style.display = 'none';
      setSchoolData(null);
      const body = {};
      requestNetworkSummary(body, 'top_colleges', 'reload-schools').then(
        (response) => {
          if (response) {
            setSchoolData(response);
          }
        },
      );
    };

    const fetchInterestData = () => {
      if (document.getElementById('progress-interest') !== null)
        document.getElementById('progress-interest').style.display = 'block';
      if (document.getElementById('reload-interests') !== null)
        document.getElementById('reload-interests').style.display = 'none';
      setInterestData(null);
      const body = {};
      requestNetworkSummary(body, 'top_interests', 'reload-interests').then(
        (response) => {
          if (response) {
            setInterestData(response);
          }
        },
      );
    };

    const showCompanyProfile = (id, orgName) => {
      if (!id) return;
      // click from any company name
      props.modalDataStore.resetProfileModal();
      props.modalDataStore.resetCompanyProfileModal();
      const { companyProfileModal } = props.modalDataStore;
      companyProfileModal.id = id;
      companyProfileModal.orgName = orgName;
      companyProfileModal.id = id;
      companyProfileModal.isVisible = true;
    };

    const toggleShowEditView = () => {
      // if (!showEditView) {
      //   fetchData(true);
      //   fetchClientInvData();
      //   fetchOpportunities();
      // }
      setShowEditView(!showEditView);
      if (showEditView) saveSelectedFilters();
    };

    const onFilterChange = (dropDownValue, boxData) => {
      /** for avoiding api calls twice [to prevent entering from useeffect on change of
       * props.globalDataStore.multipleFilters.isFilterApplied ] */
      filterBoolean = true;
      const dropdownData = { ...dashboardDropdownItems };

      if (dropDownValue === 'fromFilter') {
        /** Filter Dropdown change */
        // props.dataStore.networkFilters.selectedIdValue =
        //   props.globalDataStore.multipleFilters.selectedId;
        dropdownData.filter = props.globalDataStore.multipleFilters.selectedId;
        // eslint-disable-next-line no-prototype-builtins
      } else if (dropDownValue && dropDownValue.hasOwnProperty('id')) {
        /** Click From RelationshipPaths Dropdown */

        dropdownData.relationship_path = dropDownValue;

        if (Array.isArray(dropDownValue.id)) {
          /** Within RelationshipPaths Dropdown Importsource (linkedin/ Icloud/ google/ outlook) selected*/
          boxItem = { import_source: dropDownValue['name'] };
          const importIds = Array.isArray(dropDownValue.id)
            ? dropDownValue.id
            : [dropDownValue.id];
          /** need to merge csv imports id if already selected and  the selected linkdin/icloud.. ids */
          const csvimportIds =
            dropdownData.sources &&
            Object.values(dropdownData.sources).length > 0
              ? Object.values(dropdownData.sources)[0]
              : [];
          sourceImportId = [...csvimportIds, ...importIds];

          teamValue = 'first';
        } else {
          /** Within RelationshipPaths Dropdown (All/ 1st/ 2nd/ 1st & 2nd / Any teams) selected*/
          teamValue = dropDownValue.id;
          boxItem = {};
          dropdownData.sources = {};
          sourceImportId = [];
        }
      } else {
        /** Click From CSV Imports Dropdown */
        dropdownData.sources = boxData;
        boxItem = boxData;

        /** need to merge selected csv imports id and already selected linkdin/icloud.. ids */
        let importIds = [];
        if (
          dropdownData.relationship_path &&
          dropdownData.relationship_path.id &&
          Array.isArray(dropdownData.relationship_path.id)
        ) {
          importIds = dropdownData.relationship_path.id;
        }

        sourceImportId = [...importIds, ...dropDownValue];
      }

      setDashboardDropdownItems({ ...dropdownData });

      if (source) source.cancel('Operation canceled by the user.');

      const { CancelToken } = axios;
      source = CancelToken.source();
      // if (showEditView) fetchData();
      // else
      fetchSelectedApis(selectedItems);
      /** for the first time fetchClientInvData
       * doesn't have lookups (fileImports & events) updated in the datastore.
       * so kept a  seperate useEffect to wait for lookup data.*/
      fetchClientInvData();
    };

    const onClienInvRadioChange = (key) => {
      clientInvolvementKey = key;
      fetchClientInvData();
    };

    const onTriggersRadioFilterChange = (value) => {
      triggerKey = value;
      fetchEventsCount();
    };

    const onListItemSelected = (e) => {
      const selected = e.target.id;
      fetchDataOnSelect(selected);
      if (!selectedItems.includes(selected)) {
        setSelectedItems([...selectedItems, selected]); //update id list to render in draggable list
        setItemList((prev) => ({ ...prev, [selected]: false }));
      }
    };

    const updateGridonDrop = (item) => {
      fetchDataOnSelect(item);
      if (!selectedItems.includes(item))
        setSelectedItems([...selectedItems, item]);
      setItemList((prev) => ({ ...prev, [item]: false }));
      setItemList({ ...itemList });
    };

    const swap = (fromIndex, toIndex, dragData) => {
      const newItem = dragData.label;
      const newItems = [...selectedItems];
      newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, newItem);
      setSelectedItems([...newItems]);
    };

    const onCloseButtonClick = (item) => {
      const index = selectedItems.indexOf(item);
      if (index > -1) {
        selectedItems.splice(index, 1);
      }
      setSelectedItems([...selectedItems]);
      setItemList((prev) => ({ ...prev, [item]: true }));
    };

    const onGraphClick = (id, item, triggerPeriod) => {
      if (!item) return;
      // props.dataStore.summaryFilter =
      //   props.dataStore.summaryFilter || {};

      props.dataStore.summaryFilter = {};
      props.dataStore.summaryFilter = {
        id,
        item,
        ownerTypeId: triggerPeriod ? null : teamValue,
        quickSearchRI: props.dataStore.relationship_quick_search,
        selectedId: props.globalDataStore.multipleFilters.selectedId,
        // team_file_imports: boxClikedItem.team_file_imports || null,
        allTeamFiles: sharedFileIds || [],
        // file_imports: props.dataStore.summaryFilter.file_imports || myFileImportsIds,
        // import_source: props.dataStore.summaryFilter.import_source || importSourceIds,
      };

      if (boxItem && Object.keys(boxItem).length > 0) {
        props.dataStore.summaryFilter.import_source =
          boxItem.import_source || [];
        props.dataStore.summaryFilter.file_imports = boxItem.file_imports || [];
        props.dataStore.summaryFilter.team_file_imports =
          boxItem.team_file_imports || [];
        props.dataStore.summaryFilter.isBoxClicked = true;
      } else if (props.globalDataStore.multipleFilters.selectedId !== -1) {
        const selectedFilter =
          props.globalDataStore.multipleFilters.filterList.filter((item) => {
            return (
              item.value === props.globalDataStore.multipleFilters.selectedId
            );
          });

        const { import_source, file_imports, team_file_imports } =
          selectedFilter[0].filters;

        if (import_source && import_source.length) {
          props.dataStore.summaryFilter.import_source = import_source;
        } else {
          props.dataStore.summaryFilter.import_source = importSourceIds || [];
        }

        if (file_imports && file_imports.length) {
          props.dataStore.summaryFilter.file_imports = file_imports;
        } else {
          props.dataStore.summaryFilter.file_imports = myFileImportsIds || [];
        }

        if (selectedFilter.filter_type !== 'first_degree') {
          if (team_file_imports && team_file_imports.length) {
            props.dataStore.summaryFilter.team_file_imports = team_file_imports;
          } else {
            props.dataStore.summaryFilter.team_file_imports = sharedFileIds.map(
              (file) => {
                return file.id[0];
              },
            );
          }
        }

        props.dataStore.summaryFilter.isBoxClicked = false;
      } else {
        if (id === 'client_inv_org_id' || id === 'client_inv_trigger') {
          props.dataStore.summaryFilter.file_imports =
            props.dataStore.lookUpLists.fileImports.map((file_import) => {
              return file_import.value;
            });
        } else if (id === 'client_inv_files') {
          props.dataStore.summaryFilter.file_imports =
            props.dataStore.lookUpLists.fileImports
              .map((file_import) => {
                return file_import.label === item ? file_import.value : '';
              })
              .filter((id) => id != '');
        } else {
          if (teamValue !== 'all') {
            props.dataStore.summaryFilter.file_imports = myFileImportsIds || [];

            props.dataStore.summaryFilter.import_source = importSourceIds || [];

            props.dataStore.summaryFilter.team_file_imports = sharedFileIds.map(
              (file) => {
                return file.id[0];
              },
            );
          }
        }

        props.dataStore.summaryFilter.isBoxClicked = false;
      }

      if (triggerPeriod) {
        /* Click from triggers overview pie chart in Dashboard */

        props.dataStore.summaryFilter.m2_date =
          getDateRangeFromPeriod(triggerPeriod);

        // if (id === 'triggers_view_box') {
        //   props.dataStore.summaryFilter.triggers_view = true;
        // }
        props.dataStore.summaryFilter.triggers_view = true;
      }
      if (id === 'total_org_count') return true;
      setModalVisibility(true);
    };

    const renderComponents = (id) => {
      switch (id) {
        case 'TriggersOverview':
          return (
            <Triggers
              eventData={eventData}
              triggerTotalCount={triggerTotalCount}
              isLoading={isLoading}
              onClick={onGraphClick}
              radioChange={onTriggersRadioFilterChange}
              onCloseButtonClick={() => onCloseButtonClick('TriggersOverview')}
            />
          );
        case 'CSVImport':
          return (
            <ClientInvolvement
              // clientInvData={clientInvolvementKey === 'file_imports'? csvBoxes : clientInvData}
              csvBoxes={csvBoxes}
              clientInvData={clientInvData}
              onRadioChange={onClienInvRadioChange}
              onGraphClick={onGraphClick}
              isLoading={isClientInvLoading}
              onCloseButtonClick={() => onCloseButtonClick('CSVImport')}
              showCompanyProfile={showCompanyProfile}
            />
          );
        case 'WeeklyEngagement':
          return (
            <WeeklyEngagement
              data={weeklyEngagementData}
              onCloseButtonClick={() => onCloseButtonClick('WeeklyEngagement')}
              isLoading={isLoading}
            />
          );
        case 'WeeklyTip':
          return (
            <WeeklyTip
              onCloseButtonClick={() => onCloseButtonClick('WeeklyTip')}
              data={userDashboardData}
            />
          );
        case 'OpportunitiesbyFilters':
          return (
            <Opportunities
              onCloseButtonClick={() =>
                onCloseButtonClick('OpportunitiesbyFilters')
              }
              data={opportunitiesData}
              importSourceIds={importSourceIds}
              myFileImportsIds={myFileImportsIds}
              sharedFileIds={allTeamFilesImports}
              showCompanyProfile={showCompanyProfile}
              sourceImportId={sourceImportId}
            />
          );
        case 'SuggestedVideo':
          return (
            <SuggestedVideo
              onCloseButtonClick={() => onCloseButtonClick('SuggestedVideo')}
              data={userDashboardData}
            />
          );
        case 'TeamMembers':
          return (
            <TeamMembers
              onCloseButtonClick={() => onCloseButtonClick('TeamMembers')}
              teamId={teamValue}
            />
          );
        case 'ExternalRelationshipPaths':
          return (
            <DataSources
              data={dataSources}
              csvBoxes={csvBoxes}
              // onClick={onHandleBoxClickListener}
              showDataSourceProgress={importSourceLoading}
              onCloseButtonClick={() =>
                onCloseButtonClick('ExternalRelationshipPaths')
              }
              showCompanyProfile={showCompanyProfile}
              team={teamValue}
            />
          );
        case 'NetworkDNA':
          return (
            <NetworkDNA
              industry={industryData}
              stockPerformance={stockPerformance}
              company={companyData}
              title={titleData}
              wealthSegment={wealthSegmentData}
              salary={salaryData}
              zipCodes={zipData}
              city={cityData}
              states={stateData}
              colleges={schoolData}
              houseHoldInterests={interestData}
              onCloseButtonClick={() => onCloseButtonClick('NetworkDNA')}
            />
          );
        case 'TeamRelationshipPaths':
          if (sourceImportId && sourceImportId.length > 0)
            return (
              <div id="team_ids" className="dashboard-item chart-box">
                <div className="chart-box-header">
                  <div className="dashboard-item-title">
                    Team Relationship Paths
                  </div>
                  <div>
                    <CloseCircleFilled
                      onClick={() =>
                        onCloseButtonClick('TeamRelationshipPaths')
                      }
                      style={{ color: 'var(--color-secondary)' }}
                    />
                  </div>
                </div>

                <div className="graph-scroll-section">
                  <div className="dashboard-item-empty">
                    {' '}
                    <p>View currently not available for CSV Imports</p>
                  </div>
                </div>
              </div>
            );
          return (
            <ChartBox
              id="team_ids"
              type="horizontal"
              chartData={teamData ? teamData.top_team_members : null}
              totalCount={teamData ? teamData.total_count : 0}
              onClick={onGraphClick}
              showCompanyProfile={showCompanyProfile}
              onReload={fetchTeamData}
              onCloseButtonClick={() =>
                onCloseButtonClick('TeamRelationshipPaths')
              }
            />
          );
        case 'Companies':
          return (
            <ChartBox
              id="org_name"
              type="list"
              chartData={companyData ? companyData.top_ai_org_ids : null}
              totalCount={companyData ? companyData.total_count : 0}
              onClick={onGraphClick}
              showCompanyProfile={showCompanyProfile}
              box="company"
              onReload={fetchCompanyData}
              onCloseButtonClick={() => onCloseButtonClick('Companies')}
            />
          );
        case 'CompanyViabiliy':
          return (
            <ChartBox
              id="stock_performance"
              type="vertical"
              chartData={
                stockPerformance ? stockPerformance.top_stock_performance : null
              }
              totalCount={stockPerformance ? stockPerformance.total_count : 0}
              onClick={onGraphClick}
              showCompanyProfile={showCompanyProfile}
              onReload={fetchCompanyViabilityData}
              onCloseButtonClick={() => onCloseButtonClick('CompanyViabiliy')}
            />
          );
        case 'Industries':
          return (
            <ChartBox
              id="industry"
              type="horizontal"
              chartData={industryData ? industryData.top_industries : null}
              totalCount={industryData ? industryData.total_count : 0}
              onClick={onGraphClick}
              showCompanyProfile={showCompanyProfile}
              onReload={fetchIndustryData}
              onCloseButtonClick={() => onCloseButtonClick('Industries')}
            />
          );
        case 'TitleRanks':
          return (
            <ChartBox
              id="title_rank"
              type="list"
              chartData={titleData ? titleData.top_titles : null}
              totalCount={titleData ? titleData.total_count : 0}
              onClick={onGraphClick}
              showCompanyProfile={showCompanyProfile}
              box="title"
              onReload={fetchTitleData}
              onCloseButtonClick={() => onCloseButtonClick('TitleRanks')}
            />
          );
        case 'InferredSalaryRanges':
          return (
            <ChartBox
              id="inferred_salary_range"
              type="vertical"
              chartData={salaryData ? salaryData.top_salary_ranges : null}
              totalCount={salaryData ? salaryData.total_count : 0}
              onClick={onGraphClick}
              showCompanyProfile={showCompanyProfile}
              onReload={fetchSalaryData}
              onCloseButtonClick={() =>
                onCloseButtonClick('InferredSalaryRanges')
              }
            />
          );
        case 'Tags':
          return (
            <ChartBox
              id="tags"
              type="horizontal"
              chartData={
                tagData && tagData.results
                  ? props.dataStore.currentUser.hideSuppressTag
                    ? tagData.results.filter((tag) => tag.key !== 'Suppress')
                    : tagData.results
                  : null
              }
              totalCount={tagData ? tagData.count : 0}
              onClick={onGraphClick}
              showCompanyProfile={showCompanyProfile}
              onReload={fetchTagData}
              onCloseButtonClick={() => onCloseButtonClick('Tags')}
            />
          );
        case 'WealthSegment':
          return (
            <ChartBox
              id="top_wealth_estimate"
              type="horizontal"
              chartData={
                wealthSegmentData ? wealthSegmentData.top_wealth_estimate : null
              }
              totalCount={wealthSegmentData ? wealthSegmentData.total_count : 0}
              onClick={onGraphClick}
              showCompanyProfile={showCompanyProfile}
              onReload={fetchWealthSegmentData}
              onCloseButtonClick={() => onCloseButtonClick('WealthSegment')}
            />
          );
        case 'Cities':
          return (
            <ChartBox
              id="location_city"
              type="list"
              chartData={cityData ? cityData.top_cities : null}
              totalCount={cityData ? cityData.total_count : 0}
              onClick={onGraphClick}
              showCompanyProfile={showCompanyProfile}
              onReload={fetchCityData}
              box="city"
              onCloseButtonClick={() => onCloseButtonClick('Cities')}
            />
          );
        case 'StatesandTerritories':
          return (
            <ChartBox
              id="location_state"
              type="horizontal"
              chartData={stateData ? stateData.top_states : null}
              totalCount={stateData ? stateData.total_count : 0}
              onClick={onGraphClick}
              showCompanyProfile={showCompanyProfile}
              onReload={fetchStateData}
              onCloseButtonClick={() =>
                onCloseButtonClick('StatesandTerritories')
              }
            />
          );
        case 'ZipCodes':
          return (
            <ChartBox
              id="home_postal_code"
              type="horizontal"
              chartData={zipData ? zipData.top_zip_codes : null}
              totalCount={zipData ? zipData.total_count : 0}
              onClick={onGraphClick}
              showCompanyProfile={showCompanyProfile}
              onReload={fetchZipData}
              onCloseButtonClick={() => onCloseButtonClick('ZipCodes')}
            />
          );
        case 'CollegesandUniversities':
          return (
            <ChartBox
              id="schools"
              type="horizontal"
              chartData={schoolData ? schoolData.top_colleges : null}
              totalCount={schoolData ? schoolData.total_count : 0}
              onClick={onGraphClick}
              showCompanyProfile={showCompanyProfile}
              onReload={fetchSchoolData}
              onCloseButtonClick={() =>
                onCloseButtonClick('CollegesandUniversities')
              }
            />
          );
        case 'HouseholdInterests':
          return (
            <ChartBox
              id="interests"
              type="horizontal"
              chartData={interestData ? interestData.top_interests : null}
              totalCount={interestData ? interestData.total_count : 0}
              onClick={onGraphClick}
              showCompanyProfile={showCompanyProfile}
              onReload={fetchInterestData}
              onCloseButtonClick={() =>
                onCloseButtonClick('HouseholdInterests')
              }
            />
          );
        default:
          return <></>;
      }
    };

    const showPersonProfile = (e, b2bId, b2cId) => {
      e.stopPropagation();
      // profile clicked
      if (!b2bId && !b2cId) {
        message.warning(
          'This profile cannot be opened right now. Please try again later.',
          10,
        );
        return;
      }
      const { profileModal } = props.modalDataStore;
      profileModal.b2bId = b2bId;
      profileModal.b2cId = b2cId;
      profileModal.mimpersonId = '';
      profileModal.eventsId = '';
      profileModal.isVisible = true;
    };
    if (!getSubscription(props.dataStore.currentUser))
      return (
        <WarningMessage
          iconName="upgrade"
          content="To view, upgrade today!"
          buttonText="Subscribe"
        />
      );

    return (
      <>
        <div className="loader-wrapper">
          {(!userDashboardData ||
            Object.keys(userDashboardData).length === 0 ||
            importSourceLoading ||
            fileImportLoading) && (
            <LoaderAnimation loaderId="dashboard-loader" />
          )}
          <div
            className={
              !userDashboardData ||
              Object.keys(userDashboardData).length === 0 ||
              importSourceLoading ||
              fileImportLoading
                ? 'dashboard-wrapper aid-div-disable'
                : 'dashboard-wrapper aid-div-enable'
            }
          >
            {showEditView && (
              <div className="edit-menu-container">
                <div className="editmenu">
                  <div className="edit-menu-header">
                    Edit View
                    <span className="closebutton" onClick={toggleShowEditView}>
                      <CloseOutlined />
                    </span>
                  </div>
                  <div className="edit-menu-content">
                    <div className="text">Drag up or down to reorder</div>

                    <DragSortListBox
                      itemList={selectedItems}
                      swap={swap}
                      onCloseButtonClick={onCloseButtonClick}
                    />

                    {ungroupedLabels.map((item) => {
                      if (itemList[item])
                        return (
                          <DragDropListItem
                            id={item}
                            onClick={onListItemSelected}
                            targetKey="DragDropListItem"
                          />
                        );
                    })}

                    {/* hide groupname if all grouped items are selected to open */}
                    {!businessInsightsLabels.every((i) =>
                      selectedItems.includes(i),
                    ) && <div className="group-header">BUSINESS INSIGHTS:</div>}

                    {businessInsightsLabels.map((item) => {
                      if (itemList[item])
                        return (
                          <DragDropListItem
                            id={item}
                            onClick={onListItemSelected}
                            targetKey="DragDropListItem"
                          />
                        );
                    })}
                    {!wealthInsightsLabels.every((i) =>
                      selectedItems.includes(i),
                    ) && <div className="group-header">WEALTH INSIGHTS:</div>}

                    {wealthInsightsLabels.map((item) => {
                      if (itemList[item])
                        return (
                          <DragDropListItem
                            id={item}
                            onClick={onListItemSelected}
                            targetKey="DragDropListItem"
                          />
                        );
                    })}
                    {!locationInsightsLabels.every((i) =>
                      selectedItems.includes(i),
                    ) && <div className="group-header">LOCATION INSIGHTS:</div>}

                    {locationInsightsLabels.map((item) => {
                      if (itemList[item])
                        return (
                          <DragDropListItem
                            id={item}
                            onClick={onListItemSelected}
                            targetKey="DragDropListItem"
                          />
                        );
                    })}
                    {!educationInsightsLabels.every((i) =>
                      selectedItems.includes(i),
                    ) && (
                      <div className="group-header">
                        EDUCATION & INTERESTS INSIGHTS:
                      </div>
                    )}

                    {educationInsightsLabels.map((item) => {
                      if (itemList[item])
                        return (
                          <DragDropListItem
                            id={item}
                            onClick={onListItemSelected}
                            targetKey="DragDropListItem"
                          />
                        );
                    })}
                  </div>
                </div>
              </div>
            )}

            <div
              className="right-component"
              style={{
                width: `${showEditView ? 'calc(100% - 250px)' : '100%'}`,
              }}
            >
              <DashboardHeader
                style={{ position: 'fixed' }}
                showEditView={showEditView}
                editView={toggleShowEditView}
                filterData={onFilterChange}
                data={userDashboardData}
                dataSource={dataSources}
                csvBoxes={csvBoxes}
                showPersonProfile={showPersonProfile}
                showCompanyProfile={showCompanyProfile}
                dashboardDropdownSelected={dashboardDropdownItems}
              />
              <div className="header-container">
                <div className="scroll-dummy"></div>
              </div>
              <div style={{ marginTop: '130px' }} className="print-height">
                {selectedItems.length > 0 ? (
                  <DraggableGridBox
                    render={renderComponents}
                    selectedItems={selectedItems}
                    targetKey="DragDropListItem"
                    handleDrop={updateGridonDrop}
                    swap={swap}
                    emptyWarning={false}
                  />
                ) : (
                  // userDashboardData check is for checking the sppinning stop or not
                  userDashboardData &&
                  Object.keys(userDashboardData).length > 0 && (
                    <DraggableGridBox
                      render={renderComponents}
                      selectedItems={selectedItems}
                      targetKey="DragDropListItem"
                      handleDrop={updateGridonDrop}
                      swap={swap}
                      emptyWarning={true}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        {modalVisibility && (
          <PeopleViewModal
            onCancel={() => setModalVisibility(false)}
            showCompanyProfile={showCompanyProfile}
          />
        )}
      </>
    );
  }),
);

export default Dashboard;
