import './index.scss';

import { useEffect, useState } from 'react';
import { Button, message, Tooltip } from 'antd';

import EmailIcon from '../../assets/icons/ai_contacts.svg?react';
import MobileIcon from '../../assets/icons/ai_mobile_phone.svg?react';
import InfoIcon from '../../assets/icons/info.svg?react';

import { requestWithAuth } from '../../services/api';
import CopytoClipBoard from '../CopyToClipBoard';
import { formatPhoneNumber } from '../../utils/general';
import Verified from './Verified';

function ContactInfo({ person, toggleProgress, onDataVerified }) {
  const [emailValidated, setEmailValidated] = useState(false);
  const [phoneValidated, setPhoneValidated] = useState(false);

  useEffect(() => {
    setEmailValidated(!!person.email_verified_on);
  }, [person.email_verified_on]);

  useEffect(() => {
    if (
      person.phones_verified &&
      Object.values(person.phones_verified).every(
        (x) => typeof x.verified === 'boolean',
      )
    ) {
      setPhoneValidated(true);
    } else {
      setPhoneValidated(false);
    }
  }, [person.phones_verified]);

  const hasEmail =
    (person.personal_emails && person.personal_emails.length > 0) ||
    (person.work_emails && person.work_emails.length > 0);

  const hasPhone =
    (person.mobile_phones && person.mobile_phones.length > 0) ||
    (person.other_phones && person.other_phones.length > 0);

  const getEmail = (emails) => {
    let emailLink = '';
    if (emails !== undefined) {
      emailLink = emails.map((email, index) => {
        const emailRedirectLink = `mailto:${email}`;

        return (
          <div key={email} className="email">
            <a
              className="emailDivStyle"
              href={emailRedirectLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="link-value">{email}</span>
            </a>{' '}
            <CopytoClipBoard value={email} textId={'email'} />
          </div>
        );
      });
    }
    return emailLink;
  };

  const getPhoneNumber = (phones) => {
    let phoneNum = '';
    if (phones !== undefined) {
      phoneNum = phones.map((phone) => {
        if (person.phones_verified[phone]) {
          const classLabel =
            phoneValidated && person.phones_verified[phone].verified === false
              ? 'value invalid'
              : 'value';
          const formatedPhone = formatPhoneNumber(phone);
          return (
            <div key={phone} className="phone-info">
              <span className={classLabel} style={{ fontSize: 14 }}>
                {formatedPhone}
              </span>
            </div>
          );
        }
      });
    }
    return phoneNum;
  };

  const validatePhones = () => {
    const phones = [];
    if (person.mobile_phones && person.mobile_phones.length > 0)
      phones.push(
        ...person.mobile_phones.filter(
          (ph) => person.phones_verified[ph].verified === null,
        ),
      );
    if (person.other_phones && person.other_phones.length > 0)
      phones.push(
        ...person.other_phones.filter(
          (ph) => person.phones_verified[ph].verified === null,
        ),
      );
    if (phones && phones.length > 0) toggleProgress(true);
    const body = {};
    body.phones = phones;
    body.b2b_id = person.b2b_id;
    body.b2c_id = person.b2c_id;
    requestWithAuth(
      'realtime-phone-verification',
      'POST',
      null,
      body,
      null,
    ).then((response) => {
      toggleProgress(false);
      if (!response) {
        message.error('Something went wrong. Please try again.');
        return;
      }
      if (response && response.code === 200) {
        setPhoneValidated(true);
        onDataVerified(response.phones_verified, 'phones_verified');
      }
    });
  };

  const validateEmails = () => {
    if (person.personal_emails && person.personal_emails.length > 0)
      verifyEmails(person.personal_emails, 'personal_emails');
    if (person.work_emails && person.work_emails.length > 0)
      verifyEmails(person.work_emails, 'work_emails');
  };

  const verifyEmails = (emails, key) => {
    toggleProgress(true);
    const body = {};
    if (emails) body.email_list = emails;
    requestWithAuth(
      'realtime-email-verification',
      'POST',
      null,
      body,
      null,
    ).then((response) => {
      toggleProgress(false);
      if (!response) {
        message.error('Something went wrong. Please try again.');
        return;
      }
      if (response && response.code === 200) {
        setEmailValidated(true);
        if (response.verified_emails) {
          onDataVerified(response.verified_emails, key);
        }
      }
    });
  };
  return (
    <div className="profile-boxes">
      <div className="profile-box-header">
        <div className="profile-titles">
          Contact Info
          <Tooltip
            placement="top"
            color="var(--color-primary)"
            title='Aidentified consolidates contact information from multiple sources and associates them with profiles. They use third-party services to verify emails and phones, checking deliverability and risks for emails and names associated with phones. Aidentified does not confirm if the contacts are on "Do Not Email" or "Do Not Call" lists. Contact information changes frequently, and there may be instances of non-deliverable emails or phone numbers associated with profiles used by others. Compliance with local and federal guidelines is the responsibility of the user.'
          >
            <InfoIcon className="info-icon" />
          </Tooltip>
        </div>
      </div>
      <div className="border" />
      {hasEmail || hasPhone ? (
        <div className="profile-box-body emails-phones">
          {hasEmail && (
            <div
              style={{
                width: '50%',
              }}
            >
              <div
                style={{
                  textAlign: 'right',
                  marginTop: '7px',
                  marginRight: '6px',
                }}
              >
                {((person.personal_emails &&
                  person.personal_emails.length > 0) ||
                  (person.work_emails && person.work_emails.length > 0)) && (
                  <>
                    {emailValidated ? (
                      <div className="email-phone-verification">
                        Email(s) Verified
                      </div>
                    ) : (
                      <Button
                        className="verify-email-phone-button"
                        onClick={() => {
                          validateEmails();
                        }}
                      >
                        Verify Email(s)
                      </Button>
                    )}
                  </>
                )}
              </div>
              <div className="contact-emails">
                <EmailIcon width={30} style={{ marginLeft: '36px' }} />
                <div>
                  {person.personal_emails &&
                    person.personal_emails.length > 0 && (
                      <div style={{ paddingBottom: 18 }}>
                        <div className="profile-labels flex">
                          <div> Emails - Personal</div>
                          {person.email_verified_on && (
                            <Verified
                              verifiedDate={person.email_verified_on || ''}
                              source="3rd party provider"
                            />
                          )}
                        </div>
                        {getEmail(person.personal_emails)}
                      </div>
                    )}
                  {person.work_emails && person.work_emails.length > 0 && (
                    <>
                      <div className="profile-labels flex">
                        <div> Emails - Professional</div>
                        {person.email_verified_on && (
                          <Verified
                            verifiedDate={person.email_verified_on || ''}
                            source="3rd party provider"
                          />
                        )}
                      </div>
                      {getEmail(person.work_emails)}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {hasEmail && hasPhone && <div className="vertical-line"></div>}
          {hasPhone && (
            <div
              style={{
                width: '50%',
              }}
            >
              <div
                style={{
                  textAlign: 'right',
                  marginTop: '7px',
                  marginRight: '6px',
                }}
              >
                <>
                  {phoneValidated ? (
                    <div className="email-phone-verification">
                      Phone(s) Verified
                    </div>
                  ) : (
                    <Button
                      className="verify-email-phone-button"
                      onClick={() => {
                        validatePhones();
                      }}
                    >
                      Verify Phone(s)
                    </Button>
                  )}
                </>
              </div>

              <div className="contact-phones">
                <MobileIcon width={30} />
                <div>
                  {person.phones_verified &&
                    Object.keys(person.phones_verified).length > 0 && (
                      <div>
                        {person.mobile_phones &&
                          person.mobile_phones.length > 0 && (
                            <div style={{ paddingBottom: 18 }}>
                              <label className="profile-labels"> Mobile</label>
                              {getPhoneNumber(person.mobile_phones)}
                            </div>
                          )}
                        {person.other_phones &&
                          person.other_phones.length > 0 && (
                            <div>
                              <label className="profile-labels"> Other</label>
                              {getPhoneNumber(person.other_phones)}
                            </div>
                          )}
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="no-data">No Contact info available </div>
      )}
    </div>
  );
}

export default ContactInfo;
