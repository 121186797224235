import './CrmCallback.css';

import { Button, message } from 'antd';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

import { requestWithAuth } from '../../services/api';
import { eraseCookie, getCookie } from '../../utils/cookies';
import { getDomain } from '../../utils/general';
import LoaderAnimation from '../../components/AidLoader';

const ContactImportCallback = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const logoutFromApp = () => {
    logout({
      returnTo: `${getDomain()}/user/login`, // FIXME: getDomain is undefined
    });
  };

  const { isAuthenticated, getIdTokenClaims, logout } = useAuth0();
  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        const requestConfig = config;
        if (isAuthenticated) {
          getIdTokenClaims()
            .then((token) => {
              if (!token || !token.__raw) {
                logoutFromApp();
                return;
              }
              if (
                requestConfig &&
                requestConfig.headers &&
                requestConfig.headers.common &&
                requestConfig.headers.common.Authorization
              ) {
                requestConfig.headers.common.Authorization = `Bearer ${token.__raw}`;
              } else if (
                requestConfig &&
                requestConfig.headers &&
                requestConfig.headers.Authorization
              ) {
                requestConfig.headers.Authorization = `Bearer ${token.__raw}`;
              }
            })
            .catch((e) => {
              console.log(e);
              logoutFromApp();
            });
        } else {
          logoutFromApp();
        }
        return requestConfig;
      },
      (error) => {
        console.log(error);
      },
    );
  }, [isAuthenticated]);

  useEffect(() => {
    const param = location.search;
    let slug = getCookie('slug');
    const hostArray = window.location.host.split('.');
    if (
      hostArray &&
      hostArray.length > 0 &&
      hostArray[0] &&
      !hostArray[0].startsWith('localhost')
    ) {
      slug = slug || hostArray[0];

      if (hostArray[0] !== slug) {
        window.location.assign(
          `https://${slug}.aidentified.com/contact/callback/${param}`,
        );
      }
    }
    eraseCookie('slug');

    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (
      query &&
      !(Object.keys(query).length === 0 && query.constructor === Object) &&
      query.code
    ) {
      const body = {
        code: query.code,
      };

      let url = '';
      let params = {};
      if (localStorage.getItem('import_source') === 'google') {
        params = {
          source: 'google',
        };
        url = 'google-contacts';
      } else if (localStorage.getItem('import_source') === 'outlook') {
        params = {
          source: 'outlook',
        };
        url = 'outlook-contacts';
      }

      requestWithAuth(url, 'POST', params, body, null).then((response) => {
        if (!response) return;
        if (response) {
          if (response.code === 200) {
            if (response) navigate('/connect/status');
          } else {
            navigate('/connect/import');
            message.error(response.message, 10);
          }
        }
      });
    } else if (
      query &&
      !(Object.keys(query).length === 0 && query.constructor === Object) &&
      query.error
    ) {
      message.error(query.error, 3);
      navigate('/connect/import');
    } else {
      message.error('Import failed. Please try again', 3);
      navigate('/connect/import');
    }
  }, []);

  return (
    <div className="exception">
      <div className="imgBlock loader-wrapper">
        <div
          style={{
            top: 15,
            display: 'block',
          }}
          id="progress"
        >
          <LoaderAnimation
            loaderId="contact-import-loader"
            style={{ top: '-20px' }}
          />
        </div>
      </div>
      <div className="content">
        <h1>Processing</h1>
        <div className="actions">
          <a href="/connect/import">
            <Button type="primary">Back to settings</Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactImportCallback;
