import './index.scss';

import { useState } from 'react';
import CountUp from 'react-countup';
import { Tooltip, message } from 'antd';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';

import CsvIcon from '../../assets/icons/ai_file_csv.svg?react';

import { getSourceImage, getSourceName } from '../../utils/general';
import PeopleViewModal from '../DashboardComponents/PeopleViewModal';

message.config({
  top: 80,
});

const ImportSummary = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const { data, source, type, showCompanyProfile, team } = props;
    const navigate = useNavigate();
    const [modalVisibility, setModalVisibility] = useState(false);
    function onCountClick(e) {
      e.preventDefault();
      props.dataStore.summaryFilter = props.dataStore.summaryFilter || {};
      // props.dataStore.summaryFilter.ownerTypeId = team;
      //   props.dataStore.overViewTeam.ownerTypeId;
      if (props.type === 'file_imports') {
        if (data && data.shared) {
          props.dataStore.summaryFilter.team_file_imports = data.id;
          props.dataStore.summaryFilter.file_imports = [];
          props.dataStore.summaryFilter.import_source = [];
        } else {
          props.dataStore.summaryFilter.file_imports = data.id;
          props.dataStore.summaryFilter.team_file_imports = [];
          props.dataStore.summaryFilter.import_source = [];
        }
      } else {
        props.dataStore.summaryFilter.import_source = [source.toLowerCase()];
        props.dataStore.summaryFilter.file_imports = [];
        props.dataStore.summaryFilter.team_file_imports = [];
      }
      props.dataStore.summaryFilter.quickSearchRI =
        props.dataStore.relationship_quick_search;
      props.dataStore.summaryFilter.selectedId =
        props.dataStore.networkFilters.selectedIdValue;
      // TODO: currently api is called for entire team, no ME case so in People view entire team will be checked
      props.dataStore.summaryFilter.ownerTypeId =
        props.type === 'file_imports' ? team : 'first';
      // props.history.push({
      //   pathname: '/prospect-finder/people',
      // });
      setModalVisibility(true);
    }

    function onRedirect() {
      if (
        source === 'icloud' ||
        source === 'google' ||
        source === 'outlook' ||
        source === 'linkedin'
      ) {
        navigate(`/connect/import`, {
          state: {
            fromSource: getSourceName(source),
          },
        });
      }
      // else {
      //   navigate(
      //     `/connect/status`,
      //     {state: {
      //       expandStatus: true,
      //     },
      //   });
      // }
    }

    function onBoxClick() {
      const { onBoxClick } = props;
      const boxItem = {};
      if (type === 'file_imports') {
        if (data.shared) boxItem.team_file_imports = data.id;
        else boxItem.file_imports = data.id;
      } else {
        boxItem.import_source = [source.toLowerCase()];
      }
      if (onBoxClick) onBoxClick(data.id, boxItem);
    }

    const showPersonProfile = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!data) return;
      const { owner_b2b_id, owner_b2c_id } = data;
      // profile clicked
      if (!owner_b2b_id && !owner_b2c_id) {
        message.warning(
          'This profile cannot be opened right now. Please try again later.',
          10,
        );
        return;
      }
      const { profileModal } = props.modalDataStore;
      profileModal.b2bId = owner_b2b_id;
      profileModal.b2cId = owner_b2c_id;
      profileModal.mimpersonId = '';
      profileModal.eventsId = '';
      profileModal.isVisible = true;
    };

    // if (source === 'status')
    //   return (
    //     <div className="import-summary-block" onClick={onRedirect}>
    //       <div className="status-block">Import Status</div>
    //     </div>
    //   );

    if (!type || type !== 'file_imports') {
      return (
        <div
          className="import-summary-block"
          onClick={
            data && data.enriched_contacts && data.enriched_contacts !== 0
              ? onBoxClick
              : onRedirect
          }
        >
          <div className="count-section">
            {data && data.enriched_contacts && data.enriched_contacts !== 0 ? (
              <div
                className="count"
                onClick={onCountClick}
                // style={
                //   data &&
                //   data.enriched_contacts &&
                //   data.enriched_contacts !== 0 && { order: '2' }
                // }
              >
                <CountUp
                  className="nav-pointer"
                  separator=","
                  end={data.enriched_contacts}
                  duration={2.75}
                />
              </div>
            ) : (
              <div className="connect">Connect</div>
            )}
            <div
              className="source"
              // style={
              //   data &&
              //   data.enriched_contacts &&
              //   data.enriched_contacts !== 0 && { order: '1' }
              // }
            >
              <span>{getSourceName(source)}</span>
              <img
                className="box-images"
                src={getSourceImage(source)}
                alt="sourceimage"
              />
            </div>
            {modalVisibility && (
              <PeopleViewModal
                onCancel={() => setModalVisibility(false)}
                showCompanyProfile={showCompanyProfile}
              />
            )}
          </div>
          <div
            className="date-section"
            style={{
              display:
                data &&
                data.enriched_contacts &&
                data.enriched_contacts !== 0 &&
                data.last_import
                  ? 'block'
                  : 'none',
              backgroundColor:
                data &&
                data.enriched_contacts &&
                data.enriched_contacts !== 0 &&
                data.last_import
                  ? 'var(--color-light)'
                  : 'transparent',
            }}
          >
            {data &&
            data.enriched_contacts &&
            data.enriched_contacts !== 0 &&
            data.last_import
              ? `Last Import: ${moment(data.last_import).format('MM/DD/YYYY')}`
              : 'No Imports'}
          </div>
        </div>
      );
    }

    if (!data.enriched_contacts) return <></>;

    return (
      <>
        <div className="import-summary-csv-block" onClick={onBoxClick}>
          <div className="csv-left">
            {data.shared ? (
              <div className="shared-file-icon">
                <span style={{ color: 'var(--color-primary)' }}>Shared </span>
                <CsvIcon height={18} />
              </div>
            ) : (
              <div
                className="shared-file-icon"
                style={{ borderColor: 'transparent' }}
              >
                <CsvIcon height={18} />
              </div>
            )}
            <div
              className="file-name-section"
              style={{
                height:
                  props.dataStore.overViewTeam.ownerType === 'team'
                    ? '55px'
                    : 'unset',
              }}
            >
              <Tooltip
                title={source}
                placement="topLeft"
                color="var(--color-light)"
                overlayInnerStyle={{
                  color: 'var(--color-primary)',
                }}
              >
                <div>{source}</div>
              </Tooltip>
              <div className="date-section">
                {data.last_import
                  ? `Import: ${moment(data.last_import).format('MM/DD/YYYY')}`
                  : ''}
              </div>
            </div>
          </div>
          <div className="csv-right">
            {data && data.shared && data.owner_name && (
              <>
                <span style={{ color: 'var(--color-grey-dark)' }}>by </span>
                <span
                  className="link"
                  style={{
                    paddingLeft: '3px',
                    paddingRight: '3px',
                  }}
                  onClick={showPersonProfile}
                >
                  {data.owner_name}
                </span>
              </>
            )}
            <div className="count" onClick={onCountClick}>
              <CountUp
                className="nav-pointer"
                separator=","
                end={data.enriched_contacts}
                duration={2.75}
              />
            </div>
          </div>
        </div>
        {modalVisibility && (
          <PeopleViewModal
            onCancel={() => setModalVisibility(false)}
            showCompanyProfile={showCompanyProfile}
          />
        )}
      </>
    );
  }),
);

export default ImportSummary;
