import './index.scss';

import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, message } from 'antd';
import axios from 'axios';

import HandShake from '../../assets/icons/ai_handshake.svg?react';

import HeaderSection from '../../components/RISubComponents/HeaderSection';
import Filter from '../../components/RISubComponents/Filter';
import ContactPerson from '../../components/RISubComponents/ContactPerson';
import ContactAndIntroduction from '../../components/RISubComponents/ContactsAndIntroduction';
import Matches from '../../components/RISubComponents/Matches';
import NoOpportunityData from '../../components/RISubComponents/NoOpportunityData';
import WarningMessage from '../../components/WarningMessage';
import { requestWithAuth } from '../../services/api';
import { getSubscription } from '../../utils/general';
import LoaderAnimation from '../../components/AidLoader';

message.config({
  top: 80,
});
let source;

function getDegreeFormats(degree) {
  switch (degree) {
    case 1:
      return {
        class: 'high-indicator',
        label: '1st',
      };
    case 2:
      return {
        class: 'medium-indicator',
        label: '2nd',
      };
    default:
      return {
        class: '',
        label: '',
      };
  }
}

const SuperConnectors = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const [user, setUser] = useState({ name: '', crm: '' });
    const [superConnectorList, setSuperConnectorList] = useState([]);
    const [superConnectorsCount, setSuperConnectorsCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      props.dataStore.lookUpLists.isSuperConnectorsPage = true;
      const { CancelToken } = axios;
      source = CancelToken.source();
      const { crm, org_name, full_name } = props.dataStore.currentUser;
      setUser({
        name: full_name || '',
        crm: crm || '',
        org_name: org_name || '',
      });
      if (getSubscription(props.dataStore.currentUser)) {
        if (
          props.globalDataStore.multipleFilters.selectedIdList &&
          props.globalDataStore.multipleFilters.selectedIdList.length > 0
        ) {
          fetchData();
        }
      }

      return () => {
        props.dataStore.lookUpLists.isSuperConnectorsPage = false;
        message.destroy();
        if (source) source.cancel('Operation canceled by the user.');
      };
    }, []);

    useEffect(() => {
      if (
        props.globalDataStore.multipleFilters.selectedIdList &&
        props.globalDataStore.multipleFilters.selectedIdList.length > 0
      ) {
        if (source) source.cancel('Operation canceled by the user.');
        const { CancelToken } = axios;
        source = CancelToken.source();
        setSuperConnectorsCount(0);
        setSuperConnectorList([]);
        fetchData();
      }
    }, [props.globalDataStore.multipleFilters.selectedIdList]);

    function toggleProgress(toggle) {
      setIsLoading(toggle);
    }

    function fetchData(fromPage) {
      let ids = [];
      if (
        props.globalDataStore.multipleFilters.selectedIdList &&
        props.globalDataStore.multipleFilters.selectedIdList.length > 0
      ) {
        // ids = [...props.globalDataStore.multipleFilters.selectedIdList];
        ids = props.globalDataStore.multipleFilters.selectedIdList.map(
          (obj) => obj.value,
        );
        const index = ids.indexOf(-1);
        if (index > -1) {
          ids.splice(index, 1);
        }
        if (!ids || ids.length <= 0) {
          setIsLoading(false);
          return;
        }
      }
      setIsLoading(true);
      let list = [];
      const params = {
        from: 0,
        page_size: 25,
      };

      /** case of unhide button click */
      if (
        props.globalDataStore.multipleFilters.selectedIdList &&
        props.globalDataStore.multipleFilters.selectedIdList.length === 1 &&
        props.globalDataStore.multipleFilters.selectedIdList[0].value === -2
      ) {
        params.hidden_opportunities = 1;
        ids = [];
      }
      if (fromPage && fromPage > 0) {
        list = superConnectorList;
        params.from = fromPage;
      }
      const body = {
        filter_ids: [],
        // collapse_field: 'b2b_id',
      };
      body.filter_ids = ids;
      requestWithAuth(
        'super-connectors-new',
        'POST',
        params,
        body,
        source.token,
        'progress_icon',
      ).then((response) => {
        if (response === null) {
          /** handling error here to set nodata to avoid the blank screen */
          setSuperConnectorsCount(0);
          setSuperConnectorList([]);
          setIsLoading(false);
          return;
        }

        if (!response) return;
        // if (props.globalDataStore.multipleFilters.selectedIdList.some((opt) => opt.value === -2))
        //   message.info('Hidden Opportunities are showing now');
        if (response && response.results && response.results.length > 0) {
          setSuperConnectorsCount(response.count);
          list.push(...response.results);
          setSuperConnectorList(list);
          setIsLoading(false);
          return;
        }
        if (response && response.message) {
          message.error(response.message, 10);
        } else if (response && response.detail) {
          message.error(response.detail, 10);
        }
        setSuperConnectorsCount(0);
        setSuperConnectorList([]);
        setIsLoading(false);
        props.globalDataStore.multipleFilters.fromFilters = false;
      });
    }
    function showProfile(b2bId, b2cId) {
      // profile clicked
      if (!b2bId && !b2cId) {
        message.warning(
          'This profile cannot be opened right now. Please try again later.',
          10,
        );
        return;
      }
      const { profileModal } = props.modalDataStore;
      profileModal.b2bId = b2bId;
      profileModal.b2cId = b2cId;
      profileModal.mimpersonId = '';
      profileModal.eventsId = '';
      profileModal.isVisible = true;
    }

    function showCompanyProfile(id, orgName) {
      if (!id) return;
      // click on any company name
      props.modalDataStore.resetProfileModal();
      props.modalDataStore.resetCompanyProfileModal();
      const { companyProfileModal } = props.modalDataStore;
      companyProfileModal.id = id;
      companyProfileModal.orgName = orgName;
      companyProfileModal.isVisible = true;
    }

    function addReminder(isReminder, value, b2bId, b2cId, index) {
      setIsLoading(true);
      const body = {};
      body.source_type = 'SC';
      if (isReminder) body.remind_after = value;
      else if (
        props.globalDataStore.multipleFilters.selectedIdList.some(
          (opt) => opt.value === -2,
        )
      )
        body.is_hidden = 0;
      else body.is_hidden = 1;
      if (b2bId) body.b2b_id = b2bId;
      if (b2cId) body.b2c_id = b2cId;
      requestWithAuth(
        'opportunities-reminder',
        'POST',
        null,
        body,
        source.token,
        'progress_icon',
      ).then((response) => {
        if (response && response.code === 200) {
          if (superConnectorList && superConnectorList.length > index)
            superConnectorList.splice(index, 1);
          setSuperConnectorsCount(superConnectorsCount - 1);
          setIsLoading(false);
          return;
        }
        if (response && response.message) {
          message.error(response.message, 10);
        } else if (response && response.detail) {
          message.error(response.detail, 10);
        }
        setIsLoading(false);
      });
    }

    if (!getSubscription(props.dataStore.currentUser)) {
      return (
        <WarningMessage
          iconName="upgrade"
          content="To view executives, upgrade today!"
          buttonText="Subscribe"
        />
      );
    }
    return (
      <div className="super-connector-container loader-wrapper">
        {isLoading && <LoaderAnimation loaderId="super-connector-loader" />}
        <div className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
          {!superConnectorList || superConnectorList.length <= 0 ? (
            <>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {props.globalDataStore.multipleFilters.selectedIdList &&
                  props.globalDataStore.multipleFilters.selectedIdList.length >
                    0 && (
                    <div style={{ width: '300px' }}>
                      <div
                        style={{
                          color: 'var(--color-dark-shade)',
                          fontSize: '14px',
                        }}
                      >
                        Saved Searches:
                      </div>
                      <Filter
                      // updateAfterSelect={fetchData}
                      />
                    </div>
                  )}
              </div>
              {!isLoading && <NoOpportunityData page="Super Connectors" />}
            </>
          ) : (
            <>
              <div className="caption">
                <div>
                  Super Connectors know your prospects - reach out today!
                </div>
                {props.globalDataStore.multipleFilters.selectedIdList &&
                  props.globalDataStore.multipleFilters.selectedIdList.length >
                    0 && (
                    <div style={{ width: '300px' }}>
                      <div
                        style={{
                          color: 'var(--color-dark-shade)',
                          fontSize: '14px',
                        }}
                      >
                        Saved Searches:
                      </div>

                      <Filter
                      // updateAfterSelect={fetchData}
                      />
                    </div>
                  )}
              </div>

              {superConnectorList && superConnectorList.length > 0 && (
                <div id="superconnector-data">
                  {superConnectorList.map((item, index) => {
                    // if (item.remind_days > 0)
                    //   return reminderSection(item, index);
                    return (
                      <div className="card-item">
                        <div className="top-section topSection-superconnector">
                          <div className="header-superConnector">
                            Super Connector
                          </div>
                        </div>
                        <div className="card-content">
                          <div className="card-details-left">
                            <HeaderSection
                              data={item}
                              index={index}
                              getDegreeFormats={getDegreeFormats}
                              user={user}
                              toggleProgress={toggleProgress}
                              showProfile={showProfile}
                              showCompanyProfile={showCompanyProfile}
                            />

                            {/* <div className="card-details-main">
                              <div style={{ width: 70 }} />
                              {item &&
                                item.events &&
                                Object.keys(item.events).length !== 0 && (
                                  <OpportunityInfo data={item.events} />
                                )}
    
                              <div className="flex reminder-section">
                                <Bell fill="var(--color-primary)" />
                                <span>Remind Me: </span>
                                <Radio.Group
                                  onChange={(e) => {
                                    addReminder(
                                      true,
                                      e.target.value,
                                      item.b2b_id,
                                      item.b2c_id,
                                      index,
                                    );
                                  }}
                                  buttonStyle="solid"
                                >
                                  <Radio.Button value={1}>Tomorrow</Radio.Button>
                                  <Radio.Button value={3}>3 days</Radio.Button>
                                  <Radio.Button value={7}>1 week</Radio.Button>
                                  <Radio.Button value={14}>2 weeks</Radio.Button>
                                </Radio.Group>
                              </div>
                            </div> */}

                            <div className="card-details-main">
                              {item.filter_list &&
                                item.filter_list.length > 0 && (
                                  <Matches
                                    b2bId={item.b2b_id}
                                    filterList={item.filter_list}
                                    fullName={item.full_name}
                                    showProfile={showProfile}
                                    showCompanyProfile={showCompanyProfile}
                                  />
                                )}
                            </div>
                          </div>
                          {item.degree === 1
                            ? ((item.emails && item.emails.length > 0) ||
                                (item.phones && item.phones.length > 0)) && (
                                <ContactPerson data={item} />
                              )
                            : item.mutual_count > 0 && (
                                <div className="card-right-section">
                                  <div className="card-right-title">
                                    <div className="flex">
                                      <HandShake /> Get introduction (
                                      {item.mutual_count})
                                    </div>
                                  </div>
                                  <ContactAndIntroduction
                                    data={item.mutual_contacts}
                                    theme="golden"
                                    opportunity_degree={item.degree}
                                    openProfileTab={showProfile}
                                    showCompanyProfile={showCompanyProfile}
                                  />
                                </div>
                              )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="show-more-section">
                <Button
                  className="show-more-button"
                  onClick={() => {
                    fetchData(superConnectorList.length);
                  }}
                  style={{
                    visibility: `${
                      superConnectorList &&
                      superConnectorList.length < superConnectorsCount
                        ? 'visible'
                        : 'hidden'
                    }`,
                  }}
                >
                  Show More
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }),
);

export default SuperConnectors;
