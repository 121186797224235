import React from 'react';
import './index.scss';

/** AiButton can be customised using css with the help of @buttonclassName and @buttonStyle */

/** In future if new @type, @variant or @shape is needed,*/
/** Include the expected values in the comments next to each property for future reference  */
/** Additionally, to apply styles,
 * please use the respective value as the class name, as we have already defined in the SCSS file */

interface AiButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'cta'
    | 'text'
    | 'danger'
    | 'icon';
  size?: string /* Expected button size in px */;
  color?: string;
  shape?: string /* Expected rounded */;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  variant?: 'solid' | 'outline' | 'ghost' | 'dashed' | 'link';
  buttonclassName?: string;
  buttonStyle?: React.CSSProperties;
}

const AiButton: React.FC<AiButtonProps> = ({
  buttonType = 'default',
  size = '',
  color = 'var(--color-header-blue-variant)',
  shape = '',
  leftIcon = null,
  rightIcon = null,
  disabled = false,
  variant = leftIcon || rightIcon ? 'solid' : undefined,
  buttonclassName = '',
  buttonStyle = {},
  children,
  ...rest /* Captures onClick,type etc. */
}) => {
  const getButtonColor = (type: AiButtonProps['buttonType']) => {
    switch (type) {
      case 'primary':
      case 'text':
      case 'icon':
        return 'var(--color-header-blue-variant)';
      case 'secondary':
        return 'var(--color-secondary)';
      case 'danger':
        return 'var(--color-danger)';
      case 'cta':
        return '#66E2B7';
      default:
        return color;
    }
  };

  const buttonClass = [
    'ai-button',
    `ai-button-${buttonType}`,
    variant && `ai-button-${variant}`,
    shape && `ai-button-${shape}`,
    disabled ? 'ai-button-disabled' : '',
    buttonclassName,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <button
      className={buttonClass}
      disabled={disabled}
      style={{
        fontSize: size || '14px',
        ...buttonStyle,
        ...{ '--button-color': getButtonColor(buttonType) },
      }}
      {...rest} // Spread props like onClick, type, etc.
    >
      {leftIcon && <span className="ai-button-left-icon">{leftIcon}</span>}
      {children}
      {rightIcon && <span className="ai-button-right-icon">{rightIcon}</span>}
    </button>
  );
};

interface AiButtonGroupProps {
  direction?: 'row' | 'column';
  gap?: number;
  width?: string;
  children: React.ReactNode;
  buttonclassName?: string;
  buttonStyle?: React.CSSProperties;
}

const AiButtonGroup: React.FC<AiButtonGroupProps> = ({
  direction = 'row',
  gap = 0,
  width = 'max-content',
  children,
  buttonclassName = '',
  buttonStyle = {},
}) => {
  const groupStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: direction,
    gap: `${gap}px`,
    width,
    ...buttonStyle,
  };

  return (
    <div className={`ai-button-group ${buttonclassName}`} style={groupStyle}>
      {children}
    </div>
  );
};

export { AiButton, AiButtonGroup };
