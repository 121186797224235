import './index.scss';

import { NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import DashboardIcon from '../../assets/icons/ai_nav_dashboard.svg?react';
import ProspectFinderIcon from '../../assets/icons/ai_nav_prospect_finder.svg?react';
import FilterIcon from '../../assets/icons/ai_nav_filter.svg?react';
import ConnectIcon from '../../assets/icons/ai_nav_connection.svg?react';
import SettingsIcon from '../../assets/icons/ai_settings.svg?react';
import AlertIcon from '../../assets/icons/ai_alert.svg?react';
import { avatars } from '../../utils/constants';

function NavigationBar(props) {
  const { profileImageReference, profileImageType, full_name } =
    props.dataStore.currentUser;
  const userAvatar = avatars.find((av) => av.key === profileImageReference);

  function getInitials() {
    const names = full_name ? full_name.split(' ') : [];
    const firstInitial = names[0] ? names[0].charAt(0).toUpperCase() : '';
    const lastInitial =
      names.length > 1 ? names[names.length - 1].charAt(0).toUpperCase() : '';
    return `${firstInitial}${lastInitial}`;
  }

  const renderProfileIcon = () => {
    if (
      profileImageReference &&
      profileImageType &&
      profileImageType === 'uploaded'
    ) {
      return (
        <img
          style={{ width: '100%', height: '100%', borderRadius: '50%' }}
          src={profileImageReference}
          alt="User"
        />
      );
    } else if (
      profileImageReference &&
      profileImageType &&
      profileImageType === 'placeholder'
    ) {
      return userAvatar ? <userAvatar.Icon /> : getInitials();
    } else {
      return getInitials();
    }
  };

  return (
    <>
      <div></div>
      <NavLink
        to="/relationship-intelligence"
        className={({ isActive }) =>
          isActive ? 'navigation-item selected-nav-new' : 'navigation-item'
        }
      >
        <>
          <DashboardIcon />
          <span className="navigation-text">Dashboard</span>
        </>
      </NavLink>
      <div></div>
      <NavLink
        to="/prospect-finder"
        className={({ isActive }) =>
          isActive ? 'navigation-item selected-nav-new' : 'navigation-item'
        }
      >
        <>
          <ProspectFinderIcon />
          <span className="navigation-text">Prospect Finder</span>
        </>
      </NavLink>
      <div></div>
      <NavLink
        to="/savedsearches"
        className={({ isActive }) =>
          isActive ? 'navigation-item selected-nav-new' : 'navigation-item'
        }
      >
        <>
          <FilterIcon />
          <span className="navigation-text">Saved Searches</span>
        </>
      </NavLink>
      <div></div>
      <NavLink
        to="/connect"
        className={({ isActive }) =>
          isActive ? 'navigation-item selected-nav-new' : 'navigation-item'
        }
      >
        <>
          <ConnectIcon style={{ maxWidth: '37px' }} />
          <span className="navigation-text">Import & Export</span>
        </>
      </NavLink>
      <div></div>
      <NavLink to="/account" className="navigation-item settings-nav">
        <div style={{ position: 'relative' }}>
          {props.globalDataStore.notificationCount > 0 && (
            <AlertIcon className="notification-alert" />
          )}
          <div className="settings-profile-icon">{renderProfileIcon()}</div>
          {/* <SettingsIcon className="settings-profile-icon" /> */}
        </div>
        <span className="navigation-text" style={{ color: '#003153' }}>
          Settings
        </span>
      </NavLink>
      {/* <div className='settings-expand'></div> */}
    </>
  );
}

export default inject('globalDataStore', 'dataStore')(observer(NavigationBar));
