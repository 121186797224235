import './index.scss';
import { useState, useRef, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Input, Button, message, Modal, Checkbox, Tooltip, Form } from 'antd';
import { EditOutlined, TagsFilled } from '@ant-design/icons';

import HelpIcon from '../../assets/icons/help-icon.svg?react';
import DeleteIcon from '../../assets/icons/ai_delete_bin.svg?react';

import { requestWithAuth } from '../../services/api';
import { showConfirm } from '../../utils/popup';
import { getBodyParams } from '../../utils/getBodyParams';
import LoaderAnimation from '../AidLoader';

const TagModal = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props) => {
    const {
      addFromTable,
      removeFromTable,
      fromProfile,
      fromRow,
      selectedProfileIds,
      profileTags = [],
      isLoading,
      updatePerson,
      handleCreate,
      refreshData,
      resetSelection,
      onClose,
      // selectAllRows,
    } = props;

    const [checkedValues, setCheckedValues] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
      if (profileTags && profileTags.length > 0) {
        setCheckedValues([...profileTags.map((item) => item.tag)]);
      }
    }, [profileTags]);

    const handleAddTagsToProfiles = (tagIds) => {
      if (tagIds.length === 0) {
        message.error('Please select tags to add', 5);
        return;
      }
      let body = {};
      const params = {};

      if (selectedProfileIds.length === 0) {
        /** No profile selected - means, whole serached data */
        if (
          !props.modalDataStore.profileModal ||
          !props.modalDataStore.profileModal.mappedRelationshipsFilter
            .filterName
        ) {
          if (props.dataStore.quick_search)
            params.quick_search = props.dataStore.quick_search;
          if (props.dataStore.networkFilters.filter_type) {
            params.filter_type = props.dataStore.networkFilters.filter_type;
          }
        }
        body = getBodyParams(
          props.dataStore.networkFilters,
          props.dataStore.lookUpLists,
          props.dataStore.superConnectorFilter,
          props.dataStore.teamFilters,
          props.dataStore.currentUser,
          props.dataStore.mappedRelationshipsFilter,
          props.modalDataStore.profileModal,
          props.dataStore.groupByValues,
        );
      } else {
        body.b2b_b2c_list = selectedProfileIds;
      }
      // body.b2b_b2c_list = selectedProfileIds;
      body.tag_ids = tagIds;

      requestWithAuth('profile-tags', 'POST', params, body, null)
        .then((response) => {
          if (response && response.code === 200) {
            if (!fromProfile && !fromRow)
              message.success('Tags are added successfully', 5);
            else message.success('Tags are updated successfully', 5);
            if (resetSelection) resetSelection();
            if (refreshData) refreshData();
          }
        })
        .catch((err) => {
          message.error('Please try again', 5);
          console.log(err);
        });
      onClose();
    };

    const handleRemoveTagsFromProfiles = (tagIds) => {
      if (tagIds.length === 0) {
        message.error('Please select profiles and tags to remove', 10);
        return;
      }
      let body = {};
      const params = {};
      if (selectedProfileIds.length === 0) {
        /** No profile selected - means, whole serached data */
        if (
          !props.modalDataStore.profileModal ||
          !props.modalDataStore.profileModal.mappedRelationshipsFilter
            .filterName
        ) {
          if (props.dataStore.quick_search)
            params.quick_search = props.dataStore.quick_search;
          if (props.dataStore.networkFilters.filter_type) {
            params.filter_type = props.dataStore.networkFilters.filter_type;
          }
        }
        body = getBodyParams(
          props.dataStore.networkFilters,
          props.dataStore.lookUpLists,
          props.dataStore.superConnectorFilter,
          props.dataStore.teamFilters,
          props.dataStore.currentUser,
          props.dataStore.mappedRelationshipsFilter,
          props.modalDataStore.profileModal,
          props.dataStore.groupByValues,
        );
      } else {
        body.b2b_b2c_list = selectedProfileIds;
      }
      // body.b2b_b2c_list = selectedProfileIds;
      body.tag_ids = tagIds;
      requestWithAuth(
        'profile-tags/delete-multiple',
        'POST',
        params,
        body,
        null,
      )
        .then((response) => {
          if (response && response.code === 200) {
            if (!fromProfile && !fromRow)
              message.success('Tags are removed successfully', 5);
            if (resetSelection) resetSelection();
            if (refreshData) refreshData();
          }
        })
        .catch((err) => {
          message.error('Please try again', 5);
          console.log(err);
        });
      onClose();
    };

    const handleProfileTagUpdate = (checkedTagIds) => {
      /** In case of adding tags from profile or Row(old favorite) -
       *  a popup will show up and we can select or deselect tags
       *  we need to ADD the selectes tags to profile
       *  and we neeed to DELETE deselected tags from profile
       */
      /** pid is added to show the close button for newly created tags */
      const selectedTagList = props.dataStore.userTags
        .map((item) => {
          if (
            checkedTagIds.includes(item.id) &&
            !profileTags.some((el) => el.tag === item.id)
          ) {
            return { tag: item.id, tag_name: item.tag, pid: -1 };
          }
        })
        .filter((item) => !!item);
      const removedTagItems = profileTags.filter((el) => {
        if (el.tag) return !checkedTagIds.includes(el.tag);
      });
      if (selectedTagList && selectedTagList.length > 0) {
        handleAddTagsToProfiles(selectedTagList.map((item) => item.tag));
      }
      if (removedTagItems && removedTagItems.length) {
        handleRemoveTagsFromProfiles(removedTagItems.map((item) => item.tag));
      }
      const updatedtags = [...profileTags, ...selectedTagList].filter(
        (item) => !removedTagItems.some((el) => el.tag === item.tag),
      );

      if (updatePerson) updatePerson('tags_details', updatedtags);

      onClose();
    };

    const handleSave = (checkedTagIds) => {
      if (fromProfile || fromRow) return handleProfileTagUpdate(checkedTagIds);
      if (addFromTable) return handleAddTagsToProfiles(checkedTagIds);
      if (removeFromTable) return handleRemoveTagsFromProfiles(checkedTagIds);
    };

    return (
      <Modal
        title={
          removeFromTable
            ? 'Detach Tags to Profile(s)'
            : 'Attach Tags to Profile(s)'
        }
        wrapClassName="small-popup"
        // centered
        open
        closable={true}
        okText={
          removeFromTable ? 'Remove from Profile(s)' : 'Add to Profile(s)'
        }
        onOk={() => handleSave(checkedValues)}
        onCancel={() => {
          onClose();
          // setShowTagsPopUp(false);
          if (resetSelection) resetSelection();
        }}
      >
        <div className="loader-wrapper">
          {isLoading && <LoaderAnimation loaderId="tags-loader" size="small" />}
          <div
            className={
              isLoading
                ? 'tag-parent aid-div-disable'
                : 'tag-parent aid-div-enable'
            }
          >
            <Form
              form={form}
              className="tag-create"
              onFinish={(values) => {
                handleCreate(values.tag);
                form.resetFields();
              }}
            >
              <Form.Item name="tag">
                <Input placeholder="Tag Name" />
              </Form.Item>
              <Button className="tag-button" htmlType="submit">
                Create New
              </Button>
            </Form>

            <div style={{ height: '200px', overflowY: 'auto' }}>
              <Checkbox.Group
                value={checkedValues}
                onChange={(checkValues) => {
                  setCheckedValues(checkValues);
                }}
              >
                {props.dataStore.userTags.map(({ id, tag }) => {
                  return (
                    <div className="tags-row">
                      <Checkbox value={id}>{tag}</Checkbox>
                      {tag === 'Suppress' && (
                        <Tooltip
                          placement="bottom"
                          color="var(--color-primary)"
                          title={
                            <span style={{ color: 'var(--color-white)' }}>
                              Suppress Tag removes profile from search results,
                              filters, daily opportunities and email exports.
                            </span>
                          }
                        >
                          <HelpIcon
                            id="suppress"
                            height="16px"
                            width="16px"
                            className="help-icon"
                            onClick={(e) => e.stopPropagation()}
                          />
                        </Tooltip>
                      )}
                    </div>
                  );
                })}
              </Checkbox.Group>
            </div>
          </div>
        </div>
      </Modal>
    );
  }),
);

const Tags = inject('dataStore')(
  observer((props) => {
    const {
      selectedProfileIds,
      profileTags = [],
      tagLabel,
      tagClassName,
      id,
      isUpdate,
      fromProfile,
      addFromTable,
      removeFromTable,
      fromRow,
      // selectAllRows,
    } = props;

    const [showTagsPopUp, setShowTagsPopUp] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [profileTagsData, setProfileTagsData] = useState(profileTags);
    const tagRef = useRef(null);

    const fetchUserTags = () => {
      requestWithAuth('tags', 'GET', null, null, null)
        .then((response) => {
          if (response && response.length) {
            props.dataStore.userTags = [...response];
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    };

    const fetchProfileTags = () => {
      setIsLoading(true);
      const params = {
        b2b_id: selectedProfileIds[0].b2b_id,
      };
      if (selectedProfileIds[0].b2c_id) {
        params.b2c_id = selectedProfileIds[0].b2c_id;
      }
      requestWithAuth('profile-tags', 'GET', params, null, null)
        .then((response) => {
          if (response && response.length) {
            setProfileTagsData([...response]);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    };

    const handleCreate = (tagName) => {
      setIsLoading(true);
      requestWithAuth(
        'tags',
        'POST',
        null,
        {
          tag: tagName,
        },
        null,
      )
        .then((response) => {
          if (response && response.code === 200) {
            fetchUserTags();
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          message.error('Please try again', 5);
          console.log(err);
          setIsLoading(false);
        });
    };

    const handleUpdate = () => {
      const newName = tagRef.current.input.value;
      requestWithAuth(
        `tags/${id}`,
        'PUT',
        null,
        {
          tag: newName,
        },
        null,
      )
        .then((response) => {
          if (response && response.code === 200) {
            props.dataStore.userTags.find((obj) => obj.id === id).tag = newName;
          }
        })
        .catch((err) => {
          message.error('Please try again', 5);
          console.log(err);
        });
    };

    const handleDelete = () => {
      requestWithAuth(
        'tags/delete-multiple',
        'POST',
        null,
        {
          tag_ids: [id],
        },
        null,
      )
        .then((response) => {
          if (response && response.code === 200) {
            props.dataStore.userTags = props.dataStore.userTags.filter(
              (obj) => obj.id !== id,
            );
          }
        })
        .catch((err) => {
          message.error('Please try again', 5);
          console.log(err);
        });
    };

    const renderCreateModal = () => {
      showConfirm({
        className: 'small-popup',
        title: 'Create New Tag',
        closable: true,
        content: <Input ref={tagRef} placeholder="Tag Name" />,
        okText: 'Save',
        onOk: () => {
          handleCreate(tagRef.current.input.value);
        },
        onCancel: () => {
          return false;
        },
      });
    };

    const renderEditModal = () => {
      showConfirm({
        className: 'small-popup',
        title: 'Edit Tag',
        closable: true,
        content: (
          <Input
            ref={tagRef}
            defaultValue={tagLabel || ''}
            name="tagName"
            placeholder="Tag Name"
          />
        ),
        okText: 'Update',
        onOk: () => {
          handleUpdate();
        },
        onCancel: () => {
          return false;
        },
      });
    };

    const renderDeleteModal = () => {
      showConfirm({
        className: 'small-popup',
        title: 'Confirm Delete',
        closable: true,
        content: (
          <>Deleting this tag will remove it from all associated profiles</>
        ),
        okText: 'Confirm',
        onOk: () => {
          handleDelete();
        },
        onCancel: () => {
          return false;
        },
      });
    };

    const renderTagButton = () => {
      /** Tag Button in Rows, opportunities, superconnectors where we had favorite option */
      if (fromRow)
        return (
          <Tooltip
            placement="bottom"
            title="Add/Remove Tags"
            trigger={['hover', 'click']}
          >
            <TagsFilled
              style={{ fontSize: '18px', color: 'var(--color-primary)' }}
              onClick={() => {
                if (!props.profileTags) fetchProfileTags();
                setShowTagsPopUp(true);
              }}
            />
          </Tooltip>
        );

      /** Add/Remove  at Profile and Export Table*/
      if (fromProfile || removeFromTable || addFromTable) {
        return (
          <div
            className={tagClassName || 'tag-button'}
            onClick={(e) => {
              // if (tagClassName && tagClassName === 'inactive-section') return;
              setShowTagsPopUp(true);
            }}
          >
            {removeFromTable ? 'Remove Tags' : 'Add Tags'}
          </div>
        );
      }
      /** Edit and Delete Buttons in filters */
      if (isUpdate) {
        return (
          <div className="tag-edit-delete">
            <EditOutlined
              style={{
                fontSize: '16px',
                color: 'var(--color-primary)',
              }}
              onClick={renderEditModal}
            />

            <DeleteIcon
              style={{
                height: '16px',
              }}
              onClick={renderDeleteModal}
            />
          </div>
        );
      }

      /** Create Button to add new tags */
      return (
        <div className="tag-button" onClick={renderCreateModal}>
          New Tag
        </div>
      );
    };

    return (
      <>
        {renderTagButton()}
        {showTagsPopUp && (
          <TagModal
            isLoading={isLoading}
            handleCreate={handleCreate}
            profileTags={profileTagsData}
            onClose={() => {
              setShowTagsPopUp(false);
            }}
            // selectAllRows={selectAllRows}
            {...props}
          />
        )}
      </>
    );
  }),
);

export default Tags;
