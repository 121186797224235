import { useEffect, useState } from 'react';
import { requestWithAuth } from '../../services/api';

export default function GoogleMapImage({ wrapperStyle, style, config }) {
  if (!('markers' in config)) {
    config = { ...config, markers: 'color:blue|' + config.center };
  }
  const mapParams = new URLSearchParams(config).toString();
  const url = 'https://maps.googleapis.com/maps/api/staticmap?' + mapParams;

  const [signedUrl, setSignedUrl] = useState(null);

  useEffect(() => {
    requestWithAuth(
      'sign-static-map-url',
      'GET',
      { url: url },
      null,
      null,
    ).then((response) => {
      if (response && response.signed_url) {
        setSignedUrl(response.signed_url);
      }
    });
  }, [url]);

  return (
    <div className="map-image-wrapper" style={wrapperStyle}>
      <img src={signedUrl} style={style} alt="Map of property location" />
    </div>
  );
}
