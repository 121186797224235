import { PremiumEventTypes } from './constants';
import { arrayHasValues } from './general';

const triggersWithValues = [
  PremiumEventTypes.CompanyAcquired,
  PremiumEventTypes.Deceased,
  PremiumEventTypes.InsiderStockAcquisition,
  PremiumEventTypes.InsiderStockPurchase,
  PremiumEventTypes.InsiderStockSale,
  PremiumEventTypes.InvestmentReceived,
  PremiumEventTypes.Ipo,
  PremiumEventTypes.IpoSpac,
  PremiumEventTypes.PropertyListing,
  PremiumEventTypes.UnicornStatusAchieved,
];

const triggersWithFunding = [PremiumEventTypes.InvestmentReceived];

export function showTriggerFundingRound(triggers = []) {
  return (
    triggers &&
    arrayHasValues(triggers) &&
    triggers.some((trigger) => triggersWithFunding.includes(trigger.value))
  );
}

export function showTriggerValueSelection(triggers = []) {
  return (
    triggers &&
    arrayHasValues(triggers) &&
    triggers.some((trigger) => triggersWithValues.includes(trigger.value))
  );
}
