import './index.css';

import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const dateFormat = 'MM/DD/YYYY';

const DateRangePicker = inject('dataStore')(
  observer((props) => {
    const [selectedRange, setSelectedRange] = useState([]);

    useEffect(() => {
      const dateRange = [];
      const valueDate = props.value || [];
      valueDate.map((date) => {
        if (date) dateRange.push(dayjs(date).format('MM/DD/YYYY'));
      });
      setSelectedRange(dateRange);
    }, [props.value]);

    const onRangePickerChange = (value) => {
      setSelectedRange(value);
      const { onCalendarChange, id } = props;
      onCalendarChange(value, id);
    };

    return (
      <div className="date-picker-wrapper">
        <RangePicker
          className="date-picker-new"
          format={dateFormat}
          value={
            selectedRange && selectedRange.length > 0
              ? [dayjs(selectedRange[0]), dayjs(selectedRange[1])]
              : undefined
          }
          // defaultValue={
          //   pickDefaultDate && [
          //     null,
          //     moment(moment().add(30, 'days').format(dateFormat), dateFormat),
          //   ]
          // }
          onChange={onRangePickerChange}
          disabledDate={(current) => current && current > dayjs().endOf('day')}
          disabled={props.disabled || false}
        />
      </div>
    );
  }),
);

export default DateRangePicker;
