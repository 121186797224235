import './index.css';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { commarize } from '../../utils/general';

ChartJS.register(ArcElement);
const DashboardPieChart = (props) => {
  const { values, id, title, onClick, triggerPeriod } = props;

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        backgroundColor: getComputedStyle(document.body).getPropertyValue(
          '--color-white',
        ),
        titleColor: getComputedStyle(document.body).getPropertyValue(
          '--color-black',
        ),
        bodyColor: getComputedStyle(document.body).getPropertyValue(
          '--color-black',
        ),
        borderColor: getComputedStyle(document.body).getPropertyValue(
          '--color-light',
        ),
        borderWidth: 1,
        caretPadding: 10,
        padding: {
          top: 5,
          right: 2,
          bottom: 5,
          left: 2,
        },
        bodyFont: {
          size: 8,
        },
        boxWidth: 6,
        boxHeight: 6,
        callbacks: {
          label: function (context) {
            return context.label;
          },
          afterLabel: function (context) {
            const count = commarize(context.parsed);
            return count;
          },
        },
      },
    },
    onHover: function (event, legendItem, legend) {
      event.chart.canvas.parentNode.style.cursor = legendItem[0]
        ? 'pointer'
        : 'default';
    },
    onClick: function (e, chartElement) {
      if (chartElement.length <= 0) return; // Clicked outside any bar.
      onClick(id, values.keyLabels[chartElement[0].index], triggerPeriod);
    },
  };

  const generateLegend = () => {
    const dataList = values.data;
    const backgroundColors = [
      getComputedStyle(document.body).getPropertyValue('--color-golden-start'),
      getComputedStyle(document.body).getPropertyValue('--color-primary'),
      getComputedStyle(document.body).getPropertyValue('--color-link'),
      getComputedStyle(document.body).getPropertyValue('--color-column'),
      getComputedStyle(document.body).getPropertyValue('--color-medium'),
      getComputedStyle(document.body).getPropertyValue('--color-hot-red'),
      getComputedStyle(document.body).getPropertyValue('--color-high'),
      getComputedStyle(document.body).getPropertyValue('--color-black'),
      getComputedStyle(document.body).getPropertyValue('--color-yellow'),
    ];
    const list = [];
    const keyPrefix = Date.now().toString();

    dataList.map((item, index) => {
      list.push(
        <div
          key={`${keyPrefix}-${item}-${index}`}
          style={{
            display: 'flex',
            color: 'var(--color-primary)',
            fontSize: '11px',
            columnGap: 5,
            alignItems: 'center',
            padding: '5px 0',
          }}
        >
          <span
            style={{
              color: 'var(--color-dark-shade)',
              minWidth: '40px',
              textAlign: 'right',
            }}
          >
            {commarize(item)}
          </span>
          <span
            style={{
              height: '10px',
              width: '10px',
              backgroundColor: backgroundColors[index],
              borderRadius: '50%',
              display: 'inline-block',
            }}
          />
          <label>{values.labels[index]}</label>
        </div>,
      );
    });

    return <div>{list}</div>;
  };

  const getFormattedData = () => {
    if (!document.getElementById('company-pie-chart') || !values.data) {
      return {
        labels: [],
        datasets: [],
      };
    }
    const ctx = document.getElementById('company-pie-chart').getContext('2d');
    const gradient1 = ctx.createLinearGradient(0, 0, 0, 175);
    gradient1.addColorStop(
      0.0,
      getComputedStyle(document.body).getPropertyValue('--color-golden-end'),
    );
    gradient1.addColorStop(
      1.0,
      getComputedStyle(document.body).getPropertyValue('--color-golden-start'),
    );

    const gradient2 = ctx.createLinearGradient(0, 0, 400, 400);
    gradient2.addColorStop(
      0,
      getComputedStyle(document.body).getPropertyValue('--color-primary'),
    );
    gradient2.addColorStop(
      1,
      getComputedStyle(document.body).getPropertyValue('--color-dark'),
    );

    const gradient3 = ctx.createLinearGradient(0, 0, 0, 175);
    gradient3.addColorStop(
      0,
      getComputedStyle(document.body).getPropertyValue('--color-link'),
    );
    gradient3.addColorStop(
      1,
      getComputedStyle(document.body).getPropertyValue('--color-link'),
    );

    const gradient4 = ctx.createLinearGradient(0, 0, 0, 175);
    gradient4.addColorStop(
      0,
      getComputedStyle(document.body).getPropertyValue('--color-column'),
    );
    gradient4.addColorStop(
      1,
      getComputedStyle(document.body).getPropertyValue('--color-column'),
    );

    const gradient5 = ctx.createLinearGradient(0, 0, 0, 175);
    gradient5.addColorStop(
      0,
      getComputedStyle(document.body).getPropertyValue('--color-medium'),
    );
    gradient5.addColorStop(
      1,
      getComputedStyle(document.body).getPropertyValue('--color-medium'),
    );

    const gradient6 = ctx.createLinearGradient(0, 0, 0, 175);
    gradient6.addColorStop(
      0.0,
      getComputedStyle(document.body).getPropertyValue('--color-hot-red'),
    );
    gradient6.addColorStop(
      1.0,
      getComputedStyle(document.body).getPropertyValue('--color-hot-red'),
    );

    const gradient7 = ctx.createLinearGradient(0, 0, 0, 175);
    gradient7.addColorStop(
      0.0,
      getComputedStyle(document.body).getPropertyValue('--color-high'),
    );
    gradient7.addColorStop(
      1.0,
      getComputedStyle(document.body).getPropertyValue('--color-high'),
    );

    const gradient8 = ctx.createLinearGradient(0, 0, 0, 175);
    gradient8.addColorStop(
      0.0,
      getComputedStyle(document.body).getPropertyValue('--color-black'),
    );
    gradient8.addColorStop(
      1.0,
      getComputedStyle(document.body).getPropertyValue('--color-black'),
    );

    const gradient9 = ctx.createLinearGradient(0, 0, 0, 175);
    gradient9.addColorStop(
      0.0,
      getComputedStyle(document.body).getPropertyValue('--color-yellow'),
    );
    gradient9.addColorStop(
      1.0,
      getComputedStyle(document.body).getPropertyValue('--color-yellow'),
    );
    const backgroundColorArray = [
      gradient1,
      gradient2,
      gradient3,
      gradient4,
      gradient5,
      gradient6,
      gradient7,
      gradient8,
      gradient9,
    ];

    return {
      datasets: [
        {
          data: values.data,
          borderWidth: 0,
          backgroundColor: backgroundColorArray,
        },
      ],
      labels: values.labels,
    };
  };

  return (
    <div className="dashboard-piechart">
      <div style={{ color: 'var(--color-primary)', fontSize: 11 }}>{title}</div>
      <div style={{ width: '90px', margin: '5px auto' }}>
        <Pie
          id="company-pie-chart"
          data={getFormattedData()}
          options={options}
        />
      </div>
      <div className="dashboard-piechart-labels">{generateLegend()}</div>
    </div>
  );
};

export default DashboardPieChart;
