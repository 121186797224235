import './index.css';

import { Bar } from 'react-chartjs-2';
import { commarize, splitNumberWithCommas } from '../../utils/general';

const VerticalChart = (props) => {
  const { data, id } = props;
  const onClickHandler = props.click;

  const options = {
    layout: {
      padding: {
        top: 20,
        bottom: 5,
        left: 5,
        right: 5,
      },
    },
    maintainAspectRatio: false,
    maxBarThickness: 10,
    hoverBackgroundColor: getComputedStyle(document.body).getPropertyValue(
      '--color-secondary',
    ),
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: getComputedStyle(document.body).getPropertyValue(
          '--color-white',
        ),
        titleColor: getComputedStyle(document.body).getPropertyValue(
          '--color-black',
        ),
        bodyColor: getComputedStyle(document.body).getPropertyValue(
          '--color-black',
        ),
        borderColor: getComputedStyle(document.body).getPropertyValue(
          '--color-light',
        ),
        borderWidth: 1,
        caretPadding: 10,
        padding: 10,
        xAlign: 'center',
        yAlign: 'bottom',
        callbacks: {
          label: function (context) {
            const label = splitNumberWithCommas(Number(context.label));
            return label;
          },
          labelColor: function (context) {
            return {
              borderColor: getComputedStyle(document.body).getPropertyValue(
                '--color-light',
              ),
              backgroundColor: context.element.options.backgroundColor,
              borderWidth: 2,
              borderRadius: 2,
            };
          },
        },
      },
      datalabels: {
        color: getComputedStyle(document.body).getPropertyValue(
          '--color-primary',
        ),
        listeners: {
          click: function (e) {
            if (!e.dataIndex || !data.labels[e.dataIndex]) return;
            const labelClicked = data.labels[e.dataIndex];
            onClickHandler(id, data.keyLabels[e.dataIndex]);
          },
        },
        labels: {
          l1: {
            color: getComputedStyle(document.body).getPropertyValue(
              '--color-dark-shade',
            ),
            formatter: (value, context) => {
              if (value) return commarize(value);
            },
          },
        },
        align: 'top',
        anchor: 'end',
        font: {
          size: 12,
        },
      },
    },
    scales: {
      y: {
        display: false,
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          color: getComputedStyle(document.body).getPropertyValue(
            '--color-primary',
          ),
        },
      },
    },

    onClick: (e, chartElement) => {
      if (!chartElement || chartElement.length <= 0) return; // Clicked outside any bar.
      onClickHandler(id, data.keyLabels[chartElement[0].index]);
    },
    onHover: (event, legendItem, legend) => {
      event.chart.canvas.parentNode.style.cursor = legendItem[0]
        ? 'pointer'
        : 'default';
    },
  };

  const emptyData = {
    labels: [],
    datasets: [],
  };

  return (
    <div className="chartAreaWrapper-vertical">
      <Bar data={data.datasets ? data : emptyData} options={options} />
    </div>
  );
};

export default VerticalChart;
