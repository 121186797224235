import './index.scss';
import { useState } from 'react';
import { inject, observer } from 'mobx-react';
import TagIcon from '../../assets/icons/ai_tag.svg?react';
import MaximizeIcon from '../../assets/icons/ai-maximize.svg?react';
import TagsList from './TagsList';
import TagsModal from './TagsModal';

const TagsFolder = inject('dataStore')(
  observer((props) => {
    const [openTagsModal, setOpenTagsModal] = useState(false);
    const onExpandClick = () => {
      setOpenTagsModal(true);
    };

    const getFlatenTags = () => {
      let flattenTags = [];

      if (props.dataStore.lookUpLists.userTagFolders.orphan_tags)
        flattenTags = [
          ...props.dataStore.lookUpLists.userTagFolders.orphan_tags,
        ];
      if (props.dataStore.lookUpLists.userTagFolders.shared_tags)
        flattenTags = [
          ...flattenTags,
          ...props.dataStore.lookUpLists.userTagFolders.shared_tags,
        ];
      if (props.dataStore.lookUpLists.userTagFolders.tag_folders) {
        props.dataStore.lookUpLists.userTagFolders?.tag_folders.forEach(
          (tagFolder) => {
            tagFolder.tag_folder_members.forEach((tag_folder_member) => {
              flattenTags = [...flattenTags, tag_folder_member.tag];
            });
          },
        );
      }
      if (props.dataStore.networkFilters.tags_checked.length) {
        return flattenTags.filter(
          (tag) =>
            tag?.id == props.dataStore.networkFilters.tags_checked[0] ||
            (tag &&
              tag?.id == props.dataStore.networkFilters.tags_checked[1]) ||
            (tag && tag?.id == props.dataStore.networkFilters.tags_checked[2]),
        );
      }
      return flattenTags;
    };

    return (
      <>
        <div className="tags-section-new">
          <div>
            <div className="dropdown-label-new">
              <TagIcon color="var(--color-header-blue-variant)" />
              TAGS & SUPPRESSION
            </div>
            <div className="tags-display">
              {
                <TagsList
                  tags={getFlatenTags().splice(0, 3)}
                  checkedTags={props.dataStore.networkFilters.tags_checked}
                  handleTagsCheck={(checked) => {
                    props.dataStore.networkFilters.tags_checked = [...checked];

                    props.dataStore.networkFilters.fetchData = true;
                  }}
                  count={3}
                />
              }
              {props.dataStore.networkFilters.tags_checked.length > 3 && (
                <span className="text">
                  + {props.dataStore.networkFilters.tags_checked.length - 3}{' '}
                  more
                </span>
              )}
            </div>
            <div
              className="right-gradient"
              style={{
                width:
                  props.dataStore.networkFilters.tags_checked.length > 3
                    ? '40px'
                    : '20px',
              }}
            >
              <div className="tags-expand" onClick={onExpandClick}>
                <MaximizeIcon
                  width={12}
                  height={12}
                  style={{ color: 'var(--color-primary)' }}
                />
              </div>
            </div>
          </div>
        </div>
        {openTagsModal && (
          <TagsModal
            onClose={() => {
              setOpenTagsModal(false);
            }}
          />
        )}
      </>
    );
  }),
);

export default TagsFolder;
