import './index.scss';
import { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { Input, message, Radio } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';

import { Tooltip } from 'antd';
import { requestWithAuth } from '../../services/api';
import FolderIcon from '../../assets/icons/ai_folder_plus.svg?react';
import ArrowIcon from '../../assets/icons/ai_arrow.svg?react';
import OpenFolderIcon from '../../assets/icons/ai_folder_minus.svg?react';
import DeleteIcon from '../../assets/icons/ai_delete.svg?react';
import MoveIcon from '../../assets/icons/ai_icn_move_folder.svg?react';
import SuppressedPNGIcon from '../../assets/icons/ai_ic_suppress.png';
import FavoritesIcon from '../../assets/icons/ai_star_icn.svg?react';
import NurtureIcon from '../../assets/icons/ai_nurture_icn.svg?react';
import ContactedIcon from '../../assets/icons/ai_box_check_icn.svg?react';
import LoaderAnimation from '../AidLoader';
import CreateTagElement from './CreateTagElement';

const EditComponent = (props) => {
  const {
    Id,
    isSuppress,
    initialValue,
    handleInputChange,
    onDelete,
    onTransferClick,
    onSuppressChange,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const inputErrorRef = useRef(null);
  const [isSuppressTooltipOpen, setIsSuppressTooltipOpen] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    setInputValue(initialValue || '');
  }, [initialValue]);

  const updateInputValue = (value) => {
    setInputValue(value);
  };

  const folderErrorTimeout = () => {
    setTimeout(() => {
      setShowWarning(false);
      // setInputValue(initialValue);
    }, 3000);
  };

  function myStopFunction() {
    clearTimeout(folderErrorTimeout);
  }

  return (
    <div
      className="edit-component"
      onClick={() => {
        if (isSuppressTooltipOpen) setIsSuppressTooltipOpen(false);
      }}
    >
      <div style={{ position: 'relative', paddingLeft: 5 }}>
        <Input
          value={inputValue}
          prefix={
            <>
              {/* <div
                className="transfer-icon"
                ref={transferRef}
                onClick={() => {
                  transferTooltipRef.current.style.display = 'block';
                  //transferRef.current.style.display = 'none';
                }}
              >
                <MoveIcon />
              </div>
              <div
                className="transfer-tooltip"
                style={{ display: 'none' }}
                onClick={() => {
                  onTransferClick();
                  transferTooltipRef.current.style.display = 'none';
                  transferRef.current.style.display = 'block';
                }}
                ref={transferTooltipRef}
              >
                {' '}
                Move tag?
                <MoveIcon />
              </div> */}
              <Tooltip
                placement={'left'}
                trigger={'hover'}
                arrow={false}
                overlayClassName="move-popup"
                color={'rgba(0,0,0,0.75)'}
                title={
                  <div
                    className="transfer-tooltip"
                    onClick={() => {
                      onTransferClick();
                    }}
                  >
                    {' '}
                    <div style={{ lineHeight: 1.8 }}>Move tag? </div>{' '}
                    <MoveIcon />
                  </div>
                }
              >
                <div className="transfer-icon">
                  <MoveIcon />
                </div>
              </Tooltip>
              {isSuppress && <img src={SuppressedPNGIcon} id="suppress-icon" />}
            </>
          }
          onChange={(e) => setInputValue(e.target.value)}
          onPressEnter={(e) => {
            myStopFunction();
            setShowWarning(false);
            handleInputChange(
              initialValue,
              e.target.value,
              Id,
              inputErrorRef,
              updateInputValue,
            );
          }}
          autoFocus={false}
          onBlur={(e) => {
            if (e.target.value !== initialValue) {
              setShowWarning(true);
              // const folderInput = document.getElementById(`folder-${id}`);
              // if (folderInput) folderInput.focus();
              folderErrorTimeout();
            }
          }}
        />
        {showWarning && (
          <div className="input-warning">Press Enter to update name!</div>
        )}
        <div
          ref={inputErrorRef}
          className="input-error-ref input-error"
          style={{ visibility: 'hidden' }}
        >
          <CloseCircleFilled />
          Tag name already exists
        </div>
      </div>

      <div className="edit-component-icons">
        <Tooltip
          placement={'right'}
          trigger={'hover'}
          arrow={false}
          color={'rgba(0,0,0,0.75)'}
          overlayClassName="delete-suppress-popup"
          title={
            <div
              onClick={() => {
                onDelete();
              }}
              style={{
                display: 'flex',
                fontSize: 11,
                gap: '6px',
                alignItems: 'center',
              }}
            >
              <DeleteIcon height={16} width={16} />
              <span style={{ marginTop: 1 }}> Delete tag?</span>
            </div>
          }
        >
          <div className="delete-icon">
            <DeleteIcon />
          </div>
        </Tooltip>

        {typeof isSuppress !== 'boolean' ? (
          <span />
        ) : (
          <>
            <Tooltip
              placement={'right'}
              trigger={'hover'}
              arrow={false}
              color={'rgba(0,0,0,0.75)'}
              overlayClassName="delete-suppress-popup"
              title={
                <div
                  onClick={() => {
                    onSuppressChange(!isSuppress, initialValue, Id);
                  }}
                  style={{
                    display: 'flex',
                    fontSize: 11,
                    gap: '6px',
                    alignItems: 'center',
                  }}
                >
                  {isSuppress ? (
                    //<UnSuppressedIcon id="unsuppress-icon" />
                    <img src={SuppressedPNGIcon} id="unsuppress-icon-new" />
                  ) : (
                    <img src={SuppressedPNGIcon} id="suppress-icon" />
                  )}
                  <span style={{ marginTop: 1 }}>
                    {isSuppress ? ' Unsuppress tag?' : ' Suppress tag?'}
                  </span>
                </div>
              }
            >
              <div>
                {isSuppress ? (
                  //<UnSuppressedIcon id="unsuppress-icon" />
                  <img src={SuppressedPNGIcon} id="unsuppress-icon-new" />
                ) : (
                  <img src={SuppressedPNGIcon} id="suppress-icon" />
                )}
              </div>
            </Tooltip>

            {/* <div ref={tooltipRef}> </div> */}
          </>
        )}
      </div>
    </div>
  );
};

const TransferUi = (props) => {
  const {
    transferTag,
    sourceFolder,
    folderData,
    closeTransferView,
    updateAfterTransfer,
  } = props;
  const [targetFolder, setTargetFolder] = useState(folderData[0]);
  const [showSuppressionWarning, setShowSuppressionWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTransferClick, setIsTransferClick] = useState(false);
  const [showArrow, setShowArrow] = useState(true);
  const [hoveredFolder, setHoveredFolder] = useState('');

  useEffect(() => {
    document.addEventListener('keypress', (event) => {
      if (event.key === 'Enter') {
        transferToFolder(transferTag, targetFolder.id);
      }
    });
  });
  // useEffect(() => {
  //   /** onChange of Radio Group will trigger as we click on a targetfolder div,
  //    * but we have to control api call based on folder.is_suppression_tag.
  //    * So we can not call api on onChange, instead we update 'targetFolder'
  //    *  on Radio-OnChange, 'isTransferClick' on divClick, 'showSuppressionWarning' on
  //    * suppressionWarning click and trigger api in useEffect considering
  //    * the values of these three */
  //   /**ps. we consider showSuppressionWarning */
  //   if (!showSuppressionWarning && isTransferClick) {
  //     //transferToFolder(transferTag, targetFolder.id);
  //   }
  // }, [showSuppressionWarning, targetFolder ]);

  const transferToFolder = (tag, targetId) => {
    if (!tag || !targetId) return;
    setIsLoading(true);
    const body = {
      tag_ids: [tag.id],
      tag_folder_id: targetId,
    };
    requestWithAuth(
      '/tag-folder/attach-tag-to-folder',
      'POST',
      null,
      body,
      null,
    ).then((response) => {
      setIsTransferClick(false);
      setShowSuppressionWarning(false);
      if (response && response.code === 200) {
        updateAfterTransfer();
        setIsLoading(false);
        closeTransferView();
      } else {
        setIsLoading(false);
      }
    });
  };

  return (
    <div className="transfer-ui-wrapper">
      <div className="header">
        Move tag <span className="bold-text">{transferTag.tag}</span> from
        folder
        {hoveredFolder ? <span> to {hoveredFolder}</span> : ''}
      </div>
      <div
        className="transfer-content scroll-container"
        style={{ pointerEvents: isLoading ? 'none' : 'unset' }}
      >
        <div className="source-folder">
          <OpenFolderIcon className="folder-icon" />
          <div className="source-folder-text">
            <span style={{ width: '70%', overflow: 'ellipsis' }}>
              {sourceFolder.folder}
            </span>
          </div>
        </div>
        <div
          className="target-selection"
          onMouseEnter={(e) => setShowArrow(false)}
          onMouseLeave={() => setShowArrow(true)}
        >
          <Radio.Group
            value={targetFolder}
            onChange={(e) => {
              setTargetFolder({ ...e.target.value });
            }}
          >
            {folderData &&
              folderData.map((folder) => {
                return (
                  <div
                    // onClick={(e) => {
                    //   if (folder.is_suppression_tag)
                    //     setShowSuppressionWarning(true);
                    //   else {
                    //     if (e.screenX == 0 && e.screenY == 0) {
                    //       /**disable triggering 'click' on arrow-key press event */
                    //       return;
                    //     } else {
                    //       //setIsTransferClick(true);
                    //       console.log('calleddd');
                    //       transferToFolder(transferTag, folder.id);
                    //     }
                    //   }
                    // }}
                    className={
                      folder.id === targetFolder.id &&
                      (isTransferClick || showSuppressionWarning)
                        ? 'selected-target'
                        : ''
                    }
                    onMouseLeave={() => setShowSuppressionWarning(false)}
                  >
                    {showSuppressionWarning &&
                      folder.id === targetFolder.id && (
                        <div
                          className="suppression-warning"
                          onClick={(e) => {
                            if (e.screenX == 0 && e.screenY == 0) {
                              /**disable triggering 'click' on arrow-key press event */
                              return;
                            } else {
                              e.stopPropagation();
                              setShowSuppressionWarning(false);
                              setIsTransferClick(true);
                              transferToFolder(transferTag, folder.id);
                            }
                          }}
                        >
                          <div>
                            This folder is under suppression
                            {/* <SuppressedIcon style={{ width: '10px' }} /> */}{' '}
                            <img src={SuppressedPNGIcon} id="suppress-icon" />
                          </div>
                          <div>
                            {' '}
                            Move this tag into a suppressed folder?
                            <MoveIcon />
                          </div>
                        </div>
                      )}
                    <Radio.Button
                      value={folder}
                      style={{
                        filter:
                          isLoading && targetFolder.id !== folder.id
                            ? 'opacity(0.2)'
                            : 'opacity(1)',
                      }}
                      onClick={(e) => {
                        if (folder.is_suppression_tag)
                          setShowSuppressionWarning(true);
                        else {
                          if (e.screenX == 0 && e.screenY == 0) {
                            /**disable triggering 'click' on arrow-key press event */
                            return;
                          } else {
                            setIsTransferClick(true);
                            transferToFolder(transferTag, folder.id);
                          }
                        }
                      }}
                    >
                      <div
                        id={folder.tag_folder_name}
                        className="target-component"
                        style={{
                          left: isLoading ? '-108px' : '0px',
                        }}
                        onMouseEnter={(e) => setHoveredFolder(e.target.id)}
                      >
                        {targetFolder.id === folder.id && (
                          <>
                            {isLoading && (
                              <>
                                <LoaderAnimation
                                  style={{ position: 'unset' }}
                                  size="small"
                                  loaderId="tag-transfer"
                                />
                                <div className="spin-text">Moving tag</div>
                              </>
                            )}
                            {showArrow && !isTransferClick && (
                              <ArrowIcon className="transfer-arrow" />
                            )}
                          </>
                        )}
                        <ArrowIcon className="transfer-arrow-hover" />
                      </div>
                      {folder.tag_folder_name === hoveredFolder ? (
                        <>
                          <OpenFolderIcon className="folder-icon" />
                          {folder.is_suppression_tag && (
                            // <SuppressedIcon
                            //   style={{
                            //     width: '9px',
                            //     marginTop: '5px',
                            //     marginLeft: '-20px',
                            //   }}
                            // />
                            <img
                              src={SuppressedPNGIcon}
                              id="suppress-icon-in-open-folder"
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <FolderIcon className="folder-icon" />
                          {folder.is_suppression_tag && (
                            // <SuppressedIcon
                            //   style={{
                            //     width: '9px',
                            //     marginTop: '5px',
                            //     marginLeft: '-20px',
                            //   }}
                            // />
                            <img
                              src={SuppressedPNGIcon}
                              id="suppress-icon-in-folder"
                            />
                          )}
                        </>
                      )}
                      <div
                        style={{
                          marginTop: '6px',
                          marginLeft: folder.is_suppression_tag
                            ? '16px'
                            : '5px',
                        }}
                      >
                        {folder.tag_folder_name}(
                        {folder.tag_folder_members?.length || 0})
                      </div>
                    </Radio.Button>
                  </div>
                );
              })}
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};

const TagsEditView = inject('dataStore')(
  observer((props) => {
    const { fetchUserTags, fetchTagFolders, showAddNew } = props;

    const { userTagFolders } = props.dataStore.lookUpLists;
    const [transformedData, setTransformedData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isTagDeletion, setIsTagDeletion] = useState(false);
    const [showTransfer, setShowTransfer] = useState(false);
    const [transferTag, setTransferTag] = useState({ tag: '', id: '' });
    const [newTagValue, setNewTagValue] = useState('');
    const [newFolderName, setNewFolderName] = useState(null);
    const [existingTagError, setExistingTagError] = useState(false);

    useEffect(() => {
      updateData();
    }, [userTagFolders]);

    const updateData = () => {
      let data = [];
      data = transformData();
      if (data) setTransformedData([...data]);
    };

    const transformData = () => {
      let transformed;
      if (userTagFolders && userTagFolders.orphan_tags) {
        transformed = userTagFolders.orphan_tags.filter((obj) => {
          return (
            obj.tag !== 'Favorites' &&
            obj.tag !== 'Nurture' &&
            obj.tag !== 'Do not contact' &&
            obj.tag !== 'Suppress'
          );
        });
      }
      return transformed || [];
    };

    const addToList = () => {
      fetchTagFolders();
    };

    const fetchUpdatedData = () => {
      fetchTagFolders();
      fetchUserTags();
    };

    const handleSuppress = (suppress, tagName, tagID) => {
      setIsLoading(true);
      const body = {
        tag: tagName,
        is_suppression_tag: suppress,
      };
      requestWithAuth(`tags/${tagID}`, 'PUT', null, body, null).then(
        (response) => {
          if (response && response.code === 200) {
            fetchTagFolders();
            //fetchUpdatedData();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            return;
          }
        },
      );
    };

    const handleDeleteTags = (tagIds) => {
      if (!tagIds || tagIds.length === 0) {
        message.error('Please select tags to remove', 10);
        return;
      }
      setIsTagDeletion(true);
      const body = {};
      body.tag_ids = [...tagIds];
      requestWithAuth('/tags/delete-multiple', 'POST', null, body, null).then(
        (response) => {
          if (response && response.code === 200) {
            fetchTagFolders();
            setIsTagDeletion(false);
            return;
          }
          setIsTagDeletion(false);
          return;
        },
      );
    };

    async function handleEditTag(
      currentName,
      newName,
      Id,
      errorRef,
      updateInputValue,
    ) {
      if (!currentName || !newName || !Id || currentName === newName) return;
      const body = {};
      body.tag = newName;
      setIsLoading(true);
      requestWithAuth(`tags/${Id}`, 'PUT', null, body, null).then(
        (response) => {
          if (response && response.code === 200) {
            fetchTagFolders();
          } else if (
            response &&
            response.response &&
            response.response.data &&
            response.response.data.detail &&
            response.response.data.detail === 'Tag name already exists' &&
            errorRef &&
            errorRef.current
          ) {
            errorRef.current.style.visibility = 'visible';
            updateInputValue(currentName);
            setTimeout(() => {
              errorRef.current.style.visibility = 'hidden';
            }, 3000);
          }
          setIsLoading(false);
          return;
        },
      );
    }

    if (!transformedData) return <></>;
    return (
      <div className="edit-view-wrapper">
        {!showTransfer && (
          <div className="sub-title-bar" style={{ paddingBottom: '5px' }}>
            <div>
              <div className="sub-title-bar-heading">Editing tags</div>
              <div className="sub-title-bar-description">
                Edit Your tags, apply suppression to any tag to make it a
                supressed tag, add/delete tags
              </div>
            </div>
          </div>
        )}
        <div className="content-border">
          {showTransfer ? (
            <TransferUi
              folderData={userTagFolders.tag_folders}
              sourceFolder={{ folder: 'orphanTags' }}
              transferTag={transferTag}
              updateAfterTransfer={fetchUpdatedData}
              closeTransferView={() => {
                setShowTransfer(false);
              }}
            />
          ) : (
            <div className="edit-ui-wrapper">
              <div
                style={{
                  paddingLeft: 0,
                  position: 'relative',
                }}
              >
                {isTagDeletion ||
                  (isLoading && (
                    <LoaderAnimation
                      loaderId="tag-delete-loader"
                      size="small"
                      style={{ top: '100px' }}
                    />
                  ))}
                <div
                  style={{
                    opacity: isLoading || isTagDeletion ? 0.1 : 1,
                  }}
                >
                  <div
                    className="tag-container-heading"
                    style={{ paddingTop: 5, marginBottom: 5 }}
                  >
                    Tags ({transformedData.length})
                  </div>

                  <div
                    className="add-new-button"
                    style={{ padding: 5, margin: 0 }}
                  >
                    <div>{<CreateTagElement onCreation={addToList} />}</div>
                    <div className="tag-edit-texts">
                      <span>Delete</span>
                      <span>Suppress</span>
                    </div>
                  </div>

                  <div className="edit-component-scroll-wrapper">
                    <div className="edit-component-scroll">
                      {transformedData.map((tagObj) => {
                        return (
                          <EditComponent
                            initialValue={tagObj.tag}
                            Id={tagObj.id}
                            isSuppress={tagObj.is_suppression_tag}
                            onDelete={() => handleDeleteTags([tagObj.id])}
                            handleInputChange={handleEditTag}
                            onTransferClick={() => {
                              setTransferTag({ ...tagObj });
                              setShowTransfer(true);
                            }}
                            onSuppressChange={handleSuppress}
                            isLoading={isLoading}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="bottom-line-system-tags">
                <div className="system-tags-contents">
                  <div>System Tags</div>
                  <div>
                    <ContactedIcon />
                    <span> Contacted</span>
                  </div>
                  <div>
                    <img src={SuppressedPNGIcon} />
                    <span>Do not contact</span>
                  </div>
                  <div>
                    <FavoritesIcon />
                    <span>Favorites</span>
                  </div>
                  <div>
                    <NurtureIcon />
                    <span>Nurture</span>
                  </div>
                  <div>
                    <img src={SuppressedPNGIcon} />
                    <span>Suppress</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }),
);

export default TagsEditView;
