import './index.scss';
import { useState, useRef, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Divider, Radio } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';

import HelpIcon from '../../assets/icons/help-icon.svg?react';
import LoaderIcon from '../../assets/icons/ai_ic_logo_gold.svg?react';

import {
  formatValue,
  formatEventName,
  splitNumberWithCommas,
} from '../../utils/general';
import DashboardPieChart from '../Chart/DashboardPieChart';
import TriggerDetailModal from './TriggerDetailModal';
import TriggerCompanyModal from './TriggerCompanyModal';

const TriggerValueBox = (props) => {
  return (
    <div className="triggers-single-section" onClick={props.onClick}>
      <label className="count-label">{props.count}</label>
      <br />
      <label className="text-label">{props.label}</label>
    </div>
  );
};

const Triggers = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const { eventData, triggerTotalCount, isLoading } = props;
    const [triggerRadio, setTriggerRadio] = useState('week');
    const [showEventDetailModal, setShowEventDetailModal] = useState(false);
    const [showCompaniesImpactedModal, setShowCompaniesImpactedModal] =
      useState(false);
    const borderRef = useRef(null);
    const flags = useFlags();

    useEffect(() => {
      if (borderRef && borderRef.current) {
        borderRef.current.style.left = '0px';
      }
    }, []);

    function preparePieChartData() {
      const labelArray = [];
      const valueArray = [];
      const keyLabelArray = [];
      eventData.map((value) => {
        const eventType = formatEventName(value.event_type);
        labelArray.push(eventType);
        keyLabelArray.push(value.event_type);
        // valueArray.push(value.person_count);
        valueArray.push(value.event_count);
      });
      return {
        data: valueArray,
        labels: labelArray,
        keyLabels: keyLabelArray,
      };
    }

    function onTriggerValueBoxClick(id) {
      if (
        !flags.dashboardTriggerBoxClick ||
        !triggerTotalCount ||
        !triggerTotalCount[id] ||
        triggerTotalCount[id] <= 0
      )
        return;

      switch (id) {
        case 'total_event_count':
        case 'total_monetary_value':
          setShowEventDetailModal(true);
          break;
        case 'total_org_count':
          setShowCompaniesImpactedModal(props.onClick(id, 1, triggerRadio));
          break;
        case 'total_executives':
          props.onClick('triggers_view_box', 1, triggerRadio);
          break;
        default:
          break;
      }
    }

    /**  Method to handle the radio button change */
    const onDateSelectorChange = (e) => {
      setTriggerRadio(e.target.value);
      props.radioChange(e.target.value);
      if (borderRef && borderRef.current) {
        /** bottom border of checked radio button is showing use a div and its postion is mannually adjusted on radiobutton change */
        borderRef.current.style.left =
          e.target.value === 'week' ? '0px' : '60px';
      }
    };

    return (
      <>
        <div>
          {isLoading && (
            <LoaderIcon className="aid-sidecar-ext-logo-spin-icon-only" />
          )}
          <div
            className={
              isLoading
                ? 'dashboard-item triggers aid-div-disable'
                : 'dashboard-item triggers aid-div-enable'
            }
          >
            <div className="triggers-header">
              <div style={{ display: 'flex' }}>
                <div className="dashboard-item-title">
                  <span>Trigger Statistics</span>
                  <HelpIcon
                    id="info-triggers"
                    className="info-icon"
                    height="14px"
                    width="14px"
                    onClick={(e) => {
                      e.stopPropagation();
                      props.modalDataStore.eventHelpModalVisible = true;
                    }}
                  />
                </div>

                <div className="triggers-filter">
                  <Radio.Group
                    onChange={onDateSelectorChange}
                    value={triggerRadio}
                    defaultValue="week"
                  >
                    {/* <Radio.Button className="radio-style" value="recent">
                                            Recent
                                        </Radio.Button> */}
                    <Radio.Button className="radio-style" value="week">
                      Week
                    </Radio.Button>
                    <Radio.Button className="radio-style" value="month">
                      Month
                    </Radio.Button>
                  </Radio.Group>
                  <div className="bottom-line" ref={borderRef} />
                </div>
              </div>
              <div>
                <CloseCircleFilled
                  onClick={props.onCloseButtonClick}
                  style={{ color: 'var(--color-secondary)' }}
                />
              </div>
            </div>
            <Divider className="dash-board-divider" />
            <div className="triggers-box">
              {eventData && eventData.length > 0 ? (
                <div className="triggers-content">
                  <div className="chart-section">
                    <DashboardPieChart
                      values={preparePieChartData()}
                      title={'Executives Impacted'}
                      onClick={props.onClick}
                      triggerPeriod={triggerRadio}
                      id={'triggers_overview'}
                    />
                  </div>
                  <div className="count-section">
                    <TriggerValueBox
                      count={splitNumberWithCommas(
                        triggerTotalCount.total_event_count,
                      )}
                      label={'Total Triggers'}
                      onClick={() =>
                        onTriggerValueBoxClick('total_event_count')
                      }
                    />
                    <TriggerValueBox
                      count={formatValue(
                        triggerTotalCount.total_monetary_value,
                      )}
                      label={'Total Value'}
                      onClick={() =>
                        onTriggerValueBoxClick('total_monetary_value')
                      }
                    />
                    <TriggerValueBox
                      count={splitNumberWithCommas(
                        triggerTotalCount.total_executives,
                      )}
                      label={'Executives Impacted'}
                      onClick={() => onTriggerValueBoxClick('total_executives')}
                    />
                    <TriggerValueBox
                      count={splitNumberWithCommas(
                        triggerTotalCount.total_org_count,
                      )}
                      label={'Companies Impacted'}
                      onClick={() => onTriggerValueBoxClick('total_org_count')}
                    />
                  </div>
                </div>
              ) : (
                <div className="dashboard-item-empty">
                  {' '}
                  <p>No results</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {showEventDetailModal && (
          <TriggerDetailModal
            width="100%"
            visible
            onRowClick={props.onClick}
            triggerPeriod={triggerRadio}
            onCancel={() => {
              setShowEventDetailModal(false);
            }}
            data={eventData}
          />
        )}
        {showCompaniesImpactedModal && (
          <TriggerCompanyModal
            width="100%"
            visible
            onPeopleClick={props.onClick}
            triggerPeriod={triggerRadio}
            onCancel={() => {
              setShowCompaniesImpactedModal(false);
            }}
          />
        )}
      </>
    );
  }),
);

export default Triggers;
