import { useNavigate, useLocation } from 'react-router-dom';

const withRouter = (WrappedComponent) => {
  const WithRouterWrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    // other hooks

    return <WrappedComponent {...props} {...{ navigate, location }} />;
  };

  WithRouterWrapper.displayName = `WithRouter(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return WithRouterWrapper;
};

export default withRouter;
